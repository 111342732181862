import { useState, FC, useEffect } from "react";
import {
  faBell,
  faBells,
  faLaptop,
  faRepeat,
} from "@fortawesome/pro-light-svg-icons";
import { useParams } from "react-router-dom";
import { AlertDetails } from "./AlertDetails";
import { StatisticsTileWithProgress } from "../../components/StatisticsTileWithProgress";
import { AlertKnowledgeBase } from "./AlertKnowledgeBase";
import { NotifyBannerSmall } from "../../components/NotificationBannerSmall";
import { Loader } from "../../components/Loader";
import { useAsyncEffect } from "../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchActiveAlerts,
  fetchAlertStatistics,
} from "../../redux/Thunks/alerts";
import {
  getAlertById,
  getAlertStatisticsById,
} from "../../redux/Slices/alerts.slice";
import { setLayout } from "../../redux/Slices/layout.slice";
import { Card, Grid } from "@mui/material";
import { AlertClassification } from "./AlertClassification";

export const ViewAlertPage: FC = () => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const currentAlert = useAppSelector(getAlertById(id || ""));
  // const currentAlertId = currentAlert?.id;
  const currentAlertStats = useAppSelector(getAlertStatisticsById(id || ""));
  const [showRaisedNotification, setshowRaisedNotification] =
    useState<boolean>(false);

  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchActiveAlerts());
    if (id) await dispatch(fetchAlertStatistics(id));
  }, [id]);

  useEffect(() => {
    dispatch(
      setLayout({
        title: "View Alert",
        subtitle: "View Alert",
        icon: faBell,
        links: [{ href: "/alerts", text: "Alerts Dashboard" }],
      })
    );
  }, [dispatch]);

  const onRaiseTicketSuccess = async () => {
    if (currentAlert) {
      await dispatch(fetchActiveAlerts());
      setshowRaisedNotification(true);
    }
  };

  const handleHideBanner = () => {
    setshowRaisedNotification(false);
  };

  return (
    <>
      {loading ? (
        <Loader show={true}></Loader>
      ) : (
        <>
          {showRaisedNotification === true ? (
            <NotifyBannerSmall
              secondsDuration={20}
              message="A new ticket was raised against this incident."
              showNotification={showRaisedNotification}
              createdId={currentAlert?.ticketId || ""}
              navigatePage='ticket'
              linkText='View Ticket'
              severity='success'
            />
          ) : (
            <></>
          )}

          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid
              container
              item
              xs={12}
              md={12}
              spacing={2}
              sx={{ height: "100%" }}
            >
              <Grid item xs={12} md={3} sx={{ maxHeight: "210px" }}>
                {!loading ? (
                  <StatisticsTileWithProgress
                    headerIcon={faBells}
                    title="Incident of this type"
                    progressBarTitle={`${
                      currentAlertStats?.totalWithSameAlertName || 0
                    } Alert(s)`}
                    progressPercentage={
                      ((currentAlertStats?.totalWithSameAlertName || 0) /
                        (currentAlertStats?.totalAlerts || 0)) *
                      100
                    }
                    progressBarSubText={`Out of a total of ${currentAlertStats?.totalAlerts} Alerts(s)`}
                    linkText="View Alerts of This Type"
                    linkUrl="/alerts"
                    currentAlert={currentAlert}
                  />
                ) : (
                  <Loader show={true} />
                )}
              </Grid>

              <Grid item xs={12} md={3} sx={{ maxHeight: "210px" }}>
                {!loading ? (
                  <StatisticsTileWithProgress
                    headerIcon={faRepeat}
                    title="Incidents repeats of this type"
                    progressBarTitle={`${currentAlertStats?.totalRepeatCountOfSameNamedAlert} Times`}
                    progressPercentage={
                      ((currentAlertStats?.totalRepeatCountOfSameNamedAlert ||
                        0) /
                        (currentAlertStats?.totalRepeatCount || 0)) *
                      100
                    }
                    progressBarSubText={`Out of ${
                      currentAlertStats?.totalRepeatCount || 0
                    } total repeats for all alert types`}
                    linkText="View All Alerts"
                    linkUrl="/alerts"
                    currentAlert={currentAlert}
                  />
                ) : (
                  <Loader show={true} />
                )}
              </Grid>
              <Grid item xs={12} md={3} sx={{ maxHeight: "210px" }}>
                {!loading ? (
                  <StatisticsTileWithProgress
                    headerIcon={faLaptop}
                    title="Devices with this type of incident"
                    progressBarTitle={`${
                      currentAlertStats?.totalDevicesWithSameNamedAlert || 0
                    } Devices(s)`}
                    progressPercentage={
                      ((currentAlertStats?.totalDevicesWithSameNamedAlert ||
                        0) /
                        (currentAlertStats?.totalDevicesWithAlerts || 0)) *
                      100
                    }
                    progressBarSubText={`Out of ${
                      currentAlertStats?.totalDevicesWithAlerts || 0
                    } device(s) have this type of alert`}
                    linkText="View Devices With This Type of Alert"
                    linkUrl="/devices"
                    currentAlert={currentAlert}
                  />
                ) : (
                  <Loader show={true} />
                )}
              </Grid>

              <Grid item xs={12} md={3} sx={{ maxHeight: "210px" }}>
                <Card sx={{ border: "solid 1px #ddd" }}>
                  {!loading ? (
                    <StatisticsTileWithProgress
                      headerIcon={faBell}
                      title="Incidents under this category"
                      progressBarTitle={`${
                        currentAlertStats?.sameCategoryTotal || 0
                      } Alert(s)`}
                      progressPercentage={
                        ((currentAlertStats?.sameCategoryTotal || 0) /
                          (currentAlertStats?.totalAlerts || 0)) *
                        100
                      }
                      progressBarSubText={`Out of ${
                        currentAlertStats?.totalAlerts || 0
                      } alert(s)`}
                      linkText="View Alerts Under This Category"
                      linkUrl="/alerts"
                      currentAlert={currentAlert}
                    />
                  ) : (
                    <Loader show={true} />
                  )}
                </Card>
              </Grid>

              <Grid item xs={12}>
                {!loading ? (
                  <AlertDetails
                    alert={currentAlert}
                    onAlertTicketRaisedSuccess={onRaiseTicketSuccess}
                    page={"alert"}
                  />
                ) : (
                  <>Loading Incident</>
                )}
              </Grid>

              <Grid item xs={12}>
                {!loading ? (
                  <AlertKnowledgeBase alert={currentAlert} />
                ) : (
                  <>Loading Knowledge Articles</>
                )}
              </Grid>
              <Grid item xs={12}>
                {!loading ? (
                  <AlertClassification alert={currentAlert} />
                ) : (
                  <>Loading Incident Classification</>
                )}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
