
import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faComments,
  faTriangleExclamation,
  faCircleCheck,
} from "@fortawesome/pro-light-svg-icons";
import { FormattedDateTime, Today, Yesterday } from "../common/dateUtils";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CardHeader } from "./CardHeader";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

interface ChatComment {
  id: string;
  comment: string;
  createdDate: Date;
  createdByInitials: string;
  createdByFullName: string;
  createdByRole: string;
  createdById: string;
  isImportant: boolean;
  isInternal: boolean;
}

interface ChatProps {
  userId: string;
  onCommentAdded: (comment: string, isImportant: boolean, isInternal: boolean) => void;
  title: string;
  comments: ChatComment[];
  subTitle: React.ReactNode;
  disabled: boolean;
}

export const Chat: React.FC<ChatProps> = (props) => {
  const commentsRef = React.useRef<HTMLDivElement>(null);
  const { control, handleSubmit, reset } = useForm<{
    comment: string;
    isImportant: boolean;
    isInternal: boolean;
  }>();

  const mostRecentComment = () => {
    return props.comments.length > 0
      ? FormattedDateTime(props.comments[props.comments.length - 1].createdDate)
      : "-";
  };

  const onSubmit: SubmitHandler<{ comment: string; isImportant: boolean, isInternal: boolean }> = (
    data
  ) => {
    props.onCommentAdded(data.comment, data.isImportant, data.isInternal);
    reset();
  };

  React.useEffect(() => {
    if (commentsRef.current !== null) {
      commentsRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [props.comments]);

  return (
    <Card style={{ width: "100%" }}>
      <CardHeader
        icon={faComments}
        subText={`Last commented on ${mostRecentComment()}`}
        title={props.title}
      />
      <CardContent>
        {props.subTitle}
        <List
          sx={{
            width: "100%",
            bgcolor: "background.paper",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          <OlderComments comments={props.comments} userId={props.userId} />
          <Divider sx={{ width: "100%" }}>
            <Chip label="Yesterday" />
          </Divider>
          <YesterdaysComments comments={props.comments} userId={props.userId} />
          <Divider sx={{ width: "100%", mt: 1 }}>
            <Chip label="Today" />
          </Divider>
          <TodaysComments comments={props.comments} userId={props.userId} />
          <Box ref={commentsRef} />
        </List>
      </CardContent>
      <CardActions
        sx={{
          mb: 2,
          pb: 0,
          borderTopStyle: "solid",
          borderTopWidth: "1px",
          borderTopColor: "orange",
        }}
      >
        {props.disabled ? (
          <></>
        ) : (
          <Stack direction="column" sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex" }}>
              <Divider sx={{ mb: 2 }} />
              <Grid container spacing={0}>
                <Grid item xs={10} sx={{display: "flex", justifyContent: 'flex-start' }}>
                <Controller
                name="comment"
                control={control}
                defaultValue=""
                rules={{
                  required: { value: true, message: "Please enter a comment" },
                }}
                render={({ field: { name, value, onChange } }) => (
                  <TextField
                    name={name}
                    value={value}
                    required
                    onChange={onChange}
                    id="comment"
                    label="Add a new comment"
                    multiline
                    variant="standard"
                    rows={2}
                    fullWidth
                  />
                )}
              />
                </Grid>
                <Grid item xs={2} sx={{display: "flex", justifyContent: 'flex-end' }}>
                <Button
                  variant="outlined"
                  sx={{ alignSelf: "flex-end", color: "black" }}
                  type="submit"
                >
                  <FontAwesomeIcon
                    icon={faCircleCheck}
                    color={"orange"}
                    size="2x"
                    style={{ marginRight: "5px" }}
                  />
                  Send
                </Button>
                </Grid>
                <Grid item xs={12} sx={{display: "flex", justifyContent: 'flex-start' }}>
                <Controller
                  name="isImportant"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      id="isImportant"
                      control={<Checkbox checked={field.value} />}
                      label="Important"
                      labelPlacement="start"
                    />
                  )}
                />
                <Controller
                  name="isInternal"
                  control={control}
                  defaultValue={false}
                  render={({ field }) => (
                    <FormControlLabel
                      {...field}
                      id="isInternal"
                      control={<Checkbox checked={field.value} />}
                      label="Internal"
                      labelPlacement="start"
                    />
                  )}
                />
                </Grid>
              </Grid>
            </form>
          </Stack>
        )}
      </CardActions>
    </Card>
  );
};

const TodaysComments: React.FC<{ comments: ChatComment[]; userId: string }> = (
  props
) => {
  var today = Today();
  var comments = props.comments.filter(({ createdDate }) => {
    var date = new Date(createdDate);
    return date >= today.start && date <= today.end;
  });

  if (comments.length === 0)
    return <Typography color="#ccc">No Comments for today</Typography>;
  return (
    <>
      {comments.map((comment: ChatComment, index: number) => {
        return (
          <ChatEntry comment={comment} key={index} userId={props.userId} />
        );
      })}
    </>
  );
};

const YesterdaysComments: React.FC<{
  comments: ChatComment[];
  userId: string;
}> = (props) => {
  const yesterday = Yesterday();
  var comments = props.comments.filter(({ createdDate }) => {
    var date = new Date(createdDate);
    return date >= yesterday.start && date <= yesterday.end;
  });

  if (comments.length === 0)
    return <Typography color="#ccc">No Comments for yesterday</Typography>;
  return (
    <>
      {comments.map((comment: ChatComment, index: number) => {
        return (
          <ChatEntry comment={comment} key={index} userId={props.userId} />
        );
      })}
    </>
  );
};

const OlderComments: React.FC<{ comments: ChatComment[]; userId: string }> = (
  props
) => {
  const yesterday = Yesterday();
  var comments = props.comments.filter(
    ({ createdDate }) => new Date(createdDate) < yesterday.start
  );

  if (comments.length === 0)
    return <Typography color="#ccc">No Older Comments</Typography>;
  return (
    <>
      {comments.map((comment: ChatComment, index: number) => {
        return (
          <ChatEntry comment={comment} key={index} userId={props.userId} />
        );
      })}
    </>
  );
};

const ChatEntry: React.FC<{ comment: ChatComment; userId: string }> = (
  props
) => {
  return props.comment.createdById.toLowerCase() ===
    props.userId.toLowerCase() ? (
    <ListItem sx={{ justifyContent: "flex-end" }}>
      <Typography sx={{ backgroundColor: "#ffe0b2", p: 2, borderRadius: 2 }}>
        <Box sx={{ fontWeight: "bold" }}>
          {FormattedDateTime(props.comment.createdDate)}
        </Box>
        {props.comment.isImportant ? (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            color={"red"}
            size="1x"
          />
        ) : (
          <></>
        )}{" "}
        {props.comment.comment}
      </Typography>
    </ListItem>
  ) : (
    <ListItem>
      <ListItemAvatar>
        <Avatar>{props.comment.createdByInitials}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography>
            <Box component="span" sx={{ fontWeight: "bold", mr: 2 }}>
              {props.comment.isImportant ? (
                <FontAwesomeIcon
                  icon={faTriangleExclamation}
                  color={"red"}
                  size="1x"
                />
              ) : (
                <></>
              )}{" "}
              {props.comment.createdByFullName} ({props.comment.createdByRole})
            </Box>
            {FormattedDateTime(props.comment.createdDate)}
          </Typography>
        }
        secondary={props.comment.comment}
      />
    </ListItem>
  );
};
