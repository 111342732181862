import React, { useEffect, useState } from "react";
import {
  faTicketSimple,
  faCircleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { NotificationBanner } from "../../../components/NotificationBanner";
import { useTheme } from "@mui/material/styles";
import { TicketSummary } from "../TicketSummary";
import { useParams } from "react-router-dom";
import { FormattedDateTime } from "../../../common/dateUtils";

import { TicketChat } from "../TicketChat";
import { AlertDetails } from "../../ViewAlert/AlertDetails";
import { AlertKnowledgeBase } from "../../ViewAlert/AlertKnowledgeBase";
import { Grid } from "@mui/material";
import { blankAlert } from "./ViewTicketPageUtilities";
import { TicketStatus } from "../../../types/tickets.types";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getTicketById } from "../../../redux/Slices/tickets.slice";
import {
  fetchAllTickets,
  fetchCommentsForTicket,
} from "../../../redux/Thunks/tickets";
import { useAsyncEffect } from "../../../common/hooks";
import { Loader } from "../../../components/Loader";
import { getAlertById } from "../../../redux/Slices/alerts.slice";
import { fetchActiveAlerts } from "../../../redux/Thunks/alerts";
import { setLayout } from "../../../redux/Slices/layout.slice";

export const ViewTicketPage = () => {
  const { id } = useParams<string>();
  const ticket = useAppSelector(getTicketById(id || ""));
  const currentAlert = useAppSelector(getAlertById(ticket?.subjectId || ""));
  const dispatch = useAppDispatch();
  const [loadingTickets, setLoadingTickets] = useState(false);

  var theme = useTheme();

  useEffect(() => {
    dispatch(
      setLayout({
        title: "View Ticket",
        subtitle: "View Ticket",
        icon: faTicketSimple,
        links: [{ href: "/tickets", text: "Tickets Dashboard" }],
      })
    );
  }, [dispatch]);

  const { loading } = useAsyncEffect(async () => {
    if (id) {
      await dispatch(fetchCommentsForTicket(id))
      if (!ticket) await dispatch(fetchAllTickets());
      if (!currentAlert) await dispatch(fetchActiveAlerts());
    }
  }, [id]);

  const onRaiseTicketSuccess = () => {
    setLoadingTickets(true);
    dispatch(fetchActiveAlerts());
    if (id) dispatch(fetchCommentsForTicket(id));
    setLoadingTickets(false);
  };

  const handleTicketAssigned = () => {
    setLoadingTickets(true);
    dispatch(fetchAllTickets());
    if (id) dispatch(fetchCommentsForTicket(id));
    setLoadingTickets(false);
  };

  const handleTicketClosed = () => {
    setLoadingTickets(true);
    dispatch(fetchAllTickets());
    if (id) dispatch(fetchCommentsForTicket(id));
    setLoadingTickets(false);
  };

  //const handleCloseTicket = (comment : string) => {
  // ticket.Data?.Close(comment).then(() => {
  //   getTicketPageData();
  // });

  // selectedTicket?.Close(closureReason).then(() => {
  //     //TODO: reuse code same as below
  //     setShowNotification(true);
  //     getOpenTickets()
  //     .then((tickets : Tickets) => {
  //         setOpenTickets({Data: tickets, IsLoaded: true, hasErrored : false});

  //     }, (error) => {
  //         setOpenTickets({Data: new Tickets([]), IsLoaded: true, hasErrored : true});
  //     });
  // })
  //};
  //if (loading) return <Loader show={true} />;
  return (
    <>
      {!loading && !loadingTickets && ticket ? (
        <>
          <NotificationBanner
            mainColour={theme.palette.info.main}
            headerText={`This ticket is closed so is now read only except for commenting.`}
            subText={`Ticket closed on ${FormattedDateTime(
              ticket?.lastModifiedDate
            )}`}
            icon={faCircleExclamation}
            showBanner={ticket?.status === TicketStatus.Closed}
            sx={{ mb: 2 }}
          />
        </>
      ) : (
        <></>
      )}
      {loading || loadingTickets ? (
        <Loader show={true} />
      ) : (
        <>
          {!loading && ticket?.subjectType !== "generic" ? (
            //  VIEW TICKET LAYOUT FOR ALERT OR DEVICE TICKETS
            <Grid container spacing={1}>
              <Grid container item xs={4} spacing={1}>
                <Grid container item xs={4} md={12}>
                  {!loading && currentAlert !== undefined ? (
                    <AlertDetails
                      alert={currentAlert}
                      onAlertTicketRaisedSuccess={onRaiseTicketSuccess}
                      page={"ticket"}
                    />
                  ) : (
                    <AlertDetails
                      alert={blankAlert}
                      onAlertTicketRaisedSuccess={onRaiseTicketSuccess}
                      page={"ticket"}
                    />
                  )}
                </Grid>
                <Grid container item xs={5} md={12}>
                  {!loading ? (
                    <AlertKnowledgeBase alert={currentAlert} />
                  ) : (
                    <AlertKnowledgeBase alert={blankAlert} />
                  )}
                </Grid>
              </Grid>
              <Grid container item xs={4}>
                {!loading && ticket ? (
                  <TicketSummary
                    ticket={ticket}
                    onTicketAssigned={handleTicketAssigned}
                    onTicketClosed={handleTicketClosed}
                  />
                ) : (
                  <>Loading ... </>
                )}
              </Grid>
              <Grid container item xs={4}>
                {!loading ? (
                  <TicketChat ticket={ticket!} />
                ) : (
                  <>Loading ... </>
                )}
              </Grid>
            </Grid>
          ) : (
            // VIEW TICKET LAYOUT FOR GENERIC TICKETS
            <Grid container spacing={1}>
              <Grid container item xs={6}>
                {!loading && ticket ? (
                  <TicketSummary
                    ticket={ticket}
                    onTicketAssigned={handleTicketAssigned}
                    onTicketClosed={handleTicketClosed}
                  />
                ) : (
                  <Loader show={true} />
                )}
              </Grid>
              <Grid container item xs={5}>
                {!loading ? (
                  <TicketChat ticket={ticket!} />
                ) : (
                  <Loader show={true} />
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
