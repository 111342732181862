import React from "react";
import * as Mui from "@mui/material";
import { faTicket } from "@fortawesome/pro-light-svg-icons";
import { Chat } from "../../components/Chat";
import { useIdentityContext } from "../../context/IdentityContext";
import { FormattedDateTime } from "../../common/dateUtils";
import { useNavigate } from "react-router-dom";
import { CardHeader } from "../../components/CardHeader";
import { AddCommentCommand } from "../../types/tickets.types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  addComment,
  fetchAllTickets,
  fetchCommentsForTicket,
} from "../../redux/Thunks/tickets";
import { getTicketById } from "../../redux/Slices/tickets.slice";
import { useAsyncEffect } from "../../common/hooks";
import { Device } from "../../types/devices.types";

interface DeviceSupportTicketChatProps {
  device: Device;
}

const DeviceSupportTicketChat: React.FC<DeviceSupportTicketChatProps> = (
  props
) => {
  const ticket = useAppSelector(getTicketById(props.device.ticketId));
  const ticketComments = ticket?.comments;

  var identityContext = useIdentityContext();
  var navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleCommentAdded = async (comment: string, isImportant: boolean, isInternal: boolean) => {
    var newTicketCommand: AddCommentCommand = {
      comment: comment,
      isImportant: isImportant,
      isInternal: isInternal,
    };

    await dispatch(
      addComment({ ticketId: props.device.ticketId, command: newTicketCommand })
    );
    await dispatch(fetchCommentsForTicket(props.device.ticketId));
  };
  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchAllTickets());
    await dispatch(fetchCommentsForTicket(props.device.ticketId));
  }, [dispatch, props.device]);

  return (
    <>
      {/* DEVICE SUPPORT TICKET RAISED */}
      {ticket && !loading ? (
        <Mui.Card sx={{ mb: 2 }}>
          <CardHeader
            icon={faTicket}
            subText={`Raised On: ${FormattedDateTime(ticket.createdDate)}`}
            title="Device Support Ticket Raised"
          />
          <Mui.CardContent>
            <Mui.Typography variant="body1">
              There is currently a support ticket raised against this device
              with a priority of
              <Mui.Box fontWeight={"bold"} component="span">
                {" "}
                {ticket.priorityDescription}.{" "}
              </Mui.Box>
              {ticket.changeManagementItemId === null
                ? "There is currently NO change management raised against this ticket."
                : "There is currently change management in place on this ticket."}{" "}
              Please see comments against this ticket below.
            </Mui.Typography>
          </Mui.CardContent>
          <Mui.CardActions disableSpacing>
            <Mui.Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => {
                navigate(`/ticket/${props.device.ticketId}`);
              }}
            >
              View the ticket
            </Mui.Button>
          </Mui.CardActions>
        </Mui.Card>
      ) : (
        <>Loading...</>
      )}

      {/* TICKET CHAT */}
      {ticketComments && !loading ? (
        <Chat
          userId={identityContext.authenticatedUser.userId}
          comments={ticketComments}
          onCommentAdded={handleCommentAdded}
          title="Support Ticket History"
          subTitle={<></>}
          disabled={false}
        />
      ) : (
        <>Loading</>
      )}
    </>
  );
};

export { DeviceSupportTicketChat };
