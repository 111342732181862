import React from "react";

import { useAsyncEffect } from "../../common/hooks";
import { Chat } from "../../components/Chat";
import { useIdentityContext } from "../../context/IdentityContext";
import { useAppDispatch } from "../../redux/hooks";
import { addComment, fetchCommentsForTicket } from "../../redux/Thunks/tickets";
import { AddCommentCommand, Ticket } from "../../types/tickets.types";

interface TicketChatProps {
  //TODO: have a look at this we are passing the whole ticket, when it looks like the id will suffice.
  //but be carefull of the useeffect on the id as it may not react as adding a comment wont change the
  //ticket and it is reliant on that change.
  ticket: Ticket;
}

export const TicketChat: React.FC<TicketChatProps> = (props) => {
  var identityContext = useIdentityContext();
  const dispatch = useAppDispatch();

  const { loading } = useAsyncEffect(async () => {
    dispatch(fetchCommentsForTicket(props?.ticket?.id));
  }, [props?.ticket?.id]);

  if (!props.ticket) {
    return null;
  }
  const comments = props?.ticket?.comments;

  const handleCommentAdded = async (
    comment: string,
    isImportant: boolean,
    isInternal: boolean
  ) => {
    var newCommentCommand: AddCommentCommand = {
      comment: comment,
      isImportant: isImportant,
      isInternal: isInternal,
    };
    await dispatch(
      addComment({ ticketId: props.ticket.id, command: newCommentCommand })
    );
    await dispatch(fetchCommentsForTicket(props?.ticket?.id));
  };

  return (
    <>
      {comments && !loading ? (
        <Chat
          userId={identityContext.authenticatedUser.userId}
          comments={comments}
          onCommentAdded={handleCommentAdded}
          title="Ticket History"
          subTitle={<></>}
          disabled={false}
        />
      ) : (
        <Chat
          userId={identityContext.authenticatedUser.userId}
          comments={[]}
          onCommentAdded={handleCommentAdded}
          title="Ticket History"
          subTitle={"Ticket has been closed."}
          disabled={true}
        />
      )}
    </>
  );
};
