import React from "react";
import * as Mui from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ConfirmSignupSetPasswordProps {
  onNextClick: (password: string) => void;
  onPreviousClick: VoidFunction;
}

interface PasswordRequirements {
  has8Letters: boolean;
  containsLowerCase: boolean;
  containsUpperCase: boolean;
  containsDigit: boolean;
  containsUnique: boolean;
}

export const ConfirmSignupSetPassword: React.FC<
  ConfirmSignupSetPasswordProps
> = (props) => {
  const validationSchema = yup.object().shape({
    passwordConfirm: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        "Password confirmation does not match the password"
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<{ password: string; passwordConfirm: string }>({
    resolver: yupResolver(validationSchema),
  });

  const [passwordRequirements, setPasswordRequirements] =
    React.useState<PasswordRequirements>({
      has8Letters: false,
      containsLowerCase: false,
      containsUpperCase: false,
      containsDigit: false,
      containsUnique: false,
    });

  const [passwordValid, setPasswordValid] = React.useState<boolean>(false);

  const validatePassword = (password: string) => {
    var requirements = passwordRequirements;

    // has special character
    if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password))
      requirements.containsUnique = true;
    else requirements.containsUnique = false;

    //has 8 chars
    if (password.length >= 8) requirements.has8Letters = true;
    else requirements.has8Letters = false;

    // has number
    if (/\d/.test(password)) requirements.containsDigit = true;
    else requirements.containsDigit = false;

    // has uppercase letter
    if (password.toLowerCase() != password)
      requirements.containsUpperCase = true;
    else requirements.containsUpperCase = false;

    // has lowercase letter
    if (password.toUpperCase() != password)
      requirements.containsLowerCase = true;
    else requirements.containsLowerCase = false;

    setPasswordRequirements({ ...requirements });

    var isValid =
      requirements.containsDigit &&
      requirements.containsLowerCase &&
      requirements.containsUnique &&
      requirements.containsUpperCase &&
      requirements.has8Letters;

    setPasswordValid(isValid);
  };

  const onSubmit: SubmitHandler<{
    password: string;
    passwordConfirm: string;
  }> = (data) => {
    props.onNextClick(data.password);
  };

  return (
    <Mui.Stack
      direction="column"
      alignItems={"center"}
      sx={{ width: { xs: "100%", md: "70%", xxl: "55%" } }}
      spacing={2}
      padding={2}
    >
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        Please enter your password
      </Mui.Typography>
      <Mui.Typography
        sx={{
          pt: 1,
          maxWidth: "75%",
          textAlign: "center",
          color: "#aaa",
          pb: 6,
        }}
        variant="body2"
      >
        Please create a password for use in the portal.
      </Mui.Typography>
      <Mui.Grid container>
        <Mui.Grid item xs={6}>
          <Mui.Typography color="#999">Password Requirements</Mui.Typography>
          <Mui.Divider sx={{ width: "90%" }} />
          <Mui.List>
            <Mui.ListItem sx={{ p: 0 }}>
              <Mui.ListItemAvatar>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={
                    passwordRequirements.has8Letters ? "limegreen" : "#999"
                  }
                  size="1x"
                />
              </Mui.ListItemAvatar>
              <Mui.ListItemText primary="8 Letters" />
            </Mui.ListItem>
            <Mui.ListItem sx={{ p: 0 }}>
              <Mui.ListItemAvatar>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={
                    passwordRequirements.containsLowerCase
                      ? "limegreen"
                      : "#999"
                  }
                  size="1x"
                />
              </Mui.ListItemAvatar>
              <Mui.ListItemText primary="Contains a lowercase letter" />
            </Mui.ListItem>
            <Mui.ListItem sx={{ p: 0 }}>
              <Mui.ListItemAvatar>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={
                    passwordRequirements.containsUpperCase
                      ? "limegreen"
                      : "#999"
                  }
                  size="1x"
                />
              </Mui.ListItemAvatar>
              <Mui.ListItemText primary="Contains a uppercase letter" />
            </Mui.ListItem>
            <Mui.ListItem sx={{ p: 0 }}>
              <Mui.ListItemAvatar>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={
                    passwordRequirements.containsDigit ? "limegreen" : "#999"
                  }
                  size="1x"
                />
              </Mui.ListItemAvatar>
              <Mui.ListItemText primary="Contains a digit" />
            </Mui.ListItem>
            <Mui.ListItem sx={{ p: 0 }}>
              <Mui.ListItemAvatar>
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={
                    passwordRequirements.containsUnique ? "limegreen" : "#999"
                  }
                  size="1x"
                />
              </Mui.ListItemAvatar>
              <Mui.ListItemText primary="Contains a special character" />
            </Mui.ListItem>
          </Mui.List>
        </Mui.Grid>
        <Mui.Grid item xs={6}>
          <Mui.Stack
            direction="column"
            alignItems={"left"}
            sx={{ width: "100%", pt: 1 }}
            spacing={2}
          >
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{ width: "100%", paddingLeft: 0 }}
            >
              <Mui.Stack
                direction="column"
                alignItems={"center"}
                spacing={2}
                padding={0}
              >
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field: { name, value, onChange } }) => (
                    <Mui.TextField
                      name={name}
                      value={value}
                      size="small"
                      required
                      onChange={(e) => {
                        onChange(e);
                        validatePassword(e.currentTarget.value);
                      }}
                      label="Password"
                      type={"password"}
                      sx={{ width: "100%" }}
                      helperText={"Please enter your password"}
                      error={errors.password !== undefined}
                    />
                  )}
                />
                <Controller
                  name="passwordConfirm"
                  control={control}
                  defaultValue=""
                  render={({ field: { name, value, onChange } }) => (
                    <>
                      <Mui.TextField
                        name={name}
                        value={value}
                        size="small"
                        required
                        onChange={onChange}
                        label="Password Confirmation"
                        sx={{ width: "100%" }}
                        type={"password"}
                        helperText={
                          errors.passwordConfirm ? (
                            <Mui.Typography variant="subtitle2">
                              {errors.passwordConfirm?.message}
                            </Mui.Typography>
                          ) : (
                            <>Please confirm the email of the primary contact</>
                          )
                        }
                        error={errors.passwordConfirm !== undefined}
                      />
                    </>
                  )}
                />
                <Mui.FormGroup row>
                  <Mui.Button
                    disabled={!passwordValid}
                    variant="outlined"
                    color="primary"
                    type="submit"
                    sx={{ mt: 2 }}
                  >
                    Set Password
                  </Mui.Button>
                </Mui.FormGroup>
              </Mui.Stack>
            </form>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Stack>
  );
};
