import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { Box, Grid } from "@mui/material";
import { Card } from "../../components/Card";
import { InvestigatedEntitiesLink } from "./InvestigatedEntitiesLink";
import { InvestigatedTypesLink } from "./InvestigatedTypesLink";
import { StartTimeEndTime } from "./StartTimeEndTime";



interface InvestigateDeviceTableSummaryProps {
    investigatedDevice: any;
    currentDevice: any;
}

export const InvestigateDeviceTableSummary = (props: InvestigateDeviceTableSummaryProps) => {
    const { investigatedDevice, currentDevice } = props

    return (
        <Card
            id="Tickets"
            headerIcon={faMagnifyingGlass}
            headerTitle={"Device Investigation"}
            headerSubText={"Investigating further will take you away from the initial device."}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={""}
            cardContent={
                <Box>
                    <Grid container spacing={0}>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <strong>Investigating Device: </strong>
                            {currentDevice?.description}
                        </Grid>

                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <StartTimeEndTime
                                investigatedDevice={investigatedDevice}
                                currentDevice={currentDevice}
                            />

                        </Grid>

                        <Grid item xs={10} sx={{ mb: 1 }}>
                            <InvestigatedEntitiesLink investigatedDevice={investigatedDevice} />

                        </Grid>
                        <Grid item xs={2} sx={{ position: 'absolute', right: 30, mb: 1 }} >
                            {/* <InvestigationSummaryButtonGroup investigatedDevice={investigatedDevice} /> */}

                        </Grid>
                        <Grid item xs={12} sx={{ mb: 1 }}>
                            <InvestigatedTypesLink
                                investigatedDevice={investigatedDevice}
                                currentDevice={currentDevice}
                            />

                        </Grid>
                    </Grid>
                    <Box>


                    </Box>









                </Box>
            }
        />
    );
};
