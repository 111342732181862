import React, { useEffect } from "react";

import { useParams } from "react-router-dom";
import * as Mui from "@mui/material";
import { faBallotCheck } from "@fortawesome/pro-light-svg-icons";
import { ChangeManagementItemChat } from "./ChangeManagementItemChat";
import { Plans } from "./Plans";
import { Milestones } from "./Milestones";
import { ManualSignoff } from "./ManualSignoff";
import { Notifications } from "./Notifications";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getChangeManagementItem } from "../../redux/Slices/changeManagement.slice";
import {
  approveChangeManagementItem,
  canEditChangeManagementItem,
  fetchChangeManagementItemById,
  requestSignoffChangeManagementItem,
  signoffChangeManagementItem,
} from "../../redux/Thunks/changeManagement";
import { useAsyncEffect } from "../../common/hooks";
import { setLayout } from "../../redux/Slices/layout.slice";
import { ViewChangeManagementSummary } from "./ViewChangeManagementSummary";

export const ViewChangeManagementItemPage = () => {
  const { id } = useParams<string>();
  const changeManagementItem = useAppSelector(getChangeManagementItem);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(
      setLayout({
        title: "View Change Mgmt",
        subtitle: "View Change Mgmt",
        icon: faBallotCheck,
        links: [{ href: "/changemanagement", text: "Change Mgmt Dashboard" }],
      })
    );
  }, [dispatch]);

  const loadChangeManagementItem = async () => {
    if (id) {
      await dispatch(fetchChangeManagementItemById(id));
    }
  };

  const { loading } = useAsyncEffect(loadChangeManagementItem, [id]);

  const handleStatusChange = () => {
    loadChangeManagementItem();
  };

  const handleApprovePlan = async () => {
    if (!id) {
      return;
    }
    await dispatch(
      approveChangeManagementItem({
        changeManagementId: id,
        planName: changeManagementItem?.title || "",
        assignedEngineerId: changeManagementItem?.assignedEngineerId || "",
      })
    );
    await loadChangeManagementItem();
  };

  const handleRequestSignoff = async () => {
    if (!id) {
      return;
    }
    await dispatch(
      requestSignoffChangeManagementItem({
        changeManagementId: id,
        planName: changeManagementItem?.title || "",
      })
    );
    await loadChangeManagementItem();
  };

  const handleSignoff = async () => {
    if (!id) {
      return;
    }
    await dispatch(
      signoffChangeManagementItem({
        changeManagementId: id,
        planName: changeManagementItem?.title || "",
        assignedEngineerId: changeManagementItem?.assignedEngineerId || "",
      })
    );
    await loadChangeManagementItem();
  };

  return (
    <>
      {!loading && changeManagementItem ? (
        <Notifications
          changeManagementItem={changeManagementItem}
          onPlanApproved={handleApprovePlan}
          onRequestSignoff={handleRequestSignoff}
          onSignoff={handleSignoff}
        />
      ) : (
        <></>
      )}

      <Mui.Grid container spacing={2}>
        <Mui.Grid item xs={12} md={12}>
          
            <ViewChangeManagementSummary changeManagementItem={changeManagementItem}/>

        </Mui.Grid>
        <Mui.Grid item xs={12} md={8}>
          <Mui.Stack sx={{ width: "100%" }} spacing={2} direction="column">
            {/* TODO: this is a little messy */}
            {!loading ? (
              <>
                {changeManagementItem?.isManualSignoff ? (
                  <ManualSignoff
                    onStatusChange={handleStatusChange}
                    changeManagementItem={changeManagementItem}
                  />
                ) : (
                  <>
                    {changeManagementItem && (
                      <>
                        <Plans
                          changeManagementItem={changeManagementItem}
                          isReadOnly={
                            !canEditChangeManagementItem(changeManagementItem)
                          }
                        />
                        <Milestones
                          changeManagementItem={changeManagementItem}
                          isReadOnly={
                            !canEditChangeManagementItem(changeManagementItem)
                          }
                        />
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>Loading...</>
            )}
          </Mui.Stack>
        </Mui.Grid>

        <Mui.Grid item xs={12} md={4}>
          {!loading && changeManagementItem ? (
            <ChangeManagementItemChat item={changeManagementItem} />
          ) : (
            <>Loading ... </>
          )}
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
