import {
  faCartShopping,
  faSquareCheck,
  faSquare,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useState } from "react";

import protectAxios from "../../api/axios";
import { Card } from "../../components/Card";
import { StripeProduct } from "../../types/stripe.type";
import { PortalPrivacyPolicy } from "./PortalPrivacyPolicy";

export interface IProductCheckout {
  product: StripeProduct;
  onBack: () => void;
}

const ProductCheckout = (props: IProductCheckout) => {
  const { product, onBack } = props;
  const [quantity, setQuantity] = useState<number | string>(0);
  const [TOSIdx, setTOSIdx] = useState(-1);
  const [agree, setAgree] = useState(false);

  const checkout = async () => {
    const { redirectUrl } = await protectAxios
      .post("/companycommands/Payment/create-checkout-session", {
        items: [{ id: product.productId, quantity }],
      })
      .then((resp) => resp.data);
    window.location.href = redirectUrl;
  };

  const openTOS = () => {
    setTOSIdx(1);
  };
  const agreeToTerms = () => {
    setAgree(!agree);
  };
  if (TOSIdx !== -1) {
    return (
      <PortalPrivacyPolicy
        onAccept={() => {
          setTOSIdx(-1);
          setAgree(true);
        }}
      />
    );
  }
  return (
    <div>
      <Card
        id="Purchase"
        headerIcon={faCartShopping}
        headerTitle={"Purchase"}
        headerSubText={""}
        showHelp={false}
        helpHeaderText=""
        helpHeaderDescription=""
        cardHeaderActions={""}
        cardContent={<Box sx={{ width: "100%", maxWidth: 500 }}></Box>}
      />

      <Paper sx={{ marginTop: "10px", padding: 3, height: "70vh" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={12}>
            <Grid container justifyContent={"flex-start"}>
              <Button variant="contained" onClick={onBack}>
                Back
              </Button>
            </Grid>
            <Grid container justifyContent={"flex-end"}>
              <Typography
                component="h5"
                sx={{
                  display: "inline-block",
                  paddingRight: "15px",
                  verticalAlign: "sub",
                }}
              >
                ${product.price} / month
              </Typography>
              {/* QUANTITY */}
              <TextField
                label="Quantity"
                size="small"
                type="number"
                variant="outlined"
                onChange={(e: any) => {
                  if (
                    isNaN(parseInt(e.target.value)) &&
                    e.target.value !== ""
                  ) {
                    return;
                  }
                  setQuantity(parseInt(e.target.value));
                }}
                value={quantity.toString()}
              ></TextField>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
            >
              <Box sx={{ margin: "0 20vw 15vh 20vw" }}>
                <Typography
                  sx={{ paddingBottom: "5vh", textAlign: "left" }}
                  variant="h2"
                >
                  {product.name}
                </Typography>
                <Typography sx={{ textAlign: "left" }}>
                  {product.description}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ position: "relative" }}>
            <Grid
              container
              justifyContent={"flex-end"}
              sx={{ position: "absolute", bottom: "0px" }}
            >
              <Tooltip title="Agree to Term and Conditions">
                <Button onClick={agreeToTerms} sx={{ padding: "0px" }}>
                  {agree ? (
                    <FontAwesomeIcon
                      icon={faSquareCheck}
                      color={"#e88f00"}
                      size="2x"
                      style={{ padding: "0px" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faSquare}
                      color={"#aaa"}
                      size="2x"
                      style={{ padding: "0px" }}
                    />
                  )}
                </Button>
              </Tooltip>

              <Tooltip title="Read Term and Conditions">
                <Button
                  variant="text"
                  onClick={openTOS}
                  sx={{
                    color: "black",
                    padding: "0px 30px 0px 0px",
                  }}
                >
                  Agree to Terms and Conditions
                </Button>
              </Tooltip>

              <Button
                sx={{ height: "30px", width: "120px" }}
                variant="contained"
                disabled={quantity === 0 || quantity === "" || !agree}
                onClick={checkout}
              >
                Checkout
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ProductCheckout;
