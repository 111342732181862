import { Button, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { fetchIpLocation, fetchPortDescription } from "../../redux/Thunks/alerts";


interface RowProps {
    row: any;
    handleSearchParams: (value: any, key: string) => void;

}

export const SourcePortRow = (props: RowProps) => {
    const { row, handleSearchParams } = props
    const dispatch = useAppDispatch();
    const [tooltip, setTooltip] = useState('')
    const [open, setOpen] = useState(false)
    const handleOpen = async (value: any) => {
        setOpen(true)
        let response: any = []
        let returnArray: any = []
        const port = await dispatch(fetchPortDescription(value))
        const titleCase = [{
            'Port': port.payload[0].port,
            'Service Name': port.payload[0].serviceName,
            'Protocol': port.payload[0].protocol,
            'Description': port.payload[0].description,
        }]
        response = titleCase
        response.forEach((value: any) => {
            Object.entries(value).forEach((val: any) => {
                returnArray.push(<Typography>{val[0].charAt(0).toUpperCase() + val[0].slice(1)}: {val[1]}</Typography>)
            })
        })
        setTooltip(returnArray)
    }
    return (
        <Tooltip
            title={tooltip}
            open={open}
            onOpen={() => { handleOpen(row.sourcePort) }}
            onClose={() => { setOpen(false) }}
        >
            <Button
                variant="text"
                onClick={() => handleSearchParams(row.sourcePort, 'sourcePort')}>
                {row.sourcePort}
            </Button>
        </Tooltip>
    )
}



export const SourceIPRow = (props: RowProps) => {
    const { row, handleSearchParams } = props
    const dispatch = useAppDispatch();
    const [tooltip, setTooltip] = useState('')
    const [open, setOpen] = useState(false)
    const handleOpen = async (value: any) => {
        setOpen(true)
        let response: any = []
        let returnArray: any = []
        const ip = await dispatch(fetchIpLocation(value))
        if (ip.payload) {
            const titleCase = [{
                'Ip': ip.payload.ip,
                'City': ip.payload.city,
                'Country': ip.payload.country,
                'Country Code': ip.payload.countryCode,
                'Isp': ip.payload.isp,
                'Org': ip.payload.org,
                'region': ip.payload.region,
                'Region Name': ip.payload.regionName,
                'Zip': ip.payload.zip,
            }]
            response = titleCase
        }
        response.forEach((value: any) => {
            Object.entries(value).forEach((val: any) => {
                returnArray.push(<Typography>{val[0].charAt(0).toUpperCase() + val[0].slice(1)}: {val[1]}</Typography>)
            })
        })
        setTooltip(returnArray)
    }
    return (
        <Tooltip
            title={tooltip}
            open={open}
            onOpen={() => { handleOpen(row.sourceIP) }}
            onClose={() => { setOpen(false) }}
        >
            <Button
                variant="text"
                onClick={() => handleSearchParams(row.sourceIP, 'sourceIP')}>
                {row.sourceIP}
            </Button>
        </Tooltip>
    )
}


export const DestinationPortRow = (props: RowProps) => {
    const { row, handleSearchParams } = props
    const dispatch = useAppDispatch();
    const [tooltip, setTooltip] = useState('')
    const [open, setOpen] = useState(false)
    const handleOpen = async (value: any) => {
        setOpen(true)
        let response: any = []
        let returnArray: any = []
        const port = await dispatch(fetchPortDescription(value))
        const titleCase = [{
            'Port': port.payload[0].port,
            'Service Name': port.payload[0].serviceName,
            'Protocol': port.payload[0].protocol,
            'Description': port.payload[0].description,
        }]
        response = titleCase
        response.forEach((value: any) => {
            Object.entries(value).forEach((val: any) => {
                returnArray.push(<Typography>{val[0].charAt(0).toUpperCase() + val[0].slice(1)}: {val[1]}</Typography>)
            })
        })
        setTooltip(returnArray)
    }
    return (
        <Tooltip
            title={tooltip}
            open={open}
            onOpen={() => { handleOpen(row.destinationPort) }}
            onClose={() => { setOpen(false) }}
        >
            <Button
                variant="text"
                onClick={() => handleSearchParams(row.destinationPort, 'destinationPort')}>
                {row.destinationPort}
            </Button>
        </Tooltip>
    )
}


export const DestinationIPRow = (props: RowProps) => {
    const { row, handleSearchParams } = props
    const dispatch = useAppDispatch();
    const [tooltip, setTooltip] = useState('')
    const [open, setOpen] = useState(false)
    const handleOpen = async (value: any) => {
        setOpen(true)
        let response: any = []
        let returnArray: any = []
        const ip = await dispatch(fetchIpLocation(value))
        if (ip.payload) {
            const titleCase = [{
                'Ip': ip.payload.ip,
                'City': ip.payload.city,
                'Country': ip.payload.country,
                'Country Code': ip.payload.countryCode,
                'Isp': ip.payload.isp,
                'Org': ip.payload.org,
                'region': ip.payload.region,
                'Region Name': ip.payload.regionName,
                'Zip': ip.payload.zip,
            }]
            response = titleCase
        }
        response.forEach((value: any) => {
            Object.entries(value).forEach((val: any) => {
                returnArray.push(<Typography>{val[0].charAt(0).toUpperCase() + val[0].slice(1)}: {val[1]}</Typography>)
            })
        })
        setTooltip(returnArray)
    }
    return (
        <Tooltip
            title={tooltip}
            open={open}
            onOpen={() => { handleOpen(row.destinationIP) }}
            onClose={() => { setOpen(false) }}
        >
            <Button
                variant="text"
                onClick={() => handleSearchParams(row.destinationIP, 'destinationIP')}>
                {row.destinationIP}
            </Button>
        </Tooltip>
    )
}