import { FirewallResponse } from "../../types/alerts.types";

interface HeadCell {
  disablePadding: boolean;
  id: keyof FirewallResponse;
  label: string;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: "timeGenerated",
    numeric: true,
    disablePadding: true,
    label: "Time Generated",
  },
  {
    id: "sourcePort",
    numeric: true,
    disablePadding: true,
    label: "Source Port",
  },
  {
    id: "sourceIP",
    numeric: true,
    disablePadding: true,
    label: "Source IP",
  },
  {
    id: "deviceAction",
    numeric: true,
    disablePadding: true,
    label: "Device Action",
  },

  {
    id: "destinationPort",
    numeric: true,
    disablePadding: true,
    label: "Destination Port",
  },
  {
    id: "destinationIP",
    numeric: true,
    disablePadding: true,
    label: "Destination IP",
  },
  {
    id: "applicationProtocol",
    numeric: true,
    disablePadding: true,
    label: "Application Protocol",
  },
  {
   id: "type",
   numeric: true,
   disablePadding: true,
   label: "Type",
 },
 {
  id: "additionalInformation",
  numeric: true,
  disablePadding: true,
  label: "Additional Information",
},
];
