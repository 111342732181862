import { Button, Card } from "@mui/material";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "../../redux/hooks";
import { setTermsOfUse } from "../../redux/Slices/company.slice";

const cardStyle = {
  position: "absolute",
  margin: "50px",
  padding: "50px",
};

export const TermsOfUsePage = () => {
  const dispatch = useAppDispatch();

  const handleAgreeClick = () => {
    dispatch(setTermsOfUse(true));
  };
  return (
    <Card sx={cardStyle}>
      
      <div>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', textAlign: 'center', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', fontWeight: 'bold'}}>PORTAL TERMS OF USE</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Acceptance of the Terms of Use</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>These terms of use are entered into by and between You and SecQube, Inc. (“<span style={{color: 'black', fontWeight: 'bold'}}>Company,</span>” “<span style={{color: 'black', fontWeight: 'bold'}}>we,</span>” or “<span style={{color: 'black', fontWeight: 'bold'}}>us</span>”). The following terms and conditions, together with any documents they expressly incorporate by reference (collectively, “<span style={{color: 'black', fontWeight: 'bold'}}>Terms of Use</span>”), govern your access to and use of the services of the Company, including any content, functionality and services offered on or through the Company Portal (the “<span style={{color: 'black', fontWeight: 'bold'}}>Portal</span>”), whether as a guest or a registered user.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>Please read the Terms of Use carefully before you start to use the Portal. <strong>By using the Portal, you accept and agree to be bound and abide by these Terms of Use.</strong> If you do not want to agree to these Terms of Use, you must not access or use the Portal.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Changes to the Terms of Use</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>Your continued use of the Portal following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Accessing the Portal and Account Security</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>We reserve the right to withdraw or amend this Portal, and any service or material we provide, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Portal is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Portal, or the entire Portal, to users, including registered users.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}>You are responsible for both:</p>
        <ul style={{listStyleType: 'disc', marginLeft: '0in'}}>
          <li>Making all arrangements necessary for you to have access to the Portal.</li>
          <li>Ensuring that all persons who access the Portal through your internet connection are aware of these Terms of Use and comply with them.</li>
        </ul>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>To access the Portal or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Portal that all the information you provide on the Portal is correct, current and complete.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>If you choose, or are provided with, a user name, password or any other piece of information as part of our security procedures, you must treat such information as confidential, and you must not disclose it to any other person or entity. You also acknowledge that your account is personal to you and agree not to provide any other person with access to this Portal or portions of it using your user name, password or other security information. You agree to notify us immediately of any unauthorized access to or use of your user name or password or any other breach of security. You also agree to ensure that you exit from your account at the end of each session. You should use particular caution when accessing your account from a public or shared computer so that others are not able to view or record your password or other personal information.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>We have the right to disable any user name, password or other identifier, whether chosen by you or provided by us, at any time in our sole discretion for any or no reason, including if, in our opinion, you have violated any provision of these Terms of Use.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Intellectual Property Rights</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>The Portal and its entire contents, features and functionality (including but not limited to all information, software, text, displays, images, video and audio, and the design, selection and arrangement thereof), are owned by the Company, its licensors or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>These Terms of Use permit you to use the Portal for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store or transmit any of the material on our Portal, except as follows:</p>
        <ul style={{listStyleType: 'disc', marginLeft: '0in'}}>
          <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
          <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
          <li>You may print one copy of a reasonable number of pages of the Portal for your own personal, non-commercial use and not for further reproduction, publication or distribution.</li>
          <li>If we provide desktop, mobile or other applications for download, you may download a single copy to your computer or mobile device solely for your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.</li>
        </ul>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif'}}>You must not:</p>
        <ul style={{listStyleType: 'disc', marginLeft: '0in'}}>
          <li>Modify copies of any materials from this site.</li>
          <li>Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials from this site.</li>
        </ul>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>You must not access or use for any commercial purposes any part of the Portal or any services or materials available through the Portal.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Portal in breach of the Terms of Use, your right to use the Portal will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title or interest in or to the Portal or any content on the Portal is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Portal not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark and other laws.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Prohibited Uses</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>You may use the Portal only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Portal:</p>
        <ul style={{listStyleType: 'disc', marginLeft: '0in'}}>
          <li>In any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).</li>
          <li>For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise.</li>
          <li>To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail,” “chain letter,” “spam,” or any other similar solicitation.</li>
          <li>To impersonate or attempt to impersonate the Company, a Company employee, another user or any other person or entity (including, without limitation, by using e-mail addresses associated with any of the foregoing).</li>
          <li>To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Portal, or which, as determined by us, may harm the Company or users of the Portal, or expose them to liability.</li>
        </ul>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>Additionally, you agree not to:</p>
        <ul style={{listStyleType: 'disc', marginLeft: '0in'}}>
          <li>Use the Portal in any manner that could disable, overburden, damage, or impair the site or interfere with any other party’s use of the Portal, including their ability to engage in real time activities through the Portal.</li>
          <li>Use any robot, spider or other automatic device, process or means to access the Portal for any purpose, including monitoring or copying any of the material on the Portal.</li>
          <li>Use any manual process to monitor or copy any of the material on the Portal, or for any other purpose not expressly authorized in these Terms of Use, without our prior written consent.</li>
          <li>Use any device, software or routine that interferes with the proper working of the Portal.</li>
          <li>Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious or technologically harmful.</li>
          <li>Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Portal, the server on which the Portal is stored, or any server, computer or database connected to the Portal.</li>
          <li>Attack the Portal via a denial-of-service attack or a distributed denial-of-service attack.</li>
          <li>Otherwise attempt to interfere with the proper working of the Portal.</li>
        </ul>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>User Contributions</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>The Portal may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features (collectively, “<span style={{color: 'black', fontWeight: 'bold'}}>Interactive Services</span>”) that allow users to post, submit, publish, display or transmit to other users or other persons (hereinafter, “<span style={{color: 'black', fontWeight: 'bold'}}>post</span>”) content or materials (collectively, “<span style={{color: 'black', fontWeight: 'bold'}}>User Contributions</span>”) on or through the Portal.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>All User Contributions must comply with the Content Standards set out in these Terms of Use.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>Any User Contribution you post to the site will be considered non-confidential and non-proprietary. By providing any User Contribution on the Portal, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise disclose to third parties any such material for any purpose.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>You represent and warrant that:</p>
        <ul style={{listStyleType: 'disc', marginLeft: '0in'}}>
          <li>You own or control all rights in and to the User Contributions and have the right to grant the license granted above to us and our affiliates and service providers, and each of their and our respective licensees, successors and assigns.</li>
          <li>All of your User Contributions do and will comply with these Terms of Use.</li>
        </ul>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>You understand and acknowledge that you are responsible for any User Contributions you submit or contribute, and you, not the Company, have fully responsibility for such content, including its legality, reliability, accuracy and appropriateness.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>We are not responsible, or liable to any third party, for the content or accuracy of any User Contributions posted by you or any other user of the Portal.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Monitoring and Enforcement; Termination</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>We have the right to:</p>
        <ul style={{listStyleType: 'disc', marginLeft: '0in'}}>
          <li>Remove or refuse to post any User Contributions for any or no reason in our sole discretion.</li>
          <li>Take any action with respect to any User Contribution that we deem necessary or appropriate in our sole discretion, including if we believe that such User Contribution violates the Terms of Use, including the Content Standards, infringes any intellectual property right or other right of any person or entity, threatens the personal safety of users of the Portal or the public or could create liability for the Company.</li>
          <li>Disclose your identity or other information about you to any third party who claims that material posted by you violates their rights, including their intellectual property rights or their right to privacy.</li>
          <li>Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Portal.</li>
          <li>Terminate or suspend your access to all or part of the Portal for any or no reason, including without limitation, any violation of these Terms of Use.</li>
        </ul>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>Without limiting the foregoing, we have the right to fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or other information of anyone posting any materials on or through the Portal. YOU WAIVE AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT AUTHORITIES.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>However, we do not undertake to review all material before it is posted on the Portal, and cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no liability for any action or inaction regarding transmissions, communications or content provided by any user or third party. We have no liability or responsibility to anyone for performance or nonperformance of the activities described in this section.</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', background: 'white'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: '#252525'}}>Content Standards</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', background: 'white'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: '#252525'}}>&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', background: 'white'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: '#252525'}}>These content standards (the “<strong>Content Standards</strong>”) apply to any and all User Contributions and use&nbsp;of Interactive Services. User Contributions must in their entirety comply with all applicable federal, state, local, and international laws and regulations. Without limiting the foregoing, User Contributions must not:</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0in'}}>
          <li>Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent, hateful, inflammatory, or otherwise objectionable.</li>
          <li>Promote sexually explicit or pornographic material, violence, or discrimination based on race, sex, religion, nationality, disability, sexual orientation, or age.</li>
          <li>Infringe any patent, trademark, trade secret, copyright, or other intellectual property or other rights of any other person.</li>
          <li>Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that could give rise to any civil or criminal liability under applicable laws or regulations or that otherwise may be in conflict with these&nbsp;Terms&nbsp;of&nbsp;Use&nbsp;and our Privacy Policy.</li>
          <li>Be likely to deceive any person.</li>
          <li>Promote any illegal activity, or advocate, promote, or assist any unlawful act.</li>
          <li>Cause annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, alarm, or annoy any other person.</li>
          <li>Impersonate any person, or misrepresent your identity or affiliation with any person or organisation.</li>
          <li>Involve commercial activities or sales, such as contests, sweepstakes, and other sales promotions, barter, or advertising.</li>
          <li>Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.</li>
        </ul>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Reliance on Information Posted</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>The information presented on or through the Portal is made available solely for general information purposes. We do not warrant the accuracy, completeness or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Portal, or by anyone who may be informed of any of its contents.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>This Portal may include content provided by third parties, including materials provided by other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting services. All statements and/or opinions expressed in these materials, and all articles and responses to questions and other content, other than the content provided by the Company, are solely the opinions and the responsibility of the person or entity providing those materials. These materials do not necessarily reflect the opinion of the Company. We are not responsible, or liable to you or any third party, for the content or accuracy of any materials provided by any third parties.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Changes to the Portal</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>We may update the content on this Portal from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Portal may be out of date at any given time, and we are under no obligation to update such material.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Geographic Restrictions</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>The owner of the Portal is based in the United Kingdom. We make no claims that the Portal or any of its content is accessible or appropriate outside of the United States. Access to the Portal may not be legal by certain persons or in certain countries. If you access the Portal from outside the United States, you do so on your own initiative and are responsible for compliance with local laws.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Disclaimer of Warranties</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Portal will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE PORTAL OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PORTAL OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY PORTAL LINKED TO IT.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>YOUR USE OF THE PORTAL, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PORTAL IS AT YOUR OWN RISK. THE PORTAL, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PORTAL ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE PORTAL. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE PORTAL, ITS CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PORTAL WILL BE ACCURATE, RELIABLE, ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE PORTAL OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PORTAL WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline', textAlign: 'justify'}}>Limitation on Liability</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE PORTAL, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PORTAL OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Indemnification</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to, your User Contributions, any use of the Website’s content, services and products other than as expressly authorized in these Terms of Use, or your use of any information obtained from the Website.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Governing Law and Jurisdiction</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the United Kingdom without giving effect to any choice or conflict of law provision or rule (whether of the United Kingdom or any other jurisdiction).</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the Portal shall be instituted exclusively in the federal courts of the United Kingdom. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Arbitration</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>At Company’s sole discretion, it may require You to submit any disputes arising from these Terms of Use or use of the Portal, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration applying UK law.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Limitation on Time to File Claims</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE PORTAL MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Waiver and Severability</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>No waiver of by the Company of any term or condition set forth in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
        <p style={{margin: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman Bold",serif', fontWeight: 'bold', textDecoration: 'underline'}}>Entire Agreement</p>
        <p style={{marginTop: '12.0pt', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', textIndent: '0in', lineHeight: '15.0pt', fontSize: '16px', fontFamily: '"Times New Roman",serif', textAlign: 'justify'}}>The Terms of Use constitute the sole and entire agreement between you and SecQube LTD. with respect to the Portal and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Portal.</p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif'}}>&nbsp;</p>
      </div>
      <NavLink
        to={"/signup"}
        title={""}
        className={({ isActive }) =>
          "header-link" + (isActive ? " active" : " ")
        }
        end
      >
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          sx={{ mt: 2 }}
          onClick={handleAgreeClick}
        >
          I Agree
        </Button>
      </NavLink>
    </Card>
  );
};
