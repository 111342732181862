import { faCircleMinus, faCirclePlus, faTrashCan, faWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Typography, CardContent, Accordion, AccordionSummary, AccordionDetails, Grid, Divider, CardActions, FormGroup, Button, InputLabel, Box, Tooltip, TextField, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { getUsers } from "../../redux/Slices/account.slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ChangeManagementItemSummary, ChangeManagementCreateOrEditCommand } from "../../types/changemanagement.types";
import { getMilestones } from "../../redux/Slices/changeManagement.slice";
import { createOrEditChangeManagementItem, fetchPlansForChangeManagementItem } from "../../redux/Thunks/changeManagement";
// import dayjs from "dayjs";
import { cardStyle, formatMilestones } from "./ChangeManagementUtils";
import { useIdentityContext } from "../../context/IdentityContext";
import dayjs from "dayjs";
import { PlanCheck } from "./PlanCheck";
import RGL, { WidthProvider } from "react-grid-layout";
const ReactGridLayout = WidthProvider(RGL);



interface EditPlanFormProps {
    onClose: () => void;
    plans: any[];
    changeManagementItem: ChangeManagementItemSummary;
}

export const EditPlanForm = (props: EditPlanFormProps) => {
    const { onClose, plans, changeManagementItem } = props
    const [formValues, setformValues] = useState({
        plans: plans
    });
    const dispatch = useAppDispatch();
    useEffect(() => {
        setExpand(Array(formValues.plans.length).fill(false))
    }, [formValues.plans.length]);
    const [expand, setExpand] = useState(Array(formValues.plans.length).fill(false))
    const milestones = useAppSelector(getMilestones);
    const users = useAppSelector(getUsers);
    const identityContext = useIdentityContext();


    const user = identityContext.authenticatedUser;
    const engineers = users.filter((user) => user.roles.includes("Engineer")).map((user) => {
        return (
            {
                fullName: user.firstName + " " + user.lastName,
                id: user.id
            }
        )
    });

    const determineDefaultLayout = () => {
        let returnValue: any = []
        formValues.plans.forEach((plan: any, index: number) => {
            returnValue[index] = { 'plan': plan.title, 'layout': [] }
            plan.checks.forEach((check: any, idx: number) => {
                returnValue[index].layout.push(
                    {
                        w: 12,
                        h: 1,
                        x: 0,
                        y: idx,
                        i: check.id,
                    }
                )
            })
        })
        return returnValue
    }
    const defaultLayout = determineDefaultLayout()
    const onExpand = (index: number) => {
        setExpand(prevState => prevState.map((item, idx) => idx === index ? !item : item))
    }

    const handleSubmit = async () => {
        const payload: ChangeManagementCreateOrEditCommand = {
            id: changeManagementItem.id,
            companyId: changeManagementItem.companyId,
            ticketId: changeManagementItem.ticketId,
            title: changeManagementItem.title,
            description: changeManagementItem.description,
            assignedEngineerId: changeManagementItem.assignedEngineerId,
            assignedEngineerName: changeManagementItem.assignedEngineerName,
            plans: formValues.plans,
            milestones: formatMilestones(milestones),
            requestApproval: true,
        }
        const response = await dispatch(createOrEditChangeManagementItem({ command: payload }));
        if (response.payload) {
            await dispatch(
                fetchPlansForChangeManagementItem(response.payload.id)
            );
        }
        onClose()


    }

    const onDeleteCheck = (idx: number, index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.plans))
        clonedArray[index].checks.splice(idx, 1)
        setformValues({ plans: clonedArray })
    }
    const onDeletePlan = (index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.plans))
        clonedArray.splice(index, 1)
        setformValues({ plans: clonedArray })
    }
    const onAddCheck = (index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.plans))
        const insertPosition = clonedArray[index].checks.length
        const insertValue = {
            id: crypto.randomUUID(),
            description: 'New Check',
            checkRequired: true,
            checkPerformedOn: null,
            evidenceRequired: false,
            evidenceUploaded: false,
            isComplete: false

        }
        clonedArray[index].checks.splice(insertPosition, 0, insertValue)
        setformValues({ plans: clonedArray })
    }

    const onAddPlan = () => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.plans))
        const insertPosition = clonedArray.length
        const insertValue = {
            id: crypto.randomUUID(),
            changeManagementItemId: changeManagementItem.id,
            assignedToId: user.userId,
            assignedToName: user.fullName,
            title: 'New Plan',
            startDate: dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            completeDate: dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            status: 1,
            statusDescription: 'In Progress',
            checks: []

        }
        clonedArray.splice(insertPosition, 0, insertValue)
        setformValues({ plans: clonedArray })
    }

    const handleChangeTitle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.plans))
        const insertValue = {
            id: formValues.plans[index].id,
            changeManagementItemId: formValues.plans[index].changeManagementItemId,
            assignedToId: formValues.plans[index].assignedToId,
            assignedToName: formValues.plans[index].assignedToName,
            title: e.target.value,
            startDate: formValues.plans[index].startDate,
            completeDate: formValues.plans[index].completeDate,
            status: formValues.plans[index].status,
            statusDescription: formValues.plans[index].statusDescription,
            checks: formValues.plans[index].checks

        }
        clonedArray.splice(index, 1, insertValue)
        setformValues({ plans: clonedArray })

    }

    const handleChangeAssignedTo = (e: SelectChangeEvent<unknown>, index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.plans))
        const assignedEngineer = engineers.find(el => el.fullName === e.target.value)
        const insertValue = {
            id: formValues.plans[index].id,
            changeManagementItemId: formValues.plans[index].changeManagementItemId,
            assignedToId: assignedEngineer?.id,
            assignedToName: assignedEngineer?.fullName,
            title: formValues.plans[index].title,
            startDate: formValues.plans[index].startDate,
            completeDate: formValues.plans[index].completeDate,
            status: formValues.plans[index].status,
            statusDescription: formValues.plans[index].statusDescription,
            checks: formValues.plans[index].checks

        }
        clonedArray.splice(index, 1, insertValue)
        setformValues({ plans: clonedArray })
    }

    const handleChangeDescription = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, idx: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.plans))
        const insertValue = {
            id: formValues.plans[index].checks[idx].id,
            description: e.target.value,
            checkRequired: formValues.plans[index].checks[idx].checkRequired,
            checkPerformedOn: formValues.plans[index].checks[idx].checkPerformedOn,
            evidenceRequired: formValues.plans[index].checks[idx].evidenceRequired,
            evidenceUploaded: formValues.plans[index].checks[idx].evidenceUploaded,
            isComplete: formValues.plans[index].checks[idx].isComplete

        }
        clonedArray[index].checks.splice(idx, 1, insertValue)
        setformValues({ plans: clonedArray })
    }

    const handleNewLayout = (newLayout: RGL.Layout[], index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.plans))
        let newCheckOrder: any = []
        newLayout.forEach((item: any) => {
            newCheckOrder.push({
                id: item.i,
                description: (clonedArray[index].checks.find((el: { id: any; }) => el.id === item.i)).description,
                checkRequired: (clonedArray[index].checks.find((el: { id: any; }) => el.id === item.i)).checkRequired,
                checkPerformedOn: (clonedArray[index].checks.find((el: { id: any; }) => el.id === item.i)).checkPerformedOn,
                evidenceRequired: (clonedArray[index].checks.find((el: { id: any; }) => el.id === item.i)).evidenceRequired,
                evidenceUploaded: (clonedArray[index].checks.find((el: { id: any; }) => el.id === item.i)).evidenceUploaded,
                isComplete: (clonedArray[index].checks.find((el: { id: any; }) => el.id === item.i)).isComplete,
                orderBy: item.y
            })

        })
        newCheckOrder.sort((a: { orderBy: number; }, b: { orderBy: number; }) => a.orderBy - b.orderBy)
        clonedArray[index].checks = newCheckOrder
        setformValues({ plans: clonedArray })
    };
    return (

        <Card sx={cardStyle}>
            <Typography variant="h3" component="h3">
                Edit Plans
            </Typography>
            <Typography variant="subtitle2">
                <FontAwesomeIcon icon={faWarning} />
                &nbsp;
                {`Plan Created on: ${dayjs(changeManagementItem.createdDate).format('YYYY-MM-DD')}`}
            </Typography>
            <CardContent>
                {formValues.plans.map((plan, index: number) => {
                    return (
                        <>
                            <Accordion expanded={expand[index]}>
                                <AccordionSummary
                                    expandIcon={expand[index] ?
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={faCircleMinus}
                                            color={"#f8931c"}
                                            style={{ width: "20px", textAlign: "center" }}
                                            onClick={() => onExpand(index)}
                                        /> : <FontAwesomeIcon
                                            size="lg"
                                            icon={faCirclePlus}
                                            color={"#f8931c"}
                                            style={{ width: "20px", textAlign: "center" }}
                                            onClick={() => onExpand(index)}
                                        />
                                    }
                                >
                                    <Typography sx={{ marginLeft: '10px', fontWeight: 'bold' }}>Plan {index + 1}: {plan.title}</Typography>
                                    {expand[index] ?
                                        <Tooltip title="Delete Plan">
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                color={"#f8931c"}
                                                size="lg"
                                                style={{ position: 'absolute', right: 50 }}
                                                onClick={() => onDeletePlan(index)}
                                            />
                                        </Tooltip>
                                        : <></>}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Card sx={{ padding: '10px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={8}>
                                                <Box>
                                                    {/* TITLE */}
                                                    <InputLabel id="demo-multiple-checkbox-label">
                                                        Title
                                                    </InputLabel>
                                                    <TextField
                                                        required
                                                        id="title"
                                                        sx={{ width: "100%", mr: 1, marginBottom: "20px" }}
                                                        size="small"
                                                        value={formValues.plans[index].title}
                                                        onChange={(e) => {
                                                            handleChangeTitle(e, index)
                                                        }}
                                                    />

                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {/* ASSIGNED TO */}
                                                <Box>
                                                    <InputLabel id="demo-multiple-checkbox-label">
                                                        Assigned To
                                                    </InputLabel>
                                                    <Select
                                                        size="small"
                                                        required
                                                        sx={{ width: "100%", mb: 1 }}
                                                        value={formValues.plans[index].assignedToName}
                                                        onChange={(e) => handleChangeAssignedTo(e, index)}
                                                    >
                                                        {engineers.map((engineer: any, index: number) => {
                                                            return (
                                                                <MenuItem key={index} value={engineer.fullName}>
                                                                    {engineer.fullName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>

                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Typography variant="subtitle2">
                                            Drag and Drop Checks to change order.
                                        </Typography>
                                        <ReactGridLayout
                                            layout={defaultLayout[index].layout}
                                            onLayoutChange={(newLayout) => handleNewLayout(newLayout, index)}
                                            cols={1}
                                            rowHeight={200}
                                            isResizable={false}
                                            draggableCancel=".cancelSelectorName"
                                        >
                                            {plan.checks.map((check: any, idx: number) => {
                                                return (
                                                    <Grid item key={check.id}>

                                                        <PlanCheck
                                                            idx={idx}
                                                            index={index}
                                                            formValues={formValues}
                                                            onDeleteCheck={onDeleteCheck}
                                                            handleChangeDescription={handleChangeDescription}
                                                        />
                                                    </Grid>

                                                )
                                            })}
                                        </ReactGridLayout>
                                        <Button variant="outlined" color="primary" onClick={() => onAddCheck(index)}>Add Check</Button>
                                    </Card>
                                </AccordionDetails>
                            </Accordion>
                        </>
                    );
                })}
            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
                <FormGroup row>
                    <Button variant="outlined" color="primary" sx={{ position: 'absolute', left: 50 }} onClick={() => onAddPlan()}>
                        Add Plan
                    </Button>
                    <Button variant="outlined" color="primary" type="submit" onClick={handleSubmit}>
                        Submit Changes
                    </Button>
                </FormGroup>
            </CardActions>
        </Card>

    )
}


