import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPage, faWarning } from "@fortawesome/pro-light-svg-icons";
import { Card } from "../../components/Card";
import { MilestoneTable } from "./MilestoneTable";
import { FormattedDate } from "../../common/dateUtils";
import { ChangeManagementItemSummary } from "../../types/changemanagement.types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getMilestones } from "../../redux/Slices/changeManagement.slice";
import {
  fetchMilestonesForChangeManagementItem,
  markMilestoneTaskAsDone,
} from "../../redux/Thunks/changeManagement";
import { useAsyncEffect } from "../../common/hooks";
import { Badge, Box, Button, ButtonGroup, Modal, Stack, Tab, Tabs, Typography } from "@mui/material";
import { EditMilestoneForm } from "./EditMilestonesForm";
import dayjs from "dayjs";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

interface MilestonesProps {
  changeManagementItem: ChangeManagementItemSummary;
  isReadOnly: boolean;
}

export const Milestones: React.FC<MilestonesProps> = (props) => {
  const itemId = props.changeManagementItem.id;
  const milestones = useAppSelector(getMilestones);
  const [currentSelectedMilestone, setCurrentSelectedMilestone] =
    React.useState(0);
  const [showTodo, setShowTodo] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useAppDispatch();

  const showTodoItems = () => {
    setShowTodo(true);
  };

  const showAllItems = () => {
    setShowTodo(false);
  };

  const handleMarkMilestoneAsDone = async (taskId: string) => {
    await dispatch(
      markMilestoneTaskAsDone({ taskId, changeManagementItemId: itemId })
    );
    await dispatch(fetchMilestonesForChangeManagementItem(itemId));
  };

  const { loading } = useAsyncEffect(async () => {
    setShowTodo(true);
    await dispatch(fetchMilestonesForChangeManagementItem(itemId));
  }, [itemId]);

  const determineTextColor = (dueDate: Date) => {
    const milestoneDate = dayjs(dueDate)
    const today = dayjs()
    const dateDiff = milestoneDate.diff(today, 'day')
    if(dateDiff < 0 || dateDiff === 0){
      return 'red'
    }
    if(dateDiff > 0 && dateDiff < 8){
      return 'orange'
    }
    if(dateDiff > 7){
      return 'green'
    }
  }

  return (
    <>
     <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>

          <EditMilestoneForm
            milestones={milestones}
            onClose={handleClose}
            changeManagementItem={props.changeManagementItem}
          />
        </Box>
      </Modal>
    
    
  
    <Card
      id="Milestones"
      headerIcon={faPage}
      headerTitle={`${
        showTodo ? "Milestones left to complete" : "All your milestones"
      }`}
      headerSubText={
        ""
        // tickets.data && tickets.loadComplete ?
        //     `Total of ${tickets.data.length} ${showOpen ? 'open' : 'active'} tickets`
        //     :
        //     'Loading ticket data...'
      }
      showHelp={true}
      helpHeaderText="View Milestones"
      helpHeaderDescription="This view shows you all your milestones, use the switch on the right to toggle between 
            milestones still left to complete or all the milestones available under this change management.  A milestone is considered
            complete when all the tasks have been marked as done"
      cardHeaderActions={
        <>
        <ButtonGroup>
              <Button onClick={handleOpen} sx={{ marginRight: "10px" }}>
                Edit Milestones
              </Button>
            </ButtonGroup>
        <ButtonGroup>
          <Button
            sx={{
              backgroundColor: showTodo ? "orange" : "white",
              color: showTodo ? "white" : "orange",
            }}
            onClick={() => {
              showTodoItems();
            }}
          >
            Todo
          </Button>
          <Button
            sx={{
              backgroundColor: showTodo ? "white" : "orange",
              color: showTodo ? "orange" : "white",
            }}
            onClick={() => {
              showAllItems();
            }}
          >
            All
          </Button>
        </ButtonGroup>
        </>
      }
      cardContent={
        !loading ? (
          <>
            <Tabs
              value={currentSelectedMilestone}
              variant="scrollable"
              scrollButtons="auto"
              onChange={(e, index) => setCurrentSelectedMilestone(index)}
            >
              {milestones.map((milestone) => {
                return (
                  <Tab
                    disableTouchRipple
                    label={
                      <Badge
                        variant={milestone.completedDate ? "dot" : "standard"}
                        // badgeContent={checksLeft(plan)}
                        color={milestone.completedDate ? "success" : "primary"}
                        sx={{ p: milestone.completedDate ? "1px" : "5px" }}
                      >
                        <Typography variant="subtitle2">
                          {milestone.title}
                        </Typography>
                      </Badge>
                    }
                  />
                );
              })}
            </Tabs>
            <>
              {milestones.map((milestone, index) => {
                return (
                  <TabPanel
                    key={`milestoneTable${index}`}
                    value={currentSelectedMilestone}
                    index={index}
                  >
                    <Stack sx={{ width: "100%" }} direction="column">
                      <Typography
                        variant="subtitle2"
                        sx={{color: determineTextColor(milestone.dueDate), mb: 1 }}
                      >
                        <FontAwesomeIcon icon={faWarning} />
                        &nbsp;
                        {`Milestone due by : ${FormattedDate(
                          milestone.dueDate
                        )}`}
                      </Typography>
                      <MilestoneTable
                        isReadOnly={props.isReadOnly}
                        milestone={milestone}
                        showTodoOnly={showTodo}
                        onMarkMilestoneAsDone={handleMarkMilestoneAsDone}
                      />
                    </Stack>
                  </TabPanel>
                );
              })}
            </>
          </>
        ) : (
          <>Loading...</>
        )
      }
    />
    </>
  );
};
