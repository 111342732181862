import { toDate, format } from "date-fns-tz";
import enUS from "date-fns/locale/en-US";

interface DateRange {
  start: Date;
  end: Date;
}

function FormattedDate(date: Date | any): string {
  return `${format(toDate(date), "MMM dd yyyy", {
    timeZone: "America/New_York",
    locale: enUS,
  })}`;
}

function FormattedDateTime(date: Date): string {
  return `${format(toDate(date), "MMM dd yy HH:mm a", {
    timeZone: "America/New_York",
    locale: enUS,
  })}`;
}

function FormattedDateTimeMilitary(date: Date | any): string {
  return `${format(toDate(date), "MMM dd yy HH:mm ", {
    timeZone: "America/New_York",
    locale: enUS,
  })}`;
}

function Yesterday(): DateRange {
  var startOfYesterday = new Date();
  startOfYesterday.setDate(startOfYesterday.getDate() - 1);
  startOfYesterday.setHours(0, 0, 0, 0);

  var endOfYesterday = new Date(startOfYesterday);
  endOfYesterday.setHours(23, 59, 59, 999);

  return { start: startOfYesterday, end: endOfYesterday };
}

function Today(): DateRange {
  var startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);

  var endOfToday = new Date();
  endOfToday.setHours(23, 59, 59, 999);

  return { start: startOfToday, end: endOfToday };
}

function DaysBetween(startDate: Date, endDate: Date) {
  const MS_PER_DAY: number = 86400000;
  const start: number = new Date(startDate).getTime();
  const end: number = new Date(endDate).getTime();
  return Math.ceil((end - start) / MS_PER_DAY);
}

export {
  FormattedDate,
  FormattedDateTime,
  FormattedDateTimeMilitary,
  Yesterday,
  Today,
  DaysBetween,
};
