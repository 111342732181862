// import { faVrCardboard } from "@fortawesome/pro-light-svg-icons";
import {
  Autocomplete,
  Paper,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  TableContainer,
  Table,
  ButtonGroup,
  Button,
  Stack,
  Grid,
  Tooltip,
  Modal,
} from "@mui/material";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { FormattedDateTimeMilitary } from "../../../common/dateUtils";
import {
  getComparator,
  Order,
  stableSort,
} from "../../../common/TableUtilities";
import "./DevicesEnhancedTable.css";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { EnhancedTableHead } from "./DeviceEnhancedTableHead";
import React, { useEffect, useState } from "react";
import { Device } from "../../../types/devices.types";
import { removeDuplicateDevices } from "../../../common/device.util";
import { useAppSelector, useAppDispatch } from "../../../redux/hooks";
import { getAlertTableFilterValue, setAlertTableFilterValue } from "../../../redux/Slices/alerts.slice";
import { Alert } from "../../../types/alerts.types";
import { useIdentityContext } from "../../../context/IdentityContext";
import { getUserById } from "../../../redux/Slices/account.slice";
import exportFromJSON from "export-from-json";
import { HealthColumn } from "../HealthColumn";
import { DeviceInformationModal } from "./DeviceInformationModal";
import { SyncedIcons } from "./SyncedIcons";
interface DevicesEnhancedTableProps {
  devices: Device[];
}

export function DevicesEnhancedTable(props: DevicesEnhancedTableProps) {
  const getDevices = (): Device[] => {
    let devices = removeDuplicateDevices(props.devices);

    // Removes IP Address that begin with 169
    // devices.forEach((device: any, i: number) => {
    //   device.ipAddress.split(",").map((address: any, index: number) => {
    //     if (address.startsWith("169") || address.startsWith(" 169")) {
    //       let cleanArray = devices[i].ipAddress.split(",").slice(index + 1);
    //       devices[i].ipAddress = cleanArray.join();
    //     }
    //   });
    // });
    return devices;
  };

  return <EnhancedTable devices={getDevices()!}></EnhancedTable>;
}

export default function EnhancedTable(props: { devices: Device[] }) {
  const [rows, setRows] = useState<Device[]>(props.devices);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof Device>("id");
  const [page, setPage] = useState(0);
  const [searchDeviceName, setSearchDeviceName] = useState<string>("");
  const rowsPerPage = 100;
  const alertTableFilterValue = useAppSelector(getAlertTableFilterValue);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const identity = useIdentityContext();
  const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
  const rolesArray = user?.roles.split(',')
  const isInvestigateUser = rolesArray?.includes('Investigate User')
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [modalInfo, setModalInfo] = useState({})
  const handleInfoModalClose = () => setOpenInfoModal(false);

  const filterBasedOnValue = () => {
    if (alertTableFilterValue.alert.deviceId) {
      if (alertTableFilterValue.filterRule === 'Devices with this type of alert') {
        const filtered = props.devices.filter((row: Device) => {
          return row.id.toLowerCase().includes(alertTableFilterValue.alert.deviceId.toLowerCase());
        });
        setRows(filtered);
      }
    }

  }


  useEffect(() => {
    filterBasedOnValue()
  }, [alertTableFilterValue]);

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Device
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const deviceNameOptions = (): string[] => {
    const options: string[] = [];
    rows.map((alert) => {
      if (!alert.deviceName || alert.deviceName === "") {
        return;
      }

      if (!options.includes(alert.deviceName)) {
        options.push(alert.deviceName);
      }
    });
    return options;
  };

  const handleDeviceNameSearch = (query: string) => {
    if (!query) {
      setSearchDeviceName("");
      setRows(props.devices);
    } else {
      setSearchDeviceName(query);

      const filtered = props.devices.filter((row) => {
        return row.deviceName.toLowerCase().includes(query.toLowerCase());
      });
      setRows(filtered);
    }
  };
  const removeFilter = () => {
    setRows(props.devices)
    dispatch(setAlertTableFilterValue({ alert: {} as Alert, filterRule: '' }))
  }

  const onClickExport = (data: any) => {
    const fileName = 'DevicesTable'
    const exportType = exportFromJSON.types.xls
    exportFromJSON({ data, fileName, exportType })
  };

  const openModal = () => {
    setOpenInfoModal(true)
  }
  return (
    <>
      <Modal
        open={openInfoModal}
        onClose={handleInfoModalClose}

      >
        <DeviceInformationModal
          device={modalInfo}
        />
      </Modal>
      <Card>
        <Grid container spacing={0}>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-start' }}>
            <Autocomplete
              disablePortal
              id="device-name-autocomplete"
              options={deviceNameOptions()}
              size="small"
              sx={{ width: 175, display: "inline-block" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Devices"
                  sx={{ marginTop: 2, marginLeft: 2 }}
                  value={searchDeviceName}
                  onChange={(event) => handleDeviceNameSearch(event.target.value)}
                />
              )}
              onChange={(_event, value: any) => handleDeviceNameSearch(value)}
              value={searchDeviceName || null}
              PaperComponent={({ children }) => (
                <Paper style={{ background: "#efefef" }}>{children}</Paper>
              )}
            />
          </Grid>
          <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-end' }}>
            {rows.length !== props.devices.length ? (
              <ButtonGroup sx={{ mr: 2, pb: 1 }}>
                <Button
                  onClick={removeFilter}
                  sx={{ mt: 2 }}

                >
                  Remove Filter
                </Button>
                <Tooltip title='Export to Excel'>
                  <Button
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onClick={() => onClickExport(rows)}>Export</Button>

                </Tooltip>
              </ButtonGroup>
            ) : (
              <ButtonGroup sx={{ mr: 2, pb: 1 }}>
                <Tooltip title='Export to Excel'>

                  <Button
                    variant="outlined"
                    sx={{ mt: 2, ml: 15 }}
                    onClick={() => onClickExport(rows)}>Export</Button>

                </Tooltip>
              </ButtonGroup>
            )}
          </Grid>
        </Grid>

      </Card>

      <Card >
        <CardContent>
          <TableContainer
            component={Paper}
            sx={{
              borderStyle: "solid",
              borderColor: "#ccc",
              borderWidth: "1px",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="devices"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows as any, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, _index) => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell align="left">
                          {/* <HealthLabel device={row as any} /> */}
                          <HealthColumn device={row as any} />
                        </TableCell>
                        <TableCell align="left">
                          {row.alertCount !== 0 ? (
                            <Link to={{ pathname: `/alerts/${row.id}` }}>
                              {row.alertCount}
                            </Link>
                          ) : (
                            row.alertCount
                          )}
                        </TableCell>
                        <TableCell align="left">
                          <Button
                            sx={{ textAlign: 'left' }}
                            variant="text"
                            onClick={() => {
                              navigate(`/device/${row.id}/${row.deviceGroup}`);
                            }}
                          >
                            {row.deviceName}
                          </Button>
                          {/* <Link
                            to={{
                              pathname: `/device/${row.id}/${row.deviceGroup}`,
                            }}
                          >
                            {row.deviceName}
                          </Link> */}
                        </TableCell>
                        <TableCell align="left">{row.ipAddress}</TableCell>
                        <TableCell align="left">
                          {FormattedDateTimeMilitary(
                            row.lastModifiedDate as any
                          )}
                        </TableCell>
                        {/* <TableCell align="left">
                          {row.isMonitored === 1 ? (
                            <FontAwesomeIcon
                              icon={faEye}
                              size="lg"
                              color="orange"
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell> */}
                        {/* <TableCell align="left">
                          {row.isVirtualMachine ? (
                            <FontAwesomeIcon
                              icon={faVrCardboard}
                              color="orange"
                              size="sm"
                            />
                          ) : (
                            <></>
                          )}
                        </TableCell> */}
                        <TableCell>
                          <SyncedIcons 
                          device={row}
                          />
                        </TableCell>
                        <TableCell align="right">
                          {isInvestigateUser ?

                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent="flex-end"
                            >
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  navigate(`/investigate/${row.id}/${row.deviceGroup}`);

                                }}
                              >
                                Investigate
                              </Button>
                            </Stack>
                            : <></>}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="outlined"
                            onClick={() => {
                              openModal()
                              setModalInfo(row)
                            }
                            }

                          >
                            Additional Information
                          </Button>
                        </TableCell>
                        
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
