import * as Mui from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ICompanyData } from "./SignupPage";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface SignupPrimaryContactProps {
  onNextClick: (contactDetails: ICompanyData) => void;
  onPreviousClick: VoidFunction;
  formData: ICompanyData;
}

export const SignupPrimaryContact: React.FC<SignupPrimaryContactProps> = (
  props
) => {
  const validationSchema = yup.object().shape({
    primaryContactEmail: yup
      .string()
      .email("Primary contact email has to be a valid email address"),
    primaryContactEmailConfirm: yup
      .string()
      .email(
        "Primary contact email confirmation has to be a valid email address"
      )
      .oneOf(
        [yup.ref("primaryContactEmail"), null],
        "Confirm primary contact email does not match the primary contact email"
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ICompanyData>({ resolver: yupResolver(validationSchema) });

  const onSubmit: SubmitHandler<ICompanyData> = (data) => {
    props.onNextClick(data);
  };

  return (
    <Mui.Stack
      direction="column"
      alignItems={"center"}
      sx={{ width: { xs: "100%", md: "70%", xxl: "55%" } }}
      spacing={1}
      padding={2}
    >
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        Primary User Details
      </Mui.Typography>
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "75%", textAlign: "center", color: "#aaa" }}
        variant="body2"
      >
        An email will be sent to the Primary User to create a password
      </Mui.Typography>

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Mui.Stack
          direction="column"
          alignItems={"center"}
          spacing={2}
          padding={2}
        >
          <Controller
            name="primaryContactFirstName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                required
                onChange={field.onChange}
                id="primaryContectName"
                label="First Name"
                sx={{ width: "100%" }}
                helperText=""
                error={errors.primaryContactFirstName !== undefined}
              />
            )}
          />

          <Controller
            name="primaryContactSurname"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                required
                onChange={field.onChange}
                id="primaryContectSurname"
                label="Last Name"
                sx={{ width: "100%" }}
                helperText=""
                error={errors.primaryContactSurname !== undefined}
              />
            )}
          />

          <Controller
            name="primaryContactEmail"
            control={control}
            defaultValue=""
            render={({ field: { name, value, onChange } }) => (
              <Mui.TextField
                name={name}
                value={value}
                size="small"
                required
                onChange={onChange}
                label="Email Address"
                sx={{ width: "100%" }}
                helperText={
                  errors.primaryContactEmail ? (
                    <Mui.Typography variant="subtitle2">
                      {errors.primaryContactEmail?.message}
                    </Mui.Typography>
                  ) : (
                    <></>
                  )
                }
                error={errors.primaryContactEmail !== undefined}
              />
            )}
          />

          <Controller
            name="primaryContactEmailConfirm"
            control={control}
            defaultValue=""
            render={({ field: { name, value, onChange } }) => (
              <>
                <Mui.TextField
                  name={name}
                  value={value}
                  size="small"
                  required
                  onChange={onChange}
                  label="Confirm Email"
                  sx={{ width: "100%" }}
                  helperText={
                    errors.primaryContactEmailConfirm ? (
                      <Mui.Typography variant="subtitle2">
                        {errors.primaryContactEmailConfirm?.message}
                      </Mui.Typography>
                    ) : (
                      <></>
                    )
                  }
                  error={errors.primaryContactEmailConfirm !== undefined}
                />
              </>
            )}
          />

          <Mui.FormGroup row>
            <Mui.Button
              variant="outlined"
              color="primary"
              sx={{ mt: 2, mr: 2 }}
              onClick={props.onPreviousClick}
            >
              Previous Step
            </Mui.Button>
            <Mui.Button
              variant="outlined"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              Click To Register
            </Mui.Button>
          </Mui.FormGroup>
        </Mui.Stack>
      </form>
    </Mui.Stack>
  );
};
