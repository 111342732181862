import * as Mui from "@mui/material";
import { SelectChangeEvent } from "@mui/material";
import React from "react";
import { FormattedDate } from "../../common/dateUtils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getOpenTickets } from "../../redux/Slices/tickets.slice";
import { fetchAllTickets } from "../../redux/Thunks/tickets";
import { Ticket } from "../../types/tickets.types";
import { StepHeader } from "./StepHeader";

interface SelectTicketProps {
  onTicketSelected: (ticket: Ticket) => void;
  onStepComplete: (stepNumber: number) => void;
  onEditStep: (stepNumber: number) => void;
  stepNumber: number;
  currentStep: number;
  isComplete: boolean;
  ticket?: Ticket;
}

const SelectTicketTodo: React.FC = () => {
  return (
    <>
      <Mui.Typography>Select the Open Ticket</Mui.Typography>
      <Mui.Typography variant="subtitle2">
        Select the ticket you want to raise as a new change management item.
      </Mui.Typography>
    </>
  );
};

const SelectTicketSummary: React.FC<{ ticket: Ticket }> = (props) => {
  return (
    <>
      <Mui.Typography>
        Title :{" "}
        <Mui.Box component="span" fontWeight="bold">
          {props.ticket.title}
        </Mui.Box>
        , Ticket Type:{" "}
        <Mui.Box component="span" fontWeight="bold">
          {props.ticket.subjectType!}
        </Mui.Box>{" "}
        with a priority of
        <Mui.Box component="span" color="red" fontWeight="bold">
          {" "}
          {props.ticket.priorityDescription!}
        </Mui.Box>
      </Mui.Typography>
      <Mui.Typography>
        Description :{" "}
        <Mui.Box component="span">{props.ticket.description!}</Mui.Box>
      </Mui.Typography>
    </>
  );
};

export const SelectTicket: React.FC<SelectTicketProps> = (props) => {
  const openTickets = useAppSelector(getOpenTickets);
  const dispatch = useAppDispatch();
  const { ticket, isComplete, stepNumber, onStepComplete } = props;

  const handleChange = (event: SelectChangeEvent) => {
    const selectedTicket = openTickets.find((obj) => {
      return obj.id === (event.target.value as string);
    });

    if (selectedTicket) {
      props.onTicketSelected(selectedTicket);
      props.onStepComplete(props.stepNumber);
    }
  };

  const handleEdit = () => {
    dispatch(fetchAllTickets());

    //then preselect the one you picked
    props.onEditStep(props.stepNumber);
  };

  React.useEffect(() => {
    //if the ticket passed is not null
    if (ticket) {
      onStepComplete(stepNumber);
    } else if (isComplete === false) {
      dispatch(fetchAllTickets());
    }
  }, [ticket]);

  return (
    <Mui.Card
      sx={{
        borderStyle: "solid",
        borderWidth: props.currentStep === props.stepNumber ? "3px" : "1px",
        borderColor:
          props.currentStep === props.stepNumber
            ? "orange"
            : props.isComplete
            ? "limegreen"
            : "#ddd",
        height: "100%",
      }}
    >
      <StepHeader
        stepNumber={props.stepNumber + 1}
        headerText="Selected Ticket"
        headerSubText={
          props.ticket
            ? `Created: ${FormattedDate(props.ticket.createdDate)}`
            : "-"
        }
        isComplete={props.isComplete}
      />

      <Mui.CardContent sx={{ pt: 0 }}>
        {props.isComplete && props.currentStep !== props.stepNumber ? (
          <SelectTicketSummary ticket={props.ticket!} />
        ) : !props.isComplete && props.currentStep !== props.stepNumber ? (
          <SelectTicketTodo />
        ) : (
          <>
            {openTickets && openTickets ? (
              <Mui.FormControl fullWidth sx={{ mt: 2 }}>
                <Mui.Typography>Open Tickets</Mui.Typography>
                <Mui.Select
                  labelId="openticketselectlabel"
                  id="openticketSelect"
                  value={undefined}
                  size="small"
                  onChange={handleChange}
                >
                  {openTickets.map((ticket) => {
                    return (
                      <Mui.MenuItem value={ticket.id}>
                        {ticket.description} - {ticket.problemCategory} -{" "}
                        {ticket.subjectName}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
              </Mui.FormControl>
            ) : (
              <>Loading tickets...</>
            )}
          </>
        )}
      </Mui.CardContent>

      {/* <Mui.CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
        {props.currentStep === props.stepNumber ? (
          <Mui.Button
            variant="outlined"
            onClick={() => props.onStepComplete(props.stepNumber)}
          >
            Save
          </Mui.Button>
        ) : (
          <></>
        )}
        {props.currentStep !== props.stepNumber ? (
          <Mui.Button variant="outlined" onClick={() => handleEdit()}>
            Edit
          </Mui.Button>
        ) : (
          <></>
        )}
      </Mui.CardActions> */}
    </Mui.Card>
  );
};
