import * as Mui from "@mui/material";
import React from "react";
import largeLogo from "../../images/LogoLarge.svg";
import { useAppDispatch } from "../../redux/hooks";
import { resetPassword } from "../../redux/Thunks/account";
import { StepAllDone } from "./StepAllDone";
import { StepResetPasswordEmailAddress } from "./StepResetPasswordEmailAddress";

export const ResetPasswordPage: React.FC<{}> = (props) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const dispatch = useAppDispatch();
  const handleResetPassword = async (emailAddress: string) => {
    await dispatch(resetPassword(emailAddress));
    setActiveStep(1);
  };

  return (
    <Mui.Box display="flex" justifyContent="center" alignItems="center">
      <Mui.Stack
        direction="column"
        sx={{ width: { xs: "100%", md: "45%", xxl: "30%" } }}
        alignItems="center"
      >
        <Mui.Box
          component="img"
          sx={{
            content: { xs: `url(${largeLogo})` },
            maxWidth: { xs: "350px" },
            minHeight: "100px",
          }}
          alt="Security Portal"
        />
        {activeStep === 0 ? (
          <StepResetPasswordEmailAddress
            onNextClick={(emailAddress) => {
              handleResetPassword(emailAddress);
            }}
          />
        ) : (
          <></>
        )}
        {activeStep === 1 ? <StepAllDone onNextClick={() => {}} /> : <></>}
      </Mui.Stack>
    </Mui.Box>
  );
};
