import * as Mui from "@mui/material";

interface StepListItemProps {
  text: string;
  listItemTitle: string;
  listItemText: string;
  isComplete: boolean;
}

export const StepListItem: React.FC<StepListItemProps> = (props) => {
  return (
    <Mui.ListItem sx={{ p: 0 }}>
      <Mui.Avatar
        sx={{
          backgroundColor: "white",
          borderStyle: "solid",
          borderWidth: "3px",
          borderColor: props.isComplete ? "limegreen" : "orange",
          color: props.isComplete ? "limegreen" : "orange",
        }}
        //   ':hover': { bgcolor: 'orange', color: 'white', borderColor:'orange'
        //   onClick={() =>
        //     { if (props.refElement.current)
        //         props.refElement.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })}}
      >
        {props.text}
      </Mui.Avatar>
      <Mui.ListItemText
        sx={{ ml: 2 }}
        primary={
          <Mui.Typography fontWeight={"bold"}>
            {props.listItemTitle}
          </Mui.Typography>
        }
        secondary={props.listItemText}
      />
    </Mui.ListItem>
  );
};
