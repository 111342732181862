import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faLaptop,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import { determineDeviceHealth } from "../../../common/device.util";
import { useAsyncEffect } from "../../../common/hooks";
import { Card } from "../../../components/Card";
import { Loader } from "../../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getDevices } from "../../../redux/Slices/devices.slice";
import { fetchDevices } from "../../../redux/Thunks/devices";

export const DeviceTableSummary = () => {
  const dispatch = useAppDispatch();
  const devices = useAppSelector(getDevices);
  //const deviceStats = useAppSelector(getDeviceTotals);
  //const deviceStatus = determineDeviceStatus(deviceStats);
  const deviceHealth = determineDeviceHealth(devices);

  const { loading } = useAsyncEffect(async () => {
    if (devices.length < 1) {
      await Promise.all([
        dispatch(fetchDevices()),
        //dispatch(fetchDeviceTotals()),
      ]);
    }
  }, []);
  if (loading) {
    return <Loader show={true} />;
  }

  return (
    <Card
      id="Tickets"
      headerIcon={faLaptop}
      headerTitle={"Devices"}
      headerSubText={""}
      showHelp={false}
      helpHeaderText=""
      helpHeaderDescription=""
      cardHeaderActions={""}
      cardContent={
        <Grid
          id="overview"
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
      
          <Grid item xs={12} sx={{pl: 10}}>
            <h4
              style={{
                padding: "0 20px",
                fontWeight: "normal",
                fontSize: "16px",
                margin: 0,
                marginBottom: "10px",
              }}
            >
              Device Health
            </h4>
            <div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />{" "}
                  {deviceHealth.critical}
                </h3>
                <span>Critical</span>
              </div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="orange"
                  />{" "}
                  {deviceHealth.warning}
                </h3>
                <span>Warning</span>
              </div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon icon={faCheckCircle} color="green" />{" "}
                  {deviceHealth.healthy}
                </h3>
                <span>Healthy</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      }
    />
  );
};
