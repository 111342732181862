import React from "react";
import * as Mui from "@mui/material";
import { FormattedDate } from "../../common/dateUtils";
import {
  ChangeManagementPlan,
  ChangeManagementPlanCheck,
} from "../../types/changemanagement.types";

const checkType = (check: ChangeManagementPlanCheck) => {
  if (check.checkRequired && check.evidenceRequired) return "Evidence check";
  if (check.checkRequired && !check.evidenceRequired) return "Manual check";
  else return "Information Only";
};

const isComplete = (check: ChangeManagementPlanCheck) => {
  if(check.isComplete){return 'True'}
  if(!check.isComplete){return 'False'}
  else return '-'
}

const showUploadEvidenceButton = (check: ChangeManagementPlanCheck) => {
  return (
    check.evidenceRequired && !check.evidenceUploaded && !check.checkPerformedOn
  );
};

const showManualCheckButton = (check: ChangeManagementPlanCheck) => {
  return (
    check.checkRequired && !check.checkPerformedOn && !check.evidenceRequired
  );
};

interface PlanTableProps {
  plan: ChangeManagementPlan;
  showTodoOnly: boolean;
  onMarkPlanCheckAsDone: (checkId: string) => void;
  isReadOnly: boolean;
}
export const PlanTable: React.FC<PlanTableProps> = (props) => {
  return (
    <Mui.TableContainer
      component={Mui.Paper}
      sx={{ borderStyle: "solid", borderColor: "#ccc", borderWidth: "1px" }}
    >
      <Mui.Table
        stickyHeader
        sx={{ minWidth: 650 }}
        padding="normal"
        size="small"
        aria-label="recent tickets"
      >
        <Mui.TableHead>
          <Mui.TableRow sx={{ backgroundColor: "red" }}>
            <Mui.TableCell>Check Description</Mui.TableCell>
            {/* <Mui.TableCell>Type of check required</Mui.TableCell> */}
            <Mui.TableCell>Check Complete</Mui.TableCell>

            <Mui.TableCell>Check Performed On</Mui.TableCell>
            <Mui.TableCell></Mui.TableCell>
          </Mui.TableRow>
        </Mui.TableHead>
        <Mui.TableBody>
          {props.plan.checks.map((check) => (
            <Mui.TableRow
              key={check.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <Mui.TableCell component="th" scope="row">
                {check.description}
              </Mui.TableCell>
              {/* <Mui.TableCell component="th" scope="row">
                {checkType(check)}
              </Mui.TableCell> */}
              <Mui.TableCell component="th" scope="row">
                {isComplete(check)}
              </Mui.TableCell>
              <Mui.TableCell component="th" scope="row">
                {check.checkPerformedOn ? (
                  FormattedDate(check.checkPerformedOn)
                ) : (
                  <>-</>
                )}
              </Mui.TableCell>
              <Mui.TableCell align="right">
                {props.isReadOnly ? (
                  <></>
                ) : (
                  <>
                    {showUploadEvidenceButton(check) ? (
                      <Mui.Button
                        variant="outlined"
                        sx={{ minWidth: "170px" }}
                        onClick={() => {
                          alert(check.id);
                        }}
                      >
                        Upload Evidence
                      </Mui.Button>
                    ) : (
                      <></>
                    )}
                    {showManualCheckButton(check) ? (
                      <Mui.Button
                        variant="outlined"
                        sx={{ minWidth: "170px" }}
                        onClick={() => {
                          props.onMarkPlanCheckAsDone(check.id);
                        }}
                      >
                        Mark as done
                      </Mui.Button>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </Mui.TableCell>
            </Mui.TableRow>
          ))}
        </Mui.TableBody>
      </Mui.Table>
    </Mui.TableContainer>
  );
};
