import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import ChartsSidebar from "./ChartsSidebar";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getDoubleClickChartCounter,
  getDevices,
  setChartErrorMessage,
} from "../../redux/Slices/devices.slice";
import { useAsyncEffect } from "../../common/hooks";
import {
  fetchDevices,
  raisePerformanceValuesDaily,
} from "../../redux/Thunks/devices";
import { ChartsContainer } from "./ChartsContainer";
import { ChartsSummary } from "./ChartsSummary";
import { Loader } from "../../components/Loader";
import { useEffect, useState } from "react";
import { DeviceChartData, DeviceChartObject } from "../../types/devices.types";
import dayjs from "dayjs";
import { Box, Modal, Typography } from "@mui/material";
import ChartErrorBanner from "./ChartErrorBanner";
import { setLayout } from "../../redux/Slices/layout.slice";

const messageStyle = {
  color: "#C0C0C0",
  position: "absolute",
  top: "50%",
  left: "40%",
  width: "300px",
  textAlign: "center",
};
const InitialMessage = () => {
  return (
    <Box>
      <Typography variant="h3" gutterBottom sx={messageStyle}>
        Select a device from the device list to start generating charts.
      </Typography>
    </Box>
  );
};

export const ChartsDashboardPage = () => {
  const [loadingModal, setLoadingModal] = useState(false)
  const dispatch = useAppDispatch();
  const devices = useAppSelector(getDevices);
  const { loading } = useAsyncEffect(async () => {
    await Promise.all([dispatch(fetchDevices())]);
  }, []);
  const [addedCharts, setAddedCharts] = useState<any>([]);
  const doubleclickChartCounter = useAppSelector(getDoubleClickChartCounter);
  useEffect(() => {
    dispatch(
      setLayout({
        title: "Charts Dashboard",
        subtitle: "Charts Dashboard",
        icon: faChartSimple,
      })
    );
  }, [dispatch]);


  useEffect(() => {
    if(Object.keys(doubleclickChartCounter).length !== 0){
      addChart(doubleclickChartCounter)

    }
  }, [doubleclickChartCounter]);

  useEffect(() => {
    if(loading){
      setLoadingModal(false)
    }
  }, [loading]);

  const addChart = async (chartCounter: DeviceChartObject) => {
    dispatch(setChartErrorMessage(false));
    setLoadingModal(true)
    const dateTimeNow = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    const oneYearAgo = dayjs().subtract(1, "year").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    var timeObject: DeviceChartObject = {
      deviceId: chartCounter.deviceId,
      deviceGroup: chartCounter.deviceGroup,
      objectName: chartCounter.objectName,
      counterName: chartCounter.counterName,
      startDate: oneYearAgo,
      endDate: dateTimeNow,
    };
  
    const yearly = await dispatch(raisePerformanceValuesDaily(timeObject));
    if (!yearly.payload) {
      dispatch(setChartErrorMessage(true));
    }
    if (yearly.payload) {
      setLoadingModal(false)
      dispatch(setChartErrorMessage(false));
      setAddedCharts([
        ...addedCharts,
        {
          deviceId: chartCounter.deviceId,
          deviceGroup: chartCounter.deviceGroup,
          objectName: chartCounter.objectName,
          counterName: chartCounter.counterName,
          chartData: yearly.payload,
          timeInterval: "year",
        },
      ]);
    }
  };
  const handleDeleteChart = (chartCopy: DeviceChartData[]) => {
    setAddedCharts(chartCopy);
  };
  const handleChangeTimeInterval = (chartCopy: DeviceChartData[]) => {
    setAddedCharts(chartCopy);
  };
  return (
    <>
       <Modal open={loadingModal}>
                <Loader show={true} />
            </Modal>
      {loading ? (
        <Loader show={true} />
      ) : (
        <>
          <ChartErrorBanner />
          <ChartsSummary />
          {addedCharts.length < 1 ? (
            <InitialMessage />
          ) : (
            <ChartsContainer
              addedCharts={addedCharts}
              onDeleteChart={handleDeleteChart}
              onChangeTimeInterval={handleChangeTimeInterval}
            />
          )}
          <ChartsSidebar devices={devices} onAddChart={addChart} />
        </>
      )}
    </>
  );
};
