import { Box, Tabs, Tab } from "@mui/material"
import { a11yProps, TabPanel } from "../../common/DashboardTabs"
import { useState } from "react";
import { AlertReportsTab } from "./ReportTabs/AlertsTab/AlertReportsTab";
import { DeviceReportsTabs } from "./ReportTabs/DeviceTab/DeviceReportsTab";
import { TicketReportsTab } from "./ReportTabs/TicketsTab/TicketsReportsTab";
import { CMReportsTab } from "./ReportTabs/CMTab/CMReportsTab";
import { UsersReportsTab } from "./ReportTabs/UsersTab/UsersReportsTab";


export const ReportTabs = () => {
    const [value, setValue] = useState<any>(0);


    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Incidents" {...a11yProps(0)} />
                    <Tab label="Devices" {...a11yProps(1)} />
                    <Tab label="Tickets" {...a11yProps(2)} />
                    <Tab label="Change Management" {...a11yProps(3)} />
                    <Tab label="Users" {...a11yProps(4)} />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <AlertReportsTab />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DeviceReportsTabs />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <TicketReportsTab />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <CMReportsTab />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <UsersReportsTab />
            </TabPanel>
        </Box>
    )
}