
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { useRef } from 'react';



export default function ReportsClusterBarChart(props) {
    const { barChartCategories, barChartData, type, title } = props
    const chartComponent = useRef({});


    const options = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: title,
            align: 'center'
        },
        xAxis: {
            // array of strings ['','','','']
            categories: barChartCategories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Count trophies'
            },
            stackLabels: {
                enabled: true
            }
        },
        legend: {
            align: 'left',
            x: 70,
            verticalAlign: 'top',
            y: 70,
            floating: true,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y} ' + type +'<br/>Total: {point.stackTotal} ' + type
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        // array of objects [{name: 'string', data: [1,2,3,4]}]
        legend:{ 
            align: 'left',
            // x: 70,
            verticalAlign: 'top',
            y: 30,
            // floating: true,

         },
        series: barChartData,
         
        
        // series: [{
        //     name: 'BPL',
        //     data: [3, 5, 1, 13,5,5,5]
        // }, {
        //     name: 'FA Cup',
        //     data: [14, 8, 8, 12,6,6,6]
        // }, {
        //     name: 'CL',
        //     data: [0, 2, 6, 3,2,2,2]
        // }]
    }
    return (
        
        <Card sx={{width: '100%', m: 1, p: 1, border: "solid 1px #ddd"}}>
         
            <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%", width: '100%' } }} />

        </Card>

    );
}