import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import {
    fetchInvestigateAlert,
    // fetchInvestigate, 
    fetchIpLocation
} from "../../redux/Thunks/alerts";
// import { getAlertById } from "../../redux/Slices/alerts.slice";
import { Loader } from "../../components/Loader";
import { IPLocation } from "../../types/alerts.types";


interface InvestigatedEntitiesLinkProps {
    investigatedAlert: any;
    currentAlert: any;
}

export const InvestigatedEntitiesLink = (props: InvestigatedEntitiesLinkProps) => {
    const { investigatedAlert, currentAlert } = props
    const [open, setOpen] = useState(Array(!investigatedAlert.investigatedEntities ? 0 : investigatedAlert.investigatedEntities.length).fill(false));
    const [tooltipTitle, setTooltipTitle] = useState<any>('')
    const [IPResponce, setIPResponse] = useState<IPLocation>()
    const [loading, setLoading] = useState(false)
    const [validIP, setValidIP] = useState(false)

    const dispatch = useAppDispatch();
    // const currentAlert = useAppSelector(getAlertById(investigatedAlert.investigatedId || ""));


    useEffect(() => {
        setOpen(Array(!investigatedAlert.investigatedEntities ? 0 : investigatedAlert.investigatedEntities.length).fill(false))
    }, [investigatedAlert]);

    const handleClose = (index: number) => {
        let newArr = [...open];
        newArr[index] = false;
        setOpen(newArr);
    };

    const ValidateIPaddress = (ipaddress: string) => {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }


    const handleOpen = async (entity: string, index: number) => {
        const validEntity = ValidateIPaddress(entity)
        setValidIP(validEntity)
        if (validEntity) {
            const response = await dispatch(fetchIpLocation(entity))
            setIPResponse(response.payload)
            let newArr = Array(!investigatedAlert.investigatedEntities ? 0 : investigatedAlert.investigatedEntities.length).fill(false);
            newArr[index] = true;
            setOpen(newArr);
        } if (!validEntity) {
            let newArr = Array(!investigatedAlert.investigatedEntities ? 0 : investigatedAlert.investigatedEntities.length).fill(false);
            newArr[index] = true;
            setOpen(newArr);
        }
    };


    const buildTooltip = () => {
        let ipArray: any[] = []
        if (validIP) {
            const titleCase = {
                'Ip': IPResponce?.ip,
                'City': IPResponce?.city,
                'Country': IPResponce?.country,
                'Country Code': IPResponce?.countryCode,
                'Isp': IPResponce?.isp,
                'Org': IPResponce?.org,
                'region': IPResponce?.region,
                'Region Name': IPResponce?.regionName,
                'Zip': IPResponce?.zip,
            }
            Object.entries(titleCase).forEach((value: any) => {
                ipArray.push(<Typography>{value[0].charAt(0).toUpperCase() + value[0].slice(1)}: {value[1]}</Typography>)
            })
        } if (!validIP) {
            ipArray.push(<Typography>{''}</Typography>)
        }
        return ipArray
    }

    useEffect(() => {
        setTooltipTitle(buildTooltip())
    }, [IPResponce, validIP]);

    const handleClick = async (entity: string) => {
        setLoading(true)
      
        const command = `${currentAlert?.id}?investigate=${entity}`
        const response = await dispatch(fetchInvestigateAlert(command));
        if (response.payload) {
            setLoading(false)
        }
    }

    return (


        (investigatedAlert.investigatedEntities ?
            <Box>
                <Modal open={loading}>
                    <Loader show={true} />
                </Modal>
                <strong>Investigated Entities: </strong>
                {investigatedAlert.investigatedEntities.length < 1 ?
                    'No Entities for this Alert' :

                    (investigatedAlert.investigatedEntities.map((entity: string, index: number) => {
                        return (

                            <Tooltip
                                open={open[index]}
                                onClose={() => handleClose(index)}
                                onOpen={() => handleOpen(entity, index)}
                                title={IPResponce ? tooltipTitle : ''}
                            >
                                <Button
                                    sx={{ margin: '2px' }}
                                    variant="text"
                                    key={index}
                                    onClick={() => handleClick(entity)}>
                                    {entity}
                                </Button>

                            </Tooltip>
                        )

                    }))}
            </Box >
            :
            <></>)

    )
}

