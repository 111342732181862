import React, {
  useContext,
  useState,
  FC,
  createContext,
  ReactNode,
} from "react";

//need to dfine a type with the claims or a wrapper around them
//so we can get first name, last name etc.

//maybe make this a class as it will be initialised with a userclaims object.

//TODO: type the claims

/*
claims: Array(16)
0: {type: 'amr', value: 'pwd'}
1: {type: 'sid', value: '09BA65D85D2D10B38C702329FD57645A'}
2: {type: 'sub', value: 'c05d4475-0dbc-4fa4-987d-0f10e447ca2d'}
3: {type: 'auth_time', value: '1659345829'}
4: {type: 'idp', value: 'local'}
5: {type: 'role_perms', value: '["users.caninvite","tickets.canraise"]'}
6: {type: 'given_name', value: 'andy'}
7: {type: 'family_name', value: 'd'}
8: {type: 'company_id', value: 'e03958ff-7401-4de6-be64-c1610d506ef8'}
9: {type: 'company_name', value: 'Andrews'}
10: {type: 'default_role', value: 'companyadmin'}
11: {type: 'initials', value: 'ad'}
12: {type: 'id', value: 'c05d4475-0dbc-4fa4-987d-0f10e447ca2d'}
13: {type: 'bff:logout_url', value: '/bff/logout?sid=09BA65D85D2D10B38C702329FD57645A'}
14: {type: 'bff:session_expires_in', value: 1209598}
15: {type: 'bff:session_state', value: 'a6RfUHq7rAvCZIYDYY_F_GZSb_3Hi3lNiDCNYtZ3bbA.EBB67848FF9739A54F85AE96C13FC4D9'}
length: 16

*/

export class AuthenticatedUser {
  constructor(claims: [{ type: string; value: string }] | null) {
    this.claims = claims;
    this.userRolePermissions = [];

    if (claims !== null) {
      var firstName =
        claims.find((obj) => obj.type === "given_name")?.value || "";
      var lastName =
        claims.find((obj) => obj.type === "family_name")?.value || "";
      this.fullName = firstName + " " + lastName;

      this.isLoggedIn = true;
      this.logoutUrl =
        claims.find((obj) => obj.type === "bff:logout_url")?.value || "";
      this.defaultRole =
        claims.find((obj) => obj.type === "default_role")?.value || "";
      this.companyId =
        claims.find((obj) => obj.type === "company_id")?.value || "";
      this.userId = claims.find((obj) => obj.type === "id")?.value || "";

      var perms = claims.find((obj) => obj.type === "role_perms")?.value || "";
      if (perms !== "") this.userRolePermissions = JSON.parse(perms);
      this.authorizedCompanies = Object.keys(
        JSON.parse(
          claims.find((obj) => obj.type === "AuthorizedCompanies")?.value || ""
        )
      );
      this.isMSP = this.authorizedCompanies.length > 0;
    } else {
      this.fullName = "";
      this.isLoggedIn = false;
      this.logoutUrl = "";
      this.defaultRole = "";
      this.companyId = "";
      this.userId = "";
      this.authorizedCompanies = [];
      this.isMSP = false;
    }
  }

  private claims: [{ type: string; value: string }] | null;
  private userRolePermissions: string[];

  public fullName: string;
  public logoutUrl: string;
  public isLoggedIn: boolean;
  public defaultRole: string;
  public companyId: string;
  public userId: string;
  public authorizedCompanies: Array<string>;
  public isMSP: boolean;

  private hasPermission(permissionName: string): boolean {
    if (this.claims === null) return false;
    else return this.userRolePermissions.indexOf(permissionName) !== -1;
  }

  public canInvite() {
    //change this so it comes back as a profile or permissions object, we
    //shouldnt need the claim name here.
    return this.hasPermission("users.caninvite");
  }

  public static UnauthenticatedUser(): AuthenticatedUser {
    return new AuthenticatedUser(null);
  }
}

type IdentityContextType = {
  authenticatedUser: AuthenticatedUser;
  onLogin: VoidFunction;
  onLogout: VoidFunction;
};

const IdentityContext = createContext<IdentityContextType>({
  authenticatedUser: AuthenticatedUser.UnauthenticatedUser(),
  onLogin: () => {},
  onLogout: () => {},
});

//Identity Context allows us to set an authenticated user at parent level so all
//components can make use of it.

export const useIdentityContext = () => useContext(IdentityContext);
interface identityContextProps {
  children: ReactNode;
}

export const IdentityContextProvider: FC<identityContextProps> = (props) => {
  const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser>(
    AuthenticatedUser.UnauthenticatedUser
  );

  //TODO: we need to maybe look at the referring url so we can redirect in the ok response scenario
  const handleLogin = async () => {
    const fetchUser = async () => {
      let req = new Request("/bff/user", {
        headers: new Headers({
          "X-CSRF": "1",
        }),
      });
      let resp = await fetch(req);
      if (resp.ok) {
        let userData = await resp.json();
        let authenticatedUser = new AuthenticatedUser(userData);
        authenticatedUser.isLoggedIn = true;
        setAuthenticatedUser(authenticatedUser);
      } else if (resp.status === 401) {
        setAuthenticatedUser(AuthenticatedUser.UnauthenticatedUser());
        window.location.href = "/bff/login";
      }
    };

    setInterval(fetchUser, 60000 * 30);
    fetchUser();
  };

  const handleLogout = () => {
    setAuthenticatedUser(AuthenticatedUser.UnauthenticatedUser())
    window.location.href = authenticatedUser.logoutUrl // + "&returnUrl=/";
  };

  return (
    <IdentityContext.Provider
      value={{
        authenticatedUser: authenticatedUser,
        onLogin: handleLogin,
        onLogout: handleLogout,
      }}
    >
      {props.children}
    </IdentityContext.Provider>
  );
};
