import React, { useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
// import { Header } from "./Header";
import NavigationSidebar from "./NavigationSidebar";
import { Link, Modal, Toolbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getSelectedCompanyId,
  getSidebarToggle,
  setSelectedCompanyId,
} from "../../redux/Slices/account.slice";
//import { getBanner } from "../../redux/Slices/layout.slice";
import LayoutNotification from "./LayoutNotification";
import { getCompanies } from "../../redux/Slices/company.slice";
import { useAsyncEffect } from "../../common/hooks";
import { changeCompany, fetchCompanies } from "../../redux/Thunks/company";
import { Loader } from "../../components/Loader";

type PortalWithHeadersProps = {
  title: string;
  paragraph: string;
  appBarComponent?: React.ReactNode;
  children: React.ReactNode;
};

export const PortalWithHeadersLayout: React.FC<PortalWithHeadersProps> = ({
  title,
  appBarComponent,
  children,
}) => {
  const dispatch = useAppDispatch();
  const sidebarToggle = useAppSelector(getSidebarToggle);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const companies = useAppSelector(getCompanies);
  const selectedCompanyId = useAppSelector(getSelectedCompanyId);
  const companyName = useMemo(() => {
    return companies.find((company) => company.id === selectedCompanyId)
      ?.companyName;
  }, [companies, selectedCompanyId]);

  const { loading } = useAsyncEffect(async () => {
    if (companies.length < 1) {
      await dispatch(fetchCompanies());
    }
  }, []);

  useEffect(() => {
    if (!companyName && companies.length > 0) {
      dispatch(setSelectedCompanyId(null));
    }
  }, [dispatch, companyName, companies]);

  const clearCompany = async () => {
    setLoadingCompany(true);
    await dispatch(setSelectedCompanyId(null));
    await dispatch(changeCompany());
    setLoadingCompany(false);
  };

  return (
    <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
      <Modal open={loadingCompany}>
        <Loader show={true} />
      </Modal>
      <NavigationSidebar title={title} appBarComponent={appBarComponent} />
      <Toolbar />
      <Box
        component="main"
        sx={
          sidebarToggle
            ? {
                flex: 1,
                py: 2,
                px: 2,
                marginLeft: "175px",
                transition: "all 0.2s ease-in-out",
              }
            : {
                flex: 1,
                py: 2,
                px: 2,
                marginLeft: "55px",
                transition: "all 0.2s ease-in-out",
              }
        }
      >
        <LayoutNotification
          text={
            <>
              {`You are currently viewing ${companyName}`}{" "}
              <Link
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  clearCompany();
                }}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                Clear Selection
              </Link>
            </>
          }
          //icon={faCircleExclamation}
          show={!!selectedCompanyId && !loading}
          severity={"info"}
        />
        {children}
      </Box>
    </Box>
  );
};
