
import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getDevices, setProfileManagementAssignedDevices } from '../../redux/Slices/devices.slice';
import { Device } from '../../types/devices.types';
import { Button, Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Paper } from '@mui/material';
import { useEffect, useState } from 'react';

function not(a: readonly Device[], b: readonly Device[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly Device[], b: readonly Device[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export default function ProfileManagementTransferList() {
    const devices = useAppSelector(getDevices);
    const dispatch = useAppDispatch();
    const [checked, setChecked] = useState<Device[]>([]);
    const [leftOptions, setLeftOptions] = useState<Device[]>(devices)
    const [rightOptions, setRightOptions] = useState<Device[]>([])
    const leftChecked = intersection(checked, leftOptions);
    const rightChecked = intersection(checked, rightOptions);
    useEffect(() => {
        dispatch(setProfileManagementAssignedDevices(rightOptions));

    }, [rightOptions]);
    const handleToggle = (device: Device) => () => {
        const currentIndex = checked.indexOf(device);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(device);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const handleAllRight = () => {
        setRightOptions(rightOptions.concat(leftOptions));
        setLeftOptions([]);
    };

    const handleCheckedRight = () => {
        setRightOptions(rightOptions.concat(leftChecked));
        setLeftOptions(not(leftOptions, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeftOptions(leftOptions.concat(rightChecked));
        setRightOptions(not(rightOptions, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeftOptions(leftOptions.concat(rightOptions));
        setRightOptions([]);
    };

    const AvailableList = () => (
        <>
        <Paper sx={{ width: 300, height: 350, overflow: 'auto' }}>
            <ListSubheader sx={{ backgroundColor: '#395a7a', color: 'white' }}>Available</ListSubheader>

            <List dense={true} sx={{ padding: 0 }} component="div" role="list" >
                {leftOptions.map((device: Device) => {
                    const labelId = `transfer-list-item-${device}-label`;

                    return (
                        <ListItem
                            key={device.id}
                            role="listitem"
                            onClick={handleToggle(device)}
                           
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(device) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{padding: 0}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={device.deviceName} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
        </>
    );


    const AssignedList = () => (
        <Paper sx={{ width: 300, height: 350, overflow: 'auto' }}>
            <ListSubheader sx={{ backgroundColor: '#395a7a', color: 'white' }}>Assigned</ListSubheader>
            <List dense={true} sx={{ padding: 0 }} component="div" role="list">
                {rightOptions.map((device: Device) => {
                    const labelId = `transfer-list-item-${device}-label`;

                    return (
                        <ListItem
                            key={device.id}
                            role="listitem"
                            onClick={handleToggle(device)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(device) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                    sx={{padding: 0}}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={device.deviceName} sx={{ margin: 0 }} />
                        </ListItem>
                    );
                })}
            </List>
        </Paper>
    );
    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item><AvailableList /></Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllRight}
                        disabled={leftOptions.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        sx={{ my: 0.5 }}
                        variant="outlined"
                        size="small"
                        onClick={handleAllLeft}
                        disabled={rightOptions.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                </Grid>
            </Grid>
            <Grid item><AssignedList /></Grid>
        </Grid>
    );
}
