import { createAsyncThunk } from "@reduxjs/toolkit";
import protectAxios from "../../api/axios";
import { EmptyGuid } from "../../common/guidUtils";
import {
  AddCommentCommand,
  ChangeManagementItemSummary,
  ChangeManagementPlan,
  ChangeManagementStatus,
  CreateChangeManagementItemCommand,
  ChangeManagementCreateOrEditCommand,
} from "../../types/changemanagement.types";
import {
  setChangeManagementItem,
  setComments,
  setMilestones,
  setPlans,
  setSummaries,
  setTemplates,
} from "../Slices/changeManagement.slice";

export const createChangeManagementItem = createAsyncThunk(
  "changeManagement/createChangeManagementItem",
  async (
    {
      ticketId,
      command,
    }: { ticketId: string; command: CreateChangeManagementItemCommand },
    { dispatch }
  ) => {
    const data = await protectAxios
      .post(`/changemanagementcommands/${ticketId}/create`, command)
      .then((resp) => resp.data);
    // dispatch(requestApproval(data.id))
    return data;
  }
);

export const requestApproval = createAsyncThunk(
  "/requestApproval",
  async (changeManagementItemId: string) => {
    const data = await protectAxios
      .post("/changemanagementcommands/requestApproval", changeManagementItemId)
      .then((resp) => resp.data);

    return data;
  }
);

export const createOrEditChangeManagementItem = createAsyncThunk(
  "/CreateOrEdit",
  async (
    { command }: { command: ChangeManagementCreateOrEditCommand },
    { dispatch }
  ) => {
    const data = await protectAxios
      .post("/changemanagementcommands/CreateOrEdit", command)
      .then((resp) => resp.data);
    // dispatch(requestApproval( data.id))
    return data;
  }
);

export const approveChangeManagementItem = createAsyncThunk(
  "changeManagement/approveChangeManagementItem",
  async (
    {
      changeManagementId,
      planName,
      assignedEngineerId,
    }: {
      changeManagementId: string;
      planName: string;
      assignedEngineerId: string;
    },
    { dispatch }
  ) => {
    const data = await protectAxios
      .put(`/changemanagementcommands/${changeManagementId}/approve`)
      .then((resp) => resp.data);
    return data;
  }
);

export const requestSignoffChangeManagementItem = createAsyncThunk(
  "changeManagement/requestSignoffChangeManagementItem",
  async (
    {
      changeManagementId,
      planName,
    }: { changeManagementId: string; planName: string },
    { dispatch }
  ) => {
    const data = await protectAxios
      .put(`/changemanagementcommands/${changeManagementId}/requestsignoff`)
      .then((resp) => resp.data);
    return data;
  }
);

export const signoffChangeManagementItem = createAsyncThunk(
  "changeManagement/signoffChangeManagementItem",
  async (
    {
      changeManagementId,
      planName,
      assignedEngineerId,
    }: {
      changeManagementId: string;
      planName: string;
      assignedEngineerId: string;
    },
    { dispatch }
  ) => {
    const data = await protectAxios
      .put(`/changemanagementcommands/${changeManagementId}/signoff`)
      .then((resp) => resp.data);
    return data;
  }
);

export const markPlanCheckAsDone = createAsyncThunk(
  "changeManagement/markPlanCheckAsDone",
  async ({
    changeManagementItemId,
    checkId,
  }: {
    changeManagementItemId: string;
    checkId: string;
  }) => {
    const resp = await protectAxios.put(
      `/changemanagementcommands/${changeManagementItemId}/MarkCheckAsDone/${checkId}`
    );
    return resp.data;
  }
);

export const markMilestoneTaskAsDone = createAsyncThunk(
  "changeManagement/markMilestoneTaskAsDone",
  async ({
    changeManagementItemId,
    taskId,
  }: {
    changeManagementItemId: string;
    taskId: string;
  }) => {
    const resp = await protectAxios.put(
      `/changemanagementcommands/${changeManagementItemId}/MarkTaskAsDone/${taskId}`
    );
    return resp.data;
  }
);

export const setManualItemToInprogress = createAsyncThunk(
  "changeManagement/setManualItemToInprogress",
  async (changeManagementItemId: string) => {
    const resp = await protectAxios.put(
      `/changemanagementcommands/${changeManagementItemId}/InProgress`
    );
    return resp.data;
  }
);

export const setManualItemToComplete = createAsyncThunk(
  "changeManagement/setManualItemToComplete",
  async (changeManagementItemId: string) => {
    const resp = await protectAxios.put(
      `/changemanagementcommands/${changeManagementItemId}/Complete`
    );
    return resp.data;
  }
);

export const fetchChangeManagementTemplates = createAsyncThunk(
  "changeManagement/getChangeManagementTemplates",
  async (_, { dispatch }) => {
    const data = await protectAxios
      .get(`/changemanagementqueries/templates`)
      .then((resp) => resp.data);
    dispatch(setTemplates(data));
    return data;
  }
);

export const fetchChangeManagementItemById = createAsyncThunk(
  "changeManagement/getChangeManagementItemById",
  async (changeManagementItemId: string, { dispatch }) => {
    const data = await protectAxios
      .get(`/changemanagementqueries/${changeManagementItemId}`)
      .then((resp) => resp.data);
    dispatch(setChangeManagementItem(data));
    return data;
  }
);

export const fetchChangeManagementSummaries = createAsyncThunk(
  "changeManagement/getChangeManagementSummaries",
  async (_, { dispatch }) => {
    const data = await protectAxios
      .get("/changemanagementqueries/summaries")
      .then((resp) => resp.data);
    dispatch(setSummaries(data));
    return data;
  }
);

export const addChangeManagementComment = createAsyncThunk(
  "changeManagement/addChangeManagementComment",
  async ({
    changeManagementItemId,
    command,
  }: {
    changeManagementItemId: string;
    command: AddCommentCommand;
  }) => {
    const resp = await protectAxios.put(
      `/changemanagementcommands/${changeManagementItemId}/comment`,
      command
    );
    return resp;
  }
);

export const fetchCommentsForChangeManagementItem = createAsyncThunk(
  "changeManagement/getCommentsForChangeManagementItem",
  async (changeManagementItemId: string, { dispatch }) => {
    const data = await protectAxios
      .get(`/changemanagementqueries/${changeManagementItemId}/Comments`)
      .then((resp) => resp.data);
    dispatch(setComments(data));
    return data;
  }
);

export const fetchPlansForChangeManagementItem = createAsyncThunk(
  "changeManagement/getPlansForChangeManagementItem",
  async (changeManagementItemId: string, { dispatch }) => {
    const data = await protectAxios
      .get(`/changemanagementqueries/${changeManagementItemId}/Plans`)
      .then((resp) => resp.data);
    dispatch(setPlans(data));
    return data;
  }
);
export const fetchMilestonesForChangeManagementItem = createAsyncThunk(
  "changeManagement/getMilestonesForChangeManagementItem",
  async (changeManagementItemId: string, { dispatch }) => {
    const data = await protectAxios
      .get(`/changemanagementqueries/${changeManagementItemId}/Milestones`)
      .then((resp) => resp.data);
    dispatch(setMilestones(data));
    return data;
  }
);

export function newChangeManagementPlan(
  title: string,
  assignedToId?: string,
  assignedToName?: string
): ChangeManagementPlan {
  const IssuePlanStatusNotStarted = 0;
  return {
    checks: [],
    id: EmptyGuid(),
    title: title,
    issueId: EmptyGuid(),
    status: IssuePlanStatusNotStarted,
    statusDescription: "",
    assignedToId: assignedToId,
    assignedToName: assignedToName,
  };
}

export const itemIsNew = (item: ChangeManagementItemSummary) =>
  item.status === ChangeManagementStatus.New;
export const itemInProgress = (item: ChangeManagementItemSummary) =>
  item.status === ChangeManagementStatus.InProgress;
export const itemIsComplete = (item: ChangeManagementItemSummary) =>
  item.status === ChangeManagementStatus.Complete;
export const canEditChangeManagementItem = (
  item: ChangeManagementItemSummary
) =>
  item.status === ChangeManagementStatus.Approved ||
  item.status === ChangeManagementStatus.InProgress;
