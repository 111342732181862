import { useState } from "react";
import { AlertTableSummary } from "../AlertsDashboard/AlertTableSummary/AlertTableSummary";
import { Grid, Tabs, Tab, Card, Box } from "@mui/material";
import { TabPanel, a11yProps } from "../../common/DashboardTabs";
import NewAlertsTable from "./NewAlertsTable";

const AlertsDashboard = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Grid container direction="column">
      <Grid item>
        <AlertTableSummary />
      </Grid>
      <Grid item>
        <Card sx={{ border: "solid 1px #ddd", height: "auto", marginTop: 2 }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Alerts" {...a11yProps(0)} />
              <Tab label="Disabled Alerts" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <NewAlertsTable />
          </TabPanel>
          <TabPanel value={value} index={1}>
            TBD
          </TabPanel>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AlertsDashboard;
