
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';


export default function ReportsLineGraph(props) {
    const { timeData, seriesData, title, markers, text } = props

    const options = {
        chart: {
            zoomType: 'x',
            type: 'spline',
        },
        credits: {
            enabled: false
        },
        title: {
            text: title
        },
        xAxis: {
            type: 'datetime',
            tickInterval: 10,
            // array of string ['','','','','']
            categories: timeData,
        
        },
        yAxis: {
            title: {
                text: text
            },
        },
        plotOptions: {
            spline: {
                marker: {
                    enabled: markers
                },
                lineWidth: 2,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
            }
        },
        //seriesData is array of objects [{name: '', data: [1,2,3,4,5,6]}]
        series: seriesData
    }

    return (
        <Card sx={{width: '100%', m: 1, p: 1, border: "solid 1px #ddd",}}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Card>

    );
}