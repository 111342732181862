import { Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography, } from "@mui/material"


interface DeviceTabContentProps {
    content: any
}

export const DeviceTabContent = (props: DeviceTabContentProps) => {
    const { content } = props
    return (

        <TableContainer component={Paper} >
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                <TableBody >
                    {content.map((row: any, index: number) => (
                        <TableRow key={row.name} sx={{ maxWidth: 50 }}>
                            <TableCell align='left' sx={{ width: '30%', fontWeight: 'bold' }}>
                                {row.name}
                            </TableCell>
                            <TableCell align="left" sx={{ width: '70%' }}>
                                <Typography sx={{ whiteSpace: "pre-line" }}>
                                    {row.value.length === 0 ? 'N/A' : row.value}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

    )
}