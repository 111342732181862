import React from "react";
// import * as Mui from "@mui/material";
import { faPage } from "@fortawesome/pro-light-svg-icons";
import { Card } from "../../components/Card";
import { PlanTable } from "./PlanTable";
import {
  ChangeManagementItemSummary,
  ChangeManagementPlan,
} from "../../types/changemanagement.types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getPlans } from "../../redux/Slices/changeManagement.slice";
import {
  fetchPlansForChangeManagementItem,
  markPlanCheckAsDone,
} from "../../redux/Thunks/changeManagement";
import { useAsyncEffect } from "../../common/hooks";
import { Badge, Box, Button, ButtonGroup, Modal, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { EditPlanForm } from "./EditPlanForm";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const checksLeft = (plan: ChangeManagementPlan) => {
  var todo = plan.checks.filter((check) => {
    return !check.isComplete;
  });
  return todo.length;
};

interface PlansProps {
  changeManagementItem: ChangeManagementItemSummary;
  isReadOnly: boolean;

  //need some thing in here when a plan item is marked as done as the cnage item maybe completed.
}

export const Plans: React.FC<PlansProps> = (props) => {
  const { changeManagementItem } = props
  const plans = useAppSelector(getPlans);
  const [currentSelectedPlan, setCurrentSelectedPlan] = React.useState(0);
  const [showTodo, setShowTodo] = React.useState<boolean>(true);
  const dispatch = useAppDispatch();
  const showTodoItems = () => {
    setShowTodo(true);
  };
  const showAllItems = () => {
    setShowTodo(false);
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const handleMarkPlanCheckAsDone = async (checkId: string) => {
    await dispatch(
      markPlanCheckAsDone({
        changeManagementItemId: props.changeManagementItem.id,
        checkId,
      })
    );
    await dispatch(
      fetchPlansForChangeManagementItem(props.changeManagementItem.id)
    );
  };

  const { loading } = useAsyncEffect(async () => {
    await dispatch(
      fetchPlansForChangeManagementItem(props.changeManagementItem.id)
    );
  }, [props.changeManagementItem.id]);

  const determineBadgeColor = (plan: ChangeManagementPlan) => {
    switch (plan.statusDescription) {
      case 'Complete':
        return "success";
      case 'In Progress':
        return "primary";
      default:
        return "primary";
    }
  }
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>

          <EditPlanForm
            plans={plans}
            onClose={handleClose}
            changeManagementItem={changeManagementItem}
          />
        </Box>
      </Modal>
      <Card
        id="Plans"
        headerIcon={faPage}
        headerTitle={`${showTodo ? "Plans Left to Complete" : "All your plans"}`}
        headerSubText={`${props.changeManagementItem.completePlans} plans completed and ${props.changeManagementItem.completeMilestones} milestones completed`}
        showHelp={true}
        helpHeaderText="View Plans"
        helpHeaderDescription="This view shows you all your plans, use the switch on the right to toggle between 
            plans still left to complete or all the plans available under this change management. A Plan is considered
            complete when there is no more checks active.  Checks can be informational or a manual check."
        cardHeaderActions={
          <>
            <ButtonGroup>
              <Button onClick={handleOpen} sx={{ marginRight: "10px" }}>
                Edit Plans
              </Button>
            </ButtonGroup>
            <ButtonGroup>

              <Button
                sx={{
                  backgroundColor: showTodo ? "orange" : "white",
                  color: showTodo ? "white" : "orange",
                }}
                onClick={() => {
                  showTodoItems();
                }}
              >
                Todo
              </Button>
              <Button
                sx={{
                  backgroundColor: showTodo ? "white" : "orange",
                  color: showTodo ? "orange" : "white",
                }}
                onClick={() => {
                  showAllItems();
                }}
              >
                All
              </Button>
            </ButtonGroup>
          </>

        }
        cardContent={
          !loading ? (
            <>
              <Tabs
                value={currentSelectedPlan}
                variant="scrollable"
                scrollButtons="auto"
                onChange={(e, index) => setCurrentSelectedPlan(index)}
              >
                {plans.map((plan) => {
                  return (
                    <Tab
                      disableTouchRipple
                      label={
                        <Tooltip title={`${plan.statusDescription}`}>
                          <Badge
                            variant={plan.completeDate ? "dot" : "standard"}
                            badgeContent={checksLeft(plan)}
                            color={determineBadgeColor(plan)}
                            // color={plan.completeDate ? "success" : "primary"}
                            sx={{ p: plan.completeDate ? "1px" : "5px" }}
                          >
                            <Typography variant="subtitle2">
                              {plan.title}
                            </Typography>
                          </Badge>
                        </Tooltip>
                      }
                    />
                  );
                })}
              </Tabs>
              <>
                {plans.map((plan, index) => {
                  return (
                    <TabPanel
                      key={`planTable${index}`}
                      value={currentSelectedPlan}
                      index={index}
                    >
                      <PlanTable
                        isReadOnly={props.isReadOnly}
                        plan={plan}
                        showTodoOnly={showTodo}
                        onMarkPlanCheckAsDone={handleMarkPlanCheckAsDone}
                      />
                    </TabPanel>
                  );
                })}
              </>
            </>
          ) : (
            <>Loading...</>
          )
        }
      />
    </>
  );
};
