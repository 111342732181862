import * as React from "react";
import * as Mui from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { StepHeader } from "./StepHeader";

interface Details {
  title: string;
  description: string;
}

interface AddDetailsProps {
  onStepComplete: (stepNumber: number) => void;
  onEditStep: (stepNumber: number) => void;
  onSubmitDetails: (title: string, description: string) => void;
  stepNumber: number;
  currentStep: number;
  isComplete: boolean;
}

const DetailsComplete: React.FC<{ title: string; description: string }> = (
  props
) => {
  return (
    <>
      <Mui.Typography>
        Title:{" "}
        <Mui.Box component="span" fontWeight="bold">
          {props.title}
        </Mui.Box>
      </Mui.Typography>
      <Mui.Typography>
        Description:{" "}
        <Mui.Box component="span" fontWeight="bold">
          {props.description}
        </Mui.Box>
      </Mui.Typography>
    </>
  );
};

const DetailsTodo: React.FC = () => {
  return (
    <>
      <Mui.Typography>Details Required.</Mui.Typography>
      <Mui.Typography variant="subtitle2">
        Enter your details here such as the title and description
      </Mui.Typography>
    </>
  );
};

export const AddDetails: React.FC<AddDetailsProps> = (props) => {
  const addDetailsForm = useForm<Details>({
    mode: "onChange",
    reValidateMode: "onBlur",
  });

  const submitAddDetailsForm: SubmitHandler<Details> = (data) => {
    props.onSubmitDetails(data.title, data.description);
    props.onStepComplete(props.stepNumber);
  };

  return (
    <Mui.Card
      sx={{
        borderStyle: "solid",
        borderWidth: props.currentStep === props.stepNumber ? "3px" : "1px",
        borderColor:
          props.currentStep === props.stepNumber
            ? "orange"
            : props.isComplete
            ? "limegreen"
            : "#ddd",
        height: "100%",
      }}
    >
      <form
        name="addDetailsForm"
        onSubmit={addDetailsForm.handleSubmit(submitAddDetailsForm)}
        style={{ width: "100%" }}
      >
        <StepHeader
          stepNumber={props.stepNumber + 1}
          headerSubText=""
          headerText="New Change Mangement Item Details"
          isComplete={props.isComplete}
        />
        <Mui.CardContent sx={{ pt: 0 }}>
          {props.isComplete && props.currentStep !== props.stepNumber ? (
            <DetailsComplete
              title={addDetailsForm.getValues().title}
              description={addDetailsForm.getValues().description}
            />
          ) : !props.isComplete && props.currentStep !== props.stepNumber ? (
            <DetailsTodo />
          ) : (
            <Mui.Box>
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <Mui.TextField
                    name={name}
                    value={value}
                    label={"Title"}
                    sx={{ mr: 1, width: "40%", mb: 2 }}
                    size="small"
                    onChange={onChange}
                    error={addDetailsForm.formState.errors.title !== undefined}
                    helperText="Please enter a title"
                    //TODO: perhaps extend mui text field to add a property to prevent enter
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                      }
                    }}
                  />
                )}
                control={addDetailsForm.control}
                name={"title"}
                defaultValue=""
                rules={{ required: { value: true, message: "" } }}
              />
              <Controller
                render={({ field: { name, value, onChange } }) => (
                  <Mui.TextField
                    name={name}
                    value={value}
                    label={"Description"}
                    sx={{ mr: 1, width: "100%" }}
                    size="small"
                    multiline
                    rows={3}
                    onChange={onChange}
                    error={
                      addDetailsForm.formState.errors.description !== undefined
                    }
                    helperText={"Please enter a description"}
                  />
                )}
                control={addDetailsForm.control}
                name={"description"}
                defaultValue=""
                rules={{ required: { value: true, message: "" } }}
              />
            </Mui.Box>
          )}
        </Mui.CardContent>
        <Mui.CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
          {props.currentStep === props.stepNumber ? (
            <Mui.Button variant="outlined" type="submit">
              Save
            </Mui.Button>
          ) : (
            <></>
          )}
          {props.currentStep !== props.stepNumber ? (
            <Mui.Button
              variant="outlined"
              onClick={() => props.onEditStep(props.stepNumber)}
            >
              Edit
            </Mui.Button>
          ) : (
            <></>
          )}
        </Mui.CardActions>
      </form>
    </Mui.Card>
  );
};
