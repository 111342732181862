
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { faLaptop } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function PercentMonitoredDevices(props) {
    const { deviceStats, isLayoutChanged } = props
    const chartComponent = useRef({});
    const [noData, setNoData] = useState(false) 
    const [monitoredPercentage, setMonitoredPercentage] = useState(0)

    const getPercentage = (deviceStats) => {
        return +(((deviceStats.monitoringOn) / (deviceStats.deviceCount) * 100).toFixed(2))

    }
    const determineNoData = () => {
        if(deviceStats.monitoringOn === 0 && deviceStats.monitoringOff === 0){
         setNoData(true)
        }
     }
    useEffect(() => {
        determineNoData()
        if (deviceStats.monitoringOn && deviceStats.deviceCount) {
            setMonitoredPercentage(getPercentage(deviceStats))
        }
    }, [deviceStats]);

    const pieChartData = [
       
        deviceStats.monitoringOff !== 0 ?
        {
            name: "Off",
            y: deviceStats.monitoringOff,
            color: 'rgb(232,143,0, 0.4)',
        } : '',
        deviceStats.monitoringOn !== 0 ?
        {
            name: "On",
            y: deviceStats.monitoringOn,
            color: 'rgb(50,205,50, 0.4)',
        } : '',
       ]

    const options = {
        chart: {

            type: 'pie',
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: 100,
            labelFormatter: function () {
                return 'Monitoring ' + this.name;
            }
        },
        credits: {
            enabled: false
        },

        title: {
            text: noData ? 'No Data Available' : 'Monitored Devices',
        },

        subtitle: {
            text: noData ? '' : `${deviceStats.monitoringOn} Devices (${monitoredPercentage}%) Monitored`
        },

        tooltip: {
            pointFormat: ' {point.y} devices'
        },

        plotOptions: {
            pie: {
                borderRadius: 25,
                borderWidth: 5,
                dataLabels: {
                    enabled: true,
                    distance: '-30%',
                    format: '{point.y} {point.name}',

                },
                startAngle: -90,
                endAngle: 90,
                center: ['30%', '90%'],
                size: '135%',
                showInLegend: true
            }
        },
        
        series: [{
            name: "Total",
            innerSize: '50%',
            data: pieChartData,
        }],
    }

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [isLayoutChanged]);
    return (
        <Card sx={{ border: "solid 1px #ddd", height: '100%', padding: 1 }}>

            <FontAwesomeIcon
                icon={faLaptop}
                color={'rgb(232,143,0)'}
                size={'lg'}
                style={{ position: 'absolute', zIndex: 1 }}
            />
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />
        </Card>

    );
}