import React from "react";
import { useAsyncEffect } from "../../common/hooks";
import { Chat } from "../../components/Chat";
import { useIdentityContext } from "../../context/IdentityContext";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getComments } from "../../redux/Slices/changeManagement.slice";
import {
  addChangeManagementComment,
  fetchCommentsForChangeManagementItem,
} from "../../redux/Thunks/changeManagement";
import { ChangeManagementItemSummary } from "../../types/changemanagement.types";
import { AddCommentCommand } from "../../types/tickets.types";

interface ChangeManagementItemChatProps {
  item: ChangeManagementItemSummary;
}

export const ChangeManagementItemChat: React.FC<
  ChangeManagementItemChatProps
> = (props) => {
  const itemId = props.item.id;
  const comments = useAppSelector(getComments);

  var identityContext = useIdentityContext();
  const dispatch = useAppDispatch();

  const handleCommentAdded = async (comment: string, isImportant: boolean, isInternal: boolean) => {
    var newCommentCommand: AddCommentCommand = {
      comment: comment,
      isImportant: isImportant,
      isInternal: isInternal,
    };
    await dispatch(
      addChangeManagementComment({
        changeManagementItemId: itemId,
        command: newCommentCommand,
      })
    );
    await dispatch(fetchCommentsForChangeManagementItem(itemId));
  };

  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchCommentsForChangeManagementItem(itemId));
  }, [itemId]);

  return (
    <>
      {!loading ? (
        <Chat
          userId={identityContext.authenticatedUser.userId}
          comments={comments}
          onCommentAdded={handleCommentAdded}
          title="Change Management Comments"
          subTitle={<></>}
          disabled={false}
        />
      ) : (
        <>Loading</>
      )}
    </>
  );
};
