import { Ticket } from "../../../../types/tickets.types"

export const determineAutocompleteValues = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push(val.fullName)
    })
    return returnData
}

export const determineRowDataForUserStats = (value: any, allTickets: Ticket[]) => {
    const results = value[0].roles
    const finalResult = results.join('\r\n')
    const userId = value[0].id
    let usersTickets: Ticket[] = []
    let openTickets: Ticket[] = []
    let closedTickets: Ticket[] = []
    let inProgressTickets: Ticket[] = []
    allTickets.forEach((ticket: Ticket) => { if (ticket.assignedEngineerId === userId) { usersTickets.push(ticket) } })
    usersTickets.forEach((ticket: Ticket) => {
        if (ticket.status === 0) { openTickets.push(ticket) }
        if (ticket.status === 3) { closedTickets.push(ticket) }
        if (ticket.status === 1) { inProgressTickets.push(ticket) }
    })
    return [
        { value: 'User', count: value[0].fullName },
        { value: 'Email', count: value[0].email },
        { value: 'Company', count: value[0].companyName },
        { value: 'Roles', count: finalResult },
        { value: 'Raised Tickets', count: value[0].raisedTicketsCount },
        { value: 'Assigned Tickets', count: value[0].assignedTicketsCount },
        { value: 'In Progress Tickets / Closed Tickets', count: inProgressTickets.length + ' / ' + closedTickets.length },
        { value: '% Tickets In Progress', count: (inProgressTickets.length / usersTickets.length)*100 + '%' },
        { value: 'Assigned CM Items', count: value[0].assignedCMCount },
        { value: 'Assigned CM Plans', count: value[0].assignedCMPlansCount },
        { value: 'Assigned CM Milestones', count: value[0].assignedCMMilestonesCount },
    ]
}

export const determineBarChartDataForUserStats = (value: any, allTickets: Ticket[]) => {
    const userId = value[0].id
    let usersTickets: Ticket[] = []
    let openTickets: Ticket[] = []
    let closedTickets: Ticket[] = []
    let inProgressTickets: Ticket[] = []
    allTickets.forEach((ticket: Ticket) => { if (ticket.assignedEngineerId === userId) { usersTickets.push(ticket) } })
    usersTickets.forEach((ticket: Ticket) => {
        if (ticket.status === 0) { openTickets.push(ticket) }
        if (ticket.status === 3) { closedTickets.push(ticket) }
        if (ticket.status === 1) { inProgressTickets.push(ticket) }
    })
    return [
        value[0].assignedTicketsCount,
        inProgressTickets.length,
        closedTickets.length,
        value[0].raisedTicketsCount
    ]
}

export const determineBarChartDataCMForUserStats = (value: any) => {
    return [
        value[0].assignedCMCount,
        value[0].assignedCMMilestonesCount,
        value[0].assignedCMPlansCount

    ]
}

export const determineAssignedTicketData = (value: any) => {
    let assignedTixArray: any = []
    let raisedTixArray: any = []
    value[0].assignedTickets.forEach((val: any) => {
        assignedTixArray.push({ number: val.ticketNumber, title: val.title, id: val.id })
    })
    value[0].raisedTickets.forEach((val: any) => {
        raisedTixArray.push({ number: val.ticketNumber, title: val.title, id: val.id })
    })
    let returnData: any = [
        { type: 'Assigned Tickets', tickets: assignedTixArray },
        { type: 'Raised Tickets', tickets: raisedTixArray },
    ]
    return returnData
}

export const determineCMData = (value: any) => {
    let assignedCM: any = []
    let assignedMilestones: any = []
    let assignedPlans: any = []

    value[0].assignedCM.forEach((val: any) => {
        assignedCM.push({ number: val.changeManagementNumber, title: val.title, id: val.id })
    })
    value[0].assignedCMMilestones.forEach((val: any) => {
        assignedMilestones.push({ title: val.title, id: val.id })
    })
    value[0].assignedCMPlans.forEach((val: any) => {
        assignedPlans.push({ title: val.title, id: val.id })
    })
    let returnData: any = [
        { type: 'Assigned CM', items: assignedCM },
        { type: 'Assigned Milestones', items: assignedMilestones },
        { type: 'Assigned Plans', items: assignedPlans },

    ]
    return returnData
}

export const determineClusterData = (value: any, allTickets: Ticket[]) => {
    const userId = value[0].id
    let returnData: any = [
        {
            name: 'Open',
            data: [
                allTickets.filter((ticket: Ticket) => ticket.status === 0 && ticket.assignedEngineerId === userId).length,
                allTickets.filter((ticket: Ticket) => ticket.status === 0 && ticket.raisedById === userId).length
            ]
        },
        {
            name: 'In Progress',
            data: [
                allTickets.filter((ticket: Ticket) => ticket.status === 1 && ticket.assignedEngineerId === userId).length,
                allTickets.filter((ticket: Ticket) => ticket.status === 1 && ticket.raisedById === userId).length
            ]
        },
        {
            name: 'Closed',
            data: [
                allTickets.filter((ticket: Ticket) => ticket.status === 3 && ticket.assignedEngineerId === userId).length,
                allTickets.filter((ticket: Ticket) => ticket.status === 3 && ticket.raisedById === userId).length
            ]
        },
    ]
    return returnData
}

export const determineClusterDataCM = (value: any, cmSummaries: any) => {
    const userId = value[0].id
    const filteredSummaries = cmSummaries.filter((item: any) => item.assignedEngineerId === userId)
    let values = {
        completeMilestone: filteredSummaries.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.completeMilestones, 0),
        completePlans: filteredSummaries.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.completePlans, 0),
        overdueMilestones: filteredSummaries.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.overdueMilestones, 0),
        overduePlans: 0,
        dueMilestones: filteredSummaries.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.dueMilstones, 0),
        duePlans: 0,
        incompleteMilestones: filteredSummaries.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.incompleteMilestones, 0),
        incompletePlans: filteredSummaries.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.incompletePlans, 0), 
        notStartedMilestones: 0 ,
        notStartedPlans: filteredSummaries.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.notStartedPlans, 0), 
        inProgressMilestones: 0,
        inProgressPlans: filteredSummaries.reduce((accumulator: any, currentValue: any) => accumulator + currentValue.inProgressPlans, 0), 
    } 
    return [
        {
            name: 'Complete',
            data: [
                values.completeMilestone,
                values.completePlans
            ]
        },
        {
            name: 'Overdue',
            data: [
                values.overdueMilestones,
                null
            ]
        },
        {
            name: 'Due',
            data: [
                values.dueMilestones,
                null
            ]
        },
        {
            name: 'Incomplete',
            data: [
                values.incompleteMilestones,
                values.incompletePlans
            ]
        },
        {
            name: 'Not Started',
            data: [
                null,
                values.notStartedPlans
            ]
        },
        {
            name: 'In Progress',
            data: [
                null,
                values.inProgressPlans
            ]
        },
    ]
}