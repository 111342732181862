import { faBinoculars } from "@fortawesome/pro-light-svg-icons";
import { Box, Button, FormControl, Grid, InputLabel, Modal, OutlinedInput } from "@mui/material";
import { Card } from "../../components/Card";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchFlareString } from "../../redux/Thunks/alerts";
import { Loader } from "../../components/Loader";
import { getFlareAlertToSearch } from "../../redux/Slices/alerts.slice";



export const FlareTableSummary = () => {
    const dispatch = useAppDispatch();
  const alertToSearch = useAppSelector(getFlareAlertToSearch)

    const [loading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState<string>('')
    const [searchingFor, setSearchingFor] = useState<string>(alertToSearch)
    const handleCustomSearch = (query: string) => {
        setSearchValue(query)
    }

    const handleSearch = async () => {
        setLoading(true)
        setSearchingFor(searchValue)
        setSearchValue('')
        const command = `?queries=${searchValue}&useOr=true`
        const response = await dispatch(fetchFlareString(command));
        if (response.payload) {
            setLoading(false)
        }

    }

    return (
        <>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>
            <Card
                id="Tickets"
                headerIcon={faBinoculars}
                headerTitle={"Dark Web Results"}
                headerSubText={""}
                showHelp={false}
                helpHeaderText=""
                helpHeaderDescription=""
                cardHeaderActions={""}
                cardContent={
                    <Box>
                        <Grid container spacing={0}>
                            <Grid item xs={12} sx={{ mb: 1 }}>
                                <strong>Searching for: </strong>
                                {searchingFor}
                            </Grid>
                            <Grid item xs={4} sx={{ mb: 2, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                    <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Search '>Search</InputLabel>
                                    <OutlinedInput
                                        label='Search'
                                        sx={{ display: 'flex', flexGrow: 1 }}
                                        id="Searchs"
                                        size="small"
                                        value={searchValue}
                                        onChange={(event) =>
                                            handleCustomSearch(event.target.value)
                                        }
                                    />
                                </FormControl>

                                <Button
                                    variant="outlined"
                                    onClick={handleSearch}
                                    sx={{ml: 1}}
                                >
                                    Search
                                </Button>
                            </Grid>


                        </Grid>
                    </Box>
                }
            />
        </>
    );
};
