import React from "react";
import * as Mui from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface NotificationBannerProps {
  showBanner?: boolean;
  mainColour: string;
  icon: IconDefinition;
  headerText: string;
  subText: React.ReactNode;
  sx?: Mui.SxProps;
  children?: React.ReactNode;
}

export const NotificationBanner: React.FC<NotificationBannerProps> = (
  props
) => {
  return props.showBanner ? (
    <Mui.Grid container item xs={12} sx={props.sx}>
      <Mui.Box
        sx={{
          width: "100%",
          borderColor: props.mainColour,
          borderStyle: "solid",
          p: 2,
          borderWidth: "1px",
          backgroundColor: "#f3f1f5",
        }}
      >
        <Mui.Stack direction="row">
          <FontAwesomeIcon
            icon={props.icon}
            color={props.mainColour}
            size="2x"
          />
          <Mui.Stack direction="column" sx={{ pl: 2, width: "100%" }}>
            <Mui.Typography variant="h6" color={props.mainColour}>
              {props.headerText}
            </Mui.Typography>
            {props.subText}

            <Mui.Stack
              direction="row"
              sx={{ width: "100%" }}
              justifyContent="flex-end"
            >
              {props.children}
            </Mui.Stack>
          </Mui.Stack>
        </Mui.Stack>
      </Mui.Box>
    </Mui.Grid>
  ) : (
    <></>
  );
};
