import { faUser, faCheck, faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, List, ListItem, Button, Dialog, DialogContent, TextField, Typography, Box } from "@mui/material";
import { Card } from "../../../components/Card";
import { removeAuthenticator, verifyAuthenticator } from "../../../redux/Thunks/account";
import UploadAvatar from "../UploadAvatar";
import { useIdentityContext } from "../../../context/IdentityContext";
import { getSelectedCompanyId, getUserById, getAuthenticator } from "../../../redux/Slices/account.slice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getCompanyById } from "../../../redux/Slices/company.slice";
import { useState } from "react";
import QRCode from "react-qr-code";


export const PersonalInformation = () => {
    const { authenticatedUser } = useIdentityContext();
    const dispatch = useAppDispatch();
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const company = useAppSelector(getCompanyById(selectedCompanyId || authenticatedUser.companyId));
    const companyId = selectedCompanyId || company?.id;
    const user = useAppSelector(getUserById(authenticatedUser.userId));
    const isTwoFactorEnabled = user?.twoFactorEnabled;
    const [openTwoFactor, setOpenTwoFactor] = useState(false);
    const authenticator = useAppSelector(getAuthenticator);
    const [authCode, setAuthCode] = useState("");





    
    return (
        <Box>
            <Dialog onClose={() => setOpenTwoFactor(false)} open={openTwoFactor}>
                <DialogContent>
                    <Typography sx={{ marginBottom: 2 }}>
                        Scan the code below in an authenticator app and enter the resulting
                        code in the textbox below
                    </Typography>
                    <Grid container>
                        {" "}
                        <Grid item>
                            <QRCode value={authenticator.authenticatorUri} />
                        </Grid>
                        <Grid
                            container
                            sx={{ width: "fit-content" }}
                            alignItems="center"
                        >
                            <TextField
                                onChange={(e) => setAuthCode(e.target.value)}
                                sx={{ marginLeft: 2 }}
                                label="Authenticator Code"
                            ></TextField>
                            <Button
                                disabled={authCode === ""}
                                sx={{ marginLeft: 1 }}
                                variant="contained"
                                onClick={async () => {
                                    await dispatch(verifyAuthenticator({ code: authCode }));
                                    setOpenTwoFactor(false);
                                    window.location.reload();
                                }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
            <Card
                id="settings-personal"
                headerIcon={faUser}
                headerTitle={`Personal Information`}
                headerSubText={`View your account configuration.`}
                showHelp={false}
                helpHeaderText=""
                helpHeaderDescription=""
                cardHeaderActions={null}
                sx={{ height: "100%" }}
                cardContent={
                    <div>
                        <List
                            dense={true}
                            sx={{
                                width: "100%",
                                bgcolor: "background.paper",
                                overflowY: "auto",
                                maxHeight: "75vh",
                            }}
                        >
                            <ListItem>
                                <div style={{ marginRight: "16px" }}>
                                    <UploadAvatar
                                        uploadUrl="/accountcommands/UploadProfileImage"
                                        sx={{ width: 46, height: 46 }}
                                        imageUrl={`https://poimages.blob.core.windows.net/poimages/PortalImages/${companyId}/${authenticatedUser.userId}.png`}
                                    />
                                </div>
                                <p>
                                    {user?.emailAddress}
                                    <br />
                                    <small>{user?.roles}</small>
                                </p>
                            </ListItem>
                            <ListItem>
                                <b>Name:</b>
                                &nbsp; {user?.firstName} {user?.lastName}
                            </ListItem>
                            <ListItem>
                                <b style={{ marginRight: 10 }}>Email Verified:</b>
                                {user?.isConfirmed ? (
                                    <div>
                                        <FontAwesomeIcon
                                            size="lg"
                                            color="green"
                                            icon={faCheck}
                                        />
                                    </div>
                                ) : (
                                    <div>
                                        <FontAwesomeIcon
                                            size="lg"
                                            color="red"
                                            icon={faXmark}
                                        />
                                    </div>
                                )}
                            </ListItem>
                            <ListItem>
                                {" "}
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        if (isTwoFactorEnabled) {
                                            dispatch(removeAuthenticator(user.id));
                                        } else setOpenTwoFactor(true);
                                    }}
                                >
                                    {isTwoFactorEnabled
                                        ? "Remove Two Factor"
                                        : "Activate Two Factor"}
                                </Button>
                            </ListItem>
                        </List>
                    </div>
                }
            />
        </Box>
    )
}


