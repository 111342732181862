import { Marker, MarkerProps, Popup } from "react-leaflet";
import { useLeafletContext } from "@react-leaflet/core";
//import GreenMarkerIcon from "./marker-green.svg";
//import RedMarkerIcon from "./marker-red.svg";
import L, { LeafletEvent } from "leaflet";
import { useAppSelector } from "../../redux/hooks";
import { getDevices } from "../../redux/Slices/devices.slice";
import { useMemo, useState, useRef, useEffect, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  List,
  Paper,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DeviceSummaryListItem } from "../ViewDevice/DeviceSummary";
import {
  faHeart,
  faCalendar,
  faVrCardboard,
  faEye,
  ///faBell,
  faLaptop,
} from "@fortawesome/pro-light-svg-icons";
import { IStringIndexable } from "../../common/contracts";
import { IProtectOrgThemeHealthState } from "../../themes/ProtectOrgTheme";
import { FormattedDate } from "../../common/dateUtils";
import { Loader } from "../../components/Loader";
import { Link } from "react-router-dom";
import { convertLatLng } from "./LiveMap";
import { Coordinate } from "../../redux/Slices/maps.slice";
import ReactDOMServer from "react-dom/server";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserById } from "../../redux/Slices/account.slice";
import { useIdentityContext } from "../../context/IdentityContext";

/*const GreenLeafletIcon = new L.Icon({
  iconUrl: GreenMarkerIcon,
  iconSize: [75, 75],
  iconAnchor: [37.5, 75],
});
const RedLeafletIcon = new L.Icon({
  iconUrl: RedMarkerIcon,
  iconSize: [75, 75],
  iconAnchor: [37.5, 75],
});*/

export interface CustomMarkerProps extends MarkerProps {
  onDelete: () => void;
  onChange: (newCoordinate: Coordinate) => Promise<void>;
  onPositionChanged: (position: L.LatLng) => void;
  isEditMode?: boolean;
  coordinate: Coordinate;
}

const CustomMarker = (props: CustomMarkerProps) => {
  const {
    onDelete,
    onPositionChanged,
    onChange,
    coordinate,
    isEditMode,
    ...otherProps
  } = props;
  const {
    deviceGroupType: type,
    mapCoordinatesName: name,
    deviceIds,
  } = coordinate;
  const theme = useTheme();
  const { authenticatedUser } = useIdentityContext();
  const user = useAppSelector(getUserById(authenticatedUser.userId));
  const devices = useAppSelector(getDevices);
  const [draggable, setDraggable] = useState(true);
  const [searchDeviceName, setSearchDeviceName] = useState("");
  const [loading, setLoading] = useState(false);
  const [deviceLoading, setDeviceLoading] = useState(false);
  const [localDeviceIds, setLocalDeviceIds] = useState(deviceIds);
  const [expanded, setExpanded] = useState(false);
  const [groupName, setGroupName] = useState(name);
  const markerRef = useRef<L.Marker>(null);
  const context = useLeafletContext();

  const rolesArray = user?.roles.split(",");
  const isMapAdmin = rolesArray?.includes("Map Admin");

  useEffect(() => {
    setLocalDeviceIds(deviceIds);
  }, [deviceIds]);

  useEffect(() => {
    setGroupName(name);
  }, [name]);

  useEffect(() => {
    if (isEditMode && expanded) {
      setExpanded(false);
    }
  }, [isEditMode, expanded]);

  const onClick = useCallback(
    (e: LeafletEvent) => {
      //@ts-expect-error
      console.debug("click", e.originalEvent.target);
      setExpanded((state) => !state);
    },
    [setExpanded]
  );

  useEffect(() => {
    context.map.eachLayer((layer) => {
      //@ts-expect-error
      const latlng = layer._latlng;
      if (
        //@ts-expect-error
        convertLatLng(latlng || {})[0] === otherProps.position[0] &&
        //@ts-expect-error
        convertLatLng(latlng || {})[1] === otherProps.position[1]
      ) {
        layer.removeEventListener("dragend");
        layer.addEventListener("click", onClick);
        layer.addEventListener("dragend", (e) => {
          onPositionChanged(e.target._latlng);
        });
      }
    });
  }, [context.map, onClick, onPositionChanged, otherProps.position]);

  const eventHandlers = useMemo(
    () => ({
      click: () => {
        console.debug("hello world2");
      },
      dragend: () => {
        console.debug("Dragged");
        const marker = markerRef.current;
        console.debug("marker", marker);
        if (marker != null) {
          onPositionChanged(marker.getLatLng());
        }
      },
    }),
    [onPositionChanged]
  );

  const selectedDevices = useMemo(
    () =>
      localDeviceIds.map((id) => devices.find((device) => device.id === id)),
    [localDeviceIds, devices]
  );

  const selectedDevice = selectedDevices[0];

  const deviceOptions = useMemo(
    () => devices.map((device) => ({ name: device.deviceName, id: device.id })),
    [devices]
  );

  const selectedDeviceOptions = useMemo(
    () =>
      localDeviceIds
        .map((id) => deviceOptions.find((option) => option.id === id))
        .filter((option) => !!option) as { name: string; id: string }[],
    [localDeviceIds, deviceOptions]
  );

  const devicesStates = useMemo(
    () => ({
      healthy: selectedDevices.filter((device) => device?.healthState === 1)
        .length,
      warning: selectedDevices.filter((device) => device?.healthState === 3)
        .length,
      unknown: selectedDevices.filter((device) => device?.healthState === 5)
        .length,
    }),
    [selectedDevices]
  );

  const selectedDeviceOption = selectedDeviceOptions[0];

  const markerDivIcon = useMemo(() => {
    if (type === "single") {
      return new L.DivIcon({
        html: ReactDOMServer.renderToStaticMarkup(
          <div
            style={{
              backgroundColor:
                selectedDevice?.healthState === 1 ? "#32cd32" : "#d20000",
              width: "fit-content",
              boxShadow: "0 1px 3px rgba(0,0,0,0.4)",
              whiteSpace: "nowrap",
              padding: "2px",
              textOverflow: "ellipsis",
            }}
          >
            {selectedDevice?.deviceName || "No selected device"}
          </div>
        ),
      });
    } else {
      return new L.DivIcon({
        html: ReactDOMServer.renderToStaticMarkup(
          <Grid
            container
            style={{
              backgroundColor: "#f1f1f1",
              width: "fit-content",
              boxShadow: "0 1px 3px rgba(0,0,0,0.4)",
              whiteSpace: "nowrap",
              padding: "4px",
              border: "3px #395a7a solid",
            }}
            id="target-2"
          >
            <Grid
              container
              wrap="nowrap"
              style={{
                display: "flex",
                flexDirection: "row",
                width: "fit-content",
                padding: "1px",
              }}
              id="target-1"
            >
              <Grid
                item
                style={{
                  color: selectedDevices.every(
                    (selectedDevice) => selectedDevice?.healthState === 1
                  )
                    ? "#32cd32"
                    : "#d20000",
                  marginRight: "8px",
                  textOverflow: "ellipsis",
                  width: "150px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    width: "150px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {name || "No name set"}
                </div>
                {` (${devicesStates.healthy})`}
                <span
                  style={{ color: "orange" }}
                >{` (${devicesStates.warning})`}</span>
                <span
                  style={{ color: "red" }}
                >{` (${devicesStates.unknown})`}</span>
              </Grid>
            </Grid>

            {expanded &&
              !isEditMode &&
              selectedDevices.map((device) => (
                <Grid
                  container
                  wrap="nowrap"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "fit-content",
                    padding: "1px",
                  }}
                >
                  <Grid
                    item
                    style={{
                      color: device?.healthState === 1 ? "#32cd32" : "#d20000",
                      marginRight: "8px",
                      textOverflow: "ellipsis",
                      width: "150px",
                      overflow: "hidden",
                    }}
                  >
                    {device?.deviceName}
                  </Grid>
                  {/**<Grid
                    item
                    style={{ width: "fit-content" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <a
                      href={`/alerts/${device?.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <FontAwesomeIcon color="rgb(232, 143, 0)" icon={faBell} />
                    </a>
                    </Grid>*/}
                  <Grid
                    item
                    style={{ width: "fit-content", marginLeft: 4 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <a
                      href={`/device/${device?.id}/${device?.deviceGroup}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      <FontAwesomeIcon
                        color="rgb(232, 143, 0)"
                        icon={faLaptop}
                      />
                    </a>
                  </Grid>
                </Grid>
              ))}
          </Grid>
        ),
      });
    }
  }, [
    devicesStates.healthy,
    devicesStates.unknown,
    devicesStates.warning,
    expanded,
    name,
    selectedDevice?.deviceName,
    selectedDevice?.healthState,
    selectedDevices,
    type,
  ]);

  var indexableTheme =
    theme.healthStates as IStringIndexable<IProtectOrgThemeHealthState>;

  if (type === "") {
    return null;
  }

  let mainContent = <Loader show={true} />;

  if (loading) {
    return mainContent;
  }

  switch (type) {
    case "single":
      mainContent = (
        <>
          <Grid container>
            <Grid item sx={{ width: "100%" }}>
              <Autocomplete
                //disabled={!draggable}
                id="device-marker-autocomplete"
                options={deviceOptions}
                fullWidth
                //placeholder="Select a device"
                value={selectedDeviceOption}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Select a device"
                    value={searchDeviceName}
                    onChange={(event) =>
                      setSearchDeviceName(event.target.value || "")
                    }
                  />
                )}
                onChange={(_event, value) => {
                  if (!value) {
                    setSearchDeviceName("");
                    return;
                  }
                  setSearchDeviceName(value.name);
                  setLoading(true);
                  onChange({ ...coordinate, deviceIds: [value.id] }).then(() =>
                    setLoading(false)
                  );
                }}
                PaperComponent={({ children }) => (
                  <Paper style={{ background: "#efefef" }}>{children}</Paper>
                )}
              />
            </Grid>
          </Grid>
          {loading ? (
            <div style={{ marginTop: "-18vh" }}>
              <Loader show={true} />
            </div>
          ) : (
            <>
              {selectedDevice && (
                <Grid item sx={{ pt: 2 }}>
                  <Typography variant="subtitle2" sx={{ pl: 2 }}>
                    Device Group:{" "}
                    <Box component="span" fontWeight="bold">
                      {selectedDevice.deviceGroup}
                    </Box>
                  </Typography>
                  <Typography variant="subtitle2" sx={{ pl: 2 }}>
                    Type Of Device :{" "}
                    <Box component="span" fontWeight="bold">
                      {selectedDevice.deviceTypeDescription}
                    </Box>
                  </Typography>
                </Grid>
              )}
              {selectedDevice && (
                <Grid item>
                  <List
                    dense={true}
                    sx={{ width: "100%", bgcolor: "background.paper" }}
                  >
                    <DeviceSummaryListItem
                      icon={faHeart}
                      description={`Device is in a ${selectedDevice.healthStateDescription} state`}
                      color={indexableTheme[
                        selectedDevice.healthStateDescription.toLowerCase()
                      ].backgroundColor.toString()}
                    />
                    <DeviceSummaryListItem
                      icon={faCalendar}
                      description={`Device Created On  ${FormattedDate(
                        selectedDevice.createdDate
                      )}`}
                      color={"#ccc"}
                    />
                    <DeviceSummaryListItem
                      icon={faVrCardboard}
                      description={`Device is ${
                        selectedDevice.isVirtualMachine
                          ? "a virtual"
                          : "NOT a virtual"
                      } machine`}
                      color={"orange"}
                    />
                    <DeviceSummaryListItem
                      icon={faEye}
                      description={`Device Monitoring: ${selectedDevice.isMonitoredDescription}`}
                      color={"orange"}
                    />
                  </List>
                </Grid>
              )}
              {selectedDevice && (
                <Grid item>
                  <Link to={`/alerts/${selectedDevice.id}`}>View Alerts</Link>
                </Grid>
              )}
            </>
          )}
        </>
      );
      break;
    case "group":
      mainContent = (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item sx={{ width: 500, mb: 1 }}>
            <Autocomplete
              //disabled={!draggable}
              id="device-marker-autocomplete"
              options={deviceOptions}
              size="small"
              multiple
              fullWidth
              loading={deviceLoading}
              //placeholder="Select devices"
              value={selectedDeviceOptions}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Select devices" />
              )}
              onChange={(_event, value) => {
                setLocalDeviceIds(value.map((value) => value.id));
              }}
              PaperComponent={({ children }) => (
                <Paper style={{ background: "#efefef" }}>{children}</Paper>
              )}
            />
          </Grid>
          <Grid item mb={2}>
            <TextField
              label="Group Name"
              placeholder="Type name here"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
            />
          </Grid>
        </Grid>
      );
      break;
    default:
      break;
  }

  return (
    <Marker
      interactive={true}
      draggable={draggable}
      eventHandlers={eventHandlers}
      autoPan={true}
      icon={markerDivIcon}
      {...otherProps}
    >
      {isMapAdmin && isEditMode && (
        <Popup minWidth={600}>
          <Grid
            container
            direction="column"
            alignItems="center"
            sx={{ minWidth: 300 }}
          >
            {mainContent}

            <Grid container justifyContent="center">
              {type === "group" && (
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={() => {
                    setLoading(true);
                    onChange({
                      ...coordinate,
                      deviceIds: localDeviceIds,
                      mapCoordinatesName: groupName,
                    }).then(() => setLoading(false));
                  }}
                  sx={{ mr: 2 }}
                >
                  Save
                </Button>
              )}
              <Button
                variant="contained"
                color="error"
                disabled={loading}
                onClick={onDelete}
              >
                Delete {type === "single" ? "Marker" : "Group"}
              </Button>
            </Grid>
          </Grid>
        </Popup>
      )}
    </Marker>
  );
};

export default CustomMarker;
