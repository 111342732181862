
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { faBell} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function AlertPriorityBreakdown(props) {
    const { highPriority, mediumPriority, lowPriority, isLayoutChanged } = props
    const chartComponent = useRef({});
    const [noData, setNoData] = useState(false) 
    const [highPriorityPercentage, setHighPriorityPercentage] = useState(0)

    const getPercentage = (highPriority, mediumPriority, lowPriority) => {
        return +(((highPriority / (highPriority + mediumPriority + lowPriority)) * 100).toFixed(2))
    }
    const determineNoData = () => {
        if(highPriority === 0 && mediumPriority === 0 && lowPriority === 0){
         setNoData(true)
        }
     }
    useEffect(() => {
        determineNoData()
        if (highPriority && mediumPriority && lowPriority) {
            setHighPriorityPercentage(getPercentage(highPriority, mediumPriority, lowPriority))
        }
    }, [highPriority || mediumPriority || lowPriority]);

    const pieChartData = [
        highPriority !== 0 ?
        {
            name: "High",
            y: highPriority,
            color: 'rgb(255,0,0, 0.4)',
            // borderColor: 'rgb(255,0,0)',
        } : '',
        mediumPriority !== 0 ?
        {
            name: "Medium",
            y: mediumPriority,
            color: 'rgb(232,143,0, 0.4)',
            // borderColor: 'rgb(232,143,0)',
        } : '',
        lowPriority !== 0 ? 
        {
            name: "Low",
            y: lowPriority,
            color: 'rgb(50,205,50, 0.4)',
            // borderColor: 'rgb(153,153,153)',
        } : '']

    const options = {
        chart: {

            type: 'pie',
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: 100,
            labelFormatter: function () {
                if(this.y){
                    return this.name;
                }
                if(!this.y){
                    return 'No Values'
                }
            }
        },
        credits: {
            enabled: false
        },

        title: {
            text: noData ? 'No Data Available' : 'Breakdown of Priority',
        },

        subtitle: {
            text: noData ? '' : `${highPriority} Alerts (${highPriorityPercentage}%) at High Priority`
        },

        tooltip: {
            pointFormat: ' {point.y} alerts'
        },

        plotOptions: {
            pie: {
                borderRadius: 25,
                borderWidth: 5,
                dataLabels: {
                    enabled: true,
                    distance: '-30%',
                    format: '{point.y} {point.name}',

                },
                startAngle: -90,
                endAngle: 90,
                center: ['35%', '90%'],
                size: '135%',
                showInLegend: true
            }
        },
        series: [{
            name: "Total",
            innerSize: '50%',
            data: pieChartData,
        }],
    }

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [isLayoutChanged]);
    return (
        <Card sx={{ border: "solid 1px #ddd", height: '100%', padding: 1 }}>

            <FontAwesomeIcon
                icon={faBell}
                color={'#e88f00'}
                size={'lg'}
                style={{ position: 'absolute', zIndex: 1 }}
            />
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />
        </Card>

    );
}