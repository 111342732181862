import * as Mui from "@mui/material";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import largeLogo from "../../images/LogoLarge.svg";
import { ConfirmInvitePersonalDetails } from "./ConfirmInvitePersonalDetails";
import { StepAllDone } from "./StepAllDone";
import { ConfirmSignupSetPassword } from "./ConfirmSignupSetPassword";
import { WelcomePage } from "./WelcomePage";
import { ConfirmInviteCommand } from "../../types/account.types";
import { useAppDispatch } from "../../redux/hooks";
import { confirmInvitedUser } from "../../redux/Thunks/account";

export const ConfirmInviteSignupPage: React.FC = () => {
  const steps = ["Welcome", "Your Details", "Your Password", "All done!"];
  const dispatch = useAppDispatch();
  const [activeStep, setActiveStep] = React.useState(0);
  const [confirmInviteData, setConfirmInviteData] =
    React.useState<ConfirmInviteCommand>({
      firstName: "",
      surname: "",
      token: "",
      password: "",
    });

  const [searchParams] = useSearchParams();
  var navigate = useNavigate();
  const { id } = useParams();

  var token = searchParams.get("token") || null;

  const handlePersonalDetails = (personalDetails: ConfirmInviteCommand) => {
    setConfirmInviteData({
      ...confirmInviteData,
      firstName: personalDetails.firstName,
      surname: personalDetails.surname,
    });
    setActiveStep(2);
  };

  const handlePassword = async (password: string) => {
    if (id && token) {
      var command: ConfirmInviteCommand = {
        ...confirmInviteData,
        password: password,
        token: token,
      };
      const action = await dispatch(
        confirmInvitedUser({ userId: id, command })
      );
      if (action.payload) {
        setActiveStep(3);
      }
    }
  };

  const handleComplete = () => {
    navigate("/");
  };

  return (
    <Mui.Box display="flex" justifyContent="center" alignItems="center">
      <Mui.Stack
        direction="column"
        sx={{ width: { xs: "100%", md: "60%", xxl: "50%" } }}
        alignItems="center"
      >
        <Mui.Box
          component="img"
          sx={{
            content: { xs: `url(${largeLogo})` },
            maxWidth: { xs: "350px" },
            minHeight: "100px",
          }}
          alt="Security Portal"
        />
        <Mui.Stepper
          activeStep={activeStep}
          sx={{ width: "70%", mb: 4, mt: 4 }}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Mui.Step key={index} {...stepProps}>
                <Mui.StepLabel {...labelProps}>{label}</Mui.StepLabel>
              </Mui.Step>
            );
          })}
        </Mui.Stepper>

        {activeStep === 0 ? (
          <WelcomePage
            introMessage="In a moment you will be asked for a few details and then your password and after that, you will be taken to the login screen so you can start using the portal."
            onNextClick={() => {
              setActiveStep(1);
            }}
          />
        ) : (
          <></>
        )}

        {activeStep === 1 ? (
          <ConfirmInvitePersonalDetails
            formData={confirmInviteData}
            onNextClick={(personalDetails) => {
              handlePersonalDetails(personalDetails);
            }}
            onPreviousClick={() => {
              setActiveStep(0);
            }}
          />
        ) : (
          <></>
        )}

        {activeStep === 2 ? (
          <ConfirmSignupSetPassword
            onNextClick={(password) => {
              handlePassword(password);
            }}
            onPreviousClick={() => {
              setActiveStep(1);
            }}
          />
        ) : (
          <></>
        )}

        {activeStep === 3 ? (
          <StepAllDone
            onNextClick={() => {
              handleComplete();
            }}
          />
        ) : (
          <></>
        )}
      </Mui.Stack>
    </Mui.Box>
  );
};
