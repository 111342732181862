//@ts-nocheck
import {
  Grid,
  Button,
  CardActions,
  CardContent,
  FormGroup,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  Box,
  InputLabel,
} from "@mui/material";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useIdentityContext } from "../../context/IdentityContext";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
// import { raiseRunAsAccount, fetchRunAsAccounts } from "../../redux/Thunks/devices";
import {
  RaiseRunAsAccountCommand,
  RunAsAccounts,
} from "../../types/devices.types";
import { getAccountTypes } from "../../redux/Slices/devices.slice";
import { getCompanies } from "../../redux/Slices/company.slice";
import { useEffect, useState } from "react";
import { getUserById } from "../../redux/Slices/account.slice";
import {
  raiseRunAsAccount,
  fetchRunAsAccounts,
} from "../../redux/Thunks/devices";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
interface EditRunAsAccountsFormProps {
  onClose: () => void;
  accountToEdit: any;
}
export const EditRunAsAccountsForm = (props: EditRunAsAccountsFormProps) => {
  const { onClose, accountToEdit } = props;
  const { control, register, handleSubmit, reset } =
    useForm<RaiseRunAsAccountCommand>();
  const identity = useIdentityContext();
  const dispatch = useAppDispatch();
  const accountTypes = useAppSelector(getAccountTypes);
  const filteredAccountTypes = accountTypes.filter(
    (el) => el.name === "Windows"
  );
  const companies = useAppSelector(getCompanies);
  const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
  const [initialData, setInitialData] = useState<RunAsAccounts>(accountToEdit);
  const initialCompany = identity.authenticatedUser.companyId;

  const [isFormError, setIsFormError] = useState({
    description: false,
    password: false,
    passwordValidation: false,
    domain: false,
    runAsAccountTypeId: false,
    companyId: false,
  });
  const [passwordValidation, setPasswordValidation] = useState("");
  const [password, setPassword] = useState("");
  const anyErrors = Object.values(isFormError).includes(true);

  const validateDomainName = (event: any) => {
    if (!event.target.value.includes("\\")) {
      setIsFormError({ ...isFormError, domain: true });
    }
    if (event.target.value.includes("\\")) {
      if (event.target.value.split("\\")[1].length > 0) {
        setIsFormError({ ...isFormError, domain: false });
      }
    }
  };
  useEffect(() => {
    if (passwordValidation) {
      if (passwordValidation !== password) {
        setIsFormError({ ...isFormError, password: true });
      }
      if (passwordValidation === password) {
        setIsFormError({ ...isFormError, password: false });
      }
    }
  }, [password, passwordValidation]);

  const onCreateRunAsAccount: SubmitHandler<RaiseRunAsAccountCommand> = async (
    data
  ) => {
    const payload = {
      description: data.description,
      userName: user!.emailAddress,
      password: data.password,
      domain: initialData.domain,
      runAsAccountTypeId: initialData.runAsAccountTypeId,
      companyId: initialCompany,
    };
    await dispatch(raiseRunAsAccount(payload));
    await dispatch(fetchRunAsAccounts());
    onClose();
  };
  return (
    <Card sx={style}>
      <Typography variant="h3" component="h3">
        Edit Run-As Account
      </Typography>
      <CardContent>
        <form onSubmit={handleSubmit(onCreateRunAsAccount)}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid container item xs={12}>
                {/*DOMAIN NAME */}
                <TextField
                  inputProps={register("domain")}
                  disabled={true}
                  onChange={(event) => validateDomainName(event)}
                  error={isFormError.domain}
                  required
                  id="outlined-required"
                  label="Account Name"
                  sx={{ width: "50%", mr: 1, marginBottom: "20px" }}
                  size="small"
                  value={initialData.domain}
                  helperText="Account name must be of the form: Domain\Username"
                />
                {/*PASSWORD */}
                <TextField
                  inputProps={register("password")}
                  onChange={(event) => setPassword(event.target.value)}
                  type="password"
                  required
                  id="outlined-required"
                  label="New Password"
                  sx={{ width: "50%", mr: 1, marginBottom: "20px" }}
                  size="small"
                />
                {/*CONFIRM PASSWORD */}
                <TextField
                  onChange={(event) =>
                    setPasswordValidation(event.target.value)
                  }
                  error={isFormError.password}
                  type="password"
                  required
                  id="outlined-required"
                  label="Confirm New Password"
                  sx={{ width: "50%", mr: 1, marginBottom: "20px" }}
                  size="small"
                  helperText={isFormError.password ? "Must match password" : ""}
                />
                {/* DESCRIPTION TEXTFIELD */}
                <TextField
                  inputProps={register("description")}
                  id="outlined-multiline-flexible"
                  // label="Description"
                  multiline
                  maxRows={4}
                  fullWidth
                  size="small"
                  rows={4}
                  required
                  // value={initialData.description}

                  helperText="Please supply a full description (max 2000 chars)"
                  sx={{ mt: 2 }}
                />
                {/* ACCOUNT TYPE DROP DOWN*/}
                <Box sx={{ display: "flex", paddingTop: "20px" }}>
                  <Controller
                    name="runAsAccountTypeId"
                    control={control}
                    render={({ field }) => (
                      <Grid container rowSpacing={0}>
                        <Grid item xs={12}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Account Type
                          </InputLabel>
                          <Select
                            {...field}
                            size="small"
                            required
                            label="Account Type"
                            value={initialData.runAsAccountTypeId}
                            disabled={true}
                            sx={{ width: "300px", marginRight: "20px" }}
                          >
                            {filteredAccountTypes.map((item, index) => {
                              return (
                                <MenuItem
                                  key={index}
                                  value={item.runAsAccountTypeId}
                                >
                                  {item.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={12} p={0}></Grid>
                      </Grid>
                    )}
                  />

                  {/* COMPANY DROP DOWN */}
                  <Controller
                    name="companyId"
                    control={control}
                    render={({ field }) => (
                      <Grid container rowSpacing={0}>
                        <Grid item xs={12}>
                          <InputLabel id="demo-multiple-checkbox-label">
                            Company
                          </InputLabel>
                          <Select
                            {...field}
                            size="small"
                            required
                            label="Account Type"
                            value={initialCompany}
                            disabled={true}
                            sx={{ width: "300px", mb: 1 }}
                          >
                            {companies.map((item, index) => {
                              return (
                                <MenuItem key={index} value={item.id}>
                                  {item.companyName}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Grid>
                        <Grid item xs={12} p={0}></Grid>
                      </Grid>
                    )}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
            <FormGroup row>
              <Button
                variant="outlined"
                color="secondary"
                sx={{ mr: 1 }}
                onClick={() => {
                  reset();
                  setInitialData({ ...initialData, description: "" });
                }}
              >
                Clear
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                disabled={anyErrors}
              >
                Submit
              </Button>
            </FormGroup>
          </CardActions>
        </form>
      </CardContent>
    </Card>
  );
};
