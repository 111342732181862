import dayjs from "dayjs";

export const onHandleTimeIntervalChange = (event: any) => {
    switch (event.target.value) {
        case 'Day':
            return dayjs().subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Week':
            return dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Month':
            return dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '2 Months':
            return dayjs().subtract(2, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '3 Months':
            return dayjs().subtract(3, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '4 Months':
            return dayjs().subtract(4, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '5 Months':
            return dayjs().subtract(5, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '6 Months':
            return dayjs().subtract(6, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '7 Months':
            return dayjs().subtract(7, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '8 Months':
            return dayjs().subtract(8, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '9 Months':
            return dayjs().subtract(9, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '10 Months':
            return dayjs().subtract(10, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '11 Months':
            return dayjs().subtract(11, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Year':
            return dayjs().subtract(1, "year").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'All Time':
            return dayjs().subtract(100, "year").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        default:
            return dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }


}

export const determineBarChartCategoriesForTicketProblem = (value: any) => {
    let returnData: any = []
    value.forEach((ticket: any) => {
        returnData.push(ticket.description)
    })
    return returnData
}

export const determineBarChartDataForTicketProblemCount = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push(val.count)
    })
    return returnData
}

export const determinePieDataForTicketProblemCount = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push({name: val.description, y: val.count})
    })
    return returnData
}

export const determineRowDataForTicketProblemCount = (value: any) => {
    let returnData: any = []
    let totalArray: any = []
    value.forEach((val: any) => {
        totalArray.push(val.count)
    })
    const total = totalArray.reduce((accumulator: any, currentValue: any) => accumulator + currentValue);
    value.forEach((val: any) => {
        returnData.push({ value: val.description, count: val.count, percentage: ((100 * val.count) / total).toFixed(2) })
    })
    return returnData
}

export const determineTimeDataForTicketStatusPerDay = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push(dayjs(val.statusDate).format('MM/DD/YY'))
    })
    return returnData
}

export const determineSeriesDataForTicketStatusPerDay = (value: any) => {
    let formatArray: any = [];
    value.forEach((stat: any) => {
        formatArray.push(stat.statusCount)
    })
    const returnData: any = [{name: 'Tickets', data: formatArray}]
    return returnData
}

export const determineRowDataForTicketStatusPerDay = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push({value: dayjs(val.statusDate).format('MM/DD/YY'), count: val.statusCount})
    })
    return returnData
}

export const determineBarChartCategoiresForTicketsRaisedBy = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push(val.raisedByName)
    })
    return returnData
}

export const determineBarChartDataForTicketsRaisedBy = (value: any ) => {
    let retrunData: any = []
    value.forEach((val: any) => {
        retrunData.push(val.count)
    })
    return retrunData
}

export const determinePieDataForTicketRaisedBy = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push({name: val.raisedByName, y: val.count})
    })
    return returnData
}

export const determineRowDataForTicketRaisedBy = (value: any) => {
    let returnData: any = []
    let totalArray: any = []
    value.forEach((val: any) => {
        totalArray.push(val.count)
    })
    const total = totalArray.reduce((accumulator: any, currentValue: any) => accumulator + currentValue);
    value.forEach((val: any) => {
        returnData.push({ value: val.raisedByName, count: val.count, percentage: ((100 * val.count) / total).toFixed(2) })
    })
    return returnData
}

export const determineBarChartCategoriesForAssignedEngineers = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push(val.assignedEngineerName)
    })
    return returnData
}

export const determineBarChartDataForAssignedEngineer = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push(val.count)
    })
    return returnData
}

export const determinePieDataForAssignedEngineer = (value: any) => {
    let returnData: any = []
    value.forEach((val: any) => {
        returnData.push({name: val.assignedEngineerName, y: val.count})
    })
    return returnData
}

export const determineRowDataForAssignedEngineer = (value: any) => {
    let returnData: any = []
    let totalArray: any = []
    value.forEach((val: any) => {
        totalArray.push(val.count)
    })
    const total = totalArray.reduce((accumulator: any, currentValue: any) => accumulator + currentValue);
    value.forEach((val: any) => {
        returnData.push({ value: val.assignedEngineerName, count: val.count, percentage: ((100 * val.count) / total).toFixed(2) })
    })
    return returnData
}