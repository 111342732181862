import * as React from "react";
import {
  createTheme,
  CssBaseline,
  responsiveFontSizes,
  Theme,
  ThemeOptions,
  ThemeProvider,
} from "@mui/material";

interface IPaletteTheme {
  name: string;
  palette: ThemeOptions;
}

interface CustomThemeProviderProps {
  palettes: IPaletteTheme[];
  baseTheme: Theme;
  defaultThemeName: string;
  children: React.ReactNode;
}

const CustomThemeContext = React.createContext<
  { setPalette: (paletteName: string) => void } | undefined
>(undefined);

const CustomThemeProvider: React.FunctionComponent<
  CustomThemeProviderProps
> = ({ defaultThemeName, palettes, baseTheme, children }) => {
  const [currentPaletteName, setCurrentPaletteName] =
    React.useState(defaultThemeName);

  function switchPalette(paletteName: string) {
    setCurrentPaletteName(paletteName);
  }

  const theme = React.useMemo(() => {
    var currentPaletteIndex = palettes
      .map((p) => p.name)
      .indexOf(currentPaletteName);
    if (currentPaletteIndex === -1) currentPaletteIndex = 0;
    return responsiveFontSizes(
      createTheme(baseTheme, palettes[currentPaletteIndex].palette)
    );
  }, [currentPaletteName, palettes, baseTheme]);

  //TODO: maybe put this in another function

  theme.typography = {
    ...theme.typography,
    h1: {
      ...theme.typography.h1,
      [theme.breakpoints.down("xxl")]: {
        fontSize: "1.5rem",
        fontWeight: "600",
      },
      [theme.breakpoints.up("xxl")]: { fontSize: "1.8rem", fontWeight: "600" },
    },
    h2: {
      ...theme.typography.h2,
      [theme.breakpoints.down("xxl")]: {
        fontSize: "1.35rem",
        fontWeight: "550",
      },
    },
    h3: {
      ...theme.typography.h3,
      [theme.breakpoints.down("xxl")]: {
        fontSize: "1.25rem",
        fontWeight: "500",
      },
      [theme.breakpoints.up("xxl")]: { fontSize: "1.55rem", fontWeight: "500" },
    },
    h4: {
      ...theme.typography.h4,
      [theme.breakpoints.down("xxl")]: {
        fontSize: "1.1rem",
        fontWeight: "450",
      },
    },
    h5: {
      ...theme.typography.h5,
      [theme.breakpoints.down("xxl")]: {
        fontSize: "0.95rem",
        fontWeight: "400",
      },
    },
    h6: {
      ...theme.typography.h6,
      [theme.breakpoints.down("xxl")]: {
        fontSize: "0.85rem",
        fontWeight: "400",
      },
      //[theme.breakpoints.up('xxl')]: { for larger }
    },
    body1: {
      ...theme.typography.body1,
      [theme.breakpoints.down("xxl")]: { fontSize: "0.8125rem" },
    },
    body2: {
      ...theme.typography.body2,
      [theme.breakpoints.down("xxl")]: { fontSize: "0.8125rem" },
    },
    subtitle1: {
      ...theme.typography.subtitle1,
      [theme.breakpoints.down("xxl")]: { fontSize: "0.7125rem" },
    },
    subtitle2: {
      ...theme.typography.subtitle2,
      [theme.breakpoints.down("xxl")]: { fontSize: "0.7125rem" },
    },
    button: {
      ...theme.typography.button,
      [theme.breakpoints.down("xxl")]: { fontSize: "0.6125rem" },
    },
    /*also caption and overline to override.*/
  };

  return (
    <CustomThemeContext.Provider value={{ setPalette: switchPalette }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </CustomThemeContext.Provider>
  );
};

function useCustomTheme() {
  const context = React.useContext(CustomThemeContext);
  if (context === undefined) {
    throw new Error("useCustomTheme must be used within a CustomThemeProvider");
  }
  return context;
}

export { CustomThemeProvider, useCustomTheme };
