import * as Mui from "@mui/material";

interface StepHeaderProps {
  stepNumber: number;
  headerText: string;
  headerSubText: string;
  isComplete: boolean;
}

export const StepHeader: React.FC<StepHeaderProps> = (props) => {
  return (
    <Mui.CardHeader
      avatar={
        <Mui.Avatar
          sx={{
            backgroundColor: "white",
            borderStyle: "solid",
            borderWidth: "3px",
            borderColor: props.isComplete ? "limegreen" : "orange",
          }}
        >
          <Mui.Typography color={props.isComplete ? "limegreen" : "orange"}>
            {props.stepNumber}
          </Mui.Typography>
        </Mui.Avatar>
      }
      title={
        <Mui.Typography sx={{ fontWeight: "bold" }}>
          {props.headerText}
        </Mui.Typography>
      }
      subheader={props.headerSubText}
      sx={{
        borderBottom: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: props.isComplete ? "limegreen" : "orange",
        p: 1,
        mb: 1,
      }}
    />
  );
};
