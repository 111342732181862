import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import { Box, Grid } from "@mui/material";
import { Card } from "../../components/Card";
import { InvestigationSummaryButtonGroup } from "./InvestigationSummaryButtonGroup";
import { InvestigatedEntitiesLink } from "./InvestigatedEntitiesLink";
import { InvestigatedTypesLink } from "./InvestigatedTypesLink";
import { StartTimeEndTime } from "./StartTimeEndTime";


interface InvestigateAlertTableSummaryProps {
  investigatedAlert: any;
  currentAlert: any;
}

export const InvestigateAlertTableSummary = (props: InvestigateAlertTableSummaryProps) => {
  const { investigatedAlert, currentAlert } = props

  return (
    <Card
      id="Tickets"
      headerIcon={faMagnifyingGlass}
      headerTitle={"Incident Investigation"}
      headerSubText={"Investigating further will take you away from the initial incident."}
      showHelp={false}
      helpHeaderText=""
      helpHeaderDescription=""
      cardHeaderActions={""}
      cardContent={
        <Box>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{mb: 1}}>
              <strong>Investigating Incident: </strong>
              {currentAlert?.description}
            </Grid>

            <Grid item xs={12} sx={{mb: 1}}>
              <StartTimeEndTime
                investigatedAlert={investigatedAlert}
                currentAlert={currentAlert}
              />

            </Grid>

            <Grid item xs={10} sx={{mb: 1}}>
              <InvestigatedEntitiesLink 
              investigatedAlert={investigatedAlert} 
              currentAlert={currentAlert}
              />

            </Grid>
            <Grid item xs={2} sx={{ position: 'absolute', right: 50, mb: 1 }} >
              <InvestigationSummaryButtonGroup currentAlert={currentAlert} />

            </Grid>
            <Grid item xs={12} sx={{mb: 1}}>
              <InvestigatedTypesLink
                investigatedAlert={investigatedAlert}
                currentAlert={currentAlert}
              />

            </Grid>
          </Grid>
          <Box>


          </Box>









        </Box>
      }
    />
  );
};
