//@ts-nocheck
import React from "react";
import * as Mui from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserPlus,
  faUser,
  faCircleCheck,
} from "@fortawesome/pro-light-svg-icons";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
//import largeLogo from "../../images/success.png";
import { CardHeader } from "../../components/CardHeader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAsyncEffect } from "../../common/hooks";
import { fetchAllRoles, inviteUser } from "../../redux/Thunks/account";
import { getRoles } from "../../redux/Slices/account.slice";
import { Loader } from "../../components/Loader";
import { useIdentityContext } from "../../context/IdentityContext";
import { getCompanyById } from "../../redux/Slices/company.slice";
import { fetchCompanies } from "../../redux/Thunks/company";

interface QuickInviteCommand {
  emailAddress: string;
  role: string;
}

interface QuickInviteProps {
  onUserInvited: VoidFunction;
  onCompanyClicked?: VoidFunction;
}

export const QuickInvite: React.FC<QuickInviteProps> = (props) => {
  const validationSchema = yup.object().shape({
    emailAddress: yup.string().email("Invitee email address needs to be valid"),
  });
  const dispatch = useAppDispatch();
  const [step, setStep] = React.useState(0);
  const roles = useAppSelector(getRoles);

  const {
    control,
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<QuickInviteCommand>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      emailAddress: "",
      role: "",
    },
  });

  const onSubmit: SubmitHandler<QuickInviteCommand> = async (data) => {
    setStep(1);
    await dispatch(
      inviteUser({ emailAddress: data.emailAddress, role: data.role })
    );
    setStep(2);
    props.onUserInvited();
    reset({});
  };

  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchAllRoles());
  }, []);

  const InviteForm: React.FC = () => {
    const identity = useIdentityContext();
    const company = useAppSelector(
      getCompanyById(identity.authenticatedUser.companyId)
    );
    const { loading } = useAsyncEffect(async () => {
      if (!company) {
        await dispatch(fetchCompanies());
      }
    }, [identity]);

    return (
      <>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Mui.CardContent>
            {roles && !loading ? (
              <>
                <Mui.Typography variant="h6" sx={{ mb: 2 }}>
                  <FontAwesomeIcon
                    icon={faUser}
                    color={"orange"}
                    size="lg"
                  />{" "}
                  Invite a user into the Security Portal.
                </Mui.Typography>

                <Mui.TextField
                  inputProps={register("emailAddress")}
                  required
                  size="small"
                  id="outlined-required"
                  label="Users Email Address"
                  sx={{ width: "100%" }}
                  helperText=""
                  error={errors.emailAddress !== undefined}
                />

                <Controller
                  name="role"
                  control={control}
                  defaultValue={""}
                  render={({ field }) => (
                    <>
                      <Mui.Typography variant="subtitle2" sx={{ mt: 2 }}>
                        Role of new team member
                      </Mui.Typography>
                      <Mui.Select
                        {...field}
                        displayEmpty
                        required
                        size="small"
                        sx={{ width: "100%", mb: 2 }}
                      >
                        {[
                          {
                            id: "",
                            name: "Please Select",
                            normalizedName: "",
                            description: "",
                          },
                        ]
                          .concat(roles)
                          .map((item, index) => {
                            return (
                              <Mui.MenuItem
                                key={index}
                                value={item.normalizedName}
                              >
                                {item.name}
                              </Mui.MenuItem>
                            );
                          })}
                      </Mui.Select>
                    </>
                  )}
                />
              </>
            ) : (
              <>Loading Roles....</>
            )}
            <Mui.Typography>
              Company:{" "}
              {loading ? (
                "loading"
              ) : (
                <Mui.Link
                  sx={{ cursor: "pointer" }}
                  onClick={props.onCompanyClicked}
                >
                  {company?.companyName}{" "}
                </Mui.Link>
              )}
            </Mui.Typography>
          </Mui.CardContent>
          <Mui.CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
            <Mui.Button
              variant="outlined"
              type="submit"
            >
              Invite User
            </Mui.Button>
          </Mui.CardActions>
        </form>
      </>
    );
  };

  const InviteInProgress: React.FC = () => {
    return (
      <>
        <Loader show={true} />
      </>
    );
  };

  const InviteSuccess: React.FC = () => {
    return (
      <Mui.CardContent>
        <Mui.Stack direction="column" spacing={2} alignItems="center">
          <Mui.Box
            component="img"
            sx={{
              content: { xs: `url(${largeLogo})` },
              maxWidth: { xs: "350px" },
              align: "center",
              minHeight: "100px",
            }}
            alt=""
          />
          <FontAwesomeIcon icon={faCircleCheck} color={"limegreen"} size="3x" />
          <Mui.Typography variant="body1">
            user was invited successfully, they will have a max of{" "}
            <strong>30 days</strong> to respond.
          </Mui.Typography>
          <Mui.Button
            variant="contained"
            onClick={() => {
              setStep(0);
            }}
          >
            Invite More!
          </Mui.Button>
        </Mui.Stack>
      </Mui.CardContent>
    );
  };

  return (
    <Mui.Card>
      <CardHeader
        icon={faUserPlus}
        subText={"0 Users have been added into the Security Portal"}
        title="Invite a User"
      />
      {step === 0 ? <InviteForm /> : <></>}
      {step === 1 ? <InviteInProgress /> : <></>}
      {step === 2 ? <InviteSuccess /> : <></>}
    </Mui.Card>
  );
};

export const CompanySummaryLoading: React.FC = (props) => {
  return <>Loading!</>;
};
