import { FormControl, Grid, MenuItem, InputLabel, Select, SelectChangeEvent, OutlinedInput, IconButton, Button, ButtonGroup, Modal, FormHelperText } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { useState } from "react";
import { updateCompanySentinelConfig, validateSentinelConfig } from "../../redux/Thunks/company";
import { Loader } from "../Loader";
import { useAppDispatch } from "../../redux/hooks";


interface UpdateSentinelConfigProps {
    sentinelConfig: any;
    setShowUpdateBanner: (value: boolean) => void;
    setShowError: (value: boolean) => void
}


export const UpdateSentinelConfig = (props: UpdateSentinelConfigProps) => {
    const { sentinelConfig, setShowUpdateBanner, setShowError } = props
    const dispatch = useAppDispatch();
    const [disableForm, setDisableForm] = useState(true)
    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({
        id: '',
        subscriptionId: '',
        resourceGroupName: '',
        workspaceName: '',
        workspaceId: ''
    })

    const handleChangeSelectedConfig = (event: SelectChangeEvent) => {
        const result = sentinelConfig.filter((config: { id: string; }) => config.id === event.target.value)
        setDisableForm(false)
        setFormValues(result[0])
    };

    const handleFormValueChange = (value: any, key: string) => {
        let clone: any = structuredClone(formValues);
        setFormValues(clone)
        clone[key] = value
        setFormValues(clone)
    }
    const onDelete = (key: string) => {
        let clone: any = structuredClone(formValues);
        clone[key] = '';
        setFormValues(clone)
    }

    const handleUpdateClick = async () => {
        setLoading(true)
        const command: any = { ...formValues }
        const validationResponse: any = await dispatch(validateSentinelConfig(command));
        if(validationResponse.payload.data === false){
            setLoading(false)
            setShowError(true)
        }
        if(validationResponse.payload.data === true){
            const response: any = await dispatch(updateCompanySentinelConfig(command));
            if (response.payload) {
                setLoading(false)
                setShowUpdateBanner(true)
                navigator.clipboard.writeText(response.payload.data.id);
            }
        }
    }


    return (
        <>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>

            <Grid container spacing={0}>
                {/* SELECT COMPONENET */}
                <Grid item xs={6} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Choose Workspace'>Choose Workspace</InputLabel>
                        <Select
                            label='Choose Workspace'
                            size="small"
                            required
                            sx={{ width: "100%" }}
                            onChange={handleChangeSelectedConfig}
                        >
                            {sentinelConfig.map((item: { [key: string]: string; }, index: number) => {
                                return (
                                    <MenuItem key={index} value={item.id}>
                                        {item.workspaceName}
                                    </MenuItem>
                                );
                            })}

                        </Select>
                        <FormHelperText id="selectHelperText">Select workspace you want to edit</FormHelperText>

                    </FormControl>

                </Grid>
                {/* ID  */}
                <Grid item xs={6} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Configuration Id'>Configuration Id</InputLabel>
                        <OutlinedInput
                            label='Configuration Id'
                            disabled={true}
                            sx={{ display: 'flex', flexGrow: 1 }}
                            id="id"
                            size="small"
                            value={formValues['id']}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'id')
                            }
                        />
                        <FormHelperText id="idHelperText">Configuration Id cannot be changed</FormHelperText>

                    </FormControl>
                </Grid>
                {/* SUBSCRIPTION ID  */}
                <Grid item xs={6} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Subscription Id'>Subscription Id</InputLabel>
                        <OutlinedInput
                            label='Subscription Id'
                            disabled={disableForm}
                            sx={{ display: 'flex', flexGrow: 1 }}
                            id="subscriptionId"
                            size="small"
                            value={formValues['subscriptionId']}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'subscriptionId')
                            }
                            endAdornment={
                                <IconButton
                                    onClick={() => onDelete('subscriptionId')}
                                >
                                    <FontAwesomeIcon
                                        icon={faXmark}
                                        color={"#f8931c"}
                                        size="xs"
                                    />
                                </IconButton>
                            }
                        />
                        <FormHelperText id="subscriptionIdHelperText">Enter subscription Guid</FormHelperText>


                    </FormControl>
                </Grid>
                {/* RESOURCE GROUP NAME  */}
                <Grid item xs={6} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Resource Group Name'>Resource Group Name</InputLabel>
                        <OutlinedInput
                            label='Resource Group Name'
                            disabled={disableForm}
                            sx={{ display: 'flex', flexGrow: 1 }}
                            id="resourceGroupName"
                            size="small"
                            value={formValues['resourceGroupName']}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'resourceGroupName')
                            }
                            endAdornment={
                                <IconButton
                                    onClick={() => onDelete('resourceGroupName')}
                                >
                                    <FontAwesomeIcon
                                        icon={faXmark}
                                        color={"#f8931c"}
                                        size="xs"
                                    />
                                </IconButton>
                            }
                        />
                        <FormHelperText id="resourceGroupNameHelperText">Enter string value</FormHelperText>

                    </FormControl>
                </Grid>
                {/* WORKSPACE NAME  */}
                <Grid item xs={6} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Workspace Name'>Workspace Name</InputLabel>
                        <OutlinedInput
                            label='Workspace Name'
                            disabled={disableForm}
                            sx={{ display: 'flex', flexGrow: 1 }}
                            id="workspaceName"
                            size="small"
                            value={formValues['workspaceName']}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'workspaceName')
                            }
                            endAdornment={
                                <IconButton
                                    onClick={() => onDelete('workspaceName')}
                                >
                                    <FontAwesomeIcon
                                        icon={faXmark}
                                        color={"#f8931c"}
                                        size="xs"
                                    />
                                </IconButton>
                            }
                        />
                        <FormHelperText id="workspaceNameHelperText">Enter string value</FormHelperText>

                    </FormControl>
                </Grid>
                {/* WORKSPACE ID */}
                <Grid item xs={6} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Workspace Id'>Workspace Id</InputLabel>
                        <OutlinedInput
                            label='Workspace Id'
                            disabled={disableForm}
                            sx={{ display: 'flex', flexGrow: 1 }}
                            id="workspaceId"
                            size="small"
                            value={formValues['workspaceId']}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'workspaceId')
                            }
                            endAdornment={
                                <IconButton
                                    onClick={() => onDelete('workspaceId')}
                                >
                                    <FontAwesomeIcon
                                        icon={faXmark}
                                        color={"#f8931c"}
                                        size="xs"
                                    />
                                </IconButton>
                            }
                        />
                        <FormHelperText id="workspaceIdHelperText">Enter Workspace Guid</FormHelperText>

                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ mb: 2, pr: 1, display: "flex", justifyContent: 'flex-end' }}>
                    <ButtonGroup
                        onClick={handleUpdateClick}
                    >
                        <Button>
                            Update
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </>
    )
}