import * as Mui from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faListCheck } from "@fortawesome/pro-light-svg-icons";

import { StepListItem } from "./StepListItem";

interface StepsProps {
  currentStep: number;
  stepsCompleted: boolean[];
  onSubmitPlan: VoidFunction;
  creatingCMItem: boolean;
}

export const Steps: React.FC<StepsProps> = (props) => {
  const allStepsCompleted = () => {
    return props.stepsCompleted.every((element) => element === true);
  };

  return (
    <Mui.Card>
      <Mui.CardHeader
        avatar={
          <FontAwesomeIcon icon={faListCheck} color={"orange"} size="2x" />
        }
        title={
          <Mui.Typography sx={{ mr: 2 }}>
            Steps to complete your change management
          </Mui.Typography>
        }
        //TODO://subheader={`No plans or milestones created`}
        sx={{
          borderBottom: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "orange",
          p: 2,
        }}
      />
      <Mui.CardContent>
        <Mui.List sx={{ width: "100%", bgcolor: "background.paper", p: 0 }}>
          <StepListItem
            text="1"
            listItemText="The ticket that the change management item will be created for."
            listItemTitle="Select your ticket"
            isComplete={props.stepsCompleted[0]}
          />

          <StepListItem
            text="2"
            listItemText="Modify the title, description and other details for your new change management item"
            listItemTitle="Add details"
            isComplete={props.stepsCompleted[1]}
          />

          <StepListItem
            text="3"
            listItemText="Any number of plans can be created for a change management item.  For example, you may have a plan for implementation, a plan for roll back and plans for testing.  Each plan can comprise of a number of checks" //and can also require evidence such as screen shots.'
            listItemTitle="Add your plans"
            isComplete={props.stepsCompleted[2]}
          />

          <StepListItem
            text="4"
            listItemText="Control how the items progress is reported with milestones.  Again, like plans, they can have a number of checks, when all milestones are complete, the change management item is considered complete and closed.  Alternatively, specifying no milestones puts the change management item in the typical approve and sign off cycle."
            listItemTitle="Set your milestones"
            isComplete={props.stepsCompleted[3]}
          />

          {allStepsCompleted() ? (
            <Mui.ListItem sx={{ p: 0 }}>
              <Mui.ListItemAvatar>
                <Mui.Avatar
                  sx={{
                    backgroundColor: "limegreen",
                    borderStyle: "solid",
                    borderWidth: "3px",
                    borderColor: "limegreen",
                    color: "white",
                  }}
                >
                  5
                </Mui.Avatar>
              </Mui.ListItemAvatar>
              <Mui.ListItemText
                primary={

                    <Mui.Button
                      variant="contained"
                      onClick={() => {
                        props.onSubmitPlan();
                      }}
                    >
                      Submit your plan for approval
                    {props.creatingCMItem && (
                      <Mui.CircularProgress
                      size={24}
                      sx={{
                        color: '#32cd32',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                      />
                      )}
                      </Mui.Button>
                }
              />
            </Mui.ListItem>
          ) : (
            <></>
          )}
        </Mui.List>
      </Mui.CardContent>
    </Mui.Card>
  );
};
