import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography, } from "@mui/material"
import ReportBarChart from "../Widgets/ReportBarChart";
import dayjs from "dayjs";
import { useState } from "react";
import { fetchAlertCategory } from "../../../../redux/Thunks/alerts";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getSelectedCompanyId } from "../../../../redux/Slices/account.slice";
import ReportPieChart from "../Widgets/ReportPieChart";
import { determineBarChartCategoriesAlertSource, determineBarChartDataForAlertSource, determinePieDataForAlertSource, determineRowDataForAlertSource, onHandleTimeIntervalChange } from "./AlertTabsUtils";
import { useAsyncEffect } from "../../../../common/hooks";
import ReportsTable from "../Widgets/ReportsTable";
interface AlertSourceProps {
    alertOverride: any;
}



export const AlertSource = (props: AlertSourceProps) => {
    const { alertOverride } = props
    const dispatch = useAppDispatch();
    const [timeInterval, setTimeInterval] = useState('Month')
    const [timeDifference, setTimeDifference] = useState<any>(dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    const selectedCompanyId: string | any = useAppSelector(getSelectedCompanyId);
    const now = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const dropDownValues = ['Day', 'Week', 'Month', '2 Months', '3 Months', '4 Months', '5 Months', '6 Months', '7 Months', '8 Months', '9 Months', '10 Months', '11 Months', 'Year', 'All Time']
    const [barChartCategories, setBarChartCategories] = useState([])
    const [barChartData, setBarChartData] = useState([])
    const [pieData, setPieData] = useState([])
    const [rowData, setRowData] = useState([])
    const tableHeaders = ['Source', '# Incidents', '% of Total']
 
    const determineOverridesByTime = () => {
        const filteredByTime = alertOverride.filter((alert: any) => dayjs(alert.timeAdded).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") > timeDifference)
        return filteredByTime
    }

    useAsyncEffect(async () => {
        const response = await dispatch(fetchAlertCategory({
            companyId: selectedCompanyId,
            startDate: timeDifference,
            endDate: now
        }));
        const overridesByTime = determineOverridesByTime()
        setBarChartCategories(determineBarChartCategoriesAlertSource(response.payload, overridesByTime))
        setBarChartData(determineBarChartDataForAlertSource(response.payload, overridesByTime))
        setPieData(determinePieDataForAlertSource(response.payload, overridesByTime))
        setRowData(determineRowDataForAlertSource(response.payload, overridesByTime))
    }, [timeDifference]);

    return (
        <Card id='exportAlertSource' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Incident Source
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-start' }}>
                        <FormControl sx={{ fontWeight: 'bold', width: '25%', pl: 1 }} variant="outlined" size='small'>
                            <InputLabel id="timeSelect">Time Interval</InputLabel>
                            <Select
                                labelId="timeSelect"
                                id="timeSelect"
                                value={timeInterval}
                                label="Time Interval"
                                size='small'
                                onChange={(event) => {
                                    setTimeInterval(event.target.value as string)
                                    setTimeDifference(onHandleTimeIntervalChange(event))
                                }}

                            >
                                {dropDownValues.map((value: string) => {
                                    return (
                                        <MenuItem value={value}>{value}</MenuItem>

                                    )
                                })}

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                      
                    </Grid>


                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'alerts'}
                        />

                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportPieChart
                            pieData={pieData}
                        />
                    </Grid>

                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Alert Source Data'}
                        />
                    </Grid>

                </Grid>

            </CardContent>

        </Card >
    )
}