import React from "react";
import * as Mui from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import largeLogo from "../images/LogoLarge.svg";

interface ErrorBannerProps {
  headerText: string;
  subText: React.ReactNode;
}

export const ErrorBanner: React.FC<ErrorBannerProps> = (props) => {
  return (
    <Mui.Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="80vh"
    >
      <Mui.Stack
        direction="column"
        sx={{ width: { xs: "100%", md: "30%" } }}
        alignItems="center"
      >
        <Mui.Box
          component="img"
          sx={{
            content: { xs: `url(${largeLogo})` },
            maxWidth: { xs: "350px" },
            minHeight: "80%",
          }}
          alt="SecQube"
        />
        <FontAwesomeIcon icon={faTriangleExclamation} color={"red"} size="5x" />
        <Mui.Typography sx={{ pt: 1 }} variant="h4">
          {props.headerText}
        </Mui.Typography>
        <Mui.Typography sx={{ p: 2 }}>{props.subText}</Mui.Typography>
      </Mui.Stack>
    </Mui.Box>
  );
};
