import { Card, CardContent, Grid, Typography } from "@mui/material"
import ReportBarChart from "../Widgets/ReportBarChart";
import ReportPieChart from "../Widgets/ReportPieChart";
import ReportsTable from "../Widgets/ReportsTable";
interface DeviceSyncedFromProps {
    deviceSyncedFrom: any;
}

export const DeviceSyncedFrom = (props: DeviceSyncedFromProps) => {
    const { deviceSyncedFrom } = props
    const barChartCategories = ['Sentinel Synced Devices']
  //  const barChartCategories = ['Scom Synced Devices', 'Scom Agent Managed Devices', 'Sentinel Synced Devices', 'Endpoint Synced Devices']
    const barChartData = [deviceSyncedFrom.isScomSyncedDeviceCount, deviceSyncedFrom.isScomAgentManagedDeviceCount, deviceSyncedFrom.isSentinelSyncedDeviceCount, deviceSyncedFrom.isEndpointSyncedDeviceCount]
    const pieData = [
       // { name: 'Scom Synced', y: deviceSyncedFrom.isScomSyncedDeviceCount },
       // { name: 'Scom Agent Managed', y: deviceSyncedFrom.isScomAgentManagedDeviceCount },
        { name: 'Sentinel Synced', y: deviceSyncedFrom.isSentinelSyncedDeviceCount },
       // { name: 'Endpoint Synced', y: deviceSyncedFrom.isEndpointSyncedDeviceCount }
    ]
    const rowData = [
      //  { value: 'Scom Synced', count: deviceSyncedFrom.isScomSyncedDeviceCount, percentage: ((100 * deviceSyncedFrom.isScomSyncedDeviceCount) / deviceSyncedFrom.totalDeviceCount).toFixed(2) },
      //  { value: 'Scom Agent Managed', count: deviceSyncedFrom.isScomAgentManagedDeviceCount, percentage: ((100 * deviceSyncedFrom.isScomAgentManagedDeviceCount) / deviceSyncedFrom.totalDeviceCount).toFixed(2) },
        { value: 'Sentinel Synced', count: deviceSyncedFrom.isSentinelSyncedDeviceCount, percentage: ((100 * deviceSyncedFrom.isSentinelSyncedDeviceCount) / deviceSyncedFrom.totalDeviceCount).toFixed(2) },
     //   { value: 'Endpoint Synced', count: deviceSyncedFrom.isEndpointSyncedDeviceCount, percentage: ((100 * deviceSyncedFrom.isEndpointSyncedDeviceCount) / deviceSyncedFrom.totalDeviceCount).toFixed(2) },

    ]
    const tableHeaders = ['Device Synced From', '# Devices', '% of Total']

    return (
        <Card id='exportDeviceSyncedFrom' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ maxHeight: '65vh', padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Devices Synced From
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                
                    </Grid>


                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'devices'}
                        />

                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportPieChart
                            pieData={pieData}
                        />
                    </Grid>

                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Device Synced From Data'}
                        />
                    </Grid>

                </Grid>

            </CardContent>

        </Card >
    )
}