import React from "react";
import * as Mui from "@mui/material";
import { useTheme } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faHeart, faCalendar } from "@fortawesome/pro-light-svg-icons";

import { IStringIndexable } from "../../common/contracts";
import { IProtectOrgThemeHealthState } from "../../themes/ProtectOrgTheme";
import { FormattedDate } from "../../common/dateUtils";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchLastModifiedDevices } from "../../redux/Thunks/devices";
import { useAsyncEffect } from "../../common/hooks";
import { getLastModifiedDevices } from "../../redux/Slices/devices.slice";
import { Loader } from "../../components/Loader";

const DeviceActivityListItem: React.FC<{
  primaryText: string;
  secondaryText: string;
  health: string;
  eventType: number;
}> = (props) => {
  var theme = useTheme();
  let bg = (
    theme.healthStates as IStringIndexable<IProtectOrgThemeHealthState>
  )[props.health].backgroundColor;

  return (
    <Mui.ListItem sx={{ pt: 0, pb: 0, pl: 0 }}>
      <Mui.ListItemAvatar
        sx={{ minWidth: "36px", padding: "3px", mr: 1, borderRadius: "5px" }}
      >
        <FontAwesomeIcon icon={faHeart} color={bg} size="lg" />
      </Mui.ListItemAvatar>
      <Mui.ListItemText
        sx={{ p: 0 }}
        primary={
          <>
            <FontAwesomeIcon icon={faCalendar} color="#ccc" size="sm" />
            &nbsp;
            {props.secondaryText} - {props.primaryText}
          </>
        }
      />
    </Mui.ListItem>
  );
};

export const DevicesRecentEvents: React.FC = () => {
  const dispatch = useAppDispatch();
  const lastModifiedDevices = useAppSelector(getLastModifiedDevices);

  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchLastModifiedDevices(6));
  }, []);

  return (
    <Mui.Card sx={{ border: "solid 1px #ddd", height: "100%" }}>
      <Mui.Grid
        item
        xs={12}
        container
        sx={{ pt: 1, pl: 1, pb: 0, height: "100%" }}
      >
        <Mui.Grid item xs={12}>
          <Mui.Stack direction="column" sx={{ pr: 1 }}>
            <Mui.Typography variant="body1">
              <FontAwesomeIcon icon={faList} color="orange" size="sm" />
              &nbsp; Most Recent Devices Updated
            </Mui.Typography>
            {!loading ? (
              <Mui.List
                dense={true}
                sx={{ width: "100%", bgcolor: "background.paper" }}
              >
                {lastModifiedDevices.map((device, index) => (
                  <DeviceActivityListItem
                    key={`device${index}`}
                    primaryText={`${device.deviceName}`}
                    secondaryText={FormattedDate(device.createdDate)}
                    eventType={1}
                    health={device.healthStateDescription.toLowerCase()}
                  />
                ))}
              </Mui.List>
            ) : (
              <Loader show={true} />
            )}
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Card>
  );
};
