import { RunAsAccounts } from "../../types/devices.types";

interface HeadCell {
    disablePadding: boolean;
    id: keyof RunAsAccounts;
    label: string;
    numeric: boolean;
}

export const headCells: readonly HeadCell[] = [

    {
        id: "runAsAccountId",
        numeric: true,
        disablePadding: true,
        label: "Account Id",
    },
    {
        id: "userName",
        numeric: true,
        disablePadding: true,
        label: "User Name",
    },
    {
        id: "domain",
        numeric: true,
        disablePadding: true,
        label: "Domain",
    },
    // {
    //     id: "runAsAccountTypeId",
    //     numeric: true,
    //     disablePadding: true,
    //     label: "Run-As Account Type Id",
    // },
    // {
    //     id: "deviceGroup",
    //     numeric: true,
    //     disablePadding: true,
    //     label: "Device Group",
    // },
    {
        id: "created",
        numeric: true,
        disablePadding: true,
        label: "Created Date",
    },
    {
        id: "description",
        numeric: true,
        disablePadding: true,
        label: "Profile",
    },
    {
        id: "runAsAccountId",
        numeric: true,
        disablePadding: true,
        label: "Edit",
    },
    {
        id: "runAsAccountId",
        numeric: true,
        disablePadding: true,
        label: "Delete",
    }


];
