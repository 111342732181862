import React from "react";

import * as Mui from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareInfo,
  faCirclePlus,
  faCircleMinus,
} from "@fortawesome/pro-solid-svg-icons";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface CardHeaderProps {
  icon: IconDefinition;
  title: string;
  subText: React.ReactNode | string;
  onExpand: VoidFunction;
  onCollapse: VoidFunction;
  isExpanded: boolean;
  isHelpExpanded: boolean;
  actions?: React.ReactNode;
  menu?: React.ReactNode;
  onHelpExpand: VoidFunction;
  onHelpCollapse: VoidFunction;
  showHelp: boolean;
}

const CardHeader: React.FC<CardHeaderProps> = (props) => {
  return (
    <Mui.CardHeader
      avatar={<FontAwesomeIcon icon={props.icon} color={"orange"} size="lg" />}
      subheader={
        <Mui.Typography variant="subtitle2" color="silver">
          {props.subText}
        </Mui.Typography>
      }
      title={props.title}
      sx={{
        borderBottom: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "orange",
        p: 1,
      }}
      action={
        <Mui.Box sx={{ pt: 1, pr: 1 }}>
          {props.actions ? props.actions : <></>}

          {props.showHelp ? (
            <Mui.IconButton aria-label="settings" sx={{ pr: 0 }}>
              <FontAwesomeIcon
                icon={faSquareInfo}
                color={props.isHelpExpanded ? "orange" : "silver"}
                size="sm"
                onClick={() => {
                  props.isHelpExpanded
                    ? props.onHelpCollapse()
                    : props.onHelpExpand();
                }}
              />
            </Mui.IconButton>
          ) : (
            <></>
          )}

          <Mui.IconButton aria-label="settings">
            {props.isExpanded ? (
              <FontAwesomeIcon
                icon={faCircleMinus}
                color={"orange"}
                size="sm"
                onClick={() => props.onCollapse()}
              />
            ) : (
              <FontAwesomeIcon
                icon={faCirclePlus}
                color={"orange"}
                size="sm"
                onClick={() => props.onExpand()}
              />
            )}
          </Mui.IconButton>

          {/* TODO: <Mui.IconButton aria-label="settings">
                        <FontAwesomeIcon icon={faEllipsisV} color={'palette.primary'} size="sm"  /> 
                    </Mui.IconButton>  */}
        </Mui.Box>
      }
    />
  );
};

interface CardProp extends Mui.CardProps {
  id: string;
  actions?: React.ReactNode;
  headerIcon: IconDefinition;
  headerTitle: string;
  headerSubText: React.ReactNode | string;
  cardContent: React.ReactNode;
  cardHeaderActions?: React.ReactNode;
  headerMenu?: React.ReactNode;
  helpHeaderText?: string;
  helpHeaderDescription?: string;
  showHelp: boolean;
}

//TODO: use theme : color={theme.palette.primary.main}

export const Card: React.FC<CardProp> = (props) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(true);
  const [isHelpExpanded, setIsHelpExpanded] = React.useState<boolean>(true);

  React.useEffect(() => {
    var cardPrefs = localStorage.getItem(`cardprefs_${props.id}`);
    if (cardPrefs !== null) setIsExpanded(cardPrefs === "true");

    var cardPrefsHelp = localStorage.getItem(`cardprefshelp_${props.id}`);
    if (cardPrefsHelp !== null) setIsHelpExpanded(cardPrefsHelp === "true");
  }, [props.id]);

  const handleCollapse = () => {
    localStorage.setItem(`cardprefs_${props.id}`, "false");
    setIsExpanded(false);
  };

  const handleExpand = () => {
    localStorage.setItem(`cardprefs_${props.id}`, "true");
    setIsExpanded(true);
  };

  const handleHelpCollapse = () => {
    localStorage.setItem(`cardprefshelp_${props.id}`, "false");
    setIsHelpExpanded(false);
  };

  const handleHelpExpand = () => {
    localStorage.setItem(`cardprefshelp_${props.id}`, "true");
    setIsHelpExpanded(true);
  };

  return (
    <Mui.Card {...props}>
      <CardHeader
        icon={props.headerIcon}
        title={props.headerTitle}
        subText={props.headerSubText}
        isExpanded={isExpanded}
        onCollapse={handleCollapse}
        onExpand={handleExpand}
        actions={props.cardHeaderActions}
        isHelpExpanded={isHelpExpanded}
        onHelpCollapse={handleHelpCollapse}
        onHelpExpand={handleHelpExpand}
        showHelp={props.showHelp}
      />
      {isExpanded ? (
        <>
          <Mui.CardContent>
            {props.showHelp ? (
              <Mui.Box
                sx={{
                  width: "100%",
                  pb: 2,
                  display: isHelpExpanded ? "inline-block" : "none",
                }}
              >
                <Mui.Typography variant="body1" fontWeight="bold">
                  <FontAwesomeIcon
                    icon={faSquareInfo}
                    color={"orange"}
                    size="lg"
                  />
                  &nbsp;{props.helpHeaderText}
                </Mui.Typography>
                <Mui.Typography>{props.helpHeaderDescription}</Mui.Typography>
              </Mui.Box>
            ) : (
              <></>
            )}
            {props.cardContent}
          </Mui.CardContent>
          <Mui.CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
            {props.actions}
          </Mui.CardActions>
        </>
      ) : (
        <></>
      )}
    </Mui.Card>
  );
};
