import { faBellSlash, faChartColumn } from "@fortawesome/pro-light-svg-icons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect, useState } from "react";
import { setLayout } from "../../redux/Slices/layout.slice";
import { Card, Grid } from "@mui/material";
import { InvestigateFirewallTableSummary } from "./InvestigateFirewallTableSummary";
import InvestigateFirewallEnhancedTable from "./InvestigateFirewallEnhancedTable";
import { useAsyncEffect } from "../../common/hooks";
import { investigateCommonSecurityLog } from "../../redux/Thunks/alerts";
import { CommonSecurityLog } from "../../types/alerts.types";
import { Loader } from "../../components/Loader";
import { getFirewallResponse } from "../../redux/Slices/alerts.slice";
import { NoData } from "../../components/NoData";
import { getSelectedCompanyId } from "../../redux/Slices/account.slice";

export const InvestigateFirewallDashboardPage = () => {
  const dispatch = useAppDispatch();
  const selectedCompanyId = useAppSelector(getSelectedCompanyId);

  const [searchQueryParams, setSearchQueryParams] = useState<any>({
    sourcePort: 0,
    sourceIP: '',
    requestURL: '',
    deviceAction: '',
    destinationPort: '',
    destinationIP: '',
    applicationProtocol: '',
  })
  const firewallResponse: any = useAppSelector(getFirewallResponse);

  useEffect(() => {
    dispatch(
      setLayout({
        title: "Investigate Firewall Dashboard",
        subtitle: "Investigate Firewall Dashboard",
        icon: faChartColumn,
      })
    );
  }, [dispatch]);
  const { loading } = useAsyncEffect(async () => {
    await dispatch(investigateCommonSecurityLog({ companyId: selectedCompanyId } as CommonSecurityLog));

  }, [selectedCompanyId]);

  const handleSearchParams = (value: any, key: string) => {
    let array = []
    array.push(key, value)
    setSearchQueryParams(array)
  }
  return (
    <>
      {loading ? (
        <Loader show={true} />
      ) : !firewallResponse ? (
        <NoData
          icon={faBellSlash}
          headerText="No active results found"
          subText="We couldn`t find any results"
        />
      ) : (
        <>
          <InvestigateFirewallTableSummary
            searchQueryParams={searchQueryParams}

          />
          <Grid item xs={12} sx={{ height: "auto", marginTop: 2 }}>
            <Card sx={{ border: "solid 1px #ddd", height: "auto" }}>
              {
                <InvestigateFirewallEnhancedTable
                  firewallResponse={firewallResponse}
                  handleSearchParams={handleSearchParams}
                />
              }
            </Card>
          </Grid>
        </>
      )}
    </>
  );
};
