import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import * as Mui from "@mui/material";

import { faLaptop } from "@fortawesome/pro-light-svg-icons";

import { DeviceSummary, DeviceSummaryLoading } from "./DeviceSummary";
import { RaiseSupportTicket } from "./RaiseSupportTicket";

import { AllDeviceAlerts } from "./AllDeviceAlerts";
import { DeviceSupportTicketChat } from "./DeviceSupportTicketChat";
import { NotifiyBannerWithDuration } from "../../components/NotifyBannerWithDuration";
import { Loader } from "../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getDevices } from "../../redux/Slices/devices.slice";
import { useAsyncEffect } from "../../common/hooks";
import { fetchDevices } from "../../redux/Thunks/devices";
import { getAlerts } from "../../redux/Slices/alerts.slice";
import { setLayout } from "../../redux/Slices/layout.slice";
import { fetchActiveAlerts } from "../../redux/Thunks/alerts";

export const ViewDevicePage = () => {
  const { id, group } = useParams<string>();
  const dispatch = useAppDispatch();
  const devices = useAppSelector(getDevices);
  const allAlerts = useAppSelector(getAlerts);
  const device = devices.find(
    (device) => device.deviceGroup === group && device.id === id
  );
  const alerts = allAlerts.filter((alert) => alert.deviceId === id);

  const [showRaisedNotification, setshowRaisedNotification] =
    useState<boolean>(false);

  useEffect(() => {
    dispatch(
      setLayout({
        title: "View Device",
        subtitle: "View Device",
        icon: faLaptop,
        links: [{ href: "/devices", text: "Devices Dashboard" }],
      })
    );
  }, [dispatch]);

  const { loading } = useAsyncEffect(async () => {
    await Promise.all([
      dispatch(fetchDevices()),
      dispatch(fetchActiveAlerts()),
    ]);
  }, []);
  const onRaiseTicketSuccess = (ticketId: string) => {
    if (device) {
      dispatch(fetchDevices());
      setshowRaisedNotification(true);
    }
  };

  const handleHideBanner = () => {
    setshowRaisedNotification(false);
  };

  return (
    <>
      {loading ? (
        <Loader show={true}></Loader>
      ) : (
        <>
          {showRaisedNotification === true ? (
            <NotifiyBannerWithDuration
              secondsDuration={10}
              onHideBanner={handleHideBanner}
              title="New Support Ticket Raised"
              subText="A new ticket was raised against this device."
              color="green"
              buttons={[
                {
                  text: "View ticket",
                  url: `/ticket/${device?.ticketId}`,
                },
              ]}
            />
          ) : (
            <></>
          )}

          <Mui.Grid container spacing={1} direction="row">
            <Mui.Grid item xs={4}>
              {!loading ? (
                <AllDeviceAlerts alerts={alerts} device={device} />
              ) : (
                <DeviceSummaryLoading />
              )}
            </Mui.Grid>

            <Mui.Grid item xs={4}>
              <DeviceSummary device={device} />
            </Mui.Grid>

            <Mui.Grid item xs={4}>
              <Mui.Stack
                direction="column"
                sx={{ pt: 0 }}
                style={{ height: "100%" }}
              >
                {device && device?.ticketId === null ? (
                  <RaiseSupportTicket
                    onSupportTicketCreated={onRaiseTicketSuccess}
                    device={device}
                  />
                ) : (
                  <></>
                )}
                {device && device.ticketId !== null ? (
                  <DeviceSupportTicketChat device={device} />
                ) : (
                  <></>
                )}
              </Mui.Stack>
            </Mui.Grid>
          </Mui.Grid>
        </>
      )}
    </>
  );
};
