
import { Modal, Card, Snackbar, Alert, CardContent, Stack, Typography, CardActions, FormGroup, Box, Button, Menu, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faCaretDown } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FormattedDateTime } from "../../common/dateUtils";
import { CardHeader } from "../../components/CardHeader";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { raiseSupportTicketForAlert } from "../../redux/Thunks/tickets";
import { RaiseTicketCommand } from "../../types/tickets.types";
import { StartEnableOverrideCommand } from "../../types/alerts.types";
import {
  enableAlertOverride,
  fetchFlareAlert,
  fetchTaskStatus,
  startTaskEnableAlertOverride,
} from "../../redux/Thunks/alerts";
import { getUserById } from "../../redux/Slices/account.slice";
import { useEffect, useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useIdentityContext } from "../../context/IdentityContext";
import { HarveyModal } from "../../components/HarveyChatModal/HarveyModal";
import { Loader } from "../../components/Loader";
import { setFlareAlertToSearch } from "../../redux/Slices/alerts.slice";

interface AlertDetailsProps {
  alert?: any;
  onAlertTicketRaisedSuccess: (ticketId: string) => void;
  page: string;
}
export const AlertDetails: React.FC<AlertDetailsProps> = (props) => {
  const { alert } = props;
  const dispatch = useAppDispatch();
  var navigate = useNavigate();
  const identity = useIdentityContext();
  const [taskStatus, setTaskStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => { setAnchorEl(null) };
  const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
  const rolesArray = user?.roles.split(",");
  const isInvestigateUser = rolesArray?.includes("Investigate User");
  const [openHarvey, setOpenHarvey] = useState(false);
  const [harveyAlert, setHarveyAlert] = useState({});
  const closeHarvey = () => setOpenHarvey(false);
  const isAlert = alert?.categoryDescription !== "Sentinel Incident";
  useEffect(() => {
    if (taskStatus === "Accepted") {
      setOpen(true);
    }
  }, [taskStatus]);
  if (!alert) return <></>;
  const raiseAlertTicket = async () => {
    setLoading(true)
    var raiseCommand: RaiseTicketCommand = {
      description: alert.description,
      priority: alert.priority,
      problemCategory: "Incident",
      subjectGroup: alert.deviceGroup,
      subjectId: alert.id,
      subjectName: alert.description,
      title: `Incident ticket for ${alert.description}`,
    };
    const ticketResp = await dispatch(raiseSupportTicketForAlert(raiseCommand));
    props.onAlertTicketRaisedSuccess(ticketResp.payload.id);
    if (ticketResp.payload) {
      setLoading(false)
    }
    //TODO:
    // .catch((exception : any) => {
    //   props.onSupportTicketCreationFailure(exception);
    // });
  };
  const onDisableAlert = async () => {
    // FIRST RUN TASK OVERRIDE
    var enableOverrideCommand: StartEnableOverrideCommand = {
      alertId: alert.id,
      deviceGroup: alert.deviceGroup,
      comments: comment,
    };
    const startOverrideResp = await dispatch(
      startTaskEnableAlertOverride(enableOverrideCommand)
    );
    // IF PAYLOAD EXISTS
    if (startOverrideResp.payload) {
      // CHECK TASK ID TO SEE IF ACCEPTED
      const task = await dispatch(
        fetchTaskStatus(startOverrideResp.payload.taskId)
      );
      setTaskStatus(task.payload.state);
      // RUN REAL OVERRIDE  COMMAND
      await dispatch(enableAlertOverride({ command: enableOverrideCommand }));
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };



  const onClickThreatExposure = async () => {
    setLoading(true)
    dispatch(setFlareAlertToSearch(alert.description))
    const command = `${alert.id}`
    const response = await dispatch(fetchFlareAlert(command));
    if (response.payload) {
      setLoading(false)
    }
    navigate('/darkweb')
  }

  return (
    <>
      <Modal open={loading}>
        <Loader show={true} />
      </Modal>
      <HarveyModal
        openHarvey={openHarvey}
        closeHarvey={closeHarvey}
        topic={harveyAlert}
        type={'alert'}
      />
      <ConfirmationDialog
        title="Are you sure you want to disable this alert?"
        content={` Alert: ${alert.description}`}
        open={isDialogOpen}
        onConfirm={() => {
          onDisableAlert();
          setDialogOpen(false);
        }}
        onCancel={() => {
          setDialogOpen(false);
        }}
      />
      <Card sx={{ border: "solid 1px #ddd", width: "100%" }}>
        <CardHeader
          icon={faBell}
          subText={`Created on ${FormattedDateTime(
            alert.createdDate
          )} and last modified on 
            ${FormattedDateTime(alert.lastModifiedDate)}`}
          title={`Currently Viewing Incident : ${alert.description}`}
        />
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Alert has been disabled. It will take 30 seconds to reflect in the
            portal.
          </Alert>
        </Snackbar>
        <CardContent style={{ overflowX: "scroll" }}>
          <Stack direction="column" spacing={1}>
            <Typography variant="body1" fontWeight="bold">
              Device Name :{" "}
              <span style={{ fontWeight: "normal" }}>{alert.deviceName}</span>
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
              Category :{" "}
              <span style={{ fontWeight: "normal" }}>
                {alert.categoryDescription}
              </span>
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
              Priority :{" "}
              <FontAwesomeIcon
                icon={faCircle}
                color={
                  alert.priority === 2
                    ? "red"
                    : alert.priority === 1
                      ? "orange"
                      : alert.priority === 0
                        ? "grey"
                        : "white"
                }
                size="sm"
              />
              &nbsp;
              <span style={{ fontWeight: "normal" }}>
                {alert.priorityDescription}
              </span>
            </Typography>
            <Typography variant="body1" sx={{ mt: 1 }} fontWeight="bold">
              Full Description
            </Typography>
            {alert.fullDescription !== "" ? (
              <Typography sx={{ whiteSpace: "pre-line" }}>
                {alert.fullDescription}
              </Typography>
            ) : (
              <Typography sx={{ pl: 1, pt: 1 }}>
                Sorry, there is no alert information available for this ticket.
              </Typography>
            )}
          </Stack>
        </CardContent>
        <CardActions
          sx={{
            justifyContent: "flex-end",
            pr: 2,
            borderTopStyle: "solid",
            borderTopWidth: "1px",
            borderTopColor: "#ddd",
          }}
        >
          <FormGroup row>
            <Box sx={{ m: 1, position: 'relative' }}>

              {isAlert ? (
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={taskStatus === "Accepted"}
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setDialogOpen(true);
                    setComment("");
                  }}
                >
                  Disable Alert
                </Button>
              ) : (
                <></>
              )}
            </Box>

            

              <Button
                variant="outlined"
                color="primary"
                type="submit"
                onClick={() => {
                  setOpenHarvey(!openHarvey);
                  setHarveyAlert(alert);
                }}
                sx={{mr: 1}}
              >
                Ask Harvey
              </Button>
              <Button
                id="basic-button"
                variant="outlined"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleMenuClick}
              >

                Actions
                <FontAwesomeIcon icon={faCaretDown} color={"orange"} size="lg" style={{ paddingLeft: '5px' }} />
              </Button>

                
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {isInvestigateUser ? (
                <MenuItem onClick={() => {
                  navigate(`/investigate/${alert.id}`);
                }}>
                  Investigate
                </MenuItem>) :
                (<></>)
              }
              {/* <MenuItem onClick={() => {
                setOpenHarvey(!openHarvey);
                setHarveyAlert(alert);
              }}>
                Ask Harvey
              </MenuItem> */}
              <MenuItem onClick={onClickThreatExposure}>
                Threat Exposure
              </MenuItem>
              {alert.ticketId === null ? (
                <MenuItem onClick={raiseAlertTicket}>
                  Raise Ticket
                </MenuItem>
              ) : props.page !== "ticket" ? (
                <MenuItem onClick={() => {
                  navigate(`/ticket/${alert.ticketId}`);
                }}>
                  View Ticket
                </MenuItem>
              ) : (
                <></>
              )}
            </Menu>

          </FormGroup>
        </CardActions>
      </Card>
    </>
  );
};
