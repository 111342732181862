import { RunAsAccounts, RunAsProfileAndDevices, RunAsProfiles } from "../../types/devices.types";

export const filterRunAsProfiles = (runAsProfiles: RunAsProfiles[]) => {
    let filteredList: RunAsProfiles[] = []
    runAsProfiles.map((profile: RunAsProfiles) => {
        if (profile.displayName !== 'JEE Invoke Account' && 
        profile.displayName !== 'JEE Monitoring Account' &&
        profile.displayName !== 'SNMPv3 Monitoring Account' &&
        profile.displayName !== 'SNMP Monitoring Account'
        ) {
            filteredList.push(profile)
        }
    })

    const foundIndexOne = filteredList.findIndex(x => x.displayName === 'UNIX/Linux Agent Maintenance Account');
    const foundIndexTwo = filteredList.findIndex(x => x.displayName === 'UNIX/Linux Privileged Account');
    const foundIndexThree = filteredList.findIndex(x => x.displayName === 'Windows Cluster Action Account');

    const changedIndex = [foundIndexOne, foundIndexTwo, foundIndexThree]

    changedIndex.map((changedIndex) => {
        return (
            filteredList[changedIndex] = {
                description: filteredList[changedIndex].description, 
                displayName: filteredList[changedIndex].displayName + ' - Gateway Only', 
                runAsProfilesId: filteredList[changedIndex].runAsProfilesId, 
            }
        )
    })
    filteredList.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0))
    return filteredList
}

export const addProfilesToAccounts = (runAsAccounts: RunAsAccounts[], profileAndDevices: RunAsProfileAndDevices[]) => {
    const result = Object.values([...runAsAccounts, ...profileAndDevices].reduce((a: any, v: any) => {
        if (a[v.runAsAccountId]) a[v.runAsAccountId].runAsProfileDisplayName = v.runAsProfileDisplayName
        else a[v.runAsAccountId] = { ...v }
        return a;
      }, {}));
      return result
}