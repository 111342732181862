//@ts-nocheck
import React from "react";
import * as Mui from "@mui/material";

import { faTicket } from "@fortawesome/pro-light-svg-icons";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { CardHeader } from "../../components/CardHeader";
import { RaiseTicketCommand } from "../../types/tickets.types";
import {
  TicketPriorities,
  TicketProblemTypes,
} from "../../redux/Slices/tickets.slice";
import { useAppDispatch } from "../../redux/hooks";
import { raiseSupportTicketForDevice } from "../../redux/Thunks/tickets";
import { Device } from "../../types/devices.types";

interface RaiseSupportTicketProps {
  device: Device;
  onSupportTicketCreated: (ticketId: string) => void;
  //onSupportTicketCreationFailure : (exception : any) => void
}

const RaiseSupportTicket: React.FC<RaiseSupportTicketProps> = (props) => {
  const { control, register, handleSubmit } = useForm<RaiseTicketCommand>();
  const dispatch = useAppDispatch();
  const onSubmit: SubmitHandler<RaiseTicketCommand> = async (data) => {
    //radio buttons always returning strings so convert to number
    data.priority = +data.priority;
    data.subjectName = props.device.deviceName;
    data.subjectId = props.device.id;
    data.subjectGroup = props.device.deviceGroup;
    data.problemCategory =
      TicketProblemTypes[+data.problemCategory].description;

    const ticketResponse = await dispatch(raiseSupportTicketForDevice(data));

    props.onSupportTicketCreated(ticketResponse.payload.id);
    //TODO:
    // .catch((exception : any) => {
    //   props.onSupportTicketCreationFailure(exception);
    // });
  };

  return (
    <Mui.Card style={{ height: "100%" }}>
      <CardHeader
        icon={faTicket}
        subText="There is currently NO support tickets against this device."
        title="Raise a new support ticket against this device"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Mui.CardContent>
          <Mui.TextField
            inputProps={register("title")}
            required
            id="outlined-required"
            label="Ticket Title"
            sx={{ width: "50%", mr: 1 }}
            size="small"
            helperText="Please supply a brief title for the ticket"
          />

          <Controller
            name="problemCategory"
            control={control}
            render={({ field }) => (
              <>
                <Mui.Typography variant="subtitle2" sx={{ mt: 2 }}>
                  Type of the problem experienced
                </Mui.Typography>
                <Mui.Select
                  {...field}
                  size="small"
                  required
                  sx={{ width: "50%", mb: 1 }}
                >
                  {TicketProblemTypes.map((item, index) => {
                    return (
                      <Mui.MenuItem key={index} value={item.id}>
                        {item.description}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
              </>
            )}
          />

          <Mui.TextField
            id="outlined-multiline-flexible"
            label="Description"
            inputProps={register("description")}
            multiline
            maxRows={4}
            fullWidth
            size="small"
            rows={4}
            required
            helperText="Please supply a full description (max 2000 chars) fasdfasdf"
            sx={{ mt: 2 }}
          />

          <Controller
            name="priority"
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <Mui.RadioGroup
                {...field}
                aria-label="residence"
                id="residence-type-radio"
                name="radio-buttons-group"
                row
                sx={{ pt: 2 }}
              >
                {TicketPriorities.map((item, index) => {
                  return (
                    <Mui.FormControlLabel
                      control={<Mui.Radio key={item.id} value={item.id} />}
                      label={`${item.description} Priority`}
                    />
                  );
                })}
              </Mui.RadioGroup>
            )}
          />
        </Mui.CardContent>
        <Mui.CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
          <Mui.FormGroup row>
            <Mui.Button variant="outlined" color="secondary" sx={{ mr: 1 }}>
              Clear
            </Mui.Button>
            <Mui.Button variant="outlined" color="primary" type="submit">
              Raise Ticket
            </Mui.Button>
          </Mui.FormGroup>
        </Mui.CardActions>
      </form>
    </Mui.Card>
  );
};

export { RaiseSupportTicket };
