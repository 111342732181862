import { createAsyncThunk } from "@reduxjs/toolkit";
import protectAxios from "../../api/axios";
import {
  setAccountTypes,
  setDeviceCategory,
  setDeviceHealthStates,
  setDeviceOSVersion,
  setDevices,
  setDeviceSyncedFrom,
  setDeviceTotals,
  setDeviceTypes,
  setLastModifiedDevices,
  setRunAsAccounts,
  setRunAsProfileAndDevices,
  setRunAsProfiles,
} from "../Slices/devices.slice";
import { ChartDataCommand, DeleteRunAsAccount, RaiseRunAsAccountCommand, RaiseRunAsProfilesAndDevicesCommand } from "../../types/devices.types";

export const fetchDevices = createAsyncThunk(
  "devices/fetchDevices",
  async (_, { dispatch }) => {
    const devices = await protectAxios
      .get("/devicequeries")
      .then((resp) => resp.data);
    dispatch(setDevices(devices));
    return devices;
  }
);

export const fetchDeviceTotals = createAsyncThunk(
  "devices/fetchDeviceTotals",
  async (_, { dispatch }) => {
    const deviceTotals = await protectAxios
      .get("/devicequeries/totals")
      .then((resp) => resp.data);
    dispatch(setDeviceTotals(deviceTotals));
    return deviceTotals;
  }
);

export const fetchDeviceTypes = createAsyncThunk(
  "devices/fetchDeviceTypes",
  async (_, { dispatch }) => {
    const deviceTypes = await protectAxios
      .get("/devicequeries/devicetypes")
      .then((resp) => resp.data);
    dispatch(setDeviceTypes(deviceTypes));
    return deviceTypes;
  }
);

export const fetchDeviceCategory = createAsyncThunk(
  "devices/fetchDeviceCategory",
  async (_, { dispatch }) => {
    const deviceCategory = await protectAxios
      .get("/devicequeries/devicecategory")
      .then((resp) => resp.data);
    dispatch(setDeviceCategory(deviceCategory));
    return deviceCategory;
  }
);

export const fetchDeviceSyncedFrom = createAsyncThunk(
  "devices/fetchDeviceSyncedFrom",
  async (_, { dispatch }) => {
    const deviceSyncedFrom = await protectAxios
      .get("/devicequeries/devicesyncedfrom")
      .then((resp) => resp.data);
    dispatch(setDeviceSyncedFrom(deviceSyncedFrom));
    return deviceSyncedFrom;
  }
);

export const fetchDeviceHealthStates = createAsyncThunk(
  "devices/fetchDeviceHealthStates",
  async (_, { dispatch }) => {
    const response = await protectAxios
      .get("/devicequeries/devicehealthstates")
      .then((resp) => resp.data);
    dispatch(setDeviceHealthStates(response));
    return response;
  }
);

export const fetchDeviceOSVersion = createAsyncThunk(
  "devices/fetchDeviceOSVersion",
  async (_, { dispatch }) => {
    const response = await protectAxios
      .get("/devicequeries/deviceosversion")
      .then((resp) => resp.data);
    dispatch(setDeviceOSVersion(response));
    return response;
  }
);

export const fetchLastModifiedDevices = createAsyncThunk(
  "devices/fetchLastModifiedDevices",
  async (numberOfDevices: number, { dispatch }) => {
    const devices = await protectAxios
      .get(`/devicequeries/lastModified/${numberOfDevices}`)
      .then((resp) => resp.data);
    dispatch(setLastModifiedDevices(devices));
    return devices;
  }
);
export const fetchDeviceById = createAsyncThunk(
  "devices/fetchDeviceById",
  async ({ id, deviceGroup }: { id: string; deviceGroup: string }) => {
    const device = await protectAxios
      .get(`/devicequeries/${id}/${deviceGroup}`)
      .then((resp) => resp.data);
    return device;
  }
);

// PERFORMANCE VALUES //

export const fetchPerformanceCategories = createAsyncThunk(
  "devices/fetchPerformanceCategories",
  async ({ id, deviceGroup }: { id: string; deviceGroup: string }) => {
    const device = await protectAxios
      .get(`/devicequeries/GetPerformanceCategories/${id}/${deviceGroup}`)
      .then((resp) => resp.data);
    return device;
  }
);

export const fetchPerformanceValuesRAW = createAsyncThunk(
  "devices/fetchPerformanceValuesRAW",
  async ({ deviceId, deviceGroup, objectName, counterName, startDate, endDate }: { deviceId: string; deviceGroup: string, objectName: string, counterName: string, startDate: string, endDate: string }) => {
    const encodedCounterName = encodeURIComponent(`${counterName}`)
    const device = await protectAxios
      .get(`/devicequeries/GetPerformanceValuesRAW/${deviceId}/${deviceGroup}/${objectName}/${encodedCounterName}/${startDate}/${endDate}`)
      .then((resp) => resp.data);
    return device;
  }
);

export const fetchPerformanceValuesDaily = createAsyncThunk(
  "devices/fetchPerformanceValuesDaily",
  async ({ deviceId, deviceGroup, objectName, counterName, startDate, endDate }: { deviceId: string; deviceGroup: string, objectName: string, counterName: string, startDate: string, endDate: string }) => {
    const encodedCounterName = encodeURIComponent(`${counterName}`)
    const device = await protectAxios
      .get(`/devicequeries/GetPerformanceValuesDaily/${deviceId}/${deviceGroup}/${objectName}/${encodedCounterName}/${startDate}/${endDate}`)
      .then((resp) => resp.data);
    return device;
  }
);

export const fetchPerformanceValuesHourly = createAsyncThunk(
  "devices/fetchPerformanceValuesHourly",
  async ({ deviceId, deviceGroup, objectName, counterName, startDate, endDate }: { deviceId: string; deviceGroup: string, objectName: string, counterName: string, startDate: string, endDate: string }) => {
    const encodedCounterName = encodeURIComponent(`${counterName}`)
    const device = await protectAxios
      .get(`/devicequeries/GetPerformanceValuesHourly/${deviceId}/${deviceGroup}/${objectName}/${encodedCounterName}/${startDate}/${endDate}`)
      .then((resp) => resp.data);
    return device;
  }
);

// PERFORMANCE VALUES WITH POST //

export const raisePerformanceValuesHourly = createAsyncThunk(
  "devices/raisePerformanceValuesHourly",
  async (command: ChartDataCommand) => {
    return await protectAxios
      .post("/devicequeries/PostPerformanceValuesHourly", command)
      .then((resp) => resp.data);
  }
);

export const raisePerformanceValuesDaily = createAsyncThunk(
  "devices/raisePerformanceValuesDaily",
  async (command: ChartDataCommand) => {
    return await protectAxios
      .post("/devicequeries/PostPerformanceValuesDaily", command)
      .then((resp) => resp.data);
  }
);

export const raisePerformanceValuesRAW = createAsyncThunk(
  "devices/raisePerformanceValuesRAW",
  async (command: ChartDataCommand) => {
    return await protectAxios
      .post("/devicequeries/PostPerformanceValuesRAW", command)
      .then((resp) => resp.data);
  }
);

// RUN AS //

export const fetchAccountTypes = createAsyncThunk(
  "devices/fetchAccountTypes",
  async (_, { dispatch }) => {
    const accounts = await protectAxios
      .get("/devicequeries/RunAs/GetAccountTypes")
      .then((resp) => resp.data);
    dispatch(setAccountTypes(accounts));
    return accounts;
  }
);

export const fetchRunAsProfiles = createAsyncThunk(
  "devices/fetchRunAsProfiles",
  async (_, { dispatch }) => {
    const accounts = await protectAxios
      .get("/devicequeries/RunAs/GetRunAsProfiles")
      .then((resp) => resp.data);
    dispatch(setRunAsProfiles(accounts));
    return accounts;
  }
);

export const fetchRunAsAccounts = createAsyncThunk(
  "devices/fetchRunAsAccounts",
  async (_, { dispatch }) => {
    const accounts = await protectAxios
      .get("/devicequeries/RunAs/GetRunAsAccounts")
      .then((resp) => resp.data);
    dispatch(setRunAsAccounts(accounts));
    return accounts;
  }
);

export const fetchRunAsProfileAndDevices = createAsyncThunk(
  "devices/fetchRunAsProfileAndDevices",
  async (_, { dispatch }) => {
    const accounts = await protectAxios
      .get("/devicequeries/RunAs/GetRunAsProfileAndDevices")
      .then((resp) => resp.data);
    dispatch(setRunAsProfileAndDevices(accounts));
    return accounts;
  }
);

export const fetchRunAsProfileAndDevicesByCompany = createAsyncThunk(
  "devices/fetchRunAsProfileAndDevicesByCompany",
  async (companyId: string, { dispatch }) => {
    const accounts = await protectAxios
      .get(`/devicequeries/RunAs/GetRunAsProfileAndDevices?companyId=${companyId}`)
      .then((resp) => resp.data);
    dispatch(setRunAsProfileAndDevices(accounts));
    return accounts;
  }
);


export const fetchCheckAsAccounts = createAsyncThunk(
  "devices/fetchCheckAsAccounts",
  async () => {
    const account = await protectAxios
      .get("/devicequeries/RunAs/CheckRunAsAccounts")
      .then((resp) => resp.data);
    return account;
  }
);

export const raiseRunAsAccount = createAsyncThunk(
  "devices/raiseRunAsAccount",
  async (command: RaiseRunAsAccountCommand) => {
    return await protectAxios
      .post("/devicecommands/RunAs/CreateRunAsAccount", command)
      .then((resp) => resp.data);
  }
);

export const raiseRunAsProfileAndDevices = createAsyncThunk(
  "devices/raiseRunAsProfileAndDevices",
  async (command: RaiseRunAsProfilesAndDevicesCommand) => {
    return await protectAxios
      .post("/devicecommands/RunAs/UpdateRunAsProfileAndDevices", command)
      .then((resp) => resp.data);
  }
);

export const deleteRunAsAccount = createAsyncThunk(
  "devices/deleteRunAsAccount",
    async (command: DeleteRunAsAccount, { dispatch }) => {
    const accounts = await protectAxios
      .post("/devicecommands/RunAs/DeleteRunAsAccount", command)
      .then((resp) => resp.data);
      dispatch(fetchRunAsAccounts());
      return accounts
  }
);
