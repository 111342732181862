import {  Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography, } from "@mui/material"
import { useState } from "react"
import dayjs from "dayjs";
import { determineAlertSeverityOverTime, determineMultiLineSeriesData, determineRowDataForAlertsOverTime, determineSerierDataForAlertsOverTime, determineTimeDataForAlertsOverTime, onHandleTimeIntervalChange } from "./AlertTabsUtils";
import { useAsyncEffect } from "../../../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { fetchDailyAlertStatistics } from "../../../../redux/Thunks/alerts";
import { getSelectedCompanyId } from "../../../../redux/Slices/account.slice";
import ReportsLineGraph from "../Widgets/ReportsLineGraph";
import ReportsTable from "../Widgets/ReportsTable";

interface AlertsOverTimeProps {
}



export const AlertsOverTime = (props: AlertsOverTimeProps) => {
    const { } = props
    const dispatch = useAppDispatch();

    const [timeInterval, setTimeInterval] = useState('Month')
    const [timeDifference, setTimeDifference] = useState<any>(dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    const dropDownValues = ['Day', 'Week', 'Month', '2 Months', '3 Months', '4 Months', '5 Months', '6 Months', '7 Months', '8 Months', '9 Months', '10 Months', '11 Months', 'Year', 'All Time']
    const now = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const tableHeaders = ['Date', '# Incidents']
    const selectedCompanyId: string | any = useAppSelector(getSelectedCompanyId);
    const [timeData, setTimeData] = useState([])
    const [seriesData, setSeriesData] = useState<any>([])
    const [categoryOverTimeData, setCategoryOverTimeData] = useState<any>([])
    const [alertSeverityOverTime, setAlertSeverityOverTime] = useState<any>([])
    const [rowData, setRowData] = useState([])


    useAsyncEffect(async () => {
        const response = await dispatch(fetchDailyAlertStatistics(
            {
                companyId: selectedCompanyId,
                startDate: timeDifference,
                endDate: now
            }
        ));
        setTimeData(determineTimeDataForAlertsOverTime(response.payload))
        setSeriesData(determineSerierDataForAlertsOverTime(response.payload))
        setRowData(determineRowDataForAlertsOverTime(response.payload))
        setCategoryOverTimeData(determineMultiLineSeriesData(response.payload))
        setAlertSeverityOverTime(determineAlertSeverityOverTime(response.payload))
    }, [timeDifference]);


    return (
        <Card id='exportAlertsOverTime' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Incidents Over Time
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-start' }}>
                        <FormControl sx={{ fontWeight: 'bold', width: '25%', pl: 1 }} variant="outlined" size='small'>
                            <InputLabel id="timeSelect">Time Interval</InputLabel>
                            <Select
                                labelId="timeSelect"
                                id="timeSelect"
                                value={timeInterval}
                                label="Time Interval"
                                size='small'
                                onChange={(event) => {
                                    setTimeInterval(event.target.value as string)
                                    setTimeDifference(onHandleTimeIntervalChange(event))
                                }}

                            >
                                {dropDownValues.map((value: string) => {
                                    return (
                                        <MenuItem value={value}>{value}</MenuItem>

                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                   


                    <Grid item xs={8} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsLineGraph
                            timeData={timeData}
                            seriesData={seriesData}
                            title={'Total Incidents Over Time'}
                            markers={true}
                            text={'Incidents'}
                        />
                    </Grid>

                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Incidents Over Time Data'}

                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsLineGraph
                            timeData={timeData}
                            seriesData={categoryOverTimeData}
                            title={'Incidents Over Time By Category'}
                            markers={false}
                            text={'Incidents'}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsLineGraph
                            timeData={timeData}
                            seriesData={alertSeverityOverTime}
                            title={'Incidents Over Time By Severity'}
                            markers={false}
                            text={'Incidents'}
                        />
                    </Grid>


                </Grid>

            </CardContent>

        </Card >
    )
}