import { TableBody, TableCell, TableRow, TableContainer, Table, Box, TableHead, TableSortLabel, Tooltip, Button, Typography, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Order, getComparator, stableSort } from "../../../../common/TableUtilities";
import { visuallyHidden } from "@mui/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/pro-light-svg-icons";
import { handleExportReportsData } from "../../../../common/reportsUtils";



interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: any,
    ) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
    tableHeaders: any;
}
export function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort, tableHeaders } = props;
    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };
    const [headCells, setHeadCells] = useState<any>([])
    useEffect(() => {
        let cells: any[] = []
        tableHeaders.forEach((header: any) => {
            cells.push({ id: header, numeric: true, disablePadding: true, label: header })
        })
        setHeadCells(cells)
    }, [tableHeaders])
    return (
        <>

            <TableHead>
                {headCells.map((headCell: any) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        padding={"normal"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}

            </TableHead>
        </>
    );
}



interface ReportsTableProps {
    rowData: any;
    tableHeaders: any;
    fileName: string;
}

export function ReportsEnhancedTable(props: ReportsTableProps) {
    return <EnhancedTable
        rowData={props.rowData}
        tableHeaders={props.tableHeaders}
        fileName={props.fileName}
    ></EnhancedTable>;
}

export default function EnhancedTable(props: {
    rowData: any;
    tableHeaders: any;
    fileName: string;
}) {
    const { rowData, tableHeaders, fileName } = props;
    
    const [rows, setRows] = useState<any>(rowData);
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<any>(tableHeaders[0]);
    useEffect(() => {
        setRows(rowData)
    }, [rowData])
    const handleRequestSort = (_event: React.MouseEvent<unknown>, property: any) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        if (isAsc) {
            const filteredRows = rows.sort((a: any, b: any) => a.value > b.value ? 1 : -1)
            setRows(filteredRows)
        }
        if (!isAsc) {
            const filteredRows = rows.sort((a: any, b: any) => b.value > a.value ? 1 : -1)
            setRows(filteredRows)
        }
    };

    return (
        <>
            <TableContainer sx={{ width: '100%', height: '50vh', overflowY: 'auto', border: "solid 1px #ddd", borderRadius: '5px', m: 1 }} >
                <TableRow sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(57,90,122)' }}>
                    <Grid container spacing={0}>
                        <Grid item xs={9} sx={{ display: "flex", justifyContent: 'space-between' }}>
                            <Typography variant="h4" sx={{ pt: 1, pb: 1, pl: 2, color: 'white' }}>{fileName}</Typography>

                        </Grid>
                        <Grid item xs={3} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                            <Tooltip title='Export Data'>
                                <Button
                                    onClick={() => handleExportReportsData(rowData, fileName)}
                                >

                                    <FontAwesomeIcon
                                        icon={faFileExport}
                                        color={"white"}
                                        size="2x"
                                    />
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </TableRow>
                <Table
                    stickyHeader
                    size="small"
                    aria-label="alerts"
                >
                    <EnhancedTableHead
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        key={'table'}
                        tableHeaders={tableHeaders}
                    />
                    <TableBody>
                        {stableSort(rows as any, getComparator(order, orderBy))
                            .map((row, _index) => {
                                return (
                                    <TableRow tabIndex={-1} key={_index}>
                                        <TableCell>{row.value}</TableCell>
                                        <TableCell >{row.count}</TableCell>
                                        {row.percentage ?
                                            <TableCell >{row.percentage} %</TableCell>
                                            :
                                            <></>
                                        }
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
