import React from "react";
import * as Mui from "@mui/material";
import {
  faCircleCheck,
  faExclamationTriangle,
  faCircleX,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICompanyData } from "./SignupPage";
import { CreateCompanyCommand } from "../../types/company.types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { createCompany } from "../../redux/Thunks/company";
import { CreatePrimaryContactCommand } from "../../types/account.types";
import {
  createPrimaryContact,
  signUpPrimaryContact,
} from "../../redux/Thunks/account";
import {
  getSetUpInfoPage,
  setSetUpInfoPage,
} from "../../redux/Slices/company.slice";
import { SetUpInfo } from "../../components/SetUpInfo";

interface SignupAllDoneProps {
  formData: ICompanyData;
}

export const SignupAllDone: React.FC<SignupAllDoneProps> = (props) => {
  const dispatch = useAppDispatch();
  const setUpInfoPageOpen = useAppSelector(getSetUpInfoPage);

  const [companyCreated, setCompanyCreated] = React.useState(false);
  const [primaryContactAccountCreated, setPrimaryContactAccountCreated] =
    React.useState(false);
  const [primaryContactAccountError, setPrimaryContactAccountError] =
    React.useState(false);
  const [primaryContactEmailSent, setPrimaryContactEmailSent] =
    React.useState(false);

  const createAccount = async () => {
    var createCompanyCommand: CreateCompanyCommand = {
      companyAddressLine1: props.formData.companyAddressLine1,
      companyAddressLine2: props.formData.companyAddressLine2,
      companyAddressLine3: props.formData.companyAddressLine3,
      companyName: props.formData.companyName,
      countryCode: props.formData.country,
      postalZip: props.formData.postalZip,
      primaryContactEmail: props.formData.primaryContactEmail,
      primaryContactName: `${props.formData.primaryContactFirstName} ${props.formData.primaryContactSurname}`,
      townCity: props.formData.townCity,
      stateCounty: props.formData.stateCounty,
    };

    var createPrimaryContactCommand: CreatePrimaryContactCommand = {
      companyName: props.formData.companyName,
      primaryContactEmail: props.formData.primaryContactEmail,
      primaryContactFirstName: props.formData.primaryContactFirstName,
      primaryContactSurname: props.formData.primaryContactSurname,
    };
    const action = await dispatch(createCompany(createCompanyCommand));
    const data = action.payload;

    //TODO: what to do on a failure here?
    setCompanyCreated(true);
    const contactAction = await dispatch(
      createPrimaryContact({
        companyId: data.id,
        command: createPrimaryContactCommand,
      })
    );
    if (!contactAction.payload) {
      setPrimaryContactAccountError(true);
    }
    if (contactAction.payload) {
      setPrimaryContactAccountCreated(true);
    }

    await dispatch(signUpPrimaryContact(contactAction.payload.id));

    setPrimaryContactEmailSent(true);
  };

  //React 18 dev mode causes rerender
  const creatingAccount = React.useRef(false);

  React.useEffect(() => {
    if (creatingAccount.current === false) {
      creatingAccount.current = true;
      createAccount();
    }
  }, []);

  const handleAdditionalInfoClick = () => {
    dispatch(setSetUpInfoPage(true));
  };
  if (setUpInfoPageOpen) {
    return <SetUpInfo />;
  }

  return (
    <Mui.Stack
      direction="column"
      alignItems={"center"}
      sx={{ width: { xs: "100%", md: "70%", xxl: "55%" } }}
      spacing={1}
      padding={2}
    >
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        Creating your account
      </Mui.Typography>
      <Mui.List sx={{ mt: 5 }}>
        {/* <Mui.ListItem>
                    <Mui.ListItemAvatar>
                    <Mui.Avatar sx={{backgroundColor:'white'}}>
                        <FontAwesomeIcon icon={faCircleCheck} color={companyCreated ? 'limegreen' : '#ccc'} size="2x" />
                    </Mui.Avatar>
                    </Mui.ListItemAvatar>
                    <Mui.ListItemText primary="Preflight checks" secondary="waiting...." />
                </Mui.ListItem> */}
        <Mui.ListItem>
          <Mui.ListItemAvatar>
            <Mui.Avatar sx={{ backgroundColor: "white" }}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={companyCreated ? "limegreen" : "#ccc"}
                size="2x"
              />
            </Mui.Avatar>
          </Mui.ListItemAvatar>
          <Mui.ListItemText
            primary="Creating your new company"
            secondary={companyCreated ? "Done!" : "waiting...."}
          />
        </Mui.ListItem>
        <Mui.ListItem>
          <Mui.ListItemAvatar>
            <Mui.Avatar sx={{ backgroundColor: "white" }}>
              {!primaryContactAccountError ? (
                <FontAwesomeIcon
                  icon={faCircleCheck}
                  color={primaryContactAccountCreated ? "limegreen" : "#ccc"}
                  size="2x"
                />
              ) : (
                <FontAwesomeIcon icon={faCircleX} color={"#ff604f"} size="2x" />
              )}
            </Mui.Avatar>
          </Mui.ListItemAvatar>
          <Mui.ListItemText
            primary="Creating the primary contact account"
            color={primaryContactAccountError ? "#ff604f" : "#ccc"}
            secondary={
              primaryContactAccountCreated
                ? "Done!"
                : primaryContactAccountError
                ? "Error creating your primary contact account"
                : "waiting..."
            }
          />
        </Mui.ListItem>
        <Mui.ListItem>
          <Mui.ListItemAvatar>
            <Mui.Avatar sx={{ backgroundColor: "white" }}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={primaryContactEmailSent ? "limegreen" : "#ccc"}
                size="2x"
              />
            </Mui.Avatar>
          </Mui.ListItemAvatar>
          <Mui.ListItemText
            primary="Sending email to primary contact"
            secondary={primaryContactEmailSent ? "Done!" : "waiting...."}
          />
        </Mui.ListItem>
        <Mui.ListItem>
          <Mui.ListItemAvatar>
            <Mui.Avatar sx={{ backgroundColor: "white" }}>
              <FontAwesomeIcon
                icon={faCircleCheck}
                color={primaryContactEmailSent ? "limegreen" : "#ccc"}
                size="2x"
              />
            </Mui.Avatar>
          </Mui.ListItemAvatar>
          <Mui.ListItemText
            primary="Your all set!"
            secondary={
              primaryContactEmailSent ? (
                <Mui.Typography color="orange">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="orange"
                    size="lg"
                  />
                  &nbsp; Please check your email including <br />
                  spam folders for your invitation <br />
                  You can now close this page
                </Mui.Typography>
              ) : (
                "waiting...."
              )
            }
          />
        </Mui.ListItem>

        <Mui.ListItem>
          <Mui.Button variant="outlined" onClick={handleAdditionalInfoClick}>
            <Mui.ListItemText primary="Click here to view additional information on set up." />
          </Mui.Button>
        </Mui.ListItem>
      </Mui.List>
    </Mui.Stack>
  );
};
