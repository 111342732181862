import { NavLink } from "react-router-dom";


const StepOne = () => {
    return (
        <></>
    );
}

const StepTwo = () => {
    return (
        <div>
            <p><strong>Setting up the Security Portal. </strong></p>
            
            <p>To be added</p>
            <p><br /></p>
        </div>
    )
}

const StepThree = () => {
    return (
        <>
            <div>
                <p>User Roles:&nbsp;</p>
            </div>
            <div>
                <p>
                    Company Admin: Manages all aspects of the
                    company,&nbsp;e.g.,&nbsp;invites,
                    user&nbsp;modifications,&nbsp;and&nbsp;Install
                    methods—purchase&nbsp;solutions.&nbsp;
                </p>
            </div>
            <div>
                <p>Client: Assigns tickets and creates change management plans&nbsp;</p>
            </div>
            <div>
                <p>Engineer: Assigns tickets and create change management plans&nbsp;</p>
            </div>
            <div>
                <p>
                    Signatory: Manages sign-off for the change management&nbsp;process&nbsp;
                </p>
            </div>
            <div>
                <p>Manager: Approves ticket assignments and some company functions&nbsp;</p>
            </div>
            <div>
                <p>Approver: Approves or rejects change management plan&nbsp;</p>
            </div>
        </>

    );
}

const StepFour = () => {
    return (
        <>See Products Page</>
    )
}

const StepFive = () => {
    const link = <NavLink
        to={`/users`}
        title={`Go To Users Page`}
        end
    >
        here
    </NavLink>
    return (
        <div>
            <p>Lighthouse onboarding documentation. Click:
                <a href="https://learn.microsoft.com/en-us/azure/lighthouse/how-to/onboard-customer#deploy-in-the-azure-portal" target="_blank" rel="noreferrer">here</a>
            </p>
            <p> Download links for lighthouse template json files from:
                <ol>
                    <li>
                        <a href="https://poimages.blob.core.windows.net/poimages/Lighthouse/PortalCustomer.subscription.json" target="_blank" rel="noreferrer">ARM Template File</a>
                    </li>
                    <li>
                        <a href="https://poimages.blob.core.windows.net/poimages/Lighthouse/PortalCustomer.subscription.parameters.json" target="_blank" rel="noreferrer">ARM Parameter File</a>
                    </li>
                </ol>
                <a href="https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fpoimages.blob.core.windows.net%2Fpoimages%2FLighthouse%2FPortalCustomer.lighthouse.json" target="_blank">
                    <img src="https://aka.ms/deploytoazurebutton" />
                </a>
            </p>
            <p>Sentinel Config Settings Page {link}
            </p>
        </div>
    )
}

const TwoFactorContent = () => {
    return (
        <></>
    )
}



const AddAdminTitle = () => {
    const link = <NavLink
        to={`/users`}
        title={`Go To Users Page`}
        end
    >
        here
    </NavLink>
    return (
        <p style={{ margin: 0 }}>Step One: Add an additional Company Administrator to the portal. Click {link}</p>
    );
}

const InstallAgentsTitle = () => {
    const link = <NavLink
        to={`/users`}
        title={`Go To Users Page`}
        end
    >
        here
    </NavLink>
    return (
        <p style={{ margin: 0 }}>To be defined. Click {link}</p>
    );
}

const ProductsPageTitle = () => {
    const link = <NavLink
        to={`/account`}
        title={`Go To Products Page`}
        end
    >
        here
    </NavLink>
    return (
        <p style={{ margin: 0 }}>Step Five: Select a product you wish to use. Click {link}</p>
    );
}

const LighthouseOnboardingTitle = () => {

    return (
        <p style={{ margin: 0 }}>Step Six: Lighthouse Onboarding </p>
    );
}

const TwoFactorTitle = () => {
    const link = <NavLink
        to={`/settings`}
        title={`Go To Settings Page`}
        end
    >
        here
    </NavLink>
    return (
        <p style={{ margin: 0 }}>Step Two: Add Two factor authentication on the settings page. Click {link}</p>
    );
}


export const NewUserModalSteps = [
    { title: <AddAdminTitle />, content: <StepOne /> },
    { title: <TwoFactorTitle />, content: <TwoFactorContent /> },
    { title: <InstallAgentsTitle />, content: <StepTwo /> },
    { title: 'Step Four: Add users for the portal ', content: <StepThree /> },
    { title: <ProductsPageTitle />, content: <StepFour /> },
    { title: <LighthouseOnboardingTitle />, content: <StepFive /> }

]

