import { Box, Grid, IconButton, Link, Tooltip, Typography } from "@mui/material";
import { UserSummary } from "../../types/account.types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faTrashCan, faUserGear } from "@fortawesome/pro-light-svg-icons";
import { Link as RouterLink } from "react-router-dom";
import { faCircleXmark, faSquareCheck } from "@fortawesome/pro-solid-svg-icons";



interface TeamListItemProps {
    user: UserSummary;
    isAdmin: boolean;
    onDelete: () => void;
    isDeleting: boolean;
}



export const TeamListItem = (props: TeamListItemProps) => {
    const { user, isAdmin, onDelete, isDeleting } = props
    const formattedRoles = (user.roles.split(',')).join(', ');
    const arrayOfRoles = user.roles.split(',')
    return (
        <Grid container spacing={0}>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: 'flex-start' }} direction={'row'} alignItems={'center'}>
                <FontAwesomeIcon
                    icon={faEnvelope}
                    color={user.isConfirmed ? "orange" : "silver"}
                    size="lg"
                />
                <Typography variant="subtitle2" sx={{ ml: 1 }}>
                    {user.isConfirmed ? (
                        <></>
                    ) : (
                        <>(Awaiting Signup Confirmation)&nbsp;</>
                    )}

                    <Link component={RouterLink} to={`/user/${user.id}`}>
                        {user.firstName}&nbsp;{user.lastName}&nbsp;
                        {user.emailAddress}
                    </Link>
                    {user.lockoutEnabled ? <>&nbsp;(Disabled)&nbsp;</> : <></>}
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: 'center' }} direction={'row'} alignItems={'center'}>

                <Typography variant="subtitle1">
                    <Box>
                        Multi-factor Authentication:
                        <Tooltip title={user.twoFactorEnabled ? 'Enabled' : 'Not Enabled'}>
                        {user.twoFactorEnabled ?
                            <FontAwesomeIcon icon={faSquareCheck} color={"#4caf50"} size="lg" style={{ marginLeft: 10 }} />
                            :
                            <FontAwesomeIcon icon={faCircleXmark} color={"#ef5350"} size="lg" style={{ marginLeft: 10 }} />
                        }
                        </Tooltip>
                    </Box>
                </Typography>
            </Grid>
            <Grid item xs={4} sx={{ display: "flex", justifyContent: 'flex-end' }} direction={'row'} alignItems={'center'}>
                <Typography variant="subtitle1">
                    <Tooltip title={'Roles: ' + formattedRoles} placement="left">
                        <Box>

                            <FontAwesomeIcon icon={faUserGear} color={"#ccc"} size="sm" />
                            &nbsp; Roles:&nbsp; {`${arrayOfRoles[0]}... ${arrayOfRoles.length - 1} more`}

                        </Box>
                    </Tooltip>

                </Typography>
                <Grid item>

                    {isAdmin ? (
                        <IconButton disabled={isDeleting} onClick={onDelete}>
                            <FontAwesomeIcon
                                icon={faTrashCan}
                                color={"#f8931c"}
                                size="2xs"
                            />
                        </IconButton>
                    ) : (
                        <></>
                    )}
                </Grid>
            </Grid>
        </Grid>
    )
}