import {

  Grid,
  Box,
  InputLabel,
  Select,
  MenuItem,

  Button,
  Alert,
  Snackbar,
  FormControl,
  ButtonGroup,
} from "@mui/material";
import { useForm, SubmitHandler } from "react-hook-form";
import { useIdentityContext } from "../../context/IdentityContext";
import {
  getAccountTypes,
  getProfileManagementAssignedDevices,
  getRunAsAccounts,
  getRunAsProfiles,
} from "../../redux/Slices/devices.slice";
import { raiseRunAsProfileAndDevices } from "../../redux/Thunks/devices";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  Device,
  RaiseRunAsProfilesAndDevicesCommand,
} from "../../types/devices.types";
import { filterRunAsProfiles } from "./RunAsProfilesFiltering";
import ProfileManagementTransferList from "./ProfileManagementTransferList";
import { useState } from "react";

import { getUserById } from "../../redux/Slices/account.slice";

export const ProfileManagementForm = () => {
  const { control, register, handleSubmit, reset } =
    useForm<RaiseRunAsProfilesAndDevicesCommand>();
  const [open, setOpen] = useState(false);
  const identity = useIdentityContext();
  const dispatch = useAppDispatch();
  const runAsProfiles = useAppSelector(getRunAsProfiles);
  const filteredRunAsProfiles = filterRunAsProfiles(runAsProfiles);
  const accountTypes = useAppSelector(getAccountTypes);
  const profileManagementAssignedDevices = useAppSelector(
    getProfileManagementAssignedDevices
  );
  const deviceIdArray = profileManagementAssignedDevices.map(
    (device: Device) => device.id
  );
  const runAsAccounts = useAppSelector(getRunAsAccounts);
  const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
  const [accountType, setAccountType] = useState(-1);

  const onRaiseRunAsProfilesAndDevices: SubmitHandler<
    RaiseRunAsProfilesAndDevicesCommand
  > = async (data) => {
    const payload = {
      runAsProfileDisplayName: data.runAsProfileDisplayName,
      domain: data.domain,
      userName: user!.emailAddress,
      runAsAccountTypeId: accountType,
      devices: deviceIdArray,
      companyId: identity.authenticatedUser.companyId,
    };

    const response = await dispatch(raiseRunAsProfileAndDevices(payload));
    if (response.payload) {
      setOpen(true);
    }
  };
  const handleMenuItemClick = (item: any) => {
    setAccountType(item.runAsAccountTypeId);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return (
    <Box>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Profile has been successfully updated.
        </Alert>
      </Snackbar>
      <form onSubmit={handleSubmit(onRaiseRunAsProfilesAndDevices)}>

        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 1, mt: 3 }}>
            <FormControl variant="outlined" size='small'>
              <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Account Type'>Account Type</InputLabel>
              <Select
                // {...field}
                size="small"
                required
                id='accountType'
                label="Account Type"
                sx={{ width: "25vw" }}
              >
                {runAsAccounts.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item.domain}
                      onClick={() => handleMenuItemClick(item)}
                    >
                      {item.domain} - {item.userName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mb: 1 }}>
            <FormControl variant="outlined" size='small'>
              <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Profile'>Profile</InputLabel>
              <Select
                // {...field}
                size="small"
                required
                label="Profile"
                sx={{ width: "25vw" }}
              >
                {filteredRunAsProfiles.map((item, index) => {
                  return (
                    <MenuItem key={index} value={item.displayName}>
                      {item.displayName}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
            <ProfileManagementTransferList />

          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <ButtonGroup>

              <Button
                variant="outlined"
                color="primary"
                type="submit"
                onClick={() => reset()}
              >

                Clear
              </Button>
              <Button variant="outlined" color="primary" type="submit">
                Submit
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>








      </form>
    </Box>
  );
};
