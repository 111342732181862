import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faWarning,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import { Card } from "../../../components/Card";
import { useAppSelector } from "../../../redux/hooks";
import { getAlertPriority } from "../../../redux/Slices/alerts.slice";

export const AlertTableSummary = () => {
  //const alertStatus = useAppSelector(getAlertStatus);
  const alertPriority = useAppSelector(getAlertPriority);
  //const alerts = useAppSelector(getAlerts);
  return (
    <Card
      id="Tickets"
      headerIcon={faWarning}
      headerTitle={"Incidents"}
      headerSubText={"Incidents are in UTC time"}
      showHelp={false}
      helpHeaderText=""
      helpHeaderDescription=""
      cardHeaderActions={""}
      cardContent={
        <Grid
          id="overview"
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={12} sx={{pl: 10}}>
            <h4
              style={{
                padding: "0 20px",
                fontWeight: "normal",
                fontSize: "16px",
                margin: 0,
                marginBottom: "10px",
              }}
            >
              Incidents By Priority
            </h4>
            <div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />{" "}
                  {alertPriority.high}
                </h3>
                <span>High</span>
              </div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="orange"
                  />{" "}
                  {alertPriority.medium}
                </h3>
                <span>Medium</span>
              </div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon icon={faCheckCircle} color="grey" />{" "}
                  {alertPriority.low}
                </h3>
                <span>Low</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
      }
    />
  );
};
