



import { FormControl, Grid, MenuItem, InputLabel, Select, SelectChangeEvent, Modal, Button, ButtonGroup, Typography } from "@mui/material";
import { useState } from "react";
import { Loader } from "../Loader";
import { useAppDispatch } from "../../redux/hooks";
import { deleteSentinelConfig } from "../../redux/Thunks/company";


interface DeleteSentinelConfigProps {
    sentinelConfig: any;
    setShowDeleteBanner: (value: boolean) => void
}


export const DeleteSentinelConfig = (props: DeleteSentinelConfigProps) => {
    const { sentinelConfig, setShowDeleteBanner } = props
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({
        id: '',
        subscriptionId: '',
        resourceGroupName: '',
        workspaceName: '',
        workspaceId: ''
    })
    const handleChangeSelectedConfig = (event: SelectChangeEvent<unknown>, type: string) => {
        let result: any[] = []
        if(type === 'workspaceName'){
            result = sentinelConfig.filter((config: { workspaceName: string; }) => config.workspaceName === event.target.value)

        }
        if(type === 'id'){
            result = sentinelConfig.filter((config: { id: string; }) => config.id === event.target.value)

        }
        setFormValues(result[0])
    };
    const handleDelete = async() => {
        setLoading(true)
        const sentinelConfigToDelete = formValues.id
        const deleteResponse = await dispatch(deleteSentinelConfig(sentinelConfigToDelete));
        if (deleteResponse.meta.requestStatus === 'fulfilled') {
            setLoading(false)
            setShowDeleteBanner(true)
        }
    }


    return (
        <>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>

            <Grid container spacing={0}>
                <Grid item xs={12} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <Typography>Select a configuration to delete by Workspace Name or Id</Typography>
                </Grid>
                {/* SELECT COMPONENET */}
                <Grid item xs={6} sx={{ mb: 1, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Workspace Name'>Workspace Name</InputLabel>
                        <Select
                            label='Workspace Name'
                            size="small"
                            required
                            value={formValues['workspaceName']}
                            sx={{ width: "100%", mb: 1 }}
                            onChange={(event) => handleChangeSelectedConfig(event, 'workspaceName')}
                        >
                            {sentinelConfig.map((item: { [key: string]: string; }, index: number) => {
                                return (
                                    <MenuItem key={index} value={item.workspaceName}>
                                        {item.workspaceName}
                                    </MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>

                </Grid>
                {/* ID  */}
                <Grid item xs={6} sx={{ mb: 2, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Configuration Id'>Configuration Id</InputLabel>
                        <Select
                            label='Configuration Id'
                            size="small"
                            required
                            value={formValues['id']}
                            sx={{ width: "100%", mb: 1 }}
                            onChange={(event) => handleChangeSelectedConfig(event, 'id')}
                        >
                            {sentinelConfig.map((item: { [key: string]: string; }, index: number) => {
                                return (
                                    <MenuItem key={index} value={item.id}>
                                        {item.id}
                                    </MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sx={{ mt: 15, pr: 1, display: "flex", justifyContent: 'flex-end' }}>
                    <ButtonGroup
                        onClick={handleDelete}
                    >
                        <Button>
                            Delete
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </>
    )
}

