import { Card, CardContent, Grid, Typography } from "@mui/material"
import ReportBarChart from "../Widgets/ReportBarChart";
import { determineBarChartCategoriesForDeviceOSVersion, determineBarChartDataForDeviceOSVersion, determineRowDataForDeviceOSVersion } from "./DeviceTabsUtils";
import ReportsTable from "../Widgets/ReportsTable";

interface DeviceOSVersionProps {
    deviceOSVersion: any;
}

export const DeviceOSVersion = (props: DeviceOSVersionProps) => {
    const { deviceOSVersion } = props
    const barChartCategories = determineBarChartCategoriesForDeviceOSVersion(deviceOSVersion)
    const barChartData = determineBarChartDataForDeviceOSVersion(deviceOSVersion)
    const tableHeaders = ['Operating System', '# Devices', '% of Total']
    const rowData = determineRowDataForDeviceOSVersion(deviceOSVersion)
  
    return (
        <Card id='exportDeviceOSVersion' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Devices OS Version
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                      
                    </Grid>


                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'devices'}
                        />

                    </Grid>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Device OS Version Data'}
                        />
                    </Grid>



                </Grid>

            </CardContent>

        </Card >
    )
}