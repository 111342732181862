import { Alert } from "../../../types/alerts.types";

export const blankAlert: Alert = {
  repeatCount: 0,
  category: 0,
  categoryDescription: "",
  createdDate: new Date(),
  description: "",
  deviceGroup: "",
  deviceId: "",
  deviceName: "",
  fullDescription: "",
  id: "",
  lastModifiedDate: new Date(),
  monitorStatus: 0,
  monitorStatusDescription: "",
  priority: 100,
  priorityDescription: "",
  severity: 100,
  severityDescription: "",
  status: 0,
  statusDescription: "",
  ticketId: "",
};
