import { faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Paper,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  TableContainer,
  Table,
  Typography,
  Button,
  ButtonGroup,
  Stack,
  Tooltip,
  Grid,
} from "@mui/material";
import React, { useEffect, useMemo } from "react";
import {
  // Link,
  useParams,
  useSearchParams,
  useNavigate,
} from "react-router-dom";
import { Alert } from "../../../types/alerts.types";
import { FormattedDateTimeMilitary } from "../../../common/dateUtils";
import "./AlertsEnhancedTable.css";
import {
  getComparator,
  Order,
  stableSort,
} from "../../../common/TableUtilities";
// import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { EnhancedTableHead } from "./AlertsEnhancedTableHead";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getAlertTableFilterValue,
  getAlertTablePage,
  getAlerts,
  getTotalNumberOfActiveAlerts,
  setAlertTableFilterValue,
  setAlertTablePage,
  setTotalNumberOfActiveAlerts,
} from "../../../redux/Slices/alerts.slice";
import { useIdentityContext } from "../../../context/IdentityContext";
import { getUserById } from "../../../redux/Slices/account.slice";
import { HarveyModal } from "../../../components/HarveyChatModal/HarveyModal";
import { getDevices } from "../../../redux/Slices/devices.slice";
import exportFromJSON from "export-from-json";

interface AlertsEnhancedTableProps {
  alerts: Alert[];
  lastUpdated: any
}

export function AlertsEnhancedTable(props: AlertsEnhancedTableProps) {
  return <EnhancedTable alerts={props.alerts} lastUpdated={props.lastUpdated}></EnhancedTable>;
}

export default function EnhancedTable(props: { alerts: Alert[], lastUpdated: any }) {
  const dispatch = useAppDispatch();
  const alertTablePage = useAppSelector(getAlertTablePage);
  const alerts = useAppSelector(getAlerts);
  const devices = useAppSelector(getDevices);
  const navigate = useNavigate();

  const [rows, setRows] = React.useState<Alert[]>(props.alerts);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Alert>("createdDate");
  const [page] = React.useState(0);
  const alertTotal = useAppSelector(getTotalNumberOfActiveAlerts);
  const [searchDeviceName, setSearchDeviceName] = React.useState<string>("");
  const [searchAlertDescription, setSearchAlertDescription] =
    React.useState<string>("");
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const params = useParams();
  const identity = useIdentityContext();
  const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
  const rolesArray = user?.roles.split(",");
  const isInvestigateUser = rolesArray?.includes("Investigate User");
  const [openHarvey, setOpenHarvey] = React.useState(false);
  const closeHarvey = () => setOpenHarvey(false);
  const [harveyAlert, setHarveyAlert] = React.useState({});

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams();
  const alertTableFilterValue = useAppSelector(getAlertTableFilterValue);

  useEffect(() => {
    if (params.deviceId) {
      let name = devices.find((x) => x.id === params.deviceId)?.deviceName;
      setSearchDeviceName(name || "");
    }
  }, [params.deviceId, devices]);

  const deviceNames = useMemo(() => {
    const options: string[] = [];
    alerts.map((alert) => {
      if (!alert.deviceName || alert.deviceName === "") {
        return;
      }

      if (!options.includes(alert.deviceName)) {
        options.push(alert.deviceName);
      }
    });
    return options;
  }, [alerts]);

  const alertDescriptionOptions = useMemo(() => {
    const options: string[] = [];
    alerts.map((alert) => {
      if (!alert.description || alert.description === "") {
        return;
      }

      if (!options.includes(alert.description)) {
        options.push(alert.description);
      }
    });
    return options;
  }, [alerts]);

  useEffect(() => {
    if (searchDeviceName === "") {
      setRows(alerts);
      setRows(props.alerts);
      setRowsPerPage(25);
      dispatch(setTotalNumberOfActiveAlerts(alerts.length));
      return;
    }
    const filtered = alerts.filter((row) => {
      return row.deviceName
        .toLowerCase()
        .includes(searchDeviceName.toLowerCase());
    });
    setRows(filtered);
    let event = new CustomEvent("change");
    document.querySelector("#device-name-autocomplete")?.dispatchEvent(event);
  }, [alerts, dispatch, props.alerts, searchDeviceName]);

  useEffect(() => {
    if (alertTableFilterValue.filterRule === "Alerts of this type") {
      const filtered = alerts.filter((row: Alert) => {
        return row.description
          .toLowerCase()
          .includes(alertTableFilterValue.alert.description.toLowerCase());
      });
      setRows(filtered);
      setRowsPerPage(filtered.length);
      dispatch(setTotalNumberOfActiveAlerts(filtered.length));
    }
    if (
      alertTableFilterValue.filterRule === "Devices with this type of alert"
    ) {
      //
    }
    if (alertTableFilterValue.filterRule === "Alerts under this category") {
      const filtered = alerts.filter((row: Alert) => {
        return row.category === alertTableFilterValue.alert.category;
      });
      setRows(filtered);
      setRowsPerPage(filtered.length);
      dispatch(setTotalNumberOfActiveAlerts(filtered.length));
    }
  }, [alertTableFilterValue, alerts, dispatch]);

  const handleAlertDescriptionSearch = (query: string) => {
    if (!query) {
      setSearchAlertDescription("");
      setRows(alerts);
    } else {
      setSearchAlertDescription(query);

      const filtered = alerts.filter((row: Alert) => {
        return row.description.toLowerCase().includes(query.toLowerCase());
      });
      setRows(filtered);
    }
  };
  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Alert
  ) => {
    const array = [...alerts]
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "asc" : "desc");
    if (order === 'desc') {
      const sortedRows = array.sort((a: any, b: any) => b[property] - a[property])
      setRows(sortedRows)
    }
    if (order === 'asc') {
      const sortedRows = array.sort((a: any, b: any) => a[property] - b[property])
      setRows(sortedRows)
    }
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    dispatch(setAlertTablePage(newPage + 1));
    setSearchParams({ page: (newPage + 1).toString() });
  };

  const removeFilter = () => {
    setRows(props.alerts);
    setRowsPerPage(25);
    dispatch(setTotalNumberOfActiveAlerts(alerts.length));
    dispatch(setAlertTableFilterValue({ alert: {} as Alert, filterRule: "" }));
  };

  const onClickExport = (data: any) => {
    const fileName = "AlertsTable";
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  return (
    <>
      <HarveyModal
        openHarvey={openHarvey}
        closeHarvey={closeHarvey}
        topic={harveyAlert}
        type={'alert'}
      />
      {/* <Card> */}
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-start'}}>
          <Typography sx={{pt: 0}}>
                {`Last Updated: ${props.lastUpdated.toLocaleTimeString()}`}
                </Typography>
          </Grid>
          <Grid item xs={1.5}>
            <Autocomplete
              disabled={searchDeviceName.length > 0}
              disablePortal
              id="alert-description-autocomplete"
              options={alertDescriptionOptions}
              size="small"
              sx={{ width: 175, display: "inline-block", pb: 2 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Incidents"
                  sx={{ mt: 2 }}
                  value={searchAlertDescription}
                  onChange={(event) =>
                    handleAlertDescriptionSearch(event.target.value || "")
                  }
                />
              )}
              onChange={(_event, value: any) =>
                handleAlertDescriptionSearch(value || "")
              }
              value={searchAlertDescription!}
              PaperComponent={({ children }) => (
                <Paper style={{ background: "#efefef" }}>{children}</Paper>
              )}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Autocomplete
              disabled={searchAlertDescription.length > 0}
              disablePortal
              id="device-name-autocomplete"
              options={deviceNames}
              size="small"
              sx={{ width: 175, display: "inline-block" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Devices"
                  sx={{ mt: 2, ml: 3 }}
                  value={searchDeviceName}
                  onChange={(event) =>
                    setSearchDeviceName(event.target.value || "")
                  }
                />
              )}
              onChange={(_event, value: any) =>
                setSearchDeviceName(value || "")
              }
              value={searchDeviceName!}
              PaperComponent={({ children }) => (
                <Paper style={{ background: "#efefef" }}>{children}</Paper>
              )}
            />
          </Grid>
          <Grid item xs={7}></Grid>
          <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end', pr: 0 }}>
            {rows.length !== props.alerts.length ? (
              <ButtonGroup sx={{pb: 1.5, pt: 1.5}}>
                <Button onClick={removeFilter} sx={{ mt: 2}}>
                  Remove Filter
                </Button>
                <Tooltip title="Export to Excel">
                  <Button
                    variant="outlined"
                    sx={{ mt: 2 }}
                    onClick={() => onClickExport(rows)}
                  >
                    Export
                  </Button>
                </Tooltip>
              </ButtonGroup>
            ) : (
              <ButtonGroup sx={{pb: 1.5, pt: 1.5}}>

              <Tooltip title="Export to Excel">
                
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => onClickExport(rows)}
                  >
                  Export
                </Button>
              </Tooltip>
                  </ButtonGroup>
            )}
          </Grid>
        </Grid>
      {/* </Card> */}

      <Card>
        {/* <CardContent> */}
          <TableContainer
            component={Paper}
            sx={{
              borderStyle: "solid",
              borderColor: "#ccc",
              borderWidth: "1px",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="alerts"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows as any, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, _index) => {
                    return (
                      <TableRow tabIndex={-1} key={row.id}>
                        <TableCell align="left">
                          <Typography>
                            {FormattedDateTimeMilitary(
                              new Date(row.createdDate)
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {row.categoryDescription}
                        </TableCell>
                        <TableCell align="left">
                          {row.deviceName !== '' ?
                            <Button
                              sx={{ textAlign: 'left' }}
                              variant="text"
                              onClick={() => {
                                navigate(`/device/${row.deviceId}/${row.deviceGroup}`);
                              }}
                            >
                              {row.deviceName}
                            </Button>
                            : <></>}
                          {/* <Link
                            to={`/device/${row.deviceId}/${row.deviceGroup}`}
                          >
                            {row.deviceName}
                          </Link> */}
                        </TableCell>
                        <TableCell align="left">
                          {row.description !== '' ?
                            <Button
                              sx={{ textAlign: 'left' }}
                              variant="text"
                              onClick={() => {
                                navigate(`/alert/${row.id}`);
                              }}
                            >
                              {row.description}
                            </Button>
                            : <></>}
                          {/* <Link to={`/alert/${row.id}`}>{row.description}</Link> */}
                        </TableCell>
                        <TableCell align="left">
                          {FormattedDateTimeMilitary(
                            new Date(row.lastModifiedDate)
                          )}
                        </TableCell>
                        <TableCell align="left">{row.repeatCount}</TableCell>

                        <TableCell align="left">
                          {row.priorityDescription === "High" ? (
                            <>
                              <FontAwesomeIcon
                                icon={faStar}
                                color="red"
                                size="sm"
                              />
                              &nbsp;{row.priorityDescription}
                            </>
                          ) : (
                            <>{row.priorityDescription}</>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {isInvestigateUser ? (
                            <Stack
                              direction="row"
                              spacing={1}
                              justifyContent="flex-start"
                            >
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  navigate(`/investigate/${row.id}`);
                                }}
                              >
                                Investigate
                              </Button>
                            </Stack>
                          ) : (
                            <></>
                          )}
                        </TableCell>

                        <TableCell align="left">
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-start"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setOpenHarvey(!openHarvey);
                                setHarveyAlert(row);
                              }}
                            >
                              Ask Harvey
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={alertTotal}
              rowsPerPage={rowsPerPage}
              page={alertTablePage - 1}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        {/* </CardContent> */}
      </Card>
    </>
  );
}
