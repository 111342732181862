import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Ticket, TicketAssignedEngineer, TicketComment, TicketProblemCategoryCount, TicketRaisedBy, TicketStatus, TicketStatusPerDay } from "../../types/tickets.types";
import { RootState } from "../store";

export const TicketProblemTypes = [
  { id: 0, description: "Server Hardware" },
  { id: 1, description: "Workstation Hardware" },
  { id: 2, description: "Microsoft Software" },
  { id: 3, description: "Other Software" },
  { id: 4, description: "Printer" },
  { id: 5, description: "Router" },
  { id: 6, description: "Security Incident" },
  { id: 7, description: "Internet Router" },
  { id: 8, description: "Firewall" },
  { id: 9, description: "Email" },
  { id: 10, description: "Office 365" },
  { id: 11, description: "Phone Call Comprimised" },
];

export const TicketPriorities = [
  { id: 0, description: "Low" },
  { id: 1, description: "Medium" },
  { id: 2, description: "High" },
  { id: 3, description: "Critical" },
];

export interface TicketsState {
  tickets: Array<Ticket>;
  ticketById: Ticket;
  ticketProblemCategoryCounts: TicketProblemCategoryCount;
  ticketStatusPerDay: TicketStatusPerDay;
  ticketRaisedBy: Array<TicketRaisedBy>;
  ticketAssignedEngineer: Array<TicketAssignedEngineer>
}

const initialState: TicketsState = {
   tickets: [],
   ticketById: {} as Ticket, 
   ticketProblemCategoryCounts: {} as TicketProblemCategoryCount,
   ticketStatusPerDay: {} as TicketStatusPerDay,
   ticketRaisedBy: [],
   ticketAssignedEngineer: [],
  };

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    setTicketById: (state, action: PayloadAction<Ticket>) => {
      const ticketIdx = state.tickets.findIndex(
        (ticket) => ticket.id === action.payload.id
      );

      if (ticketIdx) {
        const newTicketsArray = [...state.tickets];
        newTicketsArray[ticketIdx] = action.payload;
        state.tickets = newTicketsArray;
      }
    },
    setOneTicketById: (state, action: PayloadAction<Ticket>) => {
      state.ticketById = action.payload;
    },
    setTickets: (state, action: PayloadAction<Array<Ticket>>) => {
      state.tickets = action.payload;
    },
    setTicketComments: (
      state,
      action: PayloadAction<{
        ticketId: string;
        comments: Array<TicketComment>;
      }>
    ) => {
      const ticketIdx = state.tickets.findIndex(
        (ticket) => ticket.id === action.payload.ticketId
      );

      if (ticketIdx) {
        const newTicketsArray = [...state.tickets];
        newTicketsArray[ticketIdx].comments = action.payload.comments;
        state.tickets = newTicketsArray;
      }
    },
    setTicketProblemCategoryCounts: (state, action: PayloadAction<TicketProblemCategoryCount>) => {
      state.ticketProblemCategoryCounts = action.payload;
    },
    setTicketStatusPerDay: (state, action: PayloadAction<TicketStatusPerDay>) => {
      state.ticketStatusPerDay = action.payload;
    },
    setTicketRaisedBy: (state, action: PayloadAction<Array<TicketRaisedBy>>) => {
      state.ticketRaisedBy = action.payload;
    },
    setTicketAssignedEngineer: (state, action: PayloadAction<Array<TicketAssignedEngineer>>) => {
      state.ticketAssignedEngineer = action.payload;
    },
  },
});

export const { 
  setTicketById, 
  setTicketComments, 
  setTickets, 
  setOneTicketById,
  setTicketProblemCategoryCounts,
  setTicketStatusPerDay,
  setTicketRaisedBy,
  setTicketAssignedEngineer,
} =
  ticketsSlice.actions;

export const getTicketById = (id: string) => (state: RootState) =>
  state.tickets.tickets.find((ticket) => ticket.id === id);
export const getOneTicketById = (state: RootState) => state.tickets.ticketById;
export const getMyOpenTickets = (userId: string) => (state: RootState) =>
  state.tickets.tickets.filter((ticket) => {
    return (
      ticket.status === TicketStatus.InProgress &&
      ticket.assignedEngineerId === userId
    );
  });

export const getAllTickets = (state: RootState) => state.tickets.tickets;
export const getAllNonChangeTickets = (state: RootState) =>
  state.tickets.tickets.filter((ticket) => ticket.changeManagementItemId === null);
export const getOpenTickets = (state: RootState) =>
  state.tickets.tickets.filter((ticket) => ticket.status !== 3);
export default ticketsSlice.reducer;
export const getTicketProblemCategoryCount = (state: RootState) => state.tickets.ticketProblemCategoryCounts;
export const getTicketstatusPerDay = (state: RootState) => state.tickets.ticketStatusPerDay;
export const getTicketRaisedBy = (state: RootState) => state.tickets.ticketRaisedBy;
export const getTicketAssignedEngineer = (state: RootState) => state.tickets.ticketAssignedEngineer;




