import * as Mui from "@mui/material";
import { faCircleInfo, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card } from "../../components/Card";
import { FormattedDate } from "../../common/dateUtils";
import { useAppDispatch } from "../../redux/hooks";
import { ChangeManagementItemSummary } from "../../types/changemanagement.types";
import {
  itemInProgress,
  itemIsNew,
  setManualItemToComplete,
  setManualItemToInprogress,
} from "../../redux/Thunks/changeManagement";

interface ManualSignoffProps {
  changeManagementItem: ChangeManagementItemSummary;
  onStatusChange: VoidFunction;
}

export const ManualSignoff: React.FC<ManualSignoffProps> = (props) => {
  const itemId = props.changeManagementItem.id;
  const dispatch = useAppDispatch();
  const handleSetToInProgress = async () => {
    await dispatch(setManualItemToInprogress(itemId));
    props.onStatusChange();
  };

  const handleSetToComplete = async () => {
    await dispatch(setManualItemToComplete(itemId));
    props.onStatusChange();
  };

  return (
    <Card
      id="Milestones"
      headerIcon={faUser}
      headerTitle={`Progress change management`}
      headerSubText={`Change management item created on ${FormattedDate(
        props.changeManagementItem.createdDate
      )} 
                and last updated on ${FormattedDate(
                  props.changeManagementItem.lastModifiedDate
                )}`}
      showHelp={false}
      cardContent={
        <Mui.Typography>
          <FontAwesomeIcon icon={faCircleInfo} /> There are no plans or
          milestones set for this change management item. This means this change
          management is managed manually by users who need to set the item in
          progress and sign it off when complete.
        </Mui.Typography>
      }
      actions={
        <>
          {itemIsNew(props.changeManagementItem) ? (
            <Mui.Button variant="outlined" onClick={handleSetToInProgress}>
              Set to in progress
            </Mui.Button>
          ) : (
            <></>
          )}
          {itemInProgress(props.changeManagementItem) ? (
            <Mui.Button variant="outlined" onClick={handleSetToComplete}>
              Signoff this item
            </Mui.Button>
          ) : (
            <></>
          )}
        </>
      }
    />
  );
};
