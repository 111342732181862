import React from "react";
import * as Mui from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSignature,
  faPeopleGroup,
  faEnvelope,
} from "@fortawesome/pro-light-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UpdateUserCommand, UserSummary } from "../../types/account.types";

const UserSummaryListItem: React.FC<{
  icon: IconDefinition;
  description: string | React.ReactNode;
  color: string;
}> = (props) => {
  return (
    <Mui.ListItem sx={{ pt: 0, pb: 0 }}>
      <Mui.ListItemAvatar
        sx={{ minWidth: "36px", padding: "3px", mr: 1, borderRadius: "5px" }}
      >
        <FontAwesomeIcon icon={props.icon} color={props.color} size="lg" />
      </Mui.ListItemAvatar>
      <Mui.ListItemText sx={{ p: 0 }} primary={<>{props.description}</>} />
    </Mui.ListItem>
  );
};

interface UserSummaryProps {
  user: UserSummary;
  isReadOnly: boolean;
  onUserUpdate: (updateCommand: UpdateUserCommand) => void;
}

export const UserBreakdown: React.FC<UserSummaryProps> = (props) => {
  const validationSchema = yup.object().shape({
    emailAddress: yup
      .string()
      .email("Primary contact email has to be a valid email address"),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateUserCommand>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<UpdateUserCommand> = (data) => {
    props.onUserUpdate(data);
  };

  const resetForm = () => {
    let defaultValues: UpdateUserCommand = {
      firstName: props.user.firstName,
      lastName: props.user.lastName,
      emailAddress: props.user.emailAddress,
    };
    reset({ ...defaultValues });
  };

  React.useEffect(() => {
    resetForm();
  }, [props.user]);

  const handleReset = () => {
    resetForm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
      <Mui.Card sx={{mb: 1}}>
        <Mui.CardHeader
          avatar={
            <Mui.Avatar
              sx={{
                width: "36px",
                height: "36px",
                backgroundColor: props.user.isConfirmed ? "orange" : "silver",
              }}
              aria-label=""
            >
              <FontAwesomeIcon icon={faUser} color={"white"} size="sm" />
            </Mui.Avatar>
          }
          title={"User Summary"}
          subheader={
            <Mui.Typography variant="subtitle2" color="silver">{`${
              props.user.isConfirmed
                ? "User has confirmed and is an active user"
                : "User has not confirmed their invitation yet"
            }`}</Mui.Typography>
          }
          sx={{
            borderBottom: "solid",
            borderBottomWidth: "1px",
            borderBottomColor: "orange",
            p: 1,
          }}
        />
        <Mui.CardContent>
          {props.isReadOnly ? (
            <Mui.List
              dense={true}
              sx={{ width: "100%", bgcolor: "background.paper" }}
            >
              <UserSummaryListItem
                icon={faSignature}
                description={`${props.user.firstName} ${props.user.lastName}`}
                color="silver"
              />
              <UserSummaryListItem
                icon={faPeopleGroup}
                description={
                  <Mui.Typography>
                    Current roles are:{" "}
                    <Mui.Box component="span" fontWeight="bold">
                      {props.user.roles}
                    </Mui.Box>
                  </Mui.Typography>
                }
                color="silver"
              />
              <UserSummaryListItem
                icon={faEnvelope}
                description={`${props.user.emailAddress}`}
                color={props.user.isConfirmed ? "orange" : "silver"}
              />
            </Mui.List>
          ) : (
            <>
              <Controller
                name="firstName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Mui.TextField
                    name={field.name}
                    value={field.value}
                    size="small"
                    required
                    onChange={field.onChange}
                    label="Users First Name"
                    sx={{ width: "100%", mb: 3, mt: 2 }}
                    helperText="Please enter the first name of the user"
                    error={errors.firstName !== undefined}
                  />
                )}
              />

              <Controller
                name="lastName"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Mui.TextField
                    name={field.name}
                    value={field.value}
                    size="small"
                    required
                    onChange={field.onChange}
                    label="Users Surname"
                    sx={{ width: "100%", mb: 3 }}
                    helperText="Please enter the last name of the user"
                    error={errors.lastName !== undefined}
                  />
                )}
              />

              <Controller
                name="emailAddress"
                control={control}
                defaultValue=""
                render={({ field: { name, value, onChange } }) => (
                  <Mui.TextField
                    name={name}
                    value={value}
                    size="small"
                    required
                    onChange={onChange}
                    label="Users email address"
                    sx={{ width: "100%", mb: 3 }}
                    helperText={
                      errors.emailAddress ? (
                        <Mui.Typography variant="subtitle2">
                          {errors.emailAddress?.message}
                        </Mui.Typography>
                      ) : (
                        <>Please enter the email of the user</>
                      )
                    }
                    error={errors.emailAddress !== undefined}
                  />
                )}
              />
            </>
          )}
          <Mui.Divider />
        </Mui.CardContent>
        <Mui.CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
          <Mui.FormGroup row>
            {props.isReadOnly ? (
              <></>
            ) : (
              <>
                <Mui.Button
                  variant="outlined"
                  color="secondary"
                  sx={{ mr: 1 }}
                  onClick={handleReset}
                >
                  Restore
                </Mui.Button>
                <Mui.Button variant="outlined" color="primary" type="submit">
                  Save Updates
                </Mui.Button>
              </>
            )}
          </Mui.FormGroup>
        </Mui.CardActions>
      </Mui.Card>
    </form>
  );
};
