import {
    Box,
    AppBar,
    Toolbar, Drawer, Divider, 
    // Button,
} from '@mui/material';
import * as React from 'react';
import { Device } from '../../types/devices.types';
//import { TreeView, TreeItem } from '@mui/lab';
import { TreeView, TreeItem } from '@mui/x-tree-view';
// import { faChartLineUp } from "@fortawesome/pro-light-svg-icons";
import { faChevronRight, faChevronDown } from "@fortawesome/pro-solid-svg-icons";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    useAppDispatch, useAppSelector,
} from '../../redux/hooks';
import { fetchPerformanceCategories } from '../../redux/Thunks/devices';
import { useState } from 'react';
import PerformanceCategoryTree from './PerformanceCategoryTree';
import { getChartCounter } from '../../redux/Slices/devices.slice';


interface ChartsSideBarProps {
    devices: Device[];
    onAddChart: (addChart: any) => void;
}
const drawerWidth = 300;




export default function ChartsSidebar(props: ChartsSideBarProps) {
    const { devices, onAddChart } = props
    const dispatch = useAppDispatch();
    const [categories, setCategories] = useState<any>([])
    const chartCounter = useAppSelector(getChartCounter);


    const onOpenDevice = async (device: Device) => {
 
        const response = await dispatch(
            fetchPerformanceCategories({ id: device.id, deviceGroup: device.deviceGroup })
        );
        setCategories([...categories, { 'id': device.id, 'categories': response.payload }]);
    }
    return (
        <Box sx={{ display: 'flex' }}>
            <AppBar
                position="fixed"
                sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
            >

            </AppBar>

            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="right"
            >
                <Toolbar />
                <Toolbar >
                    {/* <Button onClick={() => onAddChart(chartCounter)}>
                        <FontAwesomeIcon
                            size="3x"
                            title='Create Chart'
                            icon={faChartLineUp}
                            color={"black"}
                        />
                    </Button> */}
                </Toolbar>
                <Divider sx={{ marginBottom: '10px' }} />
                <TreeView
                    defaultCollapseIcon={<FontAwesomeIcon
                        icon={faChevronDown}
                        style={{ width: 10, height: 10 }}
                    />}
                    defaultExpandIcon={<FontAwesomeIcon
                        icon={faChevronRight}
                        style={{ width: 10, height: 10 }}
                    />}
                    sx={{ height: 240, flexGrow: 1, maxWidth: 300, overflowY: 'auto' }}
                >

                    {devices.map((device, index) => (
                        <TreeItem 
                        nodeId={index.toString()} 
                        label={device.deviceName} 
                        onClick={() => {onOpenDevice(device)}}
                        >
                            <PerformanceCategoryTree performanceCategories={categories} device={device} />
                        </TreeItem>

                    ))}

                </TreeView>


            </Drawer>

            <Toolbar />

        </Box>
    );
}