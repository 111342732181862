import * as Mui from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faBell } from "@fortawesome/pro-light-svg-icons";

import { Link } from "react-router-dom";

import { IStringIndexable } from "../../common/contracts";
import { IProtectOrgThemeHealthState } from "../../themes/ProtectOrgTheme";
import { CardHeader } from "../../components/CardHeader";
import { FormattedDate } from "../../common/dateUtils";
import { Alert } from "../../types/alerts.types";

const AlertListItem: React.FC<{ alert: Alert }> = (props) => {
  const theme = useTheme();
  var indexableTheme =
    theme.healthStates as IStringIndexable<IProtectOrgThemeHealthState>;

  return (
    <Mui.ListItem sx={{ pt: 0, pb: 0, pl: 0 }} secondaryAction={""}>
      <Mui.ListItemAvatar
        sx={{ minWidth: "36px", padding: "3px", borderRadius: "5px" }}
      ></Mui.ListItemAvatar>
      <Mui.ListItemText
        sx={{ p: 0 }}
        primary={
          <Mui.Typography variant="subtitle1" fontWeight={"normal"}>
            <FontAwesomeIcon icon={faCalendar} color={"#f8931c"} size="sm" />
            &nbsp;Created On: {FormattedDate(props.alert.createdDate)}
          </Mui.Typography>
        }
        secondary={
          <Mui.Typography variant="subtitle2">
            <Link
              to={{
                pathname: `/alert/${props.alert.id}`,
              }}
            >
              {props.alert.description}
            </Link>
          </Mui.Typography>
        }
      />
    </Mui.ListItem>
  );
};

interface AllDeviceAlertsProps {
  alerts: Alert[];
  device: any;
}

/*
check out the customised example for inspiration : 

https://mui.com/material-ui/react-list/#customized-list

we could have a link to the dashboard in the firebase 
in the project part have filters for the alerts : severe, priority etc
then the collapsable list of alerts which link to each alert.

*/

export const AllDeviceAlerts: React.FC<AllDeviceAlertsProps> = (props) => {
  if (!props.device) return null;
  return (
    <Mui.Card sx={{ border: "solid 1px #ddd", height: "100%" }}>
      <CardHeader
        icon={faBell}
        subText={`Total of ${props.alerts.length} alerts(s)`}
        title={`All Alerts for ${props.device.deviceName}`}
      />
      <Mui.Grid
        item
        xs={12}
        container
        sx={{ pt: 1, pl: 1, pb: 0, height: "100%" }}
      >
        <Mui.Grid item xs={12}>
          <Mui.Stack
            direction="column"
            sx={{ pr: 1 }}
            style={{ height: "100%" }}
          >
            <Mui.List
              dense={true}
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                overflowY: "auto",
                maxHeight: "100vh",
              }}
            >
              {props.alerts.length > 0 ? (
                props.alerts.map((alert: Alert, index: number) => (
                  <>
                    <AlertListItem key={`alert${index}`} alert={alert} />
                    <Mui.Divider></Mui.Divider>
                  </>
                ))
              ) : (
                <Mui.Typography>
                  There are currently no alerts for this device.
                </Mui.Typography>
              )}
            </Mui.List>
          </Mui.Stack>
        </Mui.Grid>
      </Mui.Grid>
    </Mui.Card>
  );
};
