import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { healthStates } from "../../themes/ProtectOrgTheme";
import { Device, DeviceChartObject, DeviceStat, AccountTypes, RunAsProfiles, RunAsAccounts, RunAsProfileAndDevices, DeviceTypes, DeviceCategory, DeviceSyncedFrom, DeviceOSVersion, DeviceHealthStates } from "../../types/devices.types";
import { RootState } from "../store";

export interface DeviceState {
  devices: Array<Device>;
  deviceTotals: DeviceStat;
  lastModifiedDevices: Array<Device>;
  chartCounter: DeviceChartObject;
  chartErrorMessage: boolean;
  doubleClickChartCounter: DeviceChartObject;
  accountTypes: Array<AccountTypes>;
  runAsProfiles: Array<RunAsProfiles>;
  runAsAccounts: Array<RunAsAccounts>;
  runAsProfileAndDevices: Array<RunAsProfileAndDevices>;
  profileManagementAssignedDevices: Array<Device>;
  deviceTypes: Array<DeviceTypes>;
  deviceCategory: Array<DeviceCategory>;
  deviceSyncedFrom: DeviceSyncedFrom;
  deviceOSVersion: Array<DeviceOSVersion>;
  deviceHealthStates: DeviceHealthStates;
}

const initialState: DeviceState = {
  devices: [],
  deviceTotals: {
    deviceCount: 0,
    isVirtual: 0,
    monitoringOff: 0,
    monitoringOn: 0,
    maintenenceOff: 0,
    maintenenceOn: 0,
    errorState: { status: 0, total: 0, fill: "", statusDescription: "" },
    successState: { status: 0, total: 0, fill: "", statusDescription: "" },
    warningState: { status: 0, total: 0, fill: "", statusDescription: "" },
  },
  lastModifiedDevices: [],
  chartCounter:  {} as DeviceChartObject,
  chartErrorMessage: false,
  doubleClickChartCounter: {} as DeviceChartObject,
  accountTypes: [],
  runAsProfiles: [],
  runAsAccounts: [],
  runAsProfileAndDevices: [],
  profileManagementAssignedDevices: [],
  deviceTypes: [],
  deviceCategory: [],
  deviceSyncedFrom: {} as DeviceSyncedFrom,
  deviceOSVersion: [],
  deviceHealthStates: {} as DeviceHealthStates,
};

const devicesSlice = createSlice({
  name: "devices",
  initialState,
  reducers: {
    setDevices: (state, action: PayloadAction<Array<Device>>) => {
      state.devices = action.payload;
    },
    setDeviceTotals: (state, action: PayloadAction<DeviceStat>) => {
      const deviceStat = action.payload;
      deviceStat.errorState.fill =
        healthStates[
          deviceStat.errorState.statusDescription.toLowerCase()
        ].backgroundColor.toString();
      deviceStat.warningState.fill =
        healthStates[
          deviceStat.warningState.statusDescription.toLowerCase()
        ].backgroundColor.toString();
      deviceStat.successState.fill =
        healthStates[
          deviceStat.successState.statusDescription.toLowerCase()
        ].backgroundColor.toString();
      state.deviceTotals = deviceStat;
    },
    setLastModifiedDevices: (state, action: PayloadAction<Array<Device>>) => {
      state.lastModifiedDevices = action.payload;
    },
    setChartCounter: (state, action: PayloadAction<DeviceChartObject>) => {
      state.chartCounter = action.payload;
    },
    setChartErrorMessage: (state, action: PayloadAction<boolean>) => {
      state.chartErrorMessage = action.payload;
    },
    setDoubleClickChartCounter: (state, action: PayloadAction<DeviceChartObject>) => {
      state.doubleClickChartCounter = action.payload;
    },
    setAccountTypes: (state, action: PayloadAction<Array<AccountTypes>>) => {
      state.accountTypes = action.payload;
    },
    setRunAsProfiles: (state, action: PayloadAction<Array<RunAsProfiles>>) => {
      state.runAsProfiles = action.payload;
    },
    setRunAsAccounts: (state, action: PayloadAction<Array<RunAsAccounts>>) => {
      state.runAsAccounts = action.payload;
    },
    setRunAsProfileAndDevices: (state, action: PayloadAction<Array<RunAsProfileAndDevices>>) => {
      state.runAsProfileAndDevices = action.payload;
    },
    setProfileManagementAssignedDevices: (state, action: PayloadAction<Array<Device>>) => {
      state.profileManagementAssignedDevices = action.payload;
    },
    setDeviceTypes: (state, action: PayloadAction<Array<DeviceTypes>>) => {
      state.deviceTypes = action.payload;
    },
    setDeviceCategory: (state, action: PayloadAction<Array<DeviceCategory>>) => {
      state.deviceCategory = action.payload;
    },
    setDeviceSyncedFrom: (state, action: PayloadAction<DeviceSyncedFrom>) => {
      state.deviceSyncedFrom = action.payload;
    },
    setDeviceOSVersion: (state, action: PayloadAction<Array<DeviceOSVersion>>) => {
      state.deviceOSVersion = action.payload;
    },
    setDeviceHealthStates: (state, action: PayloadAction<DeviceHealthStates>) => {
      state.deviceHealthStates = action.payload;
    },
  },
});

export const { 
  setDeviceTotals, 
  setDevices,
  setLastModifiedDevices, 
  setChartCounter, 
  setChartErrorMessage, 
  setDoubleClickChartCounter,
  setAccountTypes,
  setRunAsProfiles,
  setRunAsAccounts,
  setRunAsProfileAndDevices,
  setProfileManagementAssignedDevices,
  setDeviceTypes,
  setDeviceCategory,
  setDeviceSyncedFrom,
  setDeviceOSVersion,
  setDeviceHealthStates,
} = devicesSlice.actions;

export const getDevices = (state: RootState) => state.devices.devices;
export const getDeviceTotals = (state: RootState) => state.devices.deviceTotals;
export const getLastModifiedDevices = (state: RootState) =>
  state.devices.lastModifiedDevices;
export const getChartCounter = (state: RootState) =>
  state.devices.chartCounter;
export const getChartErrorMessage = (state: RootState) =>
  state.devices.chartErrorMessage;
export const getDoubleClickChartCounter = (state: RootState) =>
  state.devices.doubleClickChartCounter;
export const getAccountTypes = (state: RootState) => state.devices.accountTypes;
export const getRunAsProfiles = (state: RootState) => state.devices.runAsProfiles;
export const getRunAsAccounts = (state: RootState) => state.devices.runAsAccounts;
export const getRunAsProfileAndDevices = (state: RootState) => state.devices.runAsProfileAndDevices;
export const getProfileManagementAssignedDevices = (state: RootState) => state.devices.profileManagementAssignedDevices;
export const getDeviceTypes = (state: RootState) => state.devices.deviceTypes;
export const getDeviceCategory = (state: RootState) => state.devices.deviceCategory;
export const getDeviceSyncedFrom = (state: RootState) => state.devices.deviceSyncedFrom;
export const getDeviceOSVersion = (state: RootState) => state.devices.deviceOSVersion;
export const getDeviceHealthStates = (state: RootState) => state.devices.deviceHealthStates;
  
export default devicesSlice.reducer;
