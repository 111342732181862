import * as Mui from "@mui/material";
import React from "react";
import largeLogo from "../../images/LogoLarge.svg";
import { SignupCompanyAddressDetails } from "./SignupCompanyAddressDetails";
import { SignupAllDone } from "./SignupAllDone";
import { SignupWelcome } from "./SignupWelcome";
import { SignupPrimaryContact } from "./SignupPrimaryContact";

export interface ICompanyData {
  companyName: string;
  companyAddressLine1: string | null;
  companyAddressLine2: string | null;
  companyAddressLine3: string | null;
  townCity: string | null;
  stateCounty: string | null;
  postalZip: string | null;
  country: string | null;
  primaryContactFirstName: string;
  primaryContactSurname: string;
  primaryContactEmail: string;
  primaryContactEmailConfirm: string;
}

export const SignupPage: React.FC = () => {
  const steps = [
    "Welcome",
    "Organisation's Details",
    "Primary Users Details",
    "All done!",
  ];
  const [activeStep, setActiveStep] = React.useState(0);
  const [newCompanyData, setNewCompanyData] = React.useState<ICompanyData>({
    companyName: "",
    companyAddressLine1: null,
    companyAddressLine2: null,
    companyAddressLine3: null,
    townCity: null,
    stateCounty: null,
    postalZip: null,
    country: null,
    primaryContactFirstName: "",
    primaryContactSurname: "",
    primaryContactEmail: "",
    primaryContactEmailConfirm: "",
  });

  const setDataForStep0 = (newCompanyName: string) => {
    setNewCompanyData({ ...newCompanyData, companyName: newCompanyName });
    setActiveStep(1);
  };

  const setDataForStep1 = (data: ICompanyData) => {
    setNewCompanyData({
      ...newCompanyData,
      companyAddressLine1: data.companyAddressLine1,
      companyAddressLine2: data.companyAddressLine2,
      companyAddressLine3: data.companyAddressLine3,
      townCity: data.townCity,
      stateCounty: data.stateCounty,
      postalZip: data.postalZip,
      country: data.country,
    });
    setActiveStep(2);
  };

  const setDataForStep2 = (data: ICompanyData) => {
    setNewCompanyData({
      ...newCompanyData,
      primaryContactEmail: data.primaryContactEmail,
      primaryContactFirstName: data.primaryContactFirstName,
      primaryContactSurname: data.primaryContactSurname,
    });
    setActiveStep(3);
  };

  return (
    <Mui.Box display="flex" justifyContent="center" alignItems="center">
      <Mui.Stack
        direction="column"
        sx={{ width: { xs: "100%", md: "60%", xxl: "50%" } }}
        alignItems="center"
      >
        <Mui.Box
          component="img"
          sx={{
            content: { xs: `url(${largeLogo})` },
            maxWidth: { xs: "350px" },
            minHeight: "100px",
          }}
          alt="Security Portal"
        />

        <Mui.Stepper
          activeStep={activeStep}
          sx={{ width: "70%", mb: 4, mt: 4 }}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Mui.Step key={index} {...stepProps}>
                <Mui.StepLabel {...labelProps}>{label}</Mui.StepLabel>
              </Mui.Step>
            );
          })}
        </Mui.Stepper>
        {activeStep === 0 ? (
          <SignupWelcome
            formData={newCompanyData}
            onNextClick={(companyName: string) => {
              setDataForStep0(companyName);
            }}
          />
        ) : (
          <></>
        )}
        {activeStep === 1 ? (
          <SignupCompanyAddressDetails
            formData={newCompanyData}
            onNextClick={(data: ICompanyData) => {
              setDataForStep1(data);
            }}
            onPreviousClick={() => {
              setActiveStep(0);
            }}
          />
        ) : (
          <></>
        )}
        {activeStep === 2 ? (
          <SignupPrimaryContact
            formData={newCompanyData}
            onNextClick={(data: ICompanyData) => {
              setDataForStep2(data);
            }}
            onPreviousClick={() => {
              setActiveStep(1);
            }}
          />
        ) : (
          <></>
        )}
        {activeStep === 3 ? <SignupAllDone formData={newCompanyData} /> : <></>}
      </Mui.Stack>
    </Mui.Box>
  );
};
