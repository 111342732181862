import smallLogo from "../images/LogoSmall.svg";
import * as Mui from "@mui/material";
import "./Loader.css";
//This logo need to be removed, so we are only seeing the spinning wheel (Cymon Skinner)
interface LoaderProps {
  show: boolean;
}

const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <>
      {" "}
      {props.show ? (
        <Mui.Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "20vh",
          }}
        >
          <Mui.Stack
            direction="column"
            spacing={2}
            sx={{ width: "100%", alignItems: "center" }}
          >
            <Mui.Box
              id="pologo"
              component="img"
              sx={{
                height: 60,
                width: 60,
                //   maxHeight: { xs: 233, md: 167 },
                //   maxWidth: { xs: 350, md: 250 },
              }}
              alt="SecQube"
              src={smallLogo}
            />

            <div style={{ color: "orange" }}>
              <Mui.CircularProgress size="1.5rem" color="inherit" />
            </div>
          </Mui.Stack>
        </Mui.Box>
      ) : (
        <></>
      )}
    </>
  );
};

export { Loader };
