import { createAsyncThunk } from "@reduxjs/toolkit";
import protectAxios from "../../api/axios";

export const uploadImage = createAsyncThunk(
  "util/uploadImage",
  async ({
    uploadUrl,
    imageFile,
    fileName,
  }: {
    uploadUrl: string;
    imageFile: File;
    fileName?: string;
  }) => {
    const formData = new FormData();
    formData.append("uploadedFile", imageFile);
    await protectAxios.post(uploadUrl, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      params: { fileName },
    });
  }
);
