import dayjs from "dayjs"

export const cardStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    maxHeight: '80vh',
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    overflow: 'scroll',
}

export const formatChecks = (checks: any, changeManagementItemId: string) => {
    const returnValue = checks.map((check: any, index: number) => {
        return ({
            id: check.id,
            changeManagementItemPlanId: changeManagementItemId,
            description: check.description,
            checkRequired: check.checkRequired,
            evidenceRequired: check.evidenceRequired,
            orderBy: index
        })
    })
    return returnValue
}

export const formatPlans = (plans: any) => {
    const returnValue = plans.map((plan: any, index: number) => {
        return({
            id: plan.id,
            changeManagementItemId: plan.changeManagementItemId,
            status: plan.status,
            title: plan.title,
            startDate: plan.startDate !== null ? dayjs(plan.startDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            completeDate: plan.completeDate !== null ? dayjs(plan.completeDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            checks: formatChecks(plan.checks, plan.changeManagementItemId),
            assignedToId: plan.assignedToId,
            assignedToName: plan.assignedToName,
            orderBy: index,
        })
    })
    return returnValue
}

export const formatTasks = (tasks: any, id: string) => {
    const returnValue = tasks.map((task: any, index: number) => {
        return ({
            id: task.id,
            changeManagementItemMilestoneId: id,
            description: task.description,
            checkPerformedOn: task.checkPerformedOn,
            orderBy: index,
        })
    })
    return returnValue
}

export const formatMilestones = (milestones: any) => {
    const returnValue = milestones.map((milestone: any, index: number) => {
        return ({
            assignedToId: milestone.assignedToId,
            assignedToName: milestone.assignedToName,
            changeManagementItemId: milestone.changeManagementItemId,
            completedDate: milestone.completedDate !== null ? dayjs(milestone.completedDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") : null,
            dueDate: dayjs(milestone.dueDate).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            id: milestone.id,
            tasks: formatTasks(milestone.tasks, milestone.changeManagementItemId),
            title: milestone.title,
            orderBy: index,
        })
    })
    return returnValue
}


