import * as React from "react";

import * as Mui from "@mui/material";
import { faPencil } from "@fortawesome/pro-light-svg-icons";

import { Steps } from "./Steps";
import { AddDetails } from "./AddDetails";

import { SelectTicket } from "./SelectTicket";
import { EmptyGuid } from "../../common/guidUtils";
import { AddPlan } from "./AddPlan";
import { AddMilestone } from "./AddMilestone";
import { useLocation, useNavigate } from "react-router-dom";
import { Ticket } from "../../types/tickets.types";
import { useAsyncEffect } from "../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchAllUsers } from "../../redux/Thunks/account";
import { getTemplates } from "../../redux/Slices/changeManagement.slice";
import { getUsers } from "../../redux/Slices/account.slice";
import {
  createChangeManagementItem,
  fetchChangeManagementTemplates,
  newChangeManagementPlan,
} from "../../redux/Thunks/changeManagement";
import {
  ChangeManagementMilestone,
  ChangeManagementMilestoneTask,
  ChangeManagementPlan,
  ChangeManagementPlanCheck,
  CreateChangeManagementItemCommand,
} from "../../types/changemanagement.types";
import { setLayout } from "../../redux/Slices/layout.slice";
import { NotifyBannerSmall } from "../../components/NotificationBannerSmall";

export const CreateChangeManagementItemPage: React.FC = () => {
  var navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [creatingCMItem, setCreatingCMItem] = React.useState(false)
  const [ticket, setTicket] = React.useState<Ticket | undefined>(
    location.state
  );
  const [currentStep, setCurrentStep] = React.useState<number>(-1);
  const [stepsComplete, setStepsComplete] = React.useState<boolean[]>([
    false,
    false,
    false,
    false,
  ]);
  const [createdItemId, setCreatedItemId] = React.useState('')
  const [showRaisedNotification, setshowRaisedNotification] =
    React.useState<boolean>(false);
  const users = useAppSelector(getUsers);
  const templates = useAppSelector(getTemplates);

  //the change management item being built
  const [details, setDetails] = React.useState<{
    title: string;
    description: string;
  }>({ title: "", description: "" });
  const [plans, setPlans] = React.useState<ChangeManagementPlan[]>(
    new Array<ChangeManagementPlan>()
  );
  const [milestones, setMilestones] = React.useState<
    ChangeManagementMilestone[]
  >(new Array<ChangeManagementMilestone>());

  React.useEffect(() => {
    dispatch(
      setLayout({
        title: "Create Change Management For Ticket",
        subtitle: "Create Change Management For Ticket",
        icon: faPencil,
        links: [{ href: "/tickets", text: "Tickets Dashboard" }],
      })
    );
  }, [dispatch]);

  const handleHideBanner = () => {
    setCurrentStep(-1);
    setStepsComplete([false, false, false, false]);
    setDetails({ title: "", description: "" });
    setTicket(undefined);
    setPlans(new Array<ChangeManagementPlan>());
    setMilestones(new Array<ChangeManagementMilestone>());
    setshowRaisedNotification(false);
    navigate("/changemanagement/create", { state: undefined });
  };

  const onStepComplete = (stepNumber: number) => {
    var updatedSteps = stepsComplete;
    updatedSteps[stepNumber] = true;
    setStepsComplete([...updatedSteps]);
    setCurrentStep(-1);
  };

  const onEditStep = (stepNumber: number) => {
    setCurrentStep(stepNumber);
  };

  const submitDetails = (title: string, description: string) => {
    setDetails({ title, description });
  };

  const newPlanAdded = (titles: string[]) => {
    var newPlans: ChangeManagementPlan[] = new Array<ChangeManagementPlan>();
    for (var i = 0; i < titles.length; i++) {
      newPlans.push(newChangeManagementPlan(titles[i], undefined, ""));
    }

    setPlans([...newPlans, ...plans]);
  };

  const handleUserAssigned = (
    planIndex: number,
    selectedUserId: string,
    selectedUserName: string
  ) => {
    plans[planIndex].assignedToId = selectedUserId;
    plans[planIndex].assignedToName = selectedUserName;
  };

  const onPlanRemoved = (planIndex: number) => {
    var removedItemPlans = plans;
    removedItemPlans.splice(planIndex, 1);
    setPlans(removedItemPlans);
  };

  const onPlanCheckAdded = (
    planIndex: number,
    description: string,
    checkRequired: boolean,
    evidenceRequired: boolean
  ) => {
    var currentPlans = plans;
    var newCheck: ChangeManagementPlanCheck = {
      checkRequired,
      evidenceRequired,
      description,
      issuePlanId: EmptyGuid(),
      checkPerformedOn: null,
      evidenceUploaded: false,
      isComplete: false,
      id: EmptyGuid(),
    };

    currentPlans[planIndex].checks.push(newCheck);
    setPlans([...currentPlans]);
  };

  const onPlanCheckRemoved = (planIndex: number, checkIndex: number) => {
    var currentPlans = plans;
    currentPlans[planIndex].checks.splice(checkIndex, 1);
    setPlans([...currentPlans]);
  };

  const newMilestoneAdded = (
    title: string,
    dueDate: Date,
    assignedToId?: string,
    assignedToName?: string
  ) => {
    var newMilestone: ChangeManagementMilestone = {
      title,
      dueDate,
      id: EmptyGuid(),
      issueId: EmptyGuid(),
      tasks: [],
      assignedToId,
      assignedToName,
    };

    var currentMilestones = milestones;
    currentMilestones.push(newMilestone);

    currentMilestones.sort(
      (a: ChangeManagementMilestone, b: ChangeManagementMilestone) => {
        return a.dueDate.getTime() - b.dueDate.getTime();
      }
    );

    setMilestones([...currentMilestones]);
  };

  const newMilestoneTaskAdded = (
    milestone: ChangeManagementMilestone,
    description: string
  ) => {
    var currentMilestones = milestones;
    var milestoneIndex = currentMilestones.indexOf(milestone);

    var newMilestoneTask: ChangeManagementMilestoneTask = {
      description,
      id: EmptyGuid(),
      changeManagementItemMilestoneId: EmptyGuid(),
      checkPerformedOn: undefined,
    };

    currentMilestones[milestoneIndex].tasks.push(newMilestoneTask);
    setMilestones([...currentMilestones]);
  };

  const newMilestoneTaskRemoved = (
    milestone: ChangeManagementMilestone,
    task: ChangeManagementMilestoneTask
  ) => {
    var currentMilestones = milestones;
    var milestoneIndex = currentMilestones.indexOf(milestone);
    var milestoneTaskIndex =
      currentMilestones[milestoneIndex].tasks.indexOf(task);
    currentMilestones[milestoneIndex].tasks.splice(milestoneTaskIndex, 1);
    setMilestones([...currentMilestones]);
  };

  const submitPlan = async () => {
    setCreatingCMItem(true)
    let command: CreateChangeManagementItemCommand = {
      description: details.description,
      assignedEngineerId: ticket?.assignedEngineerId
        ? ticket?.assignedEngineerId
        : null,
      assignedEngineerName: ticket?.assignedEngineerName!,
      milestones: milestones,
      title: details.title,
      plans: plans,
    };
    const response = await dispatch(createChangeManagementItem({ ticketId: ticket?.id || "", command }));
    if(response.payload){
      setCreatedItemId(response.payload.id)
      setshowRaisedNotification(true);
      setCreatingCMItem(false)
    }
  };

  const { loading } = useAsyncEffect(async () => {
    await Promise.all([
      dispatch(fetchAllUsers({})),
      dispatch(fetchChangeManagementTemplates()),
    ]);
  }, []);

  return (
    <>
      {showRaisedNotification === true ? (
         <NotifyBannerSmall
         secondsDuration={20}
         message="A new Change Management Item has been raised."
         showNotification={showRaisedNotification}
         createdId={createdItemId}
         navigatePage='changemanagement'
         linkText='View Change Management Item'
         severity='success'
       />
      ) : (
        <></>
      )}

      <Mui.Grid container spacing={2}>
        <Mui.Grid item xs={12} md={4}>
          <Steps
            currentStep={currentStep}
            stepsCompleted={stepsComplete}
            onSubmitPlan={submitPlan}
            creatingCMItem={creatingCMItem}
          />
        </Mui.Grid>
        <Mui.Grid item xs={12} md={8}>
          {!loading ? (
            <Mui.Stack direction={"column"} spacing={1}>
              <SelectTicket
                stepNumber={0}
                onTicketSelected={setTicket}
                currentStep={currentStep}
                onStepComplete={onStepComplete}
                onEditStep={onEditStep}
                isComplete={stepsComplete[0]}
                ticket={ticket}
              />

              <AddDetails
                onSubmitDetails={submitDetails}
                stepNumber={1}
                currentStep={currentStep}
                onStepComplete={onStepComplete}
                onEditStep={onEditStep}
                isComplete={stepsComplete[1]}
              />

              <AddPlan
                plans={plans}
                users={users}
                onNewPlanAdded={newPlanAdded}
                onPlanCheckAdded={onPlanCheckAdded}
                onPlanCheckRemoved={onPlanCheckRemoved}
                onPlanRemoved={onPlanRemoved}
                onUserAssigned={handleUserAssigned}
                stepNumber={2}
                currentStep={currentStep}
                onStepComplete={onStepComplete}
                onEditStep={onEditStep}
                isComplete={stepsComplete[2]}
                templates={templates}
              />

              <AddMilestone
                milestones={milestones}
                users={users}
                onNewMilestoneAdded={newMilestoneAdded}
                onNewMilestoneTaskAdded={newMilestoneTaskAdded}
                onMilestoneTaskRemoved={newMilestoneTaskRemoved}
                stepNumber={3}
                currentStep={currentStep}
                onStepComplete={onStepComplete}
                onEditStep={onEditStep}
                isComplete={stepsComplete[3]}
              />
            </Mui.Stack>
          ) : (
            <>Loading users, please wait...</>
          )}
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};
