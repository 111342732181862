import { Box } from "@mui/material"
import { TicketProblemCategoryCount } from "./TicketProblemCategoryCount"
import { TicketStatusPerDay } from "./TicketStatusPerDay";
import { useAppSelector } from "../../../../redux/hooks";
import { getTicketAssignedEngineer, getTicketProblemCategoryCount, getTicketRaisedBy, getTicketstatusPerDay } from "../../../../redux/Slices/tickets.slice";
import { TicketRaisedBy } from "./TicketRaisedBy";
import { TicketsAssignedEngineer } from "./TicketsAssignedEngineer";






export const TicketReportsTab = () => {
    const ticketProblemCategoryCount = useAppSelector(getTicketProblemCategoryCount);
    const ticketStatusPerDay = useAppSelector(getTicketstatusPerDay)
    const ticketRaisedBy = useAppSelector(getTicketRaisedBy)
    const ticketAssignedEngineer = useAppSelector(getTicketAssignedEngineer)

    return (
        <Box>
            <TicketProblemCategoryCount
                ticketProblemCategoryCount={ticketProblemCategoryCount}
            />
            <TicketStatusPerDay
                ticketStatusPerDay={ticketStatusPerDay}
            />
            <TicketRaisedBy
                ticketRaisedBy={ticketRaisedBy}
            />
            <TicketsAssignedEngineer
                ticketAssignedEngineer={ticketAssignedEngineer}
            />
        </Box>
    )
}