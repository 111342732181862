import { Alert, AlertsFilter } from "../../types/alerts.types";
import { useEffect, useMemo, useState } from "react";
import {
  fetchActiveAlerts,
  fetchSearchAlerts,
} from "../../redux/Thunks/alerts";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAsyncEffect } from "../../common/hooks";
import {
  getAlertsPaginatedCount,
  getAlertsWithPagination,
} from "../../redux/Slices/alerts.slice";
import { faStar } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Autocomplete,
  Paper,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TextField,
  TableContainer,
  Table,
  Typography,
  Button,
  ButtonGroup,
  Stack,
  Tooltip,
  Grid,
  TableHead,
  TableSortLabel,
  Box,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import "./AlertsEnhancedTable.css";
// import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { HarveyModal } from "../../components/HarveyChatModal/HarveyModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { FormattedDateTimeMilitary } from "../../common/dateUtils";
import { getDevices } from "../../redux/Slices/devices.slice";
import { fetchDevices } from "../../redux/Thunks/devices";
import exportFromJSON from "export-from-json";
import { getUserById } from "../../redux/Slices/account.slice";
import { useIdentityContext } from "../../context/IdentityContext";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Alert;
  label: string;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: "createdDate",
    numeric: true,
    disablePadding: true,
    label: "Created",
  },
  {
    id: "categoryDescription",
    numeric: true,
    disablePadding: true,
    label: "Category",
  },
  {
    id: "deviceName",
    numeric: true,
    disablePadding: true,
    label: "Device",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Alert",
  },
  {
    id: "lastModifiedDate",
    numeric: true,
    disablePadding: false,
    label: "Last Modified",
  },
  {
    id: "repeatCount",
    numeric: true,
    disablePadding: false,
    label: "Repeat",
  },
  {
    id: "priorityDescription",
    numeric: true,
    disablePadding: false,
    label: "Priority",
  },
  {
    id: "investigate",
    numeric: true,
    disablePadding: false,
    label: "Investigate",
  },
  {
    id: "harvey",
    numeric: true,
    disablePadding: false,
    label: "Ask Harvey",
  },
];

const removeEmpty = (obj: any) => {
  return JSON.parse(JSON.stringify(obj));
};

let refreshInterval: NodeJS.Timer | any = setTimeout(() => {}, 1);
export interface NewAlertsTableProps {}

const NewAlertsTable = (props: NewAlertsTableProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const identity = useIdentityContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const deviceId = searchParams.get("deviceId") || undefined;
  const devices = useAppSelector(getDevices);
  const alerts = useAppSelector(getAlertsWithPagination);
  const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
  const alertsCount = useAppSelector(getAlertsPaginatedCount);
  const paramPage = searchParams.get("page");
  const searchPage = paramPage ? parseInt(paramPage) : NaN;
  const [openHarvey, setOpenHarvey] = React.useState(false);
  const closeHarvey = () => setOpenHarvey(false);
  const [harveyAlert, setHarveyAlert] = React.useState({});
  const [pageNumber, setPageNumber] = useState(
    !isNaN(searchPage) ? searchPage : 1
  );
  const [pageSize, setPageSize] = useState(25);
  const [sortBy, setSortBy] = useState<keyof Alert>("createdDate");
  const [sortOrder, setSortOrder] = useState<"ascending" | "descending" | "">(
    "descending"
  );
  const [filter, setFilter] = useState<AlertsFilter>({});
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [searchDeviceName, setSearchDeviceName] = React.useState<string>("");

  const rolesArray = user?.roles.split(",");
  const isInvestigateUser = rolesArray?.includes("Investigate User");

  const devicesOptions = useMemo(() => {
    return devices.map((device) => ({
      label: device.deviceName,
      value: device.id,
    }));
  }, [devices]);

  useEffect(() => {
    if (devices.some((device) => device.id === deviceId)) {
      setSearchDeviceName(
        devices.find((device) => device.id === deviceId)?.deviceName || ""
      );
    }
  }, [devices, deviceId]);

  console.debug(deviceId, filter);

  useEffect(() => {
    setFilter((state) =>
      removeEmpty({
        ...state,
        deviceId: deviceId,
      })
    );
  }, [deviceId]);

  const { loading } = useAsyncEffect(async () => {
    const sortString = `${sortBy} ${
      sortOrder !== "" ? sortOrder : "descending"
    }`;
    clearInterval(refreshInterval);
    await Promise.all([
      dispatch(fetchDevices()),
      dispatch(fetchActiveAlerts()),
      dispatch(
        fetchSearchAlerts({
          pageNumber,
          pageSize,
          sortBy: sortString,
          filter,
        })
      ),
    ]);
    refreshInterval = setInterval(() => {
      Promise.all([
        dispatch(fetchActiveAlerts()),
        dispatch(
          fetchSearchAlerts({
            pageNumber,
            pageSize,
            sortBy: sortString,
            filter,
          })
        ),
      ]).then(() => setLastUpdated(new Date()));
    }, 3 * 60000);
  }, [pageNumber, pageSize, sortBy, sortOrder, filter]);

  const onClickExport = (data: any) => {
    const fileName = "AlertsTable";
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data, fileName, exportType });
  };

  const createSortHandler =
    (property: keyof Alert) => (event: React.MouseEvent<unknown>) => {
      setSortBy(property);
      setSortOrder(
        sortOrder
          ? sortOrder === "descending"
            ? "ascending"
            : "descending"
          : "descending"
      );
    };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPageNumber(newPage);
    setSearchParams((prev) => ({ ...prev, page: newPage.toString() }));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageNumber(1);
  };

  const isFiltering = useMemo(
    () => Object.values(filter).filter((key) => !!key).length > 0,
    [filter]
  );

  return (
    <>
      <HarveyModal
        openHarvey={openHarvey}
        closeHarvey={closeHarvey}
        topic={harveyAlert}
        type={"alert"}
      />
      {/* <Card> */}
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Typography sx={{ pt: 0 }}>
            {`Last Updated: ${lastUpdated.toLocaleTimeString()}`}
          </Typography>
        </Grid>
        {/**<Grid item xs={1.5}>
          <Autocomplete
            disabled={searchDeviceName.length > 0}
            disablePortal
            id="alert-description-autocomplete"
            options={alertDescriptionOptions}
            size="small"
            sx={{ width: 175, display: "inline-block", pb: 2 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Alerts"
                sx={{ mt: 2 }}
                value={searchAlertDescription}
                onChange={(event) =>
                  handleAlertDescriptionSearch(event.target.value || "")
                }
              />
            )}
            onChange={(_event, value: any) =>
              handleAlertDescriptionSearch(value || "")
            }
            value={searchAlertDescription!}
            PaperComponent={({ children }) => (
              <Paper style={{ background: "#efefef" }}>{children}</Paper>
            )}
          />
            </Grid>*/}
        <Grid item xs={1.5}>
          <Autocomplete
            //disabled={searchDeviceName.length > 0}
            disablePortal
            id="device-name-autocomplete"
            options={devicesOptions}
            size="small"
            sx={{ width: 175, display: "inline-block" }}
            value={devicesOptions.find(
              (option) => option.value === searchDeviceName
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Devices"
                sx={{ mt: 2, ml: 3 }}
                inputProps={{ ...params.inputProps, value: searchDeviceName }}
              />
            )}
            onBlur={() => {
              const deviceName = devices.find(
                (device) => device.id === deviceId
              )?.deviceName;
              if (searchDeviceName !== deviceName && deviceName) {
                setSearchDeviceName(deviceName);
              }
            }}
            onInputChange={(event, newInputValue) => {
              if (newInputValue === "") {
                setSearchParams((prev) =>
                  removeEmpty({ ...prev, deviceId: undefined })
                );
              }
              setSearchDeviceName(newInputValue);
            }}
            onChange={(_event, value) =>
              setSearchParams((prev) =>
                removeEmpty({ ...prev, deviceId: value?.value })
              )
            }
            PaperComponent={({ children }) => (
              <Paper style={{ background: "#efefef" }}>{children}</Paper>
            )}
          />
        </Grid>
        <Grid item xs={7}></Grid>
        <Grid
          item
          xs={2}
          sx={{ display: "flex", justifyContent: "flex-end", pr: 0 }}
        >
          {isFiltering ? (
            <ButtonGroup sx={{ pb: 1.5, pt: 1.5 }}>
              <Button
                onClick={() => {
                  setFilter({});
                  setPageNumber(1);
                }}
                sx={{ mt: 2 }}
              >
                Remove Filter
              </Button>
              <Tooltip title="Export to Excel">
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => onClickExport(alerts)}
                >
                  Export
                </Button>
              </Tooltip>
            </ButtonGroup>
          ) : (
            <ButtonGroup sx={{ pb: 1.5, pt: 1.5 }}>
              <Tooltip title="Export to Excel">
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  onClick={() => onClickExport(alerts)}
                >
                  Export
                </Button>
              </Tooltip>
            </ButtonGroup>
          )}
        </Grid>
      </Grid>
      {/* </Card> */}

      <Card>
        {/* <CardContent> */}
        <TableContainer
          component={Paper}
          sx={{
            borderStyle: "solid",
            borderColor: "#ccc",
            borderWidth: "1px",
          }}
        >
          <Table
            stickyHeader
            sx={{ minWidth: 650 }}
            size="small"
            aria-label="alerts"
          >
            <TableHead>
              {headCells.map((cell) => (
                <TableCell>
                  <TableSortLabel
                    sx={{ fontWeight: "bold" }}
                    active={sortBy === cell.id}
                    direction={
                      sortBy === cell.id
                        ? sortOrder === "ascending"
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={createSortHandler(cell.id)}
                  >
                    {cell.label}
                    {sortBy === cell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sortOrder === "descending"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableHead>

            {loading ? (
              <Loader show={true} />
            ) : (
              <TableBody>
                {alerts.map((row, _index) => {
                  return (
                    <TableRow tabIndex={-1} key={row.id}>
                      <TableCell align="left">
                        <Typography>
                          {FormattedDateTimeMilitary(new Date(row.createdDate))}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        {row.categoryDescription}
                      </TableCell>
                      <TableCell align="left">
                        {row.deviceName !== "" ? (
                          <Button
                            sx={{ textAlign: "left" }}
                            variant="text"
                            onClick={() => {
                              navigate(
                                `/device/${row.deviceId}/${row.deviceGroup}`
                              );
                            }}
                          >
                            {row.deviceName}
                          </Button>
                        ) : (
                          <></>
                        )}
                        {/* <Link
                        to={`/device/${row.deviceId}/${row.deviceGroup}`}
                      >
                        {row.deviceName}
                      </Link> */}
                      </TableCell>
                      <TableCell align="left">
                        {row.description !== "" ? (
                          <Button
                            sx={{ textAlign: "left" }}
                            variant="text"
                            onClick={() => {
                              navigate(`/alert/${row.id}`);
                            }}
                          >
                            {row.description}
                          </Button>
                        ) : (
                          <></>
                        )}
                        {/* <Link to={`/alert/${row.id}`}>{row.description}</Link> */}
                      </TableCell>
                      <TableCell align="left">
                        {FormattedDateTimeMilitary(
                          new Date(row.lastModifiedDate)
                        )}
                      </TableCell>
                      <TableCell align="left">{row.repeatCount}</TableCell>

                      <TableCell align="left">
                        {row.priorityDescription === "High" ? (
                          <>
                            <FontAwesomeIcon
                              icon={faStar}
                              color="red"
                              size="sm"
                            />
                            &nbsp;{row.priorityDescription}
                          </>
                        ) : (
                          <>{row.priorityDescription}</>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {isInvestigateUser ? (
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-start"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                navigate(`/investigate/${row.id}`);
                              }}
                            >
                              Investigate
                            </Button>
                          </Stack>
                        ) : (
                          <></>
                        )}
                      </TableCell>

                      <TableCell align="left">
                        <Stack
                          direction="row"
                          spacing={1}
                          justifyContent="flex-start"
                        >
                          <Button
                            variant="outlined"
                            onClick={() => {
                              setOpenHarvey(!openHarvey);
                              setHarveyAlert(row);
                            }}
                          >
                            Ask Harvey
                          </Button>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={alertsCount}
            rowsPerPage={pageSize}
            page={pageNumber}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
        {/* </CardContent> */}
      </Card>
    </>
  );
};

export default NewAlertsTable;
