import { faBellSlash, faChartColumn } from "@fortawesome/pro-light-svg-icons";
import * as Mui from "@mui/material";

import { Loader } from "../../components/Loader";
import { NoData } from "../../components/NoData";
// import { AlertsEnhancedTable } from "./AlertsEnhancedTable/AlertsEnhancedTable";
import "./AlertsDashboardPage.css";
import { AlertTableSummary } from "./AlertTableSummary/AlertTableSummary";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  // getAlertPriority,
  getAlertsWithPagination,
  getAlertTablePage,
  setAlertTablePage,
} from "../../redux/Slices/alerts.slice";
import { useAsyncEffect } from "../../common/hooks";
import {
  fetchAlertOverrides,
  // fetchActiveAlerts,
  fetchAlertWithPagination,
  fetchTotalActiveAlerts,
} from "../../redux/Thunks/alerts";
import { useEffect, useState } from "react";
import { setLayout } from "../../redux/Slices/layout.slice";
import { useSearchParams } from "react-router-dom";
import { fetchDevices } from "../../redux/Thunks/devices";
import { AlertsTabPanelHeader } from "./AlertsTabPanelHeader";
let refreshInterval: NodeJS.Timer | any  = setTimeout(() => {}, 1);

export const AlertsDashboardPage = () => {
  const dispatch = useAppDispatch();
  const alertsWithPagination = useAppSelector(getAlertsWithPagination);
  const alertTablePage = useAppSelector(getAlertTablePage);
  const [searchParams] = useSearchParams();
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const page = searchParams.get("page");
  // const loadingPriority = useAsyncEffect(async () => {
  //   await dispatch(fetchActiveAlerts());
  // }, []).loading;
  // const alertPriority = useAppSelector(getAlertPriority);

  //console.debug(page);
  //console.debug(alertTablePage);

  useEffect(() => {
    if (page && !isNaN(parseInt(page)))
      dispatch(setAlertTablePage(parseInt(page)));
  }, [page, dispatch]);

  const { loading } = useAsyncEffect(async () => {
    clearInterval(refreshInterval);
    await dispatch(fetchTotalActiveAlerts());
    await dispatch(
      fetchAlertWithPagination({
        pageSize: 25, // currently dont have option to include anything but 100 per page
        pageNumber: alertTablePage,
      })
    );
    await dispatch(fetchDevices());
    await dispatch(fetchAlertOverrides());

    refreshInterval = setInterval(() => {
      dispatch(fetchTotalActiveAlerts());
      dispatch(
        fetchAlertWithPagination({
          pageSize: 25, // currently dont have option to include anything but 100 per page
          pageNumber: alertTablePage,
        })
      );
      setLastUpdated(new Date());
    }, 3 * 60000); //Refresh alerts every 3 minutes
  }, [alertTablePage]); //change with page number or number per page

  useEffect(() => {
    dispatch(
      setLayout({
        title: "Alerts Dashboard",
        subtitle: "Alerts Dashboard",
        icon: faChartColumn,
      })
    );
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Loader show={true} />
      ) : alertsWithPagination.length < 1 ? (
        <NoData
          icon={faBellSlash}
          headerText="No active incidents found"
          subText="We couldn`t find any incidents at present (unless this is a new install, raise a support ticket.)"
        />
      ) : (
        <>
         <AlertTableSummary />
          <Mui.Grid item xs={12} sx={{ height: "auto", marginTop: 2 }}>
              <AlertsTabPanelHeader 
                  alerts={alertsWithPagination}
                  lastUpdated={lastUpdated}
              />
          </Mui.Grid>
        </>
      )}
    </>
  );
};
