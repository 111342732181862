import React from "react";
import * as Mui from "@mui/material";

import { FormattedDate } from "../../common/dateUtils";
import {
  ChangeManagementMilestone,
  ChangeManagementMilestoneTask,
} from "../../types/changemanagement.types";

const showManualCheckButton = (
  task: ChangeManagementMilestoneTask,
  isReadOnly: boolean
) => {
  return task.checkPerformedOn === null && !isReadOnly;
};

interface MilestoneTableProps {
  milestone: ChangeManagementMilestone;
  showTodoOnly: boolean;
  onMarkMilestoneAsDone: (taskId: string) => void;
  isReadOnly: boolean;
}

export const MilestoneTable: React.FC<MilestoneTableProps> = (props) => {
  return (
    <Mui.TableContainer
      component={Mui.Paper}
      sx={{ borderStyle: "solid", borderColor: "#ccc", borderWidth: "1px" }}
    >
      <Mui.Table
        stickyHeader
        sx={{ minWidth: 650 }}
        padding="normal"
        size="small"
        aria-label="recent tickets"
      >
        <Mui.TableHead>
          <Mui.TableRow sx={{ backgroundColor: "red" }}>
            <Mui.TableCell>Task description</Mui.TableCell>
            <Mui.TableCell>Task completed</Mui.TableCell>
            <Mui.TableCell></Mui.TableCell>
          </Mui.TableRow>
        </Mui.TableHead>
        <Mui.TableBody>
          {props.milestone.tasks.map((task) => (
            <Mui.TableRow
              key={task.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <Mui.TableCell component="th" scope="row">
                {task.description}
              </Mui.TableCell>
              <Mui.TableCell component="th" scope="row">
                {task.checkPerformedOn ? (
                  FormattedDate(task.checkPerformedOn)
                ) : (
                  <>-</>
                )}
              </Mui.TableCell>
              <Mui.TableCell component="th" scope="row" align="right">
                {showManualCheckButton(task, props.isReadOnly) ? (
                  <Mui.Button
                    sx={{ minWidth: "160px" }}
                    variant="outlined"
                    onClick={() => {
                      props.onMarkMilestoneAsDone(task.id);
                    }}
                  >
                    Mark as done
                  </Mui.Button>
                ) : (
                  <></>
                )}
              </Mui.TableCell>
            </Mui.TableRow>
          ))}
        </Mui.TableBody>
      </Mui.Table>
    </Mui.TableContainer>
  );
};
