import React, { useEffect } from "react";
import { faGear } from "@fortawesome/pro-light-svg-icons";
import { useIdentityContext } from "../../context/IdentityContext";
import { Loader } from "../../components/Loader";
import { CompanyEdit } from "../CompanyAdminDashboard/CompanyEdit";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getCompanyById } from "../../redux/Slices/company.slice";
import { useAsyncEffect } from "../../common/hooks";
import { fetchCompanies, fetchCompanyEmailConfig, fetchCompanySentinelConfig, fetchCompanySettings } from "../../redux/Thunks/company";
import { getSelectedCompanyId } from "../../redux/Slices/account.slice";
import { fetchAllUsers, fetchAuthenticator } from "../../redux/Thunks/account";
import { setLayout } from "../../redux/Slices/layout.slice";
import { PersonalInformation } from "./SettingsPageComponents/PersonalInformation";
import { OrganizationInformation } from "./SettingsPageComponents/OrganizationInformation";
import { CompanyManagement } from "./SettingsPageComponents/CompanyManagement";
import { EmailConfiguration } from "./SettingsPageComponents/EmailConfiguration";
import { Grid } from "@mui/material";
const SettingsPage = () => {
  const { authenticatedUser } = useIdentityContext();
  const dispatch = useAppDispatch();
  const selectedCompanyId = useAppSelector(getSelectedCompanyId);
  const company = useAppSelector(getCompanyById(selectedCompanyId || authenticatedUser.companyId));

  useEffect(() => {
    let subtitle = "";
    if (company && company.companyName) {
      subtitle = `Settings for ${company.companyName}`;
    } else {
      subtitle = "Settings";
    }
    dispatch(
      setLayout({
        title: `Settings`,
        subtitle: subtitle,
        icon: faGear,
      })
    );
  }, [dispatch]);

  const { loading } = useAsyncEffect(async () => {
    await Promise.all([
      dispatch(fetchCompanies()),
      dispatch(fetchAllUsers({})),
      dispatch(fetchCompanySentinelConfig(company?.id || "")),
      dispatch(fetchCompanyEmailConfig(company?.id || "")),
      dispatch(fetchCompanySettings(company?.id || "")),
      dispatch(fetchAuthenticator()),
    ]);
  }, [authenticatedUser]);


  return (
    <>
      {loading ? (
        <Loader show={true} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <PersonalInformation />
          </Grid>

          <Grid item xs={12} lg={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <OrganizationInformation />
          </Grid>

          <Grid item xs={12} lg={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <CompanyManagement />
          </Grid>


          <Grid item xs={12} lg={6}>
            {!loading && company && company.canManageCompany ? (
              <CompanyEdit company={company} />
            ) : (
              <Loader show={true} />
            )}
          </Grid>

          <Grid item xs={12} lg={6} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <EmailConfiguration />
          </Grid>


        </Grid>
      )}
    </>
  );
};

export { SettingsPage };
