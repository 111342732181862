import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ChangeManagementItemComment,
  ChangeManagementItemSummary,
  ChangeManagementMilestone,
  ChangeManagementPlan,
  ChangeManagementTemplate,
} from "../../types/changemanagement.types";
import { RootState } from "../store";

export interface ChangeManagementState {
  templates: Array<ChangeManagementTemplate>;
  item?: ChangeManagementItemSummary;
  summaries: Array<ChangeManagementItemSummary>;
  comments: Array<ChangeManagementItemComment>;
  plans: Array<ChangeManagementPlan>;
  milestones: Array<ChangeManagementMilestone>;
}

const initialState: ChangeManagementState = {
  templates: [],
  summaries: [],
  comments: [],
  plans: [],
  milestones: [],
};

const changeManagementSlice = createSlice({
  name: "changeManagement",
  initialState,
  reducers: {
    setChangeManagementItem: (
      state,
      action: PayloadAction<ChangeManagementItemSummary>
    ) => {
      state.item = action.payload;
    },
    setTemplates: (
      state,
      action: PayloadAction<ChangeManagementTemplate[]>
    ) => {
      state.templates = action.payload;
    },
    setSummaries: (
      state,
      action: PayloadAction<ChangeManagementItemSummary[]>
    ) => {
      state.summaries = action.payload;
    },
    setComments: (
      state,
      action: PayloadAction<ChangeManagementItemComment[]>
    ) => {
      state.comments = action.payload;
    },
    setPlans: (state, action: PayloadAction<ChangeManagementPlan[]>) => {
      state.plans = action.payload;
    },
    setMilestones: (
      state,
      action: PayloadAction<ChangeManagementMilestone[]>
    ) => {
      state.milestones = action.payload;
    },
  },
});

export const {
  setChangeManagementItem,
  setTemplates,
  setSummaries,
  setComments,
  setPlans,
  setMilestones,
} = changeManagementSlice.actions;

export const getChangeManagementItem = (state: RootState) =>
  state.changeManagement.item;
export const getTemplates = (state: RootState) =>
  state.changeManagement.templates;
export const getSummaries = (state: RootState) =>
  state.changeManagement.summaries;
export const getComments = (state: RootState) =>
  state.changeManagement.comments;
export const getPlans = (state: RootState) => state.changeManagement.plans;
export const getMilestones = (state: RootState) =>
  state.changeManagement.milestones;

export default changeManagementSlice.reducer;
