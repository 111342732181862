
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { faBellExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export default function AlertErrorsVsWarnings(props) {
    const { errors, warnings, isLayoutChanged } = props
    const chartComponent = useRef({});
    const [noData, setNoData] = useState(false) 
    const [errorPercentage, setErrorPercentage] = useState(0)

    const getPercentage = (errors, warnings) => {
        return +(((errors) / (errors + warnings) * 100).toFixed(2))
    }
    const determineNoData = () => {
        if(warnings === 0 && errors === 0 ){
         setNoData(true)
        }
     }
    useEffect(() => {
        determineNoData()
        if (errors && warnings) {
            setErrorPercentage(getPercentage(errors, warnings))
        }
    }, [warnings || errors]);
   
    const pieChartData = [
       
        errors !== 0 ?
            {
                name: "Errors",
                y: errors,
                color: 'rgb(255,0,0, 0.4)',
                // borderColor: 'rgb(255,0,0)',
            } : '',
            warnings !== 0 ?
            {
                name: "Warning",
                y: warnings,
                color: 'rgb(232,143,0, 0.4)',
                // borderColor: 'rgb(232,143,0)',
            } : '']

    const options = {
        chart: {

            type: 'pie',
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: 100,
            labelFormatter: function () {
                if(this.y){
                    return this.name;
                }
                if(!this.y){
                    return 'No Values'
                }
            }
        },
        credits: {
            enabled: false
        },

        title: {
            text:  noData ? 'No Data Available' : 'Errors vs Warnings',
        },

        subtitle: {
            text: noData ? '' : `${errors} Alerts (${errorPercentage}%) in Error State`
        },

        tooltip: {
            pointFormat: ' {point.y} alerts'
        },

        plotOptions: {
            pie: {
                borderRadius: 25,
                borderWidth: 5,
                dataLabels: {
                    enabled: true,
                    distance: '-30%',
                    format: '{point.y} {point.name}',

                },
                startAngle: -90,
                endAngle: 90,
                center: ['35%', '90%'],
                size: '135%',
                showInLegend: true
            }
        },
        series: [{
            name: "Total",
            innerSize: '50%',
            data: pieChartData,
        }],
    }

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [isLayoutChanged]);
    return (
        <Card sx={{ border: "solid 1px #ddd", height: '100%', padding: 1 }}>

            <FontAwesomeIcon
                icon={faBellExclamation}
                color={'#e88f00'}
                size={'lg'}
                style={{ position: 'absolute', zIndex: 1 }}
            />
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />
        </Card>

    );
}