
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import { faBellOn } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function CreatedAlertsByDay(props) {
    const { totals, isLayoutChanged } = props
    const chartComponent = useRef({});
    const [createdToday, setCreatedToday] = useState(0)

    const incrementCreatedToday = (totals) => {
        let closed = 0
        const today = dayjs().format('MMM DD YY')
        totals.map((data) => {
            if (dayjs(data.date).format('MMM DD YY') === today) {
                closed = data.total
            }
        })
        return closed
    }

    useEffect(() => {
        setCreatedToday(incrementCreatedToday(totals))
    }, [totals]);


    const determineSeriesData = (data) => {
        let dataArray = []
        data.map((data) => {
            dataArray.push(data.total)
        })
        dataArray.reverse()
        return dataArray;
    }
    const determineTimeData = (data) => {
        let timeArray = []
        data.map((data) => {
            timeArray.push(dayjs(data.date).format('MM/DD/YY'))
        })
        timeArray.reverse()
        return timeArray
    }
    const seriesData = determineSeriesData(totals)
    const timeData = determineTimeData(totals)


    const options = {
        chart: {
            type: 'column'
        },

        credits: {
            enabled: false
        },
        title: {
            text: 'Alerts Created By Day',
        },
        subtitle: {
            text: `${createdToday} Created Today`
        },
        xAxis: {
            title: undefined,
            // tickInterval: 5,
            categories: timeData,
        },
        yAxis: {
            title: {
                text: undefined
            },
        },
          plotOptions: {
            column: {
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#666',
            }
        },
        tooltip: {
            pointFormat: '{point.y} alerts'
        },
        series: [
            {
                showInLegend: false,
                name: undefined,
                data: seriesData,
                color: 'rgb(232,143,0, 0.9)'
            }
        ],


    }
    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [isLayoutChanged]);
    return (
        <Card sx={{ border: "solid 1px #ddd", height: '100%', padding: 1 }}>
            <FontAwesomeIcon
                icon={faBellOn}
                color={'#e88f00'}
                size={'lg'}
                style={{ position: 'absolute', zIndex: 1 }}
            />
            <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />

        </Card>

    );
}