import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  Grid,
  Typography,
} from "@mui/material";

export interface ConfirmationDialogProps extends DialogProps {
  content?: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const { title, content, onConfirm, onCancel } = props;
  // return (
  // <Dialog {...props}>
  //   <DialogTitle>{title}</DialogTitle>
  //   {content && <DialogContent>{content}</DialogContent>}
  //   <DialogActions>
  //     <Button variant="outlined" color="primary" onClick={onConfirm}>
  //       Confirm
  //     </Button>
  //     <Button onClick={onCancel}>Cancel</Button>
  //   </DialogActions>
  // </Dialog>
  // );

  return (
    <Dialog {...props} >
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ py: 3 }}>
            <FontAwesomeIcon
              size="2x"
              color="orange"
              icon={faTriangleExclamation}
            />
          </Box>
          <Typography variant='h3' sx={{ py: 3, pl: 2 }}>{title}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center' }}>
          <Typography>{content}</Typography>
        </Grid>
        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
          <DialogActions>
            <Button variant="outlined" color="primary" onClick={onConfirm}>
              Confirm
            </Button>
            <Button onClick={onCancel}>Cancel</Button>
          </DialogActions>

        </Grid>

      </Grid>
    </Dialog>
  )
};

export default ConfirmationDialog;




