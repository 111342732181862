import * as React from "react";

import {
  Badge,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDoubleRight,
  faChevronDoubleLeft,
  faDashboard,
  faTicket,
  faBell,
  faLaptop,
  faBallotCheck,
  faUsers,
  faArrowRightFromBracket,
  faCartShopping,
  // faBellSlash,
  faChartSimple,
  // faFileUser,
  faCog,
  faBinoculars,
  faFireFlameCurved,
  faMap,
  faFileExport,
} from "@fortawesome/pro-light-svg-icons";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import { NavLink } from "react-router-dom";
import { useIdentityContext } from "../../context/IdentityContext";
import { Header } from "./Header";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  setSidebarToggle,
  getUserRoles,
} from "../../redux/Slices/account.slice";
import { useAsyncEffect } from "../../common/hooks";
import { fetchAllUsers } from "../../redux/Thunks/account";
import { ReactNode, useEffect, useState } from "react";
interface NavigationSidebarProps {
  title: string;
  appBarComponent?: ReactNode;
}
const drawerWidth = 180;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(8)} + 1px)`,
    width: "60px",
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const portalPages = [
  {
    displayName: "Dashboard",
    link: "home",
    icon: faDashboard,
    roleRequired: ["Client", "Company Admin"],
  },
  {
    displayName: "Incidents",
    link: "alerts",
    icon: faBell,
    roleRequired: ["Alerts Viewer", "Company Admin"],
  },
  {
    displayName: "Dark Web",
    link: "darkweb",
    icon: faBinoculars,
    roleRequired: ["Dark Web User", "Company Admin"],
  },
  {
    displayName: "Firewall",
    link: "investigatefirewall",
    icon: faFireFlameCurved,
    roleRequired: ["Firewall Admin", "Company Admin"],
  },
  {
    displayName: "Devices",
    link: "devices",
    icon: faLaptop,
    roleRequired: ["Devices User", "Company Admin"],
  },
  {
    displayName: "Maps",
    link: "livemap",
    icon: faMap,
    roleRequired: ["Map Admin", "Company Admin"],
  },
  {
    displayName: "Tickets",
    link: "tickets",
    icon: faTicket,
    roleRequired: ["End User", "Company Admin"],
  },
  {
    displayName: "Change Mgmt",
    link: "changemanagement",
    icon: faBallotCheck,
    roleRequired: ["Client", "Company Admin"],
  },
  {
    displayName: "Charts",
    link: "charts",
    icon: faChartSimple,
    roleRequired: ["Charts Viewer", "Company Admin"],
  },
  {
    displayName: "Reports",
    link: "reports",
    icon: faFileExport,
    roleRequired: ["Reports Viewer", "Company Admin"],
  },
  {
    displayName: "Users",
    link: "users",
    icon: faUsers,
    roleRequired: ["Company Admin"],
  },
  {
    displayName: "Products",
    link: "account",
    icon: faCartShopping,
    roleRequired: ["Products Admin", "Company Admin"],
  },
  {
    displayName: "Settings",
    link: "settings",
    icon: faCog,
    roleRequired: ["End User", 'Setting Administrator', "Company Admin"],
  },
];

export default function NavigationSidebar(props: NavigationSidebarProps) {
  const identity = useIdentityContext();
  const dispatch = useAppDispatch();
  const drawerOpenKey = "drawerOpen";
  const defaultOpen = localStorage.getItem(drawerOpenKey) === "true";
  const [open, setOpen] = useState(defaultOpen);
  const [selected, setSelected] = useState("dashboard");

  const { loading } = useAsyncEffect(async () => {
    dispatch(fetchAllUsers({}));
  }, []);
  const userRolesArray: any = useAppSelector(
    getUserRoles(identity.authenticatedUser.userId)
  );
  // const filteredPages = portalPages.filter((page) =>
  //   userRolesArray?.includes(page.roleRequired)
  // );
  const determinePagesByRoles = () => {
    let pageArray: any[] = []
    portalPages.forEach((page: any) => {
      if (userRolesArray?.some((r: string) => page.roleRequired.indexOf(r) >= 0)) {
        pageArray.push(page)
      }
    })
    return pageArray

  }
  const filteredPages = determinePagesByRoles()

  useEffect(() => {
    localStorage.setItem(drawerOpenKey, open.toString());
    dispatch(setSidebarToggle(open));
  }, [open]);
  // useEffect(() => {
  //   filteredPages?.forEach((page: any) => {
  //     if (
  //       window.location.pathname.includes(`/${page.link}`) ||
  //       window.location.pathname.includes(`/${page.link}`.slice(0, -1))
  //     ) {
  //       setSelected(page.link);
  //     }
  //   });
  // }, [filteredPages]);
  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(setSidebarToggle(true));
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(setSidebarToggle(false));
  };

  return (
    <Box sx={{ display: "flex", position: "absolute" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Header title={props.title} appBarComponent={props.appBarComponent} />
      </AppBar>

      <Drawer variant="permanent" open={open} transitionDuration={0}>
        <Toolbar />
        <DrawerHeader>
          <IconButton>
            {open === false ? (
              <FontAwesomeIcon
                size="xs"
                icon={faChevronDoubleRight}
                color={"#f8931c"}
                onClick={handleDrawerOpen}
              />
            ) : (
              <FontAwesomeIcon
                size="xs"
                icon={faChevronDoubleLeft}
                color={"#f8931c"}
                onClick={handleDrawerClose}
              />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        {!loading ? (
          <List>
            <>
              {/* ALL PAGES */}
              {filteredPages.map((page) => {
                return (
                  <ListItem
                    key={page.link}
                    disablePadding
                    sx={
                      selected === page.link
                        ? {
                          display: "block",
                          borderLeft: "5px solid #002a4e",
                          backgroundColor: "rgba(0,42,78, 0.15)",
                        }
                        : { display: "block" }
                    }
                    onClick={() => {
                      setSelected(page.link);
                    }}
                  >
                    <NavLink
                      to={`/${page.link}`}
                      // title={`Go To ${page.displayName} Page`}
                      className={({ isActive }) =>
                        "header-link" + (isActive ? " active" : " ")
                      }
                      end
                    >
                      <ListItemButton
                        sx={{ minHeight: 45, justifyContent: "start" }}
                      >
                        <ListItemIcon
                          sx={{ minWidth: 0, mr: open ? 1 : "auto" }}
                        >
                          <Badge badgeContent={0} className="header-badge">
                            <FontAwesomeIcon
                              size="lg"
                              icon={page.icon}
                              color={"#f8931c"}
                              style={{ width: "18px", textAlign: "center" }}
                            />
                          </Badge>
                        </ListItemIcon>
                        {/* <Typography  sx={{ opacity: open ? 1 : 0, color: "#002a4e", display: "inline-block", whiteSpace: "pre-line"}}> */}
                        <Typography
                          sx={
                            open
                              ? {
                                opacity: open ? 1 : 0,
                                color: "#002a4e",
                                display: "inline-block",
                                whiteSpace: "pre-line",
                              }
                              : { opacity: open ? 1 : 0, color: "#002a4e" }
                          }
                        >
                          {page.displayName}
                        </Typography>
                      </ListItemButton>
                    </NavLink>
                  </ListItem>
                );
              })}
              <Divider />
              {/* LOG OUT */}
              <ListItem key={"logOut"} disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{ minHeight: 48, justifyContent: "start", px: 2.5 }}
                  onClick={() => {
                    identity.onLogout();
                  }}
                  title="Log Out"
                >
                  <Badge badgeContent={0} className="header-badge">
                    <FontAwesomeIcon
                      size="lg"
                      icon={faArrowRightFromBracket}
                      color={"#f8931c"}
                    />
                  </Badge>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "start",
                    }}
                  />
                  <ListItemText
                    primary={"Log Out"}
                    sx={{ opacity: open ? 1 : 0, color: "#002a4e" }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          </List>
        ) : (
          <></>
        )}
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box>
    </Box>
  );
}
