import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AccountPreferences,
  Role,
  UserStatistics,
  UserSummary,
} from "../../types/account.types";
import { RootState } from "../store";
import { determineIsUserEndUser } from "../../common/accountUtils";

interface Authentication {
  sharedKey: string;
  authenticatorUri: string;
}

export interface AccountState {
  users: Array<UserSummary>;
  roles: Array<Role>;
  redirectRoute: string;
  installScript: string;
  setUpProgress: boolean[];
  sidebarToggle: boolean;
  selectedCompanyId: string | null;
  preferences: AccountPreferences;
  userStatistics: Array<UserStatistics>;
  authenticator: Authentication;
}

const initialState: AccountState = {
  users: [],
  roles: [],
  redirectRoute: "",
  installScript: "",
  setUpProgress: Array(5).fill(false),
  sidebarToggle: false,
  selectedCompanyId: localStorage.getItem("selectedCompanyId") || null,
  preferences: {} as AccountPreferences,
  userStatistics: [],
  authenticator: { sharedKey: "", authenticatorUri: "" },
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<Array<UserSummary>>) => {
      state.users = action.payload;
    },
    setRoles: (state, action: PayloadAction<Array<Role>>) => {
      state.roles = action.payload;
    },
    setRedirectRoute: (state, action: PayloadAction<string>) => {
      state.redirectRoute = action.payload;
    },
    setInstallScript: (state, action: PayloadAction<string>) => {
      state.installScript = action.payload;
    },
    setSetUpProgress: (state, action: PayloadAction<boolean[]>) => {
      state.setUpProgress = action.payload;
    },
    setSidebarToggle: (state, action: PayloadAction<boolean>) => {
      state.sidebarToggle = action.payload;
    },
    setSelectedCompanyId: (state, action: PayloadAction<string | null>) => {
      if (action.payload)
        localStorage.setItem("selectedCompanyId", action.payload);
      else localStorage.removeItem("selectedCompanyId");
      state.selectedCompanyId = action.payload;
    },
    setAccountPreferences: (
      state,
      action: PayloadAction<AccountPreferences>
    ) => {
      state.preferences = action.payload;
    },
    setUserStatistics: (
      state,
      action: PayloadAction<Array<UserStatistics>>
    ) => {
      state.userStatistics = action.payload;
    },
    setAuthenticator: (state, action: PayloadAction<Authentication>) => {
      state.authenticator = action.payload;
    },
  },
});

export const {
  setUsers,
  setRoles,
  setRedirectRoute,
  setInstallScript,
  setSetUpProgress,
  setSidebarToggle,
  setSelectedCompanyId,
  setAccountPreferences,
  setUserStatistics,
  setAuthenticator,
} = accountSlice.actions;

export const getAccount = (state: RootState) => state.account;
export const getUsers = (state: RootState) => state.account.users;
export const getRoles = (state: RootState) => state.account.roles;
export const getUserById = (id: string) => (state: RootState) =>
  state.account.users.find((user) => user.id === id);
export const getRedirectRoute = (state: RootState) =>
  state.account.redirectRoute;
export const getInstallScript = (state: RootState) =>
  state.account.installScript;
export const getSetUpProgress = (state: RootState) =>
  state.account.setUpProgress;
export const getSidebarToggle = (state: RootState) =>
  state.account.sidebarToggle;
export const getSelectedCompanyId = (state: RootState) =>
  state.account.selectedCompanyId;
export const getAccountPreferences = (state: RootState) =>
  state.account.preferences;
export const isUserAdmin = (id: string) => (state: RootState) =>
  state.account.users
    .find((user) => user.id === id)
    ?.roles.includes("Company Admin");
export const isUserRunAsAdmin = (id: string) => (state: RootState) =>
  state.account.users
    .find((user: { id: string }) => user.id === id)
    ?.roles.includes("RunAs Administrator");
export const getUserRoles = (id: string) => (state: RootState) =>
  state?.account?.users
    .find((user: { id: string }) => user?.id === id)
    ?.roles.split(",");
export const getAuthenticator = (state: RootState) =>
  state.account.authenticator;
export default accountSlice.reducer;
export const getUserStatistics = (state: RootState) =>
  state.account.userStatistics;
// export const isUserEndUser = (id: string) => (state: RootState) =>
// state.account.users.find((user) => user.id === id)?.roles.includes("End User");
export const isUserEndUser = (id: string) => (state: RootState) =>
  determineIsUserEndUser(id, state);
