import { Box, Button, Grid, Icon, List, Typography } from "@mui/material";
import ProductDisplay from "./ProductDisplay";
import { ReactComponent as Logo } from "../../images/LogoSmall.svg";
import { StripeProduct } from "../../types/stripe.type";
import { useState } from "react";
import { useAsyncEffect } from "../../common/hooks";
import protectAxios from "../../api/axios";
import ProductCheckout from "./ProductCheckout";
import { Card } from "../../components/Card";
import { faCartShopping } from "@fortawesome/pro-light-svg-icons";
import { Loader } from "../../components/Loader";

export interface CheckoutDisplayProps {}

const CheckoutDisplay = (props: CheckoutDisplayProps) => {
  const [products, setProducts] = useState<Array<StripeProduct>>([]);
  const [checkoutIdx, setCheckoutIdx] = useState(-1);

  const { loading } = useAsyncEffect(async () => {
    const products = await protectAxios
      .get("/companyqueries/Payment/GetStripeProductsPrices")
      .then((resp) => resp.data);

    setProducts(
      products.map((product: StripeProduct) => ({
        ...product,
        logo: (
          <Icon sx={{ height: 24, width: 24 }}>
            <Logo style={{ height: 24, width: 24 }} />
          </Icon>
        ),
      }))
    );
  }, []);
  const openPortal = async () => {
    const { redirectUrl } = await protectAxios
      .get("/companycommands/Payment/create-new-portal-session")
      .then((resp) => resp.data);
    window.location.href = redirectUrl;
  };
  if (checkoutIdx !== -1) {
    return (
      <ProductCheckout
        product={products[checkoutIdx]}
        onBack={() => setCheckoutIdx(-1)}
      />
    );
  }
  return (
    <div>
      <Card
        id="Products"
        headerIcon={faCartShopping}
        headerTitle={"Products"}
        headerSubText={""}
        showHelp={false}
        helpHeaderText=""
        helpHeaderDescription=""
        cardHeaderActions={""}
        cardContent={
          <Box sx={{ width: "100%", maxWidth: 500 }}>
            <Typography
              sx={{ marginRight: "24px", verticalAlign: "middle" }}
              component="h3"
            >
              Please select from the product line below
            </Typography>
          </Box>
        }
      />
      {!loading ? (
        <List>
          {products.map((product, idx) => (
            <ProductDisplay
              product={product}
              onCheckout={() => {
                setCheckoutIdx(idx);
              }}
            />
          ))}
        </List>
      ) : (
        <Loader show={true} />
      )}
      <Grid
        container
        direction="row"
        sx={{ marginTop: 2 }}
        justifyContent="center"
      >
        <Grid item>
          <Button variant="contained" onClick={openPortal}>
            Manage Billing
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CheckoutDisplay;
