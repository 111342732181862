import {
  faTriangleExclamation,
  faSpinner,
  faTicketSimple,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import {
  countTicketPriority,
  countTicketStatus,
} from "../../../common/ticket.util";
import { Card } from "../../../components/Card";
import { Ticket } from "../../../types/tickets.types";

export const TicketTableSummary = (props: { tickets: Array<Ticket> }) => {
  const { tickets } = props;
  const [ticketStatus, setTicketStatus] = useState({ open: 0, inProgress: 0 });
  const [ticketPriority, setTicketPriority] = useState({
    low: 0,
    medium: 0,
    high: 0,
    total: 0,
  });

  useEffect(() => {
    setTicketStatus(countTicketStatus(tickets));
    setTicketPriority(countTicketPriority(tickets));
  }, [tickets]);

  return (
    <Card
      id="Tickets"
      headerIcon={faTicketSimple}
      headerTitle={'Tickets'}
      headerSubText={""}
      showHelp={false}
      helpHeaderText=""
      helpHeaderDescription=""
      cardHeaderActions={""}
      cardContent={
        <Grid
          id="overview"
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item xs={6} sx={{pl: 10}}>
            <h4
              style={{
                padding: "0 20px",
                fontWeight: "normal",
                fontSize: "16px",
                margin: 0,
                marginBottom: "10px",
              }}
            >
              Active Tickets Status
            </h4>
            <div className="inner">
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon
                    icon={faTriangleExclamation}
                    color="orange"
                  />{" "}
                  {ticketStatus.open}
                </h3>
                <span>Open</span>
              </div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon icon={faSpinner} color="orange" />{" "}
                  {ticketStatus.inProgress}
                </h3>
                <span>In Progress</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            <h4
              style={{
                padding: "0 20px",
                fontWeight: "normal",
                fontSize: "16px",
                margin: 0,
                marginBottom: "10px",
              }}
            >
              Active Tickets by Priority
            </h4>
            <div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon icon={faExclamationCircle} color="red" />{" "}
                  {ticketPriority.high}
                </h3>
                <span>Critical</span>
              </div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="orange"
                  />{" "}
                  {ticketPriority.medium}
                </h3>
                <span>Warning</span>
              </div>
              <div
                style={{ display: "inline-block", margin: "0px 20px 0px 20px" }}
              >
                <h3>
                  <FontAwesomeIcon icon={faCheckCircle} color="grey" />{" "}
                  {ticketPriority.low}
                </h3>
                <span>Informational</span>
              </div>
            </div>
          </Grid>
        </Grid>
      }
    />
  );
};
