import { faBuildings } from "@fortawesome/pro-light-svg-icons"
import {  List, ListItem, Button } from "@mui/material"
import { Card } from "../../../components/Card"
import UploadAvatar from "../UploadAvatar"
import { useIdentityContext } from "../../../context/IdentityContext"
import { useAppDispatch, useAppSelector } from "../../../redux/hooks"
import { getSelectedCompanyId } from "../../../redux/Slices/account.slice"
import { getCompanyById } from "../../../redux/Slices/company.slice"
import { uploadImage } from "../../../redux/Thunks/util"



export const OrganizationInformation = () => {

    const { authenticatedUser } = useIdentityContext();
    const dispatch = useAppDispatch();
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const company = useAppSelector(getCompanyById(selectedCompanyId || authenticatedUser.companyId));
    const companyId = selectedCompanyId || company?.id;


    const handleFileInputChange = async (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = e.target.files;
        if (!files) {
            return;
        }
        const file = files[0];

        await dispatch(
            uploadImage({
                uploadUrl: "/companycommands/UploadCompanyPictures",
                imageFile: file,
                fileName: "banner.png",
            })
        );
        window.location.reload();
    };


    return (
        // <Box>
            <Card
                id="settings-organization"
                headerIcon={faBuildings}
                headerTitle={`Organisation Information`}
                headerSubText={`View your organisation's details'.`}
                showHelp={false}
                helpHeaderText=""
                helpHeaderDescription=""
                cardHeaderActions={null}
                sx={{ height: "100%", width: '100%' }}
                cardContent={
                    <div>
                        <List
                            dense={true}
                            sx={{
                                width: "100%",
                                bgcolor: "background.paper",
                                overflowY: "auto",
                                maxHeight: "75vh",
                            }}
                        >
                            <ListItem>
                                <div style={{ marginRight: "16px" }}>
                                    <UploadAvatar
                                        uploadUrl="/companycommands/UploadCompanyLogo"
                                        imageUrl={`https://poimages.blob.core.windows.net/poimages/PortalImages/${companyId}/logo.png`}
                                        sx={{ width: 46, height: 46 }}
                                    />
                                </div>
                                <p>
                                    {company?.companyName}
                                    <br />
                                    <small>{company?.companyAddressLine1}</small>
                                </p>
                            </ListItem>
                            <ListItem>
                                <b>Primary Contact:</b>
                                &nbsp; {company?.primaryContactName}{" "}
                                {company?.primaryContactEmail}
                            </ListItem>
                            <ListItem>
                                <b>Address:</b>
                                &nbsp; {company?.companyAddressLine1}{" "}
                                {company?.companyAddressLine2}{" "}
                                {company?.companyAddressLine3} {company?.postalZip}
                            </ListItem>
                            <ListItem>
                                <input
                                    accept="image/png, image/jpeg"
                                    hidden={true}
                                    id="banner-button-file"
                                    type="file"
                                    onChange={handleFileInputChange}
                                />

                                <label htmlFor="banner-button-file">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                    >
                                        Change Company Banner
                                    </Button>
                                </label>
                            </ListItem>
                        </List>
                    </div>
                }
            />
        // </Box>
    )
}