import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, CardContent, Box, Typography, ButtonGroup, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import Chart from "./Chart";
import ChartButtonGroup from "./ChartButtonGroup";
import { DeviceChartData } from "../../types/devices.types";

interface ChartsProps {
    addedCharts: DeviceChartData[];
    onDeleteChart: (allcharts: DeviceChartData[]) => void;
    onChangeTimeInterval: (allcharts: DeviceChartData[]) => void;

}

export const ChartsContainer = (props: ChartsProps) => {
    const { addedCharts, onDeleteChart, onChangeTimeInterval } = props
    const [chartArray, setChartArray] = useState<any>(addedCharts)
    useEffect(() => {
        setChartArray(addedCharts)
    }, [addedCharts]);

    const onHandleDelete = (index: number) => {
        const chartCopy: any = [...chartArray]
        chartCopy.splice(index, 1)
        onDeleteChart(chartCopy)
    }

    const handleToggleTimeInterval = (chartCopy: DeviceChartData[]) => {
        setChartArray(chartCopy)
        onChangeTimeInterval(chartCopy)
    };
    return (
        <Box sx={{ marginRight: '295px' }}>
            {chartArray.map((chart: any, index: any) => (
                <Card sx={{ height: '55vh', margin: '10px 0 10px 0' }} key={'chartContainerCard'}>
                    <CardContent sx={{ height: '100%', padding: 0 }}>

                        <Grid container spacing={0} sx={{ mt: 1 }}>
                            <Grid item xs={1} sx={{ display: "flex", justifyContent: 'flex-start' }}>
                                <Typography sx={{ fontSize: 14, ml: 1 }} color="#002a4e" gutterBottom>
                                    {chart.objectName}
                                </Typography>
                            </Grid>
                            <Grid item xs={10} sx={{ display: "flex", justifyContent: 'center' }}>
                                <ButtonGroup>
                                    <ChartButtonGroup chartData={chart} onToggleTimeInterval={handleToggleTimeInterval} index={index} allCharts={chartArray} />
                                </ButtonGroup>

                            </Grid>
                            <Grid item xs={1} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                                <ButtonGroup sx={{ mr: 2, mt: 1 }}>
                                    <FontAwesomeIcon
                                        icon={faTrashCan}
                                        color={"#f8931c"}
                                        size="xl"
                                        onClick={() => onHandleDelete(index)}

                                    />
                                </ButtonGroup>
                            </Grid>
                        </Grid>

                        <Chart chartData={chart} />

                    </CardContent>

                </Card>

            ))}


        </Box>
    );
};
