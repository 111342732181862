import { faServer, faBriefcase, faDatabase, faShieldCheck, faVrCardboard, faEye } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Tooltip } from "@mui/material"

interface SyncedIconsProps {
    device: any
}

export const SyncedIcons = (props: SyncedIconsProps) => {
    const { device } = props
    return (
        <Grid container spacing={2}>
            {device.isScomSynced ?
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', p: 1 }}>
                    <Tooltip title="Scom">
                        <FontAwesomeIcon icon={faServer} color={'orange'} size="lg" />
                    </Tooltip>
                </Grid>
                : <></>}
            {device.isScomAgentManaged ?
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', p: 1 }}>
                    <Tooltip title="Scom Agent">
                        <FontAwesomeIcon icon={faBriefcase} color={'orange'} size="lg" />
                    </Tooltip>
                </Grid>
                : <></>}
            {device.isEndpointSynced ?
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', p: 1 }}>
                    <Tooltip title="End Point">
                        <FontAwesomeIcon icon={faDatabase} color={'orange'} size="lg" />
                    </Tooltip>
                </Grid>
                : <></>}
            {device.isSentinelSynced ?
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', p: 1 }}>
                    <Tooltip title="Sentinel">
                        <FontAwesomeIcon icon={faShieldCheck} color={'orange'} size="lg" />
                    </Tooltip>
                </Grid>
                : <></>}
            {device.isVirtualMachine ?
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', p: 1 }}>
                    <Tooltip title="Virtual">
                        <FontAwesomeIcon icon={faVrCardboard} color={'orange'} size="lg" />
                    </Tooltip>
                </Grid>
                : <></>}
            {device.isMonitored === 1 ?
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', p: 1 }}>
                    <Tooltip title="Monitored">
                        <FontAwesomeIcon icon={faEye} color={'orange'} size="lg" />
                    </Tooltip>
                </Grid>
                : <></>}
        </Grid>
    )
}