import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import accountReducer from "./Slices/account.slice";
import alertsReducer from "./Slices/alerts.slice";
import changeManagementReducer from "./Slices/changeManagement.slice";
import companyReducer from "./Slices/company.slice";
import devicesReducer from "./Slices/devices.slice";
import ticketsReducer from "./Slices/tickets.slice";
import snackbarReducer from "./Slices/snackbar.slice";
import layoutReducer from "./Slices/layout.slice";
import mapsReducer from "./Slices/maps.slice";
//import logger from "redux-logger";

export const store = configureStore({
  reducer: {
    account: accountReducer,
    alerts: alertsReducer,
    changeManagement: changeManagementReducer,
    company: companyReducer,
    devices: devicesReducer,
    tickets: ticketsReducer,
    snackbar: snackbarReducer,
    layout: layoutReducer,
    maps: mapsReducer,
  },
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
