import { Card, CardContent, Grid, Typography } from "@mui/material"
import ReportBarChart from "../Widgets/ReportBarChart";
import { determineBarChartCategoriesForCMStatus, determineBarChartDataForCMStatus, determinePieDataForCMStatus, determineRowDataForCMStatus } from "./CMTabsUtils";
import ReportPieChart from "../Widgets/ReportPieChart";
import ReportsTable from "../Widgets/ReportsTable";

interface CMStatusProps {
    CMSummaries: any;
}

export const CMStatus = (props: CMStatusProps) => {
    const { CMSummaries } = props
    const barChartCategories = determineBarChartCategoriesForCMStatus(CMSummaries)
    const barChartData = determineBarChartDataForCMStatus(CMSummaries)
    const pieData = determinePieDataForCMStatus(barChartCategories, barChartData)
    const tableHeaders = ['CM States', '# CM Items', '% of Total']
    const rowData = determineRowDataForCMStatus(CMSummaries, barChartCategories, barChartData)



    return (
        <Card id='exportCMState' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ maxHeight: '65vh', padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Change Management State
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                        
                    </Grid>


                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'CM'}
                        />

                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportPieChart
                            pieData={pieData}
                        />
                    </Grid>

                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                    <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Change Management State Data'}
                        />
                    </Grid>

                </Grid>

            </CardContent>

        </Card >
    )
}

