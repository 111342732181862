import { Device, DeviceStat } from "../types/devices.types";

export const determineDeviceStatus = (stats: DeviceStat) => {
  return {
    error: stats.errorState.total,
    maintenance: stats.maintenenceOn,
    monitored: stats.monitoringOn,
  };
};

export const removeDuplicateDevices = (devices: Device[]) => {
  devices = devices.filter(
    (value, index, self) =>
      index === self.findIndex((device) => device.id === value.id)
  );
  return devices;
};

export const determineDeviceHealth = (devices: Device[]) => {
  const duplicateDevicesRemoved = removeDuplicateDevices(devices);
  let deviceHealthCount = {
    healthy: 0,
    warning: 0,
    critical: 0,
    unavailable: 0,
  };
  duplicateDevicesRemoved.map((device: any) => {
    if (device.healthState === 0) {
      deviceHealthCount.critical++;
    }
    if (device.healthState === 1) {
      deviceHealthCount.healthy++;
    }
    if (device.healthState === 2) {
      deviceHealthCount.unavailable++;
    }
    if (device.healthState === 3) {
      deviceHealthCount.warning++;
    }
  });
  return deviceHealthCount;
};
