
import { Button, Card } from "@mui/material";
import * as React from "react";
import { useAppDispatch } from "../redux/hooks";
import { setSetUpInfoPage } from "../redux/Slices/company.slice";

const cardStyle = {
  position: "absolute",
  margin: "50px",
  padding: "50px",
};

export const SetUpInfo = () => {
  const dispatch = useAppDispatch();

  const handleAgreeClick = () => {

    dispatch(setSetUpInfoPage(false));

  }

  return (
    <Card sx={cardStyle}>
      <Button
        variant="outlined"
        color="primary"
        type="submit"
        sx={{ mt: 2 }}
        onClick={handleAgreeClick}
      >
        Close Page
      </Button>
      <div>
        <p><strong>Setup Security Portal. </strong></p>
       
        <p>To be added.</p>
        <p><br /></p>
      </div>



    </Card>
  );
};

