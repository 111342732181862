import * as Mui from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";
import {
  getTermsofUse,
  getEndUserLicenseAgreement,
} from "../../redux/Slices/company.slice";
import { ICompanyData } from "./SignupPage";
import { faSquareCheck, faSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface SignupWelcomeProps {
  onNextClick: (companyName: string) => void;
  formData: ICompanyData;
}

export const SignupWelcome: React.FC<SignupWelcomeProps> = (props) => {
  const { control, handleSubmit } = useForm<{ companyName: string }>();
  const onSubmit: SubmitHandler<{ companyName: string }> = (data) => {
    props.onNextClick(data.companyName);
  };
  const termsOfUseAgreement = useAppSelector(getTermsofUse);
  const endUserLicenseAgreement = useAppSelector(getEndUserLicenseAgreement);

  return (
    <Mui.Stack
      direction="column"
      alignItems={"center"}
      sx={{ width: { xs: "100%", md: "70%", xxl: "55%" } }}
      spacing={1}
      padding={2}
    >
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        Enter your Organisation's Name
      </Mui.Typography>
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "75%", textAlign: "center", color: "#aaa" }}
        variant="body2"
      >
        Free 30 day trial period.
      </Mui.Typography>
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "75%", textAlign: "center", color: "#aaa" }}
        variant="body2"
      >
        Review each document, then click 'I AGREE' at the bottom of each to proceed.
      </Mui.Typography>
      <Mui.List>
        {/* TERMS OF USE LINK */}
        <Mui.ListItem
          key={"termsOfUse"}
          disablePadding
          sx={{ display: "block" }}
        >
          <NavLink
            to={"/termsofuse"}
            title={`Go To Terms of Use Page`}
            className={({ isActive }) =>
              "header-link" + (isActive ? " active" : " ")
            }
            end
          >
            <Mui.ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "start",
                px: 2.5,
              }}
            >
              <Mui.ListItemIcon
                sx={{
                  minWidth: 0,
                }}
              >
                <Mui.Badge
                  badgeContent={0}
                  className="header-badge"
                ></Mui.Badge>
              </Mui.ListItemIcon>
              {termsOfUseAgreement ? (
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  color={"#e88f00"}
                  size="lg"
                  style={{ paddingRight: "10px" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faSquare}
                  color={"#aaa"}
                  size="lg"
                  style={{ paddingRight: "10px" }}
                />
              )}
              <Mui.ListItemText
                primary={"Terms of Use"}
                sx={{ color: "#002a4e" }}
              />
            </Mui.ListItemButton>
          </NavLink>
        </Mui.ListItem>
        {/* USER AGREEMENT LINK */}
        <Mui.ListItem
          key={"endUserLicenseAgreement"}
          disablePadding
          sx={{ display: "block" }}
        >
          <NavLink
            to={"/eula"}
            title={`Go To End User License Agreement Page`}
            className={({ isActive }) =>
              "header-link" + (isActive ? " active" : " ")
            }
            end
          >
            <Mui.ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: "start",
                px: 2.5,
              }}
            >
              <Mui.ListItemIcon
                sx={{
                  minWidth: 0,
                }}
              >
                <Mui.Badge
                  badgeContent={0}
                  className="header-badge"
                ></Mui.Badge>
              </Mui.ListItemIcon>
              {endUserLicenseAgreement ? (
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  color={"#e88f00"}
                  size="lg"
                  style={{ paddingRight: "10px" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faSquare}
                  color={"#aaa"}
                  size="lg"
                  style={{ paddingRight: "10px" }}
                />
              )}
              <Mui.ListItemText
                primary={"End User License Agreement"}
                sx={{ color: "#002a4e" }}
              />
            </Mui.ListItemButton>
          </NavLink>
        </Mui.ListItem>
      </Mui.List>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Mui.Stack
          direction="column"
          alignItems={"center"}
          spacing={2}
          padding={2}
        >
          <Controller
            name="companyName"
            control={control}
            defaultValue={
              props.formData.companyName === null
                ? undefined
                : props.formData.companyName
            }
            rules={{
              required: {
                value: true,
                message: "",
              },
            }}
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                required
                onChange={field.onChange}
                id="companyname"
                label="Organisation's Name"
                sx={{ width: "100%", mt: 2 }}
                helperText=""
              />
            )}
          />
          <Mui.Button
            variant="outlined"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
            disabled={
              termsOfUseAgreement && endUserLicenseAgreement ? false : true
            }
          >
            Next Step
          </Mui.Button>
        </Mui.Stack>
      </form>
    </Mui.Stack>
  );
};
