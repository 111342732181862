import { Alert, Snackbar } from "@mui/material";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { closeSnackbar, getSnackbar } from "../redux/Slices/snackbar.slice";

const AppSnackbar = () => {
  const { message, type, open } = useAppSelector(getSnackbar);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (open) {
      setTimeout(() => dispatch(closeSnackbar()), 10000); //Move timeout in ms to either snackbar state or constants file
    }
  }, [open, dispatch]);

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
};

export default AppSnackbar;
