
import { Button, Card } from "@mui/material";
import * as React from "react";

export interface IPortalSolutionsTermsOfService {
    onAccept: () => void;
  }
const cardStyle = {
  position: "absolute",
  margin: "50px",
  padding: "50px",
};

export const PortalPrivacyPolicy = (props: IPortalSolutionsTermsOfService) => {
    const { onAccept } = props;

  return (
    <Card sx={cardStyle}>
      <div>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', textAlign: 'center', fontSize: '21px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><u><span style={{fontSize: '16px'}}>Portal Privacy Policy</span></u></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>Last modified: March 23, 2023</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Introduction</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>SecQube. (“<strong>Company</strong>” or “<strong>We</strong>”) respects your privacy and are committed to protecting it through our compliance with this policy. This software application privacy policy (“<strong>Policy</strong>”) describes:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>The types of information we may collect or that you may provide when you purchase, download, install, register with, access, or use the <strong>Security Portal Portal</strong> (the “<strong>Portal</strong>”).</li>
          <li>Our practices for collecting, using, maintaining, protecting, and disclosing that information.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>This Policy applies only to information we collect in the Portal, and in email, text, and other electronic communications sent through or in connection with the Portal, or on any other Company apps or websites, including websites you may access through the Portal.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>This Policy DOES NOT apply to information that:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>We collect offline.</li>
          <li>You provide to or is collected by any third party (see <a href="#a982672">Third-Party Information Collection</a>).</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>Our website and third parties may have their own privacy policies, which we encourage you to read before providing information on or through them.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, do not download, install, register with, or use the Portal. By downloading, installing, registering with, or using the Portal, you agree to this Policy. This Policy may change from time to time (see <a href="#a797574">Changes to Our Privacy Policy</a>). Your continued use of the Portal after we revise this Policy means you accept those changes, so please check the policy periodically for updates.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Children Under the Age of 16</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>The Portal is not intended for children under 16 years of age, and we do not knowingly collect personal information from children under 16. If we learn we have collected or received personal information from a child under 16 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 16, please email us at <strong>[EMAIL ADDRESS]</strong>.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information. Please see <a href="#a820658">Your State Privacy Rights</a> for more information.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Information We Collect and How We Collect It</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We collect information from and about users of our Portal:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>Directly from you when you provide it to us.</li>
          <li>Automatically when you use the Portal.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><em>Information You Provide to Us</em></strong></span><em>&nbsp;&nbsp;</em></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>When you download, register with, or use the Portal, we may ask you provide information:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>By which you may be personally identified, such as name, postal address, email address, telephone number, social security number, or any other identifier by which you may be contacted online or offline (“<strong>personal information</strong>”).</li>
          <li>That is about you but individually does not identify you.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>This information includes:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>Information that you provide by filling in forms in the Portal. This includes information provided at the time of registering to use the Portal, subscribing to our service, uploading data or search inputs, posting material, and requesting further services. We may also ask you for information when you report a problem with the Portal.</li>
          <li>Records and copies of your correspondence (including email addresses and phone numbers) if you contact us.</li>
          <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
          <li>Details of transactions you carry out through the Portal and of the fulfillment of your orders. You may be required to provide financial information before placing an order through the Portal.</li>
          <li>Your search queries on the Portal.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><em>Automatic Information Collection and Tracking &nbsp;&nbsp;</em></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>When you download, access, and use the Portal, it may use technology to automatically collect:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li><strong>Usage Details.</strong> When you access and use the Portal, we may automatically collect certain details of your access to and use of the Portal, including traffic data, location data, logs, and other communication data and the resources that you access and use on or through the Portal.</li>
          <li><strong>Device Information.</strong> We may collect information about your mobile device and internet connection, including the device’s unique device identifier, IP address, operating system, browser type, mobile network information, and the device’s telephone number.</li>
          <li><strong>Stored Information and Files.</strong> The Portal also may access metadata and other information associated with other files stored on your device. This may include, for example, photographs, audio and video clips, personal contacts, and address book information.</li>
          <li><strong>Location Information.</strong> This Portal collects real-time information about the location of your device.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>If you do not want us to collect this information do not download the Portal or delete it from your device.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We also may use these technologies to collect information about your activities over time and across third-party websites, apps, or other online services (behavioral tracking).</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><em>Information Collection and Tracking Technologies</em></strong></span><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong>&nbsp;</strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>The technologies we use for automatic information collection may include:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li><strong>Cookies (or mobile cookies).</strong> A cookie is a small file placed on your smartphone. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you select this setting, you may be unable to access certain parts of our Portal.</li>
          <li><strong>Web Beacons.</strong> Pages of the Portal and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related app statistics (for example, recording the popularity of certain app content and verifying system and server integrity).</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><em>Third-Party Information Collection</em></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>When you use the Portal or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>Advertisers, ad networks, and ad servers.</li>
          <li>Analytics companies.</li>
          <li>Your computer or mobile device manufacturer.</li>
          <li>Your internet or wireless service provider.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>These third parties may use tracking technologies to collect information about you when you use this app. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We do not control these third parties’ tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see <a href="#a102108">Your Choices About Our Collection, Use, and Disclosure of Your Information</a>.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>How We Use Your Information</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We use information that we collect about you or that you provide to us, including any personal information, to:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>Provide you with the Portal and its contents, and any other information, products or services that you request from us.</li>
          <li>Fulfill any other purpose for which you provide it.</li>
          <li>Give you notices about your account/subscription, including expiration and renewal notices.</li>
          <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
          <li>Notify you when Portal updates are available, and of changes to any products or services we offer or provide though it.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>The usage information we collect helps us to improve our Portal and to deliver a better and more personalized experience by enabling us to:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>Estimate our audience size and usage patterns.</li>
          <li>Store information about your preferences, allowing us to customize our Portal according to your individual interests.</li>
          <li>Speed up your searches.</li>
          <li>Recognize you when you use the Portal.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We use location information we collect to perform the services provided by the Portal, and for any other purpose for which you request or consent.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We may also use your information to contact you about our own and third parties’ goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile or email us at <strong>[EMAIL ADDRESS]</strong>. For more information, see <a href="#a102108">Your Choices About Our Collection, Use, and Disclosure of Your Information</a>.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We may use the information we collect to display advertisements to our advertisers’ target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Disclosure of Your Information</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We may disclose aggregated information about our users, and information that does not identify any individual or device, without restriction.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>In addition, we may disclose personal information that we collect or that you provide:</p>
        <ul style={{listStyleType: 'disc'}}>
          <li>To our subsidiaries and affiliates.</li>
          <li>To contractors, service providers, and other third parties we use to support our business.</li>
          <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganisation, dissolution, or other sale or transfer of some or all of Secubes assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by SecQube. about our Portal users is among the assets transferred.</li>
          <li>To third parties to market their products or services to you if you have consented to these disclosures.</li>
          <li>To fulfill the purpose for which you provide it.</li>
          <li>For any other purpose disclosed by us when you provide the information.</li>
          <li>With your consent.</li>
          <li>To comply with any court order, law, or legal process, including to respond to any government or regulatory request.</li>
          <li>To enforce our rights arising from any contracts entered into between you and us, including the Portal Master Services Agreement and/or End User License Agreement, and for billing and collection.</li>
          <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of SecQube, our customers or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Your Choices About Our Collection, Use, and Disclosure of Your Information</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We strive to provide you with choices regarding the personal information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of over your information.</p>
        <ul style={{listStyleType: 'disc'}}>
          <li><strong>Tracking Technologies.</strong> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. You can choose whether or not to allow the Portal to collect information through other tracking technologies by sending an email to <strong>[EMAIL ADDRESS]</strong>. If you disable or refuse cookies or block the use of other tracking technologies, some parts of the Portal may then be inaccessible or not function properly.</li>
          <li><strong>Location Information.</strong> You can choose whether or not to allow the Portal to collect and use real-time information about your device’s location through the device’s privacy settings. If you block the use of location information, some parts of the Portal may become inaccessible or not function properly.</li>
          <li><strong>Promotion by the Company.</strong> If you do not want us to use your email address to promote our own or third parties’ products or services, you can opt-out by logging into the Portal and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <strong>[EMAIL ADDRESS]</strong>.</li>
          <li><strong>Targeted Advertising by the Company.</strong> If you do not want us to use information that we collect or that you provide to us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by adjusting your user advertising preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <strong>[EMAIL ADDRESS]</strong>.</li>
          <li><strong>Disclosure of Your Information for Third-Party Advertising and Marketing.</strong> If you do not want us to share your personal information with unaffiliated or non-agent third parties for advertising and marketing purposes, you can opt-out by logging into the Portal and adjusting your user preferences in your account profile by checking or unchecking the relevant boxes or by sending us an email stating your request to <strong>[EMAIL ADDRESS]</strong>.</li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We do not control third parties’ collection or use of your information to serve interest-based advertising. However, these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative (“<strong>NAI</strong>”) on the NAI’s <span style={{color: 'black', textDecoration: 'none'}}>website</span>.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Your State Privacy Rights</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>State consumer privacy laws may provide their residents with additional rights regarding our use of their personal information. To learn more about California residents’ privacy rights, see our California Privacy Policy. California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Portal that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <strong>[EMAIL ADDRESS]</strong>.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Colorado, Connecticut, Virginia, and Utah each provide their state residents with rights to:</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0.25in'}}>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Confirm whether we process their personal information.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Access and delete certain personal information.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Data portability.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Opt-out of personal data processing for targeted advertising and sales.</span></li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Colorado, Connecticut, and Virginia also provide their state residents with rights to:</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0.25in'}}>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Correct inaccuracies in their personal information, taking into account the information’s nature processing purpose.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Opt-out of profiling in furtherance of decisions that produce legal or similarly significant effects.&nbsp;</span></li>
        </ul>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>To exercise any of these rights please send us an email to <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Nevada provides its residents with a limited right to opt-out of certain personal information sales. Residents who wish to exercise this sale opt-out rights may submit a request to this designated email address: <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '6.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><u><span style={{fontSize: '16px', lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}><span style={{textDecoration: 'none'}}>&nbsp;</span></span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><strong><u><span style={{fontSize: '16px', lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>European Union Data Subjects Rights Under General Data Protection Regulation 2016/679 (GDPR)</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>European Union data subjects have legal rights under the GDPR in relation to your personal information. To learn more about each right you may have or to exercise any of your rights please contact us by sending an email to <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '8.0pt', marginLeft: '0in', lineHeight: '107%', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify'}}><span style={{fontSize: '16px', lineHeight: '107%', fontFamily: '"Times New Roman",serif'}}>You may request that we:</span></p>
        <ul style={{listStyleType: 'undefined', marginLeft: '0in'}}>
          <li><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif', fontSize: '16px'}}>Confirm whether or not we have and are using your personal information.</span></li>
          <li><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif', fontSize: '16px'}}>Correct any information about you which is incorrect.&nbsp;</span></li>
          <li><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif', fontSize: '16px'}}>Ask us to restrict our use of your information in certain circumstances.</span></li>
          <li><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif', fontSize: '16px'}}>Stop using your data for direct marketing purposes.</span></li>
          <li><span style={{lineHeight: '107%', fontFamily: '"Times New Roman",serif', fontSize: '16px'}}>Erase your information if you think we no longer need to use it for the purpose we collected it from you or if you have either withdrawn your consent to us using your information (if we originally asked for your consent to use your information), or exercised your right to object to further legitimate use of your information, or where we have used it unlawfully or where we are subject to a legal obligation to erase your personal information.</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We can continue to use your information following a request for restriction where we have your consent to use it; or we need to use it to establish, exercise or defend legal claims, or we need to use it to protect the rights of another individual or a company.</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Data Security</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Portal, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>Unfortunately, the transmission of information via the internet and mobile platforms is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our Portal. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures we provide.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Changes to Our Privacy Policy</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>We may update this Policy from time to time. If we make material changes to how we treat our users’ personal information, we will post the new privacy policy on this page an in-Portal alert the first time you use the Portal after we make the change.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>The date this Policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you and for periodically visiting this Policy to check for any changes.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', textAlign: 'justify', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'bold'}}><span style={{fontFamily: '"Times New Roman",serif', color: 'black', fontWeight: 'normal', textDecoration: 'none', verticalAlign: 'baseline'}}><strong><u>Contact Information</u></strong></span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>To ask questions or comment about this Policy and our privacy practices or to register a complaint or concern, contact us at: <strong>[EMAIL ADDRESS]</strong>.</p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>&nbsp;</p>
        <p><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}><br />&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'center', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Privacy Policy for California Residents</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', border: 'none'}}><strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Effective Date:</span></strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;[DATE]</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', border: 'none'}}><strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Last Updated on</span></strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>: [DATE]</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>This Privacy Policy for California Residents (“<strong>Policy</strong>”) supplements the information contained in Company’s Portal Privacy Policy,</span><strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif'}}>&nbsp;</span></strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif'}}>(“<strong>Privacy Policy</strong>”)<span style={{color: 'black'}}>&nbsp;and applies solely to all visitors, users, and others who reside in the State of California (“<strong>consumers</strong>” or “<strong>you</strong>”) and&nbsp;</span></span><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>install, register with, access, or use the <strong>Security Portal</strong></span><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;(the “<strong>Portal</strong>”). We adopt this notice to comply with the California Consumer Privacy Act of 2018 (CCPA) and any terms defined in the CCPA have the same meaning when used in this Policy.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Information We Collect</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>SecQube (“<strong>Company</strong>” or “<strong>we</strong>”) collects information that identifies, relates to, describes, references, is reasonably capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer, household, or device (“<strong>personal information</strong>”). Personal information does not include:</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0.5in'}}>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Publicly available information from government records.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Deidentified or aggregated consumer information.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Information excluded from the CCPA’s scope, like:</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA), clinical trial data, or other qualifying research data;</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FCRA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver’s Privacy Protection Act of 1994.</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>In particular, we have collected the following categories of personal information from consumers within the last twelve (12) months:&nbsp;</span></p>
        <table style={{width: '4.7e+2pt', borderCollapse: 'collapse', border: 'none'}}>
          <tbody>
            <tr>
              <td style={{width: '91.15pt', border: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Category</span></strong></p>
              </td>
              <td style={{width: '278.2pt', borderTop: '1pt solid black', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderImage: 'initial', borderLeft: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Examples</span></strong></p>
              </td>
              <td style={{width: '98.15pt', borderTop: '1pt solid black', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderImage: 'initial', borderLeft: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><strong><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Collected</span></strong></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>A. Identifiers.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>A real name, alias, postal address, unique personal identifier, online identifier, Internet Protocol address, email address, account name, Social Security number, driver’s license number, passport number, or other similar identifiers.</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>YES</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>A name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.</span></p>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Some personal information included in this category may overlap with other categories.</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>YES</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>C. Protected classification characteristics under California or federal law.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Age (40 years or older), race, color, ancestry, national origin, citizenship, religion or creed, marital status, medical condition, physical or mental disability, sex (including gender, gender identity, gender expression, pregnancy or childbirth and related medical conditions), sexual orientation, veteran or military status, genetic information (including familial genetic information).</span></p>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>NO</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>D. Commercial information.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>YES</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>E. Biometric information.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Genetic, physiological, behavioral, and biological characteristics, or activity patterns used to extract a template or other identifier or identifying information, such as, fingerprints, faceprints, and voiceprints, iris or retina scans, keystroke, gait, or other physical patterns, and sleep, health, or exercise data.</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>NO</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>F. Internet or other similar network activity.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Browsing history, search history, information on a consumer’s interaction with a website, application, or advertisement.</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>YES</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>G. Geolocation data.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Physical location or movements.&nbsp;</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>NO</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>H. Sensory data.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Audio, electronic, visual, thermal, olfactory, or similar information.</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>NO</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>I. Professional or employment-related information.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Current or past job history or performance evaluations.</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>NO</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Education records directly related to a student maintained by an educational institution or party acting on its behalf, such as grades, transcripts, class lists, student schedules, student identification codes, student financial information, or student disciplinary records.&nbsp;</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>NO</span></p>
              </td>
            </tr>
            <tr>
              <td style={{width: '91.15pt', borderRight: '1pt solid black', borderBottom: '1pt solid black', borderLeft: '1pt solid black', borderImage: 'initial', borderTop: 'none', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '3.5pt', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>K. Inferences drawn from other personal information.</span></p>
              </td>
              <td style={{width: '278.2pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Profile reflecting a person’s preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</span></p>
              </td>
              <td style={{width: '98.15pt', borderTop: 'none', borderLeft: 'none', borderBottom: '1pt solid black', borderRight: '1pt solid black', padding: '0in 5.4pt', verticalAlign: 'top'}}>
                <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>YE</span><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif'}}>S</span></p>
              </td>
            </tr>
          </tbody>
        </table>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We obtain the categories of personal information listed above from the following categories of sources:</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0.5in'}}>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Directly from you. For example, from forms you complete or products and services you purchase.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Indirectly from you. For example, from observing your actions on our Portal.</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Use of Personal Information</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We may use or disclose the personal information we collect for one or more of the following purposes:&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>To fulfill or meet the reason you provided the information. For example, if you share your name and contact information to request a price quote or ask a question about our products or services, we will use that personal information to respond to your inquiry. If you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate delivery. We may also save your information to facilitate new product orders or process returns.&nbsp;</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0.5in'}}>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>To provide, support, personalize, and develop our Portal, products, and services.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>To create, maintain, customize, and secure your account with us.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>To process your requests, purchases, transactions, and payments and prevent transactional fraud.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>To personalize your Portal experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Portal, third-party sites, and via email or text message (with your consent, where required by law).</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>To help maintain the safety, security, and integrity of our Portal, products and services, databases and other technology assets, and business.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>For testing, research, analysis, and product development, including to develop and improve our Portal, products, and services.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our Portal users is among the assets transferred.</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Sharing Personal Information</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We may share your personal information by disclosing it to a third party for a business purpose. We only make these business purpose disclosures under written contracts that describe the purposes, require the recipient to keep the personal information confidential, and prohibit using the disclosed information for any purpose except performing the contract. In the preceding twelve (12) months, Company has not disclosed personal information for a business purpose.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We may also share your personal information by selling it to third parties, subject to your right to opt-out of those sales. Our personal information sales do not include information about individuals we know are under age 16. In the preceding twelve (12) months, Company has not sold personal information. For more on your personal information sale rights, see <u>Personal Information Sales Opt-Out and Opt-In Rights</u>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Your Rights and Choices&nbsp;</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><em><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Right to Know and Data Portability</span></em></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months (the “right to know”). Once we receive your request and confirm your identity (see&nbsp;</span><a href="#bookmark=id.32hioqz"><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif'}}>Exercising Your Rights to Know or Delete</span></u></a><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>), we will disclose to you:</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0.5in'}}>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>The categories of personal information we collected about you.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>The categories of sources for the personal information we collected about you.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Our business or commercial purpose for collecting or selling that personal information.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>The categories of third parties with whom we share that personal information.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>sales, identifying the personal information categories that each category of recipient purchased; and&nbsp;</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.&nbsp;</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>The specific pieces of personal information we collected about you (also called a data portability request).</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><em><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Right to Delete&nbsp;</span></em></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>You have the right to request that we delete any of your personal information that we collected from you and retained, subject to certain exceptions (the “right to delete”). Once we receive your request and confirm your identity (see <u>Exercising Your Rights to Know or Delete</u>), we will review your request to see if an exception allowing us to retain the information applies. We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:&nbsp;</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0.5in'}}>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, or otherwise perform our contract with you.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Debug products to identify and repair errors that impair existing intended functionality.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 <em>et. seq.</em>).</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Comply with a legal obligation.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>We will delete or deidentify personal information not subject to one of these exceptions from our records and will direct our service providers to take similar action.&nbsp;</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><em><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Exercising Your Rights to Know or Delete</span></em></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>To exercise your rights to know or delete described above, please submit a request by emailing us at <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Only you, or someone legally authorized to act on your behalf, may make a request to know or delete related to your personal information.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>You may also make a request to know or delete on behalf of your child by emailing us at <strong>[EMAIL ADDRESS]</strong>.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>You may only submit a request to know twice within a 12-month period. Your request to know or delete must:</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>You do not need to create an account with us to submit a request to know or delete. However, we do consider requests made through your password protected account sufficiently verified when the request relates to personal information associated with that specific account.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We will only use personal information provided in the request to verify the requestor’s identity or authority to make it.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>For instructions on exercising your sale opt-out or opt-in rights, see <u>Personal Information Sales Opt-Out and Opt-In Rights</u>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><em><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Response Timing and Format</span></em></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We will confirm receipt of your request within ten (10) business days. If you do not receive confirmation within the 10-day timeframe, please contact us.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We endeavor to substantively respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to another 45 days), we will inform you of the reason and extension period in writing.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Any disclosures we provide will only cover the 12-month period preceding our receipt of your request. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><em><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Personal Information Sales Opt-Out and Opt-In Rights</span></em></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>If you are age 16 or older, you have the right to direct us to not sell your personal information at any time (the “right to opt-out”). We do not sell the personal information of consumers we actually know are less than 16 years old, unless we receive affirmative authorization (the “right to opt-in”) from either the consumer who is between 13 and 15 years old, or the parent or guardian of a consumer less than 13 years old. Consumers who opt-in to personal information sales may opt-out of future sales at any time.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>To exercise the right to opt-out, you (or your authorized representative) may submit a request to us by emailing us at: <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Once you make an opt-out request, we will wait at least twelve (12) months before asking you to reauthorize personal information sales. However, you may change your mind and opt back into personal information sales at any time by emailing us at: <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>You do not need to create an account with us to exercise your opt-out rights. We will only use personal information provided in an opt-out request to review and comply with the request.&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Non-Discrimination</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</span></p>
        <ul style={{listStyleType: 'disc', marginLeft: '0.5in'}}>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Deny you goods or services.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Provide you a different level or quality of goods or services.</span></li>
          <li><span style={{fontFamily: '"Times New Roman",serif', fontSize: '12.0pt'}}>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</span></li>
        </ul>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Other California Privacy Rights</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Portal that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Changes to Our Privacy Policy</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>We reserve the right to amend this Policy at our discretion and at any time. When we make changes to this Policy, we will post the updated notice on the Portal and update the notice’s effective date. <strong>Your continued use of our Portal following the posting of changes constitutes your acceptance of such changes</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><strong><u><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>Contact Information</span></u></strong></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>If you have any questions or comments about this notice, the ways in which SecQube collects and uses your information described here and in the Privacy Policy, and Master Services Agreement and/or End User License Agreement, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at: <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>If you need to access this Policy in an alternative format due to having a disability, please contact us at <strong>[EMAIL ADDRESS]</strong>.</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '12.0pt', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', textAlign: 'justify', textIndent: '.5in', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
        <p style={{marginTop: '6.0pt', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', lineHeight: 'normal', fontSize: '15px', fontFamily: '"Calibri",sans-serif', border: 'none'}}><span style={{fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black'}}>&nbsp;</span></p>
        <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '0in', fontSize: '16px', fontFamily: '"Times New Roman",serif', color: 'black', textAlign: 'justify'}}>&nbsp;</p>
      </div>
      
      <Button
            variant="outlined"
            color="primary"
            type="submit"
            sx={{ mt: 2 }}
            onClick={onAccept}
          >
            I Agree
          </Button>
         
    </Card>
  );
};

