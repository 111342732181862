const removeDuplicates = (value: any[]) => {
    const returnValue = value.filter(
        (value, index, self) =>
            index === self.findIndex((data) => data === value)
    );
    return returnValue;
};
export const determineBarChartCategoriesForCMStatus = (value: any) => {
    let returnData: any = []
    value.forEach((cm: any) => {
        returnData.push(cm.statusDescription)
    })
    returnData = removeDuplicates(returnData)
    return returnData
}

export const determineBarChartDataForCMStatus = (value: any) => {
    let inProgress = 0;
    let approved = 0;
    let newCount = 0;
    let complete = 0;
    let signedOff = 0
    value.forEach((cm: any) => {
        if (cm.statusDescription === 'In Progress') { inProgress++ }
        if (cm.statusDescription === 'Approved') { approved++ }
        if (cm.statusDescription === 'New') { newCount++ }
        if (cm.statusDescription === 'Complete') { complete++ }
        if (cm.statusDescription === 'signed Off') { signedOff++ }
    })
    return [inProgress, approved, newCount, complete, signedOff]
}

export const determinePieDataForCMStatus = (name: any, data: any) => {
    let returnData: any = []
    name.forEach((val: any, index: number) => {
        returnData.push({name: val, y: data[index]})
    })
    return returnData
}

export const determineRowDataForCMStatus = (summaries: any, name: any, data: any) => {
    const total = summaries.length
    let returnData: any = []
    name.forEach((val: any, index: number) => {
        returnData.push({value: val, count: data[index], percentage: ((100 * data[index]) / total).toFixed(2) })
    })
    return returnData
}


