import { faBell } from "@fortawesome/pro-light-svg-icons";
import * as Mui from "@mui/material";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getFlareAlertResponse,  getFlareAlertToSearch} from "../../redux/Slices/alerts.slice";
import { useEffect } from "react";
import { setLayout } from "../../redux/Slices/layout.slice";
import { FlareTableSummary } from "./FlareTableSummary";
import FlareEnhancedTable from "./FlareEnhancedTable";

export const FlareDashboardPage = () => {
  const dispatch = useAppDispatch();
  const flareAlert = useAppSelector(getFlareAlertResponse);
  const alertToSearch = useAppSelector(getFlareAlertToSearch)
  useEffect(() => {
    dispatch(
      setLayout({
        title: "Alerts Dashboard",
        subtitle: "Alerts Dashboard",
        icon: faBell,
      })
    );
  }, [dispatch]);


  return (
    <>
        <>
          <FlareTableSummary  />
          <Mui.Grid item xs={12} sx={{ height: "auto", marginTop: 2 }}>
            <Mui.Card sx={{ border: "solid 1px #ddd", height: "auto" }}>
              {
                <FlareEnhancedTable
                  flareAlert={flareAlert}
                />
              }
            </Mui.Card>
          </Mui.Grid>
        </>
    </>
  );
};
