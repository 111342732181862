import { faCaretDown, faCaretLeft } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Menu, MenuItem, Modal, } from "@mui/material";
import { useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { fetchInvestigateDevice } from "../../redux/Thunks/alerts";
import { Loader } from "../../components/Loader";

interface InvestigatedTypesLinkProps {
    investigatedDevice: any;
    currentDevice: any;
}

export const InvestigatedTypesLink = (props: InvestigatedTypesLinkProps) => {
    const { investigatedDevice, currentDevice } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [loading, setLoading] = useState(false)

    const dispatch = useAppDispatch();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleTypeSelect = async (type: string) => {
        setLoading(true)

        const command = `${currentDevice?.id}/${currentDevice?.deviceGroup}?filterType=${type}`
        const response = await dispatch(fetchInvestigateDevice(command));
        if (response.payload) {
            setLoading(false)
        }
        setAnchorEl(null);
    }

    return (
        (investigatedDevice.investigatedTypes ?
            <div>
                <Modal open={loading}>
                    <Loader show={true} />
                </Modal>
                <strong>Investigated Types: </strong>

                <Button
                    id="basic-button"
                    variant="outlined"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                >
                    Types
                    <FontAwesomeIcon
                        icon={open ? faCaretLeft : faCaretDown}
                        color={"orange"}
                        size="lg"
                        style={{ paddingLeft: '5px' }} />
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    {investigatedDevice.investigatedTypes.map((type: string, index: number) => {
                        return (
                            <MenuItem onClick={() => handleTypeSelect(type)} key={index}>{type}</MenuItem>
                        )
                    })}
                </Menu>
            </div>
            : <Box></ Box>)


    )
}