import { TableHead, TableCell, TableSortLabel, Box } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Order } from "../../common/TableUtilities";
import { InvestigationTable } from "../../types/alerts.types";
import { headCells } from "./InvestigateAlertHeadCells";

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof InvestigationTable
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}
export function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof InvestigationTable) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  return (
    <TableHead>
      {headCells.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={"left"}
          padding={"normal"}
          sortDirection={orderBy === headCell.id ? order : false}
        >
          <TableSortLabel
            sx={{ fontWeight: "bold" }}
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : "asc"}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableHead>
  );
}
