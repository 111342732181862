import { Snackbar, Alert, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIdentityContext } from "../context/IdentityContext";
import { useAppSelector } from "../redux/hooks";
import { getUserById } from "../redux/Slices/account.slice";

export const TwoFactorBanner = () => {
    const { authenticatedUser } = useIdentityContext();
    const user = useAppSelector(getUserById(authenticatedUser.userId));
    const [open, setOpen] = useState(!user?.twoFactorEnabled)
    const navigate = useNavigate();
    useEffect(() => {
        setOpen(!user?.twoFactorEnabled!)
    }, [user]);

    if (user) {
        return (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={() => setOpen(false)}
                key={'2fabanner'}
            >
                <Alert
                    onClose={() => setOpen(false)}
                    severity={'warning'}
                    sx={{ width: "100%" }}
                >
                    You have not activated two factor authentication, please complete this on the
                    <Button
                        onClick={() => { navigate(`/settings`) }}
                    >
                        Settings Page
                    </Button>
                </Alert>
            </Snackbar>
        )
    } else
        return (
            <></>
        )
}