
import * as React from 'react';
//import { TreeItem, TreeView } from '@mui/lab';
import { TreeView, TreeItem } from '@mui/x-tree-view';
import { faChevronRight, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CounterTree from './CounterTree';
import { Device } from '../../types/devices.types';
import { useEffect, useState } from 'react';



interface PerformanceCategoryTreeProps {
    performanceCategories: any[];
    device: Device; 
}

export default function PerformanceCategoryTree(props: PerformanceCategoryTreeProps) {
    const { performanceCategories, device } = props
    const [category, setCategory] = useState([])


    useEffect(() => {
        performanceCategories.map((value: any) => {
            if (value.id === device.id) {
                setCategory(value.categories)
            }
        })
    }, [performanceCategories]);
    return (

        <TreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<FontAwesomeIcon icon={faChevronDown} style={{ width: 10, height: 10 }}/>}
            defaultExpandIcon={<FontAwesomeIcon icon={faChevronRight} style={{ width: 10, height: 10 }}/>}
            sx={{ flexGrow: 1, maxWidth: 300, overflowY: 'auto' }}
        >
            {category.length > 0 ?
                (
                    category.map((category: any, index: any) => (
                        <TreeItem nodeId={index} label={category.objectName} >
                            <CounterTree counterNames={category.counterNames} device={device} objectName={category.objectName} />

                        </TreeItem>

                    )
                    )
                )
                :
                <TreeItem nodeId={'index'} label='No counters' />
            }

        </TreeView>


    );
}