import { faFileUser } from "@fortawesome/pro-light-svg-icons";
import { Loader } from "../../components/Loader";
// import { NoData } from "../../components/NoData";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAsyncEffect } from "../../common/hooks";
import { useEffect } from "react";
import { setLayout } from "../../redux/Slices/layout.slice";
import { RunAsAccountsTableSummary } from "./RunAsAccountsTableSummary";
import { getRunAsAccounts } from "../../redux/Slices/devices.slice";
import { fetchAccountTypes, fetchCheckAsAccounts, fetchRunAsProfileAndDevices, fetchRunAsProfiles } from "../../redux/Thunks/devices";
import { RunAsAccountTabPanelHeader } from "./RunAsAccountsTabPanelHeader";


export const RunAsAccountsDashboardPage = () => {
    const dispatch = useAppDispatch();
    const runAsAccounts = useAppSelector(getRunAsAccounts);
    useEffect(() => {
        dispatch(
            setLayout({
                title: "Run-As Accounts Dashboard",
                subtitle: "run-As Accounts Dashboard",
                icon: faFileUser,
            })
        );
    }, [dispatch]);

    const { loading } = useAsyncEffect(async () => {
        await Promise.all([
             dispatch(fetchAccountTypes()),
             dispatch(fetchRunAsProfiles()),
            // await dispatch(fetchRunAsAccounts()),
             dispatch(fetchRunAsProfileAndDevices()),
             dispatch(fetchCheckAsAccounts()),
    
        ]);
      }, []);




    return (
        <>
            {loading ? (
                <Loader show={true} />
            ) 
            // : runAsAccounts.length < 1 ? (
            //     <NoData
            //         icon={faFileUser}
            //         headerText="No active Run-As Accounts"
            //         subText="We couldn`t find any run-As Accounts"
            //     />
            // )
             : (
                <>
                    <RunAsAccountsTableSummary />
                    <RunAsAccountTabPanelHeader />
                </>
            )}
        </>
    );
};

