import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useFormikContext } from "formik";

export type FormikTextfieldProps = { name: string } & TextFieldProps;
export type FormikSelectFieldProps = { name: string } & SelectProps & {
    options: Array<{ label: string; value: any }>;
  };
export const FormikTextField = (props: FormikTextfieldProps) => {
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<Record<string, any>>();
  const { name, ...otherProps } = props;
  return (
    <TextField
      {...otherProps}
      name={name}
      onChange={handleChange}
      onBlur={handleBlur}
      value={values[name]}
      error={name !== undefined && touched[name] && Boolean(errors[name])}
      helperText={
        name !== undefined && touched[name] && errors[name]?.toLocaleString()
      }
    />
  );
};
export const FormikSelectField = (props: FormikSelectFieldProps) => {
  const { handleChange, handleBlur, errors, touched, values } =
    useFormikContext<Record<string, any>>();
  const { name, label, options, ...otherProps } = props;
  return (
    <FormControl
      fullWidth
      error={name !== undefined && touched[name] && Boolean(errors[name])}
    >
      <InputLabel id={`${label}-simple-select-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-simple-select-label`}
        label={label}
        {...otherProps}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
      >
        {options.map((option) => (
          <MenuItem value={option.value}>{option.label}</MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {name !== undefined && touched[name] && errors[name]?.toLocaleString()}
      </FormHelperText>
    </FormControl>
  );
};
