import dayjs from "dayjs";

export const onHandleTimeIntervalChange = (event: any) => {
    switch (event.target.value) {
        case 'Day':
            return dayjs().subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Week':
            return dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Month':
            return dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '2 Months':
            return dayjs().subtract(2, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '3 Months':
            return dayjs().subtract(3, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '4 Months':
            return dayjs().subtract(4, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '5 Months':
            return dayjs().subtract(5, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '6 Months':
            return dayjs().subtract(6, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '7 Months':
            return dayjs().subtract(7, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '8 Months':
            return dayjs().subtract(8, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '9 Months':
            return dayjs().subtract(9, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '10 Months':
            return dayjs().subtract(10, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '11 Months':
            return dayjs().subtract(11, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Year':
            return dayjs().subtract(1, "year").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'All Time':
            return dayjs().subtract(50, "year").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        default:
            return dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }


}

export const determineTimeDataForAlertsOverTime = (statisticResponse: any) => {
    let returnData: any = [];
    statisticResponse.forEach((stat: any) => {
        returnData.push(dayjs(stat.statsDate).format('MM/DD/YY'))
    })
    returnData.reverse()
    return returnData
}

export const determineSerierDataForAlertsOverTime = (statisticResponse: any) => {
    let formatArray: any = [];
    statisticResponse.forEach((stat: any) => {
        formatArray.push(stat.total)
    })
    formatArray.reverse()
    const returnData = [{name: 'Alerts', data: formatArray}]
    return returnData
}

export const determineRowDataForAlertsOverTime = (value: any) => {
    let returnData: any = []
    value.forEach((stat: any) => {
        returnData.push({value: dayjs(stat.statsDate).format('MM/DD/YY'), count: stat.total})
    })
    return returnData
}


export const determineMultiLineSeriesData = (value: any) => {
    let caData: any = []
    let avData: any = []
    let chData: any = []
    let customData: any = []
    let daData: any = []
    let ecData: any = []
    let phData: any = []
    let shData: any = []
    let siData: any = []
    let scData: any = []
    value.forEach((data: any) => {
        caData.push(data.categoryAlert)
        avData.push(data.categoryAvailabilityHealth)
        chData.push(data.categoryConfigurationHealth)
        customData.push(data.categoryCustom)
        daData.push(data.categoryDisabledAlert)
        ecData.push(data.categoryEventCollection)
        phData.push(data.categoryPerformanceHealth)
        shData.push(data.categorySecurityHealth)
        siData.push(data.categorySentinelIncident)
        scData.push(data.categoryStateCollection)
    })
    const formattedData= [
      //  {name: 'Category Alert', data: caData},
      //  { name: 'Availability Health', data: avData },
     //   {name: 'Configuration Health', data: chData},
      //  {name: 'Custom', data: customData},
     //   {name: 'Disabled Alerts', data: daData},
     //   {name: 'Event Collection', data: ecData},
     //   {name: 'Performance Health', data: phData},
     //   {name: 'Security Health', data: shData},
        {name: 'Sentinel Incident', data: siData},
     //   {name: 'State Collection', data: scData},
    ]
    return formattedData
}


export const determineAlertSeverityOverTime = (value: any) => {
    let error: any = []
    let high: any = []
    let informational: any = []
    let low: any = []
    let medium: any = []
    let warning: any = []
    value.forEach((data: any) => {
        error.push(data.severityError)
        high.push(data.severityHigh)
        informational.push(data.severityInformational)
        low.push(data.severityLow)
        medium.push(data.severityMedium)
        warning.push(data.severityWarning)
    })
    const formattedData= [
        {name: 'Error', data: error},
        {name: 'High', data: high},
        {name: 'Informational', data: informational},
        {name: 'Low', data: low},
        {name: 'Medium', data: medium},
        {name: 'Warning', data: warning},
    ]
    return formattedData
}

export const determineBarChartCategoriesAlertSource = (categories: any, overrides: any) => {
    let returnData: any = []
    categories.forEach((cat: any) => {
        returnData.push(cat.category)
    })
    if(overrides.length > 0){
        returnData.push('Disabled Alerts')
    }
    return returnData
}

export const determineBarChartDataForAlertSource = (categories: any, overrides: any) => {
    let returnData: any = []
    categories.forEach((cat: any) => {
        returnData.push(cat.totalCount)
    })
    if(overrides.length > 0){
        returnData.push(overrides.length)
    }
    return returnData
}

export const determinePieDataForAlertSource = (categories: any, overrides: any) => {
    let returnData: any = []
    categories.forEach((cat: any) => {
        returnData.push({name: cat.category, y: cat.totalCount})
    })
    if(overrides.length > 0){
        returnData.push({name: 'Disabled Alerts', y: overrides.length})
    }
    return returnData
}

export const determineRowDataForAlertSource = (categories: any, overrides: any) => {
    let returnData: any = []
    let totalCount: any = []
    categories.forEach((cat: any) => {
        totalCount.push(cat.totalCount)
    })
    if(overrides.length > 0){
        totalCount.push(overrides.length)
    }
    const total = totalCount.reduce((accumulator: any, currentValue: any) => accumulator + currentValue);
    
    categories.forEach((cat: any) => {
        returnData.push({value: cat.category, count: cat.totalCount, percentage: ((100 * cat.totalCount) / total).toFixed(2)})
    })
    if(overrides.length > 0){
        returnData.push({value: 'Disabled Alerts', count: overrides.length, percentage: ((100 * overrides.length) / total).toFixed(2)})
    }
    return returnData
}