import { Card, Box, Tabs, Tab } from "@mui/material"
import { useState } from "react"
import { TabPanel, a11yProps } from "../../common/DashboardTabs"
import AlertsEnhancedTable from "./AlertsEnhancedTable/AlertsEnhancedTable";
import { Alert } from "../../types/alerts.types";
import { DisabledAlertsEnhancedTable } from "../DisabledAlertsDashboard/DisabledAlertsEnhancedTable";
import { useAppSelector } from "../../redux/hooks";
import { getAlertOverrides } from "../../redux/Slices/alerts.slice";



interface AlertsTabPanelHeaderProps {
    alerts: Alert[];
    lastUpdated: any;
}

export const AlertsTabPanelHeader = (props: AlertsTabPanelHeaderProps) => {
    const { alerts, lastUpdated } = props
    const [value, setValue] = useState(0);
    const alertOverrides = useAppSelector(getAlertOverrides);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Card sx={{ border: "solid 1px #ddd", height: "auto", marginTop: 2 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Incidents" {...a11yProps(0)} />
                    <Tab label="" {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <AlertsEnhancedTable
                    alerts={alerts}
                    lastUpdated={lastUpdated}

                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DisabledAlertsEnhancedTable 
                  disabledAlerts={alertOverrides}
                />
            </TabPanel>
        </Card>
    )


}