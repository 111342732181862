import * as Mui from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { setAlertTableFilterValue, setAlertTablePage } from "../redux/Slices/alerts.slice";
import { Alert } from "../types/alerts.types";

interface StatisticsTileWithProgressProps {
  title: string;
  progressBarTitle: string;
  progressBarSubText: string;
  headerIcon: IconDefinition;
  progressPercentage: number;
  linkText: string;
  linkUrl: string;
  currentAlert?: Alert;
}

const StatisticsTileWithProgress: React.FC<StatisticsTileWithProgressProps> = (
  props
) => {
  var navigate = useNavigate();
  const dispatch = useAppDispatch();


  return (
    <Mui.Card sx={{ border: "solid 1px orange" }}>
      <Mui.CardHeader
        avatar={
          <FontAwesomeIcon icon={props.headerIcon} color={"orange"} size="lg" />
        }
        title={
          <Mui.Typography sx={{ mr: 2 }} fontWeight="bold">
            {props.title}
          </Mui.Typography>
        }
        sx={{
          borderBottom: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "#eee",
          p: 1,
        }}
      />
      <Mui.CardContent>
        <Mui.Stack direction="column" sx={{ pr: 1 }}>
          <Mui.Typography variant="h6">{props.progressBarTitle}</Mui.Typography>
          <Mui.Box sx={{ display: "flex", alignItems: "center" }}>
            <Mui.Box sx={{ width: "100%", mr: 1 }}>
              <Mui.LinearProgress
                variant="determinate"
                value={props.progressPercentage}
              />
            </Mui.Box>

            <Mui.Box sx={{ minWidth: 35 }}>
              <Mui.Typography
                variant="body2"
                color="text.secondary"
              >{`${props.progressPercentage.toFixed(2)}%`}</Mui.Typography>
            </Mui.Box>
          </Mui.Box>
          <Mui.Typography variant="subtitle1" color={"silver"}>
            {props.progressBarSubText}
          </Mui.Typography>
          <Link to={{ pathname: `${props.linkUrl}` }} 
          onClick={() =>{ 
          dispatch(setAlertTableFilterValue({alert: props.currentAlert!, filterRule: props.title}))
          dispatch(setAlertTablePage(1))}
          
          }>
            {props.linkText}&nbsp;
            <FontAwesomeIcon icon={faCaretRight} color={"orange"} />
          </Link>
        </Mui.Stack>
      </Mui.CardContent>
    </Mui.Card>
  );
};

export { StatisticsTileWithProgress };
