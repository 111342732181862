
import { CardContent, Typography, Card, Grid, Box, Button } from "@mui/material";


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};


interface CreateTicketFormProps {
    selectedModalRow: any;
    handleSearchParams: (value: any, key: string) => void;

}


export const AdditionalInformationModal = (props: CreateTicketFormProps) => {
    const { selectedModalRow, handleSearchParams } = props
    const infoArray = Object.entries(selectedModalRow)

    return (
        <Card sx={style}>
            <Typography variant="h3" component="h3">
                Additional Information
            </Typography>
            <CardContent>
                <Grid container spacing={2}>
                    {infoArray.map((value: any) => {
                        return (
                            <Grid item xs={12}>
                                <Typography sx={{ display: 'flex' }} noWrap={true}>
                                    <Box
                                        sx={value[0] !== 'Request URL' ?
                                            { fontWeight: 'bold' } :
                                            { fontWeight: 'bold', marginTop: '0.5vh' }}>
                                        {value[0]}:&nbsp;
                                    </Box>
                                    {value[0] !== 'Request URL' ?
                                        <Box >{value[1]}</Box>
                                        :
                                        <Button
                                            sx={{}}
                                            variant="text"
                                            onClick={() => props.handleSearchParams(value[1], 'requestURL')}>
                                            <Box style={{ width: '35vw', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                                {value[1]}
                                            </Box>
                                        </Button>
                                    }
                                </Typography>
                            </Grid>
                        )
                    })}
                </Grid>
            </CardContent>
        </Card>
    );
};
