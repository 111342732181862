import { createAsyncThunk } from "@reduxjs/toolkit";
import protectAxios from "../../api/axios";
import {
  AddCommentCommand,
  AssignTicketToEngineerCommand,
  RaiseGenericTicketCommand,
  RaiseTicketCommand,
  Ticket,
} from "../../types/tickets.types";
import {
  setTicketById,
  setTicketComments,
  setTickets,
  setOneTicketById,
  setTicketProblemCategoryCounts,
  setTicketStatusPerDay,
  setTicketRaisedBy,
  setTicketAssignedEngineer,
} from "../Slices/tickets.slice";

export const raiseSupportTicketGeneric = createAsyncThunk(
  "tickets/raiseSupportTicketGeneric",
  async ({
    command,
    identity,
  }: {
    command: RaiseGenericTicketCommand;
    identity: any;
  }) => {
    const createResp = await protectAxios
      .post("/ticketcommands/generic/raise", command)
      .then((resp) => resp.data);
    return createResp;
  }
);

export const raiseSupportTicketForAlert = createAsyncThunk(
  "tickets/raiseSupportTicketForAlert",
  async (command: RaiseTicketCommand) => {
    return await protectAxios
      .post("/ticketcommands/alert/raise", command)
      .then((resp) => resp.data);
  }
);

export const raiseSupportTicketForDevice = createAsyncThunk(
  "tickets/raiseSupportTicketForDevice",
  async (command: RaiseTicketCommand) => {
    return await protectAxios
      .post("/ticketcommands/device/raise", command)
      .then((resp) => resp.data);
  }
);

export const pickUpTicket = createAsyncThunk(
  "tickets/pickUpTicket",
  async (
    { ticket, engineerName }: { ticket: Ticket; engineerName: string },
    { dispatch }
  ) => {
    const pickupResp = await protectAxios
      .put(`/ticketcommands/${ticket.id}/pickup`, {})
      .then((resp) => resp.data);
    return pickupResp;
  }
);

export const assignTicketToEngineer = createAsyncThunk(
  "tickets/assignTicketToEngineer",
  async (
    {
      ticket,
      command,
    }: {
      ticket: Ticket;
      command: AssignTicketToEngineerCommand;
    },
    { dispatch }
  ) => {
    const assignResp = await protectAxios.put(
      `/ticketcommands/${ticket.id}/assign`,
      command
    );
    return assignResp;
  }
);

export const closeTicket = createAsyncThunk(
  "tickets/closeTicket",
  async (ticket: Ticket, { dispatch }) => {
    const closeResp = await protectAxios.put(
      `/ticketcommands/${ticket.id}/close`,
      {}
    );
    return closeResp;
  }
);

export const addComment = createAsyncThunk(
  "tickets/addComment",
  async ({
    ticketId,
    command,
  }: {
    ticketId: string;
    command: AddCommentCommand;
  }) => {
    return await protectAxios
      .put(`/ticketcommands/${ticketId}/comment`, command)
      .then((resp) => resp.data);
  }
);

export const fetchTicketById = createAsyncThunk(
  "tickets/getTicketById",
  async (ticketId: string, { dispatch }) => {
    const ticket = await protectAxios
      .get(`/ticketqueries/${ticketId}`)
      .then((resp) => resp.data);
    dispatch(setOneTicketById(ticket));
    dispatch(setTicketById(ticket));
  }
);

export const fetchAllTickets = createAsyncThunk(
  "tickets/getAllTickets",
  async (_, { dispatch }) => {
    const tickets = await protectAxios
      .get("/ticketqueries/all")
      .then((resp) => resp.data);
    dispatch(setTickets(tickets));
  }
);

export const fetchCommentsForTicket = createAsyncThunk(
  "tickets/getCommentsForTicket",
  async (ticketId: string, { dispatch }) => {
    const comments = await protectAxios
      .get(`/ticketqueries/${ticketId}/Comments`)
      .then((resp) => resp.data);
    dispatch(setTicketComments({ ticketId, comments }));
  }
);

export const fetchProblemCategoryCounts = createAsyncThunk(
  "tickets/fetchProblemCategoryCounts",
  async ({ companyId, startDate, endDate }: { companyId: string, startDate: string; endDate: string }, { dispatch }) => {
    const response = await protectAxios
    .get("/ticketqueries/problemcategorycounts", { params: { companyId, startDate, endDate } })
      .then((resp) => resp.data);
    dispatch(setTicketProblemCategoryCounts(response));
    return response;
  }
);

export const fetchTicketStatusPerDay = createAsyncThunk(
  "tickets/fetchTicketStatusPerDay",
  async ({ companyId, startDate, endDate }: { companyId: string, startDate: string; endDate: string }, { dispatch }) => {
    const response = await protectAxios
    .get("/ticketqueries/statusperday", { params: { companyId, startDate, endDate } })
      .then((resp) => resp.data);
    dispatch(setTicketStatusPerDay(response));
    return response;
  }
);

export const fetchTicketsRaisedBy = createAsyncThunk(
  "tickets/fetchTicketsRaisedBy",
  async ({ companyId, startDate, endDate }: { companyId: string, startDate: string; endDate: string }, { dispatch }) => {
    const response = await protectAxios
    .get("/ticketqueries/ticketsraisedby", { params: { companyId, startDate, endDate } })
      .then((resp) => resp.data);
    dispatch(setTicketRaisedBy(response));
    return response;
  }
);

export const fetchTicketsAssignedEngineer = createAsyncThunk(
  "tickets/fetchTicketsAssignedEngineer",
  async ({ companyId, startDate, endDate }: { companyId: string, startDate: string; endDate: string }, { dispatch }) => {
    const response = await protectAxios
    .get("/ticketqueries/ticketsassignedengineer", { params: { companyId, startDate, endDate } })
      .then((resp) => resp.data);
    dispatch(setTicketAssignedEngineer(response));
    return response;
  }
);