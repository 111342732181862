import { CustomThemeProvider } from "./themes/CustomThemeProvider";
import {
  protectOrgTheme,
  theme_light,
  theme_dark,
  theme_green,
} from "./themes/ProtectOrgTheme";
import { Route, Routes } from "react-router-dom";
import "reflect-metadata";
import * as Pages from "./features";

import { IdentityContextProvider } from "./context/IdentityContext";
import { AuthenticatedRoute } from "./routing/AuthenticatedRoute";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import AppSnackbar from "./components/AppSnackbar";
import ProtectedRoute from "./routing/ProtectedRoute";
// import { ChatBot } from "./layouts/PortalWithHeaders/ChatBot";

function App() {
  return (
    <CustomThemeProvider
      baseTheme={protectOrgTheme}
      defaultThemeName={"light"}
      palettes={[
        { name: "light", palette: theme_light },
        { name: "dark", palette: theme_dark },
        { name: "green", palette: theme_green },
      ]}
    >
      <IdentityContextProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div className="wrapper">
            <Routes>
              <Route path="/" element={<Pages.HomePage />} />
              <Route path="/signup" element={<Pages.SignupPage />} />
              <Route
                path="/confirmsignup/:id"
                element={<Pages.ConfirmSignupPage />}
              />
              <Route path="/termsofuse" element={<Pages.TermsOfUsePage />} />
              <Route
                path="/eula"
                element={<Pages.EndUserLicenseAgreementPage />}
              />
              <Route
                path="/confirminvitesignup/:id"
                element={<Pages.ConfirmInviteSignupPage />}
              />
              <Route
                path="/resetpassword"
                element={<Pages.ResetPasswordPage />}
              />
              <Route
                path="/confirmresetpassword/:id"
                element={<Pages.ConfirmResetPasswordPage />}
              />

              {/* ALL BELOW ROUTES REQUIRE LOGGED IN USER */}
              <Route element={<AuthenticatedRoute />}>
                <Route
                  path="/home"
                  // element={<Pages.HomeDashboard />}
                  element={
                    <ProtectedRoute
                      role={["Client", 'Company Admin']}
                      outlet={<Pages.HomeDashboard />}
                    />
                  }
                />
                <Route
                  path="/account"
                  // element={<Pages.StripeDashboard />}
                  element={
                    <ProtectedRoute
                      role={["Products Admin", 'Company Admin']}
                      outlet={<Pages.StripeDashboard />}
                    />
                  }
                />

                <Route
                  path="/alerts/:deviceId"
                  element={<Pages.AlertsDashboardPage />}
                />

                <Route
                  path="/new-alerts/:deviceId?"
                  element={<Pages.NewAlertsDashboard />}
                />

                <Route
                  path="/alerts"
                  element={
                    <ProtectedRoute
                      role={["Alerts Viewer", 'Company Admin']}
                      outlet={<Pages.AlertsDashboardPage />}
                    />
                  }
                />

                <Route path="/alert/:id" element={<Pages.ViewAlertPage />} />

                <Route
                  path="/charts"
                  // element={<Pages.ChartsDashboardPage />} />
                  element={
                    <ProtectedRoute
                      role={["Charts Viewer", 'Company Admin']}
                      outlet={<Pages.ChartsDashboardPage />}
                    />
                  }
                />
                <Route
                  path="/livemap"
                  // element={<Pages.LiveMap />} />
                  element={
                    <ProtectedRoute
                      role={["Map Admin", 'Company Admin']}
                      outlet={<Pages.LiveMap />}
                    />
                  }
                />
                <Route
                  path="/devices"
                  // element={<Pages.DevicesDashboardPage />}
                  element={
                    <ProtectedRoute
                      role={["Devices User", 'Company Admin']}
                      outlet={<Pages.DevicesDashboardPage />}
                    />
                  }
                />
                <Route
                  path="/device/:id/:group"
                  element={<Pages.ViewDevicePage />}
                />
                <Route
                  path="/changemanagement/create/:ticketId"
                  element={<Pages.CreateChangeManagementItemPage />}
                />
                <Route
                  path="/changemanagement/create"
                  // element={<Pages.CreateChangeManagementItemPage />}
                  element={
                    <ProtectedRoute
                      role={["Client", 'Company Admin']}
                      outlet={<Pages.CreateChangeManagementItemPage />}
                    />
                  }
                />
                <Route
                  path="/changemanagement/"
                  // element={<Pages.ChangeManagementDashboardPage />}
                  element={
                    <ProtectedRoute
                    role={["Client", 'Company Admin']}
                      outlet={<Pages.ChangeManagementDashboardPage />}
                    />
                  }
                />
                <Route
                  path="/changemanagement/:id"
                  element={<Pages.ViewChangeManagementItemPage />}
                />
                <Route
                  path="/tickets"
                  // element={<Pages.TicketsDashboardPage />}
                  element={
                    <ProtectedRoute
                      role={["End User", 'Company Admin']}
                      outlet={<Pages.TicketsDashboardPage />}
                    />
                  }
                />
                <Route path="/ticket/:id" element={<Pages.ViewTicketPage />} />
                <Route
                  path="/user/:id"
                  element={<Pages.ViewUserProfilePage />}
                />
                <Route
                  path="/settings"
                  // element={<Pages.SettingsPage />}
                  element={
                    <ProtectedRoute
                      role={['End User', 'Settings Administrator', 'Company Admin']}
                      outlet={<Pages.SettingsDashboardPage />}
                    />
                  }
                />
                <Route
                  path="/compliance"
                  element={<Pages.ComplianceDashboard />}
                />
                <Route
                  path="/users"
                  // element={<Pages.UsersDashboard />}
                  element={
                    <ProtectedRoute
                      role={["Company Admin"]}
                      outlet={<Pages.UsersDashboard />}
                    />
                  }
                />
                <Route
                  path="/runasaccounts"
                  // element={<Pages.RunAsAccountsDashboardPage />}
                  element={
                    <ProtectedRoute
                      role={["RunAs Administrator"]}
                     outlet={<Pages.RunAsAccountsDashboardPage />}
                    />
                  }
                />
                <Route
                  path="/investigate/:id"
                  element={<Pages.InvestigateAlertDashboardPage />}
                />
                <Route
                  path="/investigate/:id/:group"
                  element={<Pages.InvestigateDeviceDashboardPage />}
                />
                <Route
                  path="/darkweb"
                  // element={<Pages.FlareDashboardPage />}
                  element={
                    <ProtectedRoute
                      role={["Dark Web User", 'Company Admin']}
                      outlet={<Pages.FlareDashboardPage />}
                    />
                  }
                />
                <Route
                  path="/investigatefirewall"
                  // element={<Pages.InvestigateFirewallDashboardPage />}
                  element={
                    <ProtectedRoute
                      role={["Firewall Admin", 'Company Admin']}
                      outlet={<Pages.InvestigateFirewallDashboardPage />}
                    />
                  }
                />
                <Route
                  path="/reports"
                  // element={<Pages.ReportsDashboardPage />}
                  element={
                    <ProtectedRoute
                      role={["Reports Viewer", 'Company Admin']}
                      outlet={<Pages.ReportsDashboardPage />}
                    />
                  }
                />
              </Route>
            </Routes>
          </div>
          <AppSnackbar />
        </LocalizationProvider>
      </IdentityContextProvider>
      {/* <ChatBot /> */}
    </CustomThemeProvider>
  );
}

export default App;
