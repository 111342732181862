
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { DeviceChartData } from '../../types/devices.types';
import { determineChartType } from './ChartUtilities';
import { useAppSelector } from '../../redux/hooks';
import { getDevices } from '../../redux/Slices/devices.slice';


interface ChartProps {
    chartData: DeviceChartData;
}


export default function Chart(props: ChartProps) {
    const { chartData } = props
    const devices = useAppSelector(getDevices);
    const device = devices.filter(el => el.id === chartData.deviceId && el.deviceGroup === chartData.deviceGroup)
    const [options, setOptions] = useState({})



    const chartType: any = determineChartType(chartData, device)

    useEffect(() => {
        setOptions(chartType)
    }, [chartData]);


    return (
        <div>

            <HighchartsReact highcharts={Highcharts} options={options} />

        </div>
    );
}