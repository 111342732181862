import { useEffect, useState } from "react";

export const useAsyncEffect = (func: () => Promise<any>, deps: Array<any>) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    func().then((data) => {
      if (data) setData(data);
      setLoading(false);
    });
  }, deps);
  return { data, loading };
};
