import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export type Coordinate = {
  id: string;
  mapId: string;
  deviceIds: Array<string>;
  lat: number;
  lng: number;
  deviceGroupType: "" | "single" | "group";
  mapCoordinatesName: string;
};
export type Map = {
  id: string;
  companyId: string;
  name: string;
  description: string;
  lastModified: string;
  coordinates: Array<Coordinate>;
};

export interface MapsState {
  maps: Array<Map>;
}

const initialState: MapsState = {
  maps: [],
};

export const mapsSlice = createSlice({
  name: "maps",
  initialState,
  reducers: {
    setMaps: (state, action: PayloadAction<Array<Map>>) => {
      state.maps = action.payload;
    },
  },
});

export const getMaps = (state: RootState) => state.maps.maps;

export const { setMaps } = mapsSlice.actions;

export default mapsSlice.reducer;
