import { Box } from "@mui/material"
import { AlertsOverTime } from "./AlertsOverTime"
import { useAppSelector } from "../../../../redux/hooks";
import { getAlertOverrides } from "../../../../redux/Slices/alerts.slice";
import { AlertSource } from "./AlertSource";




export const AlertReportsTab = () => {
    const alertOverride = useAppSelector(getAlertOverrides)

    return (
        <Box>
            <AlertsOverTime />
            <AlertSource
                alertOverride={alertOverride}
            />
        </Box>
    )
}