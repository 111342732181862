import { Alert } from "../../../types/alerts.types";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Alert;
  label: string;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: "createdDate",
    numeric: true,
    disablePadding: true,
    label: "Created",
  },
  {
    id: "categoryDescription",
    numeric: true,
    disablePadding: true,
    label: "Origin",
  },
  {
    id: "deviceName",
    numeric: true,
    disablePadding: true,
    label: "Device",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Incident",
  },
  {
    id: "lastModifiedDate",
    numeric: true,
    disablePadding: false,
    label: "Last Modified",
  },
  {
    id: "repeatCount",
    numeric: true,
    disablePadding: false,
    label: "Repeat",
  },
  {
    id: "priorityDescription",
    numeric: true,
    disablePadding: false,
    label: "Priority",
  },
  {
    id: "investigate",
    numeric: true,
    disablePadding: false,
    label: "Investigate",
  },
  {
    id: "harvey",
    numeric: true,
    disablePadding: false,
    label: "Ask Harvey",
  },

];
