import * as Mui from "@mui/material";
import { faBooks } from "@fortawesome/pro-light-svg-icons";
import { CardHeader } from "../../components/CardHeader";
import "./KnowledgeBase.css";
import { Loader } from "../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getKBArticle, getAlertArticlesById } from "../../redux/Slices/alerts.slice";
import { Alert } from "../../types/alerts.types";
import { useAsyncEffect } from "../../common/hooks";
import { fetchAlertArticles } from "../../redux/Thunks/alerts";

interface AlertKnowledgeBaseProps {
  alert?: Alert;
}

export const AlertKnowledgeBase: React.FC<AlertKnowledgeBaseProps> = (
  props
) => {
  const { alert } = props;

  const dispatch = useAppDispatch();
  const articles = useAppSelector(getAlertArticlesById(alert?.id || ""));
  const knowledgeBaseArticle: any = useAppSelector(getKBArticle);
  
  const { loading } = useAsyncEffect(async () => {
    if (alert?.id && alert?.deviceGroup)
      await dispatch(
        fetchAlertArticles({
          alertId: alert.id,
          deviceGroup: alert.deviceGroup,
        })
      );
  }, [alert?.id]);
  const hasArticles = () => {
    return articles && articles[0] && articles[0].htmlContent;
  };
  return (
    <Mui.Card sx={{ border: "solid 1px #ddd", width: "100%" }}>
      <CardHeader
        icon={faBooks}
        subText={
          !loading
            ? `${hasArticles() ? "Related Info Found" : "No Info Found"}`
            : "Searching..."
        }
        title={"Knowledgebase articles about your incident"}
      />
      <Mui.CardContent sx={{}}>
        {loading ? (
          <Loader show={true} />
        ) : (
          knowledgeBaseArticle ?
            <div
              dangerouslySetInnerHTML={{
                __html: String(
                  knowledgeBaseArticle &&
                  knowledgeBaseArticle &&
                  knowledgeBaseArticle[0] &&
                  knowledgeBaseArticle[0].htmlContent
                ),
              }}
            />
            :
            <>
              <Mui.Typography sx={{ pl: 1, pt: 1 }}>
                Sorry, we don't have information available for this incident.
              </Mui.Typography>
            </>
        )}
      </Mui.CardContent>
    </Mui.Card>
  );
};
