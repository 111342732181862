import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Typography, CardContent, Grid, FormControl, TextField, IconButton, InputAdornment, Autocomplete, InputLabel, OutlinedInput, Button, ButtonGroup, Modal } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAccountTypes } from "../../redux/Slices/devices.slice";
import { getCompanies } from "../../redux/Slices/company.slice";
import { getUserById } from "../../redux/Slices/account.slice";
import { useIdentityContext } from "../../context/IdentityContext";
import { raiseRunAsAccount, fetchRunAsAccounts } from "../../redux/Thunks/devices";
import { Loader } from "../../components/Loader";



interface RunAsAccountCreateFormProps {
    onClose: () => void;
}
const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const RunAsAccountCreateForm = (props: RunAsAccountCreateFormProps) => {
    const { onClose } = props
    const dispatch = useAppDispatch();

    const [formValues, setFormValues] = useState({
        description: '',
        userName: '',
        password: '',
        passwordValidation: '',
        domain: '',
        runAsAccountTypeId: '',
        companyId: '',
    })
    const [isFormError, setIsFormError] = useState({
        description: false,
        password: false,
        passwordValidation: false,
        domain: false,
        runAsAccountTypeId: false,
        companyId: false,
    });
    const [loading, setLoading] = useState(false)
    const [passwordValidation, setPasswordValidation] = useState("");
    const [password, setPassword] = useState("");
    const identity = useIdentityContext();
    const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
    const accountTypes = useAppSelector(getAccountTypes);
    const filteredAccountTypes = accountTypes.filter((el) => el.name === "Windows");
    const accountStrings = filteredAccountTypes.map((el: any) => { return (el.name) })
    const companies = useAppSelector(getCompanies);
    const companiesString = companies.map((el: any) => { return (el.companyName) })
    const anyErrors = Object.values(isFormError).includes(true);

    // autocomplete will need to pass id not name to payload
    useEffect(() => {
        if (passwordValidation) {
            if (passwordValidation !== password) {
                setIsFormError({ ...isFormError, password: true });
                setIsFormError({ ...isFormError, passwordValidation: true });

            }
            if (passwordValidation === password) {
                setIsFormError({ ...isFormError, password: false });
                setIsFormError({ ...isFormError, passwordValidation: false });

            }
        }
    }, [password, passwordValidation]);

    const validateDomainName = (event: any) => {
        let clone: any = { ...formValues };
        setFormValues(clone)
        clone['domain'] = event.target.value
        setFormValues(clone)
        if (!event.target.value.includes("\\")) {
            setIsFormError({ ...isFormError, domain: true });
        }
        if (event.target.value.includes("\\")) {
            if (event.target.value.split("\\")[1].length > 0) {
                setIsFormError({ ...isFormError, domain: false });
            }
        }
    };
    const handleCriteriaChange = (value: any, key: string) => {
        let clone: any = { ...formValues };
        setFormValues(clone)
        clone[key] = value
        setFormValues(clone)
    }

    const onDelete = (key: string) => {
        let clone: any = { ...formValues }
        clone[key] = '';
        setFormValues(clone)
    }

    const resetFormValues = () => {
        setFormValues({
            description: '',
            userName: '',
            password: '',
            passwordValidation: '',
            domain: '',
            runAsAccountTypeId: '',
            companyId: '',
        })
        setIsFormError({
            description: false,
            password: false,
            passwordValidation: false,
            domain: false,
            runAsAccountTypeId: false,
            companyId: false,
        })
    }

    const onHandleSubmit = async () => {
        setLoading(true)
        const companyIdForPayload: any = companies.find((el: any) => el.companyName === formValues.companyId)
        const runAsAccountTypeIdForPayload: any = filteredAccountTypes.find((el: any) => el.name === formValues.runAsAccountTypeId)
        const payload = {
            description: formValues.description,
            userName: user!.emailAddress,
            password: formValues.password,
            domain: formValues.domain,
            runAsAccountTypeId: runAsAccountTypeIdForPayload.runAsAccountTypeId,
            companyId: companyIdForPayload.id,

        };
        const respsone = await dispatch(raiseRunAsAccount(payload));
        if (respsone.payload) {
            setLoading(false)
            await dispatch(fetchRunAsAccounts());
            onClose();
        }
    }

    return (
        <>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>
            <Card sx={style}>
                <Typography variant="h3" component="h3">
                    New Run-As Account
                </Typography>
                <CardContent>
                    <Grid container spacing={0}>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between', mb: 2, pr: 1 }}>
                            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                {/* <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Account Name'>Account Name</InputLabel> */}
                                <TextField
                                    label='Account Name'
                                    sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                                    id="accountName"
                                    size="small"
                                    value={formValues['domain']}
                                    helperText="Account name must be of the form: Domain\Username"
                                    error={isFormError.domain}
                                    onChange={(event) => validateDomainName(event)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    onDelete('domain')
                                                    setIsFormError({ ...isFormError, domain: false })
                                                }
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    color={"#f8931c"}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between', mb: 2 }}>
                            <FormControl sx={{ width: '100%', m: 0 }} variant="outlined" size='small'>
                                <Autocomplete
                                    id="runAsAccountTypeId"
                                    options={accountStrings}
                                    size="small"
                                    sx={{ display: 'flex', flexGrow: 1 }}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleCriteriaChange(newValue, 'runAsAccountTypeId')
                                    }}
                                    value={formValues['runAsAccountTypeId']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                display: 'flex',
                                                flexGrow: 1,
                                                "& .MuiSvgIcon-root": {
                                                    color: "rgb(250,165,66)",
                                                },
                                                label: { fontWeight: 'bold' }
                                            }}
                                            label='Account Type'
                                            value={formValues['runAsAccountTypeId']}
                                            onChange={(event) =>
                                                handleCriteriaChange(event.target.value, 'runAsAccountTypeId')
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between', mb: 2, pr: 1 }}>
                            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                <TextField
                                    label='Password'
                                    sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                                    id="password"
                                    size="small"
                                    value={formValues['password']}
                                    error={isFormError.password}
                                    type='password'
                                    onChange={(event) => {
                                        setPassword(event.target.value)
                                        handleCriteriaChange(event.target.value, 'password')
                                    }
                                    }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    onDelete('password')
                                                    setIsFormError({ ...isFormError, password: false })
                                                }
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    color={"#f8931c"}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between', mb: 2 }}>
                            <FormControl sx={{ width: '100%', m: 0 }} variant="outlined" size='small'>
                                <Autocomplete
                                    id="companyId"
                                    options={companiesString}
                                    size="small"
                                    sx={{ display: 'flex', flexGrow: 1 }}
                                    onChange={(event: any, newValue: string | null) => {
                                        handleCriteriaChange(newValue, 'companyId')
                                    }}
                                    value={formValues['companyId']}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{
                                                display: 'flex',
                                                flexGrow: 1,
                                                "& .MuiSvgIcon-root": {
                                                    color: "rgb(250,165,66)",
                                                },
                                                label: { fontWeight: 'bold' }
                                            }}
                                            label='Company'
                                            value={formValues['companyId']}
                                            onChange={(event) =>
                                                handleCriteriaChange(event.target.value, 'companyId')
                                            }
                                        />
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between', mb: 2, pr: 1 }}>
                            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                <TextField
                                    label='Confirm Password'
                                    sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                                    id="passwordValidation"
                                    size="small"
                                    value={formValues['passwordValidation']}
                                    error={isFormError.passwordValidation}
                                    type='password'
                                    helperText={isFormError.password ? "Must match password" : ""}

                                    onChange={(event) => {
                                        setPasswordValidation(event.target.value)
                                        handleCriteriaChange(event.target.value, 'passwordValidation')
                                    }
                                    }
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => {
                                                    onDelete('passwordValidation')
                                                    setIsFormError({ ...isFormError, passwordValidation: false })
                                                }
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    color={"#f8931c"}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        </InputAdornment>,
                                    }}

                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'space-between' }}>
                            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Description'>Description</InputLabel>
                                <OutlinedInput
                                    multiline={true}
                                    rows={4}
                                    label='Description'
                                    sx={{ display: 'flex', flexGrow: 1 }}
                                    id="description"
                                    size="small"
                                    value={formValues['description']}
                                    onChange={(event) =>
                                        handleCriteriaChange(event.target.value, 'description')
                                    }
                                    endAdornment={
                                        <IconButton
                                            onClick={() => onDelete('description')}
                                        >
                                            <FontAwesomeIcon
                                                icon={faXmark}
                                                color={"#f8931c"}
                                                size="xs"
                                            />
                                        </IconButton>
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                            <ButtonGroup >
                                <Button
                                    sx={{ mt: 3 }}
                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    onClick={() => resetFormValues()}
                                >
                                    Clear All
                                </Button>
                                <Button
                                    sx={{ mt: 3 }}

                                    variant="outlined"
                                    color="primary"
                                    type="submit"
                                    disabled={anyErrors}
                                    onClick={onHandleSubmit}
                                >
                                    Create
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}