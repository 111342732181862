import * as Mui from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface CardHeaderProps {
  title: string;
  subText: string;
  icon: IconDefinition;
  actions?: React.ReactNode;
}

//TODO: REMOVE WHEN WE START USING CARD COMPONENT EVERYWHERE

export const CardHeader: React.FC<CardHeaderProps> = (props) => {
  return (
    <Mui.CardHeader
      avatar={<FontAwesomeIcon icon={props.icon} color={"orange"} size="lg" />}
      subheader={
        <Mui.Typography variant="subtitle2" color="silver">
          {props.subText}
        </Mui.Typography>
      }
      title={props.title}
      sx={{
        borderBottom: "solid",
        borderBottomWidth: "1px",
        borderBottomColor: "orange",
        p: 1,
      }}
      // action= {
      //     <>
      //         {props.actions ? props.actions : <></>}
      //         <Mui.IconButton aria-label="settings" sx={{pr:0}}>
      //             <FontAwesomeIcon icon={faCircleInfo} color={'orange'} size="sm"  />
      //         </Mui.IconButton>
      //         <Mui.IconButton aria-label="settings" sx={{pr:0}}>
      //              <FontAwesomeIcon icon={faCirclePlus} color={'orange'} size="sm"  />
      //         </Mui.IconButton>
      //         <Mui.IconButton aria-label="settings" >
      //              <FontAwesomeIcon icon={faCircleMinus} color={'orange'} size="sm"  />
      //         </Mui.IconButton>
      //         <Mui.IconButton aria-label="settings">
      //             <FontAwesomeIcon icon={faEllipsisV} color={'palette.primary'} size="sm"  />
      //         </Mui.IconButton>
      //     </>
      // }
    />
  );
};
