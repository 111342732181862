import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useIdentityContext } from "../../context/IdentityContext";
import { useAppSelector } from "../../redux/hooks";
import { getRedirectRoute } from "../../redux/Slices/account.slice";

export const HomePage: React.FC = () => {
  var identityContext = useIdentityContext();
  const redirectRoute = useAppSelector(getRedirectRoute);
  useEffect(() => {
    if (!identityContext.authenticatedUser.isLoggedIn)
      setTimeout(() => {
        identityContext.onLogin();
      }, 500);
  }, []);

  const Notification: React.FC<{ role: String }> = (props) => {
    var location = useLocation();
    console.debug(location.pathname, redirectRoute);
    if (redirectRoute && redirectRoute !== "/") {
      return <Navigate to={redirectRoute}></Navigate>;
    }
    switch (props.role) {
      case "companyadmin":
        return <Navigate to={"/home"}></Navigate>;
      case "engineer":
        return <Navigate to={"/tickets"}></Navigate>;
      case "manager":
        return <Navigate to={"/tickets"}></Navigate>;
      case "client":
        return <Navigate to={"/alerts"}></Navigate>;
      case "approver":
        return <Navigate to={"/changemanagement"}></Navigate>;
      case "signatory":
        return <Navigate to={"/changemanagement"}></Navigate>;
      default:
        return <Navigate to={"/alerts"}></Navigate>;
    }
  };

  return identityContext.authenticatedUser.isLoggedIn ? (
    <Notification role={identityContext.authenticatedUser.defaultRole} />
  ) : (
    <>Logging In...</>
  );
};
