import { Button, Modal, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { fetchInvestigateAlert, fetchIpLocation } from "../../redux/Thunks/alerts";
import { Loader } from "../../components/Loader";
import { IPLocation } from "../../types/alerts.types";

interface DeviceNameCellProps {
    deviceName: any;
    currentAlert: any;
}

export const DeviceNameCell = (props: DeviceNameCellProps) => {
    const { deviceName, currentAlert } = props
    const [open, setOpen] = useState(false);
    const [tooltipTitle, setTooltipTitle] = useState<any>('')
    const [IPResponce, setIPResponse] = useState<IPLocation>()
    const [loading, setLoading] = useState(false)
    const [validIP, setValidIP] = useState(false)
    const dispatch = useAppDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const ValidateIPaddress = (ipaddress: string) => {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }

    const handleOpen = async (name: string) => {
        const validEntity = ValidateIPaddress(name)
        setValidIP(validEntity)
        if (validEntity) {
            const response = await dispatch(fetchIpLocation(name))
            setIPResponse(response.payload)
            setOpen(true);
        } if (!validEntity) {
            setOpen(true)
        }
    };


    const buildTooltip = () => {
        let ipArray: any[] = []
        if (validIP) {
            const titleCase = {
                'Ip': IPResponce?.ip,
                'City': IPResponce?.city,
                'Country': IPResponce?.country,
                'Country Code': IPResponce?.countryCode,
                'Isp': IPResponce?.isp,
                'Org': IPResponce?.org,
                'region': IPResponce?.region,
                'Region Name': IPResponce?.regionName,
                'Zip': IPResponce?.zip,
            }
            Object.entries(titleCase).forEach((value: any) => {
                ipArray.push(<Typography>{value[0].charAt(0).toUpperCase() + value[0].slice(1)}: {value[1]}</Typography>)
            })
        } if (!validIP) {
            ipArray.push(<Typography>{''}</Typography>)
        }
        return ipArray
    }

    useEffect(() => {
        setTooltipTitle(buildTooltip())
    }, [IPResponce, validIP]);


    const handleClickDeviceName = async (deviceName: string) => {
        setLoading(true)

        const command = `${currentAlert?.id}?investigate=${deviceName}`
        const response = await dispatch(fetchInvestigateAlert(command));
        if (response.payload) {
            setLoading(false)
        }
    }

    return (
        <>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>
            <Tooltip
                open={open}
                onClose={handleClose}
                onOpen={() => handleOpen(deviceName)}
                title={IPResponce ? tooltipTitle : ''}
            >
                <Button
                    variant="text"
                    onClick={() => handleClickDeviceName(deviceName)}>
                    {deviceName}
                </Button>
            </Tooltip>
        </>
    )
}