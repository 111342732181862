import { Alert, AlertTotals } from "../types/alerts.types";

export const determineAlertStatus = (totals: AlertTotals, alerts: Alert[]) => {
  return {
    error: totals.error,
    openAlerts: alerts.length,
    monitored: totals.beingMonitored,
  };
};

export const determineAlertPriority = (alerts: Alert[]) => {
  const low = alerts.filter((el: any) => el.priority === 0)
  const medium = alerts.filter((el: any) => el.priority === 1)
  const high = alerts.filter((el: any) => el.priority === 2)
  const alertPriorityCount = {
    low: low.length,
    medium: medium.length,
    high: high.length,
    total: alerts.length
  }
  return alertPriorityCount;
};
