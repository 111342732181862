export const boxStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    overflow: 'hidden'
};

export const headerStyle = {
    width: '100%',
    backgroundColor: 'rgb(57,90,122)',
    height: '7vh'
}

export const headerTextStyle = {
    color: 'white',
    display: 'inline-block',

}

export const harveyIconStyle = {
    display: 'inline',
    padding: '5px 25px 5px 5px'
}

export const mainBoxStyle = {
    maxHeight: '63vh',
    overflowY: "auto",
}

export const harveyChatStyle = {
    p: 1,
    margin: '10px 120px 10px 10px',
    borderRadius: '10px',
    border: '3px solid rgb(232,143,0)',
}

export const userChatStyle = {
    p: 1,
    margin: '10px 10px 10px 120px',
    borderRadius: '10px',
    border: '3px solid rgb(57,90,122)',
}

export const textBoxStyle = {
    position: 'absolute',
    bottom: 0,
    width: '98%',
    margin: '0px 6px 5px 6px',
    padding: '0px 10px 0px 10px',
    backgroundColor: 'white'
}

export const chatIconStyle = {
    margin: '20px 0 0 30px',
}
