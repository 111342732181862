import { Box } from "@mui/material"
import { useAppSelector } from "../../../../redux/hooks"
import { getSummaries } from "../../../../redux/Slices/changeManagement.slice"
import { CMStatus } from "./CMStatus"


export const CMReportsTab = () => {
    const CMSummaries = useAppSelector(getSummaries)

    return (
        <Box>
            <CMStatus
                CMSummaries={CMSummaries}
            />
        </Box>
    )
}