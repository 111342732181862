import React, { useEffect } from "react";
import * as Mui from "@mui/material";
import {
  faShieldHalved,
  faRobot,
  faPersonDigging,
  faStar,
  faList,
  faScrewdriverWrench,
} from "@fortawesome/pro-light-svg-icons";

import { Card } from "../../components/Card";
import { ComplianceOverviewTable } from "./ComplianceOverviewTable";
import { CyberRiskMediationTable } from "./CyberRiskMediationTable";
import { useAppDispatch } from "../../redux/hooks";
import { setLayout } from "../../redux/Slices/layout.slice";

const ComplianceDashboard = () => {
  /**
   * render component
   * @returns the rendered component
   */
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setLayout({
        title: "Compliance",
        subtitle: "Compliance",
        icon: faShieldHalved,
      })
    );
  }, [dispatch]);

  return (
    <>
      <Mui.Grid container spacing={2}>
        <Mui.Grid item xs={12} sm={12} md={6} lg={4}>
          <Card
            id="widget-total-score"
            headerIcon={faStar}
            headerTitle={`Total Compliance Score`}
            headerSubText={`The calculated compliance score for your org.`}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={null}
            cardContent={
              <section>
                <div>
                  <span style={{ fontSize: "72px", lineHeight: 1 }}>579</span>
                  <span style={{ fontSize: "40px" }}>&nbsp;/&nbsp;</span>
                  <span style={{ fontSize: "30px", color: "grey" }}>1539</span>
                  <span style={{ fontSize: "12px", color: "grey" }}>
                    &nbsp; Compliance Score
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "14px", color: "green" }}>
                    ~ typically gaining 10 points per day
                  </span>
                </div>
              </section>
            }
          />
        </Mui.Grid>

        <Mui.Grid item xs={12} sm={12} md={6} lg={4}>
          <Card
            id="widget-manual-tasks"
            headerIcon={faPersonDigging}
            headerTitle={`Remaining Manual Tasks`}
            headerSubText={`The remaining number of manual tasks to reach compliance.`}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={null}
            cardContent={
              <section>
                <div>
                  <span style={{ fontSize: "72px", lineHeight: 1 }}>156</span>
                  <span style={{ fontSize: "40px" }}>&nbsp;/&nbsp;</span>
                  <span style={{ fontSize: "30px", color: "grey" }}>853</span>
                  <span style={{ fontSize: "12px", color: "grey" }}>
                    &nbsp; Manual Tasks
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "14px", color: "green" }}>
                    ~ typically closing 2 tasks per day
                  </span>
                </div>
              </section>
            }
          />
        </Mui.Grid>

        <Mui.Grid item xs={12} sm={12} md={6} lg={4}>
          <Card
            id="widget-automated-tasks"
            headerIcon={faRobot}
            headerTitle={`Remaining Automated Tasks`}
            headerSubText={`The remaining number of automated tasks to reach compliance.`}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={null}
            cardContent={
              <section>
                <div>
                  <span style={{ fontSize: "72px", lineHeight: 1 }}>423</span>
                  <span style={{ fontSize: "40px" }}>&nbsp;/&nbsp;</span>
                  <span style={{ fontSize: "30px", color: "grey" }}>686</span>
                  <span style={{ fontSize: "12px", color: "grey" }}>
                    &nbsp; Automated Tasks
                  </span>
                </div>
                <div>
                  <span style={{ fontSize: "14px", color: "green" }}>
                    ~ typically closing 2 tasks per day
                  </span>
                </div>
              </section>
            }
          />
        </Mui.Grid>

        <Mui.Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card
            id="widget-compliance-overview"
            headerIcon={faList}
            headerTitle={`Compliance Overview`}
            headerSubText={`The state of compliance for your organization.`}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={null}
            cardContent={
              <div>
                <ComplianceOverviewTable></ComplianceOverviewTable>
              </div>
            }
          />
        </Mui.Grid>

        <Mui.Grid item xs={12} sm={12} md={12} lg={6} xl={5}>
          <Card
            id="widget-cyber-risk-mediation"
            headerIcon={faScrewdriverWrench}
            headerTitle={`Cyber Risk Mediation`}
            headerSubText={`Easy one-click solutions for reducing your cyber risk.`}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={null}
            cardContent={
              <div>
                <CyberRiskMediationTable></CyberRiskMediationTable>
              </div>
            }
          />
        </Mui.Grid>
      </Mui.Grid>
    </>
  );
};

export { ComplianceDashboard };
