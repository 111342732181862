import * as React from "react";
import * as Mui from "@mui/material";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { AddPlanCheck } from "./AddPlanCheck";
import { StepHeader } from "./StepHeader";
import { UserSummary } from "../../types/account.types";
import {
  ChangeManagementPlan,
  ChangeManagementTemplate,
} from "../../types/changemanagement.types";

interface AddPlanProps {
  plans: ChangeManagementPlan[];
  onNewPlanAdded: (title: string[]) => void;
  onPlanCheckAdded: (
    planIndex: number,
    description: string,
    requiresCheck: boolean,
    requiresEvidence: boolean
  ) => void;
  onPlanCheckRemoved: (planIndex: number, checkIndex: number) => void;
  onStepComplete: (stepNumber: number) => void;
  onEditStep: (stepNumber: number) => void;
  onPlanRemoved: (planIndex: number) => void;
  onUserAssigned: (
    planIndex: number,
    selectedUserId: string,
    selectedUserName: string
  ) => void;
  stepNumber: number;
  currentStep: number;
  isComplete: boolean;
  users: UserSummary[];
  templates: ChangeManagementTemplate[];
}

const AddPlanTodo: React.FC = () => {
  return (
    <>
      <Mui.Typography>Add your plans</Mui.Typography>
      <Mui.Typography variant="subtitle2">
        Add any plans that are required as part of change management e.g.
        implementation, testing etc.
      </Mui.Typography>
    </>
  );
};

const AddPlanSummary: React.FC<{ planCount: number }> = (props) => {
  return (
    <Mui.Typography>{`Currently there are ${props.planCount} plan(s) defined.`}</Mui.Typography>
  );
};

interface IAddPlanForm {
  title: string;
  assignedUser?: string;
}

export const AddPlan: React.FC<AddPlanProps> = (props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [selectedTemplateId, setSelectedTemplateId] =
    React.useState<string>("");

  const handleAccordianExpanded =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const addPlanForm = useForm<IAddPlanForm>();

  const onAddPlanSubmit: SubmitHandler<FieldValues> = (data) => {
    var plans = new Array<string>();
    plans.push(data.title);
    props.onNewPlanAdded(plans);
    addPlanForm.reset();
  };

  const handleSubmitTemplate = () => {
    if (selectedTemplateId !== "") {
      const selectedTemplate = props.templates.find((obj) => {
        return obj.id === selectedTemplateId;
      });

      if (selectedTemplate) {
        props.onNewPlanAdded(selectedTemplate.items.map((i) => i.title));
      }
    }
  };

  const addPlanFormComplete = () => {
    props.onStepComplete(props.stepNumber);
  };

  return (
    <Mui.Card
      sx={{
        borderStyle: "solid",
        borderWidth: props.currentStep === props.stepNumber ? "3px" : "1px",
        borderColor:
          props.currentStep === props.stepNumber
            ? "orange"
            : props.isComplete
            ? "limegreen"
            : "#ddd",
        height: "100%",
      }}
    >
      <form name="addplan">
        <StepHeader
          stepNumber={props.stepNumber + 1}
          headerSubText=""
          headerText="Plans"
          isComplete={props.isComplete}
        />
        <Mui.CardContent sx={{ pt: 0 }}>
          {props.isComplete && props.currentStep !== props.stepNumber ? (
            <AddPlanSummary planCount={props.plans.length} />
          ) : !props.isComplete && props.currentStep !== props.stepNumber ? (
            <AddPlanTodo />
          ) : (
            <>
              <Mui.Typography color={"orange"} sx={{ width: "100%", mb: 1 }}>
                Add a new plan
              </Mui.Typography>

              <Mui.Box
                justifyContent="flex-start"
                alignItems="flex-start"
                component="span"
                display="flex"
                sx={{ mb: 2 }}
              >
                <Controller
                  render={({ field: { name, value, onChange } }) => (
                    <Mui.TextField
                      name={name}
                      value={value}
                      label={"Plan Title"}
                      sx={{ minWidth: "80%", maxWidth: "80%" }}
                      size="small"
                      onChange={onChange}
                      error={addPlanForm.formState.errors.title !== undefined}
                      helperText={
                        addPlanForm.formState.errors.title !== undefined
                          ? "Please enter a title for the plan"
                          : ""
                      }
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          ev.preventDefault();
                        }
                      }}
                    />
                  )}
                  control={addPlanForm.control}
                  name={"title"}
                  defaultValue=""
                  rules={{ required: { value: true, message: "" } }}
                />

                <Mui.Button
                  variant="outlined"
                  sx={{ pt: 1, pb: 1, ml: "auto" }}
                  onClick={addPlanForm.handleSubmit(onAddPlanSubmit)}
                >
                  Add a new plan
                </Mui.Button>
              </Mui.Box>

              <Mui.Typography color={"orange"} sx={{ width: "100%", mb: 1 }}>
                <Mui.Box component="span" fontWeight="bold">
                  Or
                </Mui.Box>{" "}
                select a template
              </Mui.Typography>
              <Mui.Box
                justifyContent="flex-start"
                alignItems="flex-start"
                component="span"
                display="flex"
                sx={{ mb: 3 }}
              >
                <Mui.Select
                  labelId=""
                  id="assignto"
                  //value={props.plan.assignedToId}
                  size="small"
                  //onChange={handleUserAssign}
                  sx={{ minWidth: "80%", maxWidth: "80%" }}
                >
                  {props.templates.map((template, index) => {
                    return (
                      <Mui.MenuItem
                        key={template.id}
                        value={template.id}
                        onClick={() => setSelectedTemplateId(template.id)}
                      >
                        {template.title} - {template.description}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>

                <Mui.Button
                  variant="outlined"
                  sx={{ pt: 1, pb: 1, ml: "auto" }}
                  onClick={handleSubmitTemplate}
                >
                  Use Template
                </Mui.Button>
              </Mui.Box>

              <Mui.Divider />
              <Mui.Box sx={{ mt: 2 }}>
                <Mui.Typography color={"orange"} fontWeight="bold">
                  Existing Plans
                </Mui.Typography>
                {props.plans.map(
                  (plan: ChangeManagementPlan, index: number) => {
                    return (
                      <AddPlanCheck
                        onUserAssigned={props.onUserAssigned}
                        users={props.users}
                        onPlanRemove={props.onPlanRemoved}
                        plan={plan}
                        planIndex={index}
                        isExpanded={expanded === `panel${index}`}
                        onAccordianExpanded={handleAccordianExpanded}
                        onCheckAdded={props.onPlanCheckAdded}
                        onCheckRemoved={props.onPlanCheckRemoved}
                      />
                    );
                  }
                )}
              </Mui.Box>
            </>
          )}
        </Mui.CardContent>
        <Mui.CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
          {props.currentStep === props.stepNumber ? (
            <Mui.Button
              variant="outlined"
              onClick={() => addPlanFormComplete()}
            >
              {props.plans.length === 0
                ? `Save (i'm ok with no plans)`
                : "Save"}
            </Mui.Button>
          ) : (
            <></>
          )}
          {props.currentStep !== props.stepNumber ? (
            <Mui.Button
              variant="outlined"
              onClick={() => props.onEditStep(props.stepNumber)}
            >
              Edit
            </Mui.Button>
          ) : (
            <></>
          )}
        </Mui.CardActions>
      </form>
    </Mui.Card>
  );
};
