import { createAsyncThunk } from "@reduxjs/toolkit";
import protectAxios from "../../api/axios";
import {
  Alert,
  AlertStatistics,
  AlertTotals,
  Article,
  CommonSecurityLog,
  DateTotal,
  DisableAlertOverrideCommand,
  EnableAlertOverrideCommand,
  StartDisableOverrideCommand,
  StartEnableOverrideCommand,
} from "../../types/alerts.types";
import {
  setAlertArticles,
  setAlerts,
  setAlertStatistics,
  setAlertTotals,
  setAlertTotalsClosedByDay,
  setAlertTotalsCreatedByDay,
  setTotalNumberOfActiveAlerts,
  setAlertsWithPagination,
  setAlertOverrides,
  setKBArticle,
  setAlertClassification,
  setInvestigatedAlertResponse,
  setIpLocation,
  setInvestigatedDeviceResponse,
  setFlareAlertResponse,
  setFirewallResponse,
  setAlertCategory,
  setDailyAlertStatistics,
  setAlertsPaginatedCount,
} from "../Slices/alerts.slice";

export const fetchSearchAlerts = createAsyncThunk(
  "alerts/fetchSearchAlerts",
  async (
    {
      pageNumber,
      pageSize,
      sortBy,
      filter,
    }: {
      pageNumber: number;
      pageSize: number;
      sortBy: string;
      filter: Record<keyof Alert, string> | {};
    },
    { dispatch }
  ) => {
    console.debug({
      params: { sortBy, ...filter },
    });
    const alerts = await protectAxios
      .get(`/alertqueries/Active/${pageSize}/${pageNumber}`, {
        params: {
          sortBy: sortBy,
          filter: Object.keys(filter)
            //@ts-expect-error
            .map((key) => `${key}="${filter[key]}"`)
            .join(""),
        },
        paramsSerializer: {
          encode: (param) => encodeURIComponent(param).replaceAll("+", "%20"),
        },
      })
      //@ts-expect-error
      .then((resp) => ({ data: resp.data, extraData: resp.extraData }));

    dispatch(setAlertsWithPagination(alerts.data));
    dispatch(setAlertsPaginatedCount(alerts.extraData.totalMatchingAlerts));
  }
);

export const fetchAlertStatistics = createAsyncThunk(
  "alerts/getAlertStatistics",
  async (id: string, { dispatch }): Promise<AlertStatistics> => {
    const statistics = await protectAxios
      .get(`/alertqueries/statistics/${id}`)
      .then((resp) => resp.data);
    dispatch(setAlertStatistics({ alertId: id, statistics }));
    return statistics;
  }
);

export const fetchActiveAlerts = createAsyncThunk(
  "alerts/fetchActiveAlerts",
  async (_, { dispatch }) => {
    const alerts = await protectAxios
      .get("/alertqueries")
      .then((resp) => resp.data);
    dispatch(setAlerts(alerts));
    return alerts;
  }
);

export const fetchAlertById = createAsyncThunk(
  "alerts/fetchAlertById",
  async (id: string, { dispatch }) => {
    const alert = await protectAxios
      .get(`/alertqueries/${id}`)
      .then((resp) => resp.data);
    return alert;
  }
);

export const fetchAlertArticles = createAsyncThunk(
  "alerts/getAlertArticles",
  async (
    { alertId, deviceGroup }: { alertId: string; deviceGroup: string },
    { dispatch }
  ): Promise<Article> => {
    const articles = await protectAxios
      .get(`/alertqueries/knowledgearticles/${alertId}/${deviceGroup}`)
      .then((resp) => resp.data);
    dispatch(setKBArticle(articles));
    dispatch(setAlertArticles({ alertId, articles }));
    return articles;
  }
);

export const fetchAlertsTotals = createAsyncThunk(
  "alerts/getAlertsTotals",
  async (_, { dispatch }): Promise<AlertTotals> => {
    const alertsTotals = await protectAxios
      .get("/alertqueries/currenttotals")
      .then((resp) => resp.data);
    dispatch(setAlertTotals(alertsTotals));
    return alertsTotals;
  }
);

export const fetchTotalActiveAlerts = createAsyncThunk(
  "alerts/getTotalActiveAlerts",
  async (_, { dispatch }): Promise<AlertTotals> => {
    const activeAlertsTotal = await protectAxios
      .get("/alertqueries/totalnumberofactivealerts")
      .then((resp) => resp.data);
    dispatch(setTotalNumberOfActiveAlerts(activeAlertsTotal));
    return activeAlertsTotal;
  }
);

export const fetchAlertTotalsCreatedByDay = createAsyncThunk(
  "alerts/getAlertTotalsCreatedByDay",
  async (_, { dispatch }): Promise<DateTotal[]> => {
    const alertsTotalsCreated = await protectAxios
      .get("/alertqueries/createdbyday")
      .then((resp) => resp.data);
    dispatch(setAlertTotalsCreatedByDay(alertsTotalsCreated));
    return alertsTotalsCreated;
  }
);

export const fetchAlertTotalsClosedByDay = createAsyncThunk(
  "alerts/getAlertTotalsClosedByDay",
  async (_, { dispatch }): Promise<DateTotal[]> => {
    const alertsTotalsClosed = await protectAxios
      .get("/alertqueries/closedbyday")
      .then((resp) => resp.data);
    dispatch(setAlertTotalsClosedByDay(alertsTotalsClosed));
    return alertsTotalsClosed;
  }
);

export const fetchAlertCategory = createAsyncThunk(
  "alerts/getAlertCategory",
  async (
    {
      companyId,
      startDate,
      endDate,
    }: { companyId: string; startDate: string; endDate: string },
    { dispatch }
  ) => {
    const response = await protectAxios
      .get("/alertqueries/alertcategory", {
        params: { companyId, startDate, endDate },
      })
      .then((resp) => resp.data);
    dispatch(setAlertCategory(response));
    return response;
  }
);

export const fetchDailyAlertStatistics = createAsyncThunk(
  "alerts/getDailyAlertStatitics",
  async (
    {
      companyId,
      startDate,
      endDate,
    }: { companyId: string; startDate: string; endDate: string },
    { dispatch }
  ) => {
    const response = await protectAxios
      .get("/alertqueries/dailyalertstatistics", {
        params: { companyId, startDate, endDate },
      })
      .then((resp) => resp.data);
    dispatch(setDailyAlertStatistics(response));
    return response;
  }
);

export const fetchAlertWithPagination = createAsyncThunk(
  "alerts/getAlertWithPagination",
  async (
    { pageSize, pageNumber }: { pageSize: number; pageNumber: number },
    { dispatch }
  ): Promise<number> => {
    const alerts = await protectAxios
      .get(`/alertqueries/active/${pageSize}/${pageNumber}`)
      .then((resp) => resp.data);
    dispatch(setAlertsWithPagination(alerts));
    return alerts;
  }
);

export const enableAlertOverride = createAsyncThunk(
  "alerts/enableAlertOverride",
  async ({ command }: { command: EnableAlertOverrideCommand }) => {
    const resp = await protectAxios.put(
      `/alertcommands/enablealertoverride`,
      command
    );
    return resp.data;
  }
);

export const disableAlertOverride = createAsyncThunk(
  "alerts/disableAlertOverride",
  async ({ command }: { command: DisableAlertOverrideCommand }) => {
    const resp = await protectAxios.put(
      `/alertcommands/disablealertoverride`,
      command
    );
    return resp.data;
  }
);

export const startTaskDisableAlertOverride = createAsyncThunk(
  "alerts/startTaskDisableAlertOverride",
  async (command: StartDisableOverrideCommand) => {
    return await protectAxios
      .post("/alertcommands/startTaskDisableAlertOverride", command)
      .then((resp) => resp.data);
  }
);

export const startTaskEnableAlertOverride = createAsyncThunk(
  "alerts/startTaskEnableAlertOverride",
  async (command: StartEnableOverrideCommand) => {
    return await protectAxios
      .post("/alertcommands/startTaskEnableAlertOverride", command)
      .then((resp) => resp.data);
  }
);

export const fetchAlertOverrides = createAsyncThunk(
  "alerts/fetchAlertOverrides",
  async (_, { dispatch }) => {
    const alerts = await protectAxios
      .get("/alertqueries/AlertOverrides")
      .then((resp) => resp.data);
    dispatch(setAlertOverrides(alerts));
    return alerts;
  }
);

export const fetchTaskStatus = createAsyncThunk(
  "alerts/fetchTaskStatus",
  async (taskId: string, { dispatch }) => {
    const status = await protectAxios
      .get(`/alertcommands/getTaskStatus/${taskId}`)
      .then((resp) => resp.data);
    return status;
  }
);

// INVESTIGATE //

export const fetchClassificationOfAlert = createAsyncThunk(
  "alerts/fetchClassificationOfalert",
  async (alertId: string, { dispatch }) => {
    const classification = await protectAxios
      .get(`/alertqueries/investigate/classificationofalert/${alertId}`)
      .then((resp) => resp.data);
    dispatch(setAlertClassification(classification));
    return classification;
  }
);

export const fetchInvestigateAlert = createAsyncThunk(
  "alerts/fetchInvestigateAlert",
  async (command: string, { dispatch }) => {
    const response = await protectAxios
      .get(`/alertqueries/investigate/investigatealert/${command}`)
      .then((resp) => resp.data);

    dispatch(setInvestigatedAlertResponse(response));
    return response;
  }
);

export const fetchInvestigateDevice = createAsyncThunk(
  "alerts/fetchInvestigateDevice",
  async (command: string, { dispatch }) => {
    const response = await protectAxios
      .get(`/alertqueries/investigate/investigatedevice/${command}`)
      .then((resp) => resp.data);
    dispatch(setInvestigatedDeviceResponse(response));
    return response;
  }
);

export const fetchInvestigate = createAsyncThunk(
  "alerts/fetchInvestigate",
  async (filterType: string, { dispatch }) => {
    const response = await protectAxios
      .get(`/alertqueries/investigate/investigate/${filterType}`)
      .then((resp) => resp.data);
    dispatch(setInvestigatedAlertResponse(response));
    return response;
  }
);

export const fetchIpLocation = createAsyncThunk(
  "alerts/fetchIpLocation",
  async (IpAddress: string, { dispatch }) => {
    const response = await protectAxios
      .get(`/alertqueries/investigate/Ip2Location/${IpAddress}`)
      .then((resp) => resp.data);
    dispatch(setIpLocation(response));
    return response;
  }
);

export const fetchPortDescription = createAsyncThunk(
  "alerts/fetchPortDescription",
  async (port: string, { dispatch }) => {
    const response = await protectAxios
      .get(`/alertqueries/investigate/PortDescription/${port}`)
      .then((resp) => resp.data);
    return response;
  }
);

export const fetchFlareAlert = createAsyncThunk(
  "alerts/fetchFlareAlert",
  async (command: string, { dispatch }) => {
    const response = await protectAxios
      .get(`/alertqueries/investigate/flarealert/${command}`)
      .then((resp) => resp.data);

    dispatch(setFlareAlertResponse(response));
    return response;
  }
);

export const fetchFlareString = createAsyncThunk(
  "alerts/fetchFlareString",
  async (command: string, { dispatch }) => {
    const response = await protectAxios
      .get(`/alertqueries/investigate/flarestring/${command}`)
      .then((resp) => resp.data);

    dispatch(setFlareAlertResponse(response));
    return response;
  }
);

//USED FOR SINGLE QUESTIONS
export const askAI = createAsyncThunk(
  "alerts/askAI",
  async (command: string) => {
    const resp = await protectAxios.post(
      "/alertqueries/investigate/askai",
      command
    );
    return resp.data;
  }
);

//USED FOR CONVERSATIONS
export const chatAI = createAsyncThunk("alerts/chatAI", async (command: {}) => {
  const resp = await protectAxios.post(
    "/alertqueries/investigate/chatai",
    command
  );
  return resp.data;
});

export const investigateCommonSecurityLog = createAsyncThunk(
  "alerts/investigateCommonSecurityLog",
  async (command: CommonSecurityLog, { dispatch }) => {
    const resp = await protectAxios.post(
      "/alertqueries/investigate/investigatecommonsecuritylog",
      command
    );
    dispatch(setFirewallResponse(resp.data));

    return resp.data;
  }
);

export const fetchDeviceActionCommonSecurityLog = createAsyncThunk(
  "alerts/fetchDeviceActionCommonSecurityLog",
  async () => {
    const resp = await protectAxios
      .get("/alertqueries/investigate/getdeviceactioncommonsecuritylog")
      .then((resp) => resp.data);
    return resp;
  }
);

export const fetchApplicationProtocolCommonSecurityLog = createAsyncThunk(
  "alerts/fetchApplicationProtocolCommonSecurityLog",
  async () => {
    const resp = await protectAxios
      .get("/alertqueries/investigate/getapplicationprotocolcommonsecuritylog")
      .then((resp) => resp.data);
    return resp;
  }
);
