import { createAsyncThunk } from "@reduxjs/toolkit";
import protectAxios from "../../api/axios";
import {
  Company,
  CompanyEmailConfig,
  CompanySentinelConfig,
  CreateCompanyCommand,
} from "../../types/company.types";
import {
  CompanyState,
  setCompanies,
  setCompanySettings,
  setEmailConfig,
  setSentinelConfig,
} from "../Slices/company.slice";
import { setInstallScript } from "../Slices/account.slice";
import {
  fetchActiveAlerts,
  fetchAlertTotalsClosedByDay,
  fetchAlertTotalsCreatedByDay,
  fetchAlertsTotals,
  fetchTotalActiveAlerts,
} from "./alerts";
import { fetchDeviceTotals, fetchDevices } from "./devices";
import { RootState } from "../store";
import { fetchAllTickets } from "./tickets";
import {
  fetchChangeManagementSummaries,
  fetchChangeManagementTemplates,
} from "./changeManagement";

export const createCompany = createAsyncThunk(
  "company/createCompany",
  async (command: CreateCompanyCommand) => {
    const data = await protectAxios
      .post("companycommands/register", command)
      .then((resp) => resp.data);
    return data;
  }
);

export const updateCompany = createAsyncThunk(
  "company/updateCompany",
  async (command: Company) => {
    const data = await protectAxios
      .put(`/companycommands/${command.id}`, command)
      .then((resp) => resp.data);
    return data;
  }
);

export const fetchCompanies = createAsyncThunk(
  "company/fetchCompanies",
  async (_, { dispatch }) => {
    const companies = await protectAxios
      .get("/companyqueries/")
      .then((resp) => resp.data);
    dispatch(setCompanies(companies));
    return companies;
  }
);

export const fetchCompanyById = createAsyncThunk(
  "company/fetchCompanyById",
  async (id: string) => {
    const company = await protectAxios
      .get(`/companyqueries/${id}`)
      .then((resp) => resp.data);
    return company;
  }
);

export const joinMsp = createAsyncThunk(
  "company/joinMsp",
  async (id: string) => {
    return await protectAxios.put(`/companycommands/AddCompanyToMSP/${id}`);
  }
);

export const becomeMsp = createAsyncThunk("company/becomeMsp", async () => {
  return await protectAxios.put("/companycommands/MakeCompanyMSP");
});
export const fetchInstallScript = createAsyncThunk(
  "company/getInstallScript",
  async (_, { dispatch }) => {
    const data = await protectAxios
      .get(`/companyqueries/getInstallScript`)
      .then((resp) => resp.data);
    dispatch(setInstallScript(data));
    return data;
  }
);

export const fetchCompanySentinelConfig = createAsyncThunk(
  "company/getCompanySentinelConfig",
  async (id: string, { dispatch }) => {
    const sentinelConfig = await protectAxios
      .get("/companyqueries/GetSentinelConfigs", { params: { companyId: id } })
      .then((resp) => resp.data);
    dispatch(setSentinelConfig(sentinelConfig));
  }
);
export const fetchCompanyEmailConfig = createAsyncThunk(
  "company/getCompanyEmailConfig",
  async (id: string, { dispatch }) => {
    const emailConfig = await protectAxios
      .get("/companyqueries/GetEmailServiceConfigs", {
        params: { companyId: id },
      })
      .then((resp) => resp.data);
    dispatch(setEmailConfig(emailConfig));
  }
);

export const updateCompanyEmailConfig = createAsyncThunk(
  "company/updateCompanyEmailConfig",
  async (newConfig: CompanyEmailConfig) => {
    const resp = await protectAxios.post(
      "/companycommands/InsertOrUpdateEmailConfig",
      newConfig
    );
    return resp;
  }
);

export const updateCompanySentinelConfig = createAsyncThunk(
  "company/updateCompanySentinelConfig",
  async (newConfig: CompanySentinelConfig, { dispatch, getState }) => {
    const companyId = (getState() as RootState).account.selectedCompanyId;
    const resp = await protectAxios.post(
      "/companycommands/InsertOrUpdateSentinelConfig",
      newConfig
    )
      dispatch(fetchCompanySentinelConfig(companyId || ""))
    ;
    return resp;
  }
);

export const validateSentinelConfig = createAsyncThunk(
  "company/validateSentinelConfig",
  async (newConfig: CompanySentinelConfig) => {
    const resp = await protectAxios.post(
      "/companycommands/ValidateSentinelConfig",
      newConfig
    );
    return resp;
  }
);


export const isSubscriptionIdValid = createAsyncThunk(
  "company/isSubscriptionIdValid",
  async (subscriptionId: string) => {
    const response = await protectAxios
    .get(`/companyqueries/isSubscriptionIdValid/${subscriptionId}`)
      .then((resp) => resp.data);
    return response;
  }
);

export const fetchSentinelResources = createAsyncThunk(
  "company/getSentinelResources",
  async (subscriptionId: string) => {
    const response = await protectAxios
    .get(`/companyqueries/getSentinelResources/${subscriptionId}`)
      .then((resp) => resp.data);
    return response;
  }
);


export const deleteSentinelConfig = createAsyncThunk(
  "company/deleteSentinelConfig",
  async (sentinelConfigId: string, { dispatch, getState }) => {
    const companyId = (getState() as RootState).account.selectedCompanyId;
    await protectAxios.delete(`/companycommands/DeleteSentinelConfig`, {
      params: { sentinelConfigId },
    });
    dispatch(fetchCompanySentinelConfig(companyId || ""))

  }
);


export const changeCompany = createAsyncThunk(
  "company/changeCompany",
  async (_, { dispatch, getState }) => {
    const companyId = (getState() as RootState).account.selectedCompanyId;
    await Promise.all([
      dispatch(fetchActiveAlerts()),
      dispatch(fetchTotalActiveAlerts()),
      dispatch(fetchAlertsTotals()),
      dispatch(fetchAlertTotalsCreatedByDay()),
      dispatch(fetchAlertTotalsClosedByDay()),
      dispatch(fetchDeviceTotals()),
      dispatch(fetchDevices()),
      dispatch(fetchCompanySentinelConfig(companyId || "")),
      dispatch(fetchCompanyEmailConfig(companyId || "")),
      dispatch(fetchAllTickets()),
      dispatch(fetchChangeManagementSummaries()),
      dispatch(fetchChangeManagementTemplates()),
    ]);
  }
);

export const deleteCompanyPicture = createAsyncThunk(
  "company/deleteCompanyPicture",
  async (fileName: string) => {
    await protectAxios.delete(`/companycommands/DeleteCompanyPictures`, {
      params: { fileName },
    });
  }
);

export const updateCompanySettings = createAsyncThunk(
  "company/updateCompanySettings",
  async (newCompanySettings: CompanyState["companySettings"]) => {
    await protectAxios.post(
      `/companycommands/SaveCompanySettings`,
      newCompanySettings
    );
  }
);

export const fetchCompanySettings = createAsyncThunk(
  "company/fetchCompanySettings",
  async (companyId: string, { dispatch }) => {
    const settings = await protectAxios
      .get("/companyqueries/GetCompanySettings", {
        params: { companyId: companyId },
      })
      .then((resp) => resp.data);
    dispatch(setCompanySettings(settings));
  }
);


export const isInboundTicketEmailAvailable = createAsyncThunk(
  "company/isInboundTicketEmailAvailable",
  async (inboundTicketEmail: string) => {
    const response = await protectAxios
    .get('/companycommands/IsInboundTicketEmailAvailable/', {params: {inboundTicketEmail: inboundTicketEmail}})
      .then((resp) => resp.data);
    return response;
  }
);

export const saveInboundTicketEmail = createAsyncThunk(
  "company/SaveInboundTicketEmail",
  async (inboundTicketEmail: string, { dispatch, getState }) => {
    const companyId = (getState() as RootState).account.selectedCompanyId;
    await protectAxios.post(`/companycommands/SaveInboundTicketEmail/${inboundTicketEmail}`);
    dispatch(fetchCompanySettings(companyId || ""))

  }
);

