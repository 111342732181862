
import * as React from 'react';
import { List, ListItemButton, ListItemText } from '@mui/material';
import { Device, DeviceChartObject } from '../../types/devices.types';
import { setChartCounter, setDoubleClickChartCounter } from '../../redux/Slices/devices.slice';
import { useAppDispatch } from '../../redux/hooks';



interface CounterTreeProps {
    counterNames: any[];
    device: Device;
    objectName: string;
}

export default function CounterTree(props: CounterTreeProps) {
    const { counterNames, device, objectName } = props
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    // const [selectedCounter, setSelectedCounter] = React.useState(1);
    const dispatch = useAppDispatch();
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        index: number,
        name: string,
    ) => {
        var deviceChartObject: DeviceChartObject = {
            deviceId: device.id,
            deviceGroup: device.deviceGroup,
            objectName: objectName,
            counterName: name,
            startDate: '',
            endDate: '',
        };
        if(event.detail === 1){
            dispatch(setChartCounter(deviceChartObject));
            setSelectedIndex(index);
        }
        if(event.detail === 2){
            dispatch(setDoubleClickChartCounter(deviceChartObject));
            setSelectedIndex(index)
        }

        
    };
    return (
        <List dense={true} sx={{ padding: 0 }}>
            {counterNames.map((name: any, index: any) => (
                <ListItemButton
                key={index}
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, index, name)}
                >
                    <ListItemText primary={name} sx={{ margin: 0 }} />
                </ListItemButton>
            )
            )}
        </List>



    );
}