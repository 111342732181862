import { faFileExport } from "@fortawesome/pro-light-svg-icons";
import { Grid } from "@mui/material";
import { Card } from "../../components/Card";


export const ReportsSummary = () => {

    return (
        <Card
            id="Charts"
            headerIcon={faFileExport}
            headerTitle={"Reports"}
            headerSubText={""}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={""}
            cardContent={
                <Grid container spacing={0}>

                </Grid>
            }
        />
    );
};
