import { FC } from "react";
import * as Mui from "@mui/material";

interface CyberRiskMediationTableProps {}

export const CyberRiskMediationTable: FC<CyberRiskMediationTableProps> = (
  props
) => {
  return (
    <Mui.Stack
      direction="column"
      justifyContent="space-evenly"
      alignItems="stretch"
      height="93%"
      width="100%"
    >
      <Mui.Box>
        <Mui.Table>
          <Mui.TableHead sx={{ backgroundColor: "white" }}>
            <Mui.TableRow>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Subject
              </Mui.TableCell>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Impact
              </Mui.TableCell>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Cyber Risk
              </Mui.TableCell>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Priority
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">Apply KB4100480: Security Update for the Win32k</a>
              </Mui.TableCell>
              <Mui.TableCell align="left">1 Devices</Mui.TableCell>
              <Mui.TableCell align="left">High</Mui.TableCell>
              <Mui.TableCell align="left">Medium</Mui.TableCell>
            </Mui.TableRow>

            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">
                  Apply KB4135498: Security Update for the LDAP Elevation of
                  Privilege
                </a>
              </Mui.TableCell>
              <Mui.TableCell align="left">5 Devices</Mui.TableCell>
              <Mui.TableCell align="left">High</Mui.TableCell>
              <Mui.TableCell align="left">High</Mui.TableCell>
            </Mui.TableRow>

            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">
                  Apply Security Updates for Microsoft Edge (December 2022)
                </a>
              </Mui.TableCell>
              <Mui.TableCell align="left">12 Devices</Mui.TableCell>
              <Mui.TableCell align="left">Low</Mui.TableCell>
              <Mui.TableCell align="left">Low</Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.Box>
    </Mui.Stack>
  );
};
