import { faUsers } from "@fortawesome/pro-light-svg-icons";
import { Box, Grid } from "@mui/material";
import * as React from "react";
import { useAsyncEffect } from "../../common/hooks";
import { Loader } from "../../components/Loader";
import { useIdentityContext } from "../../context/IdentityContext";
import { SetUpInfo } from "../../components/SetUpInfo";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUsers } from "../../redux/Slices/account.slice";
import {
  getCompanyById,
  getGatewayServer,
} from "../../redux/Slices/company.slice";
import { getSetUpInfoPage } from "../../redux/Slices/company.slice";
import { fetchAllUsers } from "../../redux/Thunks/account";
import { PasswordReset } from "../CompanyAdminDashboard/PasswordReset";
import { fetchCompanies, fetchCompanySentinelConfig, fetchInstallScript } from "../../redux/Thunks/company";
import { CompanyEdit } from "../CompanyAdminDashboard/CompanyEdit";
import { QuickInvite } from "../CompanyAdminDashboard/QuickInvite";
import { Team } from "../CompanyAdminDashboard/Team";
import "./Users.css";
import { GatewayServerInfo } from "../../components/GatewayServerInfo";
import { setLayout } from "../../redux/Slices/layout.slice";
// import { fetchAlertOverrides } from "../../redux/Thunks/alerts";

export const UsersDashboard = () => {
  const identity = useIdentityContext();
  const users = useAppSelector(getUsers);
  const [showCompany, setShowCompany] = React.useState(false);
  const dispatch = useAppDispatch();
  const company = useAppSelector(
    getCompanyById(identity.authenticatedUser.companyId)
  );
  const setUpInfoPageOpen = useAppSelector(getSetUpInfoPage);
  const globalServerPageOpen = useAppSelector(getGatewayServer);

  React.useEffect(() => {
    dispatch(
      setLayout({
        title: "Users",
        subtitle: "Users",
        icon: faUsers,
      })
    );
  }, [dispatch]);

  const { loading } = useAsyncEffect(async () => {
    Promise.all([
      await dispatch(fetchCompanies()),
      await dispatch(fetchAllUsers({})),
      await dispatch(fetchInstallScript()),
      await dispatch(fetchCompanySentinelConfig(company?.id || "")),
      // await dispatch(fetchAlertOverrides()),
    ]);
  }, []);

  const handleUserInvited = async () => {
    await dispatch(fetchAllUsers({}));
  };
  if (setUpInfoPageOpen) {
    return <SetUpInfo />;
  }
  if (globalServerPageOpen) {
    return <GatewayServerInfo />;
  }
  return (
    <>
      {loading ? (
        <Loader show={true} />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={8} md={8}>
            <Team users={users} />
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{mb: 1}}>

            <PasswordReset />
            </Box>

            {showCompany && company ? (
              <CompanyEdit
                onBack={() => setShowCompany(false)}
                company={company}
              />
            ) : (
              <QuickInvite
                onCompanyClicked={() => setShowCompany(true)}
                onUserInvited={handleUserInvited}
              />
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
