import { createAsyncThunk } from "@reduxjs/toolkit";
import protectAxios from "../../api/axios";
import {
  ConfirmInviteCommand,
  ConfirmResetPasswordCommand,
  ConfirmSignupCommand,
  CreatePrimaryContactCommand,
  InviteUserCommand,
  UpdateUserCommand,
  UpdateUserRolesCommand,
} from "../../types/account.types";
import {
  setAccountPreferences,
  setAuthenticator,
  setRoles,
  setUserStatistics,
  setUsers,
} from "../Slices/account.slice";

export const enableUser = createAsyncThunk(
  "account/enableUser",
  async ({ userId, enabled }: { userId: string; enabled: boolean }) => {
    const resp = await protectAxios.put(
      `/accountcommands/${userId}/${enabled}`,
      {}
    );
    return resp.data;
  }
);

export const updateUser = createAsyncThunk(
  "account/updateUser",
  async ({
    userId,
    command,
  }: {
    userId: string;
    command: UpdateUserCommand;
  }) => {
    const resp = await protectAxios.put(`/accountcommands/${userId}`, command);
    return resp.data;
  }
);

export const createPrimaryContact = createAsyncThunk(
  "account/createPrimaryContact",
  async ({
    companyId,
    command,
  }: {
    companyId: string;
    command: CreatePrimaryContactCommand;
  }) => {
    const resp = await protectAxios.post(
      `/accountcommands/createprimarycontact/${companyId}`,
      command
    );
    return resp.data;
  }
);

export const signUpPrimaryContact = createAsyncThunk(
  "account/signUpPrimaryContact",
  async (primaryContactId: string) => {
    const resp = await protectAxios.post(
      `/accountcommands/signupprimarycontact/${primaryContactId}`
    );
    return resp.data;
  }
);

export const confirmSignup = createAsyncThunk(
  "account/confirmSignup",
  async ({
    primaryContactId,
    command,
  }: {
    primaryContactId: string;
    command: ConfirmSignupCommand;
  }) => {
    const resp = await protectAxios.post(
      `/accountcommands/confirmsignup/${primaryContactId}`,
      command
    );
    return resp.data;
  }
);

export const inviteUser = createAsyncThunk(
  "account/inviteUser",
  async (command: InviteUserCommand) => {
    const resp = await protectAxios.post(
      "/accountcommands/inviteuser",
      command
    );
    return resp.data;
  }
);

export const confirmInvitedUser = createAsyncThunk(
  "account/confirmInvitedUser",
  async ({
    userId,
    command,
  }: {
    userId: string;
    command: ConfirmInviteCommand;
  }) => {
    const resp = await protectAxios.post(
      `/accountcommands/confirminvite/${userId}`,
      command
    );
    return resp;
  }
);

export const resetPassword = createAsyncThunk(
  "account/resetPassword",
  async (emailAddress: string) => {
    const encodedEmailAddress = encodeURIComponent(emailAddress);
    const resp = await protectAxios.put(
      `/accountcommands/resetpassword/${encodedEmailAddress}`
    );
    return resp.data;
  }
);

export const confirmResetPassword = createAsyncThunk(
  "account/confirmResetPassword",
  async ({
    userId,
    command,
  }: {
    userId: string;
    command: ConfirmResetPasswordCommand;
  }) => {
    const resp = await protectAxios.post(
      `/accountcommands/confirmresetpassword/${userId}`,
      command
    );
    return resp.data;
  }
);

export const changePassword = createAsyncThunk(
  "account/changePassword",
  async (newPassword: string) => {
    const resp = await protectAxios.put(
      `/accountcommands/resetpassword/${newPassword}`
    );
    return resp.data;
  }
);

export const updateUserRoles = createAsyncThunk(
  "account/updateUserRoles",
  async ({
    userId,
    command,
  }: {
    userId: string;
    command: UpdateUserRolesCommand;
  }) => {
    const resp = await protectAxios.put(
      `/accountcommands/${userId}/roles`,
      command
    );
    return resp.data;
  }
);

export const fetchUser = createAsyncThunk(
  "account/getUser",
  async (id: string) => {
    const data = await protectAxios
      .get(`/accountqueries/${id}`)
      .then((resp) => resp.data);
    return data;
  }
);

export const fetchAllUsers = createAsyncThunk(
  "account/getAllUsers",
  async ({ onlyActiveUsers }: { onlyActiveUsers?: boolean }, { dispatch }) => {
    const endpoint = onlyActiveUsers ? "activecompanyusers" : "companyusers"; // activecompanyusers is used for replacing the getEngineers call

    const data = await protectAxios
      .get(`/accountqueries/${endpoint}`)
      .then((resp) => resp.data);
    dispatch(setUsers(data));
    return data;
  }
);

export const fetchAllRoles = createAsyncThunk(
  "account/getAllRoles",
  async (_, { dispatch }) => {
    const data = await protectAxios
      .get("/accountqueries/roles")
      .then((resp) => resp.data);
    dispatch(setRoles(data));
    return data;
  }
);

export const deleteUserById = createAsyncThunk(
  "account/deleteUserById",
  async (id: string, { dispatch }) => {
    const resp = await protectAxios.delete(`/accountcommands/${id}`);
    await dispatch(fetchAllUsers({}));
    return resp;
  }
);

export const toggleSetUpInstructions = createAsyncThunk(
  "account/preferences/toggleSetUpInstructions",
  async (enabled: boolean, { dispatch }) => {
    const resp = await protectAxios
      .put(
        `/accountcommands/preferences/tooglesetupinstructions/${enabled}`,
        {}
      )
      .then((resp) => resp.data);
    dispatch(fetchPreferences());
    return resp.data;
  }
);

export const fetchPreferences = createAsyncThunk(
  "account/getPreferences",
  async (_, { dispatch }) => {
    const data = await protectAxios
      .get("/accountqueries/preferences")
      .then((resp) => resp.data);
    dispatch(setAccountPreferences(data));
    return data;
  }
);

export const fetchUserStatistics = createAsyncThunk(
  "account/fetchUserStatistics",
  async (_, { dispatch }) => {
    const data = await protectAxios
      .get("/accountqueries/userstatistics")
      .then((resp) => resp.data);
    dispatch(setUserStatistics(data));
    return data;
  }
);

export const fetchAuthenticator = createAsyncThunk(
  "account/fetchAuthenticator",
  async (_, { dispatch }) => {
    const data = await protectAxios
      .get("/accountcommands/GetEnableAuthenticator")
      .then((resp) => resp.data);
    dispatch(setAuthenticator(data));
    return data;
  }
);

export const verifyAuthenticator = createAsyncThunk(
  "account/verifyAuthenticator",
  async ({ code }: { code: string }, { dispatch }) => {
    await protectAxios.post("/accountcommands/VerifyAuthenticator", { code });
  }
);

export const removeAuthenticator = createAsyncThunk(
  "account/removeAuthenticator",
  async (userId: string) => {
    await protectAxios.put(
      "/accountcommands/DisableAuthenticator",
      {},
      { params: { userId } }
    );
  }
);
