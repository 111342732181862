import * as React from "react";
import { Link } from "react-router-dom";

import { AppBar, Button, Modal, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import Toolbar from "@mui/material/Toolbar";
import logo from "../../images/Banner.png";
import { useIdentityContext } from "../../context/IdentityContext";

import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faCircleInfo,
  faArrowRightFromBracket,
  faMessageBot,
  // faBars,
} from "@fortawesome/pro-light-svg-icons";
import {
  getAccountPreferences,
  getSelectedCompanyId,
  getUserById,
} from "../../redux/Slices/account.slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { NewUserModal } from "../../components/NewUserModal";
import { getCompanyById } from "../../redux/Slices/company.slice";
import { useEffect, useState } from "react";
import { fetchCompanies } from "../../redux/Thunks/company";
import { useAsyncEffect } from "../../common/hooks";
import { useCookies } from "react-cookie";
import { isUserAdmin } from "../../redux/Slices/account.slice";
import { HarveyModal } from "../../components/HarveyChatModal/HarveyModal";
import { TwoFactorBanner } from "../../common/TwoFactorBanner";
interface HeaderProps {
  title: string;
  appBarComponent?: React.ReactNode;
}

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [isPrimaryContact, setIsPrimaryContact] = useState(false);
  const identity = useIdentityContext();
  const [cookies, setCookie, removeCookie] = useCookies(["SetUpInstructions"]);
  const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
  const company = useAppSelector(
    getCompanyById(identity.authenticatedUser.companyId)
  );
  const selectedCompanyId = useAppSelector(getSelectedCompanyId);
  const preferences = useAppSelector(getAccountPreferences);
  const isAdmin = useAppSelector(isUserAdmin(identity.authenticatedUser.userId))
  const [openHarvey, setOpenHarvey] = useState(false);
  const [harveyAlert, setHarveyAlert] = useState({});
  const closeHarvey = () => setOpenHarvey(false);
  const companyId = selectedCompanyId || company?.id;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => { setAnchorEl(null) };
  const [img, setImg] = useState(
    companyId
      ? ``
      : ""
  );

  useEffect(() => {
    setImg(
      companyId
        ? ``
        : "d"
    );
  }, [companyId]);

  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchCompanies()).then(() => {
      setIsPrimaryContact(user?.emailAddress === company?.primaryContactEmail);
      // setIsPrimaryContact(true)
    });
  }, []);
  const getWelcomeMessage = () => {
    if (
      identity.authenticatedUser.fullName &&
      identity.authenticatedUser.fullName.split(" ")[0]
    ) {
      return `Welcome ${identity.authenticatedUser.fullName.split(" ")[0]}`;
    } else {
      return `Welcome`;
    }
  };

  const onClickInfoButton = () => {
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  useEffect(() => {
    if (preferences.setUpInstructionsDisabled === false) {
      if (
        isPrimaryContact &&
        !loading &&
        window.location.pathname === "/home"
      ) {
        setOpen(true);
      }
    }
    if (preferences.setUpInstructionsDisabled === true) {
      setOpen(false);
    }
  }, [loading, preferences]);

  return (
    <>
      <HarveyModal
        openHarvey={openHarvey}
        closeHarvey={closeHarvey}
        topic={{}}
        type={'general'}
      />
      <React.Fragment>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <NewUserModal />
        </Modal>
        <TwoFactorBanner />

        <AppBar
          component="div"
          position="static"
          elevation={0}
          sx={{
            backgroundColor: "#395a7a",
            boxShadow: "0 0 28px 0 rgb(86 61 124 / 13%)",
            WebkitBoxShadow: "0 0 28px 0 rgb(86 61 124 / 13%)",
          }}
        >
          <Toolbar>
            <Grid container alignItems="center" spacing={1}>
              <Grid item xs={3}>
                <Link to="/">
                  <img
                    src={img}
                    style={{
                      maxHeight: "50px",
                      paddingTop: 8,
                    }}
                    onError={() => {
                      setImg(logo);
                    }}
                    alt="SecQube"
                  />
                </Link>
              </Grid>

              <Grid item md sx={{ display: { xs: "none", md: "block" } }}></Grid>
              <Grid item>
                <Typography>
                  <FontAwesomeIcon
                    style={{ marginRight: "8px" }}
                    icon={faUser}
                  ></FontAwesomeIcon>
                  {getWelcomeMessage()}
                </Typography>
              </Grid>




              {/* <IconButton
                aria-label="menu"
                onClick={handleMenuClick}
                sx={{ mt: 1, ml: 2 }}

              >

                <FontAwesomeIcon
                  icon={faBars}
                  color={"white"}
                  size="sm"

                />
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={() => {
                  setOpenHarvey(!openHarvey);
                  setHarveyAlert({});
                }}>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faMessageBot}
                    color={"rgb(232,143,0)"}
                    style={{ width: "18px", marginRight: '10px' }}
                  />
                  Ask Harvey
                </MenuItem>
                {isAdmin ? (
                  <MenuItem onClick={onClickInfoButton}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={faCircleInfo}
                      color={"rgb(232,143,0)"}
                      style={{ width: "18px", marginRight: '10px' }}
                    />
                    Setup Info
                  </MenuItem>
                ) : (
                  <></>
                )}



                <MenuItem onClick={() => {
                  identity.onLogout();
                }}>
                  <FontAwesomeIcon
                    size="lg"
                    icon={faArrowRightFromBracket}
                    color={"rgb(232,143,0)"}
                    style={{ width: "18px", marginRight: '10px' }}
                  />
                  Logout
                </MenuItem>


              </Menu> */}


              <Grid item>
                {isAdmin ? (
                  <Button
                    sx={{ minWidth: 'auto' }}
                    onClick={onClickInfoButton}
                  >
                    <FontAwesomeIcon
                      size="xl"
                      icon={faCircleInfo}
                      color={'white'}
                      // onClick={onClickInfoButton}
                      style={{ marginLeft: "10px" }}
                    ></FontAwesomeIcon>
                  </Button>
                ) : (
                  <></>
                )}
              </Grid>
              <Grid item>
                <Button
                  sx={{ minWidth: 'auto' }}
                  onClick={() => {
                    setOpenHarvey(!openHarvey);
                    setHarveyAlert({});
                  }}
                >
                  <FontAwesomeIcon
                    size="xl"
                    icon={faMessageBot}
                    color={"white"}
                    // onClick={() => {
                    //   setOpenHarvey(!openHarvey);
                    //   setHarveyAlert({});
                    // }}
                    style={{  }}
                  />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ minWidth: 'auto' }}
                  onClick={() => {
                    identity.onLogout();
                  }}
                >

                  <FontAwesomeIcon
                    size="xl"
                    icon={faArrowRightFromBracket}
                    color={"white"}
                    // onClick={() => {
                    //   identity.onLogout();
                    // }}
                    style={{}}
                  />
                </Button>
              </Grid>
            </Grid>




          </Toolbar>
        </AppBar>
        {props.appBarComponent}
      </React.Fragment>
    </>
  );
};
