import { faComment, faTicketSimple } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { FormattedDateTimeMilitary } from "../../../common/dateUtils";
import {
  getComparator,
  Order,
  stableSort,
} from "../../../common/TableUtilities";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import { AssignEngineer } from "../../../components/Tickets/AssignEngineer";
import { useTheme } from "@mui/material/styles";
import {
  TableCell,
  ButtonGroup,
  Button,
  TableContainer,
  Paper,
  Table,
  TableBody,
  Stack,
  Modal,
  Box,
  TableRow,
  TablePagination,
} from "@mui/material";
import { CreateTicketForm } from "../CreateTicket/CreateTicketForm";
import { EnhancedTableHead } from "./TicketEnhancedTableHead";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { Ticket } from "../../../types/tickets.types";
import { fetchAllTickets } from "../../../redux/Thunks/tickets";
import { priorityBackgroundColor } from "../../../common/ticket.util";
import { useAsyncEffect } from "../../../common/hooks";
import { fetchAllUsers } from "../../../redux/Thunks/account";
import { Loader } from "../../../components/Loader";
import { useIdentityContext } from "../../../context/IdentityContext";
import { isUserEndUser } from "../../../redux/Slices/account.slice";

interface TicketsEnhancedTableProps {
  tickets: Ticket[];
  setFilteredTickets: any;
}

export function TicketsEnhancedTable(props: TicketsEnhancedTableProps) {
  const getTickets = (): Ticket[] => {
    const keys: string[] = [];
    let tickets = props.tickets;
    tickets = tickets.filter((ticket) => {
      if (keys.includes(ticket.id)) {
        console.error(`Found duplicate Ticket ID: ${ticket.id}`);
        return false;
      } else {
        keys.push(ticket.id);
        return true;
      }
    }) as Ticket[];
    return tickets;
  };

  return <EnhancedTable tickets={getTickets()!} setFilteredTickets={props.setFilteredTickets}></EnhancedTable>;
}

export default function EnhancedTable(props: { tickets: Ticket[], setFilteredTickets: any }) {
  const { tickets, setFilteredTickets } = props;
  const [rows, setRows] = React.useState<Ticket[]>([]);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Ticket>("createdDate");
  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);
  enum showStatus {
    All = 0,
    Open = 1,
    Closed = 2,
    CM = 3,
  }
  const [showItems, setShowItems] = React.useState<showStatus>(showStatus.Open);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { authenticatedUser } = useIdentityContext();
  const isEndUser = useAppSelector(isUserEndUser(authenticatedUser.userId));

  const determineSelectedTickets = (allTickets: any, selectedItem: number) => {
    if (isEndUser) {
      return allTickets.filter((ticket: Ticket) => ticket.assignedEngineerId === authenticatedUser.userId)
    }
    if (selectedItem === 0) {
      return allTickets;
    }
    if (selectedItem === 1) {
      return allTickets.filter(
        (ticket: { status: number }) => ticket.status !== 3
      );
    }
    if (selectedItem === 2) {
      return allTickets.filter(
        (ticket: { status: number }) => ticket.status === 3
      );
    }
    if (selectedItem === 3) {
      return allTickets.filter(
        (ticket: { changeManagementItemId: string }) => ticket.changeManagementItemId !== null
      );
    }
    else {
      return allTickets;
    }
  };

  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchAllUsers({}));
  }, []);

  useEffect(() => {
    const selectedTickets = determineSelectedTickets(tickets, showItems);
    setFilteredTickets(selectedTickets)
    setRows(selectedTickets || tickets);
  }, [showItems, tickets]);

  const statusColour = (status: number | any) => {
    switch (status) {
      case 0:
        return theme.palette.success.main;
      case 1:
        return theme.palette.info.main;
      case 2:
        return theme.palette.warning.main;
      default:
        return "silver";
    }
  };
  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Ticket
  ) => {
    const _property = property !== "ticketNumber" ? property : "priority";
    const isAsc = orderBy === _property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(_property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOnTicketChanged = () => {
    dispatch(fetchAllTickets());
  };

  const headerText = () => {
    switch (showItems) {
      case showStatus.Open:
        return "Open";
      case showStatus.Closed:
        return "Closed";
      case showStatus.All:
        return "All";
      case showStatus.CM:
        return "CM";
      default:
        return "Open";
    }
  };

  const updateShowItems = (status: showStatus) => {
    setShowItems(status);
  };




  if (loading) {
    return <Loader show={true} />;
  }
  return (
    <>
      <Card
        id="Tickets"
        headerIcon={faTicketSimple}
        headerTitle={`Showing ${headerText()} items`}
        headerSubText={`Total of ${rows.length || 0} ${headerText()} tickets`}
        showHelp={true}
        helpHeaderText="View Open, Closed or All Active Tickets"
        helpHeaderDescription="This view shows you all your currently open tickets, use the switch on the right to toggle between 
                the closed and open tickets views."
        cardHeaderActions={
          <>
            <ButtonGroup>
              <Button onClick={handleOpen} sx={{ marginRight: "10px" }}>
                Create Ticket
              </Button>
            </ButtonGroup>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box>
                <CreateTicketForm onClose={handleClose} />
              </Box>
            </Modal>
            <ButtonGroup>
              <Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.All ? "orange" : "white",
                  color: showItems === showStatus.All ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.All);
                }}
              >
                All
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.Open ? "orange" : "white",
                  color: showItems === showStatus.Open ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.Open);
                }}
              >
                Active
              </Button>

              <Button
                disabled={isEndUser}
                sx={{
                  backgroundColor:
                    showItems === showStatus.CM ? "orange" : "white",
                  color: showItems === showStatus.CM ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.CM);
                }}
              >
                Change Mangement
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.Closed ? "orange" : "white",
                  color: showItems === showStatus.Closed ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.Closed);
                }}
              >
                Closed
              </Button>

            </ButtonGroup>
          </>
        }
        cardContent={
          <TableContainer
            component={Paper}
            sx={{
              borderStyle: "solid",
              borderColor: "#ccc",
              borderWidth: "1px",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="recent tickets"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length || 0}
              />

              <TableBody>
                {stableSort(rows as any, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, _index) => {
                    return (
                      <TableRow key={row.id}>

                        <TableCell align='left'
                          sx={{
                            backgroundColor: priorityBackgroundColor(row.priority),
                            opacity: "0.8",
                          }}
                        >
                          <span style={{ color: "white", fontWeight: "bold" }}>
                            {row.ticketNumber}
                          </span>
                        </TableCell>
                        <TableCell>
                          {row.title}
                        </TableCell>
                        <TableCell>
                          {FormattedDateTimeMilitary(row.createdDate)}
                        </TableCell>
                        <TableCell align="left">{row.raisedByName}</TableCell>
                        <TableCell>
                          <AssignEngineer
                            showAssignment={true}
                            ticket={row}
                            onTicketChanged={handleOnTicketChanged}
                          />
                        </TableCell>
                        <TableCell align="left">
                          <FontAwesomeIcon
                            icon={faCircle}
                            color={statusColour(row.status)}
                            size="1x"
                          />{" "}
                          {row.statusDescription}
                        </TableCell>

                        <TableCell align="center">
                          <FontAwesomeIcon
                            icon={faComment}
                            color={"orange"}
                            size="1x"
                          />
                          &nbsp;{row.numberOfComments}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                navigate(`/ticket/${row.id}`);
                              }}
                            >
                              View Ticket
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[15, 30, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        }
      />
    </>
  );
}
