export enum ChangeManagementStatus {
  New = 0,
  Approved = 1,
  InProgress = 2,
  Complete = 3,
  AwaitingSignoff = 4,
  SignedOff = 5,
}

export interface CreateChangeManagementItemCommand {
  title: string;
  description: string;
  assignedEngineerId: string | null;
  assignedEngineerName: string;
  plans: ChangeManagementPlan[];
  milestones: ChangeManagementMilestone[];
}

export interface AddCommentCommand {
  comment: string;
  isImportant: boolean;
}

export interface ChangeManagementItemSummary {
  companyId?: string,
  changeManagementNumber: number;
  id: string;
  isManualSignoff: boolean;
  ticketId: string;
  assignedEngineerId: string;
  assignedEngineerName: string;
  lastModifiedDate: Date;
  createdDate: Date;
  closedDate?: Date;
  title: string;
  description: string;
  status: number;
  statusDescription: string;
  totalMilestones: number;
  completeMilestones: number;
  overdueMilestones: number;
  dueMilestones: number;
  incompleteMilestones: number;
  totalPlans: number;
  notStartedPlans: number;
  inProgressPlans: number;
  completePlans: number;
  incompletePlans: number;
  canApprove: boolean;
  canRequestSignoff: boolean;
  canSignoff: boolean;
}

export interface ChangeManagementItemComment {
  id: string;
  changeManagementItemId: string;
  comment: string;
  createdDate: Date;
  createdById: string;
  createdByFullName: string;
  createdByInitials: string;
  isImportant: boolean;
  isSystemGenerated: boolean;
  createdByRole: string;
  isInternal: boolean;
}

export interface ChangeManagementMilestoneTask {
  id: string;
  changeManagementItemMilestoneId: string;
  description: string;
  checkPerformedOn?: Date;
}

export interface ChangeManagementMilestone {
  id: string;
  issueId: string;
  completedDate?: Date;
  title: string;
  dueDate: Date;
  tasks: ChangeManagementMilestoneTask[];
  assignedToId?: string;
  assignedToName?: string;
}

export interface ChangeManagementPlanCheck {
  id: string;
  issuePlanId: string;
  description: string;
  checkRequired: boolean;
  checkPerformedOn: Date | null;
  evidenceRequired: boolean;
  evidenceUploaded: boolean;
  isComplete: boolean;
}

export interface ChangeManagementPlan {
  id: string;
  issueId: string;
  status: number;
  statusDescription: string;
  title: string;
  startDate?: Date | null;
  completeDate?: Date | null;
  checks: ChangeManagementPlanCheck[];
  assignedToId?: string;
  assignedToName?: string;
}

export interface ChangeManagementTemplate {
  id: string;
  description: string;
  title: string;
  items: ChangeManagementTemplateItem[];
}

export interface ChangeManagementTemplateItem {
  id: string;
  changeManagementPlanTemplateId: string;
  title: string;
  description: string;
}

export interface ChangeManagementCreateOrEditCommand {
  id: string;
  companyId?: string;
  ticketId: string;
  title: string;
  description: string;
  assignedEngineerId: string;
  assignedEngineerName: string;
  plans: ChangeManagementPlans[]
  milestones: ChangeManagementMilestone[]
  requestApproval: boolean;
}
export interface ChangeManagementPlans {
  id: string;
  changeManagementItemId: string;
  status: number;
  title: string,
  startDate: string;
  completeDate: string;
  assignedToId: string;
  assignedToName: string;
  checks: ChangeManagementChecks[]
}

export interface ChangeManagementChecks {
  id: string;
  changeManagementItemPlanId: string;
  description: string;
  checkRequired: boolean;
  evidenceRequired: boolean;
}

// export interface ChangeManagementMilestones {
//     id: string;
//     changeManagementItemId: string;
//     completedDate: string;
//     title: string;
//     dueDate: string;
//     tasks: [
//       {
//         id: string;
//         changeManagementItemMilestoneId: string;
//         description: string;
//         checkPerformedOn: string;
//       }
//     ],
//     assignedToId: string;
//     assignedToName: string;
//   }

  export interface EditPlanPayload {
    title: string;
    description: string;
    assignedTo: string;
    checkRequired: boolean;
  }