import { faX, faLaptop } from "@fortawesome/pro-light-svg-icons";
import * as Mui from "@mui/material";
import { Loader } from "../../components/Loader";
import { NoData } from "../../components/NoData";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getInvestigatedDeviceResponse } from "../../redux/Slices/alerts.slice";
import { useAsyncEffect } from "../../common/hooks";
import { fetchInvestigateDevice } from "../../redux/Thunks/alerts";
import { useEffect } from "react";
import { setLayout } from "../../redux/Slices/layout.slice";
import { useParams } from "react-router-dom";
import { getDevices } from "../../redux/Slices/devices.slice";
import { InvestigateDeviceTableSummary } from "./InvestigateDeviceTableSummary";
import InvestigateDeviceEnhancedTable from "./InvestigateDeviceEnhancedTable";

export const InvestigateDeviceDashboardPage = () => {
  const { id, group } = useParams<string>();
  const dispatch = useAppDispatch();
  const devices = useAppSelector(getDevices);
  const currentDevice = devices.find((device) => device.deviceGroup === group && device.id === id);
  const investigatedDevice = useAppSelector(getInvestigatedDeviceResponse);
  useEffect(() => {
    dispatch(
      setLayout({
        title: "Alerts Dashboard",
        subtitle: "Alerts Dashboard",
        icon: faLaptop,
      })
    );
  }, [dispatch]);


  const { loading } = useAsyncEffect(async () => {
    const command = `${currentDevice?.id}/${currentDevice?.deviceGroup}`
    if (currentDevice?.id)
      await dispatch(fetchInvestigateDevice(command));
  }, [currentDevice?.id]);
  return (
    <>
      {loading ? (
        <Loader show={true} />
      ) : !investigatedDevice ? (
        <NoData
          icon={faX}
          headerText="No active results found"
          subText="We couldn`t find any results"
        />
      ) : (
        <>
          <InvestigateDeviceTableSummary
            investigatedDevice={investigatedDevice}
            currentDevice={currentDevice}
          />
          <Mui.Grid item xs={12} sx={{ height: "auto", marginTop: 2 }}>
            <Mui.Card sx={{ border: "solid 1px #ddd", height: "auto" }}>
              {
                <InvestigateDeviceEnhancedTable
                  investigatedDevice={investigatedDevice}
                  currentDevice={currentDevice}
                ></InvestigateDeviceEnhancedTable>
              }
            </Mui.Card>
          </Mui.Grid>
        </>
      )}
    </>
  );
};
