import { useEffect } from "react";
import * as Mui from "@mui/material";
import { faChartColumn } from "@fortawesome/pro-light-svg-icons";

import { DevicesEnhancedTable } from "./DevicesEnhancedTable/DevicesEnhancedTable";
import { Loader } from "../../components/Loader";
import "./DevicesDashboardPage.css";
import { DeviceTableSummary } from "./DeviceTableSummary/DeviceTableSummary";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAsyncEffect } from "../../common/hooks";
import { fetchDevices, fetchDeviceTotals } from "../../redux/Thunks/devices";
import { getDevices, getDeviceTotals } from "../../redux/Slices/devices.slice";
import { setLayout } from "../../redux/Slices/layout.slice";

export const DevicesDashboardPage = () => {
  const dispatch = useAppDispatch();
  const devices = useAppSelector(getDevices);
  const deviceStats = useAppSelector(getDeviceTotals);

  useEffect(() => {
    dispatch(
      setLayout({
        title: "Devices Dashboard",
        subtitle: "Devices Dashboard",
        icon: faChartColumn,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    // get all devices for table
    const adjustUIComponents = () => {
      let maxBarSize = 400;
      let totals = deviceStats;
      let errors = Number(totals.errorState.total);
      let warnings = Number(totals.warningState.total);
      let oks = Number(totals.successState.total);

      if (errors > maxBarSize) {
        errors = maxBarSize;
      }

      if (warnings > maxBarSize) {
        warnings = maxBarSize;
      }

      if (oks > maxBarSize) {
        oks = maxBarSize;
      }

      //Percentage= (Value/Total Value)×100
      let total = errors + warnings + oks;
      let errorsPercent = Number((errors / total) * 100 - 2).toFixed(0);
      let warningsPercent = Number((warnings / total) * 100 - 2).toFixed(0);
      let oksPercent = Number((oks / total) * 100 - 2).toFixed(0);

      document
        .querySelector("#devices-bar .inner .errors")
        ?.setAttribute("style", `width: ${errorsPercent}%`);
      document
        .querySelector("#devices-bar .inner .warnings")
        ?.setAttribute("style", `width: ${warningsPercent}%`);
      document
        .querySelector("#devices-bar .inner .oks")
        ?.setAttribute("style", `width: ${oksPercent}%`);
    };

    adjustUIComponents();
  }, [deviceStats]);

  const { loading } = useAsyncEffect(async () => {
    await Promise.all([
      dispatch(fetchDevices()),
      dispatch(fetchDeviceTotals()),
    ]);
  }, []);

  return (
    <>
      {loading && devices.length < 1 ? (
        <Loader show={true} />
      ) : (
        <>
          <DeviceTableSummary />
          <Mui.Grid container spacing={2}>
            <Mui.Grid item xs={12} sx={{ height: "auto", marginTop: 2 }}>
              <Mui.Card sx={{ border: "solid 1px #ddd", height: "auto" }}>
                <DevicesEnhancedTable devices={devices} />
              </Mui.Card>
            </Mui.Grid>
          </Mui.Grid>
        </>
      )}
    </>
  );
};
