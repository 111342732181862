//@ts-nocheck
import * as Mui from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ICompanyData } from "./SignupPage";

interface SignupCompanyAddressDetailsProps {
  onNextClick: (addressDetails: ICompanyData) => void;
  onPreviousClick: VoidFunction;
  formData: ICompanyData;
}

export const SignupCompanyAddressDetails: React.FC<
  SignupCompanyAddressDetailsProps
> = (props) => {
  const { control, handleSubmit } = useForm<ICompanyData>();
  const countries = [
    { code: "US", description: "United States" },
    { code: "GB", description: "United Kingdom" },
    { code: "AU", description: "Australia" },
    { code: "AT", description: "Austria" },
    { code: "BE", description: "Belgium" },
    { code: "BR", description: "Brazil" },
    { code: "CA", description: "Canada" },
    { code: "DK", description: "Denmark" },
    { code: "FI", description: "Finland" },
    { code: "FR", description: "France" },
    { code: "DE", description: "Germany" },
    { code: "GI", description: "Gibraltar" },
    { code: "IE", description: "Ireland" },
    { code: "IT", description: "Italy" },
    { code: "JP", description: "Japan" },
    { code: "JE", description: "Jersey" },
    { code: "LU", description: "Luxembourg" },
    { code: "NL", description: "Netherlands" },
    { code: "NZ", description: "New Zealand" },
    { code: "PT", description: "Portugal" },
    { code: "SA", description: "Saudi Arabia" },
    { code: "SG", description: "Singapore" },
    { code: "ES", description: "Spain" },
    { code: "SE", description: "Sweden" },
    { code: "CH", description: "Switzerland" },
    { code: "TR", description: "Turkey" },
    ];

  const onSubmit: SubmitHandler<ICompanyData> = (data) => {
    props.onNextClick(data);
  };

  return (
    <Mui.Stack
      direction="column"
      alignItems={"center"}
      sx={{ width: { xs: "100%", md: "70%", xxl: "55%" } }}
      spacing={1}
      padding={2}
    >
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        Enter your Organisation's Details
      </Mui.Typography>
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "75%", textAlign: "center", color: "#aaa" }}
        variant="body2"
      >
        
      </Mui.Typography>

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Mui.Stack
          direction="column"
          alignItems={"center"}
          spacing={2}
          padding={2}
        >
          <Controller
            name="companyAddressLine1"
            control={control}
            defaultValue={
              props.formData.companyAddressLine1 === null
                ? undefined
                : props.formData.companyAddressLine1
            }
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                onChange={field.onChange}
                id="companyAddressLine1"
                label="Address Line 1"
                sx={{ width: "100%" }}
                helperText=""
              />
            )}
          />

          <Controller
            name="companyAddressLine2"
            control={control}
            defaultValue={
              props.formData.companyAddressLine2 === null
                ? undefined
                : props.formData.companyAddressLine2
            }
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                onChange={field.onChange}
                id="companyAddressLine2"
                label="Address Line 2"
                sx={{ width: "100%" }}
                helperText=""
              />
            )}
          />

          <Controller
            name="companyAddressLine3"
            control={control}
            defaultValue={
              props.formData.companyAddressLine3 === null
                ? undefined
                : props.formData.companyAddressLine3
            }
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                onChange={field.onChange}
                id="companyAddressLine3"
                label="Address Line 3"
                sx={{ width: "100%" }}
                helperText=""
              />
            )}
          />

          <Controller
            name="townCity"
            control={control}
            defaultValue={
              props.formData.townCity === null
                ? undefined
                : props.formData.townCity
            }
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                onChange={field.onChange}
                label="Town / City"
                sx={{ width: "100%" }}
                helperText=""
              />
            )}
          />

          <Controller
            name="stateCounty"
            control={control}
            defaultValue={
              props.formData.stateCounty === null
                ? undefined
                : props.formData.stateCounty
            }
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                onChange={field.onChange}
                label="State / County"
                sx={{ width: "100%" }}
                helperText=""
              />
            )}
          />

          <Controller
            name="postalZip"
            control={control}
            defaultValue={
              props.formData.postalZip === null
                ? undefined
                : props.formData.postalZip
            }
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                onChange={field.onChange}
                label="Zip Code / Postal Code"
                sx={{ width: "100%", mb: 2 }}
                helperText=""
              />
            )}
          />

          <Controller
            name="country"
            control={control}
            defaultValue={
              props.formData.country === null
                ? undefined
                : props.formData.country
            }
            render={({ field }) => (
              <Mui.FormControl
                sx={{ alignContent: "flex-start", width: "100%" }}
              >
                <Mui.Select
                  {...field}
                  size="small"
                  sx={{ width: "50%", alignSelf: "flex-start" }}
                >
                  {countries.map((country) => {
                    return (
                      <Mui.MenuItem key={country.code} value={country.code}>
                        {country.description}
                      </Mui.MenuItem>
                    );
                  })}
                </Mui.Select>
                <Mui.FormHelperText>Country</Mui.FormHelperText>
              </Mui.FormControl>
            )}
          />
          <Mui.FormGroup row>
            <Mui.Button
              variant="outlined"
              color="primary"
              sx={{ mt: 2, mr: 2 }}
              onClick={props.onPreviousClick}
            >
              Previous Step
            </Mui.Button>
            <Mui.Button
              variant="outlined"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              Next Step
            </Mui.Button>
          </Mui.FormGroup>
        </Mui.Stack>
      </form>
    </Mui.Stack>
  );
};
