import { Paper, TableBody, TableCell, TablePagination, TableRow, TableContainer, Table, Typography, Tooltip} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FlareItem } from "../../types/alerts.types";
import { getComparator, Order, stableSort } from "../../common/TableUtilities";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import dayjs from "dayjs";
import { EnhancedTableHead } from "./FlareEnhancedTableHead";
import { Link } from "react-router-dom";

interface FlareEnhancedTableProps {
  flareAlert: any;
}

export function FlareEnhancedTable(
  props: FlareEnhancedTableProps
) {
  return <EnhancedTable flareAlert={props.flareAlert}></EnhancedTable>;
}

export default function EnhancedTable(props: {
  flareAlert: any;
}) {
  const { flareAlert } = props;
  const [rows, setRows] = useState<[]>(flareAlert);  
  useEffect(() => {
   setRows(flareAlert)
  }, [flareAlert]);

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof FlareItem>("timeGenerated");
  const [page, setPage] = useState(0);

  const rowsPerPage = 25;
  const total = rows ? rows.length : 0

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof FlareItem
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };
  return (
    <>
      {/* TABLE CARD */}
      <Card>
        <CardContent>
          <TableContainer
            component={Paper}
            sx={{
              borderStyle: "solid",
              borderColor: "#ccc",
              borderWidth: "1px",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="alerts"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length === undefined ? 0 : rows.length}

              />
              {rows.length > 0 ?
                <TableBody>
                  {stableSort(rows as any, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, _index) => {

                      return (
                        <TableRow tabIndex={-1} key={_index}>
                          <TableCell align="left">
                            {dayjs(row.timeGenerated).format('YYYY-MM-DD HH:mm:ssA')}
                          </TableCell>
                          <TableCell align="left">{row.risk} </TableCell>
                          <TableCell align="left">{row.categoryName} </TableCell>
                          <TableCell align="left">{row.content} </TableCell>
                          <TableCell align="left">{row.sourceName} </TableCell>
                          <TableCell align="left">{row.targetName} </TableCell>
                          <TableCell align="left">{row.netLocation} </TableCell>
                          <TableCell align="left">
                            <Tooltip title='Open in new window'>

                              <Link
                                to={`${row.externalUrl}`}
                                target="_blank"
                              >
                                {row.externalUrl}
                              </Link>
                            </Tooltip>
                          </TableCell>



                        </TableRow>
                      );
                    })}
                </TableBody>
                :
                <Typography sx={{ pl: 1, pt: 1 }}>
                  No results found.
                </Typography>
              }

            </Table>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={rows.length === undefined ? 0 : rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
