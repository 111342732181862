import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Company,
  CompanyEmailConfig,
  CompanySentinelConfig,
  CompanySettings,
} from "../../types/company.types";
import { RootState } from "../store";

export interface CompanyState {
  companies: Array<Company>;
  companyEmailConfig: Array<CompanyEmailConfig> | CompanyEmailConfig;
  companySentinelConfig: Array<CompanySentinelConfig>;
  termsOfUse: boolean;
  endUserLicenseAgreement: boolean;
  setUpInfoPage: boolean;
  gatewayServer: boolean;
  companySettings: CompanySettings;
}

const initialState: CompanyState = {
  companies: [],
  companyEmailConfig: [],
  companySentinelConfig: [],
  termsOfUse: false,
  endUserLicenseAgreement: false,
  setUpInfoPage: false,
  gatewayServer: false,
  companySettings: {} as CompanySettings,
};

const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<Array<Company>>) => {
      state.companies = action.payload;
    },
    setTermsOfUse: (state, action: PayloadAction<any>) => {
      state.termsOfUse = action.payload;
    },
    setEndUserLicenseAgreement: (state, action: PayloadAction<any>) => {
      state.endUserLicenseAgreement = action.payload;
    },
    setSetUpInfoPage: (state, action: PayloadAction<any>) => {
      state.setUpInfoPage = action.payload;
    },
    setGatewayServer: (state, action: PayloadAction<any>) => {
      state.gatewayServer = action.payload;
    },
    setSentinelConfig: (
      state,
      action: PayloadAction<Array<CompanySentinelConfig>>
    ) => {
      state.companySentinelConfig = action.payload;
    },
    setEmailConfig: (
      state,
      action: PayloadAction<Array<CompanyEmailConfig>>
    ) => {
      state.companyEmailConfig = action.payload;
    },
    setCompanySettings: (
      state,
      action: PayloadAction<CompanyState["companySettings"]>
    ) => {
      state.companySettings = action.payload;
    },
  },
});

export const {
  setCompanies,
  setTermsOfUse,
  setEndUserLicenseAgreement,
  setSetUpInfoPage,
  setGatewayServer,
  setSentinelConfig,
  setEmailConfig,
  setCompanySettings,
} = companySlice.actions;

export const getCompanies = (state: RootState) => state.company.companies;
export const getCompanyById = (id: string) => (state: RootState) =>
  state.company.companies.find((company) => company.id === id);
export const getTermsofUse = (state: RootState) => state.company.termsOfUse;
export const getEndUserLicenseAgreement = (state: RootState) =>
  state.company.endUserLicenseAgreement;
export const getSetUpInfoPage = (state: RootState) =>
  state.company.setUpInfoPage;
export const getGatewayServer = (state: RootState) =>
  state.company.gatewayServer;
export const getSentinelConfig = (state: RootState) =>
  state.company.companySentinelConfig;
export const getEmailConfig = (state: RootState) =>
  state.company.companyEmailConfig;
export const getCompanySettings = (state: RootState) =>
  state.company.companySettings;
export default companySlice.reducer;
