import { faBuildings } from "@fortawesome/pro-light-svg-icons"
import { Grid, CardContent, Divider, Typography, Box } from "@mui/material"
import { Card } from "../../../components/Card"
import EmailConfigurationForm from "../EmailConfigurationForm"
import { InboundEmailConfigurationForm } from "../InboundEmailConfigurationForm"
import { useAppSelector } from "../../../redux/hooks"
import { isUserAdmin } from "../../../redux/Slices/account.slice"
import { useIdentityContext } from "../../../context/IdentityContext"
import { getEmailConfig } from "../../../redux/Slices/company.slice"



export const EmailConfiguration = () => {
    const { authenticatedUser } = useIdentityContext();
    const isAdmin = useAppSelector(isUserAdmin(authenticatedUser.userId));
    const emailConfig: any = useAppSelector(getEmailConfig);


    return (
        <Box>
            <Card
                id="settings-email-config"
                headerIcon={faBuildings}
                headerTitle={`Email Configuration`}
                headerSubText={`View the email configuration for your orgs.`}
                showHelp={false}
                helpHeaderText=""
                helpHeaderDescription=""
                cardHeaderActions={null}
                sx={{ height: "100%" }}
                cardContent={
                    emailConfig?.length === 1 || emailConfig?.id ? (
                        <CardContent>
                            <EmailConfigurationForm
                                emailConfig={
                                    emailConfig.length ? emailConfig[0] : emailConfig
                                }
                            />
                            <Divider sx={{ m: 3 }}></Divider>
                            {isAdmin ? (
                                <Grid item xs={12} md={12}>
                                    <InboundEmailConfigurationForm />
                                </Grid>
                            ) : (
                                <></>
                            )}
                        </CardContent>
                    ) : (
                        <CardContent>
                            <Grid
                                container
                                sx={{ marginTop: 4 }}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Typography
                                    sx={{ fontSize: "28px !important" }}
                                    align="center"
                                >
                                    Please select a company to edit it's email configuration
                                </Typography>
                            </Grid>
                        </CardContent>
                    )
                }
            ></Card>
            </Box>
    )
}