
import * as React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { useRef } from 'react';
require("highcharts/modules/exporting")(Highcharts);



export default function ReportBarChart(props) {
    const { barChartCategories, barChartData, type, title } = props
    const chartComponent = useRef({});


    const options = {
        chart: {
            type: 'column'
        },

        credits: {
            enabled: false
        },
        title: {
            text: title,
        },
       
        xAxis: {
            title: 'Source',
            // categories array of string ['', '', '', '']
            categories: barChartCategories

           
        },
        yAxis: {
            title: {
                text: undefined
            },
        },
          plotOptions: {
            column: {
                borderRadius: 5,
                borderWidth: 1,
                borderColor: '#666',
            }
        },
        tooltip: {
            pointFormat: '{point.y} ' + type
        },
        series: [
            {
                showInLegend: false,
                name: undefined,
                // array of numbers [1,2,3,4,5,6]
                data: barChartData,
                color: 'rgb(232,143,0, 0.9)'
            }
        ],
        exporting: {
            // filename: fileName + '_Chart'
            filename:'newfilename'
        }
        
   


    }
  
    return (
        
        <Card sx={{width: '100%', m: 1, p: 1, border: "solid 1px #ddd"}}>           
            <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%", width: '100%' } }} />

        </Card>

    );
}