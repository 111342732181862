import {
  ImageOverlay,
  MapContainer,
  TileLayer,
  useMapEvents,
} from "react-leaflet";
//import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  TextField,
  Divider,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import L from "leaflet";
import { uploadImage } from "../../redux/Thunks/util";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useIdentityContext } from "../../context/IdentityContext";
import {
  getSelectedCompanyId,
  getUserById,
} from "../../redux/Slices/account.slice";
import { useAsyncEffect } from "../../common/hooks";
import { Loader } from "../../components/Loader";
import { deleteCompanyPicture } from "../../redux/Thunks/company";
import CustomMarker from "./CustomMarker";
import { fetchDevices } from "../../redux/Thunks/devices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import {
  createOrEditMap,
  createOrEditMapCoordinate,
  deleteCoordinate,
  deleteMap,
  fetchMaps,
} from "../../redux/Thunks/maps";
import { Coordinate, getMaps, Map } from "../../redux/Slices/maps.slice";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { getDevices } from "../../redux/Slices/devices.slice";

const DEFAULT_ZOOM = 2;

export interface EventMapControlProps {
  onClick: (e: L.LeafletMouseEvent) => void;
  zoom: number;
  center: [number, number];
}

const EventMapControl = ({ onClick, zoom, center }: EventMapControlProps) => {
  const map = useMapEvents({
    click: onClick,
  });

  useEffect(() => {
    map.setView(center, zoom, { animate: true, duration: 10 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [center, zoom]);

  return null;
};

export const convertLatLng = (latlng: L.LatLng): [number, number] => [
  latlng.lat,
  latlng.lng,
];

const convertCoordinate = (coordinate: Coordinate): [number, number] => [
  coordinate.lat,
  coordinate.lng,
];

const LiveMap = () => {
  const { authenticatedUser } = useIdentityContext();
  const selectedCompanyId = useAppSelector(getSelectedCompanyId);
  const maps = useAppSelector(getMaps);
  const devices = useAppSelector(getDevices);
  const user = useAppSelector(getUserById(authenticatedUser.userId));
  const dispatch = useAppDispatch();
  const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
  const [center, setCenter] = useState<[number, number]>([51.505, -0.09]);
  const [source, setSource] = useState("");
  const [newName, setNewName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] =
    useState<Coordinate["deviceGroupType"]>("");
  const [confirmDeleteMap, setConfirmDeleteMap] = useState(false);
  const [confirmDeleteMarker, setConfirmDeleteMarker] = useState(false);
  const [confirmDeleteImage, setConfirmDeleteImage] = useState(false);
  const [selectedCoordinate, setSelectedCoordinate] = useState("");
  const [selectedMapId, setSelectedMapId] = useState(
    maps.length > 0 ? maps[0].id : ""
  );
  const [tab, setTab] = useState(0);
  const [changeLoading, setChangeLoading] = useState(false);

  const companyId = selectedCompanyId || authenticatedUser.companyId;
  const rolesArray = user?.roles.split(",");
  const isMapAdmin = rolesArray?.includes("Map Admin");

  const isCustom = Boolean(source);

  const selectedMap: Map | undefined = useMemo(() => {
    return maps.find((map) => map.id === selectedMapId);
  }, [selectedMapId, maps]);

  const healthyDevices = useMemo(() => {
    const coordinates = selectedMap?.coordinates.filter(
      (coord) => coord.deviceGroupType !== ""
    );
    if (!coordinates) {
      return { healthy: 0, warning: 0, unknown: 0 };
    }

    const healthy = coordinates.reduce((acc, curr) => {
      return (
        acc +
        curr.deviceIds.reduce((acc, id) => {
          if (devices.find((device) => device.id === id)?.healthState === 1) {
            return acc + 1;
          } else return acc;
        }, 0)
      );
    }, 0);

    const warning = coordinates.reduce((acc, curr) => {
      return (
        acc +
        curr.deviceIds.reduce((acc, id) => {
          if (devices.find((device) => device.id === id)?.healthState === 3) {
            return acc + 1;
          } else return acc;
        }, 0)
      );
    }, 0);

    const unknown = coordinates.reduce((acc, curr) => {
      return (
        acc +
        curr.deviceIds.reduce((acc, id) => {
          if (devices.find((device) => device.id === id)?.healthState === 5) {
            return acc + 1;
          } else return acc;
        }, 0)
      );
    }, 0);
    return { healthy, warning, unknown };
  }, [devices, selectedMap?.coordinates]);

  const { loading } = useAsyncEffect(async () => {
    const imgCheck = new Promise((res, rej) => {
      var img = new Image();
      img.src = `https://poimages.blob.core.windows.net/poimages/PortalImages/${companyId}/${selectedMapId}.png`;
      img.onload = res;
      img.onerror = rej;
    })
      .then(() => {
        if (selectedMapId === "") {
          setSource("");
          return;
        }
        setSource(
          `https://poimages.blob.core.windows.net/poimages/PortalImages/${companyId}/${selectedMapId}.png`
        );
      })
      .catch((e) => setSource(""));
    if (selectedMapId !== "") {
      await imgCheck;
      return;
    }
    await Promise.all([
      dispatch(fetchMaps(companyId)),
      dispatch(fetchDevices()),
    ]);
  }, [companyId, selectedMapId]);

  //On initial load set selected map to match tab index 0
  useEffect(() => {
    if (selectedMapId === "") {
      setSelectedMapId(maps.length > 0 ? maps[0].id : "");
    }
  }, [maps, selectedMapId]);

  //Initialize state on map change
  useEffect(() => {
    if (!selectedMap) return;
    setNewName(selectedMap.name);
    setNewDescription(selectedMap.description);
  }, [selectedMap]);

  //Match selected tab to position of map in event of change of order
  useEffect(() => {
    if (selectedMapId === "") {
      return;
    }

    const newTabIdx = maps.findIndex((map) => map.id === selectedMapId);
    if (newTabIdx !== -1) setTab(newTabIdx);
    else setTab(0);
  }, [dispatch, maps, selectedMapId]);

  const createMarker = async (e: L.LeafletMouseEvent) => {
    if (
      e.latlng.lat > 90 ||
      e.latlng.lat < -90 ||
      e.latlng.lng > 180 ||
      e.latlng.lng < -180
    ) {
      return;
    }
    await dispatch(
      createOrEditMapCoordinate({
        newCoordinate: {
          mapId: selectedMapId,
          lat: e.latlng.lat,
          lng: e.latlng.lng,
          deviceGroupType: createMode,
        },
        companyId,
      })
    );
    setCreateMode("");
    await dispatch(fetchMaps(companyId));
  };

  const uploadMap = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) {
      return;
    }
    const file = files[0];

    await dispatch(
      uploadImage({
        uploadUrl: "/companycommands/UploadCompanyPictures",
        imageFile: file,
        fileName: `${selectedMapId}.png`,
      })
    );
    setZoom(DEFAULT_ZOOM);
    setSource(
      `https://poimages.blob.core.windows.net/poimages/PortalImages/${companyId}/${selectedMapId}.png?key=${new Date().toString()}`
    );
  };

  const onMarkerClick = (e: L.LeafletMouseEvent) => {
    //setCenter(convertLatLng(e.latlng));
    //setZoom(4);
  };

  const onMarkerDelete = async (CoordinateId: string) => {
    await dispatch(deleteCoordinate({ CoordinateId, companyId }));
    await dispatch(fetchMaps(companyId));
  };

  const updateCoordinate = async (newCoordinate: Coordinate) => {
    await dispatch(createOrEditMapCoordinate({ newCoordinate, companyId }));
    await dispatch(fetchMaps(companyId));
  };

  const removeCustomMapPicture = async () => {
    await dispatch(deleteCompanyPicture(selectedMapId));
    setZoom(DEFAULT_ZOOM);
    setSource("");
    await dispatch(fetchMaps(companyId));
    setConfirmDeleteImage(false);
  };

  const removeMap = async () => {
    await dispatch(deleteCompanyPicture(selectedMapId));
    await dispatch(deleteMap({ companyId, MapId: selectedMapId }));
    setZoom(DEFAULT_ZOOM);
    setSource("");
    setSelectedMapId("");
    await dispatch(fetchMaps(companyId));
    setConfirmDeleteMap(false);
  };

  const onEditMap = async () => {
    if (!selectedMap) {
      return;
    }
    setChangeLoading(true);
    await dispatch(
      createOrEditMap({
        newMap: { ...selectedMap, name: newName, description: newDescription },
        companyId,
      })
    );
    await dispatch(fetchMaps(companyId));
    setChangeLoading(false);
  };

  const handleCreateMode = (newMode: Coordinate["deviceGroupType"]) => {
    setCreateMode(newMode);
  };

  const createMap = async () => {
    await dispatch(
      createOrEditMap({
        newMap: { name: `New Map ${maps.length + 1}`, description: "" },
        companyId,
      })
    );
    await dispatch(fetchMaps(companyId));
  };

  const onPositionChanged = async (
    coordinate: Coordinate,
    position: L.LatLng
  ) => {
    await dispatch(
      createOrEditMapCoordinate({
        newCoordinate: { ...coordinate, lat: position.lat, lng: position.lng },
        companyId,
      })
    );
    await dispatch(fetchMaps(companyId));
  };

  return (
    <Box>
      <ConfirmationDialog
        title="Are you sure you want to delete this map?"
        content="Deleting this map will remove it entirely, including all associated data including but not limited to image assets and markers."
        open={confirmDeleteMap}
        onConfirm={() => {
          removeMap();
          setConfirmDeleteMap(false);
        }}
        onCancel={() => setConfirmDeleteMap(false)}
      />
      <ConfirmationDialog
        title="Are you sure you want to delete this device marker?"
        open={confirmDeleteMarker}
        onConfirm={() => {
          onMarkerDelete(selectedCoordinate);
          setConfirmDeleteMarker(false);
        }}
        onCancel={() => setConfirmDeleteMarker(false)}
      />
      <ConfirmationDialog
        title="Are you sure you want to delete the custom map image?"
        open={confirmDeleteImage}
        onConfirm={removeCustomMapPicture}
        onCancel={() => setConfirmDeleteImage(false)}
      />
      <Tabs value={tab} variant="scrollable">
        {maps.map((map) => (
          <Tab
            label={map.name}
            onClick={() => {
              setSelectedMapId(map.id);
            }}
          />
        ))}
        {isMapAdmin && (
          <Tab
            label="Create New Map"
            onClick={createMap}
            iconPosition="end"
            icon={<FontAwesomeIcon icon={faAdd} />}
          />
        )}
      </Tabs>
      {changeLoading || loading ? (
        <Loader show={true} />
      ) : (
        <Grid container direction="column">
          <Grid
            container
            alignItems="flex-start"
            justifyContent={
              editMode ? "" : isMapAdmin ? "space-between" : "center"
            }
            spacing={1}
            sx={{ marginTop: 1, marginBottom: 2 }}
          >
            {isMapAdmin && (
              <Grid item>
                <Button
                  size="large"
                  variant={editMode ? "contained" : "outlined"}
                  onClick={() => {
                    setEditMode(!editMode);
                    if (editMode === true && createMode !== "") {
                      handleCreateMode("");
                    }
                  }}
                >
                  {editMode ? "Leave Edit Mode" : "Edit Mode"}
                </Button>
              </Grid>
            )}
            {!editMode && (
              <Grid
                container
                direction="column"
                alignItems="center"
                sx={{ width: "fit-content" }}
              >
                {selectedMap?.description && (
                  <Grid item>{selectedMap.description}</Grid>
                )}
                <Divider sx={{ width: "100%" }} />
                <Grid item xs={4}>
                  <h4
                    style={{
                      padding: "0 20px",
                      fontWeight: "normal",
                      fontSize: "16px",
                      margin: 0,
                      marginBottom: "10px",
                    }}
                  >
                    Device Health
                  </h4>
                  <div>
                    <div
                      style={{
                        display: "inline-block",
                        margin: "0px 20px 0px 20px",
                      }}
                    >
                      <h3>
                        <FontAwesomeIcon
                          icon={faExclamationCircle}
                          color="red"
                        />{" "}
                        {healthyDevices.unknown}
                      </h3>
                      <span>Unknown</span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        margin: "0px 20px 0px 20px",
                      }}
                    >
                      <h3>
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          color="orange"
                        />{" "}
                        {healthyDevices.warning}
                      </h3>
                      <span>Warning</span>
                    </div>
                    <div
                      style={{
                        display: "inline-block",
                        margin: "0px 20px 0px 20px",
                      }}
                    >
                      <h3>
                        <FontAwesomeIcon icon={faCheckCircle} color="green" />{" "}
                        {healthyDevices.healthy}
                      </h3>
                      <span>Healthy</span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            )}
            {!editMode && isMapAdmin && (
              <Grid item>
                <Button
                  size="large"
                  variant="contained"
                  color="error"
                  onClick={() => setConfirmDeleteMap(true)}
                  endIcon={<FontAwesomeIcon icon={faTrash} />}
                >
                  <span style={{ lineHeight: "22px" }}>Delete Map</span>
                </Button>
              </Grid>
            )}
            {editMode && isMapAdmin && (
              <Grid item>
                {!isCustom ? (
                  <>
                    <input
                      accept="image/png, image/jpeg"
                      hidden={true}
                      id="map-button"
                      type="file"
                      onChange={uploadMap}
                    />
                    <label htmlFor="map-button">
                      <Button size="large" variant="contained" component="span">
                        Upload Map Image
                      </Button>
                    </label>
                  </>
                ) : (
                  <Button
                    size="large"
                    variant="contained"
                    color="error"
                    onClick={() => setConfirmDeleteImage(true)}
                  >
                    Remove Map Image
                  </Button>
                )}
              </Grid>
            )}
            {editMode && createMode !== "single" && (
              <Grid
                item
                onClick={() =>
                  handleCreateMode(createMode === "" ? "single" : "")
                }
              >
                <Button
                  size="large"
                  variant="outlined"
                  color={createMode ? "error" : "primary"}
                >
                  {createMode ? "Cancel" : "Create Device Marker"}
                </Button>
              </Grid>
            )}
            {editMode && createMode !== "group" && (
              <Grid
                item
                onClick={() =>
                  handleCreateMode(createMode === "" ? "group" : "")
                }
              >
                <Button
                  size="large"
                  variant="outlined"
                  color={createMode ? "error" : "primary"}
                >
                  {createMode ? "Cancel" : "Create Device Group"}
                </Button>
              </Grid>
            )}
            {editMode && (
              <>
                <Grid item>
                  <TextField
                    size="small"
                    label="Map Name"
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    size="small"
                    label="Map Description"
                    multiline
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <Button
                    size="large"
                    disabled={
                      newName === selectedMap?.name &&
                      newDescription === selectedMap?.description
                    }
                    variant="contained"
                    onClick={onEditMap}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
          <MapContainer
            style={{
              height: "calc(100vh - 100px - 64px)",
              width: "100%",
            }}
            scrollWheelZoom={false}
            boundsOptions={{ padding: [0, 0] }}
            minZoom={DEFAULT_ZOOM}
            maxZoom={18}
            zoomSnap={0}
            bounds={[
              [90, 180],
              [-90, -180],
            ]}
            maxBounds={[
              [180, 180],
              [-180, -180],
            ]}
          >
            {!isCustom && (
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url={"https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"}
                noWrap={true}
              />
            )}
            {isCustom && (
              <ImageOverlay
                bounds={[
                  [90, 180],
                  [-90, -180],
                ]}
                url={source}
              />
            )}
            {
              <EventMapControl
                onClick={createMode ? createMarker : () => {}}
                center={center}
                zoom={zoom}
              />
            }
            {selectedMap &&
              selectedMap.coordinates.map((coordinate) => (
                <CustomMarker
                  key={coordinate.id}
                  coordinate={coordinate}
                  position={convertCoordinate(coordinate)}
                  eventHandlers={{ click: onMarkerClick }}
                  isEditMode={editMode}
                  onChange={async (newCoordinate) => {
                    await updateCoordinate(newCoordinate);
                  }}
                  onDelete={() => {
                    setSelectedCoordinate(coordinate.id);
                    setConfirmDeleteMarker(true);
                  }}
                  onPositionChanged={(position) => {
                    onPositionChanged(coordinate, position);
                  }}
                />
              ))}
          </MapContainer>
        </Grid>
      )}
    </Box>
  );
};

export default LiveMap;
