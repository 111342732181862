//@ts-nocheck
import * as React from "react";
import * as Mui from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/pro-light-svg-icons";
import { faSquareMinus } from "@fortawesome/pro-solid-svg-icons";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { FormattedDate } from "../../common/dateUtils";
import { StepHeader } from "./StepHeader";
import { UserSummary } from "../../types/account.types";
import {
  ChangeManagementMilestone,
  ChangeManagementMilestoneTask,
} from "../../types/changemanagement.types";

interface AddMilestoneProps {
  milestones: ChangeManagementMilestone[];
  onNewMilestoneAdded: (
    title: string,
    dueDate: Date,
    assignedToId?: string,
    assignedToName?: string
  ) => void;
  onNewMilestoneTaskAdded: (
    milestone: ChangeManagementMilestone,
    description: string
  ) => void;
  onMilestoneTaskRemoved: (
    milestone: ChangeManagementMilestone,
    milestoneTask: ChangeManagementMilestoneTask
  ) => void;
  onStepComplete: (stepNumber: number) => void;
  onEditStep: (stepNumber: number) => void;
  stepNumber: number;
  currentStep: number;
  isComplete: boolean;
  users: UserSummary[];
}

const AddMilestoneTodo: React.FC = () => {
  return (
    <>
      <Mui.Typography>Add your milestones</Mui.Typography>
      <Mui.Typography variant="subtitle2">
        Add any milestones which can be used to monitor progress of the plans or
        the change management item you have created.
      </Mui.Typography>
    </>
  );
};

const AddMilestoneSummary: React.FC<{ count: number }> = (props) => {
  return (
    <Mui.Typography>{`Currently there are ${props.count} milestones(s) defined.`}</Mui.Typography>
  );
};

export const AddMilestone: React.FC<AddMilestoneProps> = (props) => {
  const addMilestoneForm = useForm();

  const onAddMilestoneSubmit: SubmitHandler<FieldValues> = (data) => {
    if (data.assignedUser) {
      const user = props.users.find((obj) => {
        return obj.id === data.assignedUser;
      });

      if (user)
        props.onNewMilestoneAdded(
          data.title,
          data.dueDate,
          user.id,
          `${user.firstName} ${user.lastName}`
        );
    } else {
      props.onNewMilestoneAdded(data.title, data.dueDate);
    }
    addMilestoneForm.reset();
  };

  const addMilestoneFormComplete = () => {
    props.onStepComplete(props.stepNumber);
  };

  return (
    <Mui.Card sx={{ border: "solid 1px #ddd", height: "100%" }}>
      <StepHeader
        stepNumber={props.stepNumber + 1}
        headerSubText={`${props.milestones.length} milestone(s) set`}
        headerText="Milestones"
        isComplete={props.isComplete}
      />
      <Mui.CardContent sx={{ pt: 0 }}>
        {props.isComplete && props.currentStep !== props.stepNumber ? (
          <AddMilestoneSummary count={props.milestones.length} />
        ) : !props.isComplete && props.currentStep !== props.stepNumber ? (
          <AddMilestoneTodo />
        ) : (
          <>
            <form name="addmilestone">
              <Mui.Box
                sx={{
                  pb: 2,
                  borderBottomStyle: "solid",
                  borderBottomColor: "#ccc",
                  borderBottomWidth: "1px",
                  mb: 1,
                }}
              >
                <Mui.Typography color={"orange"} sx={{ mb: 2 }}>
                  Add a new milestone
                </Mui.Typography>
                <Controller
                  render={({ field: { name, value, onChange } }) => (
                    <Mui.TextField
                      name={name}
                      value={value}
                      label={"Milestone Title"}
                      sx={{ mr: 1, width: "50%" }}
                      size="small"
                      onChange={onChange}
                      error={
                        addMilestoneForm.formState.errors.title !== undefined
                      }
                      helperText={
                        addMilestoneForm.formState.errors.title !== undefined
                          ? "Please enter a title for the milestone"
                          : ""
                      }
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          ev.preventDefault();
                        }
                      }}
                    />
                  )}
                  control={addMilestoneForm.control}
                  name={"title"}
                  defaultValue=""
                  rules={{ required: { value: true, message: "" } }}
                />

                <Controller
                  render={({ field: { name, value, onChange } }) => (
                    <DatePicker
                      label="Due date"
                      value={value}
                      onChange={onChange}
                      renderInput={(params) => (
                        <Mui.TextField
                          name={name}
                          size="small"
                          sx={{ mr: 1 }}
                          {...params}
                          error={
                            addMilestoneForm.formState.errors.dueDate !==
                            undefined
                          }
                        />
                      )}
                    />
                  )}
                  control={addMilestoneForm.control}
                  name={"dueDate"}
                  defaultValue={null}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter a due date for the milestone",
                    },
                  }}
                />
                <Controller
                  name="assignedUser"
                  control={addMilestoneForm.control}
                  defaultValue={""}
                  render={({ field }) => (
                    <>
                      <Mui.Typography color={"orange"} sx={{ mt: 2 }}>
                        Assign milestone to
                      </Mui.Typography>
                      <Mui.Select
                        {...field}
                        size="small"
                        sx={{ width: "20%", mr: 2, mt: "2px" }}
                        displayEmpty
                      >
                        {props.users.map((item, index) => {
                          return (
                            <Mui.MenuItem key={index} value={item.id}>
                              {item.firstName} {item.lastName}
                            </Mui.MenuItem>
                          );
                        })}
                      </Mui.Select>
                    </>
                  )}
                />
                <Mui.Button
                  variant="outlined"
                  sx={{ pb: 1, pt: 1, marginTop: "-4px" }}
                  onClick={addMilestoneForm.handleSubmit(onAddMilestoneSubmit)}
                >
                  Add Milestone
                </Mui.Button>
              </Mui.Box>
            </form>
            <Mui.Typography color={"orange"} sx={{ mb: 1 }}>
              Milestones timeline
            </Mui.Typography>
            <Timeline position="alternate">
              {props.milestones.map(
                (milestone: ChangeManagementMilestone, index: number) => {
                  return (
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0" }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                      >
                        {`Due By ${FormattedDate(milestone.dueDate)} with ${
                          milestone.tasks.length
                        } tasks`}
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot
                          sx={{
                            backgroundColor: "white",
                            borderWidth: "2px",
                            borderColor: "orange",
                            p: 1,
                            boxShadow: "none",
                            ":hover": {
                              bgcolor: "navy", //theme.palette.primary.main
                              color: "orange",
                              borderColor: "navy",
                            },
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faFlag}
                            color={"orange"}
                            size="lg"
                          />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Mui.List>
                          <Mui.ListItem sx={{ pb: 0, pl: 0 }}>
                            <Mui.ListItemText
                              sx={{ p: 0 }}
                              primary={
                                <Mui.Typography fontWeight="bold" variant="h5">
                                  {`${milestone.title} ${
                                    milestone.assignedToId
                                      ? `(${milestone.assignedToName})`
                                      : ""
                                  }`}{" "}
                                </Mui.Typography>
                              }
                            />
                          </Mui.ListItem>
                          {milestone.tasks.map((task, index) => {
                            return (
                              <Mui.ListItem sx={{ pb: 0, pl: 0 }}>
                                <Mui.ListItemText
                                  sx={{ p: 0 }}
                                  primary={
                                    <Mui.Typography>
                                      <FontAwesomeIcon
                                        icon={faSquareMinus}
                                        color={"orange"}
                                        size="sm"
                                        style={{
                                          paddingTop: "2px",
                                          marginRight: "5px",
                                        }}
                                        onClick={() => {
                                          props.onMilestoneTaskRemoved(
                                            milestone,
                                            task
                                          );
                                        }}
                                      />
                                      {task.description}
                                    </Mui.Typography>
                                  }
                                />
                              </Mui.ListItem>
                            );
                          })}
                          <Mui.ListItem sx={{ pb: 0, pl: 0 }}>
                            <Mui.ListItemText
                              sx={{ p: 0 }}
                              primary={
                                <Mui.TextField
                                  label="Milestone task (tab to accept)"
                                  multiline
                                  rows={4}
                                  sx={{ mr: 1, width: "100%" }}
                                  size="small"
                                  defaultValue={null}
                                  onBlur={(
                                    event: React.FocusEvent<HTMLInputElement>
                                  ) => {
                                    if (
                                      event.target.value &&
                                      event.target.value.trim() !== ""
                                    )
                                      props.onNewMilestoneTaskAdded(
                                        milestone,
                                        event.target.value
                                      );
                                    event.target.value = "";
                                  }}
                                />
                              }
                            />
                          </Mui.ListItem>
                        </Mui.List>
                      </TimelineContent>
                    </TimelineItem>
                  );
                }
              )}
            </Timeline>
          </>
        )}
      </Mui.CardContent>
      <Mui.CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
        {props.currentStep === props.stepNumber ? (
          <Mui.Button
            variant="outlined"
            onClick={() => addMilestoneFormComplete()}
          >
            {props.milestones.length === 0
              ? `Save (i'm ok with no milestones)`
              : "Save"}
          </Mui.Button>
        ) : (
          <></>
        )}
        {props.currentStep !== props.stepNumber ? (
          <Mui.Button
            variant="outlined"
            onClick={() => props.onEditStep(props.stepNumber)}
          >
            Edit
          </Mui.Button>
        ) : (
          <></>
        )}
      </Mui.CardActions>
    </Mui.Card>
  );
};
