import { faTrashCan } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Grid, Box, Typography, InputLabel, TextField, Tooltip } from "@mui/material"


interface PlanChecksProps {
   idx: number;
   index: number;
   formValues: any;
   onDeleteCheck: (idx: number, index: number) => void;
   handleChangeDescription: (e: any, index: number, idx: number) => void;
}

export const PlanCheck = (props: PlanChecksProps) => {
    const { idx, index, formValues, onDeleteCheck, handleChangeDescription } = props

    return (
        <Grid container spacing={1}>
        <Grid item xs={12} >
            <Box sx={{ display: 'flex' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Check {idx + 1}</Typography>
                <Tooltip title="Delete Check">
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color={"#f8931c"}
                        size="lg"
                        style={{ position: 'absolute', right: 50, paddingTop: 10 }}
                        onClick={() => onDeleteCheck(idx, index)}
                    />
                </Tooltip>
            </Box>
        </Grid>
        <Grid item xs={12}>
            <Box>
                <InputLabel id="demo-multiple-checkbox-label">
                    Description
                </InputLabel>
                <TextField
                    required
                    id="title"
                    sx={{ width: "100%"}}
                    value={formValues.plans[index].checks[idx].description}
                    onChange={(e) => {
                        handleChangeDescription(e, index, idx)
                    }}
                    rows={4}
                    multiline
                />

            </Box>
        </Grid>
    </Grid>
    )
}