import { Autocomplete, Card, CardContent, FormControl, Grid, TextField, Typography, } from "@mui/material"
import { determineAutocompleteValues, determineBarChartDataCMForUserStats, determineBarChartDataForUserStats, determineAssignedTicketData, determineRowDataForUserStats, determineCMData, determineClusterData, determineClusterDataCM } from "./UserTabUtils";
import { useEffect, useState } from "react";
import { UserStatistics } from "../../../../types/account.types";
// import ReportBarChart from "../Widgets/ReportBarChart";
import TicketsTableWidget from "../Widgets/TicketsTableWidget";
import CMTableWidget from "../Widgets/CMTableWidget";
import ReportsTable from "../Widgets/ReportsTable";
import { Ticket } from "../../../../types/tickets.types";
import ReportsClusterBarChart from "../Widgets/ReportsClusterBarChart";
interface UserInfoProps {
    userStatistics: any;
    user: any;
    isAdmin: any;
    allTickets: Ticket[]
    CMSummaries: any;
}



export const UserInfo = (props: UserInfoProps) => {
    const { userStatistics, user, isAdmin, allTickets, CMSummaries } = props
    const userName = user.firstName + ' ' + user.lastName
    const [searchValue, setSearchValue] = useState(userName)
    const [selectedUser, setSelectedUser] = useState<Array<UserStatistics>>(userStatistics.filter((el: { fullName: string; }) => el.fullName === userName))
    const autocompleteValues = determineAutocompleteValues(userStatistics)
    const barChartCategories = ['Assigned Tickets', 'Raised Tickets']
    const tableHeaders = ['Category', 'Info']
    const [barChartData, setBarChartData] = useState<any>([])
    const [rowData, setRowData] = useState<any>([])
    // const barChartCategoriesCM = ['Assigned CM Items', 'Assigned CM Milestones', 'Assigned CM Plans']
    const barChartCategoriesCM = ['Assigned CM Milestones', 'Assigned CM Plans']

    const [barChartDataCM, setBarChartDataCM] = useState<any>([])
    const [assignedTicketData, setAssignedTicketData] = useState([])
    const [cmData, setCmData] = useState([])
    const [clusterData, setClusterData] = useState([])
    const [clusterDataCM, setclusterDataCM] = useState<any>([])
    const handleCriteriaChange = (value: any) => {
        setSearchValue(value)
    }
    useEffect(() => {
        const filteredUser = userStatistics.filter((el: { fullName: string; }) => el.fullName === searchValue)
        setSelectedUser(filteredUser)
    }, [searchValue])

    useEffect(() => {
        if (selectedUser.length > 0) {
            setRowData(determineRowDataForUserStats(selectedUser, allTickets))
            setBarChartData(determineBarChartDataForUserStats(selectedUser, allTickets))
            setBarChartDataCM(determineBarChartDataCMForUserStats(selectedUser))
            setAssignedTicketData(determineAssignedTicketData(selectedUser))
            setCmData(determineCMData(selectedUser))
            setClusterData(determineClusterData(selectedUser, allTickets))
            setclusterDataCM(determineClusterDataCM(selectedUser, CMSummaries))


        }

    }, [selectedUser])
    return (
        <Card id='exportUserStats' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            User Statistics
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-start' }}>
                        <FormControl sx={{ width: '40%', pl: 1, pr: 1 }} variant="outlined" size='small'>
                            <Autocomplete
                                disabled={!isAdmin}
                                id="userNameAutocomplete"
                                options={autocompleteValues}
                                size="small"
                                sx={{ display: 'flex', flexGrow: 1 }}
                                onChange={(event: any, newValue: string | null) => {
                                    handleCriteriaChange(newValue)
                                }}
                                value={searchValue}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            display: 'flex',
                                            flexGrow: 1,
                                            "& .MuiSvgIcon-root": {
                                                color: "rgb(250,165,66)",
                                            },
                                            label: { fontWeight: 'bold' }
                                        }}
                                        label='Users'
                                        value={searchValue}
                                        onChange={(event) =>
                                            handleCriteriaChange(event.target.value)
                                        }
                                    />
                                )}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                     
                    </Grid>


                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                    <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'User Statistics Data'}
                        />
                       
                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        {/* <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'tickets'}
                            title={'Tickets'}
                        /> */}
                    <ReportsClusterBarChart
                         barChartCategories={barChartCategories}
                         barChartData={clusterData}
                         type={'tickets'}
                         title={'Tickets'}
                    />
                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                    <ReportsClusterBarChart
                         barChartCategories={barChartCategoriesCM}
                         barChartData={clusterDataCM}
                         type={'items'}
                         title={'Change Management'}
                    />
                        {/* <ReportBarChart
                            barChartCategories={barChartCategoriesCM}
                            barChartData={barChartDataCM}
                            type={'items'}
                            title={'Change Management'}

                        /> */}
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <TicketsTableWidget
                            assignedTicketData={assignedTicketData}
                        />
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <CMTableWidget
                            cmData={cmData}

                        />

                    </Grid>



                </Grid>

            </CardContent>

        </Card >
    )
}