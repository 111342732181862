import { useTheme } from "@mui/material";
import * as Mui from "@mui/material";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { NotificationBanner } from "../../components/NotificationBanner";
import { FormattedDateTime } from "../../common/dateUtils";
import {
  ChangeManagementItemSummary,
  ChangeManagementStatus,
} from "../../types/changemanagement.types";

interface NotificationsProps {
  changeManagementItem: ChangeManagementItemSummary;
  onPlanApproved: VoidFunction;
  onRequestSignoff: VoidFunction;
  onSignoff: VoidFunction;
}

export const Notifications: React.FC<NotificationsProps> = (props) => {
  var theme = useTheme();

  return (
    <>
      <NotificationBanner
        mainColour={theme.palette.info.main}
        headerText={`The change management item is currently awaiting approval.`}
        subText={`Created on ${FormattedDateTime(
          props.changeManagementItem.createdDate
        )}`}
        icon={faCircleExclamation}
        showBanner={
          props.changeManagementItem.status === ChangeManagementStatus.New &&
          !props.changeManagementItem.canApprove
        }
        sx={{ mb: 2 }}
      />

      <NotificationBanner
        mainColour={theme.palette.primary.main}
        headerText={`The change management item is currently awaiting approval. Please check and approve`}
        subText={`Created on ${FormattedDateTime(
          props.changeManagementItem.createdDate
        )}`}
        icon={faCircleExclamation}
        showBanner={props.changeManagementItem.canApprove}
        sx={{ mb: 2 }}
      >
        <Mui.Button variant="contained" onClick={props.onPlanApproved}>
          Approve this Plan
        </Mui.Button>
      </NotificationBanner>

      <NotificationBanner
        mainColour={theme.palette.primary.main}
        headerText={`The change management item is currently complete and you can now request signoff. Please check and click 'Request Signoff'`}
        subText={`Created on ${FormattedDateTime(
          props.changeManagementItem.createdDate
        )}`}
        icon={faCircleExclamation}
        showBanner={props.changeManagementItem.canRequestSignoff}
        sx={{ mb: 2 }}
      >
        <Mui.Button variant="contained" onClick={props.onRequestSignoff}>
          Request Signoff
        </Mui.Button>
      </NotificationBanner>

      <NotificationBanner
        mainColour={theme.palette.info.main}
        headerText={`The change management item is currently complete and waiting for the engineer to request signoff'`}
        subText={`Created on ${FormattedDateTime(
          props.changeManagementItem.createdDate
        )}`}
        icon={faCircleExclamation}
        showBanner={
          props.changeManagementItem.status ===
            ChangeManagementStatus.Complete &&
          !props.changeManagementItem.canRequestSignoff
        }
        sx={{ mb: 2 }}
      ></NotificationBanner>

      <NotificationBanner
        mainColour={theme.palette.info.main}
        headerText={`The change management item is currently complete and waiting for the signatory to signoff`}
        subText={`Created on ${FormattedDateTime(
          props.changeManagementItem.createdDate
        )}`}
        icon={faCircleExclamation}
        showBanner={
          props.changeManagementItem.status ===
            ChangeManagementStatus.AwaitingSignoff &&
          !props.changeManagementItem.canSignoff
        }
        sx={{ mb: 2 }}
      ></NotificationBanner>

      <NotificationBanner
        mainColour={theme.palette.primary.main}
        headerText={`The engineer has requested sign off for this change management item. Please check and click 'Signoff'`}
        subText={`Created on ${FormattedDateTime(
          props.changeManagementItem.createdDate
        )}`}
        icon={faCircleExclamation}
        showBanner={props.changeManagementItem.canSignoff}
        sx={{ mb: 2 }}
      >
        <Mui.Button variant="contained" onClick={props.onSignoff}>
          Signoff
        </Mui.Button>
      </NotificationBanner>

      <NotificationBanner
        mainColour={theme.palette.success.main}
        headerText={`This change management item has now been signed off.`}
        subText={`Created on ${FormattedDateTime(
          props.changeManagementItem.createdDate
        )}`}
        icon={faCircleExclamation}
        showBanner={
          props.changeManagementItem.status === ChangeManagementStatus.SignedOff
        }
        sx={{ mb: 2 }}
      ></NotificationBanner>
    </>
  );
};
