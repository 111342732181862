import React, { ChangeEvent, useEffect, useState } from "react";
import { faUser, faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { useParams } from "react-router-dom";
import { UserBreakdown } from "./UserBreakdown";
import { UserRoles } from "./UserRoles";
import { NotificationBanner } from "../../components/NotificationBanner";
import { Box, Grid, Switch, Typography, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAsyncEffect } from "../../common/hooks";
import { getUserById } from "../../redux/Slices/account.slice";
import { UpdateUserCommand } from "../../types/account.types";
import {
  enableUser,
  fetchAllRoles,
  fetchAllUsers,
  updateUser,
} from "../../redux/Thunks/account";
import { setLayout } from "../../redux/Slices/layout.slice";
import { NotifyBannerSmall } from "../../components/NotificationBannerSmall";
import { PasswordReset } from "../CompanyAdminDashboard/PasswordReset";

export const ViewUserProfilePage = () => {
  const { id } = useParams<string>();
  const user = useAppSelector(getUserById(id || ""));
  const [showNotificationBanner, setShowNotificationBanner] = useState<boolean>(false)
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setLayout({
        title: "View User Profile",
        subtitle: user
          ? `Viewing User Profile : ${user?.firstName} ${user?.lastName}`
          : "Viewing User Profile",
        icon: faUser,
        links: [{ href: "/tickets", text: "Tickets Dashboard" }],
      })
    );
  }, [dispatch, user]);

  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchAllUsers({}));
    await dispatch(fetchAllRoles());

  }, []);

  const handleUserUpdate = async (updateCommand: UpdateUserCommand) => {
    if (id) {
      await dispatch(updateUser({ userId: id, command: updateCommand }));
      await dispatch(fetchAllUsers({}));
      setShowNotificationBanner(true);
    }
  };

  const handleRolesUpdate = () => {
    setShowNotificationBanner(true);
  };


  const handleEnableDisableUser = async (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    if (user?.id) {
      await dispatch(
        enableUser({ userId: user?.id, enabled: event.target.checked })
      );
      await dispatch(fetchAllUsers({}));
    }
  };

  var theme = useTheme();

  return (
    <>
      <Box display="flex" alignItems="center" justifySelf="flex-start">
        {!loading && user?.canEnableUser === true ? (
          <>
            <Switch
              sx={{ ml: "auto" }}
              size="small"
              defaultChecked={!user?.lockoutEnabled}
              onChange={handleEnableDisableUser}
            />
            <Typography>Enable / Disable</Typography>
          </>
        ) : (
          <></>
        )}
      </Box>
      {showNotificationBanner ? (
        <NotifyBannerSmall
          secondsDuration={10}
          message="User Information has been successfull update."
          showNotification={showNotificationBanner}
          createdId={''}
          navigatePage=''
          linkText=''
          severity='success'
        />
      ) : (
        <></>
      )}
      {!loading ? (
        <NotificationBanner
          mainColour={theme.palette.warning.main}
          headerText={`This user is currently disabled.`}
          subText={`Company admins can enable disabled users.`}
          icon={faCircleExclamation}
          showBanner={user?.lockoutEnabled}
          sx={{ mb: 2 }}
        />
      ) : (
        <></>
      )}

      <Grid container spacing={1}>
        <Grid item xs={12} md={3}>
          {!loading && user ? (
            <>
              <UserBreakdown
                onUserUpdate={handleUserUpdate}
                isReadOnly={!user?.canManageUsers || user?.lockoutEnabled}
                user={user}
              />
              <PasswordReset
                user={user}
              />

            </>
          ) : (
            <>Loading...</>
          )}
        </Grid>

        <Grid item xs={12} md={9}>
          {!loading && user ? (
            <>
              <UserRoles
                onUserRolesUpdate={handleRolesUpdate}
                isReadOnly={!user.canManageUsers || user.lockoutEnabled}
                user={user}
              />

            </>
          ) : (
            <>Loading...</>
          )}
        </Grid>
        <Grid item xs={12} md={6}></Grid>
      </Grid>
    </>
  );
};
