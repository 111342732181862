import React, { useEffect, useState } from "react";
import * as Mui from "@mui/material";
import {
  faGauge,
} from "@fortawesome/pro-light-svg-icons";
import { DevicesRecentEvents } from "../DevicesDashboard/DevicesRecentEvents";
import { Loader } from "../../components/Loader";
import { useAsyncEffect } from "../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getAlertPriority,
  getAlerts,
  getAlertTotals,
  getAlertTotalsClosedByDay,
  getAlertTotalsCreatedByDay,
} from "../../redux/Slices/alerts.slice";
import {
  fetchActiveAlerts,
  fetchAlertsTotals,
  fetchAlertTotalsClosedByDay,
  fetchAlertTotalsCreatedByDay,
  fetchTotalActiveAlerts,
} from "../../redux/Thunks/alerts";
import { getDeviceTotals, getDevices } from "../../redux/Slices/devices.slice";
import { fetchDeviceTotals, fetchDevices, fetchRunAsAccounts } from "../../redux/Thunks/devices";
import OutlinedInput from "@mui/material/OutlinedInput";
import RGL, { WidthProvider } from "react-grid-layout";
import ActiveAlertsOverTime from "./DashboardHighCharts/ActiveAlertsOverTime";
import ClosedAlertsByDay from "./DashboardHighCharts/ClosedAlertsByDay";
import CreatedAlertsByDay from "./DashboardHighCharts/CreatedAlertsByDay";
import { setLayout as setAppLayout } from "../../redux/Slices/layout.slice";
import AlertErrorsVsWarnings from "./DashboardHighCharts/AlertErrorsVsWarnings";
import AlertPriorityBreakdown from "./DashboardHighCharts/AlertPriorityBreakdown";
import DeviceHealthSummary from "./DashboardHighCharts/DeviceHealthSummary";
import { determineDeviceHealth } from "../../common/device.util";
import PercentMonitoredDevices from "./DashboardHighCharts/PercentMonitoredDevices";
import PercentDevicesPauseMode from "./DashboardHighCharts/PercentDevicesPauseMode";
import PercentVirtualDevices from "./DashboardHighCharts/PercentVirtualDevices";
import { fetchPreferences } from "../../redux/Thunks/account";
import { getSidebarToggle } from "../../redux/Slices/account.slice";


const ReactGridLayout = WidthProvider(RGL);

const stored_layout = localStorage.getItem("home_layout");
const saved_column_count = parseInt(
  localStorage.getItem("home_columns") || "-1"
);
const stored_widgets = localStorage.getItem("home_widgets");
const column_count = 6;

export const HomeDashboard = () => {
  const dispatch = useAppDispatch();
  const sidebarToggle = useAppSelector(getSidebarToggle);
  const deviceStats = useAppSelector(getDeviceTotals);
  const alerts = useAppSelector(getAlerts);
  const alertTotals = useAppSelector(getAlertTotals);
  const closedAlerts = useAppSelector(getAlertTotalsClosedByDay);
  const createdAlerts = useAppSelector(getAlertTotalsCreatedByDay);
  const alertPriority = useAppSelector(getAlertPriority);
  const devices = useAppSelector(getDevices);
  const deviceHealth = determineDeviceHealth(devices);
  const [isLayoutChanged, setIsLayoutChanged] = useState(false)


  const chartsArray = [
    { widget: <ActiveAlertsOverTime alerts={alerts} isLayoutChanged={isLayoutChanged} />, name: 'Alerts Over Time', default: true },
    { widget: <ClosedAlertsByDay totals={closedAlerts} isLayoutChanged={isLayoutChanged} />, name: 'Alerts Closed By Day', default: true },
    { widget: <CreatedAlertsByDay totals={createdAlerts} isLayoutChanged={isLayoutChanged} />, name: 'Alert Created By Day', default: true },
    { widget: <AlertErrorsVsWarnings errors={alertTotals?.error} warnings={alertTotals?.warning} isLayoutChanged={isLayoutChanged} />, name: 'Errors vs Warnings', default: true },
    { widget: <AlertPriorityBreakdown highPriority={alertPriority.high} mediumPriority={alertPriority.medium} lowPriority={alertPriority.low} isLayoutChanged={isLayoutChanged} />, name: 'Breakdown of Priority', default: true },
    { widget: <DeviceHealthSummary deviceHealth={deviceHealth} isLayoutChanged={isLayoutChanged} />, name: 'Device Health Summary', default: true },
    { widget: <PercentMonitoredDevices deviceStats={deviceStats} isLayoutChanged={isLayoutChanged} />, name: 'Monitored Devices', default: true },
    { widget: <PercentDevicesPauseMode deviceStats={deviceStats} isLayoutChanged={isLayoutChanged} />, name: 'Alert Pause Mode', default: true },
    { widget: <PercentVirtualDevices deviceStats={deviceStats} isLayoutChanged={isLayoutChanged} />, name: 'Virtual Devices', default: true },
    { widget: <DevicesRecentEvents />, name: 'Recent Device Updates', default: true }
  ]

  const widgets: any[] = chartsArray.map((chart) => {
    return (chart.name)
  })

  const DEFAULT_WIDGETS: any[] = chartsArray.map((chart) => {
    return (chart.default)
  })

  const DEFAULT_LAYOUT: RGL.Layout[] = chartsArray.map((chart, index: number) => {
    return (
      {
        w: 2,
        h: 2,
        x: (index * 2) % 6,
        y: (Math.floor(index / 3)) * 2,
        i: (index + 1).toString(),
        moved: false,
        static: false,
      }
    )
  })
  const [layout, setLayout] = useState(
    stored_layout && saved_column_count === column_count
      ? JSON.parse(stored_layout)
      : DEFAULT_LAYOUT
  );
  const [selectedWidgets, setSelectedWidgets] = useState<boolean[]>(
    stored_widgets ? JSON.parse(stored_widgets) : DEFAULT_WIDGETS
  );
  useEffect(() => {
    dispatch(
      setAppLayout({
        title: "Dashboard",
        subtitle: "Viewing your companies dashboard",
        icon: faGauge,
      })
    );
  }, [dispatch]);

  const { loading } = useAsyncEffect(async () => {
    await Promise.all([
      alerts.length < 1 ? dispatch(fetchActiveAlerts()) : Promise.resolve({}),
      dispatch(fetchTotalActiveAlerts()),
      dispatch(fetchAlertsTotals()),
      dispatch(fetchAlertTotalsCreatedByDay()),
      dispatch(fetchAlertTotalsClosedByDay()),
      dispatch(fetchDeviceTotals()),
      dispatch(fetchDevices()),
      dispatch(fetchRunAsAccounts()),
      dispatch(fetchPreferences()),

    ]);
  }, []);

  const handleNewLayout = (newLayout: RGL.Layout[]) => {
    setIsLayoutChanged(!isLayoutChanged);
    setLayout(newLayout);
    localStorage.setItem("home_layout", JSON.stringify(newLayout));
    localStorage.setItem("home_columns", column_count.toString());
  };

  const handleNewWidgets = (newWidgets: Array<boolean>) => {
    setSelectedWidgets(newWidgets);
    localStorage.setItem("home_widgets", JSON.stringify(newWidgets));
  };

  const isLoading = (): boolean => {
    return loading;
  };

  const isAllUnselected = selectedWidgets.every((val) => !Boolean(val));

  return (
    <>
      {isLoading() ? (
        <Loader show={true} />
      ) : (
        <Mui.Grid container direction="column" >
          <Mui.Grid container direction="row" alignItems={"center"}>
            <Mui.Grid item>
              <Mui.FormControl sx={{ m: 1, width: 250 }}>
                <Mui.InputLabel id="demo-multiple-checkbox-label">
                  Widgets
                </Mui.InputLabel>
                <Mui.Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  input={<OutlinedInput label="Widget" />}
                  multiple
                  value={[]}
                  renderValue={() =>
                    widgets
                      .filter((widget, idx) => selectedWidgets[idx])
                      .join(",")
                  }
                >
                  <Mui.MenuItem
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (isAllUnselected) {
                        handleNewWidgets(DEFAULT_WIDGETS);
                      } else {
                        handleNewWidgets(
                          new Array(DEFAULT_WIDGETS.length).fill(false)
                        );
                      }
                    }}
                  >
                    <Mui.Checkbox checked={!isAllUnselected} />
                    <Mui.ListItemText
                      primary={isAllUnselected ? "Select All" : "Deselect All"}
                    />
                  </Mui.MenuItem>
                  {widgets.map((name, idx) => (
                    <Mui.MenuItem
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const newSelectedWidgets = [...selectedWidgets];
                        newSelectedWidgets[idx] = !newSelectedWidgets[idx];
                        handleNewWidgets(newSelectedWidgets);
                      }}
                      key={name}
                      value={idx}
                    >
                      <Mui.Checkbox checked={selectedWidgets[idx]} />
                      <Mui.ListItemText primary={name} />
                    </Mui.MenuItem>
                  ))}
                </Mui.Select>
              </Mui.FormControl>
            </Mui.Grid>
            <Mui.Grid item>
              <Mui.Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelectedWidgets(DEFAULT_WIDGETS);
                  handleNewLayout(DEFAULT_LAYOUT);
                }}
              >
                Reset Layout
              </Mui.Button>
            </Mui.Grid>
          </Mui.Grid>
          <ReactGridLayout
            layout={layout}
            onLayoutChange={handleNewLayout}
            cols={column_count}
            rowHeight={200}
            isResizable={true}
          >
            {chartsArray.map((chart, index: number) => {
              return (
                selectedWidgets[index] && (
                  <Mui.Grid item key={(index + 1).toString()}>
                    {chart.widget}
                  </Mui.Grid>
                )
              )
            })}
          </ReactGridLayout>
        </Mui.Grid>
      )}
    </>
  );
};
