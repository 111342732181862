import { useAppSelector } from "../redux/hooks";
import { useIdentityContext } from "../context/IdentityContext";
import { getUserRoles } from "../redux/Slices/account.slice";
import { NoAccessPage } from "../common/NoAccessPage";
import { Loader } from "../components/Loader";
interface ProtectedRouteProps {
  role: string[];
  outlet: JSX.Element;
};

export default function ProtectedRoute(props: ProtectedRouteProps) {
  const { role, outlet } = props
  const { authenticatedUser } = useIdentityContext();
  const userRolesArray: any = useAppSelector(getUserRoles(authenticatedUser.userId))
  const permission = userRolesArray?.some((r: string) => role.indexOf(r) >= 0)
  if (userRolesArray) {
    if (permission) {
      return outlet;
    } else {
      return (
        <NoAccessPage />
      )
    }
  } else {
    return (
      <Loader show={true} />
    )
  }
};
