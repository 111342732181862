import { FormControl, Grid, IconButton, Button, ButtonGroup, Modal, InputAdornment, TextField, Typography, Tooltip } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faXmark } from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchSentinelResources, isSubscriptionIdValid, updateCompanySentinelConfig, validateSentinelConfig} from "../../redux/Thunks/company";
import { Loader } from "../Loader";
import { getSelectedCompanyId } from "../../redux/Slices/account.slice";

interface CreateSentinelConfigProps {
    setShowCreateBanner: (value: boolean) => void;
    setShowError: (value: boolean) => void

}

export const CreateSentinelConfig = (props: CreateSentinelConfigProps) => {
    const { setShowCreateBanner, setShowError } = props
    const dispatch = useAppDispatch();
    const [subscriptionIdError, setSubscriptionIdError] = useState(false)
    const [isValidated, setIsValidated] = useState(false)
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);

    const [loading, setLoading] = useState(false)
    const [formValues, setFormValues] = useState({
        resourceGroupName: '',
        workspaceName: '',
        subscriptionId: '',
        workspaceId: '',
        clientSecret: '',
        applicationClientId: '',
        tenantId: '',
    })


    const handleCreateClick = async () => {
        setLoading(true)
        const command: any = Object.fromEntries(Object.entries(formValues).filter(([_, v]) => v !== ''))
        // subscriptionId for testing ===> 1B171ED5-1199-4101-A66D-B056E6E7DC4A
        const validationResponse: any = await dispatch(validateSentinelConfig(command));
        if (validationResponse.payload.data === false) {
            setLoading(false)
            setShowError(true)
        }
        if (validationResponse.payload.data === true) {
            const response: any = await dispatch(updateCompanySentinelConfig(command));
            if (response.payload) {
                setLoading(false)
                setShowCreateBanner(true)
                navigator.clipboard.writeText(response.payload.data.id);
            }
        }
    }


    useEffect(() => {
        if (formValues.subscriptionId.length === 0) {
            setSubscriptionIdError(false)
        }
    }, [formValues]);

    const handleValidateSubscriptionId = async () => {
        setLoading(true)
        const validationResponse: any = await dispatch(isSubscriptionIdValid(formValues.subscriptionId));
        setSubscriptionIdError(!validationResponse.payload)
        if (validationResponse.payload) {
            const sentinelResources: any = await dispatch(fetchSentinelResources(formValues.subscriptionId));
            let clone: any = structuredClone(formValues);
            clone.resourceGroupName = sentinelResources.payload[0].resourceGroupName
            clone.workspaceId = sentinelResources.payload[0].workspaceId
            clone.workspaceName = sentinelResources.payload[0].workspaceName
            setFormValues(clone)
            setIsValidated(true)
            setLoading(false)
        }

    }

    const onDelete = (key: string) => {
        let clone: any = structuredClone(formValues);
        clone[key] = '';
        setFormValues(clone)
    }

    const handleFormValueChange = (value: any, key: string) => {
        let clone: any = structuredClone(formValues);
        setFormValues(clone)
        clone[key] = value
        setFormValues(clone)
    }

    const onDeleteSubscriptionId = (key: string) => {
        setSubscriptionIdError(false)
        let clone: any = structuredClone(formValues);
        clone[key] = '';
        setFormValues(clone)
    }


    return (
        <>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>
            <Grid container spacing={0}>
                <Grid item xs={6} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <TextField
                            error={subscriptionIdError}
                            label='Subscription Id'
                            sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                            id="subscriptionId"
                            size="small"
                            value={formValues['subscriptionId']}
                            helperText={subscriptionIdError ? 'Invalid Subscription Id' : 'Enter Subscription Guid'}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'subscriptionId')
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => onDeleteSubscriptionId('subscriptionId')}
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            color={"#f8931c"}
                                            size="xs"
                                        />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ mt: 0.5, pr: 0 }}>
                    <ButtonGroup
                        onClick={handleValidateSubscriptionId}
                    >
                        <Button>
                            Validate Id
                        </Button>
                    </ButtonGroup>

                </Grid>
                <Grid item xs={4} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <TextField
                            disabled={true}
                            label='Resource Group Name'
                            sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                            id="resourceGroupName"
                            size="small"
                            value={formValues['resourceGroupName']}
                            helperText={''}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'resourceGroupName')
                            }
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <TextField
                            disabled={true}

                            label='Workspace Name'
                            sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                            id="workspaceName"
                            size="small"
                            value={formValues['workspaceName']}
                            helperText={''}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'workspaceName')
                            }

                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <TextField
                            disabled={true}

                            label='Workspace Id'
                            sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                            id="workspaceId"
                            size="small"
                            value={formValues['workspaceId']}
                            helperText={''}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'workspaceId')
                            }

                        />
                    </FormControl>
                </Grid>
                








                <Grid item xs={12} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                <Typography>
                    The following fields are optional.
                </Typography>
                </Grid>
                <Grid item xs={4} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <TextField
                            label='Client Secret'
                            sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                            id="clientSecret"
                            size="small"
                            value={formValues['clientSecret']}
                            helperText={''}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'clientSecret')
                            }
                              InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => onDelete('clientSecret')}
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            color={"#f8931c"}
                                            size="xs"
                                        />
                                    </IconButton>
                                </InputAdornment>,
                            }}

                        />
                    </FormControl>
                </Grid>
                <Grid item xs={4} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <TextField
                            label='Application Client Id'
                            sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                            id="applicationClientId"
                            size="small"
                            value={formValues['applicationClientId']}
                            helperText={''}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'applicationClientId')
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => onDelete('applicationClientId')}
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            color={"#f8931c"}
                                            size="xs"
                                        />
                                    </IconButton>
                                </InputAdornment>,
                            }}

                        />
                    </FormControl>
                </Grid> 
                 <Grid item xs={4} sx={{ mb: 3, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <TextField
                            label='Tenant Id'
                            sx={{ display: 'flex', flexGrow: 1, "& .MuiFormLabel-root": { fontWeight: 'bold' } }}
                            id="tenantId"
                            size="small"
                            value={formValues['tenantId']}
                            helperText={''}
                            onChange={(event) =>
                                handleFormValueChange(event.target.value, 'tenantId')
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => onDelete('tenantId')}
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            color={"#f8931c"}
                                            size="xs"
                                        />
                                    </IconButton>
                                </InputAdornment>,
                            }}

                        />
                    </FormControl>
                </Grid>


                <Grid item xs={12} sx={{ mt: 1, pr: 1, display: "flex", justifyContent: 'flex-end' }}>
                    <Tooltip title={!isValidated ? 'Validate Subscription Id to enable' : ''}>
                    <ButtonGroup
                        onClick={() => isValidated ? handleCreateClick() : null}
                        disabled={!isValidated}
                    >

                        <Button>
                            Create
                        </Button>
                    </ButtonGroup>
                        </Tooltip>
                </Grid>
            </Grid>
        </>
    )
}