import { AlertColor } from "@mui/material";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface SnackbarState {
  open: boolean;
  message: string;
  type: AlertColor | undefined;
}

const initialState: SnackbarState = {
  open: false,
  message: "",
  type: undefined,
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    openSnackbar: (
      state,
      action: PayloadAction<Omit<SnackbarState, "open">>
    ) => {
      state.open = true;
      state.message = action.payload.message;
      state.type = action.payload.type;
    },
    closeSnackbar: (state) => {
      state.open = initialState.open;
      state.message = initialState.message;
      state.type = initialState.type;
    },
  },
});

export const { openSnackbar, closeSnackbar } = snackbarSlice.actions;

export const getSnackbar = (state: RootState) => state.snackbar;

export default snackbarSlice.reducer;
