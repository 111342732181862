import { Device } from "../../../types/devices.types";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Device;
  label: string;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: "healthState",
    numeric: true,
    disablePadding: true,
    label: "Health",
  },
  {
    id: "alertCount",
    numeric: true,
    disablePadding: true,
    label: "Alerts",
  },
  {
    id: "deviceName",
    numeric: true,
    disablePadding: true,
    label: "Device",
  },
  {
    id: "ipAddress",
    numeric: true,
    disablePadding: false,
    label: "IP Address",
  },
  {
    id: "lastModifiedDate",
    numeric: true,
    disablePadding: false,
    label: "Last Modified",
  },
  // {
  //     id: 'inMaintenanceMode',
  //     numeric: true,
  //     disablePadding: false,
  //     label: 'Maintenance',
  // },
  //   {
  //     id: "isMonitored",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Monitored",
  //   },
  // {
  //   id: "isVirtualMachine",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "Is Virtual",
  // },
  {
    id: "syncedFrom",
    numeric: true,
    disablePadding: false,
    label: "Synced From",
  },
  {
    id: "investigate",
    numeric: true,
    disablePadding: false,
    label: "Investigate",
  },
  {
    id: "additionalInformation",
    numeric: true,
    disablePadding: false,
    label: "Additional Information",
  },

];
