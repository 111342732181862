import React, { useEffect, useState } from "react";

import {
  faUserHelmetSafety,
  faCircle,
  faCircleCheck,
} from "@fortawesome/pro-light-svg-icons";
import { Card } from "../../components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Role,
  UpdateUserRolesCommand,
  UserSummary,
} from "../../types/account.types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { updateUserRoles } from "../../redux/Thunks/account";
import { getRoles } from "../../redux/Slices/account.slice";
import { Button, Checkbox, FormGroup, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Typography } from "@mui/material";
import { Loader } from "../../components/Loader";

interface UserRolesProps {
  user: UserSummary;
  isReadOnly: boolean;
  onUserRolesUpdate: VoidFunction;
}

const UserRoleReadOnlyListItem: React.FC<{
  role: Role;
  isChecked: boolean;
  labelId: string;
}> = (props) => {
  return (
    <>
      <ListItemIcon sx={{ padding: 0 }}>
        <FontAwesomeIcon
          icon={props.isChecked ? faCircleCheck : faCircle}
          size="lg"
          style={{ color: props.isChecked ? "limegreen" : "silver" }}
        />
      </ListItemIcon>
      <ListItemText
        id={props.labelId}
        primary={
          <Typography color={"orange"}>{props.role.name}</Typography>
        }
        secondary={
          <Typography variant="subtitle2">
            {props.role.description}
          </Typography>
        }
      />
    </>
  );
};

const UserRoleEditableListItem: React.FC<{
  role: Role;
  isChecked: boolean;
  labelId: string;
  onToggleClick: (roleId: string) => void;
}> = (props) => {
  return (
    <ListItemButton
      onClick={() => props.onToggleClick(props.role.id)}
      dense
      disableGutters
      disableRipple
    >
      <ListItemIcon
        sx={{ padding: 0 }}
      >
        <Checkbox
          edge="start"
          checked={props.isChecked}
          tabIndex={-1}
          //disableRipple
          size="small"
          inputProps={{ "aria-labelledby": props.labelId }}
        />
      </ListItemIcon>
      <ListItemText
        id={props.labelId}
        primary={
          <Typography color={"orange"}>{props.role.name}</Typography>
        }
        secondary={
          <Typography variant="subtitle2">
            {props.role.description}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export const UserRoles = (props: UserRolesProps) => {
  const roles = useAppSelector(getRoles);
  const [nonAdminRoles, setNonAdminRoles] = useState([])
  const [adminRoles, setAdminRoles] = useState([])
  const [CMRoles, setCMRoles] = useState([])
  const [checked, setChecked] = useState<string[]>([]);
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch();
  const usersRoles = props.user.roles.split(",");
  useEffect(() => {
    const sortedRoles: any = [...roles].sort((a: any, b: any) => a.name > b.name ? 1 : -1)
    const cmRoles: any = sortedRoles.filter((x: any) =>
      x.name === 'Approver' ||
      x.name === 'Engineer' ||
      x.name === 'Signatory'
    );
    setCMRoles(cmRoles)
    const nonAdmin: any = sortedRoles.filter((x: any) =>
      x.name === 'Client' ||
      x.name === 'Disable Alerts' ||
      x.name === 'Investigate User' ||
      x.name === 'Manager' ||
      x.name === 'End User' ||
      x.name === 'Firewall Viewer' ||
      x.name === 'Map Viewer' ||
      x.name === 'Devices User' ||
      x.name === 'Dark Web User' ||
      x.name === 'Reports Viewer' ||
      x.name === 'Charts Viewer' ||
      x.name === 'Alerts Viewer'
    );
    setNonAdminRoles(nonAdmin)

    const admin: any = sortedRoles.filter((x: any) =>
      x.name === 'Company Admin' ||
      x.name === 'Company Detail Administrator' ||
      x.name === 'Firewall Admin' ||
      x.name === 'MSP Administrator' ||
      x.name === 'Map Admin' ||
     // x.name === 'RunAs Admin' ||
      x.name === 'Products Admin' ||
      x.name === 'Settings Administrator'
    );
    setAdminRoles(admin)

  }, [roles]);



  useEffect(() => {
    const newChecked = [...checked];
    roles.map((role: any) => {
      usersRoles.map((userRole: string) => {
        if (role.name === userRole) {
          newChecked.push(role.id);
        }
      });
    });
    setChecked(newChecked);
  }, []);
  const handleToggle = (value: string) => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const setRolesFromUser = (roles: Role[]) => {
    const currentUserRoles = new Array<string>();
    var usersRoles = props.user.roles.split(",");
    let result = roles.filter((r) => usersRoles.includes(r.name));
    result.map((role) => currentUserRoles.push(role.id));

    setChecked(currentUserRoles);
  };
  const updateRoles = async () => {
    setLoading(true)
    var command: UpdateUserRolesCommand = { selectedRoles: checked };
    const response = await dispatch(updateUserRoles({ userId: props.user.id, command }));
    if (response.payload) {
      setLoading(false)
    }
    props.onUserRolesUpdate();
  };
  return (
    <Card
      id="viewuserprofile-roles"
      headerIcon={faUserHelmetSafety}
      // -1 b/c we are currently not including pause mode user remove when we include it
      headerTitle={`Showing ${roles.length - 1} roles`}
      headerSubText={`${usersRoles.length - 1} roles selected`}
      showHelp={false}
      cardContent={
        <Grid container spacing={0}>
          <Modal open={loading}>
            <Loader show={true} />
          </Modal>
          <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem>
                Admin Roles
              </ListItem>
              {adminRoles.map((role: any, index: number) => {
                const labelId = `checkbox-list-label-${role.id}`;
                return (
                  <ListItem
                    disableGutters={true}
                    key={role.normalizedName}
                    dense={true}
                    disablePadding
                  >
                    {props.isReadOnly ? (
                      <UserRoleReadOnlyListItem
                        isChecked={checked.indexOf(role.id) !== -1}
                        labelId={labelId}
                        role={role}
                      />
                    ) : (
                      <UserRoleEditableListItem
                        onToggleClick={(roleId) => {
                          handleToggle(roleId);
                        }}
                        isChecked={checked.indexOf(role.id) !== -1}
                        labelId={labelId}
                        role={role}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem>
                Non-Admin Roles
              </ListItem>
              {nonAdminRoles.map((role: any, index: number) => {
                const labelId = `checkbox-list-label-${role.id}`;
                return (
                  <ListItem
                    disableGutters={true}
                    key={role.normalizedName}
                    dense={true}
                    disablePadding
                  >
                    {props.isReadOnly ? (
                      <UserRoleReadOnlyListItem
                        isChecked={checked.indexOf(role.id) !== -1}
                        labelId={labelId}
                        role={role}
                      />
                    ) : (
                      <UserRoleEditableListItem
                        onToggleClick={(roleId) => {
                          handleToggle(roleId);
                        }}
                        isChecked={checked.indexOf(role.id) !== -1}
                        labelId={labelId}
                        role={role}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>

          <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <List
              sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
              <ListItem>
                Change Management Roles
              </ListItem>
              {CMRoles.map((role: any, index: number) => {
                const labelId = `checkbox-list-label-${role.id}`;
                return (
                  <ListItem
                    disableGutters={true}
                    key={role.normalizedName}
                    dense={true}
                    disablePadding
                  >
                    {props.isReadOnly ? (
                      <UserRoleReadOnlyListItem
                        isChecked={checked.indexOf(role.id) !== -1}
                        labelId={labelId}
                        role={role}
                      />
                    ) : (
                      <UserRoleEditableListItem
                        onToggleClick={(roleId) => {
                          handleToggle(roleId);
                        }}
                        isChecked={checked.indexOf(role.id) !== -1}
                        labelId={labelId}
                        role={role}
                      />
                    )}
                  </ListItem>
                );
              })}
            </List>
          </Grid>
        </Grid>

      }
      actions={
        <FormGroup row>
          {props.isReadOnly ? (
            <></>
          ) : (
            <>
              <Button
                variant="outlined"
                color="secondary"
                sx={{ mr: 1 }}
                onClick={() => {
                  setRolesFromUser(roles);
                }}
              >
                Restore
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={updateRoles}
              >
                Save Updates
              </Button>
            </>
          )}
        </FormGroup>
      }
    />
  );
};
