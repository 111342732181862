import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useIdentityContext } from "../context/IdentityContext";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { isUserEndUser, setRedirectRoute } from "../redux/Slices/account.slice";
import { PageAppBar } from "../layouts/PortalWithHeaders/PageAppBar";
import { PortalWithHeadersLayout } from "../layouts/PortalWithHeaders/Index";
import { getLayout } from "../redux/Slices/layout.slice";
import { faLoader } from "@fortawesome/pro-light-svg-icons";

export function AuthenticatedRoute() {
  var identityContext = useIdentityContext();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { authenticatedUser } = useIdentityContext();
  const isEndUser = useAppSelector(isUserEndUser(authenticatedUser.userId));
  const { title, subtitle, icon, links } = useAppSelector(getLayout);

  if (!identityContext.authenticatedUser.isLoggedIn) {
    if (!isEndUser) {
      dispatch(setRedirectRoute(location.pathname + location.search));
      return <Navigate to="/" replace />;
    } if (isEndUser) {
      if (location.pathname === '/tickets' || location.pathname === '/settings') {
        dispatch(setRedirectRoute(location.pathname));
        return <Navigate to="/" replace />;
      }
    }
  }
  return (
    <PortalWithHeadersLayout
      title=""
      paragraph=""
      appBarComponent={
        <PageAppBar
          breadcrumbPageTitle={title}
          mainPageTitle={subtitle}
          mainPageIcon={icon || faLoader}
          links={links || []}
        ></PageAppBar>
      }
    >
      <Outlet />
    </PortalWithHeadersLayout>
  );
}
