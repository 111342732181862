import { faChartSimple } from "@fortawesome/pro-light-svg-icons";
import { Box, Grid } from "@mui/material";
import { Card } from "../../components/Card";

export const ChartsSummary = () => {
  return (
    <Box sx={{ marginRight: "295px" }}>
      <Card
        id="Charts"
        headerIcon={faChartSimple}
        headerTitle={"Charts"}
        headerSubText={"Double Click a Device Counter to Create a Chart"}
        showHelp={false}
        helpHeaderText=""
        helpHeaderDescription=""
        cardHeaderActions={""}
        cardContent={
          <Grid
            id="overview"
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={4}></Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        }
      />
    </Box>
  );
};
