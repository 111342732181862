import { faFileUser } from "@fortawesome/pro-light-svg-icons";
import { Grid } from "@mui/material";
import { Card } from "../../components/Card";


export const RunAsAccountsTableSummary = () => {
    return (
        <Card
            id="Tickets"
            headerIcon={faFileUser}
            headerTitle={"Run-As Accounts"}
            headerSubText={""}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={""}
            cardContent={
                <Grid
                    id="overview"
                    container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="center"
                >
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
            }
        />
    );
};
