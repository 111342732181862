export enum TicketStatus {
  Open = 0,
  InProgress = 1,
  RequiresInformation = 2,
  Closed = 3,
}

export interface RaiseTicketCommand {
  problemCategory: string;
  priority: number;
  title: string;
  description: string;
  subjectGroup: string;
  subjectName: string;
  subjectId: string;
}

export interface RaiseGenericTicketCommand {
  problemCategory: string;
  priority: number;
  title: string;
  description: string;
}

export interface AddCommentCommand {
  comment: string;
  isImportant: boolean;
  isInternal?: boolean;
}

export interface AssignTicketToEngineerCommand {
  engineerId: string;
  engineerName: string;
}

export interface Ticket {
  id: string;
  raisedById: string;
  raisedByName: string;
  assignedEngineerId?: string;
  assignedEngineerName?: string;
  subjectId: string;
  subjectGroup: string;
  subjectName: string;
  subjectType: string;
  description: string;
  createdDate: Date;
  lastModifiedDate: Date;
  changeManagementItemId: string | null;
  dateTimeClosed: Date | null;
  priority: number;
  priorityDescription: string;
  problemCategory: string;
  status: number;
  statusDescription: string;
  ticketNumber: number;
  title: string;
  numberOfComments: number;
  canAssignToMyself: boolean;
  canAssignToEngineer: boolean;
  canClose: boolean;
  canStartChangeMangement: boolean;
  viewTicket: any;
  comments: Array<TicketComment>;
}

export interface TicketComment {
  id: string;
  ticketId: string;
  comment: string;
  createdDate: Date;
  createdByInitials: string;
  createdByFullName: string;
  createdByRole: string;
  createdById: string;
  isImportant: boolean;
  isInternal: boolean;
}

export interface TicketProblemCategoryCount {
  count: number;
  description: string;
  tickets: [
    {
      id: string;
      ticketNumber: number
      title: string;
      companyId: string;
      createdDate: string;
    }
  ]
}
export interface TicketStatusPerDay {
  status: number;
  statusDate: string;
  statusCount: number;
  tickets: [
    {
      id: string;
      ticketNumber: number;
      title: string;
      companyId: string;
      createdDate: string;
    }
  ]
}

export interface TicketRaisedBy  {
  count: number;
  raisedById: string;
  raisedByEmail: string;
  raisedByName: string;
  tickets: [
    {
      id: string;
      ticketNumber: number;
      title: string;
      companyId: string;
      createdDate: string;
    }
  ]
}

export interface TicketAssignedEngineer {
  count: number;
  assignedEngineerId: string;
  assignedEngineerEmail: string;
  assignedEngineerName: string;
  tickets: [
    {
      id: string;
      ticketNumber: number;
      title: string;
      companyId: string;
      createdDate: string;
    }
  ]
}