import * as Mui from "@mui/material";

import { useIdentityContext } from "../../context/IdentityContext";
import React from "react";
import {
  AssignTicketToEngineerCommand,
  Ticket,
} from "../../types/tickets.types";
import {
  assignTicketToEngineer,
  pickUpTicket,
} from "../../redux/Thunks/tickets";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUsers } from "../../redux/Slices/account.slice";
import { useAsyncEffect } from "../../common/hooks";
import { fetchAllUsers } from "../../redux/Thunks/account";

interface AssignEngineerProps {
  ticket: Ticket | any;
  onTicketChanged: VoidFunction;
  showAssignment: boolean;
}

export const AssignEngineer: React.FC<AssignEngineerProps> = (props) => {
  const engineers = useAppSelector(getUsers);
  var identityContext = useIdentityContext();
  const dispatch = useAppDispatch();

  //TODO: if we add closed tickets to this we need to check that state too.

  let user = identityContext.authenticatedUser;

  //TODO: This really needs to be in an orchestration of some kind, look at the bff backend for this

  /*
        see the bff project  (portal.web) for an example of a backend controller 
        var testresponse = await post<string>(`/ticketorchestrator/PickUpTicket`, {});

        for now, orchestration is done here, a little messy but gets it done.
    */

  const handlePickUpTicket = async (ticketId: string) => {
    dispatch(
      pickUpTicket({ ticket: props.ticket, engineerName: user.fullName })
    ).then((response) => {
      props.onTicketChanged();
    });
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleEngineerItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    var selectedEngineer = engineers[index];

    var assignCommand: AssignTicketToEngineerCommand = {
      engineerId: selectedEngineer.id,
      engineerName: `${selectedEngineer.firstName} ${selectedEngineer.lastName}`,
    };
    dispatch(
      assignTicketToEngineer({
        ticket: props.ticket,
        command: assignCommand,
      })
    ).then((response) => {
      props.onTicketChanged();
    });

    setAnchorEl(null);
  };

  const handleAssignEngineerClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: any) => {
    setAnchorEl(null);
  };

  const { loading } = useAsyncEffect(async () => {
    if (engineers.length < 1) await dispatch(fetchAllUsers({}));
  }, []);

  if (props.ticket.assignedEngineerId && props.showAssignment)
    return <>{props.ticket.assignedEngineerName}</>;
  if (props.ticket.assignedEngineerId && !props.showAssignment) return <></>;
  else if (props.ticket.canAssignToMyself)
    return (
      <Mui.Button
        variant="outlined"
        onClick={() => handlePickUpTicket(props.ticket.id)}
      >
        Assign To Me
      </Mui.Button>
    );
  else if (props.ticket.canAssignToEngineer)
    return (
      <div>
        <Mui.Button
          id="select-engineer"
          variant="outlined"
          aria-controls={open ? "select-engineer" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleAssignEngineerClick}
        >
          Assign to engineer
        </Mui.Button>
        <Mui.Menu
          id="select-engineer"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "select-engineer",
          }}
        >
          {engineers && !loading ? (
            <>
              {engineers.map((engineer, index) => {
                return (
                  <Mui.MenuItem
                    onClick={(event) => handleEngineerItemClick(event, index)}
                  >
                    {engineer.firstName} {engineer.lastName}
                  </Mui.MenuItem>
                );
              })}
            </>
          ) : (
            <></>
          )}
        </Mui.Menu>
      </div>
    );
  else return <>-</>;
};
