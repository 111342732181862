import { faBuildings } from "@fortawesome/pro-light-svg-icons";
import { Grid, CardContent, FormControl, InputLabel, Select, MenuItem, CardActions, Button, TextField, Divider, SelectChangeEvent, Modal } from "@mui/material";
import { Card } from "../../../components/Card";
import { useIdentityContext } from "../../../context/IdentityContext";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getSelectedCompanyId, setSelectedCompanyId } from "../../../redux/Slices/account.slice";
import { useMemo, useState } from "react";
import { Loader } from "../../../components/Loader";
import { becomeMsp, changeCompany, joinMsp } from "../../../redux/Thunks/company";
import { getCompanies } from "../../../redux/Slices/company.slice";
import { openSnackbar } from "../../../redux/Slices/snackbar.slice";
import ConfirmationDialog from "../../../components/ConfirmationDialog";



export const CompanyManagement = () => {
    const { authenticatedUser } = useIdentityContext();
    const dispatch = useAppDispatch();
    const selectedCompanyId = useAppSelector(getSelectedCompanyId);
    const companies = useAppSelector(getCompanies);
    const [mspId, setMspId] = useState("");
    const [mspLoading, setMspLoading] = useState(false);
    const [mspConfirm, setMspConfirm] = useState(false);

    const [loadingCompany, setLoadingCompany] = useState(false);


    const handleChangeCompany = async (
        e: SelectChangeEvent<string> | null
    ) => {
        setLoadingCompany(true);
        await dispatch(setSelectedCompanyId(e?.target?.value || null));
        await dispatch(changeCompany());
        setLoadingCompany(false);
    };

    const selectableCompanies = useMemo(() => {
        return companies.filter((company) =>
            authenticatedUser.authorizedCompanies.includes(company.id)
        );
    }, [companies, authenticatedUser]);

    const submitMspJoin = async () => {
        setMspLoading(true);
        const result = await dispatch(joinMsp(mspId));
        if (result.type === "company/joinMsp/rejected") {
            //Handle error here
            dispatch(
                openSnackbar({
                    type: "error",
                    message:
                        "Invalid MSP ID, please double check the ID that you have entered",
                })
            );
        } else window.location.reload();
    };

    const submitBecomeMsp = async () => {
        setMspLoading(true);
        await dispatch(becomeMsp());
        window.location.reload();
    };

    return (
        <>
            <Modal open={loadingCompany}>
                <Loader show={true} />
            </Modal>
            <ConfirmationDialog
                open={mspConfirm}
                onConfirm={submitBecomeMsp}
                onCancel={() => setMspConfirm(false)}
                content={
                    "Are you sure you want to become an MSP? This action cannot be reverted."
                }
            />

                <Card
                    id="settings-company-select"
                    headerIcon={faBuildings}
                    headerTitle={`Organisations Management`}
                    headerSubText={`Select an organisation to view.`}
                    showHelp={false}
                    helpHeaderText=""
                    helpHeaderDescription=""
                    cardHeaderActions={null}
                    sx={{ height: "100%", width: '100%' }}
                    cardContent={
                        authenticatedUser.isMSP ? (
                            <div>
                                <CardContent>
                                    <Grid container direction="column">
                                        <Grid item style={{ maxWidth: "250px" }}>
                                            <FormControl fullWidth>
                                                <InputLabel id="company-select">
                                                    Company
                                                </InputLabel>
                                                <Select
                                                    labelId="company-select-label"
                                                    id="company-select"
                                                    label="Organisation"
                                                    value={selectedCompanyId || ""}
                                                    onChange={handleChangeCompany}
                                                >
                                                    {selectableCompanies.map((company) => (
                                                        <MenuItem value={company.id}>
                                                            {company.companyName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
                                    <Grid item>
                                        <Button
                                            variant="outlined"
                                            color="secondary"
                                            onClick={() => {
                                                handleChangeCompany(null);
                                            }}
                                        >
                                            Clear
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="outlined">Save</Button>
                                    </Grid>
                                </CardActions>
                            </div>
                        ) : (
                            <div>
                                <CardContent>
                                    <Grid
                                        container
                                        direction="column"
                                        justifyContent="center"
                                        alignItems="center"
                                    >
                                        <Grid
                                            container
                                            justifyContent="flex-start"
                                            alignItems="center"
                                            spacing={2}
                                            sx={{ marginBottom: 4 }}
                                        >
                                            <Grid item> Join an MSP: </Grid>
                                            <Grid item>
                                                <TextField
                                                    value={mspId}
                                                    size="small"
                                                    onChange={(e) => {
                                                        setMspId(e.target.value);
                                                    }}
                                                    label="MSP ID"
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Button
                                                    variant="outlined"
                                                    onClick={submitMspJoin}
                                                    disabled={!mspId}
                                                >
                                                    Submit
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid item sx={{ marginBottom: 4, width: "100%" }}>
                                            <Divider>OR</Divider>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                variant="outlined"
                                                onClick={() => setMspConfirm(true)}
                                            >
                                                Become an MSP
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </div>
                        )
                    }
                />

        </>
    )
}