import { Card, CardContent, Grid, Typography } from "@mui/material"
import { determineDeviceTypeRowData, determineDeviceTypesBarChartCategories, determineDeviceTypesBarChartData, determineDeviceTypesPieData } from "./DeviceTabsUtils";
import ReportBarChart from "../Widgets/ReportBarChart";
import ReportPieChart from "../Widgets/ReportPieChart";
import ReportsTable from "../Widgets/ReportsTable";
interface DeviceTypesProps {
    deviceTypes: any;
}

export const DeviceTypes = (props: DeviceTypesProps) => {
    const { deviceTypes } = props
    const barChartCategories = determineDeviceTypesBarChartCategories(deviceTypes)
    const barChartData = determineDeviceTypesBarChartData(deviceTypes)
    const pieData = determineDeviceTypesPieData(deviceTypes)
    const rowData = determineDeviceTypeRowData(deviceTypes)
    const tableHeaders = ['Device Type', '# Devices', '% of Total']

    return (

        <Card id='exportDeviceType' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ maxHeight: '65vh', padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Device Types
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                    </Grid>


                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'devices'}
                            fileName={'Device_Types'}
                        />

                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportPieChart
                            pieData={pieData}
                        />
                    </Grid>

                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Device Types Data'}
                        />
                    </Grid>

                </Grid>

            </CardContent>

        </Card >
    )
}