import { Box, Button, ButtonGroup, Modal } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { setFlareAlertToSearch } from "../../redux/Slices/alerts.slice";
import { useAppDispatch } from "../../redux/hooks";
import { fetchFlareAlert } from "../../redux/Thunks/alerts";
import { useState } from "react";
import { Loader } from "../../components/Loader";

interface InvestigationSummaryButtonGroupProps {
    currentAlert: any;
}

export const InvestigationSummaryButtonGroup = (props: InvestigationSummaryButtonGroupProps) => {
    const { currentAlert } = props
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleButtonClick = async () => {
        setLoading(true)
        dispatch(setFlareAlertToSearch(currentAlert.description))
        const command = `${currentAlert.id}`
        const response = await dispatch(fetchFlareAlert(command));
        if (response.payload) {
            setLoading(false)
        }
        navigate('/darkweb')
    }

    return (
        <Box>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>
            <ButtonGroup>

            </ButtonGroup>
            <ButtonGroup>

                <Button
                    onClick={handleButtonClick}

                >
                    Threat Exposure
                </Button>
            </ButtonGroup>

        </Box>
    )
}