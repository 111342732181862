import { Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Select, Typography, } from "@mui/material"
import { useState } from "react"
import dayjs from "dayjs";
import { useAsyncEffect } from "../../../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { getSelectedCompanyId } from "../../../../redux/Slices/account.slice";
import { fetchTicketsAssignedEngineer } from "../../../../redux/Thunks/tickets";
import { determineBarChartCategoriesForAssignedEngineers, determineBarChartDataForAssignedEngineer, determinePieDataForAssignedEngineer, determineRowDataForAssignedEngineer, onHandleTimeIntervalChange } from "./TicketTabUtils";
import ReportBarChart from "../Widgets/ReportBarChart";
import ReportPieChart from "../Widgets/ReportPieChart";
import ReportsTable from "../Widgets/ReportsTable";

interface TicketsAssignedEngineerProps {
    ticketAssignedEngineer: any;
}



export const TicketsAssignedEngineer = (props: TicketsAssignedEngineerProps) => {
    const { ticketAssignedEngineer } = props
    const dispatch = useAppDispatch();
    const [timeInterval, setTimeInterval] = useState('Month')
    const [timeDifference, setTimeDifference] = useState<any>(dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"))
    const dropDownValues = ['Day', 'Week', 'Month', '2 Months', '3 Months', '4 Months', '5 Months', '6 Months', '7 Months', '8 Months', '9 Months', '10 Months', '11 Months', 'Year', 'All Time']
    const now = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const selectedCompanyId: string | any = useAppSelector(getSelectedCompanyId);
    const [barChartCategories, setBarChartCategories] = useState([])
    const [barChartData, setBarChartData] = useState([])
    const [pieData, setPieData] = useState([])
    const tableHeaders = ['Engineer', '# Tickets', '% of Total']
    const [rowData, setRowData] = useState([])


    useAsyncEffect(async () => {
        const response = await dispatch(fetchTicketsAssignedEngineer(
            {
                companyId: selectedCompanyId,
                startDate: timeDifference,
                endDate: now
            }
        ));
        setBarChartCategories(determineBarChartCategoriesForAssignedEngineers(response.payload))
        setBarChartData(determineBarChartDataForAssignedEngineer(response.payload))
        setPieData(determinePieDataForAssignedEngineer(response.payload))
        setRowData(determineRowDataForAssignedEngineer(response.payload))


    }, [timeDifference]);

    return (
        <Card id='exportTicketAssignedEngineer' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Ticket Assigned Engineer
                        </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-start' }}>
                        <FormControl sx={{ fontWeight: 'bold', width: '25%', pl: 1 }} variant="outlined" size='small'>
                            <InputLabel id="timeSelect">Time Interval</InputLabel>
                            <Select
                                labelId="timeSelect"
                                id="timeSelect"
                                value={timeInterval}
                                label="Time Interval"
                                size='small'
                                onChange={(event) => {
                                    setTimeInterval(event.target.value as string)
                                    setTimeDifference(onHandleTimeIntervalChange(event))
                                }}

                            >
                                {dropDownValues.map((value: string) => {
                                    return (
                                        <MenuItem value={value}>{value}</MenuItem>

                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                  
                    </Grid>


                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'tickets'}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportPieChart
                            pieData={pieData}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Ticket Assigned Engineer Data'}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card >
    )
}