import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindows,
  faRedhat,
  faMicrosoft,
  faUbuntu,
} from "@fortawesome/free-brands-svg-icons";
import {
  faSquareQuestion,
  faDatabase,
  faServer,
  faLaptop
} from "@fortawesome/pro-light-svg-icons";

const DeviceTypeIcon: React.FC<{ deviceType: number }> = (props) => {
  let icon = faSquareQuestion;
  switch (props.deviceType) {
    case 0:
      icon = faLaptop;
      break;
    case 1:
      icon = faWindows;
      break;
    case 2:
      icon = faWindows;
      break;
    case 3:
      icon = faDatabase;
      break;
    case 4:
      icon = faServer;
      break;
    case 5:
      icon = faUbuntu;
      break;
    case 6:
      icon = faRedhat;
      break;
    case 7:
      icon = faMicrosoft;
      break;
    default:
      icon = faSquareQuestion;
      break;
  }
  return <FontAwesomeIcon icon={icon} color="orange" size="4x" title="" />;
};

export { DeviceTypeIcon };
