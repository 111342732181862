import { createAsyncThunk } from "@reduxjs/toolkit";
import protectAxios from "../../api/axios";
import { Coordinate, Map, setMaps } from "../Slices/maps.slice";

export const fetchMaps = createAsyncThunk(
  "maps/fetchMaps",
  async (companyId: String, { dispatch }) => {
    const data = await protectAxios
      .get("/companyqueries/Maps/GetCompanyMaps", { params: { companyId } })
      .then((resp) => resp.data.companyMaps);
    dispatch(setMaps(data));
  }
);

export const deleteMap = createAsyncThunk(
  "maps/deleteMap",
  async ({ companyId, MapId }: { companyId: string; MapId: string }) => {
    await protectAxios.delete("/companycommands/Maps/DeleteCompanyMap", {
      params: { companyId, MapId },
    });
  }
);

export const createOrEditMap = createAsyncThunk(
  "maps/createOrUpdateMap",
  async ({
    newMap,
    companyId,
  }: {
    newMap: Partial<Map>;
    companyId: string;
  }) => {
    await protectAxios.post(
      "/companycommands/Maps/CreateOrEditCompanyMap",
      newMap,
      { params: { companyId } }
    );
  }
);

export const deleteCoordinate = createAsyncThunk(
  "maps/deleteCoordinate",
  async ({
    companyId,
    CoordinateId,
  }: {
    companyId: string;
    CoordinateId: string;
  }) => {
    await protectAxios.delete("/companycommands/Maps/DeleteMapCoordinate", {
      params: { companyId, CoordinateId },
    });
  }
);

export const createOrEditMapCoordinate = createAsyncThunk(
  "maps/createOrEditMapCoordinate",
  async ({
    newCoordinate,
    companyId,
  }: {
    newCoordinate: Partial<Coordinate>;
    companyId: string;
  }) => {
    await protectAxios.post(
      "/companycommands/Maps/createOrEditMapCoordinate",
      newCoordinate,
      { params: { companyId } }
    );
  }
);
