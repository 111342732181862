import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendar,
  faTicketSimple,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { useTheme } from "@mui/material/styles";
import { FormattedDate, FormattedDateTime } from "../../common/dateUtils";
import { CardHeader } from "../../components/CardHeader";
import { useNavigate } from "react-router-dom";
import { AssignEngineer } from "../../components/Tickets/AssignEngineer";
import { useIdentityContext } from "../../context/IdentityContext";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import {
  AssignTicketToEngineerCommand,
  Ticket,
} from "../../types/tickets.types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  assignTicketToEngineer,
  closeTicket,
} from "../../redux/Thunks/tickets";
import { isAlertTicket } from "../../common/ticket.util";
import { useAsyncEffect } from "../../common/hooks";
import { fetchAllUsers } from "../../redux/Thunks/account";
import { getUserById, getUsers } from "../../redux/Slices/account.slice";
import { Loader } from "../../components/Loader";

const TicketSummaryListItem: React.FC<{
  icon: IconDefinition;
  description: string | React.ReactNode;
  color: string;
}> = (props) => {
  return (
    <ListItem sx={{ pt: 0, pb: 0 }}>
      <ListItemAvatar
        sx={{ minWidth: "36px", padding: "3px", mr: 1, borderRadius: "5px" }}
      >
        <FontAwesomeIcon icon={props.icon} color={props.color} size="lg" />
      </ListItemAvatar>
      <ListItemText sx={{ p: 0 }} primary={<>{props.description}</>} />
    </ListItem>
  );
};

interface TicketSummaryProps {
  ticket: Ticket;
  onTicketAssigned: VoidFunction;
  onTicketClosed: VoidFunction;
  //onTicketChanged : VoidFunction;
}

export const TicketSummary: React.FC<TicketSummaryProps> = (props) => {
  const identity = useIdentityContext();
  const dispatch = useAppDispatch();
  const [loadingModal, setLoadingModal] = useState(false)
  const users = useAppSelector(getUsers);
  const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
  const engineers = users.filter((user) => user.roles.includes("Engineer"));

  const { loading } = useAsyncEffect(async () => {
    if (!user || users.length < 1) await dispatch(fetchAllUsers({}));
  }, []);

  const isEngineer = () => {
    return users
      .filter((user) => user.roles.includes("Engineer"))
      .map((user) => user.firstName + " " + user.lastName);
  };

  const handleEngineerNameSearch = async (query: string) => {
    let name = query.split(" ");
    let engineerID = "";
    for (let i = 0; i < engineers.length; i++) {
      const engineer = engineers[i];
      if (engineer.firstName === name[0] && engineer.lastName === name[1]) {
        engineerID = engineer.id;
      }
    }
    var assignCommand: AssignTicketToEngineerCommand = {
      engineerId: engineerID,
      engineerName: `${name[0]} ${name[1]}`,
    };
    await dispatch(
      assignTicketToEngineer({ ticket: props.ticket, command: assignCommand })
    );
    props.onTicketAssigned();
  };

  const theme = useTheme();
  const navigate = useNavigate();

  const statusColour = (status: number) => {
    switch (status) {
      case 0:
        return theme.palette.success.main;
      case 1:
        return theme.palette.info.main;
      case 2:
        return theme.palette.warning.main;
      default:
        return "silver";
    }
  };

  const priorityColour = (status: number) => {
    switch (status) {
      case 0:
        return "green";
      case 1:
        return "orange";
      case 2:
        return "red";
      default:
        return "silver";
    }
  };

  const handleOnTicketAssigned = () => props.onTicketAssigned();
  const handleOnTicketClosed = async () => {
    setLoadingModal(true)
    const response = await dispatch(closeTicket(props.ticket));
    if (response.payload) {
      setLoadingModal(false)
    }
    props.onTicketClosed();
  };
  const isManager = (user: any) => {
    if (user && user.roles) {
      return user.roles.includes("Manager");
    }
  };

  console.debug(props.ticket);
  return loading ? (
    <Loader show={true} />
  ) : (
    <>
      <Modal open={loadingModal}>
        <Loader show={true} />
      </Modal>
      <Card style={{ width: "100%" }}>
        <CardHeader
          icon={faTicketSimple}
          subText={`Created: ${FormattedDate(props.ticket.createdDate)}`}
          title="Ticket Summary"
        />
        <CardContent>
          <Typography sx={{ pt: 1, pl: 2 }}>
            Title: <Box component="span">{props.ticket.title}</Box>
          </Typography>
          <Typography sx={{ pl: 2 }}>
            Ticket Number: <Box component="span">{props.ticket.ticketNumber}</Box>
          </Typography>
          <Typography sx={{ pl: 2 }}>
            Category: <Box component="span">{props.ticket.problemCategory}</Box>
          </Typography>
          <Typography sx={{ pl: 2 }}>
            Priority :{" "}
            <FontAwesomeIcon
              icon={faCircle}
              color={priorityColour(props.ticket.priority)}
              size="sm"
            />
            &nbsp;
            <span style={{ fontWeight: "normal" }}>
              {props.ticket.priorityDescription}
            </span>
          </Typography>
          <Typography sx={{ pl: 2 }}>
            Description:{" "}
            <Box component="span" fontWeight="normal">
              {props.ticket.description}
            </Box>
          </Typography>

          {isAlertTicket(props.ticket.subjectType) ? (
            <Typography sx={{ pl: 2, pb: 2 }}>
              Alert: &nbsp;
              <Box
                component="span"
                fontWeight="bold"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  navigate(`/alert/${props.ticket.subjectId}`);
                }}
              >
                {props.ticket.subjectName}
              </Box>
            </Typography>
          ) : (
            <Typography sx={{ pl: 2, pb: 2 }}>
              Device: &nbsp;
              <Box
                component="span"
                fontWeight="bold"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  navigate(
                    `/device/${props.ticket.subjectId}/${props.ticket.subjectGroup}`
                  );
                }}
              >
                {props.ticket.subjectName}
              </Box>
            </Typography>
          )}

          <Divider sx={{ pt: 1, pb: 1 }}></Divider>
          <List dense={true} sx={{ width: "100%", bgcolor: "background.paper" }}>
            <TicketSummaryListItem
              icon={faUser}
              description={`Ticket Assigned to ${props.ticket.assignedEngineerName
                  ? props.ticket.assignedEngineerName
                  : "-"
                }`}
              color={props.ticket.assignedEngineerId ? "orange" : "#ccc"}
            />
            <TicketSummaryListItem
              icon={faCalendar}
              description={`Last modified on ${FormattedDateTime(
                props.ticket.lastModifiedDate
              )}`}
              color={"#ccc"}
            />
            <TicketSummaryListItem
              icon={faCalendar}
              description={`Created on ${FormattedDate(
                props.ticket.createdDate
              )}`}
              color={"#ccc"}
            />
            <TicketSummaryListItem
              icon={faCircle}
              description={`Status ${props.ticket.statusDescription}`}
              color={statusColour(props.ticket.status)}
            />
            {/* <TicketSummaryListItem icon={faPencil} description={props.ticket.changeManagementItemId === null ? 'No change management item' : `Issue Created for ticket (TODO addlink)`} color={props.ticket.changeManagementItemId === null ? '#ccc' : 'orange'} /> */}
          </List>
          {/* <Divider/> */}
          {/* <Typography variant="body2" color="text.secondary" sx={{pt:1}}>
                    This can be a summary of failures, tickets etc.
                </Typography> */}
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            Note: before closing or requesting more information on a ticket,
            please add the relevant comments
          </Typography>
        </CardContent>

        <CardActions sx={{ justifyContent: "space-between" }}>
          {isManager(user) && !props.ticket.changeManagementItemId ? (
            <Autocomplete
              disablePortal
              id="engineer-name-autocomplete"
              options={isEngineer()}
              size="small"
              sx={{
                flexGrow: 1,
                float: "left",
                margin: 0,
                marginRight: 1,
                maxWidth: 200,
              }}
              value={props.ticket.assignedEngineerName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Assign Engineer"
                  sx={{ marginTop: 0, marginLeft: 0 }}
                  onChange={(event) =>
                    handleEngineerNameSearch(event.target.value)
                  }
                  value={props.ticket.assignedEngineerName}
                />
              )}
              onChange={(_event, value: any) => handleEngineerNameSearch(value)}
              PaperComponent={({ children }) => (
                <Paper style={{ background: "#efefef" }}>{children}</Paper>
              )}
            />
          ) : (
            <></>
          )}

          {!props.ticket.changeManagementItemId && (
            <AssignEngineer
              ticket={props.ticket}
              onTicketChanged={handleOnTicketAssigned}
              showAssignment={false}
            />
          )}

          <div style={{ display: "flex" }}>
            {props.ticket.canClose ? (
              <Button
                variant="outlined"
                style={{ margin: "0px 2px 0px 2px" }}
                onClick={handleOnTicketClosed}
              >
                Close the ticket
              </Button>
            ) : (
              <></>
            )}

            {props.ticket.changeManagementItemId !== null ? (
              <Button
                variant="outlined"
                style={{ margin: "0px 2px 0px 2px" }}
                onClick={() => {
                  navigate(
                    `/changemanagement/${props.ticket.changeManagementItemId}`
                  );
                }}
              >
                View Change Management
              </Button>
            ) : (
              <>
                {props.ticket.canStartChangeMangement ? (
                  <Button
                    variant="outlined"
                    style={{ margin: "0px 2px 0px 2px" }}
                    onClick={() => {
                      navigate(`/changemanagement/create/${props.ticket.id}`, {
                        state: props.ticket,
                      });
                    }}
                  >
                    Start Change Management
                  </Button>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </CardActions>
      </Card>
    </>

  );
};
