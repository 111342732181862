
import { Button, Card } from "@mui/material";
import * as React from "react";
import { useAppDispatch } from "../redux/hooks";
import { setGatewayServer } from "../redux/Slices/company.slice";

const cardStyle = {
    position: "absolute",
    margin: "50px",
    padding: "50px",
};

export const GatewayServerInfo = () => {
    const dispatch = useAppDispatch();

    const handleAgreeClick = () => {

        dispatch(setGatewayServer(false));

    }

    return (
        <Card sx={cardStyle}>
<div>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '.5pt', textIndent: '0in', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333', margin: '0in'}}><strong>Agent Install Switches for advanced installation methods.&nbsp;</strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '.5pt', textIndent: '0in', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333', margin: '0in'}}><strong>&nbsp;</strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '.5pt', textIndent: '0in', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333', margin: '0in'}}><strong>&nbsp;</strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}><strong>setupAgents.exe&nbsp;</strong><strong><span style={{color: 'red'}}>-sp"</span>[OPTIONS]</strong><strong><span style={{color: 'red'}}>"</span></strong>&nbsp; setupAgents.exe <span style={{color: 'red'}}>-sp"</span>-s -u <span style={{color: 'blue'}}>""</span>username<span style={{color: 'blue'}}>""</span> -p <span style={{color: 'blue'}}>""</span>password<span style={{color: 'blue'}}>""</span> -c <span style={{color: 'blue'}}>""</span>companyname<span style={{color: 'blue'}}>""</span><span style={{color: 'red'}}>"</span> <strong>Options:</strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;If you want to use options you need to start with <span style={{color: 'red'}}>-sp</span> than add <span style={{color: 'red'}}>a quote"</span> and finish with <span style={{color: 'red'}}>a quote"</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;If you have spaces in one of the parameters you need to use <span style={{color: 'red'}}>double quotes</span>, like shown above in blue</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-s : Silent installation, no GUI</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-ai : option for advanced installer setup</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-showgui : will force to show the graphical user interface</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-debug : override to debug mode, with extensive logging</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-u : SecQube - Username for authentication</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-p : SecQube - Password for authentication</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-c : Company Name for adding a computer to during setup</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-m : Mgmt Server override if known (used for advanced installation and for additional security)</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '7.0pt', marginLeft: '-.25pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;-o : options overwrite [Scom|Client|Server|Certificate|Uninstall] if known<span style={{fontSize: '19px', lineHeight: '94%', color: 'black'}}>&nbsp;</span><strong>Examples:</strong><span style={{fontSize: '19px', lineHeight: '94%', color: 'black'}}>&nbsp;</span></p>
  <p style={{marginTop: '0in', marginRight: '41.1pt', marginBottom: '0in', marginLeft: '36.5pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>setupAgents.exe <span style={{color: 'red'}}>-sp"</span>-s -u <span style={{color: 'blue'}}>""</span>MyUsername <span style={{color: 'blue'}}>""</span> -p <span style={{color: 'blue'}}>""</span>MyPassword <span style={{color: 'blue'}}>""</span> c <span style={{color: 'blue'}}>""</span>MyCompany <span style={{color: 'blue'}}>""</span> <span style={{color: 'red'}}>"</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '36.5pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;: Silent Installtion without GUI will add computer to the right company &nbsp;setupAgents.exe <span style={{color: 'red'}}>-sp"</span>-s <span style={{color: 'red'}}>"</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '0in', marginLeft: '36.5pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;: Silent Installtion without GUI, user, pwd or company, needs excel import</p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '36.5pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;setupAgents.exe <span style={{color: 'red'}}>-sp"</span>-s -u <span style={{color: 'blue'}}>""</span>MyUsername <span style={{color: 'blue'}}>""</span> <span style={{color: 'red'}}>"</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '5.25pt', marginLeft: '36.5pt', textIndent: '-.5pt', lineHeight: '94%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333'}}>&nbsp;: Silent, can be imported through Unassigned Computers tab in the portal &nbsp;setupAgents.exe <span style={{color: 'red'}}>-sp"</span>-s -o Client -c MyCompanyWithOutSpaces <span style={{color: 'red'}}>"</span>&nbsp; : Silent, will install to client mgmt group and add computer to Company<span style={{fontSize: '19px', lineHeight: '94%', color: 'black'}}>&nbsp;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '1.8pt', marginLeft: '.5pt', textIndent: '0in', lineHeight: '107%', fontSize: '17px', fontFamily: '"Calibri",sans-serif', color: '#333333', margin: '0in'}}><span style={{fontSize: '15px', lineHeight: '107%', color: 'black'}}>&nbsp;</span></p>
</div>



            <Button
                variant="outlined"
                color="primary"
                type="submit"
                sx={{ mt: 2 }}
                onClick={handleAgreeClick}
            >
                Close Page
            </Button>

        </Card>
    );
};

