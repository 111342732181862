import { useAppDispatch } from "../../redux/hooks";
import { Formik } from "formik";
import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import { updateCompanyEmailConfig } from "../../redux/Thunks/company";
import {
  FormikSelectField,
  FormikTextField,
} from "../../components/FormikFields";
import { CompanyEmailConfig } from "../../types/company.types";

export interface EmailConfigurationFormProps {
  emailConfig: CompanyEmailConfig;
}

const EmailConfigurationForm = (props: EmailConfigurationFormProps) => {
  const { emailConfig } = props;
  const dispatch = useAppDispatch();

  if (!emailConfig) {
    return null;
  }
  return (
    <Formik
      initialValues={emailConfig}
      onSubmit={(values) => {
        dispatch(updateCompanyEmailConfig(values));
      }}
    >
      {({ setFieldValue, values, handleSubmit }) => (
        <>
          <Grid
            container
            alignItems="center"
            sx={{ marginBottom: 2 }}
            spacing={1}
          >
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(values.incidentEmailTo)}
                    onChange={() => {
                      setFieldValue("incidentEmailTo", "");
                    }}
                  />
                }
                label="Enable"
              />
            </Grid>
            <Grid item sx={{ flexGrow: "1", maxWidth: "500px" }}>
              <FormikTextField
                name="incidentEmailTo"
                label="Analysis Email"
                fullWidth
              />
            </Grid>
            <Grid item>
              <FormikSelectField
                name="incidentSeverity"
                options={[
                  { label: "Informational", value: 4 },
                  { label: "Low", value: 5 },
                  { label: "Medium", value: 6 },
                  { label: "High", value: 7 },
                ]}
                label={"Severity"}
                sx={{ width: 150 }}
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                size="large"
                type="submit"
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Formik>
  );
};
export default EmailConfigurationForm;
