import {
    Paper,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableContainer,
    Table,
    Typography,
    
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import {
    InvestigationTable,
  } from "../../types/alerts.types";
  import { getComparator, Order, stableSort } from "../../common/TableUtilities";
  import CardContent from "@mui/material/CardContent";
  import Card from "@mui/material/Card";
  import { useAppDispatch } from "../../redux/hooks";
//   import { EnhancedTableHead } from "./InvestigateAlertEnhancedTableHead";
//   import { AdditionalInformationCell } from "./AdditionalInformationCell";
  import dayjs from "dayjs";
import { EnhancedTableHead } from "./InvestigateDeviceEnhancedTableHead";
import { DeviceNameCell } from "./DeviceNameCell";
import { AdditionalInformationCell } from "./AdditionalInformationCell";
//   import { DeviceNameCell } from "./DeviceNameCell";
  interface InvestigateDeviceEnhancedTableProps {
    investigatedDevice: any;
    currentDevice: any;
  }
  
  export function InvestigateDeviceEnhancedTable(
    props: InvestigateDeviceEnhancedTableProps
  ) {
    return <EnhancedTable investigatedDevice={props.investigatedDevice} currentDevice={props.currentDevice}></EnhancedTable>;
  }
  
  export default function EnhancedTable(props: {
    investigatedDevice: any;
    currentDevice: any;
  }) {
    const { investigatedDevice, currentDevice } = props;
    const dispatch = useAppDispatch();
    const [rows, setRows] = useState<InvestigationTable[]>([]);
    // const [rows, setRows] = useState<[]>([]);
    useEffect(() => {
      if (investigatedDevice.investigationResult) {
        setRows(investigatedDevice.investigationResult)
      }
    }, [investigatedDevice]);
  
  
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<keyof InvestigationTable>("timeGenerated");
    const [page, setPage] = useState(0);
  
    const rowsPerPage = 25;
    const total = rows ? rows.length : 0
  
    const handleRequestSort = (
      _event: React.MouseEvent<unknown>,
      property: keyof InvestigationTable
    ) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    };
  
    const handleChangePage = (_event: unknown, newPage: number) => {
      setPage(newPage);
    };
  
  
    return (
      <>
        {/* TABLE CARD */}
        <Card>
          <CardContent>
            <TableContainer
              component={Paper}
              sx={{
                borderStyle: "solid",
                borderColor: "#ccc",
                borderWidth: "1px",
              }}
            >
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                size="small"
                aria-label="devices"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={!rows ? 0 : rows.length}
                />
                {rows.length > 0 ?
                  <TableBody>
                    {stableSort(rows as any, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, _index) => {
  
                        return (
                          <TableRow tabIndex={-1} key={_index}>
                            <TableCell align="left">
                            {dayjs(row.timeGenerated).format('YYYY-MM-DD HH:mm:ssA')}
                              </TableCell>
                            <TableCell align="left">{row.type} </TableCell>
                            <TableCell align="left">
                              <DeviceNameCell
                                deviceName={row.deviceName}
                                currentDevice={currentDevice}
                              />
                              
                            </TableCell>
                            <TableCell align="left">{row.actionType}</TableCell>
                            <TableCell align="left">
  
                              <AdditionalInformationCell 
                              additionalInformation={row.additionalInformations} 
                              currentDevice={currentDevice}
                              />
  
  
  
                            </TableCell>
  
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  :
                  <Typography sx={{ pl: 1, pt: 1 }}>
                    No investigation results found.
                  </Typography>
                }
  
              </Table>
              <TablePagination
                rowsPerPageOptions={[rowsPerPage]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </TableContainer>
          </CardContent>
        </Card>
      </>
    );
  }
  