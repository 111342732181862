import { faMessageBot } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Box, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";



export const NoAccessPage = () => {
    const navigate = useNavigate();

    return (
        <Box onClick={() => {
            navigate('/tickets');
        }}>
            <Grid container spacing={0}>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', mt: 30 }}>
                    <FontAwesomeIcon
                        size="10x"
                        icon={faMessageBot}
                        color={'rgb(57,90,122)'}
                    />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', mt: 3 }}>
                    <Typography variant="h3" sx={{ color: "rgb(236,159,95)", textAlign: 'center' }}>
                        You do not have access to this page.
                    </Typography>
                </Grid>
            </Grid>
        </Box>

    )
}