import { DisabledAlert } from "../../types/alerts.types";

interface HeadCell {
  disablePadding: boolean;
  id: keyof DisabledAlert;
  label: string;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: "timeAdded",
    numeric: true,
    disablePadding: true,
    label: "Time Added",
  },
  {
    id: "overrideRuleName",
    numeric: true,
    disablePadding: true,
    label: "Alert Rule",
  },
//   {
//     id: "overrideId",
//     numeric: true,
//     disablePadding: true,
//     label: "Override Id",
//   },
{
    id: "deviceGroup",
    numeric: true,
    disablePadding: true,
    label: "Device Group",
  },
  {
    id: "comment",
    numeric: true,
    disablePadding: true,
    label: "Comment",
  },
  {
    id: "overrideId",
    numeric: true,
    disablePadding: false,
    label: "",
  },
 

];
