import React, { useState, useEffect, useMemo } from "react";

import { faTicket } from "@fortawesome/pro-light-svg-icons";
import SuccessDisplay from "./SuccessDisplay";
import CheckoutDisplay from "./CheckoutDisplay";
import { useLocation } from "react-router-dom";
import { setLayout } from "../../redux/Slices/layout.slice";
import { useAppDispatch } from "../../redux/hooks";

const StripeDashboard = () => {
  const { search } = useLocation();
  const dispatch = useAppDispatch();
  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState<string | null>("");

  const query = useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    dispatch(
      setLayout({
        title: "Stripe Dashboard",
        subtitle: "Viewing payment details",
        icon: faTicket,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const urlSessionId = query.get("session_id");
    if (query.get("success")) {
      setSuccess(true);
    }

    if (urlSessionId) {
      setSessionId(urlSessionId);
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, [query]);

  let innerElement = <></>;

  if (!success && message === "") {
    innerElement = <CheckoutDisplay />;
  } else if (success && sessionId !== "" && sessionId !== null) {
    innerElement = <SuccessDisplay sessionId={sessionId} />;
  } else {
    innerElement = (
      <section>
        <p>{message}</p>
      </section>
    );
  }
  return innerElement;
};

export default StripeDashboard;
