import axios from "axios";
import { store } from "../redux/store";
const protectAxios = axios.create({
  //baseURL: "http://localhost:5000",
  //timeout: 80000,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accept: "application/json, text/plain",
    "Content-Type": "application/json;charset=UTF-8",
    "X-CSRF": "1",
  },
});

protectAxios.interceptors.request.use((request) => {
  if (!request.params) {
    request.params = {};
  }
  if (!request.params.companyId)
    request.params.companyId = store.getState()?.account?.selectedCompanyId;
  return request;
});

protectAxios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (response.data.items) {
      const { items, ...extraData } = response.data;
      response.data = items;
      //@ts-expect-error
      response.extraData = extraData;
    }
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      window.location.href = "/bff/login";
    }
    throw error;
  }
);

export default protectAxios;
