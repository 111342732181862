import React, { useEffect } from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { Alert, Button, IconButton } from "@mui/material";
import { getChartErrorMessage } from "../../redux/Slices/devices.slice";
import { useAppSelector } from "../../redux/hooks";


interface State extends SnackbarOrigin {
    open: boolean;
}
export default function ChartErrorBanner() {
    const chartErrorMessage = useAppSelector(getChartErrorMessage);
  
    const [state, setState] = React.useState<State>({
        open: chartErrorMessage,
        vertical: "top",
        horizontal: "right",
    });
    const { vertical, horizontal, open } = state;
    useEffect(() => {
        setState({
            open: chartErrorMessage,
            vertical: "top",
            horizontal: "right",
        });
    }, [chartErrorMessage]);
    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
            </IconButton>
        </React.Fragment>
    );

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                message="Note archived"
                action={action}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{ width: "100%" }}
                >
                    There was an error requesting chart data.

                </Alert>
            </Snackbar>
        </div>
    );
}


