import * as Mui from "@mui/material";
import { useNavigate } from "react-router-dom";

interface StepAllDoneProps {
  onNextClick: () => void;
}

export const StepAllDone: React.FC<StepAllDoneProps> = (props) => {
  var navigate = useNavigate();

  setTimeout(() => {
    navigate("/");
  }, 2000);
  return (
    <Mui.Stack
      direction="column"
      alignItems={"center"}
      sx={{ width: { xs: "100%", md: "70%", xxl: "55%" } }}
      spacing={2}
      padding={2}
    >
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        All Done!
      </Mui.Typography>
      <Mui.Button
        variant="outlined"
        color="primary"
        onClick={props.onNextClick}
      >
        Take me to Login
      </Mui.Button>
    </Mui.Stack>
  );
};
