import {
  Alert,
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  OutlinedInput,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { CardHeader } from "../../components/CardHeader";
import { faUnlockAlt, faXmark } from "@fortawesome/pro-light-svg-icons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getUsers } from "../../redux/Slices/account.slice";
import { useEffect, useState } from "react";
import { changePassword, resetPassword } from "../../redux/Thunks/account";
import { UserSummary } from "../../types/account.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareInfo } from "@fortawesome/pro-solid-svg-icons";
import ConfirmationDialog from "../../components/ConfirmationDialog";
interface PasswordResetProps {
  user?: UserSummary;
}

interface PasswordRequirements {
  has8Letters: boolean;
  containsLowerCase: boolean;
  containsUpperCase: boolean;
  containsDigit: boolean;
  containsUnique: boolean;
}

export const PasswordReset = (props: PasswordResetProps) => {
  const { user } = props
  const dispatch = useAppDispatch();
  const [employee, setEmployee] = useState<string>(user ? user.firstName + ' ' + user.lastName : '');
  const [isDisabled, setIsDisabled] = useState<boolean>(user ? true : false)
  const [open, setOpen] = useState<boolean>(false);
  const [selectedUserEmail, setSelectedUserEmail] = useState<string>("");
  const users = useAppSelector(getUsers);
  const userName = users.map((user) => user.firstName + " " + user.lastName);
  const [changePasswordForm, setchangePasswordForm] = useState<any>({ newPassword: '', reEntryNewPassword: '' })
  const [isReentryValid, setIsReentryValid] = useState(true)
  const [passwordValid, setPasswordValid] = useState<boolean>(true);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [bannerMessage, setBannerMessage] = useState('')
  useEffect(() => {
    if(changePasswordForm.newPassword.length === 0){
      setPasswordValid(true)
    }
    if(changePasswordForm.reEntryNewPassword.length === 0){
      setIsReentryValid(true)
    }
}, [changePasswordForm])
  const onHandleEmployeeSelection = (value: string) => {
    setEmployee(value);
  };

  const onResetPassword = async () => {
    const split = employee.split(/(\s+)/).filter(function (e) {
      return e.trim().length > 0;
    });
    const correctUser = users.find(
      (el) => el.firstName === split[0] && el.lastName === split[1]
    );
    var passwordResetCommand = correctUser?.emailAddress!;
    setSelectedUserEmail(passwordResetCommand);
    const passwordResp = await dispatch(resetPassword(passwordResetCommand));
    if (passwordResp.payload) {
      setOpen(true);
      setBannerMessage(' Password reset was successful! Instructions have been sent to' + ' ' + { selectedUserEmail })
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const onDeletePW = (key: string) => {
    let clone = structuredClone(changePasswordForm);
    clone[key] = '';
    setchangePasswordForm(clone)
    setPasswordValid(false);

  }
  const onDeleteReentry = (key: string) => {
    let clone = structuredClone(changePasswordForm);
    clone[key] = '';
    setchangePasswordForm(clone)
    setPasswordValid(false);

  }


  const [passwordRequirements, setPasswordRequirements] =
    useState<PasswordRequirements>({
      has8Letters: false,
      containsLowerCase: false,
      containsUpperCase: false,
      containsDigit: false,
      containsUnique: false,
    });


  const handlePasswordInput = (password: string) => {
    let clone = structuredClone(changePasswordForm);
    setchangePasswordForm(clone)
    clone['newPassword'] = password
    setchangePasswordForm(clone)
    var requirements = passwordRequirements;

    // has special character
    if (/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password))
      requirements.containsUnique = true;
    else requirements.containsUnique = false;

    //has 8 chars
    if (password.length >= 8) requirements.has8Letters = true;
    else requirements.has8Letters = false;

    // has number
    if (/\d/.test(password)) requirements.containsDigit = true;
    else requirements.containsDigit = false;

    // has uppercase letter
    if (password.toLowerCase() != password)
      requirements.containsUpperCase = true;
    else requirements.containsUpperCase = false;

    // has lowercase letter
    if (password.toUpperCase() != password)
      requirements.containsLowerCase = true;
    else requirements.containsLowerCase = false;

    setPasswordRequirements({ ...requirements });

    var isValid =
      requirements.containsDigit &&
      requirements.containsLowerCase &&
      requirements.containsUnique &&
      requirements.containsUpperCase &&
      requirements.has8Letters;

    setPasswordValid(isValid);
  };


  const handleReenterPassword = (value: any) => {
    let clone = structuredClone(changePasswordForm);
    setchangePasswordForm(clone)
    clone['reEntryNewPassword'] = value
    setchangePasswordForm(clone)
    if (value !== changePasswordForm.newPassword) {
      setIsReentryValid(false)
    }
    if (value === changePasswordForm.newPassword) {
      setIsReentryValid(true)

    }
  }

  const onChangePassword = async () => {
    if(changePasswordForm.newPassword === changePasswordForm.reEntryNewPassword){
      const changePWResponse = await dispatch(changePassword(changePasswordForm.newPassword));
      if (changePWResponse.payload) {
        setOpen(true);
        setBannerMessage('Your Password has been successfully changed.')
      }
    }
    if(changePasswordForm.newPassword === changePasswordForm.reEntryNewPassword){
      setIsReentryValid(false)
      setPasswordValid(false)
    }


  }

  return (
    <Card sx={{ width: '100%' }}>
      <ConfirmationDialog
        title="Are you sure you want to change your password?"
        open={isDialogOpen}
        onConfirm={() => {
          onChangePassword();
          setDialogOpen(false);
        }}
        onCancel={() => {
          setDialogOpen(false);
        }}
      />
      <CardHeader
        icon={faUnlockAlt}
        subText={"Search for a team member to reset their password"}
        title="Reset a password"
      />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {bannerMessage}
        </Alert>
      </Snackbar>

      <CardContent style={{ paddingBottom: 0 }}>
        {/* SEARCH COMPONENT  */}
        <Autocomplete
          disabled={isDisabled}
          disablePortal
          id="employee-name-autocomplete"
          options={userName}
          size="small"
          value={employee || null}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Employee"
              value={employee}
            />
          )}
          onChange={(_event, value: any) => onHandleEmployeeSelection(value)}
          PaperComponent={({ children }) => (
            <Paper style={{ background: "#efefef" }}>{children}</Paper>
          )}
        />
        {/* RESET BUTTON */}
        <CardActions sx={{ justifyContent: "flex-end", paddingRight: 0 }}>
          <Button
            variant="outlined"
            onClick={onResetPassword}
          >
            Reset Password
          </Button>
        </CardActions>
        <Divider />
        {/* NEW PASSWORD FORM */}
        <Grid container spacing={0}>
          <Grid item xs={12} sx={{ pt: 2, pb: 1, display: "flex", justifyContent: 'flex-start' }}>
            <Typography>Change Password</Typography>
          </Grid>
          <Grid item xs={1} sx={{ pt: 1, display: "flex", justifyContent: 'flex-start' }}>
            <FontAwesomeIcon
              icon={faSquareInfo}
              color={'orange'}
              size="lg"

            />
          </Grid>
          <Grid item xs={11} sx={{ display: "flex", justifyContent: 'flex-start' }}>

            <Typography variant="subtitle2" color="silver">
              Password must contain at least 8 letters, one lowercase, one uppercase, one digit and one unique character.
            </Typography>
          </Grid>
          {/* OLD PASSWORD */}
          {/* <Grid item xs={12} sx={{pt: 2, display: "flex", justifyContent: 'center' }}>
            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
              <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Current Password'>Current Password</InputLabel>
              <OutlinedInput
                label='Current Password'
                sx={{ display: 'flex', flexGrow: 1 }}
                id="oldPassword"
                size="small"
                value={changePasswordForm['oldPassword']}
                onChange={(event) =>
                  handleCriteriaChange(event.target.value, 'oldPassword')
                }
                endAdornment={
                  <IconButton
                    onClick={() => onDelete('oldPassword')}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      color={"#f8931c"}
                      size="xs"
                    />
                  </IconButton>
                }
              />
            </FormControl>
          </Grid> */}
          {/* NEW PASSWORD */}
          <Grid item xs={12} sx={{ pt: 2, display: "flex", justifyContent: 'center' }}>
            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
              <InputLabel htmlFor='New Password'>New Password</InputLabel>
              <OutlinedInput
                error={!passwordValid}
                label='New Password'
                type='password'
                sx={{ display: 'flex', flexGrow: 1 }}
                id="newPassword"
                size="small"
                value={changePasswordForm['newPassword']}
                onChange={(event) => handlePasswordInput(event.target.value)}
                endAdornment={
                  <IconButton
                    onClick={() => onDeletePW('newPassword')}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      color={"#f8931c"}
                      size="xs"
                    />
                    
                  </IconButton>
                }
              />
              {!passwordValid ?
                <FormHelperText error id="accountId-error">
                  New Password does not meet requirements.
                </FormHelperText>
                : <></>}
            </FormControl>
          </Grid>
          {/* RE-ENTER PASSWORD */}
          <Grid item xs={12} sx={{ pt: 2, display: "flex", justifyContent: 'center' }}>
            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
              <InputLabel htmlFor='Source Port'>Re-enter New Password</InputLabel>
              <OutlinedInput
                error={!isReentryValid}
                type='password'
                label='Re-enter New Password'
                sx={{ display: 'flex', flexGrow: 1 }}
                id="reEntryNewPassword"
                size="small"
                value={changePasswordForm['reEntryNewPassword']}
                onChange={(event) => handleReenterPassword(event.target.value)}
                endAdornment={
                  <IconButton
                    onClick={() => onDeleteReentry('reEntryNewPassword')}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      color={"#f8931c"}
                      size="xs"
                    />
                  </IconButton>
                }
              />
              {!isReentryValid ?
                <FormHelperText error id="accountId-error">
                  Please Re-enter new password.
                </FormHelperText>
                : <></>}
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ pt: 2, pb: 1, display: "flex", justifyContent: 'flex-end' }}>
            <Button
              disabled={(isReentryValid && passwordValid) ? false : true}
              variant="outlined"
              onClick={() => setDialogOpen(true)}
            >
              Change Password
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
