import { Box, Button, Modal, Tooltip, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hooks";
import { fetchInvestigateDevice, fetchIpLocation, fetchPortDescription } from "../../redux/Thunks/alerts";
import { Loader } from "../../components/Loader";


interface AdditionalInformationCellProps {
    additionalInformation: any
    currentDevice: any;
}

const buttonStyle = {
    padding: 0,
    textAlign: 'left',
    minWidth: 0,
}

export const AdditionalInformationCell = (props: AdditionalInformationCellProps) => {
    const { additionalInformation, currentDevice } = props
    const [open, setOpen] = useState(Array(!additionalInformation ? 0 : additionalInformation.length).fill(false));
    const [tooltipTitle, setTooltipTitle] = useState<any>('')
    const [hoverResponse, setHoverResponse] = useState([])
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState(false)

    const handleClose = (index: number) => {
        let newArr = [...open];
        newArr[index] = false;
        setOpen(newArr);
    };

    const ValidatePort = (port: string) => {
        if (/^([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/.test(port)) {
            return (true)
        }
        return (false)
    }

    const ValidateIPaddress = (ipaddress: string) => {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }


    const handleOpen = async (info: { key: '', value: '' }, index: number) => {
        const validPort = ValidatePort(info.value)
        const validIP = ValidateIPaddress(info.value)

        let response: any = []
        if (validPort) {
            const port = await dispatch(fetchPortDescription(info.value))
            const titleCase = [{
                'Port': port.payload[0].port,
                'Service Name': port.payload[0].serviceName,
                'Protocol': port.payload[0].protocol,
                'Description': port.payload[0].description,
            }]
            response = titleCase
        } if (validIP) {
            const ip = await dispatch(fetchIpLocation(info.value))
            if (ip.payload) {
                const titleCase = [{
                    'Ip': ip.payload.ip,
                    'City': ip.payload.city,
                    'Country': ip.payload.country,
                    'Country Code': ip.payload.countryCode,
                    'Isp': ip.payload.isp,
                    'Org': ip.payload.org,
                    'region': ip.payload.region,
                    'Region Name': ip.payload.regionName,
                    'Zip': ip.payload.zip,
                }]
                response = titleCase
            }
        }

        else {
        }

        setHoverResponse(response)
        let newArr = Array(!additionalInformation ? 0 : additionalInformation.length).fill(false);
        newArr[index] = true;
        setOpen(newArr);
    };

    const buildTooltip = () => {
        let ipArray: any[] = []
        if (hoverResponse) {
            hoverResponse.forEach((value) => {
                Object.entries(value).forEach((val: any) => {
                    ipArray.push(<Typography>{val[0].charAt(0).toUpperCase() + val[0].slice(1)}: {val[1]}</Typography>)
                })
            })
        }
        return ipArray
    }

    useEffect(() => {
        setTooltipTitle(buildTooltip())
    }, [hoverResponse]);

    const handleClick = async (info: { key: '', value: '' }) => {
        setLoading(true)

        const command = `${currentDevice?.id}/${currentDevice?.deviceGroup}?investigate=${info.value}`
        const response = await dispatch(fetchInvestigateDevice(command));
        if (response.payload) {
            setLoading(false)
        }
    }

    const structureDisplayNames = (key: string) => {
        if(key === 'IPAddress'){
            return 'IP Address'
        }
        if(key === 'AADDeviceName'){
            return 'AAD Device Name'
        }
        if(key === 'RemoteIP'){
            return 'Remote IP'
        }
        if(key === 'LocalIP'){
            return 'Local IP'
        }
         else {
            const result = key.replace(/([A-Z])/g, " $1")
            return  result.charAt(0).toUpperCase() + result.slice(1) 

        }
    }
    return (
        additionalInformation.map((info: { key: '', value: '' }, index: number) => {
            const displayName = structureDisplayNames(info.key)
            return (
                <Box>
                    <Modal open={loading} sx={{ '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, 0.1)' } }}>
                        <Loader show={true} />
                    </Modal>
                    <Typography >
                        <strong>{displayName}: </strong>
                        <Tooltip
                            open={open[index]}
                            onClose={() => handleClose(index)}
                            onOpen={() => handleOpen(info, index)}
                            title={hoverResponse.length > 0 ? tooltipTitle : ''}
                        >
                            <Button
                                variant="text"
                                key={index}
                                onClick={() => handleClick(info)}
                                sx={buttonStyle}
                            >
                                {info.value}
                            </Button>
                        </Tooltip>
                    </Typography>
                </Box>
            )
        })
    )
}