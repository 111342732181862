import React from "react";
import * as Mui from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ButtonLinks {
  text: string;
  url: string;
}

interface NotifyBannerWithDurationProps {
  createdItemId?: string;
  secondsDuration: number;
  onHideBanner: VoidFunction;
  title: string;
  subText: string;
  color: string;
  buttons: ButtonLinks[];
}

export const NotifiyBannerWithDuration: React.FC<
  NotifyBannerWithDurationProps
> = (props) => {
  const [counter, setCounter] = React.useState<number>(props.secondsDuration);
  const [displayAlert, setDisplayAlert] = React.useState<boolean>(true);

  var navigate = useNavigate();

  React.useEffect(() => {
    let timer: any;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 10000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);

  const handleClose = () => {
    setCounter(0);
  };
  const handleExited = (node: HTMLElement) => {
    setDisplayAlert(false);
    props.onHideBanner();
  };
  const handleEnter = (node: HTMLElement, isAppearing: boolean) => {
    setDisplayAlert(true);
  };

  return (
    <>
      <Mui.Fade
        in={counter !== 0}
        onExited={handleExited}
        onEnter={handleEnter}
      >
        <Mui.Alert
          sx={{
            mb: 2,
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: props.color,
            width: "100%",
            display: displayAlert ? "flex" : "none",
          }}
          action={
            <>
              <Mui.Button
                onClick={handleClose}
                variant="text"
                sx={{ color: props.color, textDecoration: "underline" }}
              >
                <Mui.Typography>Closing in {counter} s</Mui.Typography>
              </Mui.Button>
              <Mui.Button
                onClick={() => {
                  navigate(`/changemanagement/${props.createdItemId}`);
                }}
              >
                View Change Management
              </Mui.Button>
              {/* {props.buttons.map((buttonLink) => {
                return (
                  <Mui.Button
                    variant="outlined"
                    sx={{ color: props.color, borderColor: props.color }}
                    onClick={() => {
                      navigate(buttonLink.url);
                    }}
                  >
                    {buttonLink.text}
                  </Mui.Button>
                );
              })} */}
            </>
          }
        >
          <Mui.AlertTitle>{props.title}</Mui.AlertTitle>
          {props.subText}
        </Mui.Alert>
      </Mui.Fade>
    </>
  );
};
