import { useIdentityContext } from "../../context/IdentityContext";
import { getUserById, isUserEndUser } from "../../redux/Slices/account.slice";
import { useAppSelector } from "../../redux/hooks";
import { SettingsPage } from "./SettingsPage";
import { Grid } from "@mui/material";
import { PersonalInformation } from "./SettingsPageComponents/PersonalInformation";
import { PasswordReset } from "../CompanyAdminDashboard/PasswordReset";

export const SettingsDashboardPage = () => {
  const { authenticatedUser } = useIdentityContext();
  const isEndUser = useAppSelector(isUserEndUser(authenticatedUser.userId));
  const user = useAppSelector(getUserById(authenticatedUser.userId));
  return (
    (isEndUser ?
      <Grid container spacing={2}>
        <Grid item xs={8} sx={{ display: "flex", justifyContent: 'space-between' }}>
          <PersonalInformation />
        </Grid>
        <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
          <PasswordReset
            user={user!}
          />
        </Grid>
      </Grid>
      :
      <SettingsPage />)


  )
}