import { Paper, TableBody, TableCell, TablePagination, TableRow, TableContainer, Table, Button, Stack, Modal, Tooltip, ButtonGroup, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FirewallResponse } from "../../types/alerts.types";
import { getComparator, Order, stableSort } from "../../common/TableUtilities";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import { useAppDispatch } from "../../redux/hooks";
import { EnhancedTableHead } from "./InvestigateFirewallEnhancedTableHead";
import dayjs from "dayjs";
import { AdditionalInformationModal } from "./AdditionalInformationModal";
import { DestinationIPRow, DestinationPortRow, SourceIPRow, SourcePortRow } from "./InvestigateFirewallTableRows";
import exportFromJSON from "export-from-json";
interface InvestigateFirewallEnhancedTableProps {
  firewallResponse: FirewallResponse[];
  handleSearchParams: (value: any, key: string) => void;
}

export function InvestigateFirewallEnhancedTable(
  props: InvestigateFirewallEnhancedTableProps
) {
  return <EnhancedTable
    firewallResponse={props.firewallResponse}
    handleSearchParams={props.handleSearchParams}
  ></EnhancedTable>;
}

export default function EnhancedTable(props: {
  firewallResponse: FirewallResponse[];
  handleSearchParams: (value: any, key: string) => void;
}) {
  const { firewallResponse } = props;
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<any>([]);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof FirewallResponse>("type");
  const [page, setPage] = useState(0);
  const [openInfoModal, setOpenInfoModal] = useState(false)
  const [selectedModalRow, setSelectedModalRow] = useState({})
  const handleInfoModalClose = () => setOpenInfoModal(false);

  const rowsPerPage = 25;
  const recordsTotal = rows.length;

  useEffect(() => {
    if (firewallResponse) {
      setRows(firewallResponse)
    }
  }, [firewallResponse])

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof FirewallResponse
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };


  const onClickExport = (data: any) => {
    const fileName = 'FirewallTable'
    const exportType = exportFromJSON.types.xls
    exportFromJSON({ data, fileName, exportType })
  };
  return (
    <>
      <Modal
        open={openInfoModal}
        onClose={handleInfoModalClose}

      >

        <AdditionalInformationModal
          selectedModalRow={selectedModalRow}
          handleSearchParams={props.handleSearchParams}
        />
      </Modal>
      {/* TABLE CARD */}
      <Card>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
          <ButtonGroup sx={{pb: 1}}>
            <Tooltip title='Export to Excel'>
              <Button variant="outlined" onClick={() => onClickExport(rows)}>Export</Button>

            </Tooltip>

          </ButtonGroup>
            </Grid>
          </Grid>

          <TableContainer
            component={Paper}
            sx={{
              borderStyle: "solid",
              borderColor: "#ccc",
              borderWidth: "1px",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="alerts"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows as any, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, _index) => {
                    return (
                      <TableRow tabIndex={-1} key={row.type}>

                        <TableCell align="left">{dayjs(row.timeGenerated).format('YYYY-MM-DD HH:mm:ss A')}</TableCell>
                        {/* SOURCE PORT */}
                        <TableCell align="left">
                          <SourcePortRow
                            row={row}
                            handleSearchParams={props.handleSearchParams}
                          />
                        </TableCell>
                        {/* SOURCE IP */}
                        <TableCell align="left">
                          <SourceIPRow
                            row={row}
                            handleSearchParams={props.handleSearchParams}
                          />
                        </TableCell>
                        {/* DEVICE ACTION */}
                        <TableCell align="left">
                          <Button
                            variant="text"
                            onClick={() => props.handleSearchParams(row.deviceAction, 'deviceAction')}>
                            {row.deviceAction}
                          </Button>
                        </TableCell>
                        {/* DESTINATION PORT */}
                        <TableCell align="left">
                          <DestinationPortRow
                            row={row}
                            handleSearchParams={props.handleSearchParams}
                          />
                        </TableCell>
                        {/* DESTINATION IP */}
                        <TableCell align="left">
                          <DestinationIPRow
                            row={row}
                            handleSearchParams={props.handleSearchParams}
                          />
                        </TableCell>
                        {/* APPLICATION PROTOCOL */}
                        <TableCell align="left">
                          <Button
                            variant="text"
                            onClick={() => props.handleSearchParams(row.applicationProtocol, 'applicationProtocol')}>
                            {row.applicationProtocol}
                          </Button>
                        </TableCell>
                        {/* TYPE */}
                        <TableCell align="left">{row.type}</TableCell>
                        {/* ADDITIONAL INFO */}
                        <TableCell align="left">
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-start"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setOpenInfoModal(true);
                                setSelectedModalRow({
                                  'Sent Bytes': row.sentBytes,
                                  'Received Bytes': row.receivedBytes,
                                  'Reason': row.reason,
                                  'Device Custom String 1': row.deviceCustomString1,
                                  'Device Custom String 2': row.deviceCustomString2,
                                  'Destination User Name': row.destinationUserName,
                                  'Source User Name': row.sourceUserName,
                                  'Request URL': row.requestURL,
                                })

                              }}
                            >
                              Additional Information
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={recordsTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
        </CardContent>
      </Card>
    </>
  );
}
