import { Button, Icon } from "@mui/material";
import protectAxios from "../../api/axios";
import { ReactComponent as Logo } from "../../images/LogoSmall.svg";

const SuccessDisplay = ({ sessionId }: { sessionId: string }) => {
  const goToPortal = async () => {
    const { redirectUrl } = await protectAxios
      .post("/companycommands/Payment/create-new-portal-session", { sessionId })
      .then((resp) => resp.data);
    window.location.href = redirectUrl;
  };
  return (
    <section>
      <div className="product Box-root">
        <Icon>
          <Logo />
        </Icon>
        <div className="description Box-root">
          <h3>Your purchase was successful!</h3>
        </div>
      </div>
      <Button variant="contained" onClick={goToPortal}>
        Manage Billing
      </Button>
    </section>
  );
};

export default SuccessDisplay;
