
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { faLaptopMedical } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function DeviceHealthSummary(props) {
    const { deviceHealth, isLayoutChanged } = props
    const chartComponent = useRef({});
    const [noData, setNoData] = useState(false)
    const determineNoData = () => {
       if(deviceHealth.critical === 0 && deviceHealth.healthy === 0 && deviceHealth.warning === 0){
        setNoData(true)
       }
    }
    const [highPriorityPercentage, setHighPriorityPercentage] = useState(0)

 

const getPercentage = (highPriority, mediumPriority, lowPriority) => {
    return +(((highPriority / (highPriority + mediumPriority + lowPriority)) * 100).toFixed(2))
}




    useEffect(() => {
        setHighPriorityPercentage(getPercentage(deviceHealth.critical, deviceHealth.warning, deviceHealth.healthy))
        determineNoData()
    }, [deviceHealth])
    const pieChartData = [
        deviceHealth.critical !== 0 ?
            {
                name: "Error",
                y: deviceHealth.critical,
                color: 'rgb(255,0,0, 0.4)',
            } : '',
            deviceHealth.warning !== 0 ?
            {
                name: "Warning",
                y: deviceHealth.warning,
                color: 'rgb(232,143,0, 0.4)',
            } : '',
            deviceHealth.healthy !== 0 ?
            {
                name: "Successful",
                y: deviceHealth.healthy,
                color: 'rgb(50,205,50, 0.4)',
            } : '']


    const options = {
        chart: {
            type: 'pie',
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: 100,
            labelFormatter: function () {
                if(this.y){
                    return this.name;
                }
                if(!this.y){
                    return 'No Values'
                }
            }
        },
        credits: {
            enabled: false
        },

        title: {
            text: noData ? 'No Data Available' : 'Device Health State Summary',
        },
        subtitle: {
            text: noData ? '' : `${deviceHealth.critical} Devices (${highPriorityPercentage}%) in Error State`
        },
        tooltip: {
            pointFormat: ' {point.y} devices'
        },
        plotOptions: {
            pie: {
                borderRadius: 25,
                borderWidth: 5,
                dataLabels: {
                    enabled: true,
                    distance: '-30%',
                    format: '{point.y} {point.name}',

                },

                center: ['50%', '50%'],
                size: '100%',
                showInLegend: noData ? false : true
            }
        },
        series: [{
            name: "Total",
            innerSize: '50%',
            data: pieChartData
        }],
    }

    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [isLayoutChanged]);
    return (
        <Card sx={{ border: "solid 1px #ddd", height: '100%', padding: 1 }}>
            <FontAwesomeIcon
                icon={faLaptopMedical}
                color={'#e88f00'}
                size={'lg'}
                style={{ position: 'absolute', zIndex: 1 }}

            />
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />
        </Card>

    );
}