import { Box, IconButton, Modal, TextField, Tooltip, Typography } from "@mui/material"
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useAsyncEffect } from "../../common/hooks";
import { chatAI } from "../../redux/Thunks/alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCircleArrowUp,
    faCommentDots,
    faMessageBot
} from "@fortawesome/pro-light-svg-icons";
import { boxStyle, headerStyle, harveyIconStyle, headerTextStyle, mainBoxStyle, chatIconStyle, harveyChatStyle, userChatStyle, textBoxStyle } from "./HarveyModalStyles";
import { useIdentityContext } from "../../context/IdentityContext";
import { getUserById } from "../../redux/Slices/account.slice";

interface HarveyModalProps {
    openHarvey: boolean;
    closeHarvey: () => void;
    topic: any;
    type: string;
}

export const HarveyModal = (props: HarveyModalProps) => {
    const { openHarvey, closeHarvey, topic, type } = props
    const dispatch = useAppDispatch();
    const identity = useIdentityContext();
    const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
    const handleClose = () => { closeHarvey() }
    const [open, setOpen] = useState(openHarvey)
    const [initialMessage, setInitialMessage] = useState('')
    const [typedMessage, setTypedMessage] = useState('')
    const [chatDetails, setChatDetails] = useState([{}])
    const [conversation, setConversation] = useState({ chatAI: [], lastUserPrompt: '' })
    useEffect(() => {
        setInitialMessage('')
    }, [topic])
    useAsyncEffect(async () => {
        if (topic.description) {
            const response = await dispatch(chatAI({ 'lastUserPrompt': `Harvey can you please tell me about alert ${topic.description}` }));
            if (response.payload) {
                setInitialMessage(response.payload.chatAI[0].assistant)
                setConversation(response.payload)
            }
        }
    }, [topic]);

    useEffect(() => {
        if (type === 'alert') {
            setChatDetails([
                {
                    author: 'User',
                    text: `Harvey, can you please tell me more about ${topic.description} `,
                },
                {
                    author: 'Harvey',
                    text: `${initialMessage}`,
                }
            ])
        }
        if (type === 'device') {
            setChatDetails([
                {
                    author: 'User',
                    text: `Harvey, can you please tell me more about ${topic.description} `,
                },
                {
                    author: 'Harvey',
                    text: `${initialMessage}`,
                }
            ])
        }
        if (type === 'general') {
            setChatDetails([
                {
                    author: 'Harvey',
                    text: `Hello ${user?.firstName}! How can I assist with IT Security related questions?`,
                },
            ])
        }
    }, [initialMessage, openHarvey])
    useEffect(() => {
        setOpen(openHarvey)
    }, [openHarvey])


    useEffect(() => {
        var element = document.getElementById("mainBox");
        element?.scroll({
            top: 10000,
            left: 0,
            behavior: "smooth",
        });
    }, [chatDetails])



    const onSendMessage = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setTypedMessage(e.target.value)

    }

    const askHarvey = async (value: any) => {
        let newArr = [...value]
        let newConversation = conversation
        newConversation.lastUserPrompt = typedMessage
        const response = await dispatch(chatAI(newConversation));
        if (response.payload) {
            setConversation(response.payload)
            newArr.splice(newArr.length - 1, 1, {
                author: 'Harvey',
                text: response.payload.chatAI[response.payload.chatAI.length - 1].assistant,
            })
        }
        setChatDetails(newArr)

    }

    const onClickSendMessage = () => {
        let newArr = [...chatDetails]
        newArr.push(
            {
                author: 'User',
                text: typedMessage,
            },
            {
                author: 'Harvey',
                text: '',
            })
        setChatDetails(newArr)
        setTypedMessage('')
        askHarvey(newArr)

    }


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={boxStyle}>
                <Box sx={headerStyle}>
                    <FontAwesomeIcon
                        icon={faMessageBot}
                        color={"rgb(232,143,0)"}
                        size="3x"
                        style={harveyIconStyle}
                    />
                    <Typography key='title' variant="h5" component="h2" sx={headerTextStyle}>
                        Chat with Harvey (Harvey specialises in security information)
                    </Typography>

                </Box>
                <Box id='mainBox' sx={mainBoxStyle}>

                    {chatDetails.map((chat: any, index: number) => {
                        return (
                            <>
                                {chat.text === '' ?
                                    <FontAwesomeIcon
                                        key={index}
                                        icon={faCommentDots}
                                        color={"rgb(232,143,0)"}
                                        size="5x"
                                        style={chatIconStyle}
                                    />
                                    :
                                    <Typography
                                        key={index}
                                        variant="h6"
                                        component="h2"
                                        sx={chat.author === 'Harvey' ? harveyChatStyle : userChatStyle}
                                    >
                                        {chat.text}
                                    </Typography>
                                }
                            </>
                        )
                    })}
                </Box>
                <TextField
                    sx={textBoxStyle}
                    value={typedMessage}
                    onChange={(e) => onSendMessage(e)}
                    id="newMessage"

                    // multiline
                    variant="outlined"
                    rows={2}
                    fullWidth
                    onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            onClickSendMessage()
                        }
                    }}
                    inputRef={input => input && input.focus()}
                    InputProps={{
                        endAdornment:
                            <Tooltip title='Send Message'>
                                <IconButton
                                    type="submit"
                                    onClick={onClickSendMessage}
                                >
                                    <FontAwesomeIcon
                                        icon={faCircleArrowUp}
                                        color={"rgb(232,143,0)"}
                                        size="lg"

                                    />
                                </IconButton>
                            </Tooltip>
                    }}
                />
            </Box>
        </Modal>
    )
}