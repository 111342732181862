import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface BannerState {
  type: "impersonate";
  payload: { companyName: string };
}

export interface LayoutState {
  title: string;
  subtitle: string;
  icon: IconDefinition | null;
  banner?: BannerState;
  links?: Array<{ href: string; text: string }>;
}

const initialState: LayoutState = { title: "", subtitle: "", icon: null };

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setLayout: (state, action: PayloadAction<LayoutState>) => {
      state.title = action.payload.title;
      state.subtitle = action.payload.subtitle;
      state.icon = action.payload.icon;
      state.links = action.payload.links;
    },
    setBanner: (state, action: PayloadAction<BannerState | undefined>) => {
      state.banner = action.payload;
    },
  },
});

export const { setLayout, setBanner } = layoutSlice.actions;

export const getLayout = (state: RootState) => state.layout;
export const getBanner = (state: RootState) => state.layout.banner;

export default layoutSlice.reducer;
