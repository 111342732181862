import { Ticket } from "../types/tickets.types";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Ticket;
  label: string;
  numeric: boolean;
}

export const countTicketStatus = (tickets: Ticket[]) => {
  let ticketStatusCount = { open: 0, inProgress: 0, closed: 0 };
  tickets.map((ticket: any) => {
    if (ticket.status === 0) {
      ticketStatusCount.open++;
    }
    if (ticket.status === 1) {
      ticketStatusCount.inProgress++;
    }
  });
  return ticketStatusCount;
};

export const countTicketPriority = (tickets: Ticket[]) => {
  let ticketPriorityCount = { low: 0, medium: 0, high: 0, total: 0 };
  tickets.map((ticket: any) => {
    if (ticket.priority === 0) {
      ticketPriorityCount.low++;
    }
    if (ticket.priority === 1) {
      ticketPriorityCount.medium++;
    }
    if (ticket.priority === 2) {
      ticketPriorityCount.high++;
    }
  });
  ticketPriorityCount.total = tickets.length;
  return ticketPriorityCount;
};

export function isAlertTicket(type: string) {
  return type === "alert";
}

export const headCells: readonly HeadCell[] = [
  {
    id: "ticketNumber",
    numeric: true,
    disablePadding: true,
    label: "Ticket No.",
  },
  {
    id: "title",
    numeric: true,
    disablePadding: true,
    label: "Ticket Title",
  },
  {
    id: "createdDate",
    numeric: true,
    disablePadding: true,
    label: "Created On",
  },
  {
    id: "raisedByName",
    numeric: true,
    disablePadding: false,
    label: "Raised By",
  },
  {
    id: "assignedEngineerName",
    numeric: true,
    disablePadding: false,
    label: "Assigned Engineer",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "numberOfComments",
    numeric: true,
    disablePadding: false,
    label: "Comments",
  },
  {
    id: "viewTicket",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

export const priorityBackgroundColor = (status: number | any) => {
  switch (status) {
    case 0:
      return "grey";
    case 1:
      return "orange";
    case 2:
      return "red";
    default:
      return "grey";
  }
};