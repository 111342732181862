import { FC } from "react";
import * as Mui from "@mui/material";

interface ComplianceOverviewTableProps {}

export const ComplianceOverviewTable: FC<ComplianceOverviewTableProps> = () => {
  const CircularProgressWithLabel = (
    props: Mui.CircularProgressProps & { value: number }
  ) => {
    return (
      <Mui.Box sx={{ position: "relative", display: "inline-flex" }}>
        <Mui.CircularProgress variant="determinate" {...props} />
        <Mui.Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Mui.Typography
            variant="caption"
            component="div"
            color="text.secondary"
          >{`${Math.round(props.value)}%`}</Mui.Typography>
        </Mui.Box>
      </Mui.Box>
    );
  };

  return (
    <Mui.Stack
      direction="column"
      justifyContent="space-evenly"
      alignItems="stretch"
      height="93%"
      width="100%"
    >
      <Mui.Box>
        <Mui.Table>
          <Mui.TableHead sx={{ backgroundColor: "white" }}>
            <Mui.TableRow>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Category
              </Mui.TableCell>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Scans
              </Mui.TableCell>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Tasks
              </Mui.TableCell>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Passed
              </Mui.TableCell>
              <Mui.TableCell sx={{ color: "#E88F00" }} align="left">
                Failed
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableHead>
          <Mui.TableBody>
            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">Access Control (AC)</a>
              </Mui.TableCell>
              <Mui.TableCell align="left">OK</Mui.TableCell>
              <Mui.TableCell align="left">379</Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "green" }}
              >
                <CircularProgressWithLabel value={65} color="inherit" />
              </Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "red" }}
              >
                <CircularProgressWithLabel value={35} color="inherit" />
              </Mui.TableCell>
            </Mui.TableRow>

            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">Identification and Authentication (IA)</a>
              </Mui.TableCell>
              <Mui.TableCell align="left">OK</Mui.TableCell>
              <Mui.TableCell align="left">236</Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "green" }}
              >
                <CircularProgressWithLabel value={90} color="inherit" />
              </Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "red" }}
              >
                <CircularProgressWithLabel value={10} color="inherit" />
              </Mui.TableCell>
            </Mui.TableRow>

            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">Media Protection (MP)</a>
              </Mui.TableCell>

              <Mui.TableCell align="left">OK</Mui.TableCell>
              <Mui.TableCell align="left">31</Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "green" }}
              >
                <CircularProgressWithLabel value={80} color="inherit" />
              </Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "red" }}
              >
                <CircularProgressWithLabel value={20} color="inherit" />
              </Mui.TableCell>
            </Mui.TableRow>

            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">Physical Protection (PE)</a>
              </Mui.TableCell>
              <Mui.TableCell align="left">OK</Mui.TableCell>
              <Mui.TableCell align="left">497</Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "green" }}
              >
                <CircularProgressWithLabel value={20} color="inherit" />
              </Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "red" }}
              >
                <CircularProgressWithLabel value={80} color="inherit" />
              </Mui.TableCell>
            </Mui.TableRow>

            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">System and Communications Protection (SC)</a>
              </Mui.TableCell>
              <Mui.TableCell align="left">OK</Mui.TableCell>
              <Mui.TableCell align="left">198</Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "green" }}
              >
                <CircularProgressWithLabel value={32} color="inherit" />
              </Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "red" }}
              >
                <CircularProgressWithLabel value={68} color="inherit" />
              </Mui.TableCell>
            </Mui.TableRow>

            <Mui.TableRow>
              <Mui.TableCell align="left">
                <a href="#">System and Information Integrity (SI)</a>
              </Mui.TableCell>
              <Mui.TableCell align="left">OK</Mui.TableCell>
              <Mui.TableCell align="left">198</Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "green" }}
              >
                <CircularProgressWithLabel value={50} color="inherit" />
              </Mui.TableCell>
              <Mui.TableCell
                align="left"
                sx={{ padding: "10px 0", color: "red" }}
              >
                <CircularProgressWithLabel value={50} color="inherit" />
              </Mui.TableCell>
            </Mui.TableRow>
          </Mui.TableBody>
        </Mui.Table>
      </Mui.Box>
    </Mui.Stack>
  );
};
