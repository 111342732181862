import * as Mui from "@mui/material";

interface WelcomePageProps {
  onNextClick: () => void;
  introMessage: string;
}

export const WelcomePage: React.FC<WelcomePageProps> = (props) => {
  return (
    <Mui.Stack
      direction="column"
      alignItems={"center"}
      sx={{ width: { xs: "100%", md: "70%", xxl: "55%" } }}
      spacing={2}
      padding={2}
    >
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        Welcome!
      </Mui.Typography>
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "75%", textAlign: "center", color: "#aaa" }}
        variant="body2"
      >
        {props.introMessage}
      </Mui.Typography>
      <Mui.Button
        variant="outlined"
        color="primary"
        onClick={props.onNextClick}
      >
        Next Step
      </Mui.Button>
    </Mui.Stack>
  );
};
