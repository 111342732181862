import { faFireFlameCurved, faXmark } from "@fortawesome/pro-light-svg-icons";
import { Autocomplete, Box, Button, ButtonGroup, FormControl, Grid, IconButton, InputLabel, Modal, OutlinedInput, Switch, TextField, Tooltip, Typography } from "@mui/material";
import { Card } from "../../components/Card";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { DateTimePicker } from '@mui/x-date-pickers';
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchApplicationProtocolCommonSecurityLog, fetchDeviceActionCommonSecurityLog, investigateCommonSecurityLog } from "../../redux/Thunks/alerts";
import { CommonSecurityLog } from "../../types/alerts.types";
import { Loader } from "../../components/Loader";
import { useAsyncEffect } from "../../common/hooks";
import { getSelectedCompanyId } from "../../redux/Slices/account.slice";


interface InvestigateFirewallTableSummaryProps {
    searchQueryParams: any;
}

export const InvestigateFirewallTableSummary = (props: InvestigateFirewallTableSummaryProps) => {
    const { searchQueryParams } = props
    const dispatch = useAppDispatch();
    const [deviceActions, setDeviceActions] = useState([])
    const [applicationProtocolOptions, setApplicationProtocolOptions] = useState([])
    const [checked, setChecked] = useState(false);
    const [filterValues, setFilterValues] = useState<any>({
        companyId: "",
        startTime: "",
        endTime: "",
        useOr: false,
        sourcePort: '',
        sourceIP: "",
        requestURL: "",
        deviceAction: "",
        destinationPort: '',
        destinationIP: "",
        applicationProtocol: ""
    })
    const [startTime, setStartTime] = useState(dayjs());
    const [endTime, setEndTime] = useState(dayjs().add(1, 'hour'));
    const selectedCompanyId: string | any = useAppSelector(getSelectedCompanyId);

    const [loading, setLoading] = useState(false)


    useAsyncEffect(async () => {
        const response = await dispatch(fetchDeviceActionCommonSecurityLog());
        if (response.payload) {
            setDeviceActions(response.payload)
        }
    }, []);
    useAsyncEffect(async () => {
        const response = await dispatch(fetchApplicationProtocolCommonSecurityLog());
        if (response.payload) {
            setApplicationProtocolOptions(response.payload)
        }
    }, []);

    const determineFilterParams = () => {
        let clone = structuredClone(filterValues);
        clone[searchQueryParams[0]] = searchQueryParams[1]
        setFilterValues(clone)
    }



    useEffect(() => {
        determineFilterParams()
    }, [searchQueryParams])

    const onChangeStartTime = (e: dayjs.Dayjs | null) => {
        setStartTime(dayjs(e))
    }
    const onChangeEndTime = (e: dayjs.Dayjs | null) => {
        setEndTime(dayjs(e))
    }
    const onClickCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    const handleCriteriaChange = (value: any, key: string) => {
        let clone = structuredClone(filterValues);
        setFilterValues(clone)
        clone[key] = value
        setFilterValues(clone)
    }

    const onDelete = (key: string) => {
        let clone = structuredClone(filterValues);
        clone[key] = '';
        setFilterValues(clone)
    }

    const onHandleClick = async () => {
        setLoading(true)

        const command: CommonSecurityLog = {
            companyId: selectedCompanyId,
            startTime: dayjs(startTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            endTime: dayjs(endTime).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
            useOr: checked,
            sourcePort: filterValues.sourcePort || null,
            sourceIP: filterValues.sourceIP || null,
            requestURL: filterValues.requestURL || null,
            deviceAction: filterValues.deviceAction || null,
            destinationPort: filterValues.destinationPort || null,
            destinationIP: filterValues.destinationIP || null,
            applicationProtocol: filterValues.applicationProtocol || null,
        }
        const response = await dispatch(investigateCommonSecurityLog(command));
        if (response.payload) {
            setLoading(false)
        }
    }

    const onHandleClearAll = () => {
        let clearAll = {
            companyId: "",
            startTime: "",
            endTime: "",
            useOr: false,
            sourcePort: '',
            sourceIP: "",
            requestURL: "",
            deviceAction: "",
            destinationPort: '',
            destinationIP: "",
            applicationProtocol: ""
        }

        setFilterValues(clearAll)
    }
    return (
        <>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>
            <Card
                id="Firewall"
                headerIcon={faFireFlameCurved}
                headerTitle={"Investigate Firewall"}
                headerSubText={""}
                showHelp={false}
                helpHeaderText=""
                helpHeaderDescription=""
                cardHeaderActions={""}
                cardContent={
                    <Box>
                        <Grid container spacing={0}>
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, pb: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                    <DateTimePicker
                                        label='Start Time'
                                        value={startTime}
                                        onChange={(e) => onChangeStartTime(e)}
                                        renderInput={(params) => (
                                            <TextField
                                                size="small"
                                                sx={{ display: 'flex', flexGrow: 1 }}
                                                {...params}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, pb: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                    <DateTimePicker
                                        label='End Time'
                                        value={endTime}
                                        onChange={(e) => onChangeEndTime(e)}
                                        renderInput={(params) => (
                                            <TextField
                                                size="small"
                                                sx={{ display: 'flex', flexGrow: 1 }}
                                                {...params}
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, pb: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%', m: 0 }} variant="outlined" size='small'>
                                    <Autocomplete
                                        id="applicationProtocolAutoComplete"
                                        options={applicationProtocolOptions}
                                        size="small"
                                        sx={{ display: 'flex', flexGrow: 1 }}
                                        onChange={(event: any, newValue: string | null) => {
                                            handleCriteriaChange(newValue, 'applicationProtocol')
                                        }}
                                        value={filterValues['applicationProtocol']}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    display: 'flex',
                                                    flexGrow: 1,
                                                    "& .MuiSvgIcon-root": {
                                                        color: "rgb(250,165,66)",
                                                    },
                                                    label: { fontWeight: 'bold' }
                                                }}
                                                label='Application Protocol'
                                                value={filterValues['applicationProtocol']}
                                                onChange={(event) =>
                                                    handleCriteriaChange(event.target.value, 'applicationProtocol')
                                                }
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {/* SOURCE PORT */}
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                    <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Source Port'>Source Port</InputLabel>
                                    <OutlinedInput
                                        label='Source Port'
                                        sx={{ display: 'flex', flexGrow: 1 }}
                                        id="sourcePort"
                                        size="small"
                                        value={filterValues['sourcePort']}
                                        onChange={(event) =>
                                            handleCriteriaChange(event.target.value, 'sourcePort')
                                        }
                                        endAdornment={
                                            <IconButton
                                                onClick={() => onDelete('sourcePort')}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    color={"#f8931c"}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {/* SOURCE IP */}
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                    <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Source IP'>Source IP</InputLabel>
                                    <OutlinedInput
                                        label='Source IP'
                                        sx={{ display: 'flex', flexGrow: 1 }}
                                        id="sourceIP"
                                        size="small"
                                        value={filterValues['sourceIP']}
                                        onChange={(event) =>
                                            handleCriteriaChange(event.target.value, 'sourceIP')
                                        }
                                        endAdornment={
                                            <IconButton
                                                onClick={() => onDelete('sourceIP')}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    color={"#f8931c"}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {/* REQUEST URL */}
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                    <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Request URL'>Request URL</InputLabel>
                                    <OutlinedInput
                                        label='Request URL'
                                        sx={{ display: 'flex', flexGrow: 1 }}
                                        id="requestURL"
                                        size="small"
                                        value={filterValues['requestURL']}
                                        onChange={(event) =>
                                            handleCriteriaChange(event.target.value, 'requestURL')
                                        }
                                        endAdornment={
                                            <IconButton
                                                onClick={() => onDelete('requestURL')}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    color={"#f8931c"}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {/* DEVICE ACTION */}
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, pt: 1, display: "flex", justifyContent: 'space-between' }}>

                                <FormControl sx={{ width: '100%', m: 0 }} variant="outlined" size='small'>
                                    <Autocomplete
                                        id="deviceActionAutocomplete"
                                        options={deviceActions}
                                        size="small"
                                        sx={{ display: 'flex', flexGrow: 1 }}
                                        onChange={(event: any, newValue: string | null) => {
                                            handleCriteriaChange(newValue, 'deviceAction')
                                        }}
                                        value={filterValues['deviceAction']}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{
                                                    display: 'flex',
                                                    flexGrow: 1,
                                                    "& .MuiSvgIcon-root": {
                                                        color: "rgb(250,165,66)",
                                                    },
                                                    label: { fontWeight: 'bold' }
                                                }}
                                                label='Device Action'
                                                value={filterValues['deviceAction']}
                                                onChange={(event) =>
                                                    handleCriteriaChange(event.target.value, 'deviceAction')
                                                }
                                            />
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            {/* DESTINATION PORT */}
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, pt: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                    <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Destination Port'>Destination Port</InputLabel>
                                    <OutlinedInput
                                        label='Destination Port'
                                        sx={{ display: 'flex', flexGrow: 1 }}
                                        id="destinationPort"
                                        size="small"
                                        value={filterValues['destinationPort']}
                                        onChange={(event) =>
                                            handleCriteriaChange(event.target.value, 'destinationPort')
                                        }
                                        endAdornment={
                                            <IconButton
                                                onClick={() => onDelete('destinationPort')}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    color={"#f8931c"}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {/* DESTINATION IP */}
                            <Grid item xs={4} sx={{ mb: 1, pr: 1, pt: 1, display: "flex", justifyContent: 'space-between' }}>
                                <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                                    <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Destination IP'>Destination IP</InputLabel>
                                    <OutlinedInput
                                        label='Destination IP'
                                        sx={{ display: 'flex', flexGrow: 1 }}
                                        id="destinationIP"
                                        size="small"
                                        value={filterValues['destinationIP']}
                                        onChange={(event) =>
                                            handleCriteriaChange(event.target.value, 'destinationIP')
                                        }
                                        endAdornment={
                                            <IconButton
                                                onClick={() => onDelete('destinationIP')}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faXmark}
                                                    color={"#f8931c"}
                                                    size="xs"
                                                />
                                            </IconButton>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            {/*USE OR OPTION */}
                            <Grid item xs={4} sx={{ mb: 1, display: "flex", justifyContent: 'space-between' }}>
                                <Tooltip title={
                                    <Box>
                                        <Typography>Enabled: Returns records with either Source IP or Destination IP</Typography>
                                        <Typography>Disabled: Returns records with both Source IP and Destination IP</Typography>
                                    </Box>
                                }>
                                    <Box>
                                        <Typography sx={{ display: 'inline-block' }}>
                                            <Box sx={{ fontWeight: 'bold', m: 1 }}>And/Or Option</Box>

                                        </Typography>
                                        <Switch
                                            checked={checked}
                                            onChange={onClickCheckBox}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                    </Box>
                                </Tooltip>

                            </Grid>
                            <Grid item xs={8} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                                <ButtonGroup
                                    sx={{ m: 1 }}
                                >
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                        onClick={onHandleClearAll}
                                    >
                                        Clear All
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        type="submit"
                                        onClick={onHandleClick}
                                    >
                                        Search
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    </Box>
                }
            />
        </>
    );
};
