import { Card, CardContent, Grid, Typography } from "@mui/material"
import ReportBarChart from "../Widgets/ReportBarChart";
import ReportPieChart from "../Widgets/ReportPieChart";
import ReportsTable from "../Widgets/ReportsTable";

interface DeviceHealthStateProps {
    deviceHealthStates: any;
}

export const DeviceHealthState = (props: DeviceHealthStateProps) => {
    const { deviceHealthStates } = props
    const barChartCategories = ['Success', 'Warning', 'Error', 'Unavailable']
    const barChartData = [
        deviceHealthStates.successDeviceCount,
        deviceHealthStates.warningDeviceCount,
        deviceHealthStates.errorDeviceCount,
        deviceHealthStates.unavailableDeviceCount
    ]
    const pieData = [
        { name: 'Success', y: deviceHealthStates.successDeviceCount },
        { name: 'Warning', y: deviceHealthStates.warningDeviceCount },
        { name: 'Error', y: deviceHealthStates.errorDeviceCount },
        { name: 'Unavailable', y: deviceHealthStates.unavailableDeviceCount },
    ]
    const tableHeaders = ['Health State', '# Devices', '% of Total']
    const rowData = [
        { value: 'Success', count: deviceHealthStates.successDeviceCount, percentage: ((100 * deviceHealthStates.successDeviceCount) / deviceHealthStates.totalDeviceCount).toFixed(2) },
        { value: 'Warning', count: deviceHealthStates.warningDeviceCount, percentage: ((100 * deviceHealthStates.warningDeviceCount) / deviceHealthStates.totalDeviceCount).toFixed(2) },
        { value: 'Error', count: deviceHealthStates.errorDeviceCount, percentage: ((100 * deviceHealthStates.errorDeviceCount) / deviceHealthStates.totalDeviceCount).toFixed(2) },
        { value: 'Unavailable', count: deviceHealthStates.unavailableDeviceCount, percentage: ((100 * deviceHealthStates.unavailableDeviceCount) / deviceHealthStates.totalDeviceCount).toFixed(2) },

    ]
  
    return (
        <Card id='exportDeviceHealthStates' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Device Health States
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                        
                    </Grid>


                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'devices'}
                        />

                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportPieChart
                            pieData={pieData}
                        />
                    </Grid>

                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Device Health States Data'}
                        />
                    </Grid>

                </Grid>

            </CardContent>

        </Card >
    )
}