import * as Mui from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ConfirmInviteCommand } from "../../types/account.types";

interface ConfirmInvitePersonalDetailsProps {
  onNextClick: (contactDetails: ConfirmInviteCommand) => void;
  onPreviousClick: VoidFunction;
  formData: ConfirmInviteCommand;
}

export const ConfirmInvitePersonalDetails: React.FC<
  ConfirmInvitePersonalDetailsProps
> = (props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmInviteCommand>();
  const onSubmit: SubmitHandler<ConfirmInviteCommand> = (data) => {
    props.onNextClick(data);
  };

  return (
    <Mui.Stack
      direction="column"
      alignItems={"center"}
      sx={{ width: { xs: "100%", md: "70%", xxl: "55%" } }}
      spacing={1}
      padding={2}
    >
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        Please enter your details
      </Mui.Typography>
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "75%", textAlign: "center", color: "#aaa" }}
        variant="body2"
      >
        Lets get to know you!, please enter your first and last name
      </Mui.Typography>

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Mui.Stack
          direction="column"
          alignItems={"center"}
          spacing={2}
          padding={2}
        >
          <Controller
            name="firstName"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                required
                onChange={field.onChange}
                id="firstName"
                label="First Name"
                sx={{ width: "100%" }}
                helperText="Please enter your first name"
                error={errors.firstName !== undefined}
              />
            )}
          />

          <Controller
            name="surname"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                required
                onChange={field.onChange}
                id="surname"
                label="Last Name"
                sx={{ width: "100%" }}
                helperText="Please enter your Last Name"
                error={errors.surname !== undefined}
              />
            )}
          />

          <Mui.FormGroup row>
            <Mui.Button
              variant="outlined"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              Next
            </Mui.Button>
          </Mui.FormGroup>
        </Mui.Stack>
      </form>
    </Mui.Stack>
  );
};
