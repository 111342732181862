import {
  Paper,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  TableContainer,
  Table,
  Typography,
  Stack,
  Button,
  Alert,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DisabledAlert,
  StartDisableOverrideCommand,
} from "../../types/alerts.types";
import { FormattedDateTimeMilitary } from "../../common/dateUtils";
import { getComparator, Order, stableSort } from "../../common/TableUtilities";
import Card from "@mui/material/Card";
import { useAppDispatch } from "../../redux/hooks";
import { EnhancedTableHead } from "./DisableAlertsEnhancedTableHead";
import {
  disableAlertOverride,
  fetchTaskStatus,
  startTaskDisableAlertOverride,
} from "../../redux/Thunks/alerts";
import ConfirmationDialog from "../../components/ConfirmationDialog";
interface DisabledAlertsEnhancedTableProps {
  disabledAlerts: DisabledAlert[];
}

export function DisabledAlertsEnhancedTable(
  props: DisabledAlertsEnhancedTableProps
) {
  return <EnhancedTable disabledAlerts={props.disabledAlerts}></EnhancedTable>;
}

export default function EnhancedTable(props: {
  disabledAlerts: DisabledAlert[];
}) {
  const { disabledAlerts } = props;
  const dispatch = useAppDispatch();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [rows, setRows] = useState<DisabledAlert[]>(disabledAlerts);
  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<keyof DisabledAlert>("timeAdded");
  const [page, setPage] = useState(0);
  const [selectedAlert, setSelectedAlert] = useState<any>({});
  const [taskStatus, setTaskStatus] = useState("");
  const [open, setOpen] = useState(false);
  const [disabledButton, setDisabledButton] = useState("");
  useEffect(() => {
    if (taskStatus === "Accepted") {
      setOpen(true);
    }
  }, [taskStatus]);
  const rowsPerPage = 25;
  const overrideTotal = disabledAlerts.length;

  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof DisabledAlert
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onEnableAlert = async () => {
    //FIRST RUN TASK OVERRIDE
    var disableOverrideCommand: StartDisableOverrideCommand = {
      overrideId: selectedAlert.overrideId,
      deviceGroup: selectedAlert.deviceGroup,
    };
    const startOverrideResp = await dispatch(
      startTaskDisableAlertOverride(disableOverrideCommand)
    );
    // IF PAYLOAD EXISTS
    if (startOverrideResp.payload) {
      setDisabledButton(selectedAlert.overrideId);
      const task = await dispatch(
        fetchTaskStatus(startOverrideResp.payload.taskId)
      );
      setTaskStatus(task.payload.state);

      await dispatch(disableAlertOverride({ command: disableOverrideCommand }));
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
        {/* CONFIRMATION DIALOG BOX */}
        <ConfirmationDialog
                title="Are you sure you want to enable this alert?"
                content={selectedAlert.overrideRuleName}
                open={isDialogOpen}
                onConfirm={() => {
                  onEnableAlert();
                  setDialogOpen(false);
                }}
                onCancel={() => {
                  setDialogOpen(false);
                }}
            />
      {/* SUCCESS MESSAGE */}
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Alert has been enabled. It will take 30 seconds to reflect in the
          portal.
        </Alert>
      </Snackbar>
      {/* TABLE CARD */}
      <Card>
          <TableContainer
            component={Paper}
            sx={{
              borderStyle: "solid",
              borderColor: "#ccc",
              borderWidth: "1px",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="alerts"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {stableSort(rows as any, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, _index) => {
                    return (
                      <TableRow tabIndex={-1} key={row.overrideId}>
                        <TableCell align="left">
                          <Typography>
                            {FormattedDateTimeMilitary(new Date(row.timeAdded))}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          {row.overrideRuleName}{" "}
                        </TableCell>
                        {/* <TableCell align="left">{row.overrideId}</TableCell> */}
                        <TableCell align="left">{row.deviceGroup} </TableCell>
                        <TableCell align="left">{row.comment}</TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                setDialogOpen(true);
                                setSelectedAlert(row);
                              }}
                              disabled={disabledButton === row.overrideId}
                            >
                              Enable Alert
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[rowsPerPage]}
              component="div"
              count={overrideTotal}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableContainer>
      </Card>
    </>
  );
}
