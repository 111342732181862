
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { FormattedDate } from '../../../common/dateUtils';
import { useEffect, useRef } from 'react';
import { faBellExclamation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';


export default function ActiveAlertsOverTime(props) {
    const { alerts, isLayoutChanged } = props
    const chartComponent = useRef({});

    function CreateAlertChartData() {
        var alertTotals = {};
        alerts.forEach((alert) => {
            if (alert.createdDate != null) {
                const date = alert.createdDate.toString().split("T")[0];
                if (!alertTotals[date]) {
                    alertTotals[date] = {
                        date: date,
                        value: 1,
                        percentageDifference: undefined,
                        valueDifference: undefined,
                        direction: undefined,
                    };
                } else alertTotals[date].value++;
            }
        });

        var result = Object.values(alertTotals);
        for (var i = 1; i < result.length; i++) {
            let increase = result[i - 1].value - result[i].value;
            if (increase === 0) {
                result[i].percentageDifference = 0;
                result[i].valueDifference = 0;
                result[i].direction = "none";
            } else {
                result[i].percentageDifference = (increase / result[i - 1].value) * 100;
                result[i].valueDifference = result[i].value - result[i - 1].value;
                increase > 0
                    ? (result[i].direction = "down")
                    : (result[i].direction = "up");
            }
        }
        return result;
    }
    const determineSeriesData = (data) => {
        let dataArray = []
        data.map((data) => {
            dataArray.push(data.value)
        })
        dataArray.reverse()
        return dataArray;
    }
    const determineTimeData = (data) => {
        let timeArray = []
        data.map((data) => {
            timeArray.push(dayjs(data.date).format('MM/DD/YY'))
        })
        timeArray.reverse()
        return timeArray
    }
    var chartAlertData = CreateAlertChartData();
    const seriesData = determineSeriesData(chartAlertData)
    const timeData = determineTimeData(chartAlertData)


    const LastReceivedAlerts = () => {
        if (chartAlertData.length === 0) return "";
        else {
            var lastReading = chartAlertData[0];
            return `The last alerts received were on ${FormattedDate(
                (lastReading.date)
            )}, (${lastReading.value} in total)`;
        }
    };
    const options = {
        chart: {
            type: 'areaspline',

        },

        credits: {
            enabled: false
        },
        title: {
            text: 'Active Alerts Over Time',
        },
        subtitle: {
            text: LastReceivedAlerts()
        },
        xAxis: {
            title: undefined,
            type: 'datetime',
            tickInterval: 5,
            categories: timeData,
        },
        yAxis: {
            title: {
                text: undefined
            },
        },
        plotOptions: {
            series: {
                fillOpacity: 0.4,
                marker: {
                    enabled: false,
                    states: {
                        hover: {
                            enabled: true
                        }
                    }
                }
            }
        },
        tooltip: {
            pointFormat: '{point.y} alerts'
        },
        series: [
            {
                showInLegend: false,
                // name: '# Alerts',
                data: seriesData,
                color: '#e88f00'
            }
        ],


    }
    useEffect(() => {
        const chart = chartComponent.current?.chart;
        if (chart) chart.reflow(false);
    }, [isLayoutChanged]);
    return (
        <Card sx={{ border: "solid 1px #ddd", height: '100%', padding: 1 }}>
            <FontAwesomeIcon
                icon={faBellExclamation}
                color={'#e88f00'}
                size={'lg'}
                style={{ position: 'absolute', zIndex: 1 }}
            />
            <HighchartsReact ref={chartComponent} highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />

        </Card>

    );
}