import { FC } from "react";

import * as Mui from "@mui/material";

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

interface NoDataProps {
  icon: IconDefinition;
  headerText: string;
  subText: string;
}

const NoData: FC<NoDataProps> = (props) => {
  var navigate = useNavigate();

  return (
    <Mui.Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: "20vh",
      }}
    >
      <Mui.Stack
        direction="column"
        spacing={2}
        sx={{ width: "100%", alignItems: "center" }}
      >
        <FontAwesomeIcon icon={props.icon} size="4x" color="orange" />
        <Mui.Typography variant="h4">{props.headerText}</Mui.Typography>
        <Mui.Typography variant="body1" color="silver">
          {props.subText}
        </Mui.Typography>
        <Mui.Button
          variant="outlined"
          onClick={() => {
            navigate("/");
          }}
        >
          Go back to my dashboard
        </Mui.Button>
      </Mui.Stack>
    </Mui.Box>
  );
};

export { NoData };
