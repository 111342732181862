import { faBellSlash, faBell } from "@fortawesome/pro-light-svg-icons";
import * as Mui from "@mui/material";
import { Loader } from "../../components/Loader";
import { NoData } from "../../components/NoData";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAlertById, getInvestigatedAlertResponse } from "../../redux/Slices/alerts.slice";
import { useAsyncEffect } from "../../common/hooks";
import { fetchInvestigateAlert } from "../../redux/Thunks/alerts";
import { useEffect } from "react";
import { setLayout } from "../../redux/Slices/layout.slice";
import { InvestigateAlertTableSummary } from "./InvestigateAlertTableSummary";
import InvestigateAlertEnhancedTable from "./InvestigateAlertEnhancedTable";
import { useParams } from "react-router-dom";

export const InvestigateAlertDashboardPage = () => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const currentAlert = useAppSelector(getAlertById(id || ""));
  const investigatedAlert = useAppSelector(getInvestigatedAlertResponse);
  useEffect(() => {
    dispatch(
      setLayout({
        title: "Alerts Dashboard",
        subtitle: "Alerts Dashboard",
        icon: faBell,
      })
    );
  }, [dispatch]);


  const { loading } = useAsyncEffect(async () => {
    const command = `${currentAlert?.id}`
    if (currentAlert?.id)
      await dispatch(fetchInvestigateAlert(command));
  }, [currentAlert?.id]);
  return (
    <>
      {loading ? (
        <Loader show={true} />
      ) : !investigatedAlert ? (
        <NoData
          icon={faBellSlash}
          headerText="No active results found"
          subText="We couldn`t find any results alerts"
        />
      ) : (
        <>
          <InvestigateAlertTableSummary
            investigatedAlert={investigatedAlert}
            currentAlert={currentAlert}
          />
          <Mui.Grid item xs={12} sx={{ height: "auto", marginTop: 2 }}>
            <Mui.Card sx={{ border: "solid 1px #ddd", height: "auto" }}>
              {
                <InvestigateAlertEnhancedTable
                  investigatedAlert={investigatedAlert}
                  currentAlert={currentAlert}
                ></InvestigateAlertEnhancedTable>
              }
            </Mui.Card>
          </Mui.Grid>
        </>
      )}
    </>
  );
};
