import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faMessageBot } from "@fortawesome/pro-light-svg-icons";
import { CardHeader } from "../../components/CardHeader";
import "./KnowledgeBase.css";
import { Loader } from "../../components/Loader";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAlertClassification } from "../../redux/Slices/alerts.slice";
import { Alert } from "../../types/alerts.types";
import { useAsyncEffect } from "../../common/hooks";
import {
    fetchClassificationOfAlert,
} from "../../redux/Thunks/alerts";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";

interface AlertClassificationProps {
    alert?: Alert;
}

export const AlertClassification: React.FC<AlertClassificationProps> = (props) => {
    const { alert } = props;
    const dispatch = useAppDispatch();
    const [severityIcon, setSeverityIcon] = useState({icon: faExclamationCircle, color:'red'})

    const alertClassification = useAppSelector(getAlertClassification);

    const { loading } = useAsyncEffect(async () => {
        if (alert?.id)
            await dispatch(fetchClassificationOfAlert(alert.id));
    }, [alert?.id]);

    useEffect(() => {
        if(Number(alertClassification.severity) < 40){
            setSeverityIcon({icon: faCheckCircle, color:'green'})
        }
        if(Number(alertClassification.severity) > 40 && Number(alertClassification.severity) < 75){
            setSeverityIcon({icon: faExclamationTriangle, color:'orange'})

        }
        if(Number(alertClassification.severity) > 75){
            setSeverityIcon({icon: faExclamationCircle, color:'red'})

        }
      }, [alertClassification]);
    return (
        <Card sx={{ border: "solid 1px #ddd", width: "100%" }}>
            <CardHeader
                icon={faMessageBot}
                subText={
                    !loading
                        ? 'Incident Classification found'
                        : "Searching..."
                }
                title={"Harvey, the assistant says..."}
            />
            <CardContent sx={{}}>
                {loading ? (
                    <Loader show={true} />
                ) : (
                    alertClassification ?
                        <Box>
                            <Typography sx={{ pl: 1, pt: 1 }}>
                                <FontAwesomeIcon icon={severityIcon.icon} color={severityIcon.color} size="lg" /> &nbsp;
                                <strong>Severity (max. 100): </strong>
                                {alertClassification.severity}

                            </Typography>
                            <Typography sx={{ pl: 1, pt: 1 }}>
                                <strong>Reasoning: </strong>
                                {alertClassification.reasoning}
                            </Typography>
                        </Box>
                        :
                            <>
                                Incidents of this type
                            <Typography sx={{ pl: 1, pt: 1 }}>
                                Sorry, we don't have information available for this incident.
                            </Typography>
                        </>
                )}
            </CardContent>
        </Card>
    );
};
