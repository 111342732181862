import * as Mui from "@mui/material";
import React from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface StepResetPasswordEmailAddressProps {
  onNextClick: (emailAddress: string) => void;
}

export const StepResetPasswordEmailAddress: React.FC<
  StepResetPasswordEmailAddressProps
> = (props) => {
  const validationSchema = yup.object().shape({
    emailAddress: yup.string().email("email address has to be valid"),
  });

  const { control, handleSubmit } = useForm<{ emailAddress: string }>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit: SubmitHandler<{ emailAddress: string }> = (data) => {
    props.onNextClick(data.emailAddress);
  };

  return (
    <>
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "70%", textAlign: "center" }}
        variant="h4"
      >
        Enter your email address
      </Mui.Typography>
      <Mui.Typography
        sx={{ pt: 1, maxWidth: "75%", textAlign: "center", color: "#aaa" }}
        variant="body2"
      >
        If the email address is correct, you will receive an email.
      </Mui.Typography>

      <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
        <Mui.Stack
          direction="column"
          alignItems={"center"}
          spacing={2}
          padding={2}
        >
          <Controller
            name="emailAddress"
            control={control}
            render={({ field }) => (
              <Mui.TextField
                name={field.name}
                value={field.value}
                size="small"
                onChange={field.onChange}
                id="emailAddress"
                label="Email Address"
                sx={{ width: "100%" }}
                helperText=""
              />
            )}
          />

          <Mui.FormGroup row>
            <Mui.Button
              variant="outlined"
              color="primary"
              type="submit"
              sx={{ mt: 2 }}
            >
              Reset Password
            </Mui.Button>
          </Mui.FormGroup>
        </Mui.Stack>
      </form>
    </>
  );
};
