import { faBallotCheck } from "@fortawesome/pro-light-svg-icons";
import { Grid, Tooltip, Typography } from "@mui/material";
import { Card } from "../../components/Card";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import dayjs from "dayjs";
import { NavLink } from "react-router-dom";
import { useAsyncEffect } from "../../common/hooks";
import { fetchTicketById } from "../../redux/Thunks/tickets";
import { getOneTicketById } from "../../redux/Slices/tickets.slice";

interface ViewChangeManagementSummaryProps {
    changeManagementItem: any;
}

export const ViewChangeManagementSummary = (props: ViewChangeManagementSummaryProps) => {
    const { changeManagementItem } = props
    const { loading } = useAsyncEffect(async () => {
         dispatch(fetchTicketById(changeManagementItem?.ticketId || ''));
    }, []);
    const ticket = useAppSelector(getOneTicketById);
    const dispatch = useAppDispatch();
    return (
        <Card
            id="Change Management"
            headerIcon={faBallotCheck}
            headerTitle={'Change Management Item Summary'}
            headerSubText={""}
            showHelp={false}
            helpHeaderText=""
            helpHeaderDescription=""
            cardHeaderActions={""}
            cardContent={
                <Grid>
                    {!loading ?
                        <>
                            <Typography>
                                <strong>Title: </strong>{changeManagementItem?.title}
                                <strong> Change Management # </strong>{changeManagementItem?.changeManagementNumber}
                            </Typography>
                            <Typography>
                                <strong>Assigned To: </strong> {changeManagementItem?.assignedEngineerName}
                                <strong> Created On: </strong> {dayjs(changeManagementItem?.createdDate).format('YYYY-MM-DD')}

                            </Typography>
                            {ticket ?
                                <>
                                    <strong>Ticket: </strong>
                                    <Tooltip title='View Ticket' placement="top-start">
                                        <NavLink
                                            to={`/ticket/${ticket?.id}`}
                                        >
                                            {ticket?.title}
                                        </NavLink>
                                    </Tooltip>
                                </>
                                : ''}
                        </> : <></>}
                </Grid>
            }
        />
    );
};
