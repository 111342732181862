import dayjs from "dayjs";

export const onHandleTimeIntervalChange = (event: any) => {
    switch (event.target.value) {
        case 'Day':
            return dayjs().subtract(1, "day").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Week':
            return dayjs().subtract(1, "week").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Month':
            return dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '2 Months':
            return dayjs().subtract(2, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '3 Months':
            return dayjs().subtract(3, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '4 Months':
            return dayjs().subtract(4, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '5 Months':
            return dayjs().subtract(5, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '6 Months':
            return dayjs().subtract(6, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '7 Months':
            return dayjs().subtract(7, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '8 Months':
            return dayjs().subtract(8, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '9 Months':
            return dayjs().subtract(9, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '10 Months':
            return dayjs().subtract(10, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case '11 Months':
            return dayjs().subtract(11, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'Year':
            return dayjs().subtract(1, "year").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        case 'All Time':
            return dayjs().subtract(100, "year").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
        default:
            return dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
    }


}

export const determineDeviceTypesBarChartCategories = (deviceTypes: any) => {
    let returnValue: any = []
    deviceTypes.forEach((device: any) => {
        returnValue.push(device.deviceTypeDescription)
    })
    return returnValue
}

export const determineDeviceTypesBarChartData = (deviceTypes: any) => {
    let returnValue: any = []
    deviceTypes.forEach((device: any) => {
        returnValue.push(device.deviceCount)
    })
    return returnValue
}

export const determineDeviceTypesPieData = (deviceTypes: any) => {
    let returnValue: any = []
    deviceTypes.forEach((device: any) => {
        returnValue.push({ name: device.deviceTypeDescription, y: device.deviceCount })
    })
    return returnValue
}

export const determineDeviceTypeRowData = (deviceTypes: any) => {
    let returnValue: any = []
    let totalArray: any = []
    deviceTypes.forEach((device: any) => {
        totalArray.push(device.deviceCount)
    })
    const total = totalArray.reduce((accumulator: any, currentValue: any) => accumulator + currentValue);
    deviceTypes.forEach((device: any) => {
        returnValue.push({ value: device.deviceTypeDescription, count: device.deviceCount, percentage: ((100 * device.deviceCount) / total).toFixed(2) })
    })
    return returnValue
}

export const determineBarChartCategoriesForDeviceCategory = (value: any) => {
    let returnData: any = []
    value.forEach((device: any) => {
        returnData.push(`${device.deviceCategory} (${device.deviceSubtype})`)
    })
    return returnData
}

export const determineBarChartDataForDeviceCategory = (value: any) => {
    let returnData: any = []
    value.forEach((device: any) => {
        returnData.push(device.deviceCount)
    })
    return returnData
}

export const determineRowDataForDeviceCategory = (value: any) => {
    let returnData: any = []
    let totalArray: any = []
    value.forEach((device: any) => {
        totalArray.push(device.deviceCount)
    })
    const total = totalArray.reduce((accumulator: any, currentValue: any) => accumulator + currentValue);
    value.forEach((device: any) => {
        returnData.push({value: `${device.deviceCategory} (${device.deviceSubtype})`, count: device.deviceCount, percentage: ((100 * device.deviceCount) / total).toFixed(2) })
    })
    return returnData
}

export const determineBarChartCategoriesForDeviceOSVersion = (value: any) => {
    let returnData: any = []
    value.forEach((device: any) => {
        returnData.push(`${device.operatingSystem} (${device.osBuild})`)
    })
    return returnData
}

export const determineBarChartDataForDeviceOSVersion = (value: any) => {
    let returnData: any = []
    value.forEach((device: any) => {
        returnData.push(device.totalCount)
    })
    return returnData
}

export const determineRowDataForDeviceOSVersion = (value: any) => {
    let returnData: any = []
    let totalArray: any = []
    value.forEach((device: any) => {
        totalArray.push(device.totalCount)
    })
    const total = totalArray.reduce((accumulator: any, currentValue: any) => accumulator + currentValue);
    value.forEach((device: any) => {
        const combinedName = `${device.operatingSystem} (${device.osBuild})`
        returnData.push({
            value: combinedName,
            count: device.totalCount,
            percentage: ((100 * device.totalCount) / total).toFixed(2) 
        })
    })
    return returnData
}





