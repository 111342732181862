import * as React from "react";
import * as Mui from "@mui/material";
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { faSquareInfo } from "@fortawesome/pro-light-svg-icons";
import { faSquareCheck, faSquareMinus } from "@fortawesome/pro-solid-svg-icons";
import { SelectChangeEvent } from "@mui/material";
import { UserSummary } from "../../types/account.types";
import { ChangeManagementPlan } from "../../types/changemanagement.types";

interface AddPlanCheckProps {
  plan: ChangeManagementPlan;
  planIndex: number;
  isExpanded: boolean;
  users: UserSummary[];
  onAccordianExpanded: (
    panel: string
  ) => (event: React.SyntheticEvent, isExpanded: boolean) => void;
  onCheckAdded: (
    planIndex: number,
    description: string,
    requiresCheck: boolean,
    requiresEvidence: boolean
  ) => void;
  onCheckRemoved: (planIndex: number, checkIndex: number) => void;
  onPlanRemove: (planIndex: number) => void;
  onUserAssigned: (planIndex: number, userId: string, userName: string) => void;
}

export const AddPlanCheck: React.FC<AddPlanCheckProps> = (props) => {
  const addCheckForm = useForm();

  const onAddPlanCheckSubmit: SubmitHandler<FieldValues> = (data) => {
    var requiresCheck = +data[`checks${props.planIndex}`] > 0;
    var requiresEvidence = +data[`checks${props.planIndex}`] === 2;
    props.onCheckAdded(
      props.planIndex,
      data[`checkdescription${props.planIndex}`],
      requiresCheck,
      requiresEvidence
    );
    addCheckForm.reset();
  };

  const handleUserAssign = (event: SelectChangeEvent) => {
    const selectedUser = props.users.find((obj) => {
      return obj.id === (event.target.value as string);
    });

    if (selectedUser) {
      props.onUserAssigned(
        props.planIndex,
        selectedUser.id,
        `${selectedUser.firstName} ${selectedUser.lastName}`
      );
      //this doesnt seem to have any effect, read that only on click events work but struggling to find a solution.
      event.stopPropagation();
      event.preventDefault();
    }
  };

  return (
    <form>
      <Mui.Accordion
        expanded={props.isExpanded}
        onChange={props.onAccordianExpanded(`panel${props.planIndex}`)}
        sx={{
          borderColor: props.isExpanded ? "orange" : "silver",
          borderStyle: "solid",
          borderWidth: props.isExpanded ? "2px" : "1px",
          mb: 1,
        }}
      >
        <Mui.AccordionSummary
          expandIcon={
            <FontAwesomeIcon
              icon={faChevronRight}
              color={"orange"}
              size="1x"
              type="submit"
            />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{ p: 1 }}
        >
          <Mui.Box
            justifyContent="flex-start"
            alignItems="flex-start"
            component="span"
            display="flex"
            sx={{ width: "100%", verticalAlign: "middle" }}
          >
            <Mui.Typography variant="subtitle2" sx={{ width: "33%", mt: 1 }}>
              {props.isExpanded ? (
                <FontAwesomeIcon
                  icon={faSquareMinus}
                  style={{ color: "orange", marginRight: 5 }}
                  size="lg"
                  onClick={() => props.onPlanRemove(props.planIndex)}
                />
              ) : (
                <></>
              )}
              {props.plan.title}
            </Mui.Typography>
            <Mui.Typography
              variant="subtitle2"
              sx={{ color: "text.secondary", mt: 1 }}
            >
              {props.plan.checks.length === 0
                ? "No Checks"
                : `${props.plan.checks.length} Check(s) in plan`}
            </Mui.Typography>

            <Mui.Typography
              variant="subtitle2"
              sx={{ ml: "auto", mr: 2, mt: 1 }}
            >
              Assigned To :
            </Mui.Typography>
            <Mui.Select
              labelId=""
              id="assignto"
              value={props.plan.assignedToId}
              size="small"
              onChange={handleUserAssign}
              sx={{ mr: 5, minWidth: "150px", maxWidth: "150px" }}
            >
              {props.users.map((user, index) => {
                return (
                  <Mui.MenuItem
                    key={user.id}
                    selected={user.id === props.plan.assignedToId}
                    value={user.id}
                  >
                    {user.firstName} {user.lastName}
                  </Mui.MenuItem>
                );
              })}
            </Mui.Select>
          </Mui.Box>
        </Mui.AccordionSummary>
        <Mui.AccordionDetails>
          <Mui.Box
            justifyContent="flex-start"
            alignItems="flex-start"
            component="span"
            display="flex"
            sx={{ width: "100%" }}
          >
            {/* <Controller
              render={({ field }) => (
                <Mui.RadioGroup {...field} row aria-labelledby="">
                  <Mui.FormControlLabel
                    value="0"
                    control={<Mui.Radio />}
                    label="Information Only"
                  />
                  <Mui.FormControlLabel
                    value="1"
                    control={<Mui.Radio />}
                    label="Manual Check"
                  />
                  <Mui.FormControlLabel value="2" control={<Mui.Radio />} label="Manual Check and provide evidence" /> 
                </Mui.RadioGroup>
              )}
              control={addCheckForm.control}
              name={`checks${props.planIndex}`}
              defaultValue={0}
            /> */}

            <Controller
              render={({ field: { name, value, onChange } }) => (
                <Mui.TextField
                  name={name}
                  value={value}
                  label={"Description"}
                  sx={{ mr: 1, width: "80%" }}
                  size="small"
                  required
                  onChange={onChange}
                  error={addCheckForm.formState.errors[name] !== undefined}
                  helperText={
                    addCheckForm.formState.errors[name] !== undefined
                      ? "Please enter a description for the check"
                      : ""
                  }
                />
              )}
              control={addCheckForm.control}
              name={`checkdescription${props.planIndex}`}
              defaultValue=""
              rules={{ required: { value: true, message: "" } }}
            />
            <Mui.Button
              variant="outlined"
              type="submit"
              onClick={addCheckForm.handleSubmit(onAddPlanCheckSubmit)}
              sx={{ marginLeft: "auto", p: 1 }}
            >
              Add item
            </Mui.Button>
          </Mui.Box>

          {props.plan.checks.length === 0 ? (
            <Mui.Typography sx={{ mt: 2 }}>
              <FontAwesomeIcon
                icon={faCircleInfo}
                color={"orange"}
                size="lg"
                style={{ paddingRight: "5px" }}
              />{" "}
              There are no checks in the current plan. Add checks to help the
              assigned user complete the plan successfully.
            </Mui.Typography>
          ) : (
            <Mui.Stack direction="column" spacing={1} sx={{ mt: 5 }}>
              <Mui.Typography
                sx={{
                  borderBottomStyle: "solid",
                  borderBottomColor: "orange",
                  borderBottomWidth: "1px",
                }}
              >
                Plan Items
              </Mui.Typography>
              {props.plan.checks.map((check, index) => {
                return (
                  <Mui.Stack direction="row">
                    {check.checkRequired ? (
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        color={"orange"}
                        size="lg"
                        style={{ paddingRight: "10px", paddingTop: "3px" }}
                        title="manual check required"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faSquareInfo}
                        color={"orange"}
                        size="lg"
                        style={{ paddingRight: "10px", paddingTop: "3px" }}
                        title="information only"
                      />
                    )}

                    {/* <FontAwesomeIcon icon={faCamera} color={check.evidenceRequired ? 'orange' : '#ccc'} size="lg" style={{paddingRight:'10px', paddingTop:'3px'}} title='evidence needs to be provided for this check' /> */}
                    <Mui.Typography sx={{ width: "40%" }}>
                      {check.description}
                    </Mui.Typography>
                    <Mui.Button
                      variant="text"
                      size="small"
                      sx={{ marginLeft: "auto" }}
                      onClick={() => {
                        props.onCheckRemoved(props.planIndex, index);
                        addCheckForm.reset();
                      }}
                    >
                      Remove
                    </Mui.Button>
                  </Mui.Stack>
                );
              })}
            </Mui.Stack>
          )}
        </Mui.AccordionDetails>
      </Mui.Accordion>
    </form>
  );
};
