import {
    TableHead,
    TableRow,
    TableCell,
    TableSortLabel,
    Box,
} from "@mui/material";
import { Order } from "../../../common/TableUtilities";
import { visuallyHidden } from "@mui/utils";
import { ChangeManagementItemSummary } from "../../../types/changemanagement.types";
interface EnhancedTableProps {
    onRequestSort: (
        event: React.MouseEvent<unknown>,
        property: keyof ChangeManagementItemSummary
    ) => void;

    order: Order;
    orderBy: string;
    rowCount: number;
}

export function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
        (property: keyof ChangeManagementItemSummary) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };


    return (
        <>
            <TableHead>
                <TableRow>
                    <TableCell align="center" colSpan={6} >

                    </TableCell>
                    <TableCell align="center" colSpan={3} sx={{ borderRight: '1px solid orange', borderLeft: '1px solid orange', fontWeight: "bold" }}>
                        Milestones
                    </TableCell>
                    <TableCell align="center" colSpan={4} sx={{ borderRight: '1px solid orange', fontWeight: "bold" }}>
                        Plans
                    </TableCell>
                    <TableCell align="center" colSpan={2}>
                    </TableCell>
                </TableRow>
                <TableRow>
                     {/* NUMBER */}
                    <TableCell
                        key={'changeManagementNumber'}
                        align={"left"}
                        padding={"none"}
                        sx={{ paddingLeft: '15px' }}
                        sortDirection={orderBy === 'changeManagementNumber' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'changeManagementNumber'}
                            direction={orderBy === 'changeManagementNumber' ? order : "asc"}
                            onClick={createSortHandler('changeManagementNumber')}
                        >
                            {'CM Number'}
                            {orderBy === 'changeManagementNumber' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* TITLE */}
                    <TableCell
                        key={'title'}
                        align={"left"}
                        padding={"none"}
                        sx={{ paddingLeft: '15px' }}
                        sortDirection={orderBy === 'title' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'title'}
                            direction={orderBy === 'title' ? order : "asc"}
                            onClick={createSortHandler('title')}
                        >
                            {'CM Title'}
                            {orderBy === 'title' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* PROGRESS */}
                    <TableCell
                        key={'progress'}
                        align={"center"}
                        sx={{ fontWeight: 'bold' }}
                        padding={"normal"}
                    > 
                    Progress
                    </TableCell>
                    {/* CREATED ON */}
                    <TableCell
                        key={'createdDate'}
                        align={"center"}
                        padding={"none"}
                        sortDirection={orderBy === 'createdDate' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'createdDate'}
                            direction={orderBy === 'createdDate' ? order : "asc"}
                            onClick={createSortHandler('createdDate')}
                        >
                            {'Created On'}
                            {orderBy === 'createdDate' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* LAST MODIFIED */}
                    <TableCell
                        key={'lastModifiedDate'}
                        align={"center"}
                        padding={"none"}
                        sortDirection={orderBy === 'lastModifiedDate' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'lastModifiedDate'}
                            direction={orderBy === 'lastModifiedDate' ? order : "asc"}
                            onClick={createSortHandler('lastModifiedDate')}
                        >
                            {'Last Modified'}
                            {orderBy === 'lastModifiedDate' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* STATUS */}
                    <TableCell
                        key={'status'}
                        align={"center"}
                        padding={"none"}
                        sortDirection={orderBy === 'status' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'status'}
                            direction={orderBy === 'status' ? order : "asc"}
                            onClick={createSortHandler('status')}
                        >
                            {'Status'}
                            {orderBy === 'status' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* TOTAL */}
                    <TableCell
                        key={'totalMilestones'}
                        align={"center"}
                        padding={"none"}
                        sx={{ borderLeft: '1px solid orange', paddingLeft: '10px' }}
                        sortDirection={orderBy === 'totalMilestones' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'totalMilestones'}
                            direction={orderBy === 'totalMilestones' ? order : "asc"}
                            onClick={createSortHandler('totalMilestones')}
                        >
                            {'Total'}
                            {orderBy === 'totalMilestones' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* COMPLETE */}
                    <TableCell
                        key={'completeMilestones'}
                        align={"center"}
                        padding={"none"}
                        sortDirection={orderBy === 'completeMilestones' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'completeMilestones'}
                            direction={orderBy === 'completeMilestones' ? order : "asc"}
                            onClick={createSortHandler('completeMilestones')}
                        >
                            {'Complete'}
                            {orderBy === 'completeMilestones' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* OVERDUE */}
                    <TableCell
                        key={'overdueMilestones'}
                        align={"center"}
                        padding={"none"}
                        sx={{ borderRight: '1px solid orange' }}
                        sortDirection={orderBy === 'overdueMilestones' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold", color: 'red' }}
                            active={orderBy === 'overdueMilestones'}
                            direction={orderBy === 'overdueMilestones' ? order : "asc"}
                            onClick={createSortHandler('overdueMilestones')}
                        >
                            {'Overdue'}
                            {orderBy === 'overdueMilestones' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* TOTAL */}
                    <TableCell
                        key={'totalPlans'}
                        align={"center"}
                        padding={"none"}
                        sx={{ paddingLeft: '10px' }}
                        sortDirection={orderBy === 'totalPlans' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'totalPlans'}
                            direction={orderBy === 'totalPlans' ? order : "asc"}
                            onClick={createSortHandler('totalPlans')}
                        >
                            {'Total'}
                            {orderBy === 'totalPlans' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* NOT STARTED */}
                    <TableCell
                        key={'notStartedPlans'}
                        align={"center"}
                        padding={"none"}
                        sortDirection={orderBy === 'notStartedPlans' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'notStartedPlans'}
                            direction={orderBy === 'notStartedPlans' ? order : "asc"}
                            onClick={createSortHandler('notStartedPlans')}
                        >
                            {'Not Started'}
                            {orderBy === 'notStartedPlans' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* IN PROGRESS */}
                    <TableCell
                        key={'inProgressPlans'}
                        align={"center"}
                        padding={"none"}
                        sortDirection={orderBy === 'inProgressPlans' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'inProgressPlans'}
                            direction={orderBy === 'inProgressPlans' ? order : "asc"}
                            onClick={createSortHandler('inProgressPlans')}
                        >
                            {'In Progress'}
                            {orderBy === 'inProgressPlans' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* COMPLETE */}
                    <TableCell
                        key={'completePlans'}
                        align={"center"}
                        padding={"none"}
                        sx={{ borderRight: '1px solid orange' }}
                        sortDirection={orderBy === 'completePlans' ? order : false}
                    >
                        <TableSortLabel
                            sx={{ fontWeight: "bold" }}
                            active={orderBy === 'completePlans'}
                            direction={orderBy === 'completePlans' ? order : "asc"}
                            onClick={createSortHandler('completePlans')}
                        >
                            {'Complete'}
                            {orderBy === 'completePlans' ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === "desc"
                                        ? "sorted descending"
                                        : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                    {/* VIEW TICKET */}
                    <TableCell>
                    </TableCell>
                    {/* VIEW CHANGE MANAGEMENT */}
                    <TableCell>
                    </TableCell>
                </TableRow>
            </TableHead>
        </>
    );
}
