import {
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TableContainer,
    Table,
    Typography,
    Button,
    Stack,
    Modal,
    Box,
    IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { getComparator, Order, stableSort } from "../../common/TableUtilities";
import Card from "@mui/material/Card";
import { DeleteRunAsAccount, RunAsAccounts, RunAsAccountsWithProfile } from "../../types/devices.types";
import { EnhancedTableHead } from "./RunAsAccountsEnhancedTableHead";
import { FormattedDateTimeMilitary } from "../../common/dateUtils";
import { EditRunAsAccountsForm } from "./EditRunAsAccountsForm";
import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteRunAsAccount } from "../../redux/Thunks/devices";
import { useIdentityContext } from "../../context/IdentityContext";
import { getRunAsProfileAndDevices } from "../../redux/Slices/devices.slice";
import { addProfilesToAccounts } from "./RunAsProfilesFiltering";
interface RunAsAccountsEnhancedTableProps {
    runAsAccounts: RunAsAccounts[];
}

export function RunAsAccountsEnhancedTable(
    props: RunAsAccountsEnhancedTableProps
) {
    return <EnhancedTable runAsAccounts={props.runAsAccounts}></EnhancedTable>;
}

export default function EnhancedTable(props: {
    runAsAccounts: RunAsAccounts[];
}) {
    const { runAsAccounts } = props;
    const dispatch = useAppDispatch();
    const profileAndDevices = useAppSelector(getRunAsProfileAndDevices);

    const accountsArrayWithProfiles = addProfilesToAccounts(runAsAccounts, profileAndDevices)
    const [rows, setRows] = useState(accountsArrayWithProfiles);
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<keyof RunAsAccountsWithProfile>("runAsAccountId");
    const [page, setPage] = useState(0);
    const [open, setOpen] = useState(false);
    const [accountToEdit, setAccountToEdit] = useState({})
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
    const [accountToDelete, setAccountToDelete] = useState({} as any)
    const rowsPerPage = 25;
    const runAsAccountsTotal = runAsAccounts.length;
    const identity = useIdentityContext();


    const handleRequestSort = (
        _event: React.MouseEvent<unknown>,
        property: keyof RunAsAccountsWithProfile
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        setRows(accountsArrayWithProfiles);
    }, [runAsAccounts]);

    const handleOpenEditRunAsAccountForm = () => setOpen(true);
    const handleCloseEditRunAsAccountForm = () => setOpen(false);

    const handleDeleteRunAsAccount = async () => {

        const payload: DeleteRunAsAccount = {
            userName: accountToDelete.userName,
            domain: accountToDelete.domain,
            runAsAccountTypeId: accountToDelete.runAsAccountTypeId,
            companyId: accountToDelete.companyId,
        };
        await dispatch(deleteRunAsAccount(payload));
    }
    return (
        <>
            {/* EDIT MODAL */}
            <Modal
                open={open}
                onClose={handleCloseEditRunAsAccountForm}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box>
                    <EditRunAsAccountsForm accountToEdit={accountToEdit} onClose={handleCloseEditRunAsAccountForm} />
                </Box>
            </Modal>
            {/* DELETE DIALOG */}
            <ConfirmationDialog
                title="Are you sure you want to delete this Account?"
                open={openDeleteDialog}
                onConfirm={() => {
                    handleDeleteRunAsAccount()
                    setOpenDeleteDialog(false);

                }}
                onCancel={() => {
                    setOpenDeleteDialog(false);
                }}
            />
            {/* TABLE CARD */}
            <Card>
                <TableContainer
                    sx={{
                        borderStyle: "solid",
                        borderColor: "#ccc",
                        borderWidth: "1px",
                    }}
                >
                    <Table
                        stickyHeader
                        sx={{ minWidth: 650 }}
                        size="small"
                        aria-label="alerts"
                    >
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {stableSort(rows as any, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, _index) => {
                                    return (
                                        <TableRow tabIndex={-1} key={row.runAsAccountId}>
                                            <TableCell align="left">
                                                <Typography>
                                                    {row.runAsAccountId}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.userName}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.domain}
                                            </TableCell>
                                            {/* <TableCell align="left">
                                                    {row.runAsAccountTypeId}

                                                </TableCell> */}
                                            {/* <TableCell align="left">
                                                    {row.deviceGroup}
                                                </TableCell> */}
                                            <TableCell align="left">
                                                {FormattedDateTimeMilitary(new Date(row.created))}
                                            </TableCell>
                                            <TableCell align="left">
                                                {row.runAsProfileDisplayName ? row.runAsProfileDisplayName : ''}
                                            </TableCell>

                                            <TableCell align="center">
                                                <Stack
                                                    spacing={1}
                                                >
                                                    <Button
                                                        variant="outlined"
                                                        onClick={() => {
                                                            handleOpenEditRunAsAccountForm()
                                                            setAccountToEdit(row)
                                                        }}
                                                    // disabled={row.userName === user!.emailAddress ? false : true}
                                                    >
                                                        Edit
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Stack
                                                    spacing={1}
                                                >
                                                    <IconButton onClick={() => {
                                                        setAccountToDelete(row)
                                                        setOpenDeleteDialog(true)
                                                    }}  >
                                                        <FontAwesomeIcon
                                                            icon={faTrashCan}
                                                            color={"#f8931c"}
                                                            size="xs"
                                                        />
                                                    </IconButton>
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[rowsPerPage]}
                        component="div"
                        count={runAsAccountsTotal}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                    />
                </TableContainer>
            </Card>
        </>
    );
}
