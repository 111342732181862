
import { Card, Typography } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useState } from "react";
import { CreateSentinelConfig } from "./CreateSentinelConfig";
import { UpdateSentinelConfig } from "./UpdateSentinelConfig";
import { NotifyBannerSmall } from "../NotificationBannerSmall";
import { DeleteSentinelConfig } from "./DeleteSentinelConfig";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    height: '55vh',
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

interface SentinelConfigFormModalProps {
    sentinelConfig: any;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const SentinelConfigFormModal = (props: SentinelConfigFormModalProps) => {
    const { sentinelConfig } = props
    const [value, setValue] = useState(0);
    const [showCreateBanner, setShowCreateBanner] = useState<boolean>(false)
    const [showError, setShowError] = useState<boolean>(false)
    const [showUpdateBanner, setShowUpdateBanner] = useState<boolean>(false)
    const [showDeleteBanner, setShowDeleteBanner] = useState<boolean>(false)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return (
        <>
            {showCreateBanner === true ? (
                <NotifyBannerSmall
                    secondsDuration={10}
                    message="New Sentinel Configuration Id has been copied to your clipboard."
                    showNotification={showCreateBanner}
                    createdId={''}
                    navigatePage=''
                    linkText=''
                    severity='success'
                />
            ) : (
                <></>
            )}
              {showError === true ? (
                <NotifyBannerSmall
                    secondsDuration={10}
                    message="There was an error validating your configuration."
                    showNotification={showError}
                    createdId={''}
                    navigatePage=''
                    linkText=''
                    severity='error'
                />
            ) : (
                <></>
            )}
              {showUpdateBanner === true ? (
                <NotifyBannerSmall
                    secondsDuration={10}
                    message="Your Sentinel Configuration has been updated."
                    showNotification={showUpdateBanner}
                    createdId={''}
                    navigatePage=''
                    linkText=''
                    severity='success'
                />
            ) : (
                <></>
            )}
                  {showDeleteBanner === true ? (
                <NotifyBannerSmall
                    secondsDuration={10}
                    message="Your Sentinel Configuration has been deleted."
                    showNotification={showDeleteBanner}
                    createdId={''}
                    navigatePage=''
                    linkText=''
                    severity='success'
                />
            ) : (
                <></>
            )}
            <Card sx={style}>
                <Typography variant="h3" component="h3">
                    Sentinel Configuration
                </Typography>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Create New" {...a11yProps(0)} />
                        <Tab label="Update Existing" {...a11yProps(1)} />
                        <Tab label="Delete" {...a11yProps(2)} />

                    </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                    <CreateSentinelConfig
                        setShowCreateBanner={setShowCreateBanner}
                        setShowError={setShowError}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <UpdateSentinelConfig
                        sentinelConfig={sentinelConfig}
                        setShowUpdateBanner={setShowUpdateBanner}
                        setShowError={setShowError}
                    />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                    <DeleteSentinelConfig
                        sentinelConfig={sentinelConfig}
                        setShowDeleteBanner={setShowDeleteBanner}
                    />
                </CustomTabPanel>
            </Card>
        </>
    );
}









