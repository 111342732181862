import { faCircleMinus, faCirclePlus, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Typography, CardContent, Accordion, AccordionSummary, AccordionDetails, Grid, Divider, CardActions, FormGroup, Button, InputLabel, Box, TextField, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { getUsers } from "../../redux/Slices/account.slice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ChangeManagementItemSummary, ChangeManagementCreateOrEditCommand } from "../../types/changemanagement.types";
import { getPlans } from "../../redux/Slices/changeManagement.slice";
import { createOrEditChangeManagementItem, fetchMilestonesForChangeManagementItem } from "../../redux/Thunks/changeManagement";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { cardStyle, formatPlans, } from "./ChangeManagementUtils";
import { useIdentityContext } from "../../context/IdentityContext";
import { MilestoneTasks } from "./MilestoneTasks";
import RGL, { WidthProvider } from "react-grid-layout";
const ReactGridLayout = WidthProvider(RGL);



interface EditMilestoneFormProps {
    onClose: () => void;
    milestones: any[];
    changeManagementItem: ChangeManagementItemSummary;
}


export const EditMilestoneForm = (props: EditMilestoneFormProps) => {
    const { onClose, milestones, changeManagementItem } = props
    const [formValues, setformValues] = useState({
        milestones: milestones
    });
    const dispatch = useAppDispatch();
    useEffect(() => {
        setExpand(Array(formValues.milestones.length).fill(false))
    }, [formValues.milestones.length]);
    const [expand, setExpand] = useState(Array(formValues.milestones.length).fill(false))
    const plans = useAppSelector(getPlans);

    const users = useAppSelector(getUsers);
    const identityContext = useIdentityContext();
    const user = identityContext.authenticatedUser;

    const engineers = users.filter((user: { roles: string | string[]; }) => user.roles.includes("Engineer")).map((user: { firstName: string; lastName: string; id: any; }) => {
        return (
            {
                fullName: user.firstName + " " + user.lastName,
                id: user.id
            }
        )
    });

    const determineDefaultLayout = () => {
        let returnValue: any = []
        formValues.milestones.forEach((milestone: any, index: number) => {
            returnValue[index] = { 'milestone': milestone.title, 'layout': [] }
            milestone.tasks.forEach((task: any, idx: number) => {
                returnValue[index].layout.push(
                    {
                        w: 12,
                        h: 1,
                        x: 0,
                        y: idx,
                        i: task.id,
                    }
                )
            })
        })
        return returnValue
    }
    const defaultLayout = determineDefaultLayout()
    const onExpand = (index: number) => {
        setExpand(prevState => prevState.map((item, idx) => idx === index ? !item : item))
    }

    const handleSubmit = async () => {
        const payload: ChangeManagementCreateOrEditCommand = {
            id: changeManagementItem.id,
            companyId: changeManagementItem.companyId,
            ticketId: changeManagementItem.ticketId,
            title: changeManagementItem.title,
            description: changeManagementItem.description,
            assignedEngineerId: changeManagementItem.assignedEngineerId,
            assignedEngineerName: changeManagementItem.assignedEngineerName,
            plans: formatPlans(plans),
            milestones: formValues.milestones,
            requestApproval: true,
        }
        const response = await dispatch(createOrEditChangeManagementItem({command: payload}));
        if (response.payload) {
            await dispatch(
                fetchMilestonesForChangeManagementItem(response.payload.id)
            );
        }
        onClose()
    }

    const handleChangeTitle = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))

        const insertValue = {
            id: formValues.milestones[index].id,
            changeManagementItemId: formValues.milestones[index].changeManagementItemId,
            assignedToId: formValues.milestones[index].assignedToId,
            assignedToName: formValues.milestones[index].assignedToName,
            title: e.target.value,
            dueDate: formValues.milestones[index].dueDate,
            tasks: formValues.milestones[index].tasks,
        }
        clonedArray.splice(index, 1, insertValue)
        setformValues({ milestones: clonedArray })

    }
    const handleChangeAssignedTo = (e: SelectChangeEvent<any>, index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))
        const assignedEngineer = engineers.find((el: { fullName: any; }) => el.fullName === e.target.value)
        const insertValue = {
            id: formValues.milestones[index].id,
            changeManagementItemId: formValues.milestones[index].changeManagementItemId,
            assignedToId: assignedEngineer?.id,
            assignedToName: assignedEngineer?.fullName,
            title: formValues.milestones[index].title,
            dueDate: formValues.milestones[index].dueDate,
            tasks: formValues.milestones[index].tasks,
        }
        clonedArray.splice(index, 1, insertValue)
        setformValues({ milestones: clonedArray })
    }
    const handleChangeDescription = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number, idx: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))
        const insertValue = {
            id: formValues.milestones[index].tasks[idx].id,
            description: e.target.value,
            checkPerformedOn: formValues.milestones[index].tasks[idx].checkPerformedOn
        }
        clonedArray[index].tasks.splice(idx, 1, insertValue)
        setformValues({ milestones: clonedArray })
    }
    const handleChangeDatePicker = (value: any, index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))
        const insertValue = {
            id: formValues.milestones[index].id,
            changeManagementItemId: formValues.milestones[index].changeManagementItemId,
            assignedToId: formValues.milestones[index].assignedToId,
            assignedToName: formValues.milestones[index].assignedToName,
            title: formValues.milestones[index].title,
            completeDate: null,
            dueDate: value,
            tasks: formValues.milestones[index].tasks,
        }
        clonedArray.splice(index, 1, insertValue)
        setformValues({ milestones: clonedArray })
    }

    const onAddTask = (index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))
        const insertPosition = clonedArray[index].tasks.length
        const insertValue = {
            id: crypto.randomUUID(),
            description: 'New Task',
            checkPerformedOn: null
        }
        clonedArray[index].tasks.splice(insertPosition, 0, insertValue)
        setformValues({ milestones: clonedArray })
    }
    const onDeleteTask = (idx: number, index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))
        clonedArray[index].tasks.splice(idx, 1)
        setformValues({ milestones: clonedArray })
    }
    const onAddMilestone = () => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))
        const insertPosition = clonedArray.length
        const insertValue = {
            id: crypto.randomUUID(),
            changeManagementItemId: changeManagementItem.id,
            assignedToId: user.userId,
            assignedToName: user.fullName,
            title: 'New Milestone',
            dueDate: dayjs(new Date()).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            tasks: [],
        }
        clonedArray.splice(insertPosition, 0, insertValue)
        setformValues({ milestones: clonedArray })
    }

    const onDeleteMilestone = (index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))
        clonedArray.splice(index, 1)
        setformValues({ milestones: clonedArray })
    }

    const handleNewLayout = (newLayout: RGL.Layout[], index: number) => {
        let clonedArray = JSON.parse(JSON.stringify(formValues.milestones))
        let newTaskOrder: any = []
        newLayout.forEach((item: any) => {
            newTaskOrder.push({
                id: item.i,
                description: (clonedArray[index].tasks.find((el: { id: any; }) => el.id === item.i)).description,
                checkPerformedOn: (clonedArray[index].tasks.find((el: { id: any; }) => el.id === item.i)).checkPerformedOn,
                orderBy: item.y,
            })

        })
        newTaskOrder.sort((a: { orderBy: number; }, b: { orderBy: number; }) => a.orderBy - b.orderBy)
        clonedArray[index].tasks = newTaskOrder
        setformValues({ milestones: clonedArray })
    };
    return (

        <Card sx={cardStyle}>
            <Typography variant="h3" component="h3">
                Edit Milestones
            </Typography>
            <CardContent>
                {formValues.milestones.map((milestone, index: number) => {
                    return (
                        <>
                            <Accordion expanded={expand[index]}>
                                <AccordionSummary
                                    expandIcon={expand[index] ?
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={faCircleMinus}
                                            color={"#f8931c"}
                                            style={{ width: "20px", textAlign: "center" }}
                                            onClick={() => onExpand(index)}
                                        /> : <FontAwesomeIcon
                                            size="lg"
                                            icon={faCirclePlus}
                                            color={"#f8931c"}
                                            style={{ width: "20px", textAlign: "center" }}
                                            onClick={() => onExpand(index)}
                                        />
                                    }
                                >
                                    <Typography sx={{ marginLeft: '10px', fontWeight: 'bold' }}>Milestone {index + 1}: {milestone.title}</Typography>
                                    {expand[index] ?
                                        <Tooltip title="Delete Milestone">
                                            <FontAwesomeIcon
                                                icon={faTrashCan}
                                                color={"#f8931c"}
                                                size="lg"
                                                style={{ position: 'absolute', right: 50 }}
                                                onClick={() => onDeleteMilestone(index)}
                                            />
                                        </Tooltip>
                                        : <></>}
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Card sx={{ padding: '10px' }}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={6}>
                                                <Box>


                                                    {/* TITLE */}
                                                    <InputLabel id="demo-multiple-checkbox-label">
                                                        Title
                                                    </InputLabel>
                                                    <TextField
                                                        required
                                                        id="title"
                                                        sx={{ width: "100%", mr: 1, marginBottom: "20px" }}
                                                        size="small"
                                                        value={formValues.milestones[index].title}
                                                        onChange={(e) => {
                                                            handleChangeTitle(e, index)
                                                        }}
                                                    />
                                                </Box>
                                            </Grid>

                                            <Grid item xs={3}>
                                                {/* ASSIGNED TO */}
                                                <Box>


                                                    <InputLabel id="demo-multiple-checkbox-label">
                                                        Assigned To
                                                    </InputLabel>
                                                    <Select
                                                        size="small"
                                                        required
                                                        sx={{ width: "100%", mb: 1 }}
                                                        value={formValues.milestones[index].assignedToName}
                                                        onChange={(e) => handleChangeAssignedTo(e, index)}
                                                    >
                                                        {engineers.map((engineer: any, index: number) => {
                                                            return (
                                                                <MenuItem key={index} value={engineer.fullName}>
                                                                    {engineer.fullName}
                                                                </MenuItem>
                                                            );
                                                        })}
                                                    </Select>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={3}>
                                                {/* DATE PICKER */}
                                                <Box>
                                                    <InputLabel id="demo-multiple-checkbox-label">
                                                        Due Date
                                                    </InputLabel>

                                                    <DatePicker
                                                        value={formValues.milestones[index].dueDate}
                                                        onChange={(value): void => {
                                                            handleChangeDatePicker(value, index)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                size="small"
                                                                {...params}
                                                            />
                                                        )}
                                                    />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider />
                                        <Typography variant="subtitle2">
                                            Drag and Drop Tasks to change order.
                                        </Typography>
                                        <ReactGridLayout
                                            layout={defaultLayout[index].layout}
                                            onLayoutChange={(newLayout) => handleNewLayout(newLayout, index)}
                                            cols={1}
                                            rowHeight={200}
                                            isResizable={false}
                                            draggableCancel=".cancelSelectorName"
                                        >
                                        {milestone.tasks.map((task: any, idx: number) => {
                                            return (
                                                <Grid item key={task.id}>

                                                <MilestoneTasks
                                                    idx={idx}
                                                    index={index}
                                                    formValues={formValues}
                                                    onDeleteTask={onDeleteTask}
                                                    handleChangeDescription={handleChangeDescription}
                                                />
                                            </Grid>
                                            
                                            )
                                        })}
                                        </ReactGridLayout>

                                        <Button variant="outlined" color="primary" onClick={() => onAddTask(index)}>Add Task</Button>

                                    </Card>
                                </AccordionDetails>
                            </Accordion>

                        </>
                    );
                })}

            </CardContent>
            <CardActions sx={{ justifyContent: "flex-end", pr: 2 }}>
                <FormGroup row>
                    <Button variant="outlined" color="primary" sx={{ position: 'absolute', left: 50 }} onClick={() => onAddMilestone()}>
                        Add Milestone
                    </Button>
                    <Button variant="outlined" color="primary" type="submit" onClick={handleSubmit}>
                        Submit Changes
                    </Button>
                </FormGroup>
            </CardActions>
        </Card>

    )
}


