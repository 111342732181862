import { Typography, Card, CardContent, FormControl, Grid, IconButton, InputLabel, OutlinedInput, Autocomplete, TextField, FormControlLabel, FormLabel, Radio, RadioGroup, Button, ButtonGroup } from "@mui/material";
import { getModalStyles } from "../CreateTicket/CreateTicketUtilities";
import { useIdentityContext } from "../../../context/IdentityContext";
import { TicketPriorities, TicketProblemTypes } from "../../../redux/Slices/tickets.slice";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchAllTickets, raiseSupportTicketGeneric } from "../../../redux/Thunks/tickets";
import { useEffect, useState } from "react";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const style = getModalStyles();
interface CreateTicketFormProps {
  onClose: () => void;
}
export const CreateTicketForm = (props: CreateTicketFormProps) => {
  const [formValues, setFormValues] = useState({ title: '', problemCategory: '', description: '', priority: '' })
  const [ticketProblem, setTicketProblems] = useState<any>([])
  const identity = useIdentityContext();
  const dispatch = useAppDispatch();
  const onHandleCreate = async () => {
    const payload = {
      priority: +formValues.priority,
      problemCategory: formValues.problemCategory,
      title: formValues.title,
      description: formValues.description,
    };
    await dispatch(raiseSupportTicketGeneric({ command: payload, identity }));
    await dispatch(fetchAllTickets());
    props.onClose();
  }

  const determineTicketProblemTypes = (ticketProblemTypes: any) => {
    let problemArray: any[] = []
    ticketProblemTypes.map((problem: any) => {
      return (
        problemArray.push(problem.description)
      )
    })
    return problemArray
  }
  useEffect(() => {
    const problemsArray = determineTicketProblemTypes(TicketProblemTypes)
    setTicketProblems(problemsArray)
  }, [TicketProblemTypes]);

  const handleCriteriaChange = (value: any, key: string) => {
    let clone: any = structuredClone(formValues);
    setFormValues(clone)
    clone[key] = value
    setFormValues(clone)
  }

  const onDelete = (key: string) => {
    let clone: any = structuredClone(formValues);
    clone[key] = '';
    setFormValues(clone)
  }

  const onHandleClearAll = () => {
    setFormValues({ title: '', problemCategory: '', description: '', priority: '' })
  }
  return (
    <Card sx={style}>
      <Typography variant="h3" component="h3">
        Submit New Ticket
      </Typography>
      <CardContent>
        <Grid container spacing={0}>
          {/* TITLE */}
          <Grid item xs={6} sx={{ mb: 2, pr: 2, display: "flex", justifyContent: 'space-between' }}>
            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
              <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Title'>Title</InputLabel>
              <OutlinedInput
                label='Title'
                sx={{ display: 'flex', flexGrow: 1 }}
                id="title"
                size="small"
                value={formValues['title']}
                onChange={(event) =>
                  handleCriteriaChange(event.target.value, 'title')
                }
                endAdornment={
                  <IconButton
                    onClick={() => onDelete('title')}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      color={"#f8931c"}
                      size="xs"
                    />
                  </IconButton>
                }
              />
            </FormControl>
          </Grid>
          {/* PROBLEM */}
          <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <FormControl sx={{ width: '100%', m: 0 }} variant="outlined" size='small'>
              <Autocomplete
                id="problemCategory"
                options={ticketProblem}
                size="small"
                sx={{ display: 'flex', flexGrow: 1 }}
                onChange={(event: any, newValue: string | null) => {
                  handleCriteriaChange(newValue, 'problemCategory')
                }}
                value={formValues['problemCategory']}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      display: 'flex',
                      flexGrow: 1,
                      "& .MuiSvgIcon-root": {
                        color: "rgb(250,165,66)",
                      },
                      label: { fontWeight: 'bold' }
                    }}
                    label='Problem Category'
                    value={formValues['problemCategory']}
                    onChange={(event) =>
                      handleCriteriaChange(event.target.value, 'applicationProtocol')
                    }
                  />
                )}
              />
            </FormControl>
          </Grid>
          {/* DESCRIPTION */}
          <Grid item xs={12} sx={{ mb: 2, display: "flex", justifyContent: 'space-between' }}>
            <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
              <InputLabel sx={{ fontWeight: 'bold' }} htmlFor='Description'>Description</InputLabel>
              <OutlinedInput
                multiline={true}
                rows={4}
                label='Description'
                sx={{ display: 'flex', flexGrow: 1 }}
                id="description"
                size="small"
                value={formValues['description']}
                onChange={(event) =>
                  handleCriteriaChange(event.target.value, 'description')
                }
                endAdornment={
                  <IconButton
                    onClick={() => onDelete('description')}
                  >
                    <FontAwesomeIcon
                      icon={faXmark}
                      color={"#f8931c"}
                      size="xs"
                    />
                  </IconButton>
                }
              />
            </FormControl>
          </Grid>
          {/* PRIORITY */}
          <Grid item xs={6} sx={{ display: "flex", justifyContent: 'space-between' }}>
            <FormControl>
              <FormLabel id="priority" sx={{ fontWeight: 'bold' }}>Priority</FormLabel>
              <RadioGroup
                row
                aria-labelledby="priority"
                name="priority"
                value={formValues['priority']}
                onChange={(event) =>
                  handleCriteriaChange(event.target.value, 'priority')
                }
              >
                {TicketPriorities.map((item: any, index: number) => {
                  return (
                    <FormControlLabel
                      control={<Radio key={item.id} value={item.id} />}
                      label={`${item.description} Priority`}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>
          </Grid>
          {/* BUTTONS */}
          <Grid item xs={6} sx={{ display: "flex", justifyContent: 'flex-end' }}>
            <ButtonGroup sx={{ pb: 2 }}>
              <Button
                sx={{ mt: 3 }}
                variant="outlined"
                color="primary"
                type="submit"
                onClick={onHandleClearAll}
              >
                Clear All
              </Button>
              <Button
                sx={{ mt: 3 }}

                variant="outlined"
                color="primary"
                type="submit"
                onClick={onHandleCreate}
              >
                Create
              </Button>
            </ButtonGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
