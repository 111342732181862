

import * as React from 'react';

import { useState } from 'react';
import dayjs from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Box, Button, Grid, Modal, TextField, Tooltip } from '@mui/material';
import { useAppDispatch } from '../../redux/hooks';
import { fetchInvestigateDevice } from '../../redux/Thunks/alerts';
import { Loader } from '../../components/Loader';



interface StartTimeEndTimeProps {
    investigatedDevice: any;
    currentDevice: any;
}


export const StartTimeEndTime = (props: StartTimeEndTimeProps) => {
    const { investigatedDevice, currentDevice } = props
    const [startTime, setStartTime] = useState(dayjs(investigatedDevice.investigationStartTime));
    const [endTime, setEndTime] = useState(dayjs(investigatedDevice.investigationEndTime));
    const [disableButton, setDisableButton] = useState(true)
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch();

    const onChangeStartTime = (e: dayjs.Dayjs | null) => {
        setStartTime(dayjs(e))
        setDisableButton(false)
    }
    const onChangeEndTime = (e: dayjs.Dayjs | null) => {
        setEndTime(dayjs(e))
        setDisableButton(false)
    }
    const onClickFilterButton = async () => {
        setLoading(true)
        const timeDifference = endTime.diff(startTime, 'minute')
        const command = `${currentDevice?.id}/${currentDevice?.deviceGroup}?timeSpan=${timeDifference}`
        const response = await dispatch(fetchInvestigateDevice(command));
        if(response.payload){
            setLoading(false)
        }
    }
    return (
        <Box>
            <Modal open={loading}>
                <Loader show={true} />
            </Modal>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <strong >Start Time: </strong>
                    <DateTimePicker
                        value={startTime}
                        onChange={(e) => onChangeStartTime(e)}
                        renderInput={(params) => (
                            <TextField
                                size="small"
                                {...params}
                            />
                        )}
                    />
                    <span>&nbsp;&nbsp;</span>
                    <strong>End Time: </strong>
                    <DateTimePicker
                        value={endTime}
                        onChange={(e) => onChangeEndTime(e)}
                        renderInput={(params) => (
                            <TextField
                                size="small"
                                {...params}
                            />
                        )}
                    />
                    <Tooltip title="Adjust start or end time to enable">
                        <span>
                            <Button
                                variant='outlined'
                                disabled={disableButton}
                                onClick={onClickFilterButton}
                                sx={{ margin: '5px 0 0 20px' }}>
                                Investigate New Time Range
                            </Button>
                        </span>
                    </Tooltip>
                </Grid>
            </Grid>
        </Box>

    )
}