import * as React from "react";
// import * as Mui from "@mui/material";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { useNavigate } from "react-router-dom";
// import { FormattedDateTime } from "../../common/dateUtils";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faClock, faUser } from "@fortawesome/pro-light-svg-icons";
import { useIdentityContext } from "../../context/IdentityContext";

interface PageAppBarLink {
  href: string;
  text: string;
}

export interface PageAppBarProps {
  breadcrumbPageTitle: string;
  mainPageTitle: string;
  mainPageIcon: IconDefinition;
  links: PageAppBarLink[];
  children?: React.ReactNode;
}

export const PageAppBar: React.FC<PageAppBarProps> = (props) => {
  let identity = useIdentityContext();
  let navigate = useNavigate();

  const getWelcomeMessage = () => {
    if (
      identity.authenticatedUser.fullName &&
      identity.authenticatedUser.fullName.split(" ")[0]
    ) {
      return `Welcome ${identity.authenticatedUser.fullName.split(" ")[0]}`;
    } else {
      return `Welcome`;
    }
  };

  return (
    <></>
    // <section
    //   id="portal-secondary-header"
    //   style={{
    //     backgroundColor: "#193754",
    //     height: "auto",
    //     minHeight: "none",
    //     paddingTop: "10px",
    //     paddingBottom: "10px",
    //     paddingLeft: "16px",
    //     paddingRight: "16px",
    //     color: "white",
    //   }}
    // >
    //   <Mui.Grid container spacing={2}>
    //     <Mui.Grid item xs={12} sx={{ mt: 0 }}>
    //       <Mui.Stack direction="row" spacing={1}>
    //         <Mui.Breadcrumbs
    //           aria-label="breadcrumb"
    //           sx={{ marginRight: "auto" }}
    //         >
    //           {props.links.map((item, index) => {
    //             return (
    //               <Mui.Box
    //                 key={`link${index}`}
    //                 component="span"
    //                 sx={{ color: "lightgrey", cursor: "pointer" }}
    //                 onClick={() => {
    //                   navigate(item.href);
    //                 }}
    //               >
    //                 <Mui.Typography sx={{}}>{item.text}</Mui.Typography>
    //               </Mui.Box>
    //             );
    //           })}

    //           <Mui.Typography sx={{ color: "white" }}>
    //             {props.breadcrumbPageTitle}
    //           </Mui.Typography>
    //         </Mui.Breadcrumbs>
    //         <div
    //           style={{
    //             display: "inline-block",
    //             color: "lightgrey",
    //             marginLeft: "16px",
    //           }}
    //         >
    //           <Mui.Typography>
    //             <FontAwesomeIcon
    //               style={{ marginRight: "8px" }}
    //               icon={faClock}
    //             ></FontAwesomeIcon>
    //             {FormattedDateTime(new Date())} EST
    //           </Mui.Typography>
    //         </div>
    //         <div
    //           style={{
    //             display: "inline-block",
    //             color: "lightgrey",
    //             marginLeft: "32px",
    //           }}
    //         >
    //           <Mui.Typography>
    //             <FontAwesomeIcon
    //               style={{ marginRight: "8px" }}
    //               icon={faUser}
    //             ></FontAwesomeIcon>
    //             {getWelcomeMessage()}
    //           </Mui.Typography>
    //         </div>
    //       </Mui.Stack>
    //     </Mui.Grid>
    //   </Mui.Grid>
    // </section>
  );
};
