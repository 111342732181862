import { faFileExport } from "@fortawesome/pro-light-svg-icons";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useEffect } from "react";
import { setLayout } from "../../redux/Slices/layout.slice";
import { Card, Grid } from "@mui/material";
import { ReportsSummary } from "./ReportsSummary";
import { ReportTabs } from "./ReportTabs";
import { useAsyncEffect } from "../../common/hooks";
import { fetchAlertCategory, fetchAlertOverrides } from "../../redux/Thunks/alerts";
import { Loader } from "../../components/Loader";
import { fetchDeviceCategory, fetchDeviceHealthStates, fetchDeviceOSVersion, fetchDeviceSyncedFrom, fetchDeviceTypes } from "../../redux/Thunks/devices";
import { getSelectedCompanyId } from "../../redux/Slices/account.slice";
import dayjs from "dayjs";
import { fetchAllTickets, fetchProblemCategoryCounts, fetchTicketsAssignedEngineer, fetchTicketsRaisedBy, fetchTicketStatusPerDay } from "../../redux/Thunks/tickets";
import { fetchChangeManagementSummaries } from "../../redux/Thunks/changeManagement";
import { fetchUserStatistics } from "../../redux/Thunks/account";


export const ReportsDashboardPage = () => {
  const dispatch = useAppDispatch();
  const selectedCompanyId: string | any = useAppSelector(getSelectedCompanyId);
  const timePayload = {
    companyId: selectedCompanyId,
    startDate: dayjs().subtract(1, "month").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    endDate: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
  }

  const { loading } = useAsyncEffect(async () => {
    // await dispatch(fetchActiveAlerts());
    await dispatch(fetchAlertOverrides());
    await dispatch(fetchDeviceTypes());
    await dispatch(fetchAlertCategory(timePayload));
    await dispatch(fetchDeviceCategory());
    await dispatch(fetchDeviceSyncedFrom());
    await dispatch(fetchDeviceOSVersion())
    await dispatch(fetchDeviceHealthStates())
    await dispatch(fetchProblemCategoryCounts(timePayload))
    await dispatch(fetchTicketStatusPerDay(timePayload))
    await dispatch(fetchTicketsRaisedBy(timePayload))
    await dispatch(fetchTicketsAssignedEngineer(timePayload))
    await dispatch(fetchChangeManagementSummaries())
    await dispatch(fetchUserStatistics())
    await dispatch(fetchAllTickets())



    


  }, []);

  useEffect(() => {
    dispatch(
      setLayout({
        title: "Reports Dashboard",
        subtitle: "Reports Dashboard",
        icon: faFileExport,
      })
    );
  }, [dispatch]);


  return (
    <>
      {loading ? (
        <Loader show={true} />
      ) : (
        <>
          <ReportsSummary />
          <Grid item xs={12} sx={{ height: "auto", marginTop: 2 }}>
            <Card sx={{ border: "solid 1px #ddd", height: "auto" }}>
              <ReportTabs />

            </Card>
          </Grid>
        </>
      )}
    </>
  );
};
