import { Avatar, AvatarProps, IconButton } from "@mui/material";
import { useAppDispatch } from "../../redux/hooks";
import { uploadImage } from "../../redux/Thunks/util";
import { useState } from "react";

export interface UploadAvatarProps extends AvatarProps {
  imageUrl?: string;
  uploadUrl: string;
}

const UploadAvatar = (props: UploadAvatarProps) => {
  const { imageUrl, uploadUrl, ...otherProps } = props;
  const [imageKey, setImageKey] = useState(new Date().getTime());
  const dispatch = useAppDispatch();
  const handleFileInputChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = e.target.files;
    if (!files) {
      return;
    }
    const file = files[0];

    await dispatch(uploadImage({ uploadUrl, imageFile: file }));
    setImageKey(new Date().getTime());
  };

  return (
    <>
      <IconButton aria-label="upload" component="label">
        <Avatar {...otherProps} src={`${imageUrl}?key=${imageKey}`} />
        <input
          hidden={true}
          id={"contained-button-file"}
          accept="image/png, image/jpeg"
          type="file"
          onChange={handleFileInputChange}
        />
      </IconButton>
    </>
  );
};

export default UploadAvatar;
