import React from "react";
import * as Mui from "@mui/material";
import { useNavigate } from "react-router-dom";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";

interface NotifyBannerSmallProps {
  secondsDuration: number;
  message: string;
  showNotification: boolean;
  createdId: string;
  linkText: string;
  navigatePage: string;
  severity: any;
}

interface State extends SnackbarOrigin {
  open: boolean;
}

export const NotifyBannerSmall: React.FC<NotifyBannerSmallProps> = (props) => {
  const [counter, setCounter] = React.useState<number>(props.secondsDuration);
  const [state, setState] = React.useState<State>({
    open: false,
    vertical: "top",
    horizontal: "right",
  });
  const { vertical, horizontal, open } = state;
  React.useEffect(() => {
    setState({ ...state, open: props.showNotification });
  }, [props.showNotification]);
  
  const handleClose = () => {
    setState({ ...state, open: false });
  };
  var navigate = useNavigate();
  React.useEffect(() => {
    let timer: any;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        autoHideDuration={20000}
        key={vertical + horizontal}
      >
        <Mui.Alert
          onClose={handleClose}
          severity={props.severity}
          sx={{ width: "100%" }}
        >
          {props.message} Closing in {counter}{" "} seconds
          <Mui.Button
            onClick={() => {
              navigate(`/${props.navigatePage}/${props.createdId}`);
            }}
          >
            {props.linkText}
          </Mui.Button>
        </Mui.Alert>
      </Snackbar>
    </div>
  );
};
