import * as Mui from "@mui/material";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { ErrorBanner } from "../../components/ErrorBanner";
import largeLogo from "../../images/LogoLarge.svg";
import { StepAllDone } from "./StepAllDone";
import { ConfirmSignupSetPassword } from "./ConfirmSignupSetPassword";
import { WelcomePage } from "./WelcomePage";
import { useAppDispatch } from "../../redux/hooks";
import { confirmSignup } from "../../redux/Thunks/account";
import { ConfirmSignupCommand } from "../../types/account.types";

export const ConfirmSignupPage: React.FC = () => {
  const steps = ["Welcome", "Your Password", "All done!"];
  const [activeStep, setActiveStep] = React.useState(0);

  const [searchParams] = useSearchParams();
  var navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();

  var token = searchParams.get("token") || null;

  const handleConfirm = async (password: string) => {
    if (id && token) {
      var confirmSignUpCommand: ConfirmSignupCommand = {
        password: password,
        token,
      };

      const action = await dispatch(
        confirmSignup({ primaryContactId: id, command: confirmSignUpCommand })
      )
      if (action.payload) {
        setActiveStep(2);
      }
    }
  };

  return token === null ? (
    <ErrorBanner
      headerText={"Confirm Link Issue"}
      subText={
        "We are really sorry, but there seems to be an issue with the link being used to confirm the signup"
      }
    />
  ) : (
    <Mui.Box display="flex" justifyContent="center" alignItems="center">
      <Mui.Stack
        direction="column"
        sx={{ width: { xs: "100%", md: "60%", xxl: "50%" } }}
        alignItems="center"
      >
        <Mui.Box
          component="img"
          sx={{
            content: { xs: `url(${largeLogo})` },
            maxWidth: { xs: "350px" },
            minHeight: "100px",
          }}
          alt="Security Portal"
        />
        <Mui.Stepper
          activeStep={activeStep}
          sx={{ width: "70%", mb: 4, mt: 4 }}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Mui.Step key={index} {...stepProps}>
                <Mui.StepLabel {...labelProps}>{label}</Mui.StepLabel>
              </Mui.Step>
            );
          })}
        </Mui.Stepper>

        {activeStep === 0 ? (
          <WelcomePage
            introMessage="In a moment you will be asked to enter your password and after that, you will be taken to the login screen so you can start using the portal."
            onNextClick={() => {
              setActiveStep(1);
            }}
          />
        ) : (
          <></>
        )}

        {activeStep === 1 ? (
          <ConfirmSignupSetPassword
            onNextClick={(password) => {
              handleConfirm(password);
            }}
            onPreviousClick={() => {
              setActiveStep(0);
            }}
          />
        ) : (
          <></>
        )}
        {activeStep === 2 ? (
          <StepAllDone
            onNextClick={() => {
              navigate("/");
            }}
          />
        ) : (
          <></>
        )}
      </Mui.Stack>
    </Mui.Box>
  );
};
