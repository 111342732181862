import { Box } from "@mui/material"
import { DeviceTypes } from "./DeviceTypes"
import { useAppSelector } from "../../../../redux/hooks";
import { getDeviceCategory, getDeviceHealthStates, getDeviceOSVersion, getDeviceSyncedFrom, getDeviceTypes } from "../../../../redux/Slices/devices.slice";
import { DeviceCategory } from "./DeviceCategory";
import { DeviceSyncedFrom } from "./DeviceSyncedFrom";
import { DeviceOSVersion } from "./DeviceOSVersion";
import { DeviceHealthState } from "./DeviceHealthState";





export const DeviceReportsTabs = () => {
    const deviceTypes = useAppSelector(getDeviceTypes);
    const deviceCategory = useAppSelector(getDeviceCategory);
    const deviceSyncedFrom = useAppSelector(getDeviceSyncedFrom);
    const deviceOSVersion = useAppSelector(getDeviceOSVersion)
    const deviceHealthStates = useAppSelector(getDeviceHealthStates)



    return (
        <Box>
            <DeviceTypes
                deviceTypes={deviceTypes}
            />
            <DeviceCategory
                deviceCategory={deviceCategory}
            />
            <DeviceSyncedFrom
                deviceSyncedFrom={deviceSyncedFrom}
            />
            <DeviceOSVersion
                deviceOSVersion={deviceOSVersion}

            />
            <DeviceHealthState
                deviceHealthStates={deviceHealthStates}
            />
        </Box>
    )
}