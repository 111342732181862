import { Card, Box, Tabs, Tab, ButtonGroup, Button, Modal } from "@mui/material"
import { ProfileManagementForm } from "./ProfileManagementForm"
import RunAsAccountsEnhancedTable from "./RunAsAccountsEnhancedTable"
import { useState } from "react"
import { getRunAsAccounts, getRunAsProfiles } from "../../redux/Slices/devices.slice"
import { useAppSelector } from "../../redux/hooks"
// import { CreateRunAsAccountsForm } from "./CreateRunAsAccountsForm"
import { TabPanel, a11yProps } from "../../common/DashboardTabs"
import { RunAsAccountCreateForm } from "./RunAsAccountCreateForm"




export const RunAsAccountTabPanelHeader = () => {
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false);
    const runAsAccounts = useAppSelector(getRunAsAccounts);
    const runAsProfiles = useAppSelector(getRunAsProfiles);

    const handleOpenNewRunAsAccount = () => setOpen(true);
    const handleCloseNewRunAsAccount = () => setOpen(false);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (
        <Card sx={{ border: "solid 1px #ddd", height: "auto", marginTop: 2 }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Account Management" {...a11yProps(0)} />
                        <Tab label="Profile Management" {...a11yProps(1)} />
                    </Tabs>
                    <ButtonGroup sx={{ height: '30px', mt: 1.5, mr: 2 }}>
                        <Button onClick={handleOpenNewRunAsAccount}>
                            New Run-As Account
                        </Button>
                    </ButtonGroup>
                    <Modal
                        open={open}
                        onClose={handleCloseNewRunAsAccount}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box>
                            {/* <CreateRunAsAccountsForm onClose={handleCloseNewRunAsAccount} /> */}
                            <RunAsAccountCreateForm onClose={handleCloseNewRunAsAccount}/>
                        </Box>
                    </Modal>
                </Box>
                <TabPanel value={value} index={0}>
                    <RunAsAccountsEnhancedTable runAsAccounts={runAsAccounts} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <ProfileManagementForm />
                </TabPanel>
        </Card>
    )


}