import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  determineAlertPriority,
  determineAlertStatus,
} from "../../common/alerts.util";
import {
  Alert,
  AlertPriorityTotals,
  AlertStatusTotals,
  AlertTotals,
  DateTotal,
  AlertOverrides,
  TableFilterObject,
  AlertClassification,
  Investigation,
  Flare,
  FirewallResponse,
  AlertCategory,
  DailyAlertStatistics,
} from "../../types/alerts.types";
import { RootState } from "../store";

export interface AlertState {
  alerts: Alert[];
  alertTotals: AlertTotals | null;
  alertStatus: AlertStatusTotals;
  alertPriority: AlertPriorityTotals;
  alertTotalsCreatedByDay: DateTotal[];
  alertTotalsClosedByDay: DateTotal[];
  alertCategory: AlertCategory[];
  alertsPaginatedCount: number;
  totalNumberOfActiveAlerts: number;
  alertsWithPagination: Alert[];
  tablePage: number;
  alertOverrides: AlertOverrides[];
  alertTableFilterValue: TableFilterObject;
  KBArticle: [];
  alertClassification: AlertClassification;
  investigatedAlert: Investigation;
  investigatedDevice: Investigation;
  IpLocation: {};
  flareAlert: Flare;
  flareAlertToSearch: "";
  firewallResponse: FirewallResponse;
  dailyAlertStatistics: DailyAlertStatistics[];
}

const initialState: AlertState = {
  alerts: [],
  alertTotals: null,
  alertPriority: { low: 0, medium: 0, high: 0, total: 0 },
  alertStatus: { error: 0, openAlerts: 0, monitored: 0 },
  alertTotalsCreatedByDay: [],
  alertTotalsClosedByDay: [],
  alertCategory: [],
  alertsPaginatedCount: 0,
  totalNumberOfActiveAlerts: 0,
  alertsWithPagination: [],
  tablePage: 1,
  alertOverrides: [],
  alertTableFilterValue: { alert: {} as Alert, filterRule: "" },
  KBArticle: [],
  alertClassification: {} as AlertClassification,
  investigatedAlert: {} as Investigation,
  investigatedDevice: {} as Investigation,
  IpLocation: {},
  flareAlert: {} as Flare,
  flareAlertToSearch: "",
  firewallResponse: {} as FirewallResponse,
  dailyAlertStatistics: [],
};

const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setAlerts: (state, action: PayloadAction<Alert[]>) => {
      state.alerts = action.payload;
      state.alertPriority = determineAlertPriority(action.payload);
    },
    setAlertsPaginatedCount: (state, action: PayloadAction<number>) => {
      state.alertsPaginatedCount = action.payload;
    },
    setAlertArticles: (
      state,
      action: PayloadAction<{
        alertId: string;
        articles: Alert["alertArticles"];
      }>
    ) => {
      const idx = state.alerts.findIndex(
        (alert) => alert.id === action.payload.alertId
      );
      if (idx)
        state.alerts[idx] = {
          ...state.alerts[idx],
          alertArticles: action.payload.articles,
        };
    },
    setAlertStatistics: (
      state,
      action: PayloadAction<{
        alertId: string;
        statistics: Alert["alertStatistics"];
      }>
    ) => {
      const idx = state.alerts.findIndex(
        (alert) => alert.id === action.payload.alertId
      );
      if (idx !== -1)
        state.alerts[idx] = {
          ...state.alerts[idx],
          alertStatistics: action.payload.statistics,
        };
    },
    setAlertTotals: (state, action: PayloadAction<AlertTotals>) => {
      state.alertTotals = action.payload;
      state.alertStatus = determineAlertStatus(action.payload, state.alerts);
    },
    setAlertTotalsCreatedByDay: (state, action: PayloadAction<DateTotal[]>) => {
      state.alertTotalsCreatedByDay = action.payload;
    },
    setAlertTotalsClosedByDay: (state, action: PayloadAction<DateTotal[]>) => {
      state.alertTotalsClosedByDay = action.payload;
    },
    setAlertCategory: (state, action: PayloadAction<AlertCategory[]>) => {
      state.alertCategory = action.payload;
    },
    setTotalNumberOfActiveAlerts: (state, action: PayloadAction<number>) => {
      state.totalNumberOfActiveAlerts = action.payload;
    },
    setAlertsWithPagination: (state, action: PayloadAction<Alert[]>) => {
      state.alertsWithPagination = action.payload;
    },
    setAlertTablePage: (state, action: PayloadAction<number>) => {
      state.tablePage = action.payload;
    },
    setAlertOverrides: (state, action: PayloadAction<AlertOverrides[]>) => {
      state.alertOverrides = action.payload;
    },
    setAlertTableFilterValue: (
      state,
      action: PayloadAction<TableFilterObject>
    ) => {
      state.alertTableFilterValue = action.payload;
    },
    setKBArticle: (state, action: PayloadAction<[]>) => {
      state.KBArticle = action.payload;
    },
    setAlertClassification: (
      state,
      action: PayloadAction<AlertClassification>
    ) => {
      state.alertClassification = action.payload;
    },
    setInvestigatedAlertResponse: (
      state,
      action: PayloadAction<Investigation>
    ) => {
      state.investigatedAlert = action.payload;
    },
    setInvestigatedDeviceResponse: (
      state,
      action: PayloadAction<Investigation>
    ) => {
      state.investigatedDevice = action.payload;
    },
    setIpLocation: (state, action: PayloadAction<{}>) => {
      state.IpLocation = action.payload;
    },
    setFlareAlertResponse: (state, action: PayloadAction<Flare>) => {
      state.flareAlert = action.payload;
    },
    setFlareAlertToSearch: (state, action: PayloadAction<"">) => {
      state.flareAlertToSearch = action.payload;
    },
    setFirewallResponse: (state, action: PayloadAction<FirewallResponse>) => {
      state.firewallResponse = action.payload;
    },
    setDailyAlertStatistics: (
      state,
      action: PayloadAction<DailyAlertStatistics[]>
    ) => {
      state.dailyAlertStatistics = action.payload;
    },
  },
});

export const {
  setAlerts,
  setAlertArticles,
  setAlertStatistics,
  setAlertTotals,
  setAlertTotalsCreatedByDay,
  setAlertTotalsClosedByDay,
  setAlertCategory,
  setTotalNumberOfActiveAlerts,
  setAlertsWithPagination,
  setAlertTablePage,
  setAlertOverrides,
  setAlertTableFilterValue,
  setKBArticle,
  setAlertClassification,
  setInvestigatedAlertResponse,
  setIpLocation,
  setInvestigatedDeviceResponse,
  setFlareAlertResponse,
  setFlareAlertToSearch,
  setFirewallResponse,
  setDailyAlertStatistics,
  setAlertsPaginatedCount,
} = alertsSlice.actions;

export const getAlertById = (id: string) => (state: RootState) =>
  state.alerts.alerts.find((alert) => alert.id === id);
export const getAlertByTicketId = (id: string) => (state: RootState) =>
  state.alerts.alerts.find((alert) => alert.ticketId === id);
export const getAlertArticlesById = (id: string) => (state: RootState) =>
  state.alerts.alerts.find((alert) => alert.id === id)?.alertArticles;
export const getAlertStatisticsById = (id: string) => (state: RootState) =>
  state.alerts.alerts.find((alert) => alert.id === id)?.alertStatistics;
export const getAlerts = (state: RootState) => state.alerts.alerts;
export const getAlertStatus = (state: RootState) => state.alerts.alertStatus;
export const getAlertPriority = (state: RootState) =>
  state.alerts.alertPriority;
export const getAlertTotals = (state: RootState) => state.alerts.alertTotals;
export const getAlertTotalsClosedByDay = (state: RootState) =>
  state.alerts.alertTotalsClosedByDay;
export const getAlertCategory = (state: RootState) =>
  state.alerts.alertCategory;
export const getAlertTotalsCreatedByDay = (state: RootState) =>
  state.alerts.alertTotalsCreatedByDay;
export const getTotalNumberOfActiveAlerts = (state: RootState) =>
  state.alerts.totalNumberOfActiveAlerts;
export const getAlertsWithPagination = (state: RootState) =>
  state.alerts.alertsWithPagination;
export const getAlertTablePage = (state: RootState) => state.alerts.tablePage;
export const getAlertOverrides = (state: RootState) =>
  state.alerts.alertOverrides;
export const getAlertTableFilterValue = (state: RootState) =>
  state.alerts.alertTableFilterValue;
export const getKBArticle = (state: RootState) => state.alerts.KBArticle;
export const getAlertClassification = (state: RootState) =>
  state.alerts.alertClassification;
export const getInvestigatedAlertResponse = (state: RootState) =>
  state.alerts.investigatedAlert;
export const getIpLocation = (state: RootState) => state.alerts.IpLocation;
export const getInvestigatedDeviceResponse = (state: RootState) =>
  state.alerts.investigatedDevice;
export const getFlareAlertResponse = (state: RootState) =>
  state.alerts.flareAlert;
export const getFlareAlertToSearch = (state: RootState) =>
  state.alerts.flareAlertToSearch;
export const getFirewallResponse = (state: RootState) =>
  state.alerts.firewallResponse;
export const getDailyAlertStatistics = (state: RootState) =>
  state.alerts.dailyAlertStatistics;
export const getAlertsPaginatedCount = (state: RootState) =>
  state.alerts.alertsPaginatedCount;

export default alertsSlice.reducer;
