import * as Mui from "@mui/material";
import { Loader } from "../../components/Loader";
import "./TicketsDashboardPage.css";

import { TicketsEnhancedTable } from "./TicketEnhancedTable/TicketEnhancedTable";
import { faTicket } from "@fortawesome/pro-light-svg-icons";
import { TicketTableSummary } from "./TicketTableSummary/TicketTableSummary";
import { getAllTickets } from "../../redux/Slices/tickets.slice";
import { fetchAllTickets } from "../../redux/Thunks/tickets";
import { useAsyncEffect } from "../../common/hooks";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setLayout } from "../../redux/Slices/layout.slice";
import { useEffect, useState } from "react";

export const TicketsDashboardPage = () => {
  const dispatch = useAppDispatch();
  const tickets = useAppSelector(getAllTickets);
  const [filteredTickets, setFilteredTickets] = useState(tickets)
  const { loading } = useAsyncEffect(() => dispatch(fetchAllTickets()), []);

  useEffect(() => {
    dispatch(
      setLayout({
        title: "Tickets Dashboard",
        subtitle: "Viewing tickets",
        icon: faTicket,
      })
    );
  }, [dispatch]);
  return (
    <>
      {loading && tickets.length < 1 ? (
        <Loader show={true} />
      ) : (
        <>
          <TicketTableSummary
            tickets={filteredTickets}
          />
          <Mui.Grid container>
            <Mui.Grid
              container
              spacing={2}
              sx={{ minHeight: "200px", marginTop: 1 }}
            >
              <Mui.Grid item xs={12}>
                <TicketsEnhancedTable
                  tickets={tickets}
                  setFilteredTickets={setFilteredTickets}
                />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </>
      )}
    </>
  );
};
