import { Box, Button } from "@mui/material";
import { useState } from "react";
import { DeviceChartData, DeviceChartObject } from "../../types/devices.types";
import dayjs from "dayjs";
import { raisePerformanceValuesDaily, raisePerformanceValuesHourly, raisePerformanceValuesRAW } from "../../redux/Thunks/devices";
import { useAppDispatch } from "../../redux/hooks";
import { setChartErrorMessage } from "../../redux/Slices/devices.slice";

interface ChartButtonGroupProps {
    chartData: DeviceChartData;
    onToggleTimeInterval: (allcharts: DeviceChartData[]) => void;
    index: number;
    allCharts: DeviceChartData[];
}



export default function ChartButtonGroup(props: ChartButtonGroupProps) {
    const { chartData, onToggleTimeInterval, index, allCharts } = props
    const dispatch = useAppDispatch();
    const [selectedTimeInterval, setSelectedTimeInterval] = useState(chartData.timeInterval)
    const buttonGroup = ['year', 'month', 'week', 'day', 'hour',]
    const dateTimeNow = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const oneHourAgo = dayjs().subtract(1, 'hour').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const oneDayAgo = dayjs().subtract(1, 'day').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const oneWeekAgo = dayjs().subtract(1, 'week').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const oneMonthAgo = dayjs().subtract(1, 'month').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    const oneYearAgo = dayjs().subtract(1, 'year').format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")


    const onClickTimeInterval = (button: string, index: number) => {
        dispatch(setChartErrorMessage(false));
        setSelectedTimeInterval(button)
        fetchByTimeInterval(button)
    }
    const fetchByTimeInterval = (timeInterval: string) => {
        const dynamicStartDate = (timeInterval === 'year' ? oneYearAgo : timeInterval === 'month' ? oneMonthAgo : timeInterval === 'week' ? oneWeekAgo : timeInterval === 'day' ? oneDayAgo : timeInterval === 'hour' ? oneHourAgo : oneYearAgo)
        var timeObject: DeviceChartObject = {
            deviceId: chartData.deviceId,
            deviceGroup: chartData.deviceGroup,
            objectName: chartData.objectName,
            counterName: chartData.counterName,
            startDate: dynamicStartDate,
            endDate: dateTimeNow,
        }
        switch (timeInterval) {
            case 'year':
                return fetchByYear(timeObject, timeInterval);
            case 'month':
                return fetchByMonth(timeObject, timeInterval);
            case 'week':
                return fetchByWeek(timeObject, timeInterval);
            case 'day':
                return fetchByDay(timeObject, timeInterval);
            case 'hour':
                return fetchByHour(timeObject, timeInterval);
            default:
                return;
        }

    }
    const fetchByHour = async (timeObject: DeviceChartObject, timeInterval: string) => {
        const chartCopy = [...allCharts]
        const hourly = await dispatch(
            raisePerformanceValuesRAW(timeObject)
        )
        if (!hourly.payload) {
            dispatch(setChartErrorMessage(true));
        }
        if (hourly.payload) {
            dispatch(setChartErrorMessage(false));
            const newChartData = {
                deviceId: chartData.deviceId,
                deviceGroup: chartData.deviceGroup,
                objectName: chartData.objectName,
                counterName: chartData.counterName,
                chartData: hourly.payload,
                timeInterval: timeInterval,

            }
            chartCopy.splice(index, 1, newChartData)
            onToggleTimeInterval(chartCopy)
        }
    }
    const fetchByDay = async (timeObject: DeviceChartObject, timeInterval: string) => {
        const chartCopy = [...allCharts]
        const daily = await dispatch(
            raisePerformanceValuesRAW(timeObject)
        )
        if (!daily.payload) {
            dispatch(setChartErrorMessage(true));
        }
        if (daily.payload) {
            dispatch(setChartErrorMessage(false));
            const newChartData = {
                deviceId: chartData.deviceId,
                deviceGroup: chartData.deviceGroup,
                objectName: chartData.objectName,
                counterName: chartData.counterName,
                chartData: daily.payload,
                timeInterval: timeInterval,

            }
            chartCopy.splice(index, 1, newChartData)
            onToggleTimeInterval(chartCopy)
        }
    }
    const fetchByWeek = async (timeObject: DeviceChartObject, timeInterval: string) => {
        const chartCopy = [...allCharts]
        const weekly = await dispatch(
            raisePerformanceValuesHourly(timeObject)
        )
        if (!weekly.payload) {
            dispatch(setChartErrorMessage(true));
        }
        if (weekly.payload) {
            dispatch(setChartErrorMessage(false));
            const newChartData = {
                deviceId: chartData.deviceId,
                deviceGroup: chartData.deviceGroup,
                objectName: chartData.objectName,
                counterName: chartData.counterName,
                chartData: weekly.payload,
                timeInterval: timeInterval,

            }
            chartCopy.splice(index, 1, newChartData)
            onToggleTimeInterval(chartCopy)
        }
    }
    const fetchByMonth = async (timeObject: DeviceChartObject, timeInterval: string) => {
        const chartCopy = [...allCharts]
        const monthly = await dispatch(
            raisePerformanceValuesHourly(timeObject)
        )
        if (!monthly.payload) {
            dispatch(setChartErrorMessage(true));
        }
        if (monthly.payload) {
            dispatch(setChartErrorMessage(false));
            const newChartData = {
                deviceId: chartData.deviceId,
                deviceGroup: chartData.deviceGroup,
                objectName: chartData.objectName,
                counterName: chartData.counterName,
                chartData: monthly.payload,
                timeInterval: timeInterval,

            }
            chartCopy.splice(index, 1, newChartData)
            onToggleTimeInterval(chartCopy)
        }
    }
    const fetchByYear = async (timeObject: DeviceChartObject, timeInterval: string) => {
        const chartCopy = [...allCharts]
        const yearly = await dispatch(
            raisePerformanceValuesDaily(timeObject)
        )
        if (!yearly.payload) {
            dispatch(setChartErrorMessage(true));
        }
        if (yearly.payload) {
            dispatch(setChartErrorMessage(false));
            const newChartData = {
                deviceId: chartData.deviceId,
                deviceGroup: chartData.deviceGroup,
                objectName: chartData.objectName,
                counterName: chartData.counterName,
                chartData: yearly.payload,
                timeInterval: timeInterval,

            }
            chartCopy.splice(index, 1, newChartData)
            onToggleTimeInterval(chartCopy)
        }
    }
    return (
        <Box>

            {buttonGroup.map((button, index) => (
                <Button
                    sx={{
                        backgroundColor: selectedTimeInterval === button ? "orange" : "white",
                        color: selectedTimeInterval === button ? "white" : "orange",
                    }}
                    onClick={() => {
                        onClickTimeInterval(button, index);
                    }}
                >
                    {button}
                </Button>

            ))}

        </Box>
    );
}