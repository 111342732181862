//@ts-nocheck
import React from "react";
import * as Mui from "@mui/material";

import { faClipboard } from "@fortawesome/pro-light-svg-icons";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormattedDate } from "../../common/dateUtils";
import { Card } from "../../components/Card";
import { Company } from "../../types/company.types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchCompanies, updateCompany } from "../../redux/Thunks/company";
import { getUsers } from "../../redux/Slices/account.slice";
import { useAsyncEffect } from "../../common/hooks";
import { fetchAllUsers } from "../../redux/Thunks/account";

interface CompanyEditProps {
  company: Company;
  onBack?: VoidFunction;
}

export const CompanyEdit: React.FC<CompanyEditProps> = (props) => {
  const { company } = props;
  const dispatch = useAppDispatch();
  const { control, handleSubmit, reset } = useForm<Company>();
  const users = useAppSelector(getUsers);

  const { loading } = useAsyncEffect(async () => {
    dispatch(fetchAllUsers({}));
  }, []);

  const handleRestore = () => {
    reset();
    if (props.onBack) {
      props.onBack();
    }
  };

  const onSubmit: SubmitHandler<Company> = async (data) => {
    //find the user, then set the name
    const id = company.id;
    const selectedUser = users.find((obj) => {
      return obj.emailAddress === data.primaryContactEmail;
    });

    if (selectedUser) {
      data.primaryContactName = `${selectedUser.firstName} ${selectedUser.lastName}`;
      data.id = id;
      await dispatch(updateCompany(data));
      await dispatch(fetchCompanies());
    }
    if (props.onBack) {
      props.onBack();
    }
  };

  //dont like the any's but its an enclosed const in here and the form has strict checks
  const EditbleTextField: React.FC<{
    defaultValue: any;
    name: any;
    label: string;
  }> = (props) => {
    return (
      <Controller
        name={props.name}
        control={control}
        defaultValue={props.defaultValue}
        render={({ field }) => (
          <Mui.TextField
            name={field.name}
            value={field.value}
            size="small"
            onChange={field.onChange}
            id={props.name}
            label={props.label}
            sx={{ width: "100%", mb: 1 }}
          />
        )}
      />
    );
  };

  return (
    <Card
      id="companydashboard-editcompany"
      headerIcon={faClipboard}
      headerTitle={`Organisation's Summary`}
      headerSubText={`Created on ${FormattedDate(props.company.createdDate)}`}
      showHelp={true}
          helpHeaderText="Edit your organisation's details"
          helpHeaderDescription="Use this view to modify your organisation's details such as address, name and primary contact."
      cardContent={
        <form style={{ width: "100%" }}>
          <Mui.Typography
            onClick={() => {
              navigator.clipboard.writeText(company.id);
            }}
            sx={{ cursor: "pointer" }}
          >
            Id:{" "}
            <span style={{ textDecoration: "underline" }}>{company.id}</span>
          </Mui.Typography>
          <br />
          <EditbleTextField
            defaultValue={
              props.company.companyName === null
                ? undefined
                : props.company.companyName
            }
                  label="Organisation's Name"
            name="companyName"
          />

          <EditbleTextField
            defaultValue={
              props.company.companyAddressLine1 === null
                ? undefined
                : props.company.companyAddressLine1
            }
            label="Address Line 1"
            name="companyAddressLine1"
          />

          <EditbleTextField
            defaultValue={
              props.company.companyAddressLine2 === null
                ? undefined
                : props.company.companyAddressLine2
            }
            label="Address Line 2"
            name="companyAddressLine2"
          />

          <EditbleTextField
            defaultValue={
              props.company.companyAddressLine3 === null
                ? undefined
                : props.company.companyAddressLine3
            }
            label="Address Line 3"
            name="companyAddressLine3"
          />

          <EditbleTextField
            defaultValue={
              props.company.townCity === null
                ? undefined
                : props.company.townCity
            }
            label="Town / City"
            name="townCity"
          />

          <EditbleTextField
            defaultValue={
              props.company.stateCounty === null
                ? undefined
                : props.company.stateCounty
            }
            label="State / County"
            name="stateCounty"
          />

          <EditbleTextField
            defaultValue={
              props.company.postalZip === null
                ? undefined
                : props.company.postalZip
            }
            label="Zip Code / Postal Code"
            name="postalZip"
          />

          <Mui.Divider
            sx={{ mt: 1, borderColor: "orange", mb: 1, bgColor: "orange" }}
          />
          <Mui.Typography>Primary Contact</Mui.Typography>

          {users && !loading ? (
            <Controller
              name="primaryContactEmail"
              control={control}
              defaultValue={
                props.company.primaryContactEmail === null
                  ? undefined
                  : props.company.primaryContactEmail
              }
              render={({ field }) => (
                <Mui.FormControl
                  sx={{ alignContent: "flex-start", width: "100%" }}
                >
                  <Mui.Select
                    {...field}
                    size="small"
                    sx={{ width: "100%", alignSelf: "flex-start" }}
                  >
                    {users.map((user, index) => {
                      return (
                        <Mui.MenuItem key={index} value={user.emailAddress}>
                          {user.firstName} {user.lastName} ({user.emailAddress})
                        </Mui.MenuItem>
                      );
                    })}
                  </Mui.Select>
                </Mui.FormControl>
              )}
            />
          ) : (
            <>Loading users....</>
          )}
        </form>
      }
      actions={
        <Mui.FormGroup row>
          <Mui.Button
            variant="outlined"
            color="secondary"
            sx={{ mr: 1 }}
            onClick={handleRestore}
          >
            {props.onBack ? "Back" : "Restore"}
          </Mui.Button>
          <Mui.Button
            variant="outlined"
            color="primary"
            onClick={handleSubmit(onSubmit)}
          >
            Save Updates
          </Mui.Button>
        </Mui.FormGroup>
      }
    />
  );
};
