import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faFileExport } from '@fortawesome/pro-light-svg-icons';
import { Button, Divider, Grid, Tooltip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { handleExportReportsData } from '../../../../common/reportsUtils';

function createData(
    type: any,
    items: any,

) {
    return {
        type,
        items,
    };
}

function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <Grid container spacing={4} sx={{ height: '10vh' }}>
                <Grid item xs={1} sx={{ display: "flex", justifyContent: 'center' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <FontAwesomeIcon
                            icon={faAngleUp}
                            style={{ paddingLeft: 10 }}
                        /> : <FontAwesomeIcon
                            icon={faAngleDown}
                            style={{ paddingLeft: 10 }}
                        />}
                    </IconButton>
                </Grid>
                <Grid item xs={10} sx={{ display: "flex", justifyContent: 'center', fontWeight: 'bold', mt: 2 }}>
                    {row.type}
                </Grid>
                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, pr: 2 }}>
                    <Tooltip title='Export Data'>
                        <FontAwesomeIcon
                            onClick={() => handleExportReportsData(row.items, `User ${row.type} Data`)}
                            icon={faFileExport}
                            color={"black"}
                            size="lg"
                        />
                    </Tooltip>
                </Grid>
            </Grid>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Number</TableCell>
                                        <TableCell>Name</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.items.map((item: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell align='left' sx={{ width: '10%' }}>{item.number}</TableCell>
                                            <TableCell align='left' sx={{ width: '45%' }}>
                                                {row.type === 'Assigned Milestones' || row.type === 'Assigned Plans' ?
                                                    <>{item.title}</>
                                                    :

                                                    <Button
                                                        sx={{ textAlign: 'left' }}
                                                        variant="text"
                                                        onClick={() => {
                                                            navigate(`/changemanagement/${item.id}`);

                                                        }}
                                                    >
                                                        {item.title}
                                                    </Button>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}


interface CMTableWidgetProps {
    cmData: any;
}
export default function CMTableWidget(props: CMTableWidgetProps) {
    const { cmData } = props
    return (
        <TableContainer sx={{ width: '100%', height: '50vh', overflowY: 'auto', border: "solid 1px #ddd", borderRadius: '5px', m: 1 }} >

            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow sx={{ display: 'flex', justifyContent: 'center', backgroundColor: 'rgb(57,90,122)' }}>
                        <Typography variant="h4" sx={{ pt: 1, pb: 1, color: 'white' }}>Change Management</Typography>
                    </TableRow>
                    <Divider />
                </TableHead>
                <TableBody>
                    {cmData.map((row: any) => (
                        <Row key={row.type} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}