import { faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Box, Typography, InputLabel, TextField, Divider, Tooltip } from "@mui/material";


interface MilestoneTaskProps {
    idx: number;
    index: number;
    formValues: any;
    onDeleteTask: (idx: number, index: number) => void;
    handleChangeDescription: (e: any, index: number, idx: number) => void;
 }

export const MilestoneTasks = (props: MilestoneTaskProps) => {
    const { idx, index, formValues, onDeleteTask, handleChangeDescription } = props

    return (
        <Grid container spacing={2}>
        <Grid item xs={12}>
            <Box sx={{ display: 'flex' }}>
                <Typography sx={{ fontWeight: 'bold' }}>Task {idx + 1}</Typography>
                <Tooltip title="Delete Task">
                    <FontAwesomeIcon
                        icon={faTrashCan}
                        color={"#f8931c"}
                        size="lg"
                        style={{ position: 'absolute', right: 50, paddingTop: 10 }}
                        onClick={() => onDeleteTask(idx, index)}
                    />
                </Tooltip>
            </Box>

        </Grid>
        <Grid item xs={21}>
            {/* DESCRIPTION */}
            <Box>


                <InputLabel id="demo-multiple-checkbox-label">
                    Description
                </InputLabel>
                <TextField
                    required
                    id="title"
                    sx={{ width: "100%", mr: 1, marginBottom: "20px" }}
                    value={formValues.milestones[index].tasks[idx].description}
                    onChange={(e) => {
                        handleChangeDescription(e, index, idx)
                    }}
                    rows={4}
                    multiline
                />
            </Box>
        </Grid>

        <Divider />
    </Grid>
    )
}