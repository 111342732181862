import { Alert, AlertProps } from "@mui/material";

export interface LayoutNotificationProps {
  text: string | React.ReactElement;
  show: boolean;
  severity: AlertProps["severity"];
}

const LayoutNotification = (props: LayoutNotificationProps) => {
  const { text, show, severity } = props;
  if (!show) {
    return <></>;
  }
  return (
    <Alert severity={severity} sx={{ marginBottom: 2 }}>
      {text}
    </Alert>
  );
};

export default LayoutNotification;
