import { InvestigationTable } from "../../types/alerts.types";

interface HeadCell {
  disablePadding: boolean;
  id: keyof InvestigationTable;
  label: string;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: "timeGenerated",
    numeric: true,
    disablePadding: true,
    label: "Time Generated",
  },
  {
    id: "type",
    numeric: true,
    disablePadding: true,
    label: "Type",
  },

{
    id: "deviceName",
    numeric: true,
    disablePadding: true,
    label: "Device Name",
  },
  {
    id: "actionType",
    numeric: true,
    disablePadding: true,
    label: "Action Type",
  },
  {
    id: "additionalInformations",
    numeric: true,
    disablePadding: false,
    label: "Additional Information",
  },
 

];
