import { Card, CardContent, Grid, Typography } from "@mui/material"
import ReportBarChart from "../Widgets/ReportBarChart";
import { determineBarChartCategoriesForDeviceCategory, determineBarChartDataForDeviceCategory, determineRowDataForDeviceCategory } from "./DeviceTabsUtils";
import ReportsTable from "../Widgets/ReportsTable";
interface DeviceCategoryProps {
    deviceCategory: any;
}

export const DeviceCategory = (props: DeviceCategoryProps) => {
    const { deviceCategory } = props
    const barChartCategories = determineBarChartCategoriesForDeviceCategory(deviceCategory)
    const barChartData = determineBarChartDataForDeviceCategory(deviceCategory)
    const tableHeaders = ['Category', '# Devices', '% of Total']
    const rowData = determineRowDataForDeviceCategory(deviceCategory)

    return (
        <Card id='exportDeviceCategory' key='chartContainerCard' sx={{ border: "solid 1px #ddd", m: 1, }}>
            <CardContent sx={{ padding: 0 }}>
                <Grid container spacing={0}>
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'center', pt: 1 }}>
                        <Typography variant="h3" component="h3" sx={{ pb: 1, }}>
                            Device Category
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-end' }}>
                       
                    </Grid>


                    <Grid item xs={8} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportBarChart
                            barChartCategories={barChartCategories}
                            barChartData={barChartData}
                            type={'devices'}
                        />
                    </Grid>
                    <Grid item xs={4} sx={{ display: "flex", justifyContent: 'space-between' }}>
                        <ReportsTable
                            rowData={rowData}
                            tableHeaders={tableHeaders}
                            fileName={'Device Category Data'}
                        />
                    </Grid>

                </Grid>

            </CardContent>

        </Card >
    )
}