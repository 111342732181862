import { Box, Button, Modal, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { fetchInvestigateDevice, fetchIpLocation} from "../../redux/Thunks/alerts";
import { Loader } from "../../components/Loader";
import { IPLocation } from "../../types/alerts.types";
import { useParams } from "react-router-dom";
import { getDevices } from "../../redux/Slices/devices.slice";


interface InvestigatedEntitiesLinkProps {
    investigatedDevice: any;
}

export const InvestigatedEntitiesLink = (props: InvestigatedEntitiesLinkProps) => {
    const { investigatedDevice } = props
  const { id, group } = useParams<string>();
    const [open, setOpen] = useState(Array(!investigatedDevice.investigatedEntities ? 0 : investigatedDevice.investigatedEntities.length).fill(false));
    const [tooltipTitle, setTooltipTitle] = useState<any>('')
    const [IPResponce, setIPResponse] = useState<IPLocation>()
    const [loading, setLoading] = useState(false)
    const dispatch = useAppDispatch();
    const devices = useAppSelector(getDevices);
    const currentDevice = devices.find((device) => device.deviceGroup === group && device.id === id);
    const [validIP, setValidIP] = useState(false)


    useEffect(() => {
        setOpen(Array(!investigatedDevice.investigatedEntities ? 0 : investigatedDevice.investigatedEntities.length).fill(false))
    }, [investigatedDevice]);

    const handleClose = (index: number) => {
        let newArr = [...open];
        newArr[index] = false;
        setOpen(newArr);
    };


    const ValidateIPaddress = (ipaddress: string) => {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return (true)
        }
        return (false)
    }

    const handleOpen = async (entity: string, index: number) => {
        const validEntity = ValidateIPaddress(entity)
        setValidIP(validEntity)
        if(validEntity){
            const response = await dispatch(fetchIpLocation(entity))
            setIPResponse(response.payload)
            let newArr = Array(!investigatedDevice.investigatedEntities ? 0 : investigatedDevice.investigatedEntities.length).fill(false);
            newArr[index] = true;
            setOpen(newArr);
        }if (!validEntity) {
            let newArr = Array(!investigatedDevice.investigatedEntities ? 0 : investigatedDevice.investigatedEntities.length).fill(false);
            newArr[index] = true;
            setOpen(newArr);
        }
    };

    const buildTooltip = () => {
        let ipArray: any[] = []
        if (validIP) {
            const titleCase = {
                'Ip': IPResponce?.ip,
                'City': IPResponce?.city,
                'Country': IPResponce?.country,
                'Country Code': IPResponce?.countryCode,
                'Isp': IPResponce?.isp,
                'Org': IPResponce?.org,
                'region': IPResponce?.region,
                'Region Name': IPResponce?.regionName,
                'Zip': IPResponce?.zip
            }
            Object.entries(titleCase).forEach((value: any) => {
                ipArray.push(<Typography>{value[0].charAt(0).toUpperCase() + value[0].slice(1)}: {value[1]}</Typography>)
            })
        }if (!validIP) {
            ipArray.push(<Typography>{''}</Typography>)
        }
        return ipArray
    }

    useEffect(() => {
        setTooltipTitle(buildTooltip())
    }, [IPResponce, validIP]);

    const handleClick = async (entity: string) => {
        setLoading(true)

        const command = `${currentDevice?.id}/${currentDevice?.deviceGroup}?investigate=${entity}`
        const response = await dispatch(fetchInvestigateDevice(command));
        if (response.payload) {
            setLoading(false)
        }
    }
    return (


        (investigatedDevice.investigatedEntities ?
            <Box>
                <Modal open={loading}>
                    <Loader show={true} />
                </Modal>
                <strong>Investigated Entities: </strong>
                {investigatedDevice.investigatedEntities.length < 1 ?
                    'No Entities for this Device' :

                    (investigatedDevice.investigatedEntities.map((entity: string, index: number) => {
                        return (

                            <Tooltip
                                open={open[index]}
                                onClose={() => handleClose(index)}
                                onOpen={() => handleOpen(entity, index)}
                                title={IPResponce ? tooltipTitle : ''}
                            >
                                <Button
                                    sx={{ margin: '2px' }}

                                    variant="text"
                                    key={index}
                                    onClick={() => handleClick(entity)}>
                                    {entity}
                                </Button>

                            </Tooltip>
                        )

                    }))}
            </Box >
            :
            <></>)

    )
}

