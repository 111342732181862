import { Box } from "@mui/material"
import { UserInfo } from "./UserInfo"
import { getUserStatistics } from "../../../../redux/Slices/account.slice";
import { useAppSelector } from "../../../../redux/hooks";
import { useIdentityContext } from "../../../../context/IdentityContext";
import { getUserById } from "../../../../redux/Slices/account.slice";
import { isUserAdmin } from "../../../../redux/Slices/account.slice";
import { getAllTickets } from "../../../../redux/Slices/tickets.slice";
import { getSummaries } from "../../../../redux/Slices/changeManagement.slice";







export const UsersReportsTab = () => {
    const { authenticatedUser } = useIdentityContext();
    const userStatistics = useAppSelector(getUserStatistics);
    const identity = useIdentityContext();
    const user = useAppSelector(getUserById(identity.authenticatedUser.userId));
    const isAdmin = useAppSelector(isUserAdmin(authenticatedUser.userId))
    const allTickets = useAppSelector(getAllTickets)
    const CMSummaries = useAppSelector(getSummaries)

    return (
        <Box>
            <UserInfo
                userStatistics={userStatistics}
                user={user}
                isAdmin={isAdmin}
                allTickets={allTickets}
                CMSummaries={CMSummaries}
            />
        </Box>
    )
}