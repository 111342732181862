import { faXmark } from "@fortawesome/pro-light-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, ButtonGroup, FormControl, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { isInboundTicketEmailAvailable, saveInboundTicketEmail } from "../../redux/Thunks/company"
import { NotifyBannerSmall } from "../../components/NotificationBannerSmall"
import { getCompanySettings } from "../../redux/Slices/company.slice"
import { faSquareInfo } from "@fortawesome/pro-solid-svg-icons"
import ConfirmationDialog from "../../components/ConfirmationDialog"



export const InboundEmailConfigurationForm = () => {
    const [formValues, setFormValues] = useState<string>('')
    const dispatch = useAppDispatch();
    const [error, setError] = useState<boolean>(false)
    const [showSuccessBanner, setShowSuccessBanner] = useState<boolean>(false)
    const [disabled, setDisabled] = useState<boolean>(false)
    const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

    const companySettings = useAppSelector(getCompanySettings)

    useEffect(() => {
        if (companySettings?.inboundTicketEmail) {
            setDisabled(true)
        }
    }, [companySettings])

    useEffect(() => {
        if (formValues.length === 0) {
            setError(false)
        }
    }, [formValues])


    const handleInboundEmailChange = (event: string) => {
        setFormValues(event)
    }

    const onDeleteFormField = () => {
        setFormValues('')
        setError(false)

    }

    const onHandleSaveInboundEmail = async () => {
        const payload = formValues + '@pticketing.com'
        const validationResponse: any = await dispatch(isInboundTicketEmailAvailable(payload));
        if (validationResponse.payload) {
            const command = `?inboundTicketEmail=${payload}`
            await dispatch(saveInboundTicketEmail(command))
            setShowSuccessBanner(true)
            setDialogOpen(false);

        }
        if (!validationResponse.payload) {
            setError(true)
            setDialogOpen(false);

        }

    }

    return (
        <>
            {showSuccessBanner === true ? (
                <NotifyBannerSmall
                    secondsDuration={10}
                    message="Inbound Email Address has been successfully saved."
                    showNotification={showSuccessBanner}
                    createdId={''}
                    navigatePage=''
                    linkText=''
                    severity='success'
                />
            ) : (
                <></>
            )}
            <ConfirmationDialog
                title="Are you sure you want to set your Inbound Email Address?"
                content="Inbound Email Address can only be set once and can not be changed."
                open={isDialogOpen}
                onConfirm={() => { onHandleSaveInboundEmail() }}
                onCancel={() => { setDialogOpen(false) }}
            />
            <Grid container spacing={2} sx={{ m: 0 }}>
                {disabled ?
                    <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-start' }}>
                        <FontAwesomeIcon
                            size="lg"
                            color="orange"
                            icon={faSquareInfo}
                        />
                        <Typography variant="h6" sx={{ pl: 1 }}>
                            Inbound Email Address already set as {companySettings.inboundTicketEmail}.
                        </Typography>
                    </Grid>
                    :
                    <>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: 'flex-start' }}>
                            <FontAwesomeIcon
                                size="lg"
                                color="orange"
                                icon={faSquareInfo}
                            />
                            <Typography variant="h6" sx={{ pl: 1 }}>
                                Warning: Inbound Email Address can only be set once and cannot be changed.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Save Inbound Ticket Email.
                            </Typography>
                            <Typography variant="subtitle2" color="silver" >
                                Email address will end with '@pticketing.com'
                            </Typography>
                        </Grid>
                    </>

                }
                <Grid item xs={7} sx={{ mb: 0, ml: 12, display: "flex", justifyContent: 'space-between' }}>
                    <FormControl sx={{ width: '100%' }} variant="outlined" size='small'>
                        <TextField
                            label='Inbound Email Address'
                            error={error}
                            disabled={disabled}
                            helperText={error ? 'Email Not Available' : ' '}
                            sx={{ display: 'flex', flexGrow: 1 }}
                            id="resourceGroupName"
                            size="medium"
                            value={formValues}
                            onChange={(event) =>
                                handleInboundEmailChange(event.target.value)
                            }
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton
                                        disabled={disabled}
                                        onClick={() => onDeleteFormField()}
                                    >
                                        <FontAwesomeIcon
                                            icon={faXmark}
                                            color={"#f8931c"}
                                            size="xs"
                                        />
                                    </IconButton>
                                </InputAdornment>,
                            }}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3} sx={{ mb: 0, mt: 2, p: 0, display: "flex", justifyContent: 'space-between' }}>
                    <Typography>@pticketing.com</Typography>
                </Grid>
                <Grid item xs={12} sx={{ mt: 0, pr: 2, display: "flex", justifyContent: 'flex-end' }}>
                    <ButtonGroup
                        disabled={disabled}
                        // onClick={() => setDialogOpen(true)}
                        onClick={() => !disabled ? setDialogOpen(true) : null}

                    >
                        <Button>
                            Save Inbound Email Address
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </>

    )
}