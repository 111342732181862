import { Button, Grid, ListItem, Paper, Typography } from "@mui/material";
import React from "react";
import { StripeProduct } from "../../types/stripe.type";

export interface ProductDisplayProps {
  product: StripeProduct;
  onCheckout: () => void;
}

const ProductDisplay = (props: ProductDisplayProps) => {
  const { name, price, logo } = props.product;
  const { onCheckout } = props;

  return (
    <ListItem disableGutters disablePadding>
      <Paper sx={{ width: "100%", padding: "10px" }}>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={6}>
            <Typography
              sx={{ marginRight: "24px", verticalAlign: "middle" }}
              component="h3"
            >
              {name}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid container justifyContent={"flex-end"}>
              <Typography sx={{ marginRight: "24px" }} component="h5">
                ${price} / month
              </Typography>
              <Button variant="contained" onClick={onCheckout}>
                Purchase
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </ListItem>
  );
};

export default ProductDisplay;
