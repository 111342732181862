import dayjs from "dayjs";
import { Device, DeviceChartData } from "../../types/devices.types"

export const determineChartType = (chartData: DeviceChartData, device: Device[]) => {

    const counter = chartData.counterName;

    switch (counter) {
        case '% Free Space':
        case '% Idle Time':
            return buildSplineWithPlotBands(chartData, device);

        case '':
            return buildBar(chartData, device);

        case '':
            return buildAreaLine(chartData, device);

        case '':
            return buildAreaLineWithPlotBands(chartData, device);

        default:
            return buildSpline(chartData, device);
    }

}
const determineData = (chartData: DeviceChartData) => {
    let dataArray: any[] = []
    chartData.chartData.map((data: any) => {
        dataArray.push(data.sampleValue)
    })
    return dataArray;
}

const determineTimeSeries = (chartData: DeviceChartData) => {
    let timeArray: any[] = []
    chartData.chartData.map((data: any) => {
        timeArray.push(dayjs(data.timeStamp).format('DD/MMM/YY'))
    })
    return timeArray
}
const buildSpline = (chartData: DeviceChartData, device: Device[]) => {
    const sortedChartData = chartData.chartData.sort(function (a: any, b: any) {
        return a.timeStamp.localeCompare(b.timeStamp);
    });
    chartData.chartData = sortedChartData
    const splineChart = {
        chart: {
            zoomType: 'x',
            type: 'spline',
        },
        credits: {
            enabled: false
        },
        title: {
            text: chartData.chartData.length === 0 ? "No Data for selected time interval." : chartData.counterName
        },
        xAxis: {
            type: 'datetime',
            tickInterval: 10,
            categories: determineTimeSeries(chartData),
        },
        yAxis: {
            title: {
                text: chartData.counterName
            },
        },
        plotOptions: {
            spline: {
                lineWidth: 2,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
            }
        },
        series: [
            {
                name: device[0].deviceName,
                data: determineData(chartData)
            }
        ]

    }
    return splineChart;
}

const buildSplineWithPlotBands = (chartData: DeviceChartData, device: Device[]) => {
    const counter = chartData.counterName;
    const splineChartWithPlotBands = {
        chart: {
            type: 'spline',
            scrollablePlotArea: {
                minWidth: 600,
                scrollPositionX: 1
            }
        },
        credits: {
            enabled: false
        },
        title: {
            text: chartData.chartData.length === 0 ? "No Data for selected time interval." : chartData.counterName
        },

        xAxis: {
            tickInterval: 10,
            categories: determineTimeSeries(chartData)

        },
        yAxis: {
            title: {
                text: chartData.counterName
            },
            max: 100,
            min: 0,
            minorGridLineWidth: 0,
            gridLineWidth: 0,
            alternateGridColor: null,
            plotBands: [
                { // bottom
                    from: 0,
                    to: counter === '% Free Space' ? 20 : 50,
                    color: counter === '% Free Space' ? 'rgb(244,210,210)' : 'rgb(210,244,210)',
                    label: {
                        text: counter === '% Free Space' ? 'Critical' : 'Normal',
                        style: {
                            color: '#606060'
                        }
                    }
                },
                { //middle
                    from: counter === '% Free Space' ? 20 : 50,
                    to: counter === '% Free Space' ? 40 : 75,
                    color: 'rgb(244,244,210)',
                    label: {
                        text: 'Warning',
                        style: {
                            color: '#606060'
                        }
                    }
                },
                { //top
                    from: counter === '% Free Space' ? 40 : 75,
                    to: 100,
                    color: counter === '% Free Space' ? 'rgb(210,244,210)' : 'rgb(244,210,210)',
                    label: {
                        text: counter === '% Free Space' ? 'Safe' : 'Alert',
                        style: {
                            color: '#606060'
                        }
                    }
                },
            ]
        },

        plotOptions: {
            spline: {
                lineWidth: 2,
                states: {
                    hover: {
                        lineWidth: 3
                    }
                },
                marker: {
                    enabled: false
                },

            }
        },
        series: [{
            name: device[0].deviceName,
            data: determineData(chartData)

        }],

    }
    return splineChartWithPlotBands
}

const buildBar = (chartData: DeviceChartData, device: Device[]) => {

    const barChart = {
        chart: {
            type: 'column'
        },
        credits: {
            enabled: false
        },
        title: {
            text: chartData.chartData.length === 0 ? "No Data for selected time interval." : chartData.counterName
        },
        subtitle: {
            text: 'Source: WorldClimate.com'
        },
        xAxis: {
            categories: determineTimeSeries(chartData),
            crosshair: true
        },
        yAxis: {
            min: 0,
            max: 100,
            title: {
                text: chartData.counterName
            }
        },
        plotOptions: {
            column: {
                pointPadding: 0.2,
                borderWidth: 0
            }
        },
        series: [{
            name: device[0].deviceName,
            data: determineData(chartData)

        }]

    }
    return barChart;
}
const buildAreaLine = (chartData: DeviceChartData, device: Device[]) => {

}
const buildAreaLineWithPlotBands = (chartData: DeviceChartData, device: Device[]) => {

}