import * as Mui from "@mui/material";
import { faBallotCheck } from "@fortawesome/pro-light-svg-icons";
// import { ItemsTable } from "./ItemsTable";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getSummaries } from "../../redux/Slices/changeManagement.slice";
import { useAsyncEffect } from "../../common/hooks";
import { fetchChangeManagementSummaries } from "../../redux/Thunks/changeManagement";
import { ChangeManagementTableSummary } from "./ChangeManagementTableSummary";
import { Loader } from "../../components/Loader";
import ChangeManagementEnhancedTable from "./ChangeManagementEnhancedTable/ChangeManagementEnhancedTable";
import { useEffect } from "react";
import { setLayout } from "../../redux/Slices/layout.slice";

export const ChangeManagementDashboardPage = () => {
  const dispatch = useAppDispatch();
  const summaries = useAppSelector(getSummaries);
  const { loading } = useAsyncEffect(
    () => dispatch(fetchChangeManagementSummaries()),
    []
  );

  useEffect(() => {
    dispatch(
      setLayout({
        title: "Change Mgmt Dashboard",
        subtitle: "Change Mgmt",
        icon: faBallotCheck,
      })
    );
  }, [dispatch]);

  return (
    <>
      {loading && summaries.length < 1 ? (
        <Loader show={true} />
      ) : (
        <>
          <ChangeManagementTableSummary summaries={summaries} />
          <Mui.Grid container>
            <Mui.Grid
              container
              spacing={2}
              sx={{ minHeight: "200px", marginTop: 1 }}
            >
              <Mui.Grid item xs={12}>
                {/* <ItemsTable /> */}
                <ChangeManagementEnhancedTable summaries={summaries} />
              </Mui.Grid>
            </Mui.Grid>
          </Mui.Grid>
        </>
      )}
    </>
  );
};
