import { Box, Card, Tab, Tabs, Typography } from "@mui/material";
import { a11yProps, TabPanel } from "../../../common/DashboardTabs";
import { useState } from "react";
import { useAsyncEffect } from "../../../common/hooks";
import { useAppDispatch } from "../../../redux/hooks";
import { fetchDeviceById } from "../../../redux/Thunks/devices";
import { DeviceTabContent } from "../../../components/DeviceTabContent";

interface DeviceInformationModalProps {
    device: any
}

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    height: '80vh',
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

export const DeviceInformationModal = (props: DeviceInformationModalProps) => {
    const { device } = props
    const dispatch = useAppDispatch();
    const [value, setValue] = useState(0);
    const [tabInfo, setTabInfo] = useState<any>({})
    const [general, setGeneral] = useState<any>([])
    const [companyData, setCompanyData] = useState<any>([])
    // const [hyperV, setHyperV] = useState<any>([])
    const [sqlData, setSqlData] = useState<any>([])
    const [inTune, setInTune] = useState<any>([])
    const [notes, setNotes] = useState<any>([])

    const { loading } = useAsyncEffect(async () => {
        const response = await dispatch(fetchDeviceById({ id: device.id, deviceGroup: device.deviceGroup }));
        const { payload } = response
        setGeneral([
            {
                name: 'Active Directory Object Sid',
                value: payload.activeDirectoryObjectSid
            },
            {
                name: 'Active Directory Site',
                value: payload.activeDirectorySite
            },
            {
                name: 'Category Name',
                value: payload.categoryName
            },
            {
                name: 'Device Category',
                value: payload.deviceCategory
            },
            {
                name: 'Device Sub-Type',
                value: payload.deviceSubtype
            },
            {
                name: 'Device Type',
                value: payload.deviceType
            },
            {
                name: 'Display Name',
                value: payload.displayName
            },
            {
                name: 'Domain Dns Name',
                value: payload.domainDnsName
            },
            {
                name: 'Id',
                value: payload.id
            },
            {
                name: 'Install Directory',
                value: payload.installDirectory
            },
            {
                name: 'Ip Address',
                value: payload.ipAddress
            },
            {
                name: 'Last Modified',
                value: payload.lastModified
            },
            {
                name: 'Logical Processors',
                value: payload.logicalProcessors
            },
            {
                name: 'Portal Company Id',
                value: payload.portalCompanyId
            },
            {
                name: 'Last Logged on User',
                value: payload.lastLoggedOnUser
            },
        ])
        setCompanyData([
            {
                name: 'Company Name',
                value: payload.companyName
            },
            {
                name: 'Host Server Name',
                value: payload.hostServerName
            },
        ])
        // setHyperV([
        //     {
        //         name: 'Host Server Name',
        //         value: payload.hostServerName
        //     },
        // ])
        setSqlData([
            {
                name: 'Instance Name',
                value: payload.instanceName
            },
           
            {
                name: 'Version',
                value: payload.version
            },
        ])
        setInTune([
            {
                name: 'Last Hardware Scan',
                value: payload.lastHardwareScan
            },
            {
                name: 'Last Inventory Date',
                value: payload.lastInventoryDate
            },
            {
                name: 'Last Online',
                value: payload.lastOnline
            },
            {
                name: 'Last Synch',
                value: payload.lastSynch
            },

        ])
        setNotes([
            {
                name: 'Notes',
                value: payload.notes
            },
        ])

    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    return (

        <Card sx={style}>
            <Typography variant="h3" component="h3" sx={{pb: 1,}}>
                Additional Information for {device.description}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="General" {...a11yProps(0)} />
                    <Tab label="Company Data" {...a11yProps(1)} />
                    {/* <Tab label="Hyper-V" {...a11yProps(2)} /> */}
                    <Tab label="SQL" {...a11yProps(2)} />
                    <Tab label="InTune" {...a11yProps(3)} />
                    <Tab label="Notes" {...a11yProps(4)} />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0} >
                <DeviceTabContent
                    content={general}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <DeviceTabContent
                    content={companyData}
                />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
                <DeviceTabContent
                    content={hyperV}
                />
            </TabPanel> */}
            <TabPanel value={value} index={2}>
                <DeviceTabContent
                    content={sqlData}
                />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <DeviceTabContent
                    content={inTune}
                />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <DeviceTabContent
                    content={notes}
                />
            </TabPanel>

        </Card>
    )
}