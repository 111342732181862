
import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Card } from '@mui/material';
import { useRef } from 'react';



export default function ReportPieChart(props) {
    const { pieData } = props
    const chartComponent = useRef({});


    const options = {
        chart: {
            type: 'pie',
        },
        legend: {
            align: 'left',
            verticalAlign: 'top',
            layout: 'vertical',
            x: 0,
            y: 100,
            labelFormatter: function () {
                if(this.y){
                    return this.name;
                }
                if(!this.y){
                    return this.name
                }
            }
        },
        credits: {
            enabled: false
        },

        title: {
            text: null
        },
       
        tooltip: {
            pointFormat: ' {point.y} <b>{point.percentage:.1f} %'
        },
        plotOptions: {
            pie: {
                borderRadius: 25,
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                    distance: '10%',
                    format: '{point.y} {point.name} </b>: {point.percentage:.1f} %',

                },

                center: ['50%', '50%'],
                size: '100%',
                showInLegend:  true

            }
        },
        series: [{
            name: "Total",
            // data is array of objects [{name: 'string', y: number}]
            data: pieData
        }],
    }


    return (
        <Card sx={{width: '100%', m: 1, p: 1, border: "solid 1px #ddd"}}>
        
            <HighchartsReact
                ref={chartComponent}
                highcharts={Highcharts} options={options} containerProps={{ style: { height: "100%" } }} />
        </Card>

    );
}