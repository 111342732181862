import { faCirclePlus, faCircleMinus, faCircleCheck, faSquare, faSquareX } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, LinearProgress, LinearProgressProps, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import {
    getAccountPreferences,
    getSetUpProgress,
    setSetUpProgress
} from "../redux/Slices/account.slice";
import ConfirmationDialog from "./ConfirmationDialog";
import { NewUserModalSteps } from "./NewUserModalSteps";
import { toggleSetUpInstructions } from "../redux/Thunks/account";


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '5px' }}>
            <Box sx={{ width: '100%' }}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35, paddingLeft: '15px' }}>
                <Typography variant="body2" color="text.secondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


export const NewUserModal = () => {
    const [expand, setExpand] = useState([false, false, false, false])
    const setUpProgress = useAppSelector(getSetUpProgress);
    const [completeIndex, setcompleteIndex] = useState(setUpProgress)
    const [isDialogOpen, setDialogOpen] = useState(false);
    const preferences = useAppSelector(getAccountPreferences);
    const dispatch = useAppDispatch();
    const onExpand = (index: number) => {
        setExpand(prevState => prevState.map((item, idx) => idx === index ? !item : item))
    }

    const onHandleComplete = (index: number) => {
        setcompleteIndex(prevState => prevState.map((item, idx) => idx === index ? !item : item))
    }

    useEffect(() => {
        dispatch(setSetUpProgress(completeIndex));
    }, [completeIndex]);

    const onClickRemoveSetUp = async () => {
        await dispatch(
            toggleSetUpInstructions(!preferences.setUpInstructionsDisabled)
        );
        setDialogOpen(false);
    }
    return (
        <>
            <ConfirmationDialog
                title="Are you sure you want to remove the set up guide?"
                content="You will be able to view these instructions by clicking the ⓘ button"
                open={isDialogOpen}
                onConfirm={() => { onClickRemoveSetUp() }}
                onCancel={() => { setDialogOpen(false) }}
            />
            <Box sx={style} style={{ overflowY: 'scroll' }}>
                <Typography variant="h3" component="h3">
                    Welcome to your Security Portal!
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Before you get started lets go through how to set up and navigate the portal.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', margin: '15px 0 15px 0' }}>
                    <Grid container spacing={1} >
                        <Grid item xs='auto' >
                            <Typography variant="h3" component="h3" >
                                Progress:
                            </Typography>
                        </Grid>
                        <Grid item xs={10.5} >
                            <LinearProgressWithLabel value={(setUpProgress.filter(x => x === true).length) * (100 / NewUserModalSteps.length)} />
                        </Grid>
                    </Grid>
                </Box>
                <div>
                    {NewUserModalSteps.map((accordion: any, index: number) => {
                        return (
                            <Accordion expanded={expand[index]}>
                                <AccordionSummary
                                    expandIcon={expand[index] ?
                                        <FontAwesomeIcon
                                            size="lg"
                                            icon={faCircleMinus}
                                            color={"#f8931c"}
                                            style={{ width: "20px", textAlign: "center" }}
                                            onClick={() => onExpand(index)}
                                        /> : <FontAwesomeIcon
                                            size="lg"
                                            icon={faCirclePlus}
                                            color={"#f8931c"}
                                            style={{ width: "20px", textAlign: "center" }}
                                            onClick={() => onExpand(index)}
                                        />
                                    }
                                >
                                    <FontAwesomeIcon
                                        icon={faCircleCheck}
                                        color={completeIndex[index] ? "limegreen" : "#ccc"}
                                        size="xl"
                                    />
                                    <Typography sx={{ marginLeft: '10px' }}>{accordion.title}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <Typography>
                                            {accordion.content}
                                        </Typography>
                                        {!completeIndex[index] ?
                                            <Button onClick={() => onHandleComplete(index)} variant="outlined"
                                                sx={{ borderWidth: 2, position: 'absolute', bottom: 10, right: 50 }}>
                                                Complete
                                            </Button>
                                            :
                                            <Button onClick={() => onHandleComplete(index)} variant="outlined"
                                                sx={{ borderWidth: 2, position: 'absolute', bottom: 10, right: 50 }}>
                                                Redo Section
                                            </Button>
                                        }

                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                </div>
                <Tooltip title='This will permanently remove set up instruction guide'>
                    <Button sx={{ position: 'absolute', top: 10, right: 10 }} onClick={() => setDialogOpen(true)}>
                        {preferences.setUpInstructionsDisabled === false ?
                            <FontAwesomeIcon
                                size="lg"
                                icon={faSquare}
                                style={{ marginRight: '5px', color: 'black' }}
                            />
                            :
                            <FontAwesomeIcon
                                size="lg"
                                icon={faSquareX}
                                style={{ marginRight: '5px', color: 'black' }}
                            />
                        }
                        <Typography variant="subtitle2" sx={{ color: 'black' }}>
                            Remove set up instructions
                        </Typography>
                    </Button>
                </Tooltip>
            </Box>
        </>
    );
};
