import * as Mui from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faCaretDown} from "@fortawesome/pro-light-svg-icons";
import { Card } from "../../components/Card";
import React, { useState } from "react";
import ConfirmationDialog from "../../components/ConfirmationDialog";
import { UserSummary } from "../../types/account.types";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { deleteUserById } from "../../redux/Thunks/account";
import { getInstallScript, getRoles } from "../../redux/Slices/account.slice";
import { getSentinelConfig, setGatewayServer, setSetUpInfoPage } from "../../redux/Slices/company.slice";
import { SentinelConfigFormModal } from "../../components/SentinelConfigModal/SentinelConfigFormModal";
import { TeamListItem } from "./TeamListItem";

interface TeamProps {
  users: UserSummary[];

}

export const Team: React.FC<TeamProps> = (props) => {
  enum showStatus {
    All = 0,
    Disabled = 1,
    Awaiting = 2,
  }

  const [showItems, setShowItems] = React.useState<showStatus>(showStatus.All);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [deleteIdx, setDeleteIdx] = useState(-1);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const roles = useAppSelector(getRoles);
  const installScript = useAppSelector(getInstallScript);
  const isAdmin = roles.some((el: { normalizedName: string; }) => el.normalizedName === "companyadmin");
  const [openSentinelConfigModal, setOpenSentinelConfigModal] = useState(false)
  const handleSentinelConfigModalClose = () => setOpenSentinelConfigModal(false)
  const sentinelConfig = useAppSelector(getSentinelConfig);
  const updateShowItems = (status: showStatus) => {
    setShowItems(status);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickInstallScript = () => {
    setOpen(true);
    navigator.clipboard.writeText(installScript);
    setAnchorEl(null);
  };
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onClickSetUpInfo = () => {
    dispatch(setSetUpInfoPage(true));

    setAnchorEl(null);
  };
  const onClickGatewayServer = () => {
    dispatch(setGatewayServer(true));

    setAnchorEl(null);
  };

  const onClickSentinelConfig = () => {
    setOpenSentinelConfigModal(true)
  }
  return (
    <>
      <Mui.Modal
        open={openSentinelConfigModal}
        onClose={handleSentinelConfigModalClose}

      >
        <SentinelConfigFormModal
          sentinelConfig={sentinelConfig}
        />

      </Mui.Modal>
      <ConfirmationDialog
        title="Are you sure you want to delete this user?"
        content={props.users[deleteIdx]?.firstName + " " + props.users[deleteIdx]?.lastName}
        open={isDialogOpen}
        onConfirm={async () => {
          setIsDeleting(true);
          await dispatch(deleteUserById(props.users[deleteIdx].id));
          setDialogOpen(false);
          setDeleteIdx(-1);
          setIsDeleting(false);
        }}
        onCancel={() => {
          setDialogOpen(false);
          setDeleteIdx(-1);
        }}
      />
      <Card
        id="companydashboard-yourteam"
        headerIcon={faUsers}
        headerTitle={`Your Team`}
        headerSubText={`${props.users.length} users in your Organisation`}
        showHelp={true}
        helpHeaderText="View your team"
        helpHeaderDescription="View your team and use the filter to show all, disabled and user awaiting signup"
        cardHeaderActions={
          <>
            {/* COPY INSTALL SCRIPT AND DOWNLOAD AGENT BUTTONS ARE LIMITED TO COMPANY ADMIN */}
            {isAdmin ? (
              <Mui.ButtonGroup>
                <Mui.Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  open={open}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Mui.Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{ width: "100%" }}
                  >
                    Installation script has been copied to clipboard, paste to
                    powershell to install agent.
                  </Mui.Alert>
                </Mui.Snackbar>
              </Mui.ButtonGroup>
            ) : (
              <></>
            )}

            <Mui.ButtonGroup>
              <Mui.Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.All ? "orange" : "white",
                  color: showItems === showStatus.All ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.All);
                }}
              >
                All
              </Mui.Button>
              <Mui.Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.Disabled ? "orange" : "white",
                  color: showItems === showStatus.Disabled ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.Disabled);
                }}
              >
                Disabled
              </Mui.Button>
              <Mui.Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.Awaiting ? "orange" : "white",
                  color: showItems === showStatus.Awaiting ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.Awaiting);
                }}
              >
                Awaiting Signup
              </Mui.Button>
            </Mui.ButtonGroup>

            <Mui.Button
              id="basic-button"
              variant="outlined"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleMenuClick}
              sx={{ marginLeft: '20px' }}
            >

              Additional Actions
              <FontAwesomeIcon icon={faCaretDown} color={"orange"} size="lg" style={{ paddingLeft: '5px' }} />
            </Mui.Button>
            <Mui.Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
                    {/*  <a href="https://poimages.blob.core.windows.net/protectorg/setupAgents.exe">
              <Mui.MenuItem onClick={handleMenuClose} sx={{ color: "black" }}>
                  Download Agent
                </Mui.MenuItem>
              </a>
              <Mui.MenuItem onClick={onClickInstallScript}>
                Copy Install Script
              </Mui.MenuItem>
              <Mui.MenuItem onClick={onClickSetUpInfo}>
                Set Up Information
              </Mui.MenuItem>
              <Mui.MenuItem onClick={onClickGatewayServer}>
                Gateway Server
              </Mui.MenuItem> */}
              <Mui.MenuItem onClick={onClickSentinelConfig}>
                Sentinel Config
              </Mui.MenuItem>
            </Mui.Menu>
          </>
        }
        cardContent={
          <Mui.List
            dense={true}
            sx={{
              width: "100%",
              bgcolor: "background.paper",
              overflowY: "auto",
              maxHeight: "75vh",
            }}
          >
            {props.users.map((user: UserSummary, index: number) =>
              showItems === showStatus.All ||
                (showItems === showStatus.Awaiting &&
                  user.isConfirmed === false) ||
                (showItems === showStatus.Disabled &&
                  user.lockoutEnabled === true) ? (
                <Mui.Box key={`user${index}`}>
                  <TeamListItem 
                     user={user}
                     isDeleting={isDeleting}
                     onDelete={() => {
                       setDeleteIdx(index);
                       setDialogOpen(true);
                     }}
                     isAdmin={isAdmin}
                  />
                  <Mui.Divider></Mui.Divider>
                </Mui.Box>
              ) : (
                <></>
              )
            )}
          </Mui.List>
        }
      />
    </>
  );
};
