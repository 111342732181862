import React from "react";
import * as Mui from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faCalendar,
  // faWrench,
  faVrCardboard,
  faEye,
  faChevronDown,
} from "@fortawesome/pro-light-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { IconButtonProps } from "@mui/material/IconButton";

import { styled, useTheme } from "@mui/material/styles";
import { DeviceTypeIcon } from "../../components/DeviceTypeIcon";
import { IStringIndexable } from "../../common/contracts";
import { IProtectOrgThemeHealthState } from "../../themes/ProtectOrgTheme";
import { FormattedDate } from "../../common/dateUtils";
import { Device } from "../../types/devices.types";

export const DeviceSummaryListItem: React.FC<{
  icon: IconDefinition;
  description: string;
  color: string;
}> = (props) => {
  return (
    <Mui.ListItem sx={{ pt: 0, pb: 0 }}>
      <Mui.ListItemAvatar
        sx={{ minWidth: "36px", padding: "3px", mr: 1, borderRadius: "5px" }}
      >
        <FontAwesomeIcon icon={props.icon} color={props.color} size="lg" />
      </Mui.ListItemAvatar>
      <Mui.ListItemText sx={{ p: 0 }} primary={<>{props.description}</>} />
    </Mui.ListItem>
  );
};

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <Mui.IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DeviceSummary: React.FC<{ device?: Device }> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  const theme = useTheme();
  const { device } = props;

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  var indexableTheme =
    theme.healthStates as IStringIndexable<IProtectOrgThemeHealthState>;

  if (!device) return <DeviceSummaryLoading />;
  return (
    <Mui.Card style={{ height: "100%" }}>
      <Mui.CardHeader
        avatar={
          <Mui.Avatar
            sx={{
              width: "36px",
              height: "36px",
              bgcolor:
                indexableTheme[
                  device.healthStateDescription.toLowerCase()
                ].backgroundColor.toString(),
            }}
            aria-label=""
          >
            <FontAwesomeIcon icon={faHeart} color={"white"} size="sm" />
          </Mui.Avatar>
        }
        title={"Device Summary"}
        subheader={
          <Mui.Typography
            variant="subtitle2"
            color="silver"
          >{`Created: ${FormattedDate(device.createdDate)}`}</Mui.Typography>
        }
        sx={{
          borderBottom: "solid",
          borderBottomWidth: "1px",
          borderBottomColor: "orange",
          p: 1,
        }}
      />
      <Mui.CardContent>
        <Mui.Stack
          direction={"column"}
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          justifyItems="center"
          sx={{ pt: 2 }}
        >
          <DeviceTypeIcon deviceType={device.deviceType}></DeviceTypeIcon>
        </Mui.Stack>

        <Mui.Typography variant="subtitle2" sx={{ pt: 2, pl: 2 }}>
          Device Name:{" "}
          <Mui.Box component="span" fontWeight="bold">
            {device.deviceName}
          </Mui.Box>
        </Mui.Typography>
        <Mui.Typography variant="subtitle2" sx={{ pl: 2 }}>
          Device Group:{" "}
          <Mui.Box component="span" fontWeight="bold">
            {device.deviceGroup}
          </Mui.Box>
        </Mui.Typography>
        <Mui.Typography variant="subtitle2" sx={{ pl: 2 }}>
          Type Of Device :{" "}
          <Mui.Box component="span" fontWeight="bold">
            {device.deviceTypeDescription}
          </Mui.Box>
        </Mui.Typography>

        <Mui.Divider sx={{ pt: 1, pb: 1 }}></Mui.Divider>
        <Mui.List
          dense={true}
          sx={{ width: "100%", bgcolor: "background.paper" }}
        >
          <DeviceSummaryListItem
            icon={faHeart}
            description={`Device is in a ${device.healthStateDescription} state`}
            color={indexableTheme[
              device.healthStateDescription.toLowerCase()
            ].backgroundColor.toString()}
          />
          {/* <DeviceSummaryListItem
            icon={faCalendar}
            description={`Device Last modified on ${FormattedDate(
              device.lastModifiedDate
            )}`}
            color={"#ccc"}
          /> */}
          <DeviceSummaryListItem
            icon={faCalendar}
            description={`Device Created On  ${FormattedDate(
              device.createdDate
            )}`}
            color={"#ccc"}
          />
          {/* <DeviceSummaryListItem
            icon={faWrench}
            description={`Device is ${device.inMaintenanceModeDescription} Mode`}
            color={"orange"}
          /> */}
          <DeviceSummaryListItem
            icon={faVrCardboard}
            description={`Device is ${
              device.isVirtualMachine ? "a virtual" : "NOT a virtual"
            } machine`}
            color={"orange"}
          />
          <DeviceSummaryListItem
            icon={faEye}
            description={`Device Monitoring: ${device.isMonitoredDescription}`}
            color={"orange"}
          />
        </Mui.List>
        <Mui.Divider />
        {/* <Mui.Typography variant="body2" color="text.secondary" sx={{pt:1}}>
                    This can be a summary of failures, tickets etc.
                </Mui.Typography> */}
      </Mui.CardContent>
      <Mui.CardActions disableSpacing>
        {/* <Mui.IconButton aria-label="add to favorites on dashboard">
                    <FontAwesomeIcon icon={faStar} color={'grey'}   /> 
                </Mui.IconButton>
                <Mui.IconButton aria-label="share">
                <FontAwesomeIcon icon={faShareNodes} color={'orange'}   /> 
                </Mui.IconButton> */}
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <FontAwesomeIcon icon={faChevronDown} color={"orange"} size="sm" />
        </ExpandMore>
      </Mui.CardActions>
      <Mui.Collapse in={expanded} timeout="auto" unmountOnExit>
        <Mui.CardContent>
          <Mui.Typography variant="h6">Description</Mui.Typography>&nbsp;
          <Mui.Typography paragraph>{device.description}</Mui.Typography>
        </Mui.CardContent>
      </Mui.Collapse>
    </Mui.Card>
  );
};

const DeviceSummaryLoading: React.FC = () => {
  return <>TODO: Skeleton Loading</>;
};

export { DeviceSummaryLoading, DeviceSummary };
