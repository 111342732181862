import { FlareItem } from "../../types/alerts.types";

interface HeadCell {
  disablePadding: boolean;
  id: keyof FlareItem;
  label: string;
  numeric: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: "timeGenerated",
    numeric: true,
    disablePadding: true,
    label: "Time Generated",
  },
  {
    id: "risk",
    numeric: true,
    disablePadding: true,
    label: "Risk",
  },

{
    id: "categoryName",
    numeric: true,
    disablePadding: true,
    label: "Category Name",
  },
  {
    id: "content",
    numeric: true,
    disablePadding: true,
    label: "Content",
  },
  {
    id: "sourceName",
    numeric: true,
    disablePadding: false,
    label: "Source Name",
  },
  {
    id: "targetName",
    numeric: true,
    disablePadding: false,
    label: "Target Name",
  },
  {
    id: "netLocation",
    numeric: true,
    disablePadding: false,
    label: "Net Location",
  },
  {
    id: "externalUrl",
    numeric: true,
    disablePadding: false,
    label: "External Url",
  },

];
