import { createTheme, SimplePaletteColorOptions } from "@mui/material/styles";
import { orange, green } from "@mui/material/colors";
import { ThemeOptions } from "@mui/material";

import type {} from "@mui/x-date-pickers/themeAugmentation";

interface IProtectOrgThemeHealthState {
  backgroundColor: string;
  color: string;
}

/* Add our own global semantic styles to the theme */
declare module "@mui/material/styles" {
  interface Theme {
    healthStates: {
      error: IProtectOrgThemeHealthState;
      unavailable: IProtectOrgThemeHealthState;
      ok: IProtectOrgThemeHealthState;
      warning: IProtectOrgThemeHealthState;
    };
    header: SimplePaletteColorOptions;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    healthStates?: {
      error?: IProtectOrgThemeHealthState;
      unavailable?: IProtectOrgThemeHealthState;
      ok?: IProtectOrgThemeHealthState;
      warning?: IProtectOrgThemeHealthState;
    };
    header?: SimplePaletteColorOptions;
  }
}

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

//lets create our new variants !
/*

TODO: 
add to card:
box-shadow: 0 0 13px 0 rgb(74 53 107 / 8%)

Adding & disabling variants
In addition to using the default typography variants, you can add custom ones, or disable any you don't need. Here is what you need to do:

Step 1. Update the theme's typography object

const theme = createTheme({
  typography: {
    poster: {
      color: 'red',
    },
    // Disable h3 variant
    h3: undefined,
  },
});
Step 2. Update the necessary typings (if you are using TypeScript)

If you aren't using TypeScript you should skip this step.

You need to make sure that the typings for the theme's typography variants and the Typography's variant prop reflects the new set of variants.

declare module '@mui/material/styles' {
  interface TypographyVariants {
    poster: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poster?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    poster: true;
    h3: false;
  }
}
Step 3. You can now use the new variant

poster
h3
<Typography variant="poster">poster</Typography>;

/* This variant is no longer supported 
<Typography variant="h3">h3</Typography>;






can then use like this:

 MuiCard: {
      variants: [
        {
          props: { variant: "raised" },
          style: {
            '-webkit-box-shadow': '0 0 13px 0 rgb(74 53 107 / 8%)',
            'box-shadow' : '0 0 13px 0 rgb(74 53 107 / 8%)'
          }
        }
      ], 
*/
declare module "@mui/material/Paper" {
  interface PaperPropsVariantOverrides {
    raised: true;
  }
}

/* This is our non palette theme */

const protectOrgTheme = createTheme({
  breakpoints: {
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536, xxl: 2500 },
  },
  // typography: {
  //   body1: {
  //     fontSize: '0.7rem'
  //   },
  //   body2: {
  //     fontSize: '0.7rem'
  //   },
  //   h6: {
  //     fontSize: '1rem',
  //     color:'red'
  //   }
  // },
  components: {
    MuiDatePicker: {
      styleOverrides: {
        root: {
          backgroundColor: "red",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: "white",
          backgroundColor: 'rgb(57,90,122)',
        }
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        html {
          --scrollbarBG: #fff;
          --thumbBG: #bbb;
        }
        *::-webkit-scrollbar {
          width: 11px;
        }
        * {
          scrollbar-width: thin;
          scrollbar-color: var(--thumbBG) var(--scrollbarBG);
        }
        *::-webkit-scrollbar-track {
          background: var(--scrollbarBG);
        }
        *::-webkit-scrollbar-thumb {
          background-color: var(--thumbBG) ;
          border-radius: 6px;
          border: 3px solid var(--scrollbarBG);
        }
      `,
    },
    MuiPaper: {
      styleOverrides: {
        //styling a card at root is an issue as it wraps the whole component with another div
        //so even proving root styles for box shadaows dont get applied as it automatically
        //applies the elevation1 one class above the root style on its parent div.
        elevation1: {
          boxShadow: "0 0 13px 0 rgb(74 53 107 / 8%)",
        },
      },
    },
    // MuiPaper: {
    //   variants: [
    //     {
    //       props: { variant: "raised" },
    //       style: {
    //         ".MuiPaper-raised":{
    //           backgroundColor: 'red'
    //         },
    //         root: {
    //           backgroundColor:'purple'
    //         }
    //       },

    //         // WebkitBoxShadow: '0 0 13px 0 rgb(74 53 107 / 8%)',
    //         // boxShadow: '0 0 13px 0 rgb(74 53 107 / 8%)',
    //         // // root: {

    //         //   backgroundColor: 'yellow'
    //         // }

    //     }
    //   ]
    // }
  },
  //
  //   MuiTypography: {
  //     styleOverrides: {

  //       // h6: {
  //       //   fontSize: '0.80rem',
  //       // },
  //       // h5: {
  //       //   fontSize: '0.85rem',
  //       // },
  //       // h4: {
  //       //   fontSize: '0.85rem',
  //       // },
  //       // h3: {
  //       //   fontSize: '0.85rem',
  //       // }
  //     }
  //   },

  //     styleOverrides: {
  //       root: {
  //         '-webkit-box-shadow': '0 0 13px 0 rgb(74 53 107 / 8%)',
  //         'box-shadow' : '0 0 13px 0 rgb(74 53 107 / 8%)'
  //       }
  //     }
  //   }
  //   }
});

/* Palette Themes */

export const healthStates: Record<string, any> = {
  error: { backgroundColor: "#eb372a", color: "#eb372a" }, //red
  unavailable: { backgroundColor: "#ccc", color: "#ccc" }, //grey
  ok: { backgroundColor: "#a4de6c", color: "#a4de6c" }, //green
  warning: { backgroundColor: "#ffc658", color: "#ffc658" }, //orange
};

const theme_light: ThemeOptions = {
  palette: {
    primary: {
      main: "rgb(232, 143, 0)",
      light: "#FFAF2D",
    },
    secondary: {
      main: "rgb(2, 43, 79)",
    },
    background: {
      default: "#f1f1f1",
    },
    success: {
      main: "limegreen",
    },
  },
  header: {
    main: "white",
    light: "red",
  },
  healthStates: {
    error: { backgroundColor: "#eb372a", color: "#eb372a" }, //red
    unavailable: { backgroundColor: "#ccc", color: "#ccc" }, //grey
    ok: { backgroundColor: "#a4de6c", color: "#a4de6c" }, //green
    warning: { backgroundColor: "#ffc658", color: "#ffc658" }, //orange
  },
};

const theme_dark: ThemeOptions = {
  palette: {
    primary: orange,
    secondary: green,
    background: {
      default: "#f1f1f1",
    },
  },
  healthStates: {
    error: { backgroundColor: "#eb372a", color: "#eb372a" },
    unavailable: { backgroundColor: "#ccc", color: "#ccc" },
    ok: { backgroundColor: "#a4de6c", color: "#a4de6c" },
    warning: { backgroundColor: "#ffc658", color: "#ffc658" },
  },
};

const theme_green: ThemeOptions = {
  palette: {
    primary: orange,
    secondary: green,
    background: {
      default: "#f1f1f1",
    },
  },
  healthStates: {
    error: { backgroundColor: "#eb372a", color: "#eb372a" },
    unavailable: { backgroundColor: "#ccc", color: "#ccc" },
    ok: { backgroundColor: "#a4de6c", color: "#a4de6c" },
    warning: { backgroundColor: "#ffc658", color: "#ffc658" },
  },
};

export { protectOrgTheme, theme_light, theme_dark, theme_green };
export type { IProtectOrgThemeHealthState };
