import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faQuestionSquare } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Grid, Typography } from "@mui/material";

interface HealthColumnProps {
    device: any;
}

export const HealthColumn = (Props: HealthColumnProps) => {
    const { healthState } = Props.device
    const formatLabel = () => {
        let label = {icon: faQuestionSquare, color: 'gray', text: '' }
        switch (healthState) {
            case 0:
                label = {
                    icon: faExclamationCircle,
                    color: 'red',
                    text: 'Critical'
                }
                break;
            case 1:
                label = {
                    icon: faCheckCircle,
                    color: 'green',
                    text: 'Healthy'
                }
                break;
            case 3:
                label = {
                    icon: faExclamationTriangle,
                    color: 'orange',
                    text: 'Warning'
                }
                break;
            default:
                break;
        }
        return label
    }
    const label = formatLabel()
    return (
        <Grid container spacing={2}>
            <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <FontAwesomeIcon icon={label.icon} color={label.color} size="lg" />
            </Grid>
            <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Typography>{label.text}</Typography>
            </Grid>
        </Grid>
    )
}
