import { faBallot } from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import React from "react";
import {
  getComparator,
  Order,
  stableSort,
} from "../../../common/TableUtilities";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../components/Card";
import { useTheme } from "@mui/material/styles";
import {
  ButtonGroup,
  Button,
  TableContainer,
  Paper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Stack,
  LinearProgress,
  TablePagination,
} from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import { useAsyncEffect } from "../../../common/hooks";
import { fetchAllUsers } from "../../../redux/Thunks/account";
import { Loader } from "../../../components/Loader";
import { ChangeManagementItemSummary } from "../../../types/changemanagement.types";
import { EnhancedTableHead } from "./ChangeManagementEnhancedTableHead";
import { FormattedDate } from "../../../common/dateUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ChangeManagementEnhancedTableProps {
  summaries: ChangeManagementItemSummary[];
}

export function ChangeManagementEnhancedTable(
  props: ChangeManagementEnhancedTableProps
) {
  return <EnhancedTable summaries={props.summaries}></EnhancedTable>;
}

export default function EnhancedTable(props: {
  summaries: ChangeManagementItemSummary[];
}) {
  const { summaries } = props;
  const [rows, setRows] =
    React.useState<ChangeManagementItemSummary[]>(summaries);
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] =
    React.useState<keyof ChangeManagementItemSummary>("createdDate");

  const [rowsPerPage, setRowsPerPage] = React.useState(15);
  const [page, setPage] = React.useState(0);
  enum showStatus {
    All = 0,
    Open = 1,
    Closed = 2,
  }
  const [showItems, setShowItems] = React.useState<showStatus>(showStatus.All);
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { loading } = useAsyncEffect(async () => {
    await dispatch(fetchAllUsers({}));
  }, []);

  const statusColour = (status: number | any) => {
    switch (status) {
      case 0:
        return theme.palette.success.main;
      case 1:
        return theme.palette.info.main;
      case 2:
        return theme.palette.warning.main;
      default:
        return "silver";
    }
  };
  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof ChangeManagementItemSummary
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const issuePercentage = (row: any) => {
    return Math.trunc(
      ((row.completePlans + row.completeMilestones) /
        (row.totalPlans + row.totalMilestones)) *
        100
    );
  };
  const headerText = () => {
    switch (showItems) {
      case showStatus.Open:
        return "Open";
      case showStatus.Closed:
        return "Closed";
      case showStatus.All:
        return "All";
      default:
        return "Open";
    }
  };

  const updateShowItems = (status: showStatus) => {
    setShowItems(status);
  };

  if (loading) {
    return <Loader show={true} />;
  }
  return (
    <>
      <Card
        id="CMItems"
        headerIcon={faBallot}
        headerTitle={`Showing ${headerText()} Change Management items`}
        headerSubText={
          !loading
            ? `Total of ${rows.length} ${headerText()} items`
            : "Loading change management data..."
        }
        showHelp={true}
        helpHeaderText="View All, Open or All Active"
        helpHeaderDescription="This view shows you all your currently open change management items, use the switch on the right to toggle between 
                     the available views."
        cardHeaderActions={
          <>
            <ButtonGroup>
              <Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.All ? "orange" : "white",
                  color: showItems === showStatus.All ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.All);
                }}
              >
                All
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.Open ? "orange" : "white",
                  color: showItems === showStatus.Open ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.Open);
                }}
              >
                Active
              </Button>
              <Button
                sx={{
                  backgroundColor:
                    showItems === showStatus.Closed ? "orange" : "white",
                  color: showItems === showStatus.Closed ? "white" : "orange",
                }}
                onClick={() => {
                  updateShowItems(showStatus.Closed);
                }}
              >
                Closed
              </Button>
            </ButtonGroup>
          </>
        }
        cardContent={
          <TableContainer
            component={Paper}
            sx={{
              borderStyle: "solid",
              borderColor: "#ccc",
              borderWidth: "1px",
            }}
          >
            <Table
              stickyHeader
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="recent tickets"
            >
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length || 0}
              />

              <TableBody>
                {stableSort(rows as any, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, _index) => {
                    return (
                      <TableRow key={row.id}>
                         <TableCell>{row.changeManagementNumber}</TableCell>
                        <TableCell>{row.title}</TableCell>
                        <TableCell>
                          <LinearProgress
                            variant="determinate"
                            color={"info"}
                            value={issuePercentage(row)}
                            sx={{
                              backgroundColor: "silver",
                              height: "10px",
                              borderRadius: 2,
                            }}
                          />
                        </TableCell>
                        <TableCell>{FormattedDate(row.createdDate)}</TableCell>
                        <TableCell>
                          {FormattedDate(row.lastModifiedDate)}
                        </TableCell>
                        <TableCell>
                          <FontAwesomeIcon
                            icon={faCircle}
                            color={statusColour(row.status)}
                            size="1x"
                          />{" "}
                          {row.statusDescription}
                        </TableCell>
                        <TableCell>{row.totalMilestones}</TableCell>
                        <TableCell>{row.completeMilestones}</TableCell>
                        <TableCell sx={{
                          fontWeight:
                            row.overdueMilestones === 0 ? "normal" : "bold",
                          color: row.overdueMilestones === 0 ? "black" : "red",
                        }}>
                           {row.overdueMilestones === 0
                          ? "-"
                          : row.overdueMilestones}
                        </TableCell>
                        <TableCell>{row.totalPlans}</TableCell>
                        <TableCell>{row.notStartedPlans}</TableCell>
                        <TableCell>{row.inProgressPlans}</TableCell>
                        <TableCell>{row.completePlans}</TableCell>
                        <TableCell sx={{ padding: "5px 2px 5px 5px" }}>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                navigate(`/ticket/${row.ticketId}`);
                              }}
                            >
                              View Ticket
                            </Button>
                          </Stack>
                        </TableCell>
                        <TableCell sx={{ padding: "5px 5px 5px 2px" }}>
                          <Stack
                            direction="row"
                            spacing={1}
                            justifyContent="flex-end"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                navigate(`/changemanagement/${row.id}`);
                              }}
                            >
                              View Change Mgt
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[15, 30, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        }
      />
    </>
  );
}
